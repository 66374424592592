import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Customer, PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import PaginationCpn from '../../common/PaginationCpn';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { customerLabelName } from '../../../statics';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// -------------
// Props
export type DeviceGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    keyword:string;
    onChangeSearchCondition: (name: string, value: any) => void;
    onShowDetails: (acceptanceNumber: string, redirect: string) => void;
    onSelect: (data: any) => void;
    openSearch: boolean;
    authRole: any;
    btnController: {
        isDisableGWStatusEditBtn: boolean;
        isDisableGWcrudEditBtn: boolean;
        isDisableGWcrudDeleteBtn: boolean;
    }
};

// -------------
// Component
export const DeviceGrid = forwardRef((props: DeviceGridProps, ref) => {
    const { colState, pageSize, pageData, keyword, onChangeSearchCondition, onShowDetails, onSelect, openSearch, btnController, authRole } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);
    
    const checkRoleClickBtn = (roleName: string, authRole: any, data: any) => {
        let isDisable = true;
        if (authRole.auth_role_map){
            if (authRole.auth_role_map[roleName + '-all']){
                isDisable = false;
            } else {
                if(authRole.auth_role_map[roleName + '-group']){
                    if(authRole.company_group_id == data.group_id){
                        isDisable = false;
                    }
                }
                if (authRole.auth_role_map[roleName + '-belong']){
                    if (authRole.company_id == data.customer_id)
                        isDisable = false;
                }
                if (authRole.auth_role_map[roleName + '-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == data.customer_id).length > 0)
                        isDisable = false;
                }
            }
        }
        return isDisable
    }

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        let isDisable = true;
        // 状態設定値
        if (props.colDef.field === 'pollingTopicDetail'){
            isDisable = btnController.isDisableGWStatusEditBtn;
        }
        // 装置編集
        if (props.colDef.field === 'editDevice'){
            isDisable = btnController.isDisableGWcrudEditBtn;
        }
        // 接点編集
        if (props.colDef.field === 'contact'){
            if (authRole !== undefined)
                isDisable = checkRoleClickBtn('GW-adview', authRole, props.data);
        }
        // 漏電編集
        if (props.colDef.field === 'leaks'){
            if (authRole !== undefined)
                isDisable = checkRoleClickBtn('GW-ebecview', authRole, props.data);
        }
        // デマンド編集
        if (props.colDef.field === 'demand'){
            if (authRole !== undefined)
                isDisable = checkRoleClickBtn('GW-demandview', authRole, props.data);
        }
        // 太陽光編集
        if (props.colDef.field === 'solar'){
            if (authRole !== undefined)
                isDisable = checkRoleClickBtn('GW-solarview', authRole, props.data);
        }
        // 通報編集
        if (props.colDef.field === 'notification'){
            if (authRole !== undefined)
                isDisable = checkRoleClickBtn('GW-notifyview', authRole, props.data);
        }

        // 帳票配信
        if (props.colDef.field === 'registReport'){
            if (authRole !== undefined)
                isDisable = checkRoleClickBtn('GW-demandreportview', authRole, props.data);
        }    

        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data.id, props.colDef.field);
        };
        return (
            <>
                <IconButton disabled={isDisable} aria-label="edit" onClick={handleEdit} style={{ marginLeft: '-10px' }} >
                    { isDisable ?
                        <EditIcon fontSize="small" sx={{ color: 'text.disabled', opacity: '0.5' }}/>
                        : <EditIcon fontSize="small"/>
                    }
                </IconButton>
            </>
        );
    };

    const cellRenderer = (props:any) => {
        if(props.value == "1"){
            return "〇"
        }
        return "";
    }

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword);
        const { selected, group_name } = props.data;
        if (btnController.isDisableGWcrudDeleteBtn){
            return (
                <FormControlLabel
                    control={<Typography>{group_name}</Typography>}
                    label=''
                />
            );
        } else {
            let isSelected = selected;
            if (props.data.isSelect) {
                isSelected = true;
            }
            return (
                <FormControlLabel
                    control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} onChange={() => handleChecked(props)} />}
                    label={group_name}
                    labelPlacement="end"
                />
            );
        }
    };

    const handleChecked = (props: any) => {
        onSelect(props.data)
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: `グループ名`,
            field: "group_name",
            rowDrag: false,
            width: colState ? colState[0].width : 300,
            cellRenderer: CellCheckRenderer,
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: colState ? colState[1].width : 300,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[2].width : 300,
        },
        {
            headerName: "管理組織名",
            field: "manage_customer_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[3].width : 300,
        },
        {
            headerName: "装置ID",
            field: "id",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[4].width : 180,
        },
        {
            headerName: "IPアドレス",
            field: "ip_address",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[5].width : 180,
        },
        {
            headerName: "パルス種別",
            field: "pulse_type_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[6].width : 180,
        },
        {
            headerName: "漏電緊急警報",
            field: "emg_alarm_leak",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            cellRenderer: cellRenderer,
            getQuickFilterText: (params:any) => {
                if(params.value == "1"){
                    return "〇"
                }
                return "";
            },
            width: colState ? colState[7].width : 120,
        },
        {
            headerName: "テスト用",
            field: "develop",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            cellRenderer: cellRenderer,
            getQuickFilterText: (params:any) => {
                if(params.value == "1"){
                    return "〇"
                }
                return "";
            },
            width: colState ? colState[8].width : 120,
        },
        {
            headerName: "状態設定値",
            field: "pollingTopicDetail",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[9].width : 120,
            sortable:false,
        },
        {
            headerName: "装置編集",
            field: "editDevice",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[10].width : 120,
            sortable:false,
        },
        {
            headerName: "接点編集",
            field: "contact",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[11].width : 120,
            sortable:false,
        },
        {
            headerName: "漏電編集",
            field: "leaks",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[12].width : 120,
            sortable:false,
        },
        {
            headerName: "デマンド編集",
            field: "demand",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[13].width : 120,
            sortable:false,
        },
        {
            headerName: "太陽光編集",
            field: "solar",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[14].width : 120,
            sortable:false,
        },
        {
            headerName: "通報編集",
            field: "notification",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[15].width : 120,
            sortable:false,
        },
        {
            headerName: "帳票配信",
            field: "registReport",
            resizable: false,
            cellRenderer: cellMessageRenderer,
            cellStyle: { textAlign: 'center' },
            width: colState ? colState[16].width : 120,
            sortable:false,
        }
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
              };
        }
        return {
            backgroundColor: 'white',
          };
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 275px)' : 'calc(100vh - 230px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        getRowStyle={getRowStyle}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default DeviceGrid;
