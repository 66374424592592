import CachedIcon from '@mui/icons-material/Cached';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { LoRaConnectionInfoModel } from '../../../models';

import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { showUnit } from '../../../utils';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 17;

const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setRefreshGroup: (refreshGroup: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
}

const fetchId = "LORA_CONNECTION_INFO_FETCH";

type LoRaConnectionInfoProps = {
    defaultData: LoRaConnectionInfoModel | undefined;
};

export type PureLoRaConnectionInfoProps = LoRaConnectionInfoProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

export const LoRaConnectionInfo = (props: PureLoRaConnectionInfoProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isRefreshGroup, isDisbleRefeshBtn } = props;
    const { onError, onRefresh, isRefreshAllCompleted, setDisabledRefreshGroupBtn, setRefreshGroup } = props;
    const [loRaConnectionInfo, setLoRaConnectionInfo] = React.useState<LoRaConnectionInfoModel>(fetchState.data);
    const [refreshFlg, setRefreshFlg] = React.useState(false);
    const [isRefreshSuccess, setIsRefreshSuccess] = React.useState(false);

    // パラメータ設定
    const handleFetchParams = () => {
        const params = new Map<string, any>();
        const code = "QN";
        params.set("code", code);
        params.set("mainid", mainid);
        return params;
    }

    // デマンド現在値取得成功時
    const handleFetchSuccess = (data: any, isClickedRefreshBtn: boolean) => {
        setRefreshFlg(false);
        const resData = {
            mainid: data.mainid,
            loraLedStatus: data.lora_led_status,
            loraKwhPluseStatus: data.lora_kwh_pluse_status,
            loraAi1Status: data.lora_ai1_status,
            loraAi2Status: data.lora_ai2_status,
            loraAi3Status: data.lora_ai3_status,
            loraAi4Status: data.lora_ai4_status,
            serialNumber: data.serial_number,
            mainBoardVer: data.main_board_ver,
            alarmBoardVer: data.alarm_board_ver,
            kwhPluseVer: data.kwh_pluse_ver,
            ai1Ver: data.ai_1_ver,
            ai2Ver: data.ai_2_ver,
            ai3Ver: data.ai_3_ver,
            ai4Ver: data.ai_4_ver,
            ackTime: data.ack_time,
            lastGetDatetime: data.last_get_datetime,
        } as LoRaConnectionInfoModel;
        setLoRaConnectionInfo(resData);
        setRefreshGroup(false, fetchId);
        isClickedRefreshBtn && setIsRefreshSuccess(true);
    };

    const handleError = (success: boolean, data: any, error: any) => {
        setRefreshFlg(false);
        onError(success, data, error);
        isRefreshAllCompleted(0);
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        setIsRefreshSuccess(false);
    }

    const handleRefresh = () => {
        setRefreshFlg(true);
        const params = handleFetchParams();
        onRefresh(params);
        setIsRefreshSuccess(false);
    };

    const handleRefreshSuccess = (data: any, fetchId: string) => {
        handleFetchSuccess(data, true);
        isRefreshAllCompleted(0);
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            console.log("end fetch all");
            handleRefresh();
        }
        if (isRefreshGroup) {
            handleRefresh();
            setIsRefreshSuccess(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setIsRefreshSuccess(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setRefreshFlg(true) : setRefreshFlg(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, isRefreshGroup]);

    React.useEffect(() => {
        setDisabledRefreshGroupBtn(refreshFlg, fetchId);
        // eslint-disable-next-line
    }, [refreshFlg]);

    React.useEffect(() => {
        defaultData && handleFetchSuccess(defaultData, false);
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={handleRefresh} disabled={isDisbleRefeshBtn || refreshFlg}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || refreshFlg ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='LoRa connect情報'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {(fetchState.loading || loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>LoRa LED接続状態</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>LoRa TJ3Pluse接続状態</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3A1 接続状態</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3A2 接続状態</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3A3 接続状態</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3A4 接続状態</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3-GW SerialNumber</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3-GW Version</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>LED警報器Version</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3 Pluse Version</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3AI 1 Version</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3AI 2 Version</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3AI 3 Version</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3AI 4 Version</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>再送回数</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>LoRa LED接続状態</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.loraLedStatus}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>LoRa TJ3Pluse接続状態</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.loraKwhPluseStatus}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3A1 接続状態</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.loraAi1Status}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3A2 接続状態</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.loraAi2Status}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3A3 接続状態</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.loraAi3Status}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3A4 接続状態</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.loraAi4Status}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3-GW SerialNumber</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.serialNumber}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3-GW Version</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.mainBoardVer}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>LED警報器Version</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.alarmBoardVer}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3 Pluse Version</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.kwhPluseVer}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3AI 1 Version</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.ai1Ver}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3AI 2 Version</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.ai2Ver}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3AI 3 Version</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.ai3Ver}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3AI 4 Version</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.ai4Ver}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>再送回数</td>
                                    <td style={pollingColStyle}>{loRaConnectionInfo?.ackTime}{showUnit(loRaConnectionInfo?.ackTime, "回")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{loRaConnectionInfo?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, fetchId) : handleError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onUpdate: (code: string, mainid: string, data: any) => actionCreators.fetch("UPDATE_LORA_SETTING", `/polling/?code=${code}&mainid=${mainid}`, 'PUT', data),
    onRefresh: (params: any) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "LoRa connect情報取得",
            body: "LoRa connect情報取得できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoRaConnectionInfo);