
export type Calendar1Props = {
    month: number,
    year?: number,
    mode: string
};

export const Calendar1 = (props: Calendar1Props) => {
    const { month } = props;
    const { year } = props;
    const { mode } = props;
    return (
        <>
            {mode === 'calendar1' && 
                <>
                    <tr>
                        <th className={'sticky-col col'} colSpan={3} rowSpan={1} style={{ minWidth: '60px', backgroundColor: '#e6b800', verticalAlign: 'middle'}}>{year}年{month}月</th>
                        {[...Array(24)].map((x, i) =>
                            <th rowSpan={2} style={{ minWidth: '60px', backgroundColor: "#e6b800"}}>{i.toString().padStart(2, '0')} h</th>
                        )}
                    </tr>
                    <tr>
                        <th className={'sticky-col first-col'} style={{ minWidth: '100px', backgroundColor: '#e6b800'}}>日合計</th>
                        <th className={'sticky-col second-col'} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>日</th>
                        <th className={'sticky-col third-col'} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>曜日</th>
                    </tr>
                </>
            }

            {mode === 'calendar2' && 
                <>
                    <tr>
                        <th className={'sticky-col col'} colSpan={3} rowSpan={1} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>{year}年{month}月</th>
                        {[...Array(24)].map((x, i) =>
                            <th colSpan={2} style={{ minWidth: '60px', backgroundColor: "#e6b800"}}>{i.toString().padStart(2, '0')} h</th>
                        )}
                    </tr>
                    <tr>
                        <th className={'sticky-col first-col'} style={{ minWidth: '100px', backgroundColor: '#e6b800',}}>日最大</th>
                        <th className={'sticky-col second-col'} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>日</th>
                        <th className={'sticky-col third-col'} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>曜日</th>
                        {[...Array(24)].map((x, i) =>
                            <>
                                <th style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>00</th>
                                <th style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>30</th>
                            </>
                        )}
                    </tr>
                </>
            }
            {mode === 'calendar3' && 
                <>
                    <tr>
                        <th className={'sticky-col col'} colSpan={3} rowSpan={1} style={{ minWidth: '60px', backgroundColor: '#e6b800', verticalAlign: 'middle'}}>{year}年{month}月</th>
                        {[...Array(24)].map((x, i) =>
                            <th rowSpan={2} style={{ minWidth: '60px', backgroundColor: "#e6b800"}}>{i.toString().padStart(2, '0')} h</th>
                        )}
                    </tr>
                    <tr>
                        <th className={'sticky-col first-col'} style={{ minWidth: '100px', backgroundColor: '#e6b800'}}>日合計</th>
                        <th className={'sticky-col second-col'} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>日</th>
                        <th className={'sticky-col third-col'} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>曜日</th>
                    </tr>
                </>
            }
        </>
    );
};

export default Calendar1;
