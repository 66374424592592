import { Button, FormControlLabel, Grid, Stack, Switch, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";

import CircularProgress from '@mui/material/CircularProgress';
import { push } from "connected-react-router";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import DeviceInformation from "../device/DeviceInformation";
import { Customer } from "../../../models";

// FETCH ID
const fetchId = "DEMANDS_FETCH_ID";
const menusFetchId = 'MENUS_FETCH';

// フォーム定義
interface EditDemandsForm {
    mainid: string,
    th1stKw: string,
    th2ndKw: string,
    th3rdKw: string,
    th1st_visible:boolean,
    th3rd_visible:boolean,
}

// 初期値設定
const initialValue: EditDemandsForm = {
    mainid: "",
    th1stKw: "",
    th2ndKw: "",
    th3rdKw: "",
    th1st_visible:false,
    th3rd_visible:false,
}

// -------------
// Props
export type DemandsDetailsProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const DemandsDetails = (props: DemandsDetailsProps) => {
    const { onError, onUpdate, onUpdateSuccess, onUpdateError, fetchStateMenu, onGetAuthRole  } = props;
    const urlParams = new URLSearchParams(window.location.search);
    localStorage.setItem('title', '監視装置');
    const [location] = React.useState(useLocation());
    const [hiddenOptions] = React.useState(location.state ? location.state.hiddenOptions : null);
    const mainid = urlParams.get('mainid');
    const [device, setDevice] = React.useState<any>();
    const { handleSubmit, setValue, control } = useForm<EditDemandsForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [loadingFlag, setLoadingFlag] = React.useState(true);

    let navigate = useNavigate();

    const [authRole, setAuthRole] = React.useState<any>();
    const [disableSaveBtn, setDisableSaveBtn] = React.useState(true);

    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        if (authRole !== undefined && device !== undefined){
            if (authRole.auth_role_map['GW-demandsave-all']){
                setDisableSaveBtn(false);
            } else {
                if (authRole.auth_role_map['GW-demandsave-belong']){
                    if (authRole.company_id == device.customer_id)
                        setDisableSaveBtn(false);
                }
                if (authRole.auth_role_map['GW-demandsave-group']){
                    if (authRole.company_group_id == device.group_id)
                        {
                            setDisableSaveBtn(false);
                        }
                }
                if (authRole.auth_role_map['GW-demandsave-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == device.customer_id).length > 0)
                        setDisableSaveBtn(false);
                }
            }
        }
    },[authRole, device])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    // Submitイベント
    const handleEditSubmit = (data: EditDemandsForm) => {
        setLoadingFlag(true);
        const request = createRequestData(data);
        onUpdate(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: EditDemandsForm) => {
        let request = {
            mainid: mainid,
            th1st_kw: formValue.th1stKw !== '' ? formValue.th1stKw : null,
            th2nd_kw: formValue.th2ndKw !== '' ? formValue.th2ndKw : null,
            th3rd_kw: formValue.th3rdKw !== '' ? formValue.th3rdKw : null,
            th1st_visible: formValue.th1st_visible ? "1" : "0",
            th2nd_visible: "1",
            th3rd_visible: formValue.th3rd_visible ? "1" : "0",
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        if(hiddenOptions){
            navigate(RoutePath.SettingDemandList);
            return
        }
        if(authRole){
            if(authRole.auth_role_map["LM-gateway-view"]){
                navigate(RoutePath.DeviceList);
                return;
            }
            if(authRole.auth_role_map["LM-smonitor-view"]){
                if(authRole.code_restriction_array?.includes("GADEMAND")){
                    navigate(RoutePath.SettingDemandList);
                    return;
                }
            }
        }
        navigate("/");
        return
    };

    // 取得正常終了時の初期表示
    const handleSuccess = (data: any) => {
        setValue('mainid', data.mainid);
        setValue('th1stKw', data.th1st_kw);
        setValue('th2ndKw', data.th2nd_kw);
        setValue('th3rdKw', data.th3rd_kw);
        setValue("th1st_visible",data.th1st_visible == "1" ? true : false)
        setValue("th3rd_visible",data.th3rd_visible == "1" ? true : false)
        setLoadingFlag(false);
    }
    

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid # ccc',
        boxShadow: '0 0 5px #fff, 0 0 5px #ccc, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '10px',
    }

    //タイトルの高さを戻
    React.useEffect(() => {
        return () => {
            let div: any = document.getElementsByClassName('css-ua3wu6')[0];
            if (typeof (div) !== 'undefined' && div != null) {
                div.removeAttribute("style");
            }
        };
    }, []);

    const handleFetchParam = (key: string) => {
        const params = new Map<string, any>();
        params.set(key, mainid);
        return params;
    }

    const handleUpdateSuccess = (data: any) => {
        const result = {
            resultCode: data.result_code,
            updateData: data.data,
        };
        if (result.resultCode === 1) {
            handleUpdateError();
        } else {
            onUpdateSuccess();
            setLoadingFlag(false);
        }
    }

    const handleUpdateError = () => {
        setLoadingFlag(false);
        onUpdateError();
    }

    const handleFetchDeviceSuccess = (data: any) => {
        setDevice(data)
    }

    return (
        <div style={{ margin: 'auto 10px' }}>
            <FetchHelper fetchId={'DEVICE_FETCH'}
                url={"/master/gateways/get"}
                method="GET"
                request={true}
                params={handleFetchParam('id')}
                onComplete={(success, data, error) => {
                    success ? handleFetchDeviceSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={fetchId}
                url={"/master/demands/get"}
                method="GET"
                request={true}
                params={handleFetchParam('mainid')}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error)
                }}
            />
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <DeviceInformation deviceInformation={device} mainid={mainid ?? ''} title="デマンド編集" isDisplayDeviceInfo={true} isShowMenuButton={hiddenOptions ? false : true} ></DeviceInformation>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={divStyles}>
                        <Stack spacing={3}>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={1} >
                                    <Grid item xs={0.3}></Grid>
                                    <Grid item xs={11.3} display={"flex"}>
                                        <Controller
                                            name="th1st_visible"
                                            control={control}
                                            render={({field:{onChange,value}}) => (
                                                <>
                                                    <FormControlLabel 
                                                        sx={{
                                                            display:"block",
                                                            minWidth:"100px"
                                                        }}
                                                        control={<Switch 
                                                        checked={value}
                                                        onChange={onChange}
                                                        />}
                                                        label="表示"
                                                        labelPlacement="end"
                                                    />
                                                </>
                                            )}
                                        />
                                        <Controller
                                            name="th1stKw"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="注意デマンド"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={0.3}></Grid>
                                    <Grid item xs={11.3} display={"flex"}>
                                        <FormControlLabel 
                                            sx={{
                                                display:"block",
                                                minWidth:"100px",
                                                opacity:"0"
                                            }}
                                            control={<Switch
                                            />}
                                            disabled={true}
                                            label="表示"
                                        />
                                        <Controller
                                            name="th2ndKw"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="限界デマンド"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={1} direction="row" >
                                    <Grid item xs={0.3}></Grid>
                                    <Grid item xs={11.3} display={"flex"}>
                                        <Controller
                                            name="th3rd_visible"
                                            control={control}
                                            render={({field:{onChange,value}}) => (
                                                <>
                                                    <FormControlLabel 
                                                        sx={{
                                                            display:"block",
                                                            minWidth:"100px"
                                                        }}
                                                        control={<Switch 
                                                        checked={value}
                                                        onChange={onChange}
                                                        />}
                                                        label="表示"
                                                    />
                                                </>
                                            )}
                                        />
                                        <Controller
                                            name="th3rdKw"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="契約電力"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} width="100%" margin="10px auto 0">
                        <Grid item xs={1.5}>
                            <Button fullWidth variant="contained" type="submit" disabled={disableSaveBtn}>保存</Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button fullWidth color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Grid>
                    </Stack>
                </div>
            </form >
            <FetchHelper fetchId="UPDATE_DEMAND" onComplete={(success, data, error) => success ? handleUpdateSuccess(data) : handleUpdateError()} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド情報",
            body: "デマンド情報が取得出来ません。",
        }),
    onCancel: () => push(RoutePath.DeviceList),
    onUpdate: (data: any) => actionCreators.fetch("UPDATE_DEMAND", `/master/demands/edit`, 'POST', data, false, true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "デマンド変更",
            body: "デマンドを更新しました。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド更新",
            body: "デマンドを更新出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemandsDetails as any);