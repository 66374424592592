import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

// -------------
// Props
export type Page2Props = {
  onRefreshGrid: () => void;
};

const Page = (props: Page2Props) => {
    const { onRefreshGrid } = props;
    const [seconds, setSeconds ] =  useState(5);
    const [secondsShow, setSecondsShow ] =  useState(5);
    const [stopFlag, setStopFlag ] =  useState(false);
    useEffect(()=>{
        let temp = seconds;
        let myInterval = setInterval(() => {
            if (temp > 0) {
                temp--;
            }
            else if (!stopFlag){
                clearInterval(myInterval);
            }
            else {
                onRefreshGrid();
                temp = seconds
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    useEffect(()=>{
        setStopFlag(false);
    },[secondsShow]);

    const HandleAutoUpdate = (flag: boolean) => {
        setSeconds(secondsShow);
        setStopFlag(flag)
    }
  
    const onSetSeconds = () => {
        onRefreshGrid();
    }

    const onChange = (value: any) => {
        if(value.trim() === ""){
            setSecondsShow(NaN)
        }
        else if(Number(value) !== 0){
            setSecondsShow(parseInt(Number(value).toString()))
        }
    }

    const onBlur = (value: any) => {
        if(value.trim() === ""){
            setSecondsShow(5)
        }
    }
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Stack direction="row">
                <div style={{marginInlineStart: 'auto'}}>
                    <Stack spacing={1}  direction="row">
                    <Button variant={stopFlag ? "contained" : "outlined"}  size="small" style={{marginBlock: 'auto'}} onClick={e =>HandleAutoUpdate(true)}>自動更新ON</Button>
                    <Button variant={!stopFlag ? "contained" : "outlined"} size="small" style={{marginBlock: 'auto'}} onClick={e =>HandleAutoUpdate(false)}>自動更新OFF</Button>
                    <TextField InputProps={{readOnly: stopFlag}} type="number" label = "更新間隔" size="small" variant="outlined" value={secondsShow}  onChange={(e:any)=>onChange(e.target.value)} inputProps={{style: { width: '100px' }, min: 1}} onBlur={(e:any)=>onBlur(e.target.value)}/>
                    <p style={{marginBlock: 'auto'}}>(秒)</p>
                    <IconButton aria-label="edit" onClick={onSetSeconds} >
                        <CachedIcon fontSize="medium" style={{ color: '#1890ff' }} />
                    </IconButton>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
};

export default Page;
