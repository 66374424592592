import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography, Avatar, IconButton, Grid } from '@mui/material';
// components
import MenuPopover from './MenuPopover';
import { actionCreators, ApplicationState, AppState } from '../store';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------
interface AccountPopoverActions {
    onLogout: () => void;
}

export type AccountPopoverProps =
    AppState &
    AccountPopoverActions;

export const AccountPopover = (props: AccountPopoverProps) => {
    const { user, onLogout } = props;
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    let navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePassword = () => {
        navigate('/changepass');
    }

    return (
        <>
            <Grid container display='flex' justifyContent={'center'}>
                <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                    <Avatar src={"/assets/acount.svg"} />
                </IconButton>
                <Typography margin={'auto'} marginLeft={'6px'}>{user?.family_name} {user?.given_name}</Typography>
            </Grid>
            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.family_name} {user?.given_name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button fullWidth color="inherit" variant="outlined"
                        onClick={handleChangePassword}>
                        パスワード変更
                    </Button>
                </Box>

                <Box sx={{ p: 2, pt: 1 }}>
                    <Button fullWidth color="inherit" variant="outlined"
                        onClick={onLogout}>
                        ログアウト
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}

export default connect(
    (state: ApplicationState) => state.app,
    dispatch => ({
        onLogout: () => {
            dispatch(actionCreators.clearSession())
        }
    } as AccountPopoverActions)
)(AccountPopover as any)