import { Button, TextField } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import {
    actionCreators,
    ApplicationState
} from "../store";
import TextSelector from "./common/TextSelector";
import FetchHelper from "./FetchHelper";


let methodType = [
    {
        value: "GET",
        label: "GET",
    },
    {
        value: "PUT",
        label: "PUT",
    },
    {
        value: "POST",
        label: "POST",
    },
    {
        value: "DELETE",
        label: "DELETE",
    },
];
// -------------
// Props
export type CheckAPIsProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const CheckAPIs = (props: CheckAPIsProps) => {
    const { onGET,onPUT, onPOST,onDELETE, onError } = props;
    const [ strLink, setStrLink] = React.useState('');
    const [ strResult, setStrResult] = React.useState('');
    const [ strMethod, setStrMethod] = React.useState("GET");
    const [methodTypeList] = React.useState<any>(methodType);

    // 検索正常終了時
    const handleSuccess = (data: any) => {
        setStrResult(JSON.stringify(data, null, 2));
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        setStrResult("");
        onError(success, data, error);
    };

    // 条件変更時にAPI検索する
    const handleChangeTextField= (data: any) => {
        setStrLink(data);
    };

    const handleChangeTextSelector= (data: any) => {
        setStrMethod(data.target.value);
    };

    const check = () => {
        let targetURL = process.env.REACT_APP_APIGW_URL + "/";
        let value = strLink.replace(targetURL, "");
        if(value){
            if(strMethod === "GET"){
                onGET(value);
            }
            else if(strMethod === "PUT"){
                onPUT(value);
            }
            else if(strMethod === "POST"){
                onPOST(value);
            }
            else if(strMethod === "DELETE"){
                onDELETE(value);
            }
        }
        else{
            setStrResult("");
        }
    }

    return (
        <>
            <div style={{ height: "100%", width: "50%" }}>
                <div style={{padding: '50px'}}>
                    <TextField fullWidth multiline size="small" label={"Link API"} value={strLink}  onChange={e => handleChangeTextField(e.target.value)}/>
                    <div style={{width:"50%", display: "flex", flexDirection: "row", marginTop: "10px", marginBottom: "10px"}}>
                        <TextSelector
                            label="HTTP Methods"
                            options={methodTypeList}
                            onChange={handleChangeTextSelector}
                            value={strMethod}
                        />
                        <Button variant={"contained"} size="small"  onClick={check}>check</Button>
                    </div>
                    <TextField fullWidth multiline size="small" label={"Result"} value={strResult} />
                </div>
            </div>
            <FetchHelper fetchId={"check"} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = {
    onGET: (param: string) =>
        actionCreators.fetch("check", '/' + param, "GET", null),
    onPUT: (param: string) =>
        actionCreators.fetch("check", '/' + param, "PUT", null),
    onPOST: (param: string) =>
        actionCreators.fetch("check", '/' + param, "POST", null),
    onDELETE: (param: string) =>
        actionCreators.fetch("check", '/' + param, "DELETE", null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "遠方監視管理一覧検索",
            body: "遠方監視管理一覧を検索できません。" + error,
        }),
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckAPIs as any);
