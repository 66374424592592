import * as React from "react";
import { connect } from "react-redux";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import './styles.css';

import CachedIcon from '@mui/icons-material/Cached';
import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { push } from "connected-react-router";
import { useNavigate, useParams } from "react-router";
import palette from '../../../theme/palette';
import Cpn4GLteSetting from "./4GLteSetting";
import ConfirmTime from "./ConfirmTime";
import DemandCurrentData from "./DemandCurrentData";
import DemandSettingData from "./DemandSettingData";
import DeviceName from "./DeviceName";
import ExcessHeavyLeakageCurrent from "./ExcessHeavyLeakageCurrent";
import ExcessLightLeakageCurrent from "./ExcessLightLeakageCurrent";
import HeavyEarthLeakageSetting from "./HeavyEarthLeakageSetting";
import LightLeakageSetting from "./LightLeakageSetting";
import LocationName from "./LocationName";
import LoRaConnectionInfo from "./LoRaConnectionInfo";
import LoRaConnectionStatus from "./LoRaConnectionStatus";
import LoRaSetting from "./LoRaSetting";
import MaximumEarthLeakage from "./MaximumEarthLeakage";
import PhaseAngleInformation from "./PhaseAngleInformation";
import SettingInformation from "./SettingInformation";
import DemandChangeStartEnd from "./DemandChangeStartEnd";
import LeakageInformation from "./LeakageInformation";
import FetchHelper from "../../FetchHelper";
import { ConfirmTimeModel, Cpn4GLteSettingModel, DemandCurrentDataModel, DemandSettingDataModel, DeviceNameModel, ExcessHeavyLeakageCurrentModel, ExcessLightLeakageCurrentModel, HeavyEarthLeakageSettingModel, LeakageInformationModel, LightLeakageSettingModel, LoRaConnectionInfoModel, LoRaConnectionStatusModel, LoRaSettingModel, LocationNameModel, MaximumEarthLeakageModel, PhaseAngleInformationModel, SettingInformationModel } from "../../../models";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { PollingMenuOptions } from "../../../statics";
import { deviceBackgroundStyles, deviceContentStyles } from "../../styles";
import DeviceInformation from "../device/DeviceInformation";
import ChangeMeasurentStartedEnd from "./ChangeMeasurentStartedEnd";


const menusFetchId = 'MENUS_FETCH';

const demandCurrentDataFetchId = "DEMAND_CURRENT_DATA_FETCH";
const demandSettingDataFetchId = "DEMAND_SETTING_DATA_FETCH";
const loRaConnectionStatusFetchId = "LORA_CONNECTION_STATUS_FETCH";
const leakageInformationFetchId = "LEAKAGE_INFORMATION_FETCH";
const lightLeakageSettingFetchId = "LIGHT_LEAKAGE_SETTING_FETCH";
const heavyEarthLeakageSettingFetchId = "HEAVY_EARTH_LEAKAGE_SETTING_FETCH";
const confirmTimeFetchId = "CONFIRM_TIME_FETCH";
const settingInformationFetchId = "SETTING_INFORMATION_FETCH";
const phaseAngleInformationFetchId = "PHASE_ANGLE_INFORMATION_FETCH";
const excessLightLeakageCurrentFetchId = "EXCESS_LIGHT_LEAKAGE_CURRENT_FETCH";
const excessHeavyLeakageCurrentFetchId = "EXCESS_HEAVY_LEAKAGE_CURRENT_FETCH";
const maximumEarthLeakageFetchId = "MAXIMUM_EARTH_LEAKAGE_FETCH";
const deviceNameFetchId = "DEVICE_NAME_FETCH";
const locationNameFetchId = "LOCATION_NAME_FETCH";
const loRaSettingFetchId = "LORA_SETTING_FETCH";
const data4GLteSettingFetchId = "DATA_4G_LTE_SETTING_FETCH";
const loRaConnectionInfoFetchId = "LORA_CONNECTION_INFO_FETCH";
// -------------
// Props
export type PollingTopicDetailsProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const PollingTopicDetails = (props: PollingTopicDetailsProps) => {
    let { id } = useParams();
    let navigate = useNavigate();
    const isVisibleRefreshAllButton = process.env.REACT_APP_POLLING_ALL_UPDATE_BUTTON_VISIBLE;
    const { onError, fetchStateMenu, onGetAuthRole } = props;
    //遷移ボタンのdivタグのref変数
    const myRef1 = React.useRef<any>(null);
    const myRef2 = React.useRef<any>(null);
    const myRef3 = React.useRef<any>(null);
    const myRef4 = React.useRef<any>(null);
    const myRef5 = React.useRef<any>(null);
    const myRef6 = React.useRef<any>(null);
    const myRef7 = React.useRef<any>(null);
    const myRef8 = React.useRef<any>(null);
    const myRef9 = React.useRef<any>(null);
    const myRef10 = React.useRef<any>(null);
    const myRef11 = React.useRef<any>(null);
    const myRef12 = React.useRef<any>(null);
    const myRef13 = React.useRef<any>(null);
    const myRef14 = React.useRef<any>(null);
    const myRef15 = React.useRef<any>(null);
    const myRef16 = React.useRef<any>(null);
    const myRef17 = React.useRef<any>(null);
    const myRef18 = React.useRef<any>(null);
    const myRef19 = React.useRef<any>(null);

    const [activeRef, setActiveRef] = React.useState(1);
    const [isOnScroll, setIsOnScroll] = React.useState(false);
    const [loadingRefreshAll, setLoadingRefreshAll] = React.useState<any>(new Array(17).fill('').map(() => false));
    const [isEdit, setIsEdit] = React.useState(false);
    const [device, setDevice] = React.useState<any>();
    const [isLoadingForm, setIsLoadingForm] = React.useState(true);
    const [countFetch, setCountFetch] = React.useState(0);

    const [disableAllRefeshButton, setDisableAllRefeshButton] = React.useState(true);
    const [disableAllUpdateButton, setDisableAllUpdateButton] = React.useState(true);
    
    React.useEffect(() => {
        if(fetchStateMenu.data){
            setDisableAllRefeshButton(!fetchStateMenu.data.auth_role_map['GW-gwstatus-get'] ?? true)
            setDisableAllUpdateButton(!fetchStateMenu.data.auth_role_map['GW-gwstatus-update'] ?? true)
        } else {
            onGetAuthRole()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            setDisableAllRefeshButton(!data.auth_role_map['GW-gwstatus-get'] ?? true)
            setDisableAllUpdateButton(!data.auth_role_map['GW-gwstatus-update'] ?? true)
        }
    }

    //移動関数
    const scrollTo = (value: String) => {
        if (value === "デマンド現在値") {
            myRef1.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(1);
        }
        if (value === "デマンド設定値") {
            myRef2.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(2);
        }
        if (value === "デマンド開始終了変更") {
            myRef3.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(3);
        }

        if(value === "測定開始終了変更"){
            myRef4.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(4);
        }

        if (value === "LoRa接続状態") {
            myRef5.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(5);
        }
        if (value === "漏電情報") {
            setActiveRef(6);
            myRef6.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "軽漏電設定") {
            setActiveRef(7);
            myRef7.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "重漏電設定") {
            setActiveRef(8);
            myRef8.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "時間確認") {
            setActiveRef(9);
            myRef9.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "Io/Ior設定情報") {
            setActiveRef(10);
            myRef10.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "PhaseAngle情報") {
            setActiveRef(11);
            myRef11.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "軽漏電流超過・復帰時間") {
            setActiveRef(12);
            myRef12.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "重漏電流超過・復帰時間") {
            setActiveRef(13);
            myRef13.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "最大漏電") {
            setActiveRef(14);
            myRef14.current.scrollIntoView({ behavior: 'auto' });
        }
        if (value === "DeviceName") {
            setActiveRef(15);
            myRef15.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "LocationName") {
            setActiveRef(16);
            myRef16.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "LoRa設定") {
            myRef17.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(17);
            setIsOnScroll(true);
        }
        if (value === "4G LTE設定") {
            myRef18.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(18);
            setIsOnScroll(true);
        }
        if (value === "LoRa connect情報") {
            myRef19.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(19);
            setIsOnScroll(true);
        }
    }

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        let offsetTop = event.currentTarget.scrollTop + myRef1.current.offsetTop;
        if (isOnScroll) {
            setIsOnScroll(false);
            return;
        }
        if (offsetTop < myRef2.current.offsetTop) {
            setActiveRef(1);
        } else if (offsetTop >= myRef2.current.offsetTop && offsetTop < myRef3.current.offsetTop) {
            setActiveRef(2);
        } else if (offsetTop >= myRef3.current.offsetTop && offsetTop < myRef4.current.offsetTop) {
            setActiveRef(3);
        } else if (offsetTop >= myRef4.current.offsetTop && offsetTop < myRef5.current.offsetTop) {
            setActiveRef(4);
        } else if (offsetTop >= myRef5.current.offsetTop && offsetTop < myRef6.current.offsetTop) {
            setActiveRef(5);
        } else if (offsetTop >= myRef6.current.offsetTop && offsetTop < myRef7.current.offsetTop) {
            setActiveRef(6);
        } else if (offsetTop >= myRef7.current.offsetTop && offsetTop < myRef8.current.offsetTop) {
            setActiveRef(7);
        } else if (offsetTop >= myRef8.current.offsetTop && offsetTop < myRef9.current.offsetTop) {
            setActiveRef(8);
        } else if (offsetTop >= myRef9.current.offsetTop && offsetTop < myRef10.current.offsetTop) {
            setActiveRef(9);
        } else if (offsetTop >= myRef10.current.offsetTop && offsetTop < myRef11.current.offsetTop) {
            setActiveRef(10);
        } else if (offsetTop >= myRef11.current.offsetTop && offsetTop < myRef12.current.offsetTop) {
            setActiveRef(11);
        } else if (offsetTop >= myRef12.current.offsetTop && offsetTop < myRef13.current.offsetTop) {
            setActiveRef(12);
        } else if (offsetTop >= myRef13.current.offsetTop && offsetTop < myRef14.current.offsetTop) {
            setActiveRef(13);
        } else if (offsetTop >= myRef14.current.offsetTop && offsetTop < myRef15.current.offsetTop) {
            setActiveRef(14);
        } else if (offsetTop >= myRef15.current.offsetTop && offsetTop < myRef16.current.offsetTop) {
            setActiveRef(15);
        } else if (offsetTop >= myRef16.current.offsetTop && offsetTop < myRef17.current.offsetTop) {
            setActiveRef(16);
        } else if (offsetTop >= myRef17.current.offsetTop && offsetTop < myRef18.current.offsetTop) {
            setActiveRef(17);
        } else if (offsetTop >= myRef18.current.offsetTop && offsetTop < myRef19.current.offsetTop) {
            setActiveRef(18);
        } else{
            setActiveRef(19);
        }
    }

    // const handleMouseOver = (e: any) => {
    //     e.target.style.color = '#1890ff';
    // }
    // const handleMouseOut = (e: any) => {
    //     e.target.style.color = 'black';
    // }

    const liStyles = {
        cursor: 'pointer',
        width: '80%',
        marginBottom: '3px',
        color: '#1890ff',
        backgroundColor: palette.action.hover,
        alignItems: 'center',
    }

    const liStylesNormal = {
        cursor: 'pointer',
        width: '80%',
        marginBottom: '3px',
    }

    const menuStyles = {
        width: '85%',
        display: 'flex',
        justifyContent: 'space-between',
    }

    const [numberRefreshAll, setNumberRefreshAll] = React.useState(0);
    const handleRefresh = () => {
        setNumberRefreshAll(1);
        setLoadingRefreshAll(loadingRefreshAll.map(() => true));
    };

    const handleRefreshGroup = (fetchId: string, scrollToName: string) => {
        scrollTo(scrollToName);
        switch (fetchId) {
            case demandCurrentDataFetchId:
                setDemandCurrentDataRefresh(true);
                break;
            case demandSettingDataFetchId:
                setDemandSettingDataRefresh(true);
                break;
            case loRaConnectionStatusFetchId:
                setLoRaConnectionStatusRefresh(true);
                break;
            case leakageInformationFetchId:
                setLeakageInformationRefresh(true);
                break;
            case lightLeakageSettingFetchId:
                setLightLeakageSettingRefresh(true);
                break;
            case heavyEarthLeakageSettingFetchId:
                setHeavyEarthLeakageSettingRefresh(true);
                break;
            case confirmTimeFetchId:
                setConfirmTimeRefresh(true);
                break;
            case settingInformationFetchId:
                setSettingInformationRefresh(true);
                break;
            case phaseAngleInformationFetchId:
                setPhaseAngleInformationRefresh(true);
                break;
            case excessLightLeakageCurrentFetchId:
                setExcessLightLeakageCurrentRefresh(true);
                break;
            case excessHeavyLeakageCurrentFetchId:
                setExcessHeavyLeakageCurrentRefresh(true);
                break;
            case maximumEarthLeakageFetchId:
                setMaximumEarthLeakageRefresh(true);
                break;
            case deviceNameFetchId:
                setDeviceNameRefresh(true);
                break;
            case locationNameFetchId:
                setLocationNameRefresh(true);
                break;
            case loRaSettingFetchId:
                setLoRaSettingRefresh(true);
                break;
            case data4GLteSettingFetchId:
                setData4GLteSettingRefresh(true);
                break;
            case loRaConnectionInfoFetchId:
                setLoRaConnectionInfoRefresh(true);
                break;
        }
    }

    // キャンセル
    const handleCancel = () => navigate(RoutePath.DeviceList);

    const handleRefreshAll = (no: number) => {
        setNumberRefreshAll(no);
    }

    const disabledRefreshAllBtnStyle = {
        fontSize: 24,
        color: 'gray',
    }

    const enableRefreshAllBtnStyle = {
        fontSize: 24,
        color: '#1890ff',
    }

    const handleSetIsEdit = (isEditRes: boolean) => {
        setIsEdit(isEditRes);
    }

    const [disabledRefreshAll, setDisabledRefreshAll] = React.useState<boolean>(false);
    const [disabledDemandCurrentDataRefresh, setDisabledDemandCurrentDataRefresh] = React.useState<boolean>(false);
    const [disabledDemandSettingDataRefresh, setDisabledDemandSettingDataRefresh] = React.useState<boolean>(false);
    const [disabledLoRaConnectionStatusRefresh, setDisabledLoRaConnectionStatusRefresh] = React.useState<boolean>(false);
    const [disabledLeakageInformationRefresh, setDisabledLeakageInformationRefresh] = React.useState<boolean>(false);
    const [disabledLightLeakageSettingRefresh, setDisabledLightLeakageSettingRefresh] = React.useState<boolean>(false);
    const [disabledHeavyEarthLeakageSettingRefresh, setDisabledHeavyEarthLeakageSettingRefresh] = React.useState<boolean>(false);
    const [disabledConfirmTimeRefresh, setDisabledConfirmTimeRefresh] = React.useState<boolean>(false);
    const [disabledSettingInformationRefresh, setDisabledSettingInformationRefresh] = React.useState<boolean>(false);
    const [disabledPhaseAngleInformationRefresh, setDisabledPhaseAngleInformationRefresh] = React.useState<boolean>(false);
    const [disabledExcessLightLeakageCurrentRefresh, setDisabledExcessLightLeakageCurrentRefresh] = React.useState<boolean>(false);
    const [disabledExcessHeavyLeakageCurrentRefresh, setDisabledExcessHeavyLeakageCurrentRefresh] = React.useState<boolean>(false);
    const [disabledMaximumEarthLeakageRefresh, setDisabledMaximumEarthLeakageRefresh] = React.useState<boolean>(false);
    const [disabledDeviceNameRefresh, setDisabledDeviceNameRefresh] = React.useState<boolean>(false);
    const [disabledLocationNameRefresh, setDisabledLocationNameRefresh] = React.useState<boolean>(false);
    const [disabledLoRaSettingRefresh, setDisabledLoRaSettingRefresh] = React.useState<boolean>(false);
    const [disabledData4GLteSettingRefresh, setDisabledData4GLteSettingRefresh] = React.useState<boolean>(false);
    const [disabledLoRaConnectionInfoRefresh, setDisabledLoRaConnectionInfoRefresh] = React.useState<boolean>(false);

    const handleSetDisabledRefreshGroupBtn = (isDisabled: boolean, name: string) => {
        switch (name) {
            case demandCurrentDataFetchId:
                setDisabledDemandCurrentDataRefresh(isDisabled);
                break;
            case demandSettingDataFetchId:
                setDisabledDemandSettingDataRefresh(isDisabled);
                break;
            case loRaConnectionStatusFetchId:
                setDisabledLoRaConnectionStatusRefresh(isDisabled);
                break;
            case leakageInformationFetchId:
                setDisabledLeakageInformationRefresh(isDisabled);
                break;
            case lightLeakageSettingFetchId:
                setDisabledLightLeakageSettingRefresh(isDisabled);
                break;
            case heavyEarthLeakageSettingFetchId:
                setDisabledHeavyEarthLeakageSettingRefresh(isDisabled);
                break;
            case confirmTimeFetchId:
                setDisabledConfirmTimeRefresh(isDisabled);
                break;
            case settingInformationFetchId:
                setDisabledSettingInformationRefresh(isDisabled);
                break;
            case phaseAngleInformationFetchId:
                setDisabledPhaseAngleInformationRefresh(isDisabled);
                break;
            case excessLightLeakageCurrentFetchId:
                setDisabledExcessLightLeakageCurrentRefresh(isDisabled);
                break;
            case excessHeavyLeakageCurrentFetchId:
                setDisabledExcessHeavyLeakageCurrentRefresh(isDisabled);
                break;
            case maximumEarthLeakageFetchId:
                setDisabledMaximumEarthLeakageRefresh(isDisabled);
                break;
            case deviceNameFetchId:
                setDisabledDeviceNameRefresh(isDisabled);
                break;
            case locationNameFetchId:
                setDisabledLocationNameRefresh(isDisabled);
                break;
            case loRaSettingFetchId:
                setDisabledLoRaSettingRefresh(isDisabled);
                break;
            case data4GLteSettingFetchId:
                setDisabledData4GLteSettingRefresh(isDisabled);
                break;
            case loRaConnectionInfoFetchId:
                setDisabledLoRaConnectionInfoRefresh(isDisabled);
                break;

        }
    }

    const [demandCurrentDataRefresh, setDemandCurrentDataRefresh] = React.useState<boolean>(false);
    const [demandSettingDataRefresh, setDemandSettingDataRefresh] = React.useState<boolean>(false);
    const [loRaConnectionStatusRefresh, setLoRaConnectionStatusRefresh] = React.useState<boolean>(false);
    const [leakageInformationRefresh, setLeakageInformationRefresh] = React.useState<boolean>(false);
    const [lightLeakageSettingRefresh, setLightLeakageSettingRefresh] = React.useState<boolean>(false);
    const [heavyEarthLeakageSettingRefresh, setHeavyEarthLeakageSettingRefresh] = React.useState<boolean>(false);
    const [confirmTimeRefresh, setConfirmTimeRefresh] = React.useState<boolean>(false);
    const [settingInformationRefresh, setSettingInformationRefresh] = React.useState<boolean>(false);
    const [phaseAngleInformationRefresh, setPhaseAngleInformationRefresh] = React.useState<boolean>(false);
    const [excessLightLeakageCurrentRefresh, setExcessLightLeakageCurrentRefresh] = React.useState<boolean>(false);
    const [excessHeavyLeakageCurrentRefresh, setExcessHeavyLeakageCurrentRefresh] = React.useState<boolean>(false);
    const [maximumEarthLeakageRefresh, setMaximumEarthLeakageRefresh] = React.useState<boolean>(false);
    const [deviceNameRefresh, setDeviceNameRefresh] = React.useState<boolean>(false);
    const [locationNameRefresh, setLocationNameRefresh] = React.useState<boolean>(false);
    const [loRaSettingRefresh, setLoRaSettingRefresh] = React.useState<boolean>(false);
    const [data4GLteSettingRefresh, setData4GLteSettingRefresh] = React.useState<boolean>(false);
    const [loRaConnectionInfoRefresh, setLoRaConnectionInfoRefresh] = React.useState<boolean>(false);
    const [demandChangeStartEnd, setDemandChangeStartEnd] = React.useState<boolean>(false);

    const handleSetDisabledRefreshAll = (isDisabled: boolean, name: string) => {
        switch (name) {
            case demandCurrentDataFetchId:
                setDemandCurrentDataRefresh(isDisabled);
                break;
            case demandSettingDataFetchId:
                setDemandSettingDataRefresh(isDisabled);
                break;
            case loRaConnectionStatusFetchId:
                setLoRaConnectionStatusRefresh(isDisabled);
                break;
            case leakageInformationFetchId:
                setLeakageInformationRefresh(isDisabled);
                break;
            case lightLeakageSettingFetchId:
                setLightLeakageSettingRefresh(isDisabled);
                break;
            case heavyEarthLeakageSettingFetchId:
                setHeavyEarthLeakageSettingRefresh(isDisabled);
                break;
            case confirmTimeFetchId:
                setConfirmTimeRefresh(isDisabled);
                break;
            case settingInformationFetchId:
                setSettingInformationRefresh(isDisabled);
                break;
            case phaseAngleInformationFetchId:
                setPhaseAngleInformationRefresh(isDisabled);
                break;
            case excessLightLeakageCurrentFetchId:
                setExcessLightLeakageCurrentRefresh(isDisabled);
                break;
            case excessHeavyLeakageCurrentFetchId:
                setExcessHeavyLeakageCurrentRefresh(isDisabled);
                break;
            case maximumEarthLeakageFetchId:
                setMaximumEarthLeakageRefresh(isDisabled);
                break;
            case deviceNameFetchId:
                setDeviceNameRefresh(isDisabled);
                break;
            case locationNameFetchId:
                setLocationNameRefresh(isDisabled);
                break;
            case loRaSettingFetchId:
                setLoRaSettingRefresh(isDisabled);
                break;
            case data4GLteSettingFetchId:
                setData4GLteSettingRefresh(isDisabled);
                break;
            case loRaConnectionInfoFetchId:
                setLoRaConnectionInfoRefresh(isDisabled);
                break;

        }
    }

    const handleDisabledRefreshAll = () => {
        (!disabledDemandCurrentDataRefresh && !disabledDemandSettingDataRefresh && !disabledLoRaConnectionStatusRefresh && !disabledLeakageInformationRefresh && !disabledLightLeakageSettingRefresh
            && !disabledHeavyEarthLeakageSettingRefresh && !disabledConfirmTimeRefresh && !disabledSettingInformationRefresh && !disabledPhaseAngleInformationRefresh
            && !disabledExcessLightLeakageCurrentRefresh && !disabledExcessHeavyLeakageCurrentRefresh && !disabledMaximumEarthLeakageRefresh && !disabledDeviceNameRefresh
            && !disabledLocationNameRefresh && !disabledLoRaSettingRefresh && !disabledData4GLteSettingRefresh && !disabledLoRaConnectionInfoRefresh) && !demandChangeStartEnd ?
            setDisabledRefreshAll(false) :
            setDisabledRefreshAll(true);
    }
    React.useEffect(handleDisabledRefreshAll, [disabledDemandCurrentDataRefresh, disabledDemandSettingDataRefresh, disabledLoRaConnectionStatusRefresh, disabledLeakageInformationRefresh,
        disabledLightLeakageSettingRefresh, disabledHeavyEarthLeakageSettingRefresh, disabledConfirmTimeRefresh, disabledSettingInformationRefresh, disabledPhaseAngleInformationRefresh
        , disabledExcessLightLeakageCurrentRefresh, disabledExcessHeavyLeakageCurrentRefresh, disabledMaximumEarthLeakageRefresh, disabledDeviceNameRefresh
        , disabledLocationNameRefresh, disabledLoRaSettingRefresh, disabledData4GLteSettingRefresh, disabledLoRaConnectionInfoRefresh, demandChangeStartEnd]);

    const handleFetchParam = (param: string, value: any) => {
        const params = new Map<string, any>();
        params.set(param, value);
        return params;
    }

    const handleFetchDeviceSuccess = (data: any) => {
        if (device?.customer_id !== data.customer_id) {
            setDevice(data);
            setCountFetch(countFetch + 1);
        }
    }

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setCountFetch(countFetch + 1);
    }

    const [demandCurrentData, setDemandCurrentData] = React.useState<DemandCurrentDataModel>();
    const [demandSettingData, setDemandSettingData] = React.useState<DemandSettingDataModel>();
    const [loRaConnectionStatus, setLoRaConnectionStatus] = React.useState<LoRaConnectionStatusModel>();
    const [leakageInformation, setLeakageInformation] = React.useState<LeakageInformationModel[]>();
    const [lightLeakageSetting, setLightLeakageSetting] = React.useState<LightLeakageSettingModel[]>();
    const [heavyEarthLeakageSetting, setHeavyEarthLeakageSetting] = React.useState<HeavyEarthLeakageSettingModel[]>();
    const [confirmTime, setConfirmTime] = React.useState<ConfirmTimeModel[]>();
    const [settingInformation, setSettingInformation] = React.useState<SettingInformationModel[]>();
    const [phaseAngleInformation, setPhaseAngleInformation] = React.useState<PhaseAngleInformationModel[]>();
    const [excessLightLeakageCurrent, setExcessLightLeakageCurrent] = React.useState<ExcessLightLeakageCurrentModel[]>();
    const [excessHeavyLeakageCurrent, setExcessHeavyLeakageCurrent] = React.useState<ExcessHeavyLeakageCurrentModel[]>();
    const [maximumEarthLeakage, setMaximumEarthLeakage] = React.useState<MaximumEarthLeakageModel[]>();
    const [deviceName, setDeviceName] = React.useState<DeviceNameModel[]>();
    const [locationName, setLocationName] = React.useState<LocationNameModel[]>();
    const [loRaSetting, setLoRaSetting] = React.useState<LoRaSettingModel[]>();
    const [data4GLteSetting, setData4GLteSetting] = React.useState<Cpn4GLteSettingModel[]>();
    const [loRaConnectionInfo, setLoRaConnectionInfo] = React.useState<LoRaConnectionInfoModel>();
    const [measurementInfo, setMeasurementInfo] = React.useState<any>();
    const [demandStartEndInfo, setDemandStartEndInfo] = React.useState<any>();
    
    const handleFetchSuccess = (data: any) => {
        if (data.polling_info_array_count > 0) {
            const datas = handleDefaultData(data.polling_info_array);
            setDemandCurrentData(datas.get('demandCurrentData'));
            setDemandSettingData(datas.get('demandSettingData'));
            setLoRaConnectionStatus(datas.get('loRaConnectionStatus'));
            setLeakageInformation(datas.get('leakageInformation'));
            setLightLeakageSetting(datas.get('lightLeakageSetting'));
            setHeavyEarthLeakageSetting(datas.get('heavyEarthLeakageSetting'));
            setConfirmTime(datas.get('confirmTime'));
            setSettingInformation(datas.get('settingInformation'));
            setPhaseAngleInformation(datas.get('phaseAngleInformation'));
            setExcessLightLeakageCurrent(datas.get('excessLightLeakageCurrent'));
            setExcessHeavyLeakageCurrent(datas.get('excessHeavyLeakageCurrent'));
            setMaximumEarthLeakage(datas.get('maximumEarthLeakage'));
            setDeviceName(datas.get('deviceName'));
            setLocationName(datas.get('locationName'));
            setLoRaSetting(datas.get('loRaSetting'));
            setData4GLteSetting(datas.get('data4GLteSetting'));
            setLoRaConnectionInfo(datas.get('loRaConnectionInfo'));
            setMeasurementInfo(datas.get("measurementInfo"));
            setDemandStartEndInfo(datas.get("demandStartEndInfo"))
        }
        setCountFetch(countFetch + 1);
    }

    const defaultDatas = [
        {
            code: 'PA',
            name: 'demandCurrentData',
        },
        {
            code: 'PB',
            name: 'demandSettingData',
        },
        {
            code: 'PG',
            name: 'loRaConnectionStatus',
        },
        {
            code: 'PH',
            name: 'leakageInformation',
        },
        {
            code: 'PI',
            name: 'lightLeakageSetting',
        },
        {
            code: 'PK',
            name: 'heavyEarthLeakageSetting',
        },
        {
            code: 'PO',
            name: 'confirmTime',
        },
        {
            code: 'PU',
            name: 'settingInformation',
        },
        {
            code: 'PW',
            name: 'phaseAngleInformation',
        },
        {
            code: 'QA',
            name: 'excessLightLeakageCurrent',
        },
        {
            code: 'QC',
            name: 'excessHeavyLeakageCurrent',
        },
        {
            code: 'QE',
            name: 'maximumEarthLeakage',
        },
        {
            code: 'QF',
            name: 'deviceName',
        },
        {
            code: 'QH',
            name: 'locationName',
        },
        {
            code: 'QJ',
            name: 'loRaSetting',
        },
        {
            code: 'QL',
            name: 'data4GLteSetting',
        },
        {
            code: 'QN',
            name: 'loRaConnectionInfo',
        },
        {
            code: 'PY',
            name: 'measurementInfo',
        },
        {
            code: 'PF',
            name: 'demandStartEndInfo',
        }
    ];
    const deviceNos = ['00', '01', '02', '03', '04'];
    const handleDefaultData = (data: any) => {
        const results = new Map<string, any>();
        defaultDatas.forEach(item => {
            const object = data.find((pollingInfo: any) => pollingInfo.code === item.code);
            if (object) {
                if (object.device_no === null) {
                    object && object.data && results.set(item.name, JSON.parse(object.data));
                } else {
                    const devices = new Array();
                    deviceNos.forEach(deviceNo => {
                        const device = data.find((pollingInfo: any) => pollingInfo.code === item.code && pollingInfo.device_no === deviceNo);
                        device && device.data && devices.push(JSON.parse(device.data));
                    });
                    results.set(item.name, devices);
                }
            }
        });
        return results;
    }

    React.useEffect(() => {
        countFetch === 2 ? setIsLoadingForm(false) : setIsLoadingForm(true);
    }, [countFetch])

    return (
        <>
            <FetchHelper 
                fetchId={menusFetchId} 
                onComplete={(success, data, error) =>
                    success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} 
            />
            <FetchHelper fetchId={'DEVICE_FETCH'}
                url={"/master/gateways/get"}
                method="GET"
                request={true}
                params={handleFetchParam('id', id)}
                onComplete={(success, data, error) => {
                    success ? handleFetchDeviceSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={'CACHE_FETCH'}
                url={"/polling/cache"}
                method="GET"
                request={true}
                params={handleFetchParam('mainid', id)}
                onComplete={(success, data, error) => {
                    success ? handleFetchSuccess(data) : handleError(success, data, error)
                }}
            />
            {isLoadingForm && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <DeviceInformation deviceInformation={device} mainid={id ?? ''} title="状態設定値" isDisplayDeviceInfo={true} isShowMenuButton={true} ></DeviceInformation>
            <Card style={{ width: '85%', margin: '12px auto auto auto', boxShadow: '1px 1px 1px 1px #bfbfbf', paddingBottom: '0', height: 'calc(100vh - 300px)', minHeight: '600px' }}>
                {isVisibleRefreshAllButton === 'true' ? (
                    <CardHeader
                        action={
                            <div>
                                <IconButton aria-label="refresh" onClick={handleRefresh} disabled={numberRefreshAll > 0 || isEdit || disabledRefreshAll}>
                                    <CachedIcon style={numberRefreshAll > 0 || isEdit || disabledRefreshAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                </IconButton>
                            </div>
                        }
                        titleTypographyProps={{ variant: 'h4' }}
                        title="監視装置　状態設定値"
                    />
                ) : (
                    <CardHeader
                        titleTypographyProps={{ variant: 'h4' }}
                        title="監視装置　状態設定値"
                    />
                )}
                <CardContent>
                    <Grid container spacing={1} direction="row">
                        <Grid item xs={3} width='20%' style={{ display: 'block', paddingTop: '0' }}>
                            <ul style={{ listStyle: 'none', paddingLeft: '0', margin: '0' }}>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('デマンド現在値')} style={activeRef === 1 ? liStyles : liStylesNormal}>デマンド現在値</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(demandCurrentDataFetchId, 'デマンド現在値')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledDemandCurrentDataRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledDemandCurrentDataRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('デマンド設定値')} style={activeRef === 2 ? liStyles : liStylesNormal}>デマンド設定値</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(demandSettingDataFetchId, 'デマンド設定値')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledDemandSettingDataRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledDemandSettingDataRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <li onClick={() => scrollTo('デマンド開始終了変更')} style={activeRef === 3 ? liStyles : liStylesNormal}>デマンド開始終了変更</li>
                                <li onClick={() => scrollTo('測定開始終了変更')} style={activeRef === 4 ? liStyles : liStylesNormal}>測定開始終了変更</li>                                
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('LoRa接続状態')} style={activeRef === 5 ? liStyles : liStylesNormal}>LoRa接続状態</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(loRaConnectionStatusFetchId, 'LoRa接続状態')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledLoRaConnectionStatusRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledLoRaConnectionStatusRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('漏電情報')} style={activeRef === 6 ? liStyles : liStylesNormal}>漏電情報</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(leakageInformationFetchId, '漏電情報')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledLeakageInformationRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledLeakageInformationRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('軽漏電設定')} style={activeRef === 7 ? liStyles : liStylesNormal}>軽漏電設定</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(lightLeakageSettingFetchId, '軽漏電設定')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledLightLeakageSettingRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledLightLeakageSettingRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('重漏電設定')} style={activeRef === 8 ? liStyles : liStylesNormal}>重漏電設定</li>
                                    <IconButton aria-label="refresh" onClick={() => handleRefreshGroup(heavyEarthLeakageSettingFetchId, '重漏電設定')} disabled={numberRefreshAll > 0 || disabledHeavyEarthLeakageSettingRefresh} style={{ height: '24px' }}>
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledHeavyEarthLeakageSettingRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('時間確認')} style={activeRef === 9 ? liStyles : liStylesNormal}>時間確認</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(confirmTimeFetchId, '時間確認')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledConfirmTimeRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledConfirmTimeRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('Io/Ior設定情報')} style={activeRef === 10 ? liStyles : liStylesNormal}>Io/Ior設定情報</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(settingInformationFetchId, 'Io/Ior設定情報')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledSettingInformationRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledSettingInformationRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('PhaseAngle情報')} style={activeRef === 11 ? liStyles : liStylesNormal}>PhaseAngle情報</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(phaseAngleInformationFetchId, 'PhaseAngle情報')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledPhaseAngleInformationRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledPhaseAngleInformationRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('軽漏電流超過・復帰時間')} style={activeRef === 12 ? liStyles : liStylesNormal}>軽漏電流超過・復帰時間</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(excessLightLeakageCurrentFetchId, '軽漏電流超過・復帰時間')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledExcessLightLeakageCurrentRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledExcessLightLeakageCurrentRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('重漏電流超過・復帰時間')} style={activeRef === 13 ? liStyles : liStylesNormal}>重漏電流超過・復帰時間</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(excessHeavyLeakageCurrentFetchId, '重漏電流超過・復帰時間')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledExcessHeavyLeakageCurrentRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledExcessHeavyLeakageCurrentRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>

                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('最大漏電')} style={activeRef === 14 ? liStyles : liStylesNormal}>最大漏電</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(maximumEarthLeakageFetchId, '最大漏電')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledMaximumEarthLeakageRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledMaximumEarthLeakageRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('DeviceName')} style={activeRef === 15 ? liStyles : liStylesNormal}>DeviceName</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(deviceNameFetchId, 'DeviceName')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledDeviceNameRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledDeviceNameRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('LocationName')} style={activeRef === 16 ? liStyles : liStylesNormal}>LocationName</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(locationNameFetchId, 'LocationName')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledLocationNameRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledLocationNameRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('LoRa設定')} style={activeRef === 17 ? liStyles : liStylesNormal}>LoRa設定</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(loRaSettingFetchId, 'LoRa設定')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledLoRaSettingRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledLoRaSettingRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('4G LTE設定')} style={activeRef === 18 ? liStyles : liStylesNormal}>4G LTE設定</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(data4GLteSettingFetchId, '4G LTE設定')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledData4GLteSettingRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledData4GLteSettingRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                                <div style={menuStyles}>
                                    <li onClick={() => scrollTo('LoRa connect情報')} style={activeRef === 19 ? liStyles : liStylesNormal}>LoRa connect情報</li>
                                    <IconButton
                                        aria-label="refresh"
                                        onClick={() => handleRefreshGroup(loRaConnectionInfoFetchId, 'LoRa connect情報')}
                                        disabled={disableAllRefeshButton || numberRefreshAll > 0 || disabledLoRaConnectionInfoRefresh}
                                        style={{ height: '24px' }}
                                    >
                                        <CachedIcon style={disableAllRefeshButton || numberRefreshAll > 0 || disabledLoRaConnectionInfoRefresh ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                    </IconButton>
                                </div>
                            </ul>
                        </Grid>
                        <Grid item xs={9} style={{ height: 'calc(100vh - 100px - 140px - 160px)', minHeight: '500px', overflowY: 'auto', paddingRight: '10px' }} onScroll={handleScroll}>
                            <div>
                                <div ref={myRef1}>
                                    <DemandCurrentData
                                        defaultData={demandCurrentData}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isRefreshGroup={demandCurrentDataRefresh}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                    >
                                    </DemandCurrentData>
                                </div>
                                <div ref={myRef2}>
                                    <DemandSettingData
                                        defaultData={demandSettingData}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isRefreshGroup={demandSettingDataRefresh}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </DemandSettingData>
                                </div>
                                <div ref={myRef3}>
                                    <DemandChangeStartEnd
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setDemandChangeStartEnd={((isLoading: boolean) => setDemandChangeStartEnd(isLoading))}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                        demandStartEndInfo={demandStartEndInfo}
                                    >
                                    </DemandChangeStartEnd>
                                </div>
                                <div ref={myRef4}>
                                    <ChangeMeasurentStartedEnd
                                        mainid={`${id}`}
                                        title={"測定値開始終了変更/本体"}
                                        device_no={"00"}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setChangeMeasurentStartedEnd={((isLoading: boolean) => setDemandChangeStartEnd(isLoading))}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                        measurementInfo={measurementInfo}
                                    >
                                    </ChangeMeasurentStartedEnd>
                                    <ChangeMeasurentStartedEnd
                                        mainid={`${id}`}
                                        title={"測定値開始終了変更/子機1"}
                                        device_no={"01"}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setChangeMeasurentStartedEnd={((isLoading: boolean) => setDemandChangeStartEnd(isLoading))}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                        measurementInfo={measurementInfo}
                                    >
                                    </ChangeMeasurentStartedEnd>  
                                    <ChangeMeasurentStartedEnd
                                        mainid={`${id}`}
                                        title={"測定値開始終了変更/子機2"}
                                        device_no={"02"}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setChangeMeasurentStartedEnd={((isLoading: boolean) => setDemandChangeStartEnd(isLoading))}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                        measurementInfo={measurementInfo}
                                    ></ChangeMeasurentStartedEnd>
                                    <ChangeMeasurentStartedEnd
                                        mainid={`${id}`}
                                        title={"測定値開始終了変更/子機3"}
                                        device_no={"03"}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setChangeMeasurentStartedEnd={((isLoading: boolean) => setDemandChangeStartEnd(isLoading))}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                        measurementInfo={measurementInfo}
                                    ></ChangeMeasurentStartedEnd>
                                    <ChangeMeasurentStartedEnd
                                        mainid={`${id}`}
                                        title={"測定値開始終了変更/子機4"}
                                        device_no={"04"}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setChangeMeasurentStartedEnd={((isLoading: boolean) => setDemandChangeStartEnd(isLoading))}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                        measurementInfo={measurementInfo}
                                    ></ChangeMeasurentStartedEnd>
                                </div>
                                <div ref={myRef5}>
                                    <LoRaConnectionStatus
                                        defaultData={loRaConnectionStatus}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isRefreshGroup={loRaConnectionStatusRefresh}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                    >
                                    </LoRaConnectionStatus>
                                </div>
                                <div ref={myRef6}>
                                    <LeakageInformation
                                        defaultData={leakageInformation}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={leakageInformationRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                    >
                                    </LeakageInformation>
                                </div>
                                <div ref={myRef7}>
                                    <LightLeakageSetting
                                        defaultData={lightLeakageSetting}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={lightLeakageSettingRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </LightLeakageSetting>
                                </div>
                                <div ref={myRef8}>
                                    <HeavyEarthLeakageSetting
                                        defaultData={heavyEarthLeakageSetting}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={heavyEarthLeakageSettingRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </HeavyEarthLeakageSetting>
                                </div>
                                <div ref={myRef9}>
                                    <ConfirmTime
                                        defaultData={confirmTime}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isRefreshGroup={confirmTimeRefresh}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                    >
                                    </ConfirmTime>
                                </div>
                                <div ref={myRef10}>
                                    <SettingInformation
                                        defaultData={settingInformation}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={settingInformationRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </SettingInformation>
                                </div>
                                <div ref={myRef11}>
                                    <PhaseAngleInformation
                                        defaultData={phaseAngleInformation}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={phaseAngleInformationRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                    >
                                    </PhaseAngleInformation>
                                </div>
                                <div ref={myRef12}>
                                    <ExcessLightLeakageCurrent
                                        defaultData={excessLightLeakageCurrent}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={excessLightLeakageCurrentRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </ExcessLightLeakageCurrent>
                                </div>
                                <div ref={myRef13}>
                                    <ExcessHeavyLeakageCurrent
                                        defaultData={excessHeavyLeakageCurrent}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={excessHeavyLeakageCurrentRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </ExcessHeavyLeakageCurrent>
                                </div>
                                <div ref={myRef14}>
                                    <MaximumEarthLeakage
                                        defaultData={maximumEarthLeakage}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={maximumEarthLeakageRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                    >
                                    </MaximumEarthLeakage>
                                </div>
                                <div ref={myRef15}>
                                    <DeviceName
                                        defaultData={deviceName}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={deviceNameRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </DeviceName>
                                </div>
                                <div ref={myRef16}>
                                    <LocationName
                                        defaultData={locationName}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={locationNameRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </LocationName>
                                </div>
                                <div ref={myRef17}>
                                    <LoRaSetting
                                        defaultData={loRaSetting}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={loRaSettingRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </LoRaSetting>
                                </div>
                                <div ref={myRef18}>
                                    <Cpn4GLteSetting
                                        defaultData={data4GLteSetting}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        isEdit={isEdit}
                                        setIsEdit={handleSetIsEdit}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isRefreshGroup={data4GLteSettingRefresh}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                        isDisbleUpdateBtn={disableAllUpdateButton}
                                    >
                                    </Cpn4GLteSetting>
                                </div>
                                <div ref={myRef19}>
                                    <LoRaConnectionInfo
                                        defaultData={loRaConnectionInfo}
                                        mainid={`${id}`}
                                        isRefreshAllBegin={numberRefreshAll}
                                        isRefreshAllCompleted={handleRefreshAll}
                                        loadingRefreshAll={loadingRefreshAll}
                                        setDisabledRefreshGroupBtn={handleSetDisabledRefreshGroupBtn}
                                        isRefreshGroup={loRaConnectionInfoRefresh}
                                        setRefreshGroup={handleSetDisabledRefreshAll}
                                        isDisbleRefeshBtn={disableAllRefeshButton}
                                    >
                                    </LoRaConnectionInfo>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
            <div>
                <Stack direction='row' justifyContent="flex-end" spacing={2} width="85%" margin="10px auto 0">
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                </Stack>
            </div>
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onCancel: () => push(RoutePath.DeviceList),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "装置情報",
            body: "装置情報が取得出来ません。",
        }),
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PollingTopicDetails as any);

