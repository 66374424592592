import { Button, Grid, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { ExcessHeavyLeakageCurrentModel } from "../../../models";
import { formatDateTime, padZeroToStart } from "../../../utils";

export type ExcessHeavyLeakageCurrentEditFormProps = {
    excessHeavyLeakageCurrent: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const ExcessHeavyLeakageCurrentEdit = (props: ExcessHeavyLeakageCurrentEditFormProps) => {
    const { excessHeavyLeakageCurrent, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: ExcessHeavyLeakageCurrentModel = {
        mainid: excessHeavyLeakageCurrent.mainid,
        deviceNo: excessHeavyLeakageCurrent.deviceNo,
        limitOverSec1: excessHeavyLeakageCurrent.limitOverSec1,
        recoverySec1: excessHeavyLeakageCurrent.recoverySec1,
        limitOverSec2: excessHeavyLeakageCurrent.limitOverSec2,
        recoverySec2: excessHeavyLeakageCurrent.recoverySec2,
        limitOverSec3: excessHeavyLeakageCurrent.limitOverSec3,
        recoverySec3: excessHeavyLeakageCurrent.recoverySec3,
        limitOverSec4: excessHeavyLeakageCurrent.limitOverSec4,
        recoverySec4: excessHeavyLeakageCurrent.recoverySec4,
        limitOverSec5: excessHeavyLeakageCurrent.limitOverSec5,
        recoverySec5: excessHeavyLeakageCurrent.recoverySec5,
        limitOverSec6: excessHeavyLeakageCurrent.limitOverSec6,
        recoverySec6: excessHeavyLeakageCurrent.recoverySec6,
        lastGetDatetime: excessHeavyLeakageCurrent.lastGetDatetime,
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<ExcessHeavyLeakageCurrentModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: ExcessHeavyLeakageCurrentModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ExcessHeavyLeakageCurrentModel) => {

        let request = {
            mainid: formValue.mainid,
            device_no: formValue.deviceNo,
            limit_over_sec1: formValue.limitOverSec1,
            recovery_sec1: formValue.recoverySec1,
            limit_over_sec2: formValue.limitOverSec2,
            recovery_sec2: formValue.recoverySec2,
            limit_over_sec3: formValue.limitOverSec3,
            recovery_sec3: formValue.recoverySec3,
            limit_over_sec4: formValue.limitOverSec4,
            recovery_sec4: formValue.recoverySec4,
            limit_over_sec5: formValue.limitOverSec5,
            recovery_sec5: formValue.recoverySec5,
            limit_over_sec6: formValue.limitOverSec6,
            recovery_sec6: formValue.recoverySec6,
            last_get_datetime: formatDateTime(new Date()),
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleInputOnBlur = (e: any, name: any, maxLength: number) => {
        setValue(name, padZeroToStart(e.target.value, maxLength));
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleEditSubmit)} style={{ height: 'calc(55vh - 20px)' }}>
                <div style={{ height: '90%', width: '100%', overflowY: 'auto' }}>
                    <Stack spacing={3} style={{ width: '90%', margin: 'auto' }}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} paddingTop={"10px"}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="超過時間1"
                                    size="small"
                                    {...register("limitOverSec1",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.limitOverSec1}
                                    helperText={errors?.limitOverSec1?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'limitOverSec1', 3)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="復帰時間1"
                                    size="small"
                                    {...register("recoverySec1",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.recoverySec1}
                                    helperText={errors?.recoverySec1?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'recoverySec1', 3)}
                                />
                            </Grid>
                        </Stack>

                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="超過時間2"
                                    size="small"
                                    {...register("limitOverSec2",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.limitOverSec2}
                                    helperText={errors?.limitOverSec2?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'limitOverSec2', 3)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="復帰時間2"
                                    size="small"
                                    {...register("recoverySec2",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.recoverySec2}
                                    helperText={errors?.recoverySec2?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'recoverySec2', 3)}
                                />
                            </Grid>
                        </Stack>

                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="超過時間3"
                                    size="small"
                                    {...register("limitOverSec3",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.limitOverSec3}
                                    helperText={errors?.limitOverSec3?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'limitOverSec3', 3)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="復帰時間3"
                                    size="small"
                                    {...register("recoverySec3",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.recoverySec3}
                                    helperText={errors?.recoverySec3?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'recoverySec3', 3)}
                                />
                            </Grid>
                        </Stack>

                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="超過時間4"
                                    size="small"
                                    {...register("limitOverSec4",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.limitOverSec4}
                                    helperText={errors?.limitOverSec4?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'limitOverSec4', 3)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="復帰時間4"
                                    size="small"
                                    {...register("recoverySec4",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.recoverySec4}
                                    helperText={errors?.recoverySec4?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'recoverySec4', 3)}
                                />
                            </Grid>
                        </Stack>

                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="超過時間5"
                                    size="small"
                                    {...register("limitOverSec5",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.limitOverSec5}
                                    helperText={errors?.limitOverSec5?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'limitOverSec5', 3)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="復帰時間5"
                                    size="small"
                                    {...register("recoverySec5",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.recoverySec5}
                                    helperText={errors?.recoverySec5?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'recoverySec5', 3)}
                                />
                            </Grid>
                        </Stack>

                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="超過時間6"
                                    size="small"
                                    {...register("limitOverSec6",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.limitOverSec6}
                                    helperText={errors?.limitOverSec6?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'limitOverSec6', 3)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="復帰時間6"
                                    size="small"
                                    {...register("recoverySec6",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 999,
                                                message: '000~999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.recoverySec6}
                                    helperText={errors?.recoverySec6?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'recoverySec6', 3)}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                </div>
                <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }} style={{ position: 'relative', right: 'calc(5% + 15px)', top: '-5px' }}>
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    <Button variant="contained" type="submit">保存</Button>
                </Stack>
            </form >
        </>
    );
};
export default ExcessHeavyLeakageCurrentEdit;


