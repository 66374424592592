import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import Cpn4GLteSettingEdit from '../polling/pollingTopicEdit/4GLteSettingEdit';

export type Select4GLteSettingDialogProps = {
    isOpen: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
    data: any;
    dialogTitle: string;
};

export const Select4GLteSettingDialog = (props: Select4GLteSettingDialogProps) => {
    const { isOpen, onOK, onCancel, data, dialogTitle } = props;
    const [open, setOpen] = React.useState(isOpen);

    // Cancelボタンイベント
    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const handleUpdate = (data: any) => {
        setOpen(false);
        onOK(data);
    };

    return (
        <div>
            <Dialog open={open} maxWidth={"md"} fullWidth>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                    <Cpn4GLteSettingEdit onCancel={handleCancel} data4GLteSetting={data} onOK={(data) => handleUpdate(data)}></Cpn4GLteSettingEdit>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Select4GLteSettingDialog;