import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Autocomplete, Breakpoint, Button, CircularProgress, Collapse, Grid, IconButton, Stack, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OptionItem, PageData } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import palette from "../../../theme/palette";
import { formatDateTime, groupBy } from "../../../utils";
import FetchHelper from "../../FetchHelper";
import SmartDemandGrid from "./SmartDemandGrid";
import CollapseItem from './CollapseItem';
import TextSelector from '../../common/TextSelector';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useResponsive from '../../../hooks/useResponsive';
import PaginationMobileCpn from '../../common/PaginationMobileCpn';
import { limitMasterCustomers, customerLabelName } from '../../../statics';

const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER";
const GET_ALL_GATE_WAYS = "GET_ALL_GATE_WAYS";

// ページサイズ
const PageSizeList = [
    { value: '10', label: '10', attribute: '', },
    { value: '50', label: '50', attribute: '', },
    { value: '100', label: '100', attribute: '', },
] as OptionItem[];

const pStyle1 = {
    padding: '0 4px',
    fontWeight: 'bold',
}

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
    customer_id: OptionItem | null;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
    customer_id: null,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// Props
export type DeviceJSPListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

export const SmartDemandList = (props:DeviceJSPListProps) => {
    // #region props
    const { onError, onSearchByCondition, onGetAllCustomer } = props;
    const [latestUpdates, setLatestUpdates] = React.useState("");
    const [customerLoadingFlag,setCustomerLoadingFlag] = React.useState<boolean>(false);
    const [keyword,setKeyword] = React.useState<string>("");
    const [colState, setColState] = React.useState<any>();
    const [pageData, setPageData] = React.useState(initialPageData);
    const [loadingFlag,setLoadingFlag] = React.useState<boolean>(false);
    const [openSearch,setOpenSearch] = React.useState<boolean>(false);
    const [customerOptions,setCustomerOptions] = React.useState<OptionItem[]>([]);
    const [smallGridData,setSmallGridData] = React.useState<any>([]);
    const [dataGrid,setDataGrid] = React.useState<any>([]);
    localStorage.setItem('lastActiveFormPath', RoutePath.SmartDemandList);
    localStorage.setItem('title', 'スマートデマンド');
    const gridRef = useRef() as any;
    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    const [pageSize, setPageSize] = React.useState(getValues('limit'));
    const disablePrevious = pageData.currPage === "1" || pageData.currPage === "0";
    const disableNext = pageData.currPage === pageData.totalPage || pageData.totalPage === "0";
    const isMobile = useResponsive({ query: 'down', key: 'sm' as Breakpoint });

    useEffect(()=> {
        if(!isMobile){
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(dataGrid);
            }
        }
    },[isMobile])

    // #endregion props

    // #region method
    React.useEffect(() => {
        setCustomerLoadingFlag(true);
        setLoadingFlag(true);
        handleSearchSubmit(getValues());
        onGetAllCustomer();
    },[])


    useEffect(() => {

        const search_key = keyword.split(" ");
        let data = dataGrid;
        for(const key of search_key){
            data = data.filter((item:any) =>{
                return item?.customer_name?.toLowerCase().includes(key?.toLowerCase()) || item?.id?.toLowerCase().includes(key?.toLowerCase()) || item?.device_name?.toLowerCase().includes(key?.toLowerCase())
            })
        }
        
        const groupedData = groupBy(data,"customer_id");
        const sortedArray = Object.entries(groupedData)
            .sort((a:any,b:any) => {
                const nameA = a[1][0].customer_name.toUpperCase();
                const nameB = b[1][0].customer_name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
        setSmallGridData(sortedArray);
    },[keyword,dataGrid])

    // 検索正常終了時
    const handleSuccess = (data:any) => {
        setLoadingFlag(false);
        const totalCount = data.total_count;
        const pageSize = getValues('limit');
        const page = Number(data.page) + 1;
        const totalPage = Math.ceil(totalCount / pageSize);
        const rowFrom = (pageSize * page - pageSize) + 1;
        const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);
        const updateData: PageData = {
            rowFrom: rowFrom.toLocaleString(),
            rowTo: rowTo.toLocaleString(),
            totalRow: totalCount.toLocaleString(),
            currPage: page.toLocaleString(),
            totalPage: totalPage.toLocaleString(),
        };
        setPageData(updateData);
        
        if(data && data.results){
            const newValues:any = [...data.results];
            data.results.forEach((item:any,index:any)=>{
                if(item.gateway_type.toString() === "1"){
                    newValues[index].idOrDeviceName = item.id;
                }else if(item.gateway_type.toString() === "2"){
                    newValues[index].idOrDeviceName = item.device_name;
                }
            })
            setDataGrid(newValues);
            const groupedData = groupBy(newValues,"customer_id");
            const sortedArray = Object.entries(groupedData)
            .sort((a:any,b:any) => {
                const nameA = a[1][0].customer_name.toUpperCase();
                const nameB = b[1][0].customer_name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
            setSmallGridData(sortedArray);
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(newValues);
            }
        }else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
        setLatestUpdates(formatDateTime(new Date()));
    }

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    const handleChangeKeyword = (value:string) => {
        setKeyword(value);
        const groupedData = groupBy(dataGrid,"customer_id");
        const sortedArray = Object.entries(groupedData)
            .sort((a:any,b:any) => {
                const nameA = a[1][0].customer_name.toUpperCase();
                const nameB = b[1][0].customer_name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
        setSmallGridData(sortedArray);
    }

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    const handleChangePageData = (name:any,value:any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    }

    const handleSearchSubmit = (value:SearchItemForm) => {
        setLoadingFlag(true);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.customer_id && value.customer_id.value) params.set("customer_id", value.customer_id.value);
        params.set("pulse_type",1);
        onSearchByCondition(params);
    }

    // 検索エラー時
    const handleError = (success:any,data:any,error:any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    }

    // 顧客名一覧取得成功時
    const handleSuccessGetAllCustomer = (data:any) => {
        setCustomerLoadingFlag(false);
        if (data?.results) setCustomerOptions(data?.results.map((x:any) => { return { value: x.id.toString(), label: x.name, attribute: '' } as OptionItem }));
    }

    const handleClearSeachCondition = () => {
        setValue("customer_id",{label:"",value:"",attribute:""} as OptionItem);
    }

    const handleErrorGetAllCustomer = (success:any,data:any,error:any) => {
        setCustomerLoadingFlag(false);
        onError(success,data,error);
    }

    let navigate = useNavigate();
    const onShowDetailPage = (data: any, field: string) => {
        if (field === "smartDemand") {
            sessionStorage.setItem('references', RoutePath.SmartDemandList);
            if(data.pulse_type.toString() === "2"){
                navigate(`${RoutePath.Gaex3}?mainid=${data.id}&customer_id=${data.customer_id}`);
            }else{
                navigate(`${RoutePath.Gaex1}?mainid=${data.id}&customer_id=${data.customer_id}`);
            }
        }
        else if(field === "monthlyTable") {
            sessionStorage.setItem('references', RoutePath.SmartDemandList);
            navigate(`${RoutePath.Gaex2}?mainid=${data.id}&customer_id=${data.customer_id}`);
        }else if(field === "yearlyTable") {
            sessionStorage.setItem('references', RoutePath.SmartDemandList);
            navigate(`${RoutePath.Gaex5}?mainid=${data.id}&customer_id=${data.customer_id}`);
        }else if(field === "link") {
            window.open(data.jsp_url, '_blank', 'noopener,noreferrer');
        }
    }

    // #endregion method

    return (
        <>
            {(loadingFlag|| customerLoadingFlag) && (
                    <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                        <CircularProgress />
                    </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleSearchSubmit)}>
                    {
                        (!isMobile) &&
                        <>
                        <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                            <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                                <div style={{ display: 'inline-block' }}>
                                    <Typography variant="h6" >検索条件</Typography>
                                </div>
                                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                    {
                                        openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                    }
                                </div>
                            </div>
                            <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems={"end"}
                                        spacing={1}
                                        direction="row"
                                    >
                                        <Grid item minWidth="20rem">
                                            <Controller
                                                control={control}
                                                name="customer_id"
                                                render={({ field: { value } }) => (
                                                    <Autocomplete
                                                        autoHighlight
                                                        value={value}
                                                        options={customerOptions}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" style={{ background: palette.background.paper }} />}
                                                        onChange={(_, data) => setValue("customer_id", data)}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                        </Grid>
                                        <Grid item >
                                            <Button variant="contained" onClick={handleSearch}>
                                                検索
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </div>
                        </Stack>
                        <Stack direction="row">
                            <Typography variant="body2" style={{ textAlign: "center", lineHeight: "40px" }}>
                            最終更新日時&emsp;{latestUpdates}
                            </Typography>
                            <div style={{ marginInlineStart: 'auto' }}>
                                <Stack mb={1} justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                    <Grid item>
                                        <TextField
                                            fullWidth
                                            autoComplete="searchStr"
                                            name="keyword"
                                            type="text"
                                            label="フィルター"
                                            size="small"
                                            value={keyword}
                                            onChange={e => handleChangeKeyword(e.target.value)}
                                            />
                                        </Grid>
                                    <Button variant="outlined" size="small" onClick={() => handleChangeKeyword("")}>クリア</Button>
                                </Stack>
                            </div>
                        </Stack>
                        <div>
                            <SmartDemandGrid
                                ref={gridRef}
                                colState={colState}
                                pageSize={getValues('limit')}
                                pageData={pageData}
                                onChangeSearchCondition={handleChangePageData}
                                openSearch={openSearch}
                                keyword = {keyword}
                                onShowDetails={onShowDetailPage}
                            />
                        </div>
                        </>
                    }
                    {
                        isMobile &&
                        <>
                            <div style={{ marginInlineStart: 'auto' }}>
                                <Stack paddingTop={1} mb={1} justifyContent="flex-end" alignItems={"center"} direction="row" spacing={1} >
                                    <Grid item >
                                        <TextField
                                            fullWidth
                                            autoComplete="searchStr"
                                            name="keyword"
                                            type="text"
                                            label="フィルター"
                                            size="small"                                            
                                            value={keyword}
                                            onChange={e => handleChangeKeyword(e.target.value)}
                                        />
                                        </Grid>
                                    <Button variant="outlined" size="small" sx={{paddingTop:"5px",paddingBottom:"5px"}} onClick={() => handleChangeKeyword("")}>クリア</Button>
                                </Stack>
                            </div>
                            <Table>
                                <div>
                                <TableRow style={{display:"flex",margin:"auto",border:"1px solid grey"}}>
                                    <TableCell style={{width:"100%",fontWeight:"bold"}}>
                                        {`${customerLabelName}名`}
                                    </TableCell>
                                </TableRow>
                                </div>
                                {
                                    smallGridData.length > 0 &&
                                    <>
                                        <Table style={{border:"1px solid grey",borderTop:"0px"}}>
                                            <div style={{overflow:"auto",maxHeight:"calc(100vh - 252px)",width:"100%"}}>

                                                {smallGridData.map((item:any,index:any) => {
                                                    return <> 
                                                        <CollapseItem id={item[0]} data={item[1]} index={index} onShowDetails={onShowDetailPage} />
                                                    </>
                                                })}
                                            </div>
                                        </Table>
                                    </>

                                }
                                <PaginationMobileCpn pageData={pageData} onChangeSearchCondition={handleChangePageData} limit={pageSize} />
                            </Table>
                        </>
                    }
                </form >
                
                
            </div>
            <FetchHelper fetchId={GET_ALL_GATE_WAYS} onComplete={
                (success:any,data:any,error:any) => success ? handleSuccess(data) : handleError(success,data,error) } />
            <FetchHelper fetchId={GET_ALL_CUSTOMER} onComplete={
                (success:any,data:any,error:any) => success ? handleSuccessGetAllCustomer(data) : handleErrorGetAllCustomer(success,data,error) } />
        </>
    )
}


const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        ""
    ) as FetchState,
    formState: selectors.getFormState(state, ""),
});
const mapDispatchToProps = {
    onSearchByCondition:(params: { [key: string]: any }) =>  actionCreators.fetch(GET_ALL_GATE_WAYS,`/master/gateways-all/get`,"GET",params),
    onGetAllCustomer: () => actionCreators.fetch(GET_ALL_CUSTOMER,`/master/customers/get?limit=${limitMasterCustomers}`,"GET",null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "スマートデマンド",
            body: "スマートデマンド一覧を検索できません。" + error,
        }),
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SmartDemandList as any);