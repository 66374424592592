import { OptionItem } from "../models";
export const customerLabelName = "事業所";
export const limitMasterCustomers = 5000;
export const limitMasterGateways = 5000;
export const MonitoringOperatorCustomerRoleValue = "536870912";
export const MobileSmartCustomerRoleValue = "562949953421312";
export const SmartCustomerRoleValue = "140737488355328";
export const BussinessOperationMonitoringRoleValue = "17592186044416";
export const GeneralManagerRoleValue = "32";
export const ResultOK = "良";
export const ResultNG = "否";
export const PollingMenuOptions = [
    {
        value: 'pollingTopicDetail',
        label: '状態設定値',
    },
    {
        value: 'editDevice',
        label: '装置編集',
    },
    {
        value: 'contact',
        label: '接点編集',
    },
    {
        value: 'leaks',
        label: '漏電編集',
    },
    {
        value: 'demand',
        label: 'デマンド編集',
    },
    {
        value: 'solar',
        label: '太陽光編集',
    },
    {
        value: 'notification',
        label: '通報編集',
    },
    {
        value: "registReport",
        label: "帳票配信"
    }
]

// export const UserRoleOptions = [
//     {
//         value: '4',
//         label: 'システム管理者',
//     },
//     {
//         value: '4096',
//         label: '社員 - 正社員',
//     },
//     {
//         value: '1048576',
//         label: '社員 - 従事者',
//     },
//     {
//         value: CustomerRoleValue,
//         label: 'お客様',
//     },
//     {
//         value: SmartCustomerRoleValue,
//         label: "お客様 - スマート監視"
//     },
//     {
//         value: MobileSmartCustomerRoleValue,
//         label: "お客様 - モバイル監視"
//     }
    
    
// ] as OptionItem[];

export const CodeRestrictionArrayOptions = [
    {
        value: 'ALARM',
        label: '全般',
    },
    {
        value: 'ABAC',
        label: '停電・復電',
    },
    {
        value: 'AD',
        label: '接点警報',
    },
    {
        value: 'GB',
        label: 'デマンド注意警報',
    },
    {
        value: 'GC',
        label: 'デマンド限界警報',
    },
    {
        value: 'EBEC',
        label: '漏電警報',
    },
    {
        value: "BX",
        label: "地絡警報",
    },
    {
        value: "BE",
        label: "微地絡トレンド電流"
    },
    {
        value: 'GADEMAND',
        label: 'デマンド定期通報',
    },
    {
        value: 'GASOLAR',
        label: '太陽光定期通報',
    },
    {
        value: 'FA',
        label: '測定値定期通報',
    },
    {
        value: 'AE',
        label: '死活監視',
    },
] as OptionItem[];

export const GwAuthOptions = [
    {
        value:"GW-gwcrud-add",
        label:"装置管理-追加",
    },
    {
        value:"GW-gwcrud-edit",
        label:"装置管理-編集",
    },
    {
        value:"GW-gwcrud-delete",
        label:"装置管理-削除",
    },
    {
        value:"GW-gwstatus-view",
        label:"状態設定-表示",
    },
    {
        value:"GW-gwstatus-update",
        label:"状態設定-設定",
    },
    {
        value:"GW-gwstatus-get",
        label:"状態設定-取得",
    },
    {
        value:"GW-adedit",
        label:"接点編集",
    },
    {
        value:"GW-ebecedit",
        label:"漏電編集",
    },
    {
        value:"GW-demandedit",
        label:"デマンド編集",
    },
    {
        value:"GW-solaredit",
        label:"太陽光編集",
    },
    {
        value:"GW-notifyedit",
        label:"通報編集",
    },
    {
        value:"GW-demandreportedit",
        label:"帳票配信",
    },
] as OptionItem[]

export const SmartObserveArrayOptions = [
    {
        label: "スマートデマンド",
        value: "GADEMAND"
    },
    {
        label: "スマート太陽光",
        value: "GASOLAR"
    }
] as OptionItem[]