import * as React from "react";
import { connect } from "react-redux";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, FormControlLabel, Grid, Stack, Switch, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { push } from "connected-react-router";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import palette from "../../../theme/palette";
import DeviceInformation from "../device/DeviceInformation";
import { Customer } from "../../../models";

// FETCH ID
const fetchId = "NOTIFICATIONS_ITEM";
const menusFetchId = 'MENUS_FETCH';

interface Item {
    address: string,
}

const powerFailure = {
    code: "AB",
    name: "powerFailure",
}
const powerRestored = {
    code: "AC",
    name: "powerRestored",
}
const contactAlarm = {
    code: "AD",
    name: "contactAlarm",
}
const demandAlertAlarm = {
    code: "GB",
    name: "demandAlertAlarm",
}
const demandLimitAlarm = {
    code: "GC",
    name: "demandLimitAlarm",
}
const lightLeakageAlarm = {
    code: "EB",
    name: "lightLeakageAlarm",
}

const heavyEarthLeakageAlarm = {
    code: "EC",
    name: "heavyEarthLeakageAlarm",
}

const subGroundFaultDetection = {
    code : "BA",
    name: "subGroundFaultDetection"
}

const subGroundWarningDetection = {
    code : "BB",
    name: "subGroundWarningDetection"
}

const subGroundFaultAlarm = {
    code : "BC",
    name: "subGroundFaultAlarm"
}

const groundFaultAlarm = {
    code : "BD",
    name: "groundFaultAlarm"
}

const microEarthFaultPeakCurrentAlarm = {
    code : "BE",
    name: "microEarthFaultPeakCurrentAlarm"
}

// フォーム定義
interface EditNotificationsForm {
    address_info_array: {
        powerFailure?: {
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        powerRestored?: {
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        contactAlarm?: {
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        demandAlertAlarm?: {
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        demandLimitAlarm?: {
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        lightLeakageAlarm?: {
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        heavyEarthLeakageAlarm?: {
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        subGroundFaultDetection?:{
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        subGroundWarningDetection?:{
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        subGroundFaultAlarm?:{
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        groundFaultAlarm?:{
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
        microEarthFaultPeakCurrentAlarm?:{
            slackNotifyFlag: boolean,
            addressList: Item[]
        },
    }
}

// 初期値設定
const initialValue: EditNotificationsForm = {
    address_info_array: {
        powerFailure: {
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        powerRestored: {
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        contactAlarm: {
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        demandAlertAlarm: {
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        demandLimitAlarm: {
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        lightLeakageAlarm: {
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        heavyEarthLeakageAlarm: {
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        subGroundFaultDetection:{
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        subGroundWarningDetection:{
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        subGroundFaultAlarm:{
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        groundFaultAlarm:{
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
        microEarthFaultPeakCurrentAlarm:{
            slackNotifyFlag: false,
            addressList: [{ address: "" } as Item]
        },
    }
}

// -------------
// Props
export type NotificationsDetailsProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const NotificationsDetails = (props: NotificationsDetailsProps) => {
    const { onError, onGetData, onUpdate, onUpdateSuccess, onUpdateError, fetchStateMenu, onGetAuthRole  } = props;

    const [loadingFlag, setLoadingFlag] = React.useState(true);

    const urlParams = new URLSearchParams(window.location.search);
    const mainid = urlParams.get('mainid');
    const [device, setDevice] = React.useState<any>();
    
    const [authRole, setAuthRole] = React.useState<any>();
    const [disableSaveBtn, setDisableSaveBtn] = React.useState(true);

    // 統括管理者
    const generalAdminstratorRole = React.useMemo(() => {
        return authRole?.role_list.find((item:any) => item.name == "統括管理者").role;
    },[authRole]);

    // 統括管理者以下( 6 >= target がTRUE)
    const isDisabled = React.useMemo(() => {
        if(!authRole)
            return true;
        return !(6 >= authRole?.role || authRole?.role == generalAdminstratorRole)
    },[authRole,generalAdminstratorRole]);

    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        if (authRole !== undefined && device !== undefined){
            if (authRole.auth_role_map['GW-notifysave-all']){
                setDisableSaveBtn(false);
            } else {
                if (authRole.auth_role_map['GW-notifysave-belong']){
                    if (authRole.company_id == device.customer_id)
                    setDisableSaveBtn(false);
                }
                if (authRole.auth_role_map['GW-notifysave-group']){
                    if (authRole.company_group_id == device.group_id)
                        {
                            setDisableSaveBtn(false);
                        }
                }
                if (authRole.auth_role_map['GW-notifysave-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == device.customer_id).length > 0)
                        setDisableSaveBtn(false);
                }
                
            }
        }
    },[authRole, device])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    const { handleSubmit, formState: { errors }, getValues, control, setValue } = useForm<EditNotificationsForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const { fields: powerFailureFields, append: appendPowerFailure, remove: removePowerFailure } = useFieldArray({
        control,
        name: "address_info_array.powerFailure.addressList",
    });
    const { fields: powerRestoredFields, append: appendPowerRestored, remove: removePowerRestored } = useFieldArray({
        control,
        name: "address_info_array.powerRestored.addressList",
    });

    const { fields: contactAlarmFields, append: appendContactAlarm, remove: removeContactAlarm } = useFieldArray({
        control,
        name: "address_info_array.contactAlarm.addressList",
    });

    const { fields: demandAlertAlarmFields, append: appendDemandAlertAlarm, remove: removeDemandAlertAlarm } = useFieldArray({
        control,
        name: "address_info_array.demandAlertAlarm.addressList",
    });

    const { fields: demandLimitAlarmFields, append: appendDemandLimitAlarm, remove: removeDemandLimitAlarm } = useFieldArray({
        control,
        name: "address_info_array.demandLimitAlarm.addressList",
    });

    const { fields: lightLeakageAlarmFields, append: appendLightLeakageAlarm, remove: removeLightLeakageAlarm } = useFieldArray({
        control,
        name: "address_info_array.lightLeakageAlarm.addressList",
    });

    const { fields: heavyEarthLeakageAlarmFields, append: appendHeavyEarthLeakageAlarm, remove: removeHeavyEarthLeakageAlarm } = useFieldArray({
        control,
        name: "address_info_array.heavyEarthLeakageAlarm.addressList",
    });

    const { fields: subGroundFaultDetectionFields, append: appendSubGroundFaultDetection, remove: removeSubGroundFaultDetection } = useFieldArray({
        control,
        name: "address_info_array.subGroundFaultDetection.addressList",
    });

    const { fields: subGroundWarningDetectionFields, append: appendSubGroundWarningDetection, remove: removeSubGroundWarningDetection } = useFieldArray({
        control,
        name: "address_info_array.subGroundWarningDetection.addressList",
    });

    const { fields: subGroundFaultAlarmFields, append: appendSubGroundFaultAlarm, remove: removeSubGroundFaultAlarm } = useFieldArray({
        control,
        name: "address_info_array.subGroundFaultAlarm.addressList",
    });

    const { fields: groundFaultAlarmFields, append: appendGroundFaultAlarm, remove: removeGroundFaultAlarm } = useFieldArray({
        control,
        name: "address_info_array.groundFaultAlarm.addressList",
    });

    const { fields: microEarthFaultPeakCurrentAlarmFields, append: appendMicroEarthFaultPeakCurrentAlarm, remove: removeMicroEarthFaultPeakCurrentAlarm } = useFieldArray({
        control,
        name: "address_info_array.microEarthFaultPeakCurrentAlarm.addressList",
    });

    //遷移ボタンのdivタグのref変数
    const myRef1 = React.useRef<any>(null);
    const myRef2 = React.useRef<any>(null);
    const myRef3 = React.useRef<any>(null);
    const myRef4 = React.useRef<any>(null);
    const myRef5 = React.useRef<any>(null);
    const myRef6 = React.useRef<any>(null);
    const myRef7 = React.useRef<any>(null);
    const myRef8 = React.useRef<any>(null);
    const myRef9 = React.useRef<any>(null);
    const myRef10 = React.useRef<any>(null);
    const myRef11 = React.useRef<any>(null);
    const myRef12 = React.useRef<any>(null);

    const [activeRef, setActiveRef] = React.useState(1);
    const [isOnScroll, setIsOnScroll] = React.useState(false);
    //移動関数
    const scrollTo = (value: String) => {
        if (value === "停電") {
            myRef1.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(1);
        }
        if (value === "復電") {
            myRef2.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(2);
        }
        if (value === "接点警報") {
            myRef3.current.scrollIntoView({ behavior: 'auto' });
            setActiveRef(3);
            setIsOnScroll(true);
        }
        if (value === "デマンド注意警報") {
            setActiveRef(4);
            myRef4.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "デマンド限界警報") {
            setActiveRef(5);
            myRef5.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "軽漏電警報") {
            setActiveRef(6);
            myRef6.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "重漏電警報") {
            setActiveRef(7);
            myRef7.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        
        if (value === "微地絡下限検出") {
            setActiveRef(8);
            myRef8.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "微地絡警戒検出") {
            setActiveRef(9);
            myRef9.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "微地絡警報") {
            setActiveRef(10);
            myRef10.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if (value === "地絡警報") {
            setActiveRef(11);
            myRef11.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
        if(value === "微地絡トレンド電流") {
            setActiveRef(12);
            myRef12.current.scrollIntoView({ behavior: 'auto' });
            setIsOnScroll(true);
        }
    }

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        let offsetTop = event.currentTarget.scrollTop + myRef1.current.offsetTop;
        if (isOnScroll) {
            setIsOnScroll(false);
            return;
        }
        if (offsetTop < myRef2.current.offsetTop) {
            setActiveRef(1);
        } else if (offsetTop >= myRef2.current.offsetTop && offsetTop < myRef3.current.offsetTop) {
            setActiveRef(2);
        } else if (offsetTop >= myRef3.current.offsetTop && offsetTop < myRef4.current.offsetTop) {
            setActiveRef(3);
        } else if (offsetTop >= myRef4.current.offsetTop && offsetTop < myRef5.current.offsetTop) {
            setActiveRef(4);
        } else if (offsetTop >= myRef5.current.offsetTop && offsetTop < myRef6.current.offsetTop) {
            setActiveRef(5);
        } else if (offsetTop >= myRef6.current.offsetTop && offsetTop < myRef7.current.offsetTop) {
            setActiveRef(6);
        } else if (offsetTop >= myRef7.current.offsetTop && offsetTop < myRef8.current.offsetTop) {
            setActiveRef(7);
        } else if (offsetTop >= myRef8.current.offsetTop && offsetTop < myRef9.current.offsetTop) {
            setActiveRef(8);
        } else if (offsetTop >= myRef9.current.offsetTop && offsetTop < myRef10.current.offsetTop) {
            setActiveRef(9);
        } else if (offsetTop >= myRef10.current.offsetTop && offsetTop < myRef11.current.offsetTop) {
            setActiveRef(10);
        }else if (offsetTop >= myRef11.current.offsetTop && offsetTop < myRef12.current.offsetTop){
            setActiveRef(11);
        }
        else{
            setActiveRef(12);
        }
    }

    let navigate = useNavigate();

    // Submitイベント
    const handleEditSubmit = (data: EditNotificationsForm) => {
        setLoadingFlag(true);
        const request = createRequestData(data);
        onUpdate(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: EditNotificationsForm) => {
        let address_info_array = [
            {
                code: "AB",
                slackNotifyFlag: formValue.address_info_array.powerFailure?.slackNotifyFlag ? formValue.address_info_array.powerFailure?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.powerFailure?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "AC",
                slackNotifyFlag: formValue.address_info_array.powerRestored?.slackNotifyFlag ? formValue.address_info_array.powerRestored?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.powerRestored?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "AD",
                slackNotifyFlag: formValue.address_info_array.contactAlarm?.slackNotifyFlag ? formValue.address_info_array.contactAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.contactAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "GB",
                slackNotifyFlag: formValue.address_info_array.demandAlertAlarm?.slackNotifyFlag ? formValue.address_info_array.demandAlertAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.demandAlertAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "GC",
                slackNotifyFlag: formValue.address_info_array.demandLimitAlarm?.slackNotifyFlag ? formValue.address_info_array.demandLimitAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.demandLimitAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "EB",
                slackNotifyFlag: formValue.address_info_array.lightLeakageAlarm?.slackNotifyFlag ? formValue.address_info_array.lightLeakageAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.lightLeakageAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "EC",
                slackNotifyFlag: formValue.address_info_array.heavyEarthLeakageAlarm?.slackNotifyFlag ? formValue.address_info_array.heavyEarthLeakageAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.heavyEarthLeakageAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "BA",
                slackNotifyFlag: formValue.address_info_array.subGroundFaultDetection?.slackNotifyFlag ? formValue.address_info_array.subGroundFaultDetection?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.subGroundFaultDetection?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "BB",
                slackNotifyFlag: formValue.address_info_array.subGroundWarningDetection?.slackNotifyFlag ? formValue.address_info_array.subGroundWarningDetection?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.subGroundWarningDetection?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "BC",
                slackNotifyFlag: formValue.address_info_array.subGroundFaultAlarm?.slackNotifyFlag ? formValue.address_info_array.subGroundFaultAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.subGroundFaultAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "BD",
                slackNotifyFlag: formValue.address_info_array.groundFaultAlarm?.slackNotifyFlag ? formValue.address_info_array.groundFaultAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.groundFaultAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
            {
                code: "BE",
                slackNotifyFlag: formValue.address_info_array.microEarthFaultPeakCurrentAlarm?.slackNotifyFlag ? formValue.address_info_array.microEarthFaultPeakCurrentAlarm?.slackNotifyFlag : undefined,
                addressList: formValue.address_info_array.microEarthFaultPeakCurrentAlarm?.addressList.filter(x => x.address !== '').map(item => item.address),
            },
        ];

        let address_info_array_request = address_info_array.filter(x => x.addressList?.length || x.slackNotifyFlag);

        let request = {
            mainid: mainid,
            address_info_array_count: address_info_array_request.length,
            address_info_array: address_info_array_request
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => navigate(-1);

    //タイトルの高さを戻
    React.useEffect(() => {
        onGetData(mainid);
        return () => {
            let div: any = document.getElementsByClassName('css-ua3wu6')[0];
            if (typeof (div) !== 'undefined' && div != null) {
                div.removeAttribute("style");
            }
        };
        // eslint-disable-next-line
    }, []);

    const handleUpdateSuccess = (data: any) => {
        const result = {
            resultCode: data.result_code,
            updateData: data.data,
        };
        if (result.resultCode === 1) {
            handleUpdateError();
        } else {
            setLoadingFlag(false);
            onUpdateSuccess();
        }
    }

    const handleUpdateError = () => {
        onUpdateError();
        setLoadingFlag(false);
    }

    const liStyles = {
        cursor: 'pointer',
        width: '90%',
        marginBottom: '3px',
        color: '#1890ff',
        backgroundColor: palette.action.hover,
        display: 'flex',
        alignItems: 'center',
    }

    const liStylesNormal = {
        cursor: 'pointer',
        width: '90%',
        marginBottom: '3px',
    }

    const handleAddRow = (code: any) => {
        switch (code) {
            case powerFailure.code:
                appendPowerFailure({ address: "" } as Item);
                break;
            case powerRestored.code:
                appendPowerRestored({ address: "" } as Item);
                break;
            case contactAlarm.code:
                appendContactAlarm({ address: "" } as Item);
                break;
            case demandAlertAlarm.code:
                appendDemandAlertAlarm({ address: "" } as Item);
                break;
            case demandLimitAlarm.code:
                appendDemandLimitAlarm({ address: "" } as Item);
                break;
            case lightLeakageAlarm.code:
                appendLightLeakageAlarm({ address: "" } as Item);
                break;
            case heavyEarthLeakageAlarm.code:
                appendHeavyEarthLeakageAlarm({ address: "" } as Item);
                break;
            case subGroundFaultDetection.code:
                appendSubGroundFaultDetection({ address: "" } as Item)
                break;
            case subGroundWarningDetection.code:
                appendSubGroundWarningDetection({ address: "" } as Item)
                break;
            case subGroundFaultAlarm.code:
                appendSubGroundFaultAlarm({ address: "" } as Item)
                break;
            case groundFaultAlarm.code:
                appendGroundFaultAlarm({ address: "" } as Item)
                break;
            case microEarthFaultPeakCurrentAlarm.code:
                appendMicroEarthFaultPeakCurrentAlarm({ address: "" } as Item);
                break;
        }
    }

    const handleDeleteRow = (obj: any, index: number) => {
        const name = "address_info_array." + obj.name + '.addressList' as any;
        const arrInput = getValues(name);
        switch (obj.code) {
            case powerFailure.code:
                removePowerFailure(index);
                arrInput.length === 1 && appendPowerFailure({ address: "" } as Item);
                break;
            case powerRestored.code:
                removePowerRestored(index);
                arrInput.length === 1 && appendPowerRestored({ address: "" } as Item);
                break;
            case contactAlarm.code:
                removeContactAlarm(index);
                arrInput.length === 1 && appendContactAlarm({ address: "" } as Item);
                break;
            case demandAlertAlarm.code:
                removeDemandAlertAlarm(index);
                arrInput.length === 1 && appendDemandAlertAlarm({ address: "" } as Item);
                break;
            case demandLimitAlarm.code:
                removeDemandLimitAlarm(index);
                arrInput.length === 1 && appendDemandLimitAlarm({ address: "" } as Item);
                break;
            case lightLeakageAlarm.code:
                removeLightLeakageAlarm(index);
                arrInput.length === 1 && appendLightLeakageAlarm({ address: "" } as Item);
                break;
            case heavyEarthLeakageAlarm.code:
                removeHeavyEarthLeakageAlarm(index);
                arrInput.length === 1 && appendHeavyEarthLeakageAlarm({ address: "" } as Item);
                break;
            case subGroundFaultDetection.code:
                removeSubGroundFaultDetection(index);
                arrInput.length === 1 && appendSubGroundFaultDetection({ address: "" } as Item)
                break;
            case subGroundWarningDetection.code:
                removeSubGroundWarningDetection(index);
                arrInput.length === 1 && appendSubGroundWarningDetection({ address: "" } as Item);
                break;
            case subGroundFaultAlarm.code:
                removeSubGroundFaultAlarm(index);
                arrInput.length === 1 && appendSubGroundFaultAlarm({ address: "" } as Item);
                break;
            case groundFaultAlarm.code:
                removeGroundFaultAlarm(index);
                arrInput.length === 1 && appendGroundFaultAlarm({ address: "" } as Item);
                break;
            case microEarthFaultPeakCurrentAlarm.code:
                removeMicroEarthFaultPeakCurrentAlarm(index);
                arrInput.length === 1 && appendMicroEarthFaultPeakCurrentAlarm({ address: "" } as Item);
                break;
        }
    }

    const handleFetchParam = (id: string, value: any) => {
        const params = new Map<string, any>();
        params.set(id, value);
        return params;
    }

    const handleFetchDeviceSuccess = (data: any) => {
        setDevice(data)
    }

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
    }

    // 取得正常終了時の初期表示
    const handleSuccess = (data: any) => {
        data.address_info_array.forEach((element: any) => {
            switch (element.code) {
                case powerFailure.code:
                    setValue("address_info_array.powerFailure.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.powerFailure.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .powerFailure?.addressList);
                    break;
                case powerRestored.code:
                    setValue("address_info_array.powerRestored.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.powerRestored.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .powerRestored?.addressList);
                    break;
                case contactAlarm.code:
                    setValue("address_info_array.contactAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.contactAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .contactAlarm?.addressList);
                    break;
                case demandAlertAlarm.code:
                    setValue("address_info_array.demandAlertAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.demandAlertAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .demandAlertAlarm?.addressList);
                    break;
                case demandLimitAlarm.code:
                    setValue("address_info_array.demandLimitAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.demandLimitAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .demandLimitAlarm?.addressList);
                    break;
                case lightLeakageAlarm.code:
                    setValue("address_info_array.lightLeakageAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.lightLeakageAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .lightLeakageAlarm?.addressList);
                    break;
                case heavyEarthLeakageAlarm.code:
                    setValue("address_info_array.heavyEarthLeakageAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.heavyEarthLeakageAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .heavyEarthLeakageAlarm?.addressList);
                    break;
                case subGroundFaultDetection.code:
                    setValue("address_info_array.subGroundFaultDetection.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.subGroundFaultDetection.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .subGroundFaultDetection?.addressList);
                    break;
                case subGroundWarningDetection.code:
                    setValue("address_info_array.subGroundWarningDetection.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.subGroundWarningDetection.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .subGroundWarningDetection?.addressList);
                    break;
                case subGroundFaultAlarm.code:
                    setValue("address_info_array.subGroundFaultAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.subGroundFaultAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .subGroundFaultAlarm?.addressList);
                    break;
                case groundFaultAlarm.code:
                    setValue("address_info_array.groundFaultAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.groundFaultAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .groundFaultAlarm?.addressList);
                    break;
                case microEarthFaultPeakCurrentAlarm.code:
                    setValue("address_info_array.microEarthFaultPeakCurrentAlarm.slackNotifyFlag", element.slackNotifyFlag);
                    setValue('address_info_array.microEarthFaultPeakCurrentAlarm.addressList', element.addressList.length > 0 ? element.addressList.map((item: any) => ({ address: item })) : initialValue.address_info_array
                        .microEarthFaultPeakCurrentAlarm?.addressList);
                    break;
            }
        });
        setLoadingFlag(false);
    }

    return (
        <div style={{ margin: 'auto 10px' }}>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <FetchHelper fetchId={'DEVICE_FETCH'}
                url={"/master/gateways/get"}
                method="GET"
                request={true}
                params={handleFetchParam('id', mainid)}
                onComplete={(success, data, error) => {
                    success ? handleFetchDeviceSuccess(data) : handleError(success, data, error)
                }}
            />
            <DeviceInformation deviceInformation={device} mainid={mainid ?? ''} title="通報編集" isDisplayDeviceInfo={true} isShowMenuButton={true}></DeviceInformation>
            <form onSubmit={handleSubmit(handleEditSubmit)} >
                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                    <Grid container spacing={1} direction="row">
                        <Grid item xs={2} width='20%' style={{ display: 'block', paddingTop: '20px', paddingLeft: '40px' }}>
                            <ul style={{ listStyle: 'none', paddingLeft: '0', margin: '0' }}>
                                <li onClick={() => scrollTo('停電')} style={activeRef === 1 ? liStyles : liStylesNormal}>停電</li>
                                <li onClick={() => scrollTo('復電')} style={activeRef === 2 ? liStyles : liStylesNormal}>復電</li>
                                <li onClick={() => scrollTo('接点警報')} style={activeRef === 3 ? liStyles : liStylesNormal}>接点警報</li>
                                <li onClick={() => scrollTo('デマンド注意警報')} style={activeRef === 4 ? liStyles : liStylesNormal}>デマンド注意警報</li>
                                <li onClick={() => scrollTo('デマンド限界警報')} style={activeRef === 5 ? liStyles : liStylesNormal}>デマンド限界警報</li>
                                <li onClick={() => scrollTo('軽漏電警報')} style={activeRef === 6 ? liStyles : liStylesNormal}>軽漏電警報</li>
                                <li onClick={() => scrollTo('重漏電警報')} style={activeRef === 7 ? liStyles : liStylesNormal}>重漏電警報</li>
                                
                                <li onClick={() => scrollTo('微地絡下限検出')} style={activeRef === 8 ? liStyles : liStylesNormal}>微地絡下限検出</li>
                                <li onClick={() => scrollTo('微地絡警戒検出')} style={activeRef === 9 ? liStyles : liStylesNormal}>微地絡警戒検出</li>
                                <li onClick={() => scrollTo('微地絡警報')} style={activeRef === 10 ? liStyles : liStylesNormal}>微地絡警報</li>
                                <li onClick={() => scrollTo('地絡警報')} style={activeRef === 11 ? liStyles : liStylesNormal}>地絡警報</li>
                                <li onClick={() => scrollTo('微地絡トレンド電流')} style={activeRef === 12 ? liStyles : liStylesNormal}>微地絡トレンド電流</li>

                            </ul>
                        </Grid>
                        <Grid item xs={10} style={{ height: 'calc(100vh -  265px)', minHeight: '300px', overflowY: 'auto' }} onScroll={handleScroll}>
                            <Grid direction='row' width="90%" margin="10px auto 0">
                                <Card ref={myRef1} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='停電'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack>
                                                <Controller
                                                    name="address_info_array.powerFailure.slackNotifyFlag"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                    )}
                                                />
                                            
                                            
                                        </Stack>
                                        <Stack spacing={1}>
                                            <span>宛先一覧</span>
                                            {
                                                powerFailureFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.powerFailure.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.powerFailure?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.powerFailure?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button"
                                                                                onClick={() => handleDeleteRow(powerFailure, index)}
                                                                            >
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(powerFailure.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Card ref={myRef2} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='復電'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.powerRestored.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                powerRestoredFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.powerRestored.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.powerRestored?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.powerRestored?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(powerRestored, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(powerRestored.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Card ref={myRef3} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='接点警報'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.contactAlarm.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                contactAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.contactAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.contactAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.contactAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(contactAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(contactAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Card ref={myRef4} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='デマンド注意警報'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.demandAlertAlarm.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                demandAlertAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.demandAlertAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.demandAlertAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.demandAlertAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(demandAlertAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(demandAlertAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Card ref={myRef5} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='デマンド限界警報'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.demandLimitAlarm.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                demandLimitAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.demandLimitAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.demandLimitAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.demandLimitAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(demandLimitAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(demandLimitAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Card ref={myRef6} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='軽漏電警報'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.lightLeakageAlarm.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                lightLeakageAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.lightLeakageAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.lightLeakageAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.lightLeakageAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(lightLeakageAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(lightLeakageAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Card ref={myRef7} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='重漏電警報'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.heavyEarthLeakageAlarm.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                heavyEarthLeakageAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.heavyEarthLeakageAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.heavyEarthLeakageAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.heavyEarthLeakageAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(heavyEarthLeakageAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(heavyEarthLeakageAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>



                                <Card ref={myRef8} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='微地絡下限検出'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.subGroundFaultDetection.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                subGroundFaultDetectionFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.subGroundFaultDetection.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.subGroundFaultDetection?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.subGroundFaultDetection?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(subGroundFaultDetection, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(subGroundFaultDetection.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Card ref={myRef9} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='微地絡警戒検出'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.subGroundWarningDetection.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                subGroundWarningDetectionFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.subGroundWarningDetection.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.subGroundWarningDetection?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.subGroundWarningDetection?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(subGroundWarningDetection, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(subGroundWarningDetection.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Card ref={myRef10} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='微地絡警報'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.subGroundFaultAlarm.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            
                                            <span>宛先一覧</span>
                                            {
                                                subGroundFaultAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.subGroundFaultAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.subGroundFaultAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.subGroundFaultAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(subGroundFaultAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(subGroundFaultAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Card ref={myRef11} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='地絡警報'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                                <Stack>
                                                    <Controller
                                                        name="address_info_array.groundFaultAlarm.slackNotifyFlag"
                                                        control={control}
                                                        render={({ field }: any) => (
                                                            <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                        )}
                                                    />
                                                </Stack>
                                            <span>宛先一覧</span>
                                            {
                                                groundFaultAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.groundFaultAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.groundFaultAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.groundFaultAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(groundFaultAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(groundFaultAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Card ref={myRef12} style={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title='微地絡トレンド電流'
                                    />
                                    <CardContent style={{ paddingTop: '0', width: '80%', margin: 'auto' }}>
                                        <Stack spacing={1}>
                                            <Stack>
                                                <Controller
                                                    name="address_info_array.microEarthFaultPeakCurrentAlarm.slackNotifyFlag"
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <FormControlLabel {...field} control={<Switch disabled={isDisabled} />} checked={field.value} label="Slack通知" />
                                                    )}
                                                />
                                            </Stack>
                                            <span>宛先一覧</span>
                                            {
                                                microEarthFaultPeakCurrentAlarmFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.microEarthFaultPeakCurrentAlarm.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="通報アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.microEarthFaultPeakCurrentAlarm?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.microEarthFaultPeakCurrentAlarm?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button" onClick={() => handleDeleteRow(microEarthFaultPeakCurrentAlarm, index)}>
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            <Grid item xs={1}>
                                                <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow(microEarthFaultPeakCurrentAlarm.code)} >
                                                    <AddIcon fontSize="small" />
                                                </Button>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack direction='row' justifyContent="flex-end" spacing={2} width="90%" margin="10px auto 0">
                    <Grid item xs={1.5}>
                        <Button fullWidth variant="contained" type="submit" disabled={disableSaveBtn}>保存</Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button fullWidth color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Grid>
                </Stack>
            </form >
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId="UPDATE_NOTIFICATIONS" onComplete={(success, data, error) => success ? handleUpdateSuccess(data) : handleUpdateError()} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "通報情報",
            body: "通報情報が取得出来ません。",
                    
        }),
    onCancel: () => push(RoutePath.DeviceList),
    onGetData: (mainid: any) => actionCreators.fetch(fetchId, `/master/notifications/get?mainid=${mainid}`, "GET", null),
    onUpdate: (data: any) => actionCreators.fetch("UPDATE_NOTIFICATIONS", `/master/notifications/edit`, 'POST', data, false, true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "通報変更",
            body: "通報を更新しました。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "通報更新",
            body: "通報を更新出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsDetails as any);