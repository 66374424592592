import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import LocationNameEdit from '../polling/pollingTopicEdit/LocationNameEdit';

export type SelectLocationNameDialogProps = {
    isOpen: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
    data: any;
};

export const SelectLocationNameDialog = (props: SelectLocationNameDialogProps) => {
    const { isOpen, onOK, onCancel, data } = props;
    const [open, setOpen] = React.useState(isOpen);

    // Cancelボタンイベント
    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const handleUpdate = (data: any) => {
        setOpen(false);
        onOK(data);
    };

    return (
        <div>
            <Dialog open={open} maxWidth={"md"} fullWidth>
                <DialogTitle>LocationName / 親機</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                    <LocationNameEdit onCancel={handleCancel} locationName={data} onOK={(data) => handleUpdate(data)}></LocationNameEdit>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SelectLocationNameDialog;