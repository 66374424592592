import { Box, Breakpoint } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardLayout from "./DashboardLayout";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import useResponsive from "../hooks/useResponsive";

export type LayoutProps = { children?: React.ReactNode };

export const Layout = (props: LayoutProps) => {
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(true);
    const isDesktop = useResponsive({ query: 'up', key: 'lg' as Breakpoint });

    const handleOpenMenu = () => {
        setOpenMenu(false);
        setOpen(false);
    };

    const handleMenu = () => {
        if(!openMenu){
            setOpenMenu(true);
        }
        if(!isDesktop){
            setOpen(true);
        }
    };

    return (
        <div style={{ display: "flex" }}>
            <div>
                <DashboardSidebar
                    isOpenSidebar={open}
                    onCloseSidebar={() => {
                        setOpen(false);
                    }}
                    onOpenMenu={handleOpenMenu}
                    openMenu = {openMenu}
                    {...props}
                />
            </div>
            <div style={{ width: "100%" }}>
                <Box mb={1}>
                    <DashboardNavbar
                        onOpenSidebar={handleMenu}
                        openMenu = {openMenu}
                    />
                </Box>

                <DashboardLayout
                    openMenu = {openMenu}>
                    <Outlet />
                </DashboardLayout>
            </div>
        </div>
    );
};
