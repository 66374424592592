import { Button, Grid, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { DemandSettingDataModel } from "../../../models";
import { formatDateTime, padZeroToStart } from "../../../utils";

export type DemandSettingEditFormProps = {
    demandSettingData: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const DemandSettingDataEdit = (props: DemandSettingEditFormProps) => {
    const { demandSettingData, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: DemandSettingDataModel = {
        mainid: demandSettingData.mainid,
        alarm2Kw: demandSettingData.alarm2Kw,
        alarm1Kw: demandSettingData.alarm1Kw,
        pulseCoefficient: demandSettingData.pulseCoefficient,
        demandTstop: demandSettingData.demandTstop,
        lastGetDatetime: demandSettingData.lastGetDatetime,
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<DemandSettingDataModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: DemandSettingDataModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: DemandSettingDataModel) => {
        let request = {
            mainid: formValue.mainid,
            alarm2_kw: formValue.alarm2Kw,
            alarm1_kw: formValue.alarm1Kw,
            pulse_coefficient: formValue.pulseCoefficient,
            demand_tstop: formValue.demandTstop,
            last_get_datetime: formatDateTime(new Date()),
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleInputOnBlur = (e: any, name: any, maxLength: number) => {
        setValue(name, padZeroToStart(e.target.value, maxLength));
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '90%', margin: 'auto' }}>
                    <Stack spacing={3}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} paddingTop={"10px"}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("alarm2Kw",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            }
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    label="限界電力"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.alarm2Kw}
                                    helperText={errors?.alarm2Kw?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'alarm2Kw', 4)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("alarm1Kw",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            }
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    label="注意電力"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.alarm1Kw}
                                    helperText={errors?.alarm1Kw?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'alarm1Kw', 4)}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("pulseCoefficient",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            }
                                        })
                                    }
                                    onBlur={(e) => handleInputOnBlur(e, 'pulseCoefficient', 8)}
                                    fullWidth
                                    type="text"
                                    label="パルス係数"
                                    size="small"
                                    inputProps={{ maxLength: 8 }}
                                    error={!!errors?.pulseCoefficient}
                                    helperText={errors?.pulseCoefficient?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("demandTstop",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: '0029',
                                                message: '0000~0029 の値を入力してください。',
                                            }
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    label="デマンド未判定時限"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.demandTstop}
                                    helperText={errors?.demandTstop?.message}
                                    onBlur={(e) => handleInputOnBlur(e, 'demandTstop', 4)}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        <Button variant="contained" type="submit" >保存</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default DemandSettingDataEdit;


