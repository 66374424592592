import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { CodeRestrictionArrayOptions, MobileSmartCustomerRoleValue, SmartCustomerRoleValue, SmartObserveArrayOptions, customerLabelName } from '../../../statics';
import PaginationCpn from '../../common/PaginationCpn';
import { PageData } from '../../../models';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -------------
// Props
export type UsersGridProps = {
    colState: any[];
    onSelect: (data: any) => void;
    keyword: string;
    authRole: any;
    onChangePassword:(props:any) => void;
    onChangeSearchCondition: (name: string, value: any) => void;
    customerList:any;
    openSearch:boolean;
    pageData: PageData;
    pageSize: number;
};

// -------------
// Component
export const UsersGrid = forwardRef((props: UsersGridProps, ref) => {
    const { pageData, pageSize, openSearch,authRole, colState, keyword, customerList, onChangeSearchCondition, onChangePassword, onSelect} = props;
    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any>();

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        setSelectRowData(value: any) {
            if (rowData) {
                let list = rowData?.filter((item: any) => item.username === value)
                list[0].isSelect = !list[0].isSelect;
            }
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const handleCheckedDelete = (props: any) => {
        onSelect(props.data)
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
    };

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        const { company_group_name, selected } = props.data;
        let isSelected = selected
        if (props.data.isSelect) {
            isSelected = true;
        }
        return (
            <FormControlLabel
                control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} onChange={() => handleCheckedDelete(props)} />}
                label={company_group_name}
                labelPlacement="end"
            />
        );
    };

    const cellRestrictionRenderer = (props: any) => {
        let result:any = "";
        if (props.data.code_restriction_array?.length > 0) {
            if(props.data.custom_role == SmartCustomerRoleValue || props.data.custom_role == MobileSmartCustomerRoleValue){
                const list_label = props.data.code_restriction_array.map((item:any) => {
                    if(SmartObserveArrayOptions.find((data) => data.value == item))
                        return SmartObserveArrayOptions.find((data) => data.value == item)?.label
                });                
                list_label.map((item:any) => {
                    result += item + '\n';
                })
            }else{
                const list_label = props.data.code_restriction_array.map((item:any) => {
                    if(CodeRestrictionArrayOptions.find((data) => data.value == item))
                        return CodeRestrictionArrayOptions.find((data) => data.value == item)?.label
                });                
                list_label.map((item:any) => {
                    result += item + '\n';
                })
            }
            return result;
        } else {
            return "全て";
        }
    };

    const cellRoleRenderer = (props: any) => {
        return getRoleName(props.data.custom_role)
    };

    const getRoleName = (value: any) => {
        if(authRole){
            const role = authRole.role_list.find((item:any) => item.role == value)
            if(role)
                return role.name;
        }
        return "";
    };

    const cellUsersCustomerArrayRenderer = (props: any) => {
        if (props.data.users_customer_array?.length >= 1) {
            let result = "";
            props.data.users_customer_array.forEach((item:any) => {
                result += customerList.find((data:any) => data.value == item)?.label + "\n";
            })
            return result;
        } else {
            return "";
        }
    };

    const setCompanyName = (props: any) => {
        let result = "〇〇株式会社" + props.data.custom_company;
        return result;
    };

    const handleEdit = (props:any) => {
        onChangePassword(props)
    }

    const cellChangePasswordRenderer = (props:any) => {
        let isDisable = true;
        // パスワード変更
        if(authRole && authRole.auth_role_map["MS-user-editpw"])
            isDisable = false;
        
        return (
            <>
                <IconButton disabled={isDisable} aria-label="edit" onClick={() => handleEdit(props.data)} style={{ marginLeft: '-10px' }} >
                    { isDisable ?
                        <EditIcon fontSize="small" sx={{ color: 'text.disabled', opacity: '0.5' }}/>
                        : <EditIcon fontSize="small"/>
                    }
                </IconButton>
            </>
        );
    }

    const setDepartmentName = (props: any) => {
        let result = props.data.custom_department;
        if (props.data.custom_department === "1") {
            result = "CRM部";
        }
        else if (props.data.custom_department === "2") {
            result = "ES事業部";
        }
        else if (props.data.custom_department === "3") {
            result = "SE事業部";
        }
        else if (props.data.custom_department === "4") {
            result = "品質検査部";
        }
        else if (props.data.custom_department === "5") {
            result = "秋田ネットワークセンター";
        }
        return result;
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "組織グループ名",
            field: "company_group_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace : 'pre' },
            cellRenderer: CellCheckRenderer,
            width: colState ? colState[0].width : 250,
        },
        {
            headerName: `${customerLabelName}名`,
            field: "company_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace : 'pre' },
            width: colState ? colState[1].width : 250,
        },
        {
            headerName: "氏名",
            field: "user_full_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace : 'pre' },
            width: colState ? colState[2].width : 300,
        },
        {
            headerName: "ロール",
            field: "custom_role",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace : 'pre' },
            cellRenderer: cellRoleRenderer,
            getQuickFilterText: (params:any) => {
                if(params.value){
                    return getRoleName(params.value);
                }
                return "";
            },
            width: colState ? colState[3].width : 200,
            comparator:(valueA:any,valueB:any) => {
            const a = getRoleName(valueA);
            const b = getRoleName(valueB);
            if (a == b) return 0;
                return (a > b) ? 1 : -1;
            }
        },
        {
            headerName: "メールアドレス",
            field: "email",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace : 'pre' },
            width: colState ? colState[4].width : 250,
        },
        {
            headerName: "監視対象",
            field: "code_restriction_array",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'  },
            valueGetter: cellRestrictionRenderer,
            width: colState ? colState[5].width : 150,
            wrapText:true,
            autoHeight:true,
        },
        {
            headerName: "担当事業所",
            field: "users_customer_array",
            rowDrag: false,
            cellStyle: { textAlign: 'left', whiteSpace: 'pre'},
            valueGetter: cellUsersCustomerArrayRenderer,
            width: colState ? colState[6].width : 150,
            wrapText:true,
            autoHeight:true,
        },
        {
            headerName: "パスワード変更",
            field: "change_password",
            rowDrag: false,
            cellStyle: { textAlign: 'center', whiteSpace: 'pre'  },
            cellRenderer: cellChangePasswordRenderer,
            width: colState ? colState[7].width : 150,
        }
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
              };
        }
        return {
            backgroundColor: 'white',
          };
    }
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? `calc(100vh - 285px)` : `calc(100vh - 240px)`, width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        getRowStyle={getRowStyle}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default UsersGrid;
