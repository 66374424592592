import { connect } from "react-redux";
import { ApplicationState, actionCreators } from "../../../store";
import { Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Controller, useForm } from "react-hook-form";
import palette from "../../../theme/palette";
import { Path as RoutePath } from "../../../path";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CustomersGroupGrid from "./CustomersGroupGrid";
import { PageData } from "../../../models";
import FetchHelper from "../../FetchHelper";
import { Html } from "@mui/icons-material";
import CustomersGroupEdit from "./CustomersGroupEdit";
import AlertDialog from "../../common/AlertDialog";

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
    "keyword": string;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 0,
    keyword: '',
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};
interface AddCustomersGroup {
    name: string,
    managerName: string,
    note: string,
}
const initialAddCustomersGroup: AddCustomersGroup = {
    name: "",
    managerName: "",
    note: "",
}
const GET_ALL_CUSTOMERS_GROUP = "GET_ALL_CUSTOMERS_GROUP";
const ADD_CUSTOMERS_GROUP = "ADD_CUSTOMERS_GROUP"
const EDIT_CUSTOMERS_GROUP = "EDIT_CUSTOMERS_GROUP"
const DELETE_CUSTOMERS_GROUP = "DELETE_CUSTOMERS_GROUP"

export type CustomersGroupListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

export const CustomersGroupList = (props: CustomersGroupListProps) => {
    // #region props
    const { onSearch,
        onAddCustomersGroup,
        onEditCustomersGroup,
        onDeleteCustomersGroup,
        onDeleteError,
        onDeleteSuccess,
        onAddSuccess,
        onAddError,
        onEditSuccess,
        onEditError} = props;
    const gridRef = useRef() as any;
    const [pageData, setPageData] = React.useState(initialPageData);
    const [colState, setColState] = React.useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [openSearch, setOpenSearch] = React.useState(false);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [disableDelete, setDisableDelete] = React.useState(true);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [openAddCustomersGroup, setOpenAddCustomersGroup] = React.useState(false);
    const [openEditCustomersGroup, setOpenEditCustomersGroup] = React.useState(false);
    const [openDeleteCustomersGroup, setDeleteCustomersGroup] = React.useState(false);

    const [listData, setListData] = React.useState<any>([]);
    const [selectData, setSelectData] = React.useState<any>();
    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    localStorage.setItem('title', `組織グループ`);
    localStorage.setItem('lastActiveFormPath', RoutePath.CustomersGroupList);
    // #endregion

    // #region method

    useEffect(() => {
        handleSearchSubmit(getValues());
    },[])

    // ハンドルオープン
    const handleOpen = () => {
        // if (gridRef && gridRef.current) {
        //     setColState(gridRef.current.saveState());
        // }
        setOpenSearch((prev: boolean) => !prev);
    };

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.keyword) params.set("keyword", value.keyword);
        setLoadingFlag(true);
        onSearch(params);
    }

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("keyword", '')
    };

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    // 追加の処理
    const handleAdd = () => {
        setSelectData(initialAddCustomersGroup)
        setOpenAddCustomersGroup(true)
    }
    const handleAddCustomersGroup = (data:any) =>{
        setLoadingFlag(true)
        onAddCustomersGroup(data)
    }
    const handleCancelAddCustomersGroup = () =>{
        setOpenAddCustomersGroup(false)
    }

    // 編集の処理
    const handleEdit = () => {
        var customerSelect = listData.filter((item:any) => item.isSelect === true)[0]
        setSelectData(customerSelect)
        setOpenEditCustomersGroup(true)
        
    }
    const handleEditCustomersGroup = (data:any) =>{
        setLoadingFlag(true);
        onEditCustomersGroup(data)
    }
    const handleCancelEditCustomersGroup = () =>{
        setOpenEditCustomersGroup(false)
    }

    //削除の処理
    const handleDelete = () => {
        const deleteNames = listData.filter((item:any) => item.isSelect === true).map((item:any) => item.name);
        const msg = "組織グループ : " + deleteNames.join(', ') + "を削除しますか？"
        setTitle("組織グループ削除")
        setMessage(msg)
        setDeleteCustomersGroup(true)
    }
    const handleDeleteCustomersGroup = () =>{
        setLoadingFlag(true);
        listData.filter((item:any) => item.isSelect === true).forEach((item:any) => {
            onDeleteCustomersGroup(item.group_id.toString());
        })
    }
    const handleDeleteCancel = () =>{
        setDeleteCustomersGroup(false)
    }

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    // 検索正常終了時
    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        let list = listData.filter((item:any) => item.group_id === data.group_id)
        list[0].isSelect = !list[0].isSelect;

        let selectList = listData.filter((item:any) => item.isSelect === true);
        if (selectList.length > 0){
            if (selectList.length === 1) {
                setDisableEdit(false);
            } else  {
                setDisableEdit(true);
            }
            if(selectList.find((item:any) => item.group_id == 2 || item.group_id == 1)){
                setDisableDelete(true);
            }else{
                setDisableDelete(false);
            }
        }
        else {
            setDisableEdit(true);
            setDisableDelete(true);
        }
    };

    const handleGetData = (data: any) => {
        data.forEach((element: any) => {
            element.isSelect = false;
        });
        return data;
    };

    const handleSuccessGetAll = (data:any) => {
        setLoadingFlag(false);
        setDisableDelete(true);
        setDisableEdit(true);
        if(data && data.result_code == 0){
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(handleGetData(data.results));
            }
            setListData(() => [...handleGetData(data.results)]);

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
    }

    const handleError = (success:any, data:any, type:any) => {
        setLoadingFlag(false);
    }
    const handleSuccess = (data:any, type: string) =>{
        if(data.result_code === 0){
            switch(type){
                case ADD_CUSTOMERS_GROUP:
                    onAddSuccess()
                    setOpenAddCustomersGroup(false)
                    break;
                case EDIT_CUSTOMERS_GROUP:
                    onEditSuccess()
                    setOpenEditCustomersGroup(false)
                    break;
                case DELETE_CUSTOMERS_GROUP:
                    onDeleteSuccess()
                    setDeleteCustomersGroup(false)
                    break;
            }
            handleSearchSubmit(getValues())
        }
        else{
            switch(type){
                case ADD_CUSTOMERS_GROUP:
                    onAddError()
                    setOpenAddCustomersGroup(false)
                    break;
                case EDIT_CUSTOMERS_GROUP:
                    onEditError()
                    setOpenEditCustomersGroup(false)
                    break;
                case DELETE_CUSTOMERS_GROUP:
                    let message = "組織グループ情報が削除出来ませんでした。"
                    if(data.message !== "" || data.message !== undefined){
                        message = data.message
                    }
                    onDeleteError(message)
                    setDeleteCustomersGroup(false)
                    break;
            }
            setLoadingFlag(false);
        }
    }

    // #endregion

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                {loadingFlag && (
                    <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                        <CircularProgress />
                    </div>
                )}
                <div>
                    <form onSubmit={handleSubmit(handleSearch)}>
                        <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                            <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={handleOpen}>
                                <div style={{ display: 'inline-block' }}>
                                    <Typography variant="h6" >検索条件</Typography>
                                </div>
                                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                    {
                                        openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                    }
                                </div>
                            </div>
                            <div style={{ marginLeft: '10px', marginRight: '10px', marginBottom: "10px" }}>
                                <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems={"end"}
                                        spacing={1}
                                        direction="row"
                                        style={{ alignItems: 'center' }}
                                    >
                                        <Grid item>
                                            <Stack direction="row">
                                                <Controller
                                                    name="keyword"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            label="検索キーワード"
                                                            size="small"
                                                            style={{ background: palette.background.paper }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                        </Grid>
                                        <Grid item >
                                            <Button variant="contained" onClick={handleSearch}>検索</Button>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </div>
                        </Stack>
                    </form >
                </div>
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} mb={1} spacing={1}>
                        <Button variant="contained" size="medium" onClick={handleAdd}>{<AddIcon />}</Button>
                        <Button variant="contained" size="medium" onClick={handleEdit} disabled={disableEdit}>{<EditIcon />}</Button>
                        <Button variant="contained" size="medium" onClick={handleDelete} disabled={disableDelete}>{<ClearIcon />} </Button>
                    </Stack>
                </div>
                <div>
                    <CustomersGroupGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        onSelect={handleSelect}
                        openSearch={openSearch}
                    />
                </div>
            </div>
            {openAddCustomersGroup?
                <Dialog open={openAddCustomersGroup} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                    <DialogTitle>組織グループ追加</DialogTitle>
                    <DialogContent sx={{ pb: 1.5 }}>
                        <CustomersGroupEdit
                            customersGroup={selectData}
                            onOK={handleAddCustomersGroup}
                            onCancel={handleCancelAddCustomersGroup}
                        ></CustomersGroupEdit>
                    </DialogContent>
                </Dialog>
                :
                <div />
            }
            {openEditCustomersGroup?
                <Dialog open={openEditCustomersGroup} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                    <DialogTitle>組織グループ編集</DialogTitle>
                    <DialogContent sx={{ pb: 1.5 }}>
                        <CustomersGroupEdit
                            customersGroup={selectData}
                            onOK={handleEditCustomersGroup}
                            onCancel={handleCancelEditCustomersGroup}
                        ></CustomersGroupEdit>
                    </DialogContent>
                </Dialog>
                :
                <div />
            }
            {openDeleteCustomersGroup ?
                <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteCustomersGroup} onCancel={handleDeleteCancel} /> :
                <div />
            }

            <FetchHelper fetchId={GET_ALL_CUSTOMERS_GROUP} onComplete={(success, data, error) =>
                success ? handleSuccessGetAll(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={ADD_CUSTOMERS_GROUP} onComplete={(success, data, error) =>
                success ? handleSuccess(data, ADD_CUSTOMERS_GROUP) : handleError(success, data, error)} />
            <FetchHelper fetchId={EDIT_CUSTOMERS_GROUP} onComplete={(success, data, error) =>
                success ? handleSuccess(data, EDIT_CUSTOMERS_GROUP) : handleError(success, data, error)} />
            <FetchHelper fetchId={DELETE_CUSTOMERS_GROUP} onComplete={(success, data, error) =>
                success ? handleSuccess(data, DELETE_CUSTOMERS_GROUP) : handleError(success, data, error)} />
        </>
    )

}


const mapStateToProps = (state: ApplicationState) => ({
});
const mapDispatchToProps = {   
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(GET_ALL_CUSTOMERS_GROUP, `/master/customer-groups/get`, "GET", params),
    onAddCustomersGroup: (params:any ) => actionCreators.fetch(ADD_CUSTOMERS_GROUP, `/master/customer-groups/add`, "POST", params, false, true),
    onEditCustomersGroup: (params:any ) => actionCreators.fetch(EDIT_CUSTOMERS_GROUP, `/master/customer-groups/edit`, "POST", params,  false, true),
    onDeleteCustomersGroup: (id:any ) => actionCreators.fetch(DELETE_CUSTOMERS_GROUP, `/master/customer-groups/delete?id=${id}`, "GET", null),
    onAddSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "組織グループ追加",
            body: "組織グループ情報を登録しました。",
    }),
    onAddError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "組織グループ追加",
            body: "組織グループ情報が登録出来ませんでした。",
    }),
    onEditSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "組織グループ編集",
            body: "組織グループ情報を更新しました。",
    }),
    onEditError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "組織グループ編集。",
            body: "組織グループ情報が更新出来ませんでした。",
    }),
    onDeleteSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "組織グループ削除",
            body: "組織グループ情報を削除しました。",
    }),
    onDeleteError: (message:any) =>
        actionCreators.showMessage({
            type: "error",
            title: "組織グループ削除",
            body: message,
    }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersGroupList as any);