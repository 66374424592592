import CachedIcon from '@mui/icons-material/Cached';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { LeakageInformationModel } from '../../../models';

import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import { showUnit } from '../../../utils';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 4;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
}

type LeakageInformationProps = {
    defaultData: LeakageInformationModel[] | undefined;
};

export type PureLeakageInformationProps = LeakageInformationProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

const fetchId = "LEAKAGE_INFORMATION_FETCH";
export const LeakageInformation = (props: PureLeakageInformationProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isRefreshGroup, isDisbleRefeshBtn } = props;
    const { onError, onRefresh, isRefreshAllCompleted, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [leakageInformation0, setLeakageInformation0] = React.useState<LeakageInformationModel>(fetchState.data);
    const [leakageInformation1, setLeakageInformation1] = React.useState<LeakageInformationModel>(fetchState.data);
    const [leakageInformation2, setLeakageInformation2] = React.useState<LeakageInformationModel>(fetchState.data);
    const [leakageInformation3, setLeakageInformation3] = React.useState<LeakageInformationModel>(fetchState.data);
    const [leakageInformation4, setLeakageInformation4] = React.useState<LeakageInformationModel>(fetchState.data);
    const [fetchLoadingDevice0, setFetchLoadingDevice0] = React.useState(false);
    const [fetchLoadingDevice1, setFetchLoadingDevice1] = React.useState(false);
    const [fetchLoadingDevice2, setFetchLoadingDevice2] = React.useState(false);
    const [fetchLoadingDevice3, setFetchLoadingDevice3] = React.useState(false);
    const [fetchLoadingDevice4, setFetchLoadingDevice4] = React.useState(false);
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const deviceNos = ['00', '01', '02', '03', '04'];
    const deviceNames = ['本体', '子機1', '子機2', '子機3', '子機4'];
    const [isRefreshSuccess0, setIsRefreshSuccess0] = React.useState(false);
    const [isRefreshSuccess1, setIsRefreshSuccess1] = React.useState(false);
    const [isRefreshSuccess2, setIsRefreshSuccess2] = React.useState(false);
    const [isRefreshSuccess3, setIsRefreshSuccess3] = React.useState(false);
    const [isRefreshSuccess4, setIsRefreshSuccess4] = React.useState(false);

    // パラメータ設定
    const handleFetchParams = (deviceNo: string) => {
        const params = new Map<string, any>();
        const code = "PH";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", deviceNo);
        return params;
    }

    // デマンド現在値取得成功時
    const handleFetchSuccess = (data: any, isClickedRefreshBtn: boolean) => {
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            leakMa1: data.leak_ma1,
            leakMa2: data.leak_ma2,
            leakMa3: data.leak_ma3,
            leakMa4: data.leak_ma4,
            leakMa5: data.leak_ma5,
            leakMa6: data.leak_ma6,
            di1: data.di_1,
            di2: data.di_2,
            lastGetDatetime: data.last_get_datetime,
        } as LeakageInformationModel;

        switch (data.device_no) {
            case deviceNos[0]:
                setLeakageInformation0(resData);
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                isClickedRefreshBtn && setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                setLeakageInformation1(resData);
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                isClickedRefreshBtn && setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                setLeakageInformation2(resData);
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                isClickedRefreshBtn && setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                setLeakageInformation3(resData);
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                isClickedRefreshBtn && setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                setLeakageInformation4(resData);
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                isClickedRefreshBtn && setIsRefreshSuccess4(true);
        }
    };

    const handleError = (success: boolean, data: any, error: any, deviceNo: string) => {
        onError(success, data, error, deviceNames[Number(deviceNo)]);
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                setIsRefreshSuccess4(false);
                break;
        }
    }

    const handleRefresh = (deviceNo: string) => {
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
    };

    const handleRefreshSuccess = (data: any, deviceNo: string) => {
        handleFetchSuccess(data, true);
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh(deviceNos[0]);
        }
        if (isRefreshGroup === true) {
            handleRefresh(deviceNos[0]);
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, loadingRefreshAll, isRefreshGroup]);

    const handleDisabledRefreshAll = () => {
        if (!fetchLoadingDevice0 && !fetchLoadingDevice1 && !fetchLoadingDevice2 && !fetchLoadingDevice3 && !fetchLoadingDevice4) {
            setDisabledRefreshGroupBtn(false, fetchId);
        } else {
            setDisabledRefreshGroupBtn(true, fetchId);
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevice0, fetchLoadingDevice1, fetchLoadingDevice2, fetchLoadingDevice3, fetchLoadingDevice4]);

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);
    return (
        <>
            {/* device00 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess0 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('00')} disabled={isDisbleRefeshBtn || fetchLoadingDevice0 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='漏電情報/本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice0 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.leakMa1}{showUnit(leakageInformation0?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.leakMa2}{showUnit(leakageInformation0?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.leakMa3}{showUnit(leakageInformation0?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.leakMa4}{showUnit(leakageInformation0?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.leakMa5}{showUnit(leakageInformation0?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.leakMa6}{showUnit(leakageInformation0?.leakMa6, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_1</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.di1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_2</td>
                                    <td style={pollingColStyle}>{leakageInformation0?.di2}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{leakageInformation0?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device01 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess1 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <div>
                                <IconButton aria-label="refresh" onClick={() => handleRefresh('01')} disabled={isDisbleRefeshBtn || fetchLoadingDevice1 || isFetchAll}>
                                    <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                </IconButton>
                            </div>
                        </div>
                    }
                    title='漏電情報/子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice1 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.leakMa1}{showUnit(leakageInformation1?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.leakMa2}{showUnit(leakageInformation1?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.leakMa3}{showUnit(leakageInformation1?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.leakMa4}{showUnit(leakageInformation1?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.leakMa5}{showUnit(leakageInformation1?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.leakMa6}{showUnit(leakageInformation1?.leakMa6, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_1</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.di1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_2</td>
                                    <td style={pollingColStyle}>{leakageInformation1?.di2}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{leakageInformation1?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device02 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess2 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('02')} disabled={isDisbleRefeshBtn || fetchLoadingDevice2 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='漏電情報/子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice2 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.leakMa1}{showUnit(leakageInformation2?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.leakMa2}{showUnit(leakageInformation2?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.leakMa3}{showUnit(leakageInformation2?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.leakMa4}{showUnit(leakageInformation2?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.leakMa5}{showUnit(leakageInformation2?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.leakMa6}{showUnit(leakageInformation2?.leakMa6, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_1</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.di1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_2</td>
                                    <td style={pollingColStyle}>{leakageInformation2?.di2}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{leakageInformation2?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device03 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess3 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('03')} disabled={isDisbleRefeshBtn || fetchLoadingDevice3 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='漏電情報/子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice3 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.leakMa1}{showUnit(leakageInformation3?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.leakMa2}{showUnit(leakageInformation3?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.leakMa3}{showUnit(leakageInformation3?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.leakMa4}{showUnit(leakageInformation3?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.leakMa5}{showUnit(leakageInformation3?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.leakMa6}{showUnit(leakageInformation3?.leakMa6, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_1</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.di1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_2</td>
                                    <td style={pollingColStyle}>{leakageInformation3?.di2}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{leakageInformation3?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device04 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess4 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('04')} disabled={isDisbleRefeshBtn || fetchLoadingDevice4 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='漏電情報/子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice4 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>接点DI_2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.leakMa1}{showUnit(leakageInformation4?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.leakMa2}{showUnit(leakageInformation4?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.leakMa3}{showUnit(leakageInformation4?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.leakMa4}{showUnit(leakageInformation4?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.leakMa5}{showUnit(leakageInformation4?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.leakMa6}{showUnit(leakageInformation4?.leakMa6, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_1</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.di1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>接点DI_2</td>
                                    <td style={pollingColStyle}>{leakageInformation4?.di2}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{leakageInformation4?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >
            <FetchHelper fetchId={fetchId + deviceNos[0]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[0]) : handleError(success, data, error, deviceNos[0])} />
            <FetchHelper fetchId={fetchId + deviceNos[1]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[1]) : handleError(success, data, error, deviceNos[1])} />
            <FetchHelper fetchId={fetchId + deviceNos[2]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[2]) : handleError(success, data, error, deviceNos[2])} />
            <FetchHelper fetchId={fetchId + deviceNos[3]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[3]) : handleError(success, data, error, deviceNos[3])} />
            <FetchHelper fetchId={fetchId + deviceNos[4]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[4]) : handleError(success, data, error, deviceNos[4])} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onRefresh: (params: any, fetchId: string) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `漏電情報/${deviceName}取得`,
            body: "漏電情報取得できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeakageInformation);

