import WarningIcon from '@mui/icons-material/Warning';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import PaginationCpn from '../../common/PaginationCpn';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';

// -------------
// Props
export type DemandDataGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    pulseType: string;
    mainid: string | null;
};

// -------------
// Component
export const DemandDataGrid = forwardRef((props: DemandDataGridProps, ref) => {
    const {
        colState,
        pageSize,
        pageData,
        onChangeSearchCondition,
        pulseType,
        mainid,
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },
        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const cellStatusRenderer = (props: any) => {
        if (props.data.status !== '0') {
            return <>
                <WarningIcon fontSize="small" style={{ verticalAlign: 'text-top', color: "red" }} /> {props.data.status_name}
            </>
        }
        return (
            <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.data.status_name}
            </>
        );
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "No.",
            field: "id",
            rowDrag: false,
            width: colState ? colState[0].width : 188,
        },
        {
            headerName: "状況",
            field: "status_name",
            rowDrag: false,
            cellRenderer: cellStatusRenderer,
            width: colState ? colState[1].width : 450,
        },
        {
            headerName: "受信日時",
            field: "occurrence_time",
            rowDrag: false,
            width: colState ? colState[2].width : 350,
        },
        {
            headerName: "デマンド終了時間",
            field: "end_time",
            rowDrag: false,
            width: colState ? colState[3].width : 350,
        },
        {
            headerName: "kWh",
            field: "kw",
            rowDrag: false,
            width: colState ? colState[4].width : 250,
        },
    ];

    const rowHeight = 48;

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 325px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        cacheQuickFilter={true}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn
                    pageData={pageData}
                    onChangeSearchCondition={onChangeSearchCondition}
                    limit={pageSize}
                    exportCsv={{ gridApi: gridRef?.current?.api, name: pulseType === "1" ? "デマンド一覧" : "太陽光発電一覧", mainid: mainid }}
                    enabledExportCsv={true}
                />
            </div>
        </>
    );
});

export default DemandDataGrid;
