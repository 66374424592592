import { Button, Checkbox, CircularProgress, FormControlLabel, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import ChartPanel, { ChartResult } from "../../chart/ChartPanel";
import FetchHelper from "../../FetchHelper";
import { Progress } from "./Progress";
import { Chart } from "chart.js";

const fetchId = "321_DEMANDLP_LIST";
const formId = "321_DEMANDLP_SEARCH_FORM";
const menusFetchId = 'MENUS_FETCH';
const COPY_LINK = "COPY_LINK";

// -------------
// Props
export type Gaex1Props = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------

export interface Gaex1Data {
    dataChart1: number[],
    dataChart2: number[],
    dataChart3: number[],
    dataChart4: number[],
    dataChart5: number,

}
// Component
export const Gaex1 = (props: Gaex1Props) => {
    const {
        onCopyLink,
        onError,
        onSearch,
        onErrorSearch,
        onSuccessCopy,
        onErrorCopy,
        onGetAuthRole,
        fetchStateMenu,
    } = props;

    let navigate = useNavigate();
    const [year] = React.useState(new Date().getFullYear());
    const [month] = React.useState(new Date().getMonth() + 1);
    const [day] = React.useState(new Date().getDate());
    const [chartModel, setChartModel] = React.useState<ChartResult>();
    const [progressData, setProgressData] = React.useState<any>([]);
    const [checked, setChecked] = React.useState(false);
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [authRole, setAuthRole] = React.useState<any>();

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const mainid = urlParams.get('mainid');

    const labels = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5];
    const CreateGaex1Data = (data : any) => {
        return {
            dataChart1: data.days_data[1].halfhours_data.map((item: any) => item.used_kw),
            dataChart2: data.days_data[0].halfhours_data.map((item: any) => item.used_kw),
            dataChart3: labels.map(() => data.contract_kw === 0 ? null : data.contract_kw),
            dataChart4: labels.map(() => data.alarm_kw === 0 ? null : data.alarm_kw),
            dataChart5: data.th2nd_limit_kw ? data.th2nd_limit_kw : 0,
            dataChart6: labels.map(() => data.th1st_caution_kw === 0 ? null : data.th1st_caution_kw),
            th3rd_contract_Flag: data.th3rd_contract_visible == "1" ? true : false,
            th1st_caution_flag: data.th1st_caution_visible == "1" ? true : false,
            list_source_type_today: data.days_data[1].halfhours_data.map((item: any) => item.source_type),
            list_temperature_today: data.days_data[1].halfhours_data.map((item: any) => item.temperature),
            list_condition_today: data.days_data[1].halfhours_data.map((item: any) => item.condition),
            list_solar_radiation_today: data.days_data[1].halfhours_data.map((item: any) => item.solar_radiation),
            list_precipitation_today: data.days_data[1].halfhours_data.map((item: any) => item.precipitation),
        } as Gaex1Data;
    };

    useEffect(()=> {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    const reCreateChartData = (data : any) => {
        return {
            chartId: 1,
            labels: labels,
            dataChart: CreateGaex1Data(data),
        } as ChartResult;
    };

    // 検索実行
    const handleSearchSubmit = () => {
        let isError = false;
        if(isEmpty(mainid)) isError = true;

        const params = new Map<string, any>();
        params.set("code", "GAEX1")
        params.set("mainid", mainid)
        params.set("year", year)
        params.set("month", month)
        params.set("day", day)

        if (!isError) {
            setLoadingFlag(true);
            onSearch(params);
        }
        else{
            onErrorSearch();
        }
    }

    const isEmpty = (value: any) => {
        return (value === null || value?.length <=0)
    }

    const handleFetchSuccess = (data: any) => {
        const chartData = reCreateChartData(data);
        setChartModel(chartData);

        setProgressData(data);
        setLoadingFlag(false);
    };

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
    }

    const onRefreshChart = () => {
        handleSearchSubmit();
    };

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit();
        // eslint-disable-next-line
    }, []);

    // キャンセル
    const handleCancel = () => {
        let path = sessionStorage.getItem("references");
        if(!path){
            if(authRole.auth_role_map["LM-telemetry-view"])
            {
                if(authRole.code_restriction_array?.length == 0 || authRole.code_restriction_array?.includes("GADEMAND")){
                    let newPath = "/telemetry/ga";
                    let newUrlPath = (urlParams.get('path'))
                    if(newUrlPath){
                        newUrlPath = (urlParams.get('path') ?? "root");
                        newPath +='?path=' + newUrlPath;
                        newPath += id ? '&id=' + id : "";
                        if (newUrlPath=="customer") {
                            newPath += '&customer_id=' + (urlParams.get('customer_id') ?? '');
                        }
                    }else{
                        newPath += "?path=root";
                    }
                    if(newPath){
                        navigate(newPath, { replace: true });
                        return;
                    }
                }
            }
            if(authRole.auth_role_map["LM-smonitor-view"]){
                if(authRole.code_restriction_array?.includes("GADEMAND")){
                    navigate(RoutePath.SmartDemandList);
                    return;
                }
            }
            navigate("/");
            return;
        }
        sessionStorage.removeItem('references');
        let urlPath = (urlParams.get('path'))
        if(urlPath){
            urlPath = (urlParams.get('path') ?? "root");
            path +='?path=' + urlPath;
            path += id ? '&id=' + id : "";
            if (urlPath=="customer") {
                path += '&customer_id=' + (urlParams.get('customer_id') ?? '');
            }
        }else{
            path += id ? '?id=' + id : "";
        }
        if(path)
            navigate(path, { replace: true });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleFetchError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        if(checked){
            setProgressData({...progressData});
        }
    }
    
    const handleNavigateMonthlyReport = () => {
        navigate(`${RoutePath.Gaex2}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
    }
    
    const handleNavigateYearlyReport = () => {
        navigate(`${RoutePath.Gaex5}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
    }

    const handleCopyLinkDemandReport = () => {
        setLoadingFlag(true);
        const params = {
            code: "OUTURL",
            type: "GAEX1",
            customer_id: urlParams.get("customer_id"),
            mainid: mainid,
        }
        onCopyLink(params);
    }

    const handleSuccessCopyLink =  async (data:any) => {
        setLoadingFlag(false);
        if(data && data.result_code == 0){
            const string = `${data.results.generate_url}`.toString();
            try {
                await navigator.clipboard.writeText(string);
                onSuccessCopy();
            } catch (err) {
                onErrorCopy("");
            }
        }else{
            onErrorCopy("");
        }
    }

    const handleErrorCopyLink = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
        onErrorCopy("");
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <Progress progressData={progressData} onRefreshChart={onRefreshChart} stopFlag={checked} onBack={handleCancel} onMonthlyReport={handleNavigateMonthlyReport} onYearlyReport={handleNavigateYearlyReport} onCopyLink={handleCopyLinkDemandReport} ></Progress>
                <div style={{ maxWidth: '500px', margin: 'auto'}} >
                    <ChartPanel chartModel={chartModel} />
                    <div style={{ textAlign: 'center',marginLeft:"50px" }}>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={checked}
                                    onChange={handleChange}/>}
                                    label="自動更新" 
                                />
                    </div>
                </div>
            </div>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleFetchSuccess(data) : handleFetchError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={COPY_LINK} onComplete={(success, data, error) => {
                success ? handleSuccessCopyLink(data) : handleErrorCopyLink(success, data, error)}} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onCopyLink: (params:any) => actionCreators.fetch(COPY_LINK,`/telemetry/get?code=${params.code}&type=${params.type}&customer_id=${params.customer_id}&mainid=${params.mainid}`,"GET",null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－スマート検索",
            body: "デマンド－スマートを検索できません。" + error,
        }),
    onErrorSearch: () =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－スマート検索",
            body: "デマンド－スマートを検索できません。" ,
        }),
    onSuccessCopy: () => 
        actionCreators.showMessage({
            type: "info",
            title: "URLをコピー",
            body: "URLをコピーしました。",
        }),
    onErrorCopy: (message:any) => 
        actionCreators.showMessage({
            type: "error",
            title: "URLをコピー",
            body: "URLをコピーできません。" ,
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gaex1 as any);
