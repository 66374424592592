import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Button, CircularProgress, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import FetchHelper from "../../FetchHelper";

const FETCH_ID_COORDINATE = "FETCH_ID_COORDINATE";
export type WeathersAddFormProps = {
    onOK: (data: any) => void;
    onCancel: () => void;
    customerList: any[];
    gatewayList: any[];
    handleNext:() => void;
    handleBack:() => void;
    onError:(success:any,data:any,error:any) => void;
    showDialogError:(message:string) => void;
};

// 検索フォーム
export interface ItemForm {
    "address_in": string;
    "address_out": string;
    "latitude": string;
    "longitude":string;
    "collect_flag": number;
    "ob_point_name":string;
}

// 初期値設定
const initialValue: ItemForm = {
    address_in: "",
    address_out:"",
    latitude:"",
    longitude:"",
    collect_flag: 1,
    ob_point_name:"",
}

export const WeathersAdd = (props: WeathersAddFormProps) => {
    const { showDialogError,onOK, onCancel,handleBack,handleNext,onError } = props;
    const [confirmFlag,setConfirmFlag] = React.useState(false);
    const [loadingFlag,setLoadingFlag] = React.useState<boolean>(false);

    const { handleSubmit, formState: { errors }, control, setValue, getValues,trigger  } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: any) => {
        let request = {
            ob_point_name: formValue.ob_point_name,
            address_in: formValue.address_in,
            address_out: formValue.address_out,
            latitude:   formValue.latitude,
            longitude:  formValue.longitude,
            collect_flag: formValue.collect_flag,
            } as any
        return request;
    };


    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeRestricted = (e: any, value: any) => {
        if (value != null) {
            if (value !== '1'){
                setValue('collect_flag', 0);
            }
            setValue("collect_flag", value);
        }
    };

    const handleClickNext = () => {
        setLoadingFlag(true);
        
    }

    const handleFetchLatitudeLongitudeSuccess = (success:any,data:any,error:any) => {
        if(data && data.data){
            if(data.result_code == "0"){
                const value = data.data;
                if(value.address_in)
                    setValue("address_in",value.address_in)
                if(value.address_out)
                    setValue("address_out",value.address_out)
                if(value.latitude)
                    setValue("latitude",value.latitude)
                if(value.longitude)
                    setValue("longitude",value.longitude)
                setConfirmFlag(true);
                handleNext();
            }else{
            if(data.result_code == "1"){
                if(data.message){
                    showDialogError(data.message);
                    setLoadingFlag(false);
                    return;
                }
                else{
                    onError(success,data,data.message);
                    setLoadingFlag(false);
                    return;
                }
            }
            onError(success,data,data.message);
            }
        }else{
            onError(success,data,"");
        }
        setLoadingFlag(false);
    }
    
    const handleError = (success:any, data:any, error:any) => {
        onError(success,data,data.message);
        setLoadingFlag(false);
    }

    const handleBackClick = () => {
        setConfirmFlag(false);
        handleBack();
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}

            {
                loadingFlag
                &&
                <FetchHelper fetchId={FETCH_ID_COORDINATE}
                url="/master/weathers/llget"
                method="POST"
                request={true}
                params={{address_in:getValues("address_in")}}
                onComplete={(success, data, error) => {
                    success ? handleFetchLatitudeLongitudeSuccess(success,data,error) : handleError(success, data, error)
                }}
                multipart={false}
                json={true}
                />
            }

            
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px' }}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    rules={{ required: "必須項目です。入力してください。", }}
                                    name="ob_point_name"
                                    render={({ field }) => (
                                        <Grid container spacing={1} direction="row">
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="観測地点名"
                                                size="small"
                                                style={{
                                                    backgroundColor:confirmFlag?"floralwhite":"white"
                                                }}
                                                inputProps={{
                                                    readOnly:confirmFlag,
                                                    maxLength:255
                                                }}
                                                error={!!errors?.ob_point_name}
                                                helperText={errors?.ob_point_name?.message}
                                            />
                                        </Grid>
                                    )}
                                />
                            </FormControl>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name="address_in"
                                        rules={{ required: "必須項目です。入力してください。", }}
                                        render={({ field }) => (
                                            <Grid container spacing={1} direction="row">
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="住所"
                                                    size="small"
                                                    style={{
                                                        backgroundColor:confirmFlag?"floralwhite":"white"
                                                    }}
                                                    inputProps={{
                                                        readOnly:confirmFlag,
                                                        maxLength:255
                                                    }}
                                                    error={!!errors?.address_in}
                                                    helperText={errors?.address_in?.message}
                                                    onChange={(e:any)=>{
                                                        setValue("address_in",e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    />
                                </FormControl>
                        </Stack>
                        {
                            confirmFlag &&
                            <>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name="address_out"
                                            render={({ field }) => (
                                                <Grid container spacing={1} direction="row">
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="住所(確認用)"
                                                        size="small"
                                                        inputProps={{
                                                            readOnly:confirmFlag,
                                                            maxLength:255
                                                        }}
                                                        style={{
                                                            backgroundColor:confirmFlag?"floralwhite":"white"
                                                        }}
                                                        error={!!errors?.address_out}
                                                        helperText={errors?.address_out?.message}
                                                    />
                                                </Grid>
                                            )}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name="latitude"
                                            render={({ field }) => (
                                                <Grid container spacing={1} direction="row">
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="緯度"
                                                        size="small"
                                                        inputProps={{
                                                            readOnly:confirmFlag
                                                        }}
                                                        style={{
                                                            backgroundColor:confirmFlag?"floralwhite":"white"
                                                        }}
                                                        error={!!errors?.latitude}
                                                        helperText={errors?.latitude?.message}
                                                    />
                                                </Grid>
                                            )}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                    <FormControl fullWidth>
                                        <Controller
                                            control={control}
                                            name="longitude"
                                            render={({ field }) => (
                                                <Grid container spacing={1} direction="row">
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="経度"
                                                        size="small"
                                                        inputProps={{
                                                            readOnly:confirmFlag
                                                        }}
                                                        style={{
                                                            backgroundColor:confirmFlag?"floralwhite":"white"
                                                        }}
                                                        error={!!errors?.latitude}
                                                        helperText={errors?.latitude?.message}
                                                    />
                                                </Grid>
                                            )}
                                        />
                                    </FormControl>
                                </Stack>
                            </>
                        }
                        <Typography style={{ marginLeft: '5px' } } mt={1.5}>収集有無</Typography>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px', marginTop: '10px' }}>
                            <Controller
                                control={control}
                                name="collect_flag"
                                render={({ field: { value } }) => (
                                    <Grid container spacing={1} direction="row" >
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={value}
                                            exclusive
                                            onChange={(e, value) => handleChangeRestricted(e, value)}
                                            aria-label="Platform"
                                            size="small"
                                            aria-labelledby="権限"
                                            disabled={confirmFlag?true:false}
                                        >
                                            <ToggleButton value={0}>なし</ToggleButton>
                                            <ToggleButton value={1}>あり</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        {   !confirmFlag &&
                            <Button variant="contained" onClick={async ()=>{
                                const result = await trigger();
                                if(result)
                                    handleClickNext();
                            }}>次へ</Button>
                        }
                        {
                            confirmFlag &&
                            <Button variant="contained" type="submit">保存</Button>
                        }

                        <Button color="inherit" variant="contained" onClick={confirmFlag?handleBackClick:handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
};

export default WeathersAdd;


