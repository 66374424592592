import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Collapse, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { CustomerResponse, OptionItem, PageData } from '../../../models';
import { Path as RoutePath } from "../../../path";
import { limitMasterCustomers } from '../../../statics';
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import palette from '../../../theme/palette';
import AlertDialog from '../../common/AlertDialog';
import FetchHelper from "../../FetchHelper";
import ChangePasswordByAdmin from './ChangePasswordByAdmin';
import UserAdd from './UsersAdd';
import UserEdit from './UsersEdit';
import UsersGrid from "./UsersGrid";

// -------------
// FETCH ID
const getAllCustomers = "GET_ALL_CUSTOMERS";
const getAllUsers = "GET_ALL_USERS";
const getUsersById = "GET_USERS_BY_ID";
const insertUsers = "INSERT_USERS";
const updateUsers = "UPDATE_USERSS";
const deleteUsers = "DELETE_USERS";
const formId = "USERS_SEARCH_FORM";
const menusFetchId = 'MENUS_FETCH';
const CHANGE_PASS = "CHANGE_PASS";
export const strInsert = "insert";
export const strUpdate = "update";
export const strDelete = "delete";

// 検索フォーム
export interface SearchItemForm {
    keyword: string;
    page:number,
    limit:number,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

interface UserModel {
    username: string;
    email: string | null;
    email_verified: string | null;
    custom_department: string | null;
    custom_system_manage_flag: string | null;
    custom_employee_number: string | null;
    custom_company: string | null;
    company_name: string | null;
    user_full_name: string | null;
    family_name: string | null;
    given_name: string | null;
    additional_info: AdditionalInfoModel;
    isSelect: boolean;
    custom_role: string;
    code_restriction_array: string[] | null;
    users_customer_array: string[] | null;
    gw_edit_auth_array: string[] | null;
    company_group_name: string | null;
    company_group_id: string | null;

}

interface AdditionalInfoModel {
    send_invitation_mail_flag: string | null;
    set_email_auto_verify_flag: string | null;
    temporary_pass_manual_setting_flag: string | null;
    temporary_pass_manual_setting_value: string | null;
    DesiredDeliveryMediums: string | null;
}

const initialUser: UserModel = {
    username: "",
    email: "test@testmail.co.jp",
    email_verified: "",
    custom_department: "1",
    custom_system_manage_flag: "0",
    custom_employee_number: "100",
    custom_company: "1",
    company_name: "株式会社 ITplus",
    family_name: "yamada",
    given_name: "Taro",
    user_full_name: "yamada Taro",
    additional_info: {
        send_invitation_mail_flag: "false",
        set_email_auto_verify_flag: "true",
        temporary_pass_manual_setting_flag: "false",
        temporary_pass_manual_setting_value: "",
        DesiredDeliveryMediums: "EMAIL",
    },
    isSelect: false,
    custom_role: "4",
    code_restriction_array: [],
    users_customer_array: [],
    gw_edit_auth_array:[],
    company_group_id:"",
    company_group_name:"",
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
    keyword: '',
}

// -------------
// Props
export type UsersListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const UsersList = (props: UsersListProps) => {
    const {
        fetchState,
        fetchStateMenu,
        currentLoginUser,
        onSearch,
        onInsertUsers,
        onUpdateUsers,
        onDeleteUsers,
        onCreateSuccess,
        onUpdateSuccess,
        onDeleteSuccess,
        onError,
        onCreateError,
        onUpdateError,
        onDeleteError,
        onGetAuthRole,
        onChangePass,
        onChangePassSuccess,
        onChangePassError,
    } = props;

    const [customerList, setCustomerList] = React.useState<any>([]);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [listData, setListData] = React.useState<UserModel[]>([]);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectData, setSelectData] = React.useState<UserModel>(initialUser);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [disableDelete, setDisableDelete] = React.useState(true);
    const [keyword, setKeyword] = React.useState("");
    const [authRole, setAuthRole] = React.useState<any>();
    const [openChangePasswordDialog,setOpenChangePasswordDialog] = React.useState<boolean>(false);
    const [dataUserChangePass,setDataUserChangePass] = React.useState<any>();
    const [authRoleMap,setAuthRoleMap] = React.useState<any>();
    const [openSearch, setOpenSearch] = React.useState(false);
    const [pageData, setPageData] = React.useState(initialPageData);
    localStorage.setItem('lastActiveFormPath', RoutePath.UsersList);
    localStorage.setItem('title', 'ユーザー');

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');

    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [loadedCustomerFlag, setLoadedCustomerFlag] = React.useState(false);
    const [loadedUserFlag, setLoadedUserFlag] = React.useState(false);
    const [roleList, setRoleList] = React.useState<any>([]);
    const [colState, setColState] = useState<any>();
    const gridRef = useRef() as any;

    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    React.useEffect(() => {
        handleSearchSubmit(getValues())
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        const roleList:OptionItem[] = [];
        if(authRole){
            authRole.role_list.forEach((item:any) => {
                const data = { label: item.name, value: item.role} as OptionItem
                roleList.push(data);
            })
            setRoleList(roleList);
            setAuthRoleMap(authRole.auth_role_map);
        }
    },[authRole])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            setAuthRole(data);
        }
    }
    
    // 検索正常終了時
    const handleSuccessGetById = (data: any) => {
        console.log('>>> handleSuccess');

        setSelectData(handleGetData(data.results.data)[0]);
        setOpenEditDialog(true);
    };

    // 検索正常終了時
    const handleSuccess = (data: any, type: string) => {
        console.log('>>> handleSuccess');

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        if (data.status) {
            switch (type) {
                case strInsert:
                    setOpenAddDialog(false);
                    onCreateSuccess();
                    break;
                case strUpdate:
                    setOpenEditDialog(false);
                    onUpdateSuccess();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteSuccess();
                    break;
                default:
                    break;
            }
            handleSearchSubmit(getValues());
            setDisableEdit(true);
            setDisableDelete(true);
        }
        else {
            switch (type) {
                case strInsert:
                    onCreateError();
                    break;
                case strUpdate:
                    onUpdateError();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteError();
                    break;
                default:
                    break;
            }
            setLoadingFlag(false);
        }
    };
    
    // 顧客名一覧取得成功時
    const handleFetchCustomersSuccess = (data: CustomerResponse) => {
        if (data?.results) {
            const converted_data = data?.results.map((x) => { return { value: x.id.toString(), label: x.name, group_id:x.group_id, group_name: x.group_name } as any });
            setCustomerList(converted_data);
        }
        
        setLoadedCustomerFlag(true);
        if (loadedUserFlag)
            setLoadingFlag(false);
    };

    // 検索正常終了時
    const handleSuccessGetAll = (data: any) => {
        if (data && data.results && data.results.data) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(handleGetData(data.results.data))
            }
            setListData(() => [...handleGetData(data.results.data)]);
            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }

        setLoadedUserFlag(true);
        if (loadedCustomerFlag)
            setLoadingFlag(false);
    };

    const handleGetData = (data: any) => {
        var result = [] as any[];        
        Object.values(data)?.forEach((element: any) => {
            let userItem = {} as any;
            userItem.username = element.Username;
            userItem.isSelect = false;
            element.Attributes.forEach((item: any) => {
                if (item.Name === "email") {
                    userItem.email = item.Value;
                }
                else if (item.Name === "email_verified") {
                    userItem.email_verified = item.Value;
                }
                else if (item.Name === "custom:department") {
                    userItem.custom_department = item.Value;
                }
                else if (item.Name === "custom:system_manage_flag") {
                    userItem.custom_system_manage_flag = item.Value;
                }
                else if (item.Name === "custom:employee_number") {
                    userItem.custom_employee_number = item.Value;
                }
                else if (item.Name === "given_name") {
                    userItem.given_name = item.Value;
                }
                else if (item.Name === "family_name") {
                    userItem.family_name = item.Value;
                }
                else if (item.Name === "custom:company") {
                    userItem.custom_company = item.Value;
                }
                else if (item.Name === "company_name") {
                    userItem.company_name = item.Value;
                }
                else if (item.Name === "custom:role") {
                    userItem.custom_role = item.Value;
                }else if(item.Name === "company_group_name"){
                    userItem.company_group_name = item.Value;
                }else if(item.Name === "company_group_id"){
                    userItem.company_group_id = item.Value;
                }else if(item.Name == "custom:reserve_num_02"){
                    userItem.custom_reserve_num_02 = item.Value;
                }
            });
            userItem.code_restriction_array = element.code_restriction_array;
            userItem.users_customer_array = element.users_customer_array;
            userItem.gw_edit_auth_array = element.gw_edit_auth_array;

            userItem.user_full_name = userItem.family_name + ' ' + userItem.given_name;
            result.push(userItem);
        });
        return result;
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    React.useEffect(() => {
        // グラフ表示用データの更新
        // --------------------------------
        if (myParam) {
            listData.forEach((item: any) => {
                item.serial_no = myParam
            });
            setListData(() => [...listData])
        }
        else {
            listData.forEach((item: any) => {
                item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            });
            setListData(() => [...listData])
        }
        // eslint-disable-next-line
    }, [myParam]);

    // 追加の処理
    const handleAdd = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        handleOpenAddDialog();
    }

    // 編集の処理
    const handleEdit = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        handleOpenEditDialog();
    }

    //削除の処理
    const handleDelete = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        let selectedData = listData.filter((item) => item.isSelect === true);
        if (selectedData.length === 0) {
            return;
        }
        let userName = selectedData.map((obj: UserModel) => obj.user_full_name).join(', ');
        const msg = "ユーザー：" + userName + " を削除しますか？";
        setTitle("ユーザー削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        listData.filter((item) => item.isSelect === true).forEach((item) => {
            onDeleteUsers(item.username);
        })
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    }

    // 検索正常終了時
    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        let list = listData.filter((item) => item.username === data.username)
        list[0].isSelect = !list[0].isSelect;
        if (gridRef && gridRef.current) {
            gridRef.current.setSelectRowData(data.username);
        }

        let selectCount = listData.filter((item) => item.isSelect === true).length;

        if (selectCount === 1) {
            setDisableEdit(false);
            setDisableDelete(false);
        } else if (selectCount > 0) {
            setDisableEdit(true);
            setDisableDelete(false);
        }
        else {
            setDisableEdit(true);
            setDisableDelete(true);
        }
    };

    // 帳票ダイアログを表示する
    const handleOpenAddDialog = () => {
        setSelectData(initialUser);
        setOpenAddDialog(true);
    };

    // 帳票ダイアログを無効にする
    const handleOpenAddDialogCancel = () => {
        setOpenAddDialog(false);
    };

    // 帳票ダイアログを表示する
    const handleOpenEditDialog = () => {
        console.log('>>> handleOpenEditDialog');

        var customerSelect = listData.filter((item) => item.isSelect === true)[0] as UserModel
        // const params = new Map<string, any>();
        // params.set("username", customerSelect.username);
        // onGetUsersById(params);
        setSelectData(customerSelect);
        setOpenEditDialog(true);
    };

    // 帳票ダイアログを無効にする
    const handleOpenEditDialogCancel = () => {
        setOpenEditDialog(false);
    };

    const handleInsert = (data: any) => {
        setLoadingFlag(true);
        onInsertUsers(data);
    };

    const handleUpdate = (data: any) => {
        setLoadingFlag(true);
        onUpdateUsers(data);
    };

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
    }

    const handleChangePassword = (data:any) => {
        onChangePass(data);
        setLoadingFlag(true);
    }

    const handleSuccessChangePass = (data: any) => {
        setLoadingFlag(false);
        if(data){
            if(data.result_code != undefined && data.result_code != null){
                // SUCCESS
                if(data.result_code == 0){
                    setOpenChangePasswordDialog(false);
                    onChangePassSuccess();
                    return
                }
            }
        }
        //ERROR
        onChangePassError(data.message);
    }

    const handleChangePassError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        onChangePassError(error)
    }

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.keyword) params.set("keyword", value.keyword);
        setLoadingFlag(true);
        onSearch(params);
    }

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("keyword", '')
    };

    // ハンドルオープン
    const handleOpen = () => {
        setOpenSearch((prev: boolean) => !prev);
    };

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    return (
        <>
            <FetchHelper fetchId={getAllCustomers}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomersSuccess(data) : handleError(success, data, error)
                }}
            />
            
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: '100%' }}>
                {openDeleteDialog &&
                    <AlertDialog 
                        title={title} 
                        message={message} 
                        isOpen={true} 
                        onOK={handleDeleteOK} 
                        onCancel={handleDeleteCancel} 
                    />
                }
                {openAddDialog &&
                    <Dialog open={openAddDialog} maxWidth={"sm"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>{"ユーザー追加"}</DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <UserAdd
                                onOK={handleInsert}
                                onCancel={handleOpenAddDialogCancel}
                                customerList={customerList}
                                roleList={roleList}
                                currentLoginUser={currentLoginUser}
                                authRole={authRole}
                            />
                        </DialogContent>
                    </Dialog>
                }
                {openEditDialog &&
                    <Dialog open={openEditDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>{"ユーザー編集"}</DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <UserEdit
                                user={selectData}
                                onOK={handleUpdate}
                                onCancel={handleOpenEditDialogCancel}
                                customerList={customerList}
                                roleList={roleList}
                                authRoleMap={authRoleMap}
                                currentLoginUser={currentLoginUser}
                            />
                        </DialogContent>
                    </Dialog>
                }
                {
                    openChangePasswordDialog &&
                    <Dialog open={openChangePasswordDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>{"パスワード変更"}</DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <ChangePasswordByAdmin
                                user={dataUserChangePass}
                                onOK={handleChangePassword}
                                onCancel={() => setOpenChangePasswordDialog(false)}
                            />
                        </DialogContent>
                    </Dialog>
                }
                <form onSubmit={handleSubmit(handleSearch)}>
                    <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                        <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={handleOpen}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="h6" >検索条件</Typography>
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {
                                    openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: '10px', marginRight: '10px', marginBottom: "10px" }}>
                            <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    <Grid item>
                                        <Stack direction="row">
                                            <Controller
                                                name="keyword"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="検索キーワード"
                                                        size="small"
                                                        style={{ background: palette.background.paper }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                    </Grid>
                                    <Grid item >
                                        <Button variant="contained" onClick={handleSearch}>検索</Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </div>
                    </Stack>
                </form >
                <div style={{ marginBottom: "10px",paddingTop:"5px"}}>
                    <Stack direction="row">
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => handleChangeKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                        <Stack direction={{ xs: 'column', sm: 'row' }} ml={5} spacing={1}>
                            <Button variant="contained" size="small" onClick={handleAdd}>{<AddIcon />}</Button>
                            <Button variant="contained" size="small" onClick={handleEdit} disabled={disableEdit}>{<EditIcon />}</Button>
                            <Button variant="contained" size="small" onClick={handleDelete} disabled={disableDelete}>{<ClearIcon />} </Button>
                        </Stack>
                    </Stack>
                </div>
                <div>       
                    <UsersGrid
                        ref={gridRef}
                        colState={colState}
                        onSelect={handleSelect}
                        keyword={keyword}
                        authRole={authRole}
                        customerList={customerList}
                        onChangePassword={(data) => {
                            setDataUserChangePass(data);
                            setOpenChangePasswordDialog(true)
                        }}
                        openSearch={openSearch}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                    /> 
                </div>
            </div>
            <FetchHelper fetchId={getUsersById} onComplete={(success, data, error) =>
                success ? handleSuccessGetById(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={insertUsers} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strInsert) : handleError(success, data, error)} />
            <FetchHelper fetchId={updateUsers} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strUpdate) : handleError(success, data, error)} />
            <FetchHelper fetchId={deleteUsers} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strDelete) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={CHANGE_PASS} onComplete={(success, data, error) => success ? handleSuccessChangePass(data) : handleChangePassError(success, data, error)} />
            <FetchHelper fetchId={getAllUsers}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetAll(data) : handleError(success, data, error)
                }}
            /> 
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        getAllUsers
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    currentLoginUser: state.app.user?.cognit_user,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(getAllUsers, `/master/users/get`, "GET", params),
    onGetUsersById: (params: { [key: string]: any }) => actionCreators.fetch(getUsersById, `/master/users/get`, "GET", params),
    onInsertUsers: (data: any) => actionCreators.fetch(insertUsers, `/master/users/add`, "POST", data, false, true),
    onUpdateUsers: (data: any) => actionCreators.fetch(updateUsers, `/master/users/edit`, "POST", data, false, true),
    onDeleteUsers: (username: string) => actionCreators.fetch(deleteUsers, `/master/users/delete`, "POST", [{ cognito_username: username }], false, true),
    onChangePass: (params:any) => actionCreators.fetch(CHANGE_PASS,`/master/users/editpw`,"POST",params,false,true),
    onCreateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "ユーザー情報追加",
            body: "ユーザー情報を登録しました。",
        }),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "ユーザー情報編集",
            body: "ユーザー情報を更新しました。",
        }),
    onDeleteSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "ユーザー情報削除",
            body: "ユーザー情報を削除しました。",
        }),
    onCreateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報追加",
            body: "ユーザー情報が登録出来ませんでした。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報編集",
            body: "ユーザー情報が更新出来ませんでした。",
        }),
    onDeleteError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報削除",
            body: "ユーザー情報が削除出来ませんでした。",
        }),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報",
            body: "ユーザー情報を取得できません。" + error,
        }),
    onChangePassError: (error:any) =>
        actionCreators.showMessage({
          type: "error",
          title: "パスワード変更",
          body: "パスワード変更に失敗しました。" + error,
        }
      ),
    onChangePassSuccess: () =>
      actionCreators.showMessage({
        type: "info",
        title: "パスワード変更",
        body: "パスワードを変更しました。",
      }),
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersList as any);
