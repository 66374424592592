import { Button, Grid, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoRaSettingModel } from "../../../models";
import { formatDateTime } from "../../../utils";

export type LoRaSettingEditFormProps = {
    loRaSetting: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const LoRaSettingEdit = (props: LoRaSettingEditFormProps) => {
    const { loRaSetting, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: LoRaSettingModel = {
        mainid: loRaSetting.mainid,
        deviceNo: loRaSetting.deviceNo,
        fwVersion: loRaSetting.fwVersion,
        hostName: loRaSetting.hostName,
        frequencyCh: loRaSetting.frequencyCh,
        frequencyPower: loRaSetting.frequencyPower,
        macAddr: loRaSetting.macAddr,
        alarmLedMacAddr: loRaSetting.alarmLedMacAddr,
        kwhPluseMacAddr: loRaSetting.kwhPluseMacAddr,
        ai1MacAddr: loRaSetting.ai1MacAddr,
        ai2MacAddr: loRaSetting.ai2MacAddr,
        ai3MacAddr: loRaSetting.ai3MacAddr,
        ai4MacAddr: loRaSetting.ai4MacAddr,
        lastGetDatetime: loRaSetting.lastGetDatetime,
    }

    const { register, handleSubmit, formState: { errors } } = useForm<LoRaSettingModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: LoRaSettingModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: LoRaSettingModel) => {

        let request = {
            mainid: formValue.mainid,
            device_no: formValue.deviceNo,
            host_name: formValue.hostName,
            frequency_ch: formValue.frequencyCh,
            frequency_power: formValue.frequencyPower,
            mac_addr: formValue.macAddr,
            alarm_led_mac_addr: formValue.alarmLedMacAddr,
            kwh_pluse_mac_addr: formValue.kwhPluseMacAddr,
            ai_1_mac_addr: formValue.ai1MacAddr,
            ai_2_mac_addr: formValue.ai2MacAddr,
            ai_3_mac_addr: formValue.ai3MacAddr,
            ai_4_mac_addr: formValue.ai4MacAddr,
            last_get_datetime: formatDateTime(new Date()),
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <form onSubmit={handleSubmit(handleEditSubmit)} style={{ height: 'calc(55vh - 20px)' }}>
                <div style={{ height: '90%', width: '100%', overflowY: 'auto' }}>
                    <Stack spacing={3} style={{ width: '90%', margin: 'auto' }}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="ホストID"
                                    size="small"
                                    {...register("hostName",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    inputProps={{ maxLength: 6 }}
                                    error={!!errors?.hostName}
                                    helperText={errors?.hostName?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="周波数CH"
                                    size="small"
                                    {...register("frequencyCh",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.frequencyCh}
                                    helperText={errors?.frequencyCh?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="周波数Power"
                                    size="small"
                                    {...register("frequencyPower",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.frequencyPower}
                                    helperText={errors?.frequencyPower?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="MACアドレス"
                                    size="small"
                                    {...register("macAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.macAddr}
                                    helperText={errors?.macAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="LED警報器MAC"
                                    size="small"
                                    {...register("alarmLedMacAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.alarmLedMacAddr}
                                    helperText={errors?.alarmLedMacAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="TJ3Pluse MAC"
                                    size="small"
                                    {...register("kwhPluseMacAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.kwhPluseMacAddr}
                                    helperText={errors?.kwhPluseMacAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="TJ3AI 1MAC"
                                    size="small"
                                    {...register("ai1MacAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.ai1MacAddr}
                                    helperText={errors?.ai1MacAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="TJ3AI 2MAC"
                                    size="small"
                                    {...register("ai2MacAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.ai2MacAddr}
                                    helperText={errors?.ai2MacAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="TJ3AI 3MAC"
                                    size="small"
                                    {...register("ai3MacAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.ai3MacAddr}
                                    helperText={errors?.ai3MacAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="TJ3AI 4MAC"
                                    size="small"
                                    {...register("ai4MacAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    error={!!errors?.ai4MacAddr}
                                    helperText={errors?.ai4MacAddr?.message}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                </div>
                <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }} style={{ position: 'relative', right: 'calc(5% + 15px)', top: '-5px' }}>
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    <Button variant="contained" type="submit">保存</Button>
                </Stack>
            </form >
        </>
    );
};
export default LoRaSettingEdit;


