
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Checkbox, FormControlLabel } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { customerLabelName } from '../../../statics';

const icon = <RadioButtonUncheckedIcon fontSize="small" />;
const checkedIcon = <RadioButtonCheckedIcon fontSize="small" />;

// -------------
// Props
export type BusinessPartnerGridProps = {
    colState: any[];
    keyword:string;
    onSelect: (data: any) => void;
};

// -------------
// Component
export const BusinessPartnerGrid = forwardRef((props: BusinessPartnerGridProps, ref) => {
    const { keyword,colState, onSelect } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const handleCheckedDelete = (props: any) => {
        onSelect(props.data)
        gridRef.current.api.redrawRows()
    };

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        const { name, selected } = props.data;
        let isSelected = selected
        if (props.data.isSelect) {
            isSelected = true;
        }
        return (
            <FormControlLabel
                control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} onChange={() => handleCheckedDelete(props)} />}
                label={name}
                labelPlacement="end"
            />
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: `${customerLabelName}名`,
            field: "name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            cellRenderer: CellCheckRenderer,
            width: colState ? colState[0].width : 400,
        },
        {
            headerName: "かな",
            field: "kana",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[1].width : 300,
        },
        {
            headerName: "郵便番号",
            field: "zip",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[2].width : 120,
        },
        {
            headerName: "住所１",
            field: "address1",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[3].width : 388,
        },
        {
            headerName: "住所２",
            field: "address2",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[4].width : 200,
        },
        {
            headerName: "電話番号",
            field: "tel",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[5].width : 200,
        },
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
              };
        }
        return {
            backgroundColor: 'white',
          };
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 230px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        getRowStyle={getRowStyle}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
            </div>
        </>
    );
});

export default BusinessPartnerGrid;