import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { ApplicationState, AppState } from "../store";

// -------------
// Props
type PurePrivateRouteProps = { returnElement: React.ReactNode };
export type PrivateRouteProps = AppState & PurePrivateRouteProps;
export const PrivateRoute = (props: PrivateRouteProps) => {

    const isAuthorize = (props.user && props.user.cognit_user.challengeName !== 'NEW_PASSWORD_REQUIRED') ? true : false;
    const isNewPasswordRequired = props.user && props.user.cognit_user.challengeName === 'NEW_PASSWORD_REQUIRED';

    if (isAuthorize) {
        return <>{props.returnElement}</>
    }
    else if (isNewPasswordRequired) {
        return <Navigate to={"/changepass"} replace={true} />
    }
    return (
        <Navigate to={"/login"} replace={true} />
    );
};

export default connect((state: ApplicationState) => state.app)(PrivateRoute);