import { Box, styled, TextField } from "@mui/material";

export const CustomFrameBox = styled(Box)(({ theme }) => ({
    backgroundColor: "floralwhite"
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "white"
}));

export const disabledRefreshAllBtnStyle = {
    color: 'gray',
}

export const enableRefreshAllBtnStyle = {
    color: '#1890ff'
}

export const pollingColStyleTitle = {
    width: '40%',
    border: 'none',
}

export const pollingColStyle = {
    border: 'none',
}

export const disabledRefreshSuccessIconStyles = {
    display: 'none',
}

export const enableRefreshSuccessIconStyles = {
    display: 'block',
    color: '#1890ff'
}

export const deviceContentStyles = {
    paddingLeft: '10px',
    width: '100%'
}

export const deviceBackgroundStyles = {
    backgroundColor: '#ecf6ff',
    color: '#1890ff',
    borderRight: '1px solid #b9ddff',
}