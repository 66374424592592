import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FormControlLabel } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { customerLabelName } from '../../../statics';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -------------
// Props
export type CustomersGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    openSearch: boolean;
    authRole:any;
    onChangeSearchCondition: (name: string, value: any) => void;
    onSelect: (data: any) => void;
};

// -------------
// Component
export const CustomersGrid = forwardRef((props: CustomersGridProps, ref) => {
    const { authRole,colState, pageSize, pageData, onChangeSearchCondition, onSelect, openSearch } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    // 統括管理者
    const generalAdminstratorRole = useMemo( () => {
        return authRole?.role_list.find((item:any) => item.name == "統括管理者").role
    },[authRole]);
    // 統括管理者以下( 6 >= target がTRUE)
    const isShow = useMemo(() => {
        return (6 >= authRole?.role || authRole?.role ==  generalAdminstratorRole)
    },[authRole,generalAdminstratorRole]);

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const handleCheckedDelete = (props: any) => {
        onSelect(props.data)
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
    };

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        const { group_name, selected } = props.data;
        let isSelected = selected
        if (props.data.isSelect) {
            isSelected = true;
        }
        return (
            <>
                <FormControlLabel
                    control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} onChange={() => handleCheckedDelete(props)} />}
                    label={group_name}
                    labelPlacement="end"
                />
            </>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const setAddress = (props: any) => {
        return props.data.address1 + props.data.address2;
    };

    const CellAccountCopyFlag = (props:any) => {
        const { account_copy_flag } = props.data;
        if(account_copy_flag == 1)
            return "〇";
        else if(account_copy_flag == 0)
            return "";
    }

    // 列の個別定義
    const columnDefs = isShow  ? 
        [        
            {
                headerName: "組織グループ名",
                field: "group_name",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                cellRenderer: CellCheckRenderer,
                width: colState ? colState[0].width : 400,
            },
            {
                headerName: `${customerLabelName}-表記名`,
                field: "name",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[1].width : 400,
            },
            {
                headerName: `${customerLabelName}-正式名`,
                field: "name_formal",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[2].width : 400,
            },
            {
                headerName: `取引先コピー`,
                field: "account_copy_flag",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[3].width : 130,
                cellRenderer: CellAccountCopyFlag,
            },
            {
                headerName: "カナ",
                field: "kana",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[4].width : 300,
            },
            {
                headerName: "郵便番号",
                field: "zip",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[5].width : 300,
            },
            {
                headerName: "住所",
                field: "address1",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[6].width : 388,
                valueGetter: setAddress,
            },
            {
                headerName: "電話番号",
                field: "tel",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[7].width : 200,
            },
        ]
        :
        [
            {
                headerName: "組織グループ名",
                field: "group_name",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                cellRenderer: CellCheckRenderer,
                width: colState ? colState[0].width : 400,
            },
            {
                headerName: `${customerLabelName}-表記名`,
                field: "name",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[1].width : 400,
            },
            {
                headerName: `${customerLabelName}-正式名`,
                field: "name_formal",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[2].width : 400,
            },
            {
                headerName: "カナ",
                field: "kana",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[3].width : 300,
            },
            {
                headerName: "郵便番号",
                field: "zip",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[4].width : 300,
            },
            {
                headerName: "住所",
                field: "address1",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[5].width : 388,
                valueGetter: setAddress,
            },
            {
                headerName: "電話番号",
                field: "tel",
                rowDrag: false,
                cellStyle: { textAlign: 'left' },
                width: colState ? colState[6].width : 200,
            },
        ]
    ;

    const rowHeight = 48;

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
            };
        }
        return {
            backgroundColor: 'white',
        };
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 275px)' : 'calc(100vh - 230px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        getRowStyle={getRowStyle}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default CustomersGrid;