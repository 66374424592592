import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
    Autocomplete,
    Button,
    CircularProgress,
    Collapse,
    Grid, Stack,
    TextField,
    Typography
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { OptionItem, PageData } from "../../../models";
import { Path as RoutePath } from "../../../path";
import {
    ApplicationState,
    FetchState,
    actionCreators,
    selectors
} from "../../../store";
import palette from '../../../theme/palette';
import { formatDateTime } from "../../../utils";
import FetchHelper from "../../FetchHelper";
import Page from "../../common/Page";
import AlarmGrid from "./AlarmGrid";
import { useSearchParams } from "react-router-dom";
import { limitMasterCustomers, limitMasterGateways, customerLabelName } from "../../../statics";

// -------------
// FETCH ID
const menusFetchId = 'MENUS_FETCH';
const fetchId = "ALARM_LIST";
const formId = "ALARM_SEARCH_FORM";
const customerFetch = "ALARM_CUSTOMER_FETCH";
const mainidFetch = "ALARM_MAINID_FETCH";

let statusData: OptionItem[] = [
    {
        value: "0",
        label: "復電/復帰",
        attribute: ""
    },
    {
        value: "1",
        label: "停電/警報/発生",
        attribute: ""
    }
];

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
    status: OptionItem | null;
    customer_id: OptionItem | null;
    place: string;
    mainid: OptionItem | null;
    occurrence_time_from: Date | null;
    occurrence_time_to: Date | null;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
    status: null,
    customer_id: null,
    place: "",
    mainid: null,
    occurrence_time_from: null,
    occurrence_time_to: null,
};

const initialPageData: PageData = {
    rowFrom: "0",
    rowTo: "0",
    totalRow: "0",
    currPage: "0",
    totalPage: "0",
};

// -------------
// Props
export type AlarmListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const AlarmList = (props: AlarmListProps) => {
    const { onSearch, onError, onSuccess, onUpdateError, fetchStateMenu, onGetAuthRole } = props;

    const { handleSubmit, control, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue
    });

    localStorage.setItem('lastActiveFormPath', window.location.pathname + window.location.search);
    localStorage.setItem('title', '全般');
    const [openSearch, setOpenSearch] = React.useState(false);
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<any>([]);
    const [colState, setColState] = useState<any>();

    const [statusList] = React.useState<OptionItem[]>(statusData);
    const [customerList, setCustomerList] = React.useState<OptionItem[]>([]);
    const [mainidList, setMainidList] = React.useState<OptionItem[]>([]);

    const [latestUpdates, setLatestUpdates] = React.useState("");

    const [keyword, setKeyword] = useState("");
    const gridRef = useRef() as any;

    const [loadingFlag, setLoadingFlag] = React.useState(true);

    const [searchParams] = useSearchParams();
    const isShowCustomerSearch = !(searchParams.get('path') === 'customer' && searchParams.get('customer_id'));
    
    // reload data when change URL query strings
    const watchChangeUrl = searchParams.get('customer_id');

    const [authRole, setAuthRole] = React.useState();

    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    React.useEffect(() => {
        if (!loadingFlag){
            const params = new Map<string, any>();

            params.set('code', 'ALARM');
            params.set('limit', getValues('limit'));
            params.set('path', searchParams.get('path') ?? 'root');
            if (!isShowCustomerSearch)
                params.set('customer_id', searchParams.get('customer_id'));

            handleClearSeachCondition();
            setLoadingFlag(true);
            onSearch(params);
        }        
    }, [watchChangeUrl]);

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        let isError = false;

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const params = new Map<string, any>();
        params.set("code", "ALARM");
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.status && value.status.value) params.set("status", value.status.value);
        if (value.place) params.set("place", value.place);
        if (value.mainid && value.mainid.value) params.set("mainid", value.mainid.value);
        if (value.occurrence_time_from) params.set("occurrence_time_from", formatDateTime(value.occurrence_time_from));
        if (value.occurrence_time_to) params.set("occurrence_time_to", formatDateTime(value.occurrence_time_to));

        params.set('path', searchParams.get('path') ?? 'root');
        if (isShowCustomerSearch){
            if (value.customer_id && value.customer_id.value) 
                params.set("customer_id", value.customer_id.value);
        } else {
            params.set('customer_id', searchParams.get('customer_id'));
        }

        if (!isError) {
            setLoadingFlag(true);
            onSearch(params);
        }
    };

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
        // eslint-disable-next-line
    }, []);

    // 検索正常終了時
    const handleSuccess = (data: any) => {
        if (data.results) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.results);
            }
            setListData(() => [...data.results]);

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = pageSize * page - pageSize + 1;
            const rowTo =
                pageSize * page > totalCount ? totalCount : pageSize * page;

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            setListData(() => [...[] as any]);
        }
        setLatestUpdates(formatDateTime(new Date()));
        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    const onRefreshGrid = () => {
        handleSearchSubmit(getValues());
    };

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("status", null)
        setValue("customer_id", null)
        setValue("place", "")
        setValue("mainid", null)
        setValue("occurrence_time_from", null)
        setValue("occurrence_time_to", null)
    };

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
    }

    const handleFetchCustomerSuccess = (data: any) => {
        if (data?.results) setCustomerList(data?.results.map((x: any) => { return { value: x.id.toString(), label: x.name, attribute: '' } as OptionItem }));
    };

    const handleFetchMainidSuccess = (data: any) => {
        if (data?.results) setMainidList(data?.results.map((x: any) => { return { value: x.id.toString(), label: x.id.toString(), attribute: '' } as OptionItem }));
    };

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    return (
        <>
            <FetchHelper fetchId={customerFetch}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomerSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={mainidFetch}
                url={`/master/gateways/get?limit=${limitMasterGateways}` + (( searchParams.get('path') && searchParams.get('path') === 'customer' && searchParams.get('customer_id')) ? ('&customer_id='+searchParams.get('customer_id')) : "")}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchMainidSuccess(data) : handleError(success, data, error)
                }}
            />
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: "100%" }}>
                <div>
                <form onSubmit={handleSubmit(handleSearch)}>
                    <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                        <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="h6" >検索条件</Typography>
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {
                                    openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                            <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={2}
                                    direction="row"
                                >
                                    <Grid item minWidth="20rem">
                                        <Controller
                                            control={control}
                                            name="status"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={statusList}
                                                    fullWidth
                                                    sx={{backgroundColor:"white"}}
                                                    renderInput={(params) => <TextField {...params} label="状態" size="small" />}
                                                    onChange={(_, data) => setValue("status", data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {isShowCustomerSearch &&
                                        <Grid item minWidth="20rem">
                                            <Controller
                                                control={control}
                                                name="customer_id"
                                                render={({ field: { value } }) => (
                                                    <Autocomplete
                                                        autoHighlight
                                                        value={value}
                                                        options={customerList}
                                                        fullWidth
                                                        sx={{backgroundColor:"white"}}
                                                        renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" />}
                                                        onChange={(_, data) => setValue("customer_id", data)}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    <Grid item minWidth="20rem">
                                        <Controller
                                            name="place"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="設置場所"
                                                    size="small"
                                                    sx={{backgroundColor:"white"}}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item minWidth="20rem">
                                        <Controller
                                            control={control}
                                            name="mainid"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={mainidList}
                                                    fullWidth
                                                    sx={{backgroundColor:"white"}}
                                                    renderInput={(params) => <TextField {...params} label="装置ID" size="small" />}
                                                    onChange={(_, data) => setValue("mainid", data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                    mt="3px"
                                >
                                    <Grid item xs={4} minWidth="29.5rem">
                                        <Stack spacing={1} direction="row">
                                            <Controller
                                                control={control}
                                                name="occurrence_time_from"
                                                render={({ field: { value } }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            inputFormat="YYYY/MM/DD HH:mm:ss"
                                                            renderInput={(props: any) => (
                                                                <TextField size="small" sx={{backgroundColor:"white"}} {...props} />
                                                            )}
                                                            label="受信日時From"
                                                            value={value}
                                                            onChange={(newValue: any) => {
                                                                setValue("occurrence_time_from", newValue ? newValue.$d : null);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                            <Stack justifyContent="center" alignItems="center">
                                                <div>～</div>
                                            </Stack>
                                            <Controller
                                                control={control}
                                                name="occurrence_time_to"
                                                render={({ field: { value } }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            inputFormat="YYYY/MM/DD HH:mm:ss"
                                                            renderInput={(props: any) => (
                                                                <TextField
                                                                    size="small"
                                                                    sx={{backgroundColor:"white"}}
                                                                    {...props}
                                                                />
                                                            )}
                                                            label="受信日時To"
                                                            value={value}
                                                            onChange={(newValue: any) => {
                                                                setValue("occurrence_time_to", newValue ? newValue.$d : null);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={handleSearch}>検索</Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </div>
                    </Stack>
                </form>
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <Typography variant="body2" style={{ textAlign: "center", lineHeight: "40px" }}>
                            最終表示日時&emsp;{latestUpdates}
                        </Typography>
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <Page onRefreshGrid={onRefreshGrid}></Page>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => handleChangeKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <AlarmGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        openSearch={openSearch}
                        authRole={authRole}
                    />
                </div>
            </div>
            <FetchHelper
                fetchId={fetchId}
                onComplete={(success, data, error) =>
                    success ? handleSuccess(data) : handleError(success, data, error)
                }
            />
            <FetchHelper 
                fetchId={menusFetchId} 
                onComplete={(success, data, error) => 
                    success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)
                } 
            />
        
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) =>
        actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onChangePageSize: (pageSize: number) => actionCreators.setPageSize(pageSize),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "全般検索",
            body: "遠方監視管理一覧を検索できません。" + error,
        }),
    onSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "全般検索",
            body: "情報を更新しました。",
        }),
    onUpdateError: (message: string) =>
        actionCreators.showMessage({
            type: "error",
            title: "全般検索",
            body: message,
        }),
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmList as any);
