import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { LoRaSettingModel, UpdateResult } from '../../../models';

import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import { changeDisabledPollingBtn } from '../../../utils';
import SelectLoRaSettingDialog from '../../dialogs/SelectLoRaSettingDialog';
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 15;

const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
    isDisbleUpdateBtn: true,
}
const fetchId = "LORA_SETTING_FETCH";

type LoRaSettingProps = {
    defaultData: LoRaSettingModel[] | undefined;
};

export type PureLoRaSettingProps = LoRaSettingProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

export const LoRaSetting = (props: PureLoRaSettingProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isEdit, isRefreshGroup, isDisbleRefeshBtn, isDisbleUpdateBtn } = props;
    const { onError, onUpdate, onRefresh, onUpdateSuccess, onUpdateError, isRefreshAllCompleted, setIsEdit, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [updateData, setUpdateData] = React.useState<any>();
    const [loRaSetting0, setLoRaSetting0] = React.useState<LoRaSettingModel>(fetchState.data);
    const [loRaSetting1, setLoRaSetting1] = React.useState<LoRaSettingModel>(fetchState.data);
    // const [loRaSetting2, setLoRaSetting2] = React.useState<LoRaSettingModel>(fetchState.data);
    // const [loRaSetting3, setLoRaSetting3] = React.useState<LoRaSettingModel>(fetchState.data);
    // const [loRaSetting4, setLoRaSetting4] = React.useState<LoRaSettingModel>(fetchState.data);
    const [editData, setEditData] = React.useState<LoRaSettingModel>();
    const [fetchLoadingDevice0, setFetchLoadingDevice0] = React.useState(false);
    // const [fetchLoadingDevice2, setFetchLoadingDevice2] = React.useState(false);
    // const [fetchLoadingDevice3, setFetchLoadingDevice3] = React.useState(false);
    // const [fetchLoadingDevice4, setFetchLoadingDevice4] = React.useState(false);
    const [editFlg, setEditFlg] = React.useState(new Array(5).fill('').map(() => false));
    // const [isFetchAll, setIsFetchAll] = React.useState(false);
    const [isRefreshSuccess0, setIsRefreshSuccess0] = React.useState(false);
    // const [isRefreshSuccess1, setIsRefreshSuccess1] = React.useState(false);
    // const [isRefreshSuccess2, setIsRefreshSuccess2] = React.useState(false);
    // const [isRefreshSuccess3, setIsRefreshSuccess3] = React.useState(false);
    // const [isRefreshSuccess4, setIsRefreshSuccess4] = React.useState(false);

    const deviceNos = ['00', '01', '02', '03', '04'];
    // const deviceNames = ['親機', '子機1', '子機2', '子機3', '子機4'];
    const deviceNames = ['1', '2'];

    // パラメータ設定
    const handleFetchParams = (deviceNo: string) => {
        const params = new Map<string, any>();
        const code = "QJ";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", deviceNo);
        return params;
    }

    const handleFetchSuccess = (data: any, action: string, isClickedRefreshBtn: boolean) => {
        setEditFlg(changeDisabledPollingBtn(false, data.device_no, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            fwVersion: data.fw_version,
            hostName: data.host_name,
            frequencyCh: data.frequency_ch,
            frequencyPower: data.frequency_power,
            macAddr: data.mac_addr,
            alarmLedMacAddr: data.alarm_led_mac_addr,
            kwhPluseMacAddr: data.kwh_pluse_mac_addr,
            ai1MacAddr: data.ai_1_mac_addr,
            ai2MacAddr: data.ai_2_mac_addr,
            ai3MacAddr: data.ai_3_mac_addr,
            ai4MacAddr: data.ai_4_mac_addr,
            lastGetDatetime: data.last_get_datetime,
        } as LoRaSettingModel;

        switch (data.device_no) {
            case deviceNos[0]:
                setLoRaSetting0(resData);
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && isRefreshAllCompleted(isRefreshAllBegin);
                setRefreshGroup(false, fetchId);
                isClickedRefreshBtn && setIsRefreshSuccess0(true);
                break;
            // case deviceNos[1]:
            //     setLoRaSetting1(resData);
            //     // (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
            //     (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
            //     setRefreshGroup(false, fetchId);
            //     isClickedRefreshBtn && setIsRefreshSuccess1(true);
            //     break;
            // case deviceNos[2]:
            //     setLoRaSetting2(resData);
            //     setFetchLoadingDevice2(false);
            //     (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
            //     isClickedRefreshBtn && setIsRefreshSuccess2(true);
            //     break;
            // case deviceNos[3]:
            //     setLoRaSetting3(resData);
            //     setFetchLoadingDevice3(false);
            //     (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
            //     isClickedRefreshBtn && setIsRefreshSuccess3(true);
            //     break;
            // case deviceNos[4]:
            //     setLoRaSetting4(resData);
            //     setFetchLoadingDevice4(false);
            //     (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
            //     setRefreshGroup(false, fetchId);
            //     isClickedRefreshBtn && setIsRefreshSuccess4(true);
            //     break;
        }

        if (action === 'edit') {
            setEditData(resData);
            setOpenSelectDialog(true);
        }
    };

    const handleError = (success: boolean, data: any, error: any, deviceNo: string) => {
        onError(success, data, error, deviceNames[Number(deviceNo)]);
        setEditFlg(changeDisabledPollingBtn(false, deviceNo, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                // (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                // setIsRefreshSuccess1(false);
                break;
            // case deviceNos[2]:
            //     setFetchLoadingDevice2(false);
            //     (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
            //     setIsRefreshSuccess2(false);
            //     break;
            // case deviceNos[3]:
            //     setFetchLoadingDevice3(false);
            //     (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
            //     setIsRefreshSuccess3(false);
            //     break;
            // case deviceNos[4]:
            //     setFetchLoadingDevice4(false);
            //     (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
            //     setRefreshGroup(false, fetchId);
            //     setIsRefreshSuccess4(false);
            //     break;
        }
    }

    const handleRefresh = (deviceNo: string) => {
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                // setIsRefreshSuccess1(false);
                break;
            // case deviceNos[2]:
            //     setFetchLoadingDevice2(true);
            //     setIsRefreshSuccess2(false);
            //     break;
            // case deviceNos[3]:
            //     setFetchLoadingDevice3(true);
            //     setIsRefreshSuccess3(false);
            //     break;
            // case deviceNos[4]:
            //     setFetchLoadingDevice4(true);
            //     setIsRefreshSuccess4(false);
            //     break;
        }
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
    };


    const handleRefreshSuccess = (data: any, deviceNo: string) => {
        const deviceIndex = deviceNos.findIndex((element) => element === data.device_no);
        editFlg[deviceIndex] ? handleFetchSuccess(data, 'edit', true) : handleFetchSuccess(data, 'view', true);
    }

    const [updateDialogTitle, setUpdateDialogTitle] = React.useState('');
    // const handleEdit = (deviceNo: string) => {
    //     setEditFlg(changeDisabledPollingBtn(true, deviceNo, deviceNos, editFlg));
    //     const params = handleFetchParams(deviceNo);
    //     onRefresh(params, fetchId + deviceNo);
    //     switch (deviceNo) {
    //         case deviceNos[0]:
    //             setUpdateDialogTitle('LoRa設定/親機');
    //             setFetchLoadingDevice0(true);
    //             setIsRefreshSuccess0(false);
    //             break;
    //         case deviceNos[1]:
    //             setUpdateDialogTitle('LoRa設定/子機1');
    //             setFetchLoadingDevice1(true);
    //             setIsRefreshSuccess1(false);
    //             break;
    //         case deviceNos[2]:
    //             setUpdateDialogTitle('LoRa設定/子機2');
    //             setFetchLoadingDevice2(true);
    //             setIsRefreshSuccess2(false);
    //             break;
    //         case deviceNos[3]:
    //             setUpdateDialogTitle('LoRa設定/子機3');
    //             setFetchLoadingDevice3(true);
    //             setIsRefreshSuccess3(false);
    //             break;
    //         case deviceNos[4]:
    //             setUpdateDialogTitle('LoRa設定/子機4');
    //             setFetchLoadingDevice4(true);
    //             setIsRefreshSuccess4(false);
    //             break;
    //     }
    // };

    const handleUpdateOk = (request: any) => {
        setOpenSelectDialog(false);
        const code = "QK";
        const requestData = {
            mainid: request.mainid,
            device_no: request.device_no,
            host_name: request.host_name,
            frequency_ch: request.frequency_ch,
            frequency_power: request.frequency_power,
            mac_addr: request.mac_addr,
            alarm_led_mac_addr: request.alarm_led_mac_addr,
            kwh_pluse_mac_addr: request.kwh_pluse_mac_addr,
            ai_1_mac_addr: request.ai_1_mac_addr,
            ai_2_mac_addr: request.ai_2_mac_addr,
            ai_3_mac_addr: request.ai_3_mac_addr,
            ai_4_mac_addr: request.ai_4_mac_addr,
        }
        onUpdate(code, mainid, requestData);
        setUpdateData(request);
        switch (request.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                // setIsRefreshSuccess1(false);
                break;
            // case deviceNos[2]:
            //     setFetchLoadingDevice2(true);
            //     setIsRefreshSuccess2(false);
            //     break;
            // case deviceNos[3]:
            //     setFetchLoadingDevice3(true);
            //     setIsRefreshSuccess3(false);
            //     break;
            // case deviceNos[4]:
            //     setFetchLoadingDevice4(true);
            //     setIsRefreshSuccess4(false);
            //     break;
        }
    };

    const handleUpdateCancel = () => {
        setOpenSelectDialog(false);
    };

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        const result = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            resultCode: data.result_code,
        } as UpdateResult;
        if (result.resultCode === '0') {
            handleFetchSuccess(updateData, 'view', true);
            onUpdateSuccess(deviceNames[Number(result.deviceNo)]);
        } else {
            handleUpdateError(success, data, error);
        }
    }

    const handleUpdateError = (success: boolean, data: any, error: any) => {
        switch (updateData.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                // setIsRefreshSuccess1(true);
                break;
            // case deviceNos[2]:
            //     setFetchLoadingDevice2(false);
            //     setIsRefreshSuccess2(true);
            //     break;
            // case deviceNos[3]:
            //     setFetchLoadingDevice3(false);
            //     setIsRefreshSuccess3(true);
            //     break;
            // case deviceNos[4]:
            //     setFetchLoadingDevice4(false);
            //     setIsRefreshSuccess4(true);
            //     break;
        }
        onUpdateError(success, data, error, deviceNames[Number(updateData.device_no)]);
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh(deviceNos[0]);
        }
        if (isRefreshGroup === true) {
            handleRefresh(deviceNos[0]);
            setFetchLoadingDevice0(true);
            // setFetchLoadingDevice2(true);
            // setFetchLoadingDevice3(true);
            // setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            // setIsRefreshSuccess1(false);
            // setIsRefreshSuccess2(false);
            // setIsRefreshSuccess3(false);
            // setIsRefreshSuccess4(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setFetchLoadingDevice0(true);
            // setFetchLoadingDevice2(true);
            // setFetchLoadingDevice3(true);
            // setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            // setIsRefreshSuccess1(false);
            // setIsRefreshSuccess2(false);
            // setIsRefreshSuccess3(false);
            // setIsRefreshSuccess4(false);
        }
        // isRefreshAllBegin > 0 || isRefreshGroup ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, loadingRefreshAll, isRefreshGroup]);

    const handleDisabledRefreshAll = () => {
        // if (!fetchLoadingDevice0 && !fetchLoadingDevice1 && !fetchLoadingDevice2 && !fetchLoadingDevice3 && !fetchLoadingDevice4) {
        if (!fetchLoadingDevice0) {
            setDisabledRefreshGroupBtn(false, fetchId);
        } else {
            setDisabledRefreshGroupBtn(true, fetchId);
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevice0]);
    // React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevice0, fetchLoadingDevice1, fetchLoadingDevice2, fetchLoadingDevice3, fetchLoadingDevice4]);

    React.useEffect(() => {
        [...editFlg].find(item => item === true) === true ? setIsEdit(true) : setIsEdit(false);
        // eslint-disable-next-line
    }, [editFlg])

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, 'view', false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess0 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            {/* <IconButton aria-label="edit" onClick={() => handleEdit('00')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice0 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton> */}
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('00')} disabled={isDisbleRefeshBtn || editFlg[0] || fetchLoadingDevice0 }>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[0] === true || fetchLoadingDevice0 ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='LoRa設定'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice0 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Firmwareバージョン</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>ホストID</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>周波数CH</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>周波数Power</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>MACアドレス</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>LED警報器MAC</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3Pluse MAC</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>TJ3AI 1MAC</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>Firmwareバージョン</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.fwVersion}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>ホストID</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.hostName}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>周波数CH</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.frequencyCh}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>周波数Power</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.frequencyPower}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>MACアドレス</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.macAddr}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>LED警報器MAC</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.alarmLedMacAddr}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3Pluse MAC</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.kwhPluseMacAddr}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>TJ3AI 1MAC</td>
                                    <td style={pollingColStyle}>{loRaSetting0?.ai1MacAddr}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{loRaSetting0?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {
                openSelectDialog ? (
                    <SelectLoRaSettingDialog
                        isOpen={true}
                        onOK={(data) => handleUpdateOk(data)}
                        onCancel={handleUpdateCancel}
                        data={editData}
                        dialogTitle={updateDialogTitle}
                    />
                ) : (
                    <div />
                )
            }
            <FetchHelper fetchId={fetchId + deviceNos[0]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[0]) : handleError(success, data, error, deviceNos[0])} />
            <FetchHelper fetchId={fetchId + deviceNos[1]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[1]) : handleError(success, data, error, deviceNos[1])} />
            {/* <FetchHelper fetchId={fetchId + deviceNos[2]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[2]) : handleError(success, data, error, deviceNos[2])} />
            <FetchHelper fetchId={fetchId + deviceNos[3]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[3]) : handleError(success, data, error, deviceNos[3])} />
            <FetchHelper fetchId={fetchId + deviceNos[4]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[4]) : handleError(success, data, error, deviceNos[4])} /> */}
            <FetchHelper fetchId="UPDATE_LORA_SETTING" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(success, data, error) : handleUpdateError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onUpdate: (code: string, mainid: string, data: any) => actionCreators.fetch("UPDATE_LORA_SETTING", `/polling/set?code=${code}`, 'POST', data, false, true),
    onRefresh: (params: any, fetchId: string) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `LoRa設定-${deviceName}取得`,
            body: "LoRa設定取得できませんでした。",
        }),
    onUpdateSuccess: (deviceName: string) =>
        actionCreators.showMessage({
            type: "info",
            title: `LoRa設定-${deviceName}変更`,
            body: "LoRa設定を変更しました。",
        }),
    onUpdateError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `LoRa設定-${deviceName}変更`,
            body: "LoRa設定を変更できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoRaSetting);