import CircularProgress from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import {
    Route, Routes
} from "react-router-dom";

// theme
import { Stack } from "@mui/material";
import CheckAPIs from "./components/CheckAPIs";
import PrivateRoute from "./components/PrivateRoute";
import ChangePassword from './components/changePassword/ChangePassword';
import ToastContainer from "./components/common/ToastContainer";
import Login from "./components/login/Login";
import CustomersList from "./components/master/customers/CustomersList";
import DemandsDetails from "./components/polling/demands/DemandsDetails";
import AddDevice from "./components/polling/device/AddDevice";
import AddDeviceList from "./components/polling/device/AddDeviceList";
import DeviceList from "./components/polling/device/DeviceList";
import EditDevice from "./components/polling/device/EditDevice";
import NotificationsDetails from "./components/polling/notifications/NotificationsDetails";
import PointsDetails from "./components/polling/points/PointsDetails";
import SolarsDetails from "./components/polling/solars/SolarsDetails";
import UsersList from "./components/master/users/UsersList";
import PollingTopicDetails from "./components/polling/pollingTopicDetails/PollingTopicDetails";
import SolarLiveStatus from "./components/solarLiveStatus/SolarLiveStatus";
import AlarmList from "./components/telemetry/Alarm/AlarmList";
import AbacList from "./components/telemetry/Abac/AbacList";
import AdList from "./components/telemetry/Ad/AdList";
import AeList from "./components/telemetry/Ae/AeList";
import EbecList from "./components/telemetry/Ebec/EbecList";
import GaList from "./components/telemetry/Ga/GaList";
import Ga2List from "./components/telemetry/Ga2/Ga2List";
import Gaex1 from "./components/telemetry/Gaex1/Gaex1";
import Gaex2 from "./components/telemetry/Gaex2/Gaex2";
import Gaex3 from "./components/telemetry/Gaex3/Gaex3";
import GbList from "./components/telemetry/Gb/GbList";
import GcList from "./components/telemetry/Gc/GcList";
import DemandDataList from "./components/telemetry/demandData/DemandDataList";
import { Layout } from "./layouts/Layout";
import { Path as RoutePath } from "./path";
import { ApplicationState } from "./store";
import ThemeConfig from './theme';
import LeaksDetails from "./components/polling/leaks/LeaksDetails";
import Gaex5 from "./components/telemetry/Gaex5/Gaex5";
import WeathersList from "./components/master/weathers/WeathersList";
import WeathersData from "./components/master/weathers/WeathersData";
import DeviceJSPList from "./components/polling/deviceJSP/DeviceJSPList";
import SmartDemandList from "./components/smonitor/smartDemand/SmartDemandList";
import AlarmDemandList from "./components/smonitor/AlarmDemand/AlarmDemandList";
import SettingDemandList from "./components/smonitor/SettingDemand/SettingDemandList";
import SmartSolarList from "./components/smonitor/SmartSolar/SmartSolarList";
import SettingSolarList from "./components/smonitor/SettingSolar/SettingSolarList";
import ReportDemand from "./components/polling/ReportDemand/ReportDemand";
import MonthlyPowerUpdate from "./components/telemetry/MonthlyPowerUpdate";
import Dashboard from "./components/dashboard/Dashboard";
import BxList from "./components/telemetry/Bx/BxList";
import CustomersGroupList from "./components/master/CustomersGroup/CustomersGroupList";
import FaList from "./components/telemetry/Fa/FaList";
import NotifyList from "./components/polling/notify/NotifyList";
import BeList from "./components/telemetry/Be/BeList";
import ObservationPointAndDevicesScreen from "./components/master/weathers/ObservationPointAndDevicesScreen";
import EarthFaultWaveForm from "./components/telemetry/Be/EarthFaultWaveForm";

interface AppData {
    loading: boolean;
}

export type AppProps = AppData;

const App = (props: AppProps) => {
    if (props.loading) {
        return (
            <ThemeConfig>
                <Layout>
                    <ToastContainer />
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                        width={"100%"}
                        height={"100%"}
                    >
                        <CircularProgress />
                    </Stack>
                </Layout>
            </ThemeConfig>
        );
    }
    return (
        <ThemeConfig>
            <Routes>
                <Route path="/" element={<PrivateRoute returnElement={<Layout />}></PrivateRoute>}>
                    <Route index element={<Dashboard />} />
                    <Route path={RoutePath.Dashboard} element={<Dashboard />} />
                    <Route path={RoutePath.AlarmList} element={<AlarmList />} />
                    <Route path={RoutePath.AbacList} element={<AbacList />} />
                    <Route path={RoutePath.AdList} element={<AdList />} />
                    <Route path={RoutePath.GbList} element={<GbList />} />
                    <Route path={RoutePath.GcList} element={<GcList />} />
                    <Route path={RoutePath.EbecList} element={<EbecList />} />
                    <Route path={RoutePath.BxList} element={<BxList />} />
                    <Route path={RoutePath.BeList} element={<BeList />} />
                    <Route path={RoutePath.FaList} element={<FaList />} />
                    <Route path={RoutePath.GaList} element={<GaList />} />
                    <Route path={RoutePath.Ga2List} element={<Ga2List />} />
                    <Route path={RoutePath.AeList} element={<AeList />} />
                    <Route path={RoutePath.DemandList} element={<DemandDataList />} />
                    <Route path={RoutePath.Gaex2} element={<Gaex2 />} />
                    <Route path={RoutePath.Gaex5} element= {<Gaex5 />} />
                    <Route path={RoutePath.CustomersList} element={<CustomersList />} />
                    <Route path={RoutePath.UsersList} element={<UsersList />} />
                    <Route path={RoutePath.DeviceList} element={<DeviceList />} />
                    <Route path={RoutePath.CustomersGroupList} element={<CustomersGroupList />} />
                    <Route path={RoutePath.AddDevice} element={<AddDevice />} />
                    <Route path={RoutePath.AddDeviceList} element={<AddDeviceList />} />
                    <Route path={RoutePath.EditDevice + '/:id'} element={<EditDevice />} />
                    <Route path={RoutePath.PointsEdit} element={<PointsDetails />} />
                    <Route path={RoutePath.LeaksEdit} element={<LeaksDetails />} />
                    <Route path={RoutePath.DemandsEdit} element={<DemandsDetails />} />
                    <Route path={RoutePath.SolarsEdit} element={<SolarsDetails />} />
                    <Route path={RoutePath.NotificationsEdit} element={<NotificationsDetails />} />
                    <Route path={RoutePath.Gaex1} element={<Gaex1 />} />
                    <Route path={RoutePath.Gaex3} element={<Gaex3 />} />
                    <Route path={RoutePath.Weather} element={<WeathersList />} />
                    <Route path={RoutePath.WeatherData} element={<WeathersData />} />
                    <Route path={RoutePath.ObservationPointsAndDeviceScreen + "/:id"} element={<ObservationPointAndDevicesScreen />} />
                    <Route path={'/smonitor'} >
                        <Route path={RoutePath.SmartDemandList} element={<SmartDemandList />} />
                        <Route path={RoutePath.AlarmDemandList} element={<AlarmDemandList/>} />
                        <Route path={RoutePath.SettingDemandList} element={<SettingDemandList />} />
                        <Route path={RoutePath.SmartSolarList} element={<SmartSolarList />} />
                        <Route path={RoutePath.SettingSolarList} element={<SettingSolarList />} />
                    </Route>
                    <Route path={'/polling'} >
                        <Route index element={<DeviceList />} />
                        <Route path={RoutePath.DeviceJSPList} element={<DeviceJSPList />} /> 
                        <Route path="detail/:id" element={<PollingTopicDetails />} />
                        <Route path={RoutePath.ReportDemand} element={<ReportDemand />} />
                    </Route>
                    <Route path={RoutePath.NotifyList} element={<NotifyList />} />
                    <Route path={RoutePath.SolarLiveStatus} element={<SolarLiveStatus />} />
                    <Route path={RoutePath.MonthlyPowerUpdate} element={<MonthlyPowerUpdate />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/changepass" element={<ChangePassword />} />
                <Route path={RoutePath.CheckAPIs} element={<CheckAPIs />} />
            </Routes>
            <ToastContainer />
        </ThemeConfig>
    );
};

export default connect((state: ApplicationState) => state.app)(App as any);
