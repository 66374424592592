import * as React from "react";
import { connect } from "react-redux";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";

import { Button, Card, CardContent, CardHeader, Grid, Stack, TextField, Typography, CircularProgress, IconButton, Menu, MenuItem, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { PollingMenuOptions } from "../../../statics";
import { deviceBackgroundStyles, deviceContentStyles } from "../../styles";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeviceInformation from "../device/DeviceInformation";
import { Customer } from "../../../models";

// FETCH ID
const fetchId = "LEAKS_FETCH_ID";
const menusFetchId = 'MENUS_FETCH';

// フォーム定義
interface EditLeaksForm {
    name1: string,
    name2: string,
    name3: string,
    name4: string,
    name5: string,
    name6: string,
    puInfo1:string,
    puInfo2:string,
    puInfo3:string,
    puInfo4:string,
    puInfo5:string,
    puInfo6:string,
    type1:string,
    type2:string,
}

// 初期値設定
const initialValue: EditLeaksForm = {
    name1: '',
    name2: '',
    name3: '',
    name4: '',
    name5: '',
    name6: '',
    puInfo1:"",
    puInfo2:"",
    puInfo3:"",
    puInfo4:"",
    puInfo5:"",
    puInfo6:"",
    type1:"0",
    type2:"0",
}

// -------------
// Props
export type LeaksDetailsProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const LeaksDetails = (props: LeaksDetailsProps) => {
    const { onError, onUpdate, onUpdateSuccess, onUpdateError, fetchStateMenu, onGetAuthRole  } = props;

    const urlParams = new URLSearchParams(window.location.search);
    const mainid = urlParams.get('mainid');
    const [device, setDevice] = React.useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [authRole, setAuthRole] = React.useState<any>();
    const [disableSaveBtn, setDisableSaveBtn] = React.useState(true);

    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        if (authRole !== undefined && device !== undefined){
            if (authRole.auth_role_map['GW-ebecsave-all']){
                setDisableSaveBtn(false);
            } else {
                if (authRole.auth_role_map['GW-ebecsave-belong']){
                    if (authRole.company_id == device.customer_id)
                    setDisableSaveBtn(false);
                }
                if (authRole.auth_role_map['GW-ebecsave-group']){
                    if (authRole.company_group_id == device.group_id)
                        {
                            setDisableSaveBtn(false);
                        }
                }
                if (authRole.auth_role_map['GW-ebecsave-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == device.customer_id).length > 0)
                        setDisableSaveBtn(false);
                }
            }
        }
    },[authRole, device])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    const getOrBlank = (value: string) => {
        if (value) {
            return value;
        } else return '';
    }

    const { handleSubmit, control, formState: { errors }, setValue, getValues } = useForm<EditLeaksForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    //ref変数
    let navigate = useNavigate();

    // Submitイベント
    const handleEditSubmit = (data: EditLeaksForm) => {
        setLoadingFlag(true);
        const request = createRequestData(data);
        onUpdate(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: EditLeaksForm) => {
        let request = {
            mainid: mainid,
            leak_info_array: [
                {
                    ch: '1',
                    type: formValue.type1,
                    name: getOrBlank(formValue.name1),
                },
                {
                    ch: '2',
                    type: formValue.type2,
                    name: getOrBlank(formValue.name2),
                },
                {
                    ch: '3',
                    name: getOrBlank(formValue.name3),
                },
                {
                    ch: '4',
                    name: getOrBlank(formValue.name4),
                },
                {
                    ch: '5',
                    name: getOrBlank(formValue.name5),
                },
                {
                    ch: '6',
                    name: getOrBlank(formValue.name6),
                }
            ]
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => navigate(RoutePath.DeviceList);

    const handleError = (success: boolean, data: any, error: any) => {
        setLoadingFlag(false);
        onError(success, data, error);
    }

    const handleFetchLeaksSuccess = (data: any) => {
        setValue('name1', data.name1);
        setValue('name2', data.name2);
        setValue('name3', data.name3);
        setValue('name4', data.name4);
        setValue('name5', data.name5);
        setValue('name6', data.name6);
        setValue("puInfo1",data.pu_info_1);
        setValue("puInfo2",data.pu_info_2);
        setValue("puInfo3",data.pu_info_3);
        setValue("puInfo4",data.pu_info_4);
        setValue("puInfo5",data.pu_info_5);
        setValue("puInfo6",data.pu_info_6);
        setValue('type1', data.type1);
        setValue('type2', data.type2);
        setLoadingFlag(false);
    }

    const handleUpdateSuccess = (data: any) => {
        const result = {
            resultCode: data.result_code,
            updateData: data.data,
        };
        if (result.resultCode === 1) {
            handleUpdateError();
        } else {
            onUpdateSuccess();
            setLoadingFlag(false);
        }
    }

    const handleUpdateError = () => {
        onUpdateError();
        setLoadingFlag(false);
    }

    const handleFetchDeviceSuccess = (data: any) => {
        setDevice(data)
    }

    const handleFetchParam = (id: string, value: any) => {
        const params = new Map<string, any>();
        params.set(id, value);
        return params;
    }

    return (
        <div style={{ margin: 'auto 10px' }} >
            <FetchHelper fetchId={'DEVICE_FETCH'}
                url={"/master/gateways/get"}
                method="GET"
                request={true}
                params={handleFetchParam('id', mainid)}
                onComplete={(success, data, error) => {
                    success ? handleFetchDeviceSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={fetchId}
                url={"/master/leaks/get"}
                method="GET"
                request={true}
                params={handleFetchParam('mainid', mainid)}
                onComplete={(success, data, error) => {
                    success ? handleFetchLeaksSuccess(data) : handleError(success, data, error)
                }}
            />
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <DeviceInformation deviceInformation={device} mainid={mainid ?? ''} title="漏電編集" isDisplayDeviceInfo={true} isShowMenuButton={true}></DeviceInformation>
            <form onSubmit={handleSubmit(handleEditSubmit)} >
                <Grid>
                    <Card style={{ margin: '12px auto 12px', width: '100%' }}>
                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                            <CardHeader
                                style={{minWidth:"12%",paddingBottom:"8px"}}
                                title='漏電監視 ch1'
                            />
                            <Typography mt={"3px"}>
                                {getValues("puInfo1")}
                            </Typography>
                        </Stack>
                        <CardContent style={{ marginTop:"12px",overflowY:'auto' }}>
                            <Stack spacing={1} pb={"8px"} direction={{ xs: 'column', sm: 'row' }}>
                                <Controller
                                    control={control}
                                    name="type1"
                                    render={({ field: { value } }) => (
                                        <Grid container spacing={1} direction="row" >
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={value}
                                                exclusive
                                                onChange={(e, value) => {
                                                    if(value != null){setValue("type1",value);}
                                            }}
                                                aria-label="Platform"
                                                size="small"
                                                aria-labelledby="権限"
                                            >
                                                <ToggleButton value="0">接点種別 漏電</ToggleButton>
                                                <ToggleButton value="1">接点種別 地絡</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    )}
                                />
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1}>
                                <Controller
                                    name="name1"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="接点名称"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    )}
                                />
                            </Stack>
                        </CardContent>
                    </Card >
                    <Card style={{ margin: '12px auto 12px', width: '100%' }}>
                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                            <CardHeader
                                style={{minWidth:"12%"}}
                                title='漏電監視 ch2'
                            />
                            <Typography mt={"3px"}>
                                {getValues("puInfo2")}
                            </Typography>
                        </Stack>
                        <CardContent style={{ paddingTop: '0' }}>
                        <Stack spacing={1} pb={"8px"} direction={{ xs: 'column', sm: 'row' }}>
                            <Controller
                                control={control}
                                name="type2"
                                render={({ field: { value } }) => (
                                    <Grid container spacing={1} direction="row" >
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={value}
                                            exclusive
                                            onChange={(e, value) => {
                                                if(value != null){setValue("type2",value);}
                                                }
                                            }
                                            aria-label="Platform"
                                            size="small"
                                            aria-labelledby="権限"
                                        >
                                            <ToggleButton value="0">接点種別 漏電</ToggleButton>
                                            <ToggleButton value="1">接点種別 地絡</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                )}
                            />
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1}>
                                <Controller
                                    name="name2"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="接点名称"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    )}
                                />
                            </Stack>
                        </CardContent>
                    </Card >
                    <Card style={{ margin: '12px auto 12px', width: '100%' }}>
                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                            <CardHeader
                                style={{minWidth:"12%"}}
                                title='漏電監視 ch3'
                            />
                            <Typography mt={"3px"}>
                                {getValues("puInfo3")}
                            </Typography>
                        </Stack>
                        <CardContent style={{ paddingTop: '0' }}>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1}>
                                <Controller
                                    name="name3"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="接点名称"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    )}
                                />
                            </Stack>
                        </CardContent>
                    </Card >
                    <Card style={{ margin: '12px auto 12px', width: '100%' }}>
                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                            <CardHeader
                                style={{minWidth:"12%"}}
                                title='漏電監視 ch4'
                            />
                            <Typography mt={"3px"}>
                                {getValues("puInfo4")}
                            </Typography>
                        </Stack>
                        <CardContent style={{ paddingTop: '0' }}>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1}>
                                <Controller
                                    name="name4"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="接点名称"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    )}
                                />
                            </Stack>
                        </CardContent>
                    </Card >
                    <Card style={{ margin: '12px auto 12px', width: '100%' }}>
                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                            <CardHeader
                                style={{minWidth:"12%"}}
                                title='漏電監視 ch5'
                            />
                            <Typography mt={"3px"}>
                                {getValues("puInfo5")}
                            </Typography>
                        </Stack>
                        <CardContent style={{ paddingTop: '0' }}>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1}>
                                <Controller
                                    name="name5"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="接点名称"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    )}
                                />
                            </Stack>
                        </CardContent>
                    </Card >
                    <Card style={{ margin: '12px auto 12px', width: '100%' }}>
                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                            <CardHeader
                                style={{minWidth:"12%"}}
                                title='漏電監視 ch6'
                            />
                            <Typography mt={"3px"}>
                                {getValues("puInfo6")}
                            </Typography>
                        </Stack>
                        <CardContent style={{ paddingTop: '0' }}>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1}>
                                <Controller
                                    name="name6"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="接点名称"
                                            size="small"
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    )}
                                />
                            </Stack>
                        </CardContent>
                    </Card >
                </Grid>
                <Stack direction='row' justifyContent="flex-end" spacing={2} width="100%" margin="10px auto 0">
                    <Grid item xs={1.5}>
                        <Button fullWidth variant="contained" type="submit" disabled={disableSaveBtn}>保存</Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button fullWidth color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Grid>
                </Stack>
            </form >
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleFetchLeaksSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId="UPDATE_LEAKS" onComplete={(success, data, error) => success ? handleUpdateSuccess(data) : handleUpdateError()} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </div >
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "漏電情報",
            body: "漏電情報が取得出来ません。",
        }),
    onUpdate: (data: any) => actionCreators.fetch("UPDATE_LEAKS", `/master/leaks/edit`, 'POST', data, false, true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "漏電変更",
            body: "漏電を更新しました。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "漏電更新",
            body: "漏電を更新出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaksDetails as any);