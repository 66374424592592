import { number } from "yup";

export type YearHeaderProps = {
    year: number,
    mode: string,
    isKwMode?:boolean,
};

export const YearHeader = (props: YearHeaderProps) => {
    const { mode,year,isKwMode } = props;
    return (
        <>
            {
                mode === 'calendarYear' &&
                <>
                    <tr>
                    <th className={'sticky-col col'} colSpan={2} rowSpan={1} style={{ minWidth: '60px', backgroundColor: '#e6b800', verticalAlign: 'middle'}}>{year}年</th>
                        {[...Array(31)].map((x, i) =>
                            <th rowSpan={2} style={{ minWidth: '60px', backgroundColor: "#e6b800"}}>{Number(i+1)} 日</th>
                        )}
                    </tr>
                    <tr>
                        <th className={'sticky-col first-col'} style={{ minWidth: '100px', backgroundColor: '#e6b800'}}>{isKwMode?"月最大":"月合計"}</th>
                        <th className={'sticky-col second-col'} style={{ minWidth: '60px', backgroundColor: '#e6b800'}}>月</th>
                    </tr>
                </>
            }
        </>
    );
};

export default YearHeader;
