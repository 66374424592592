import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { OptionItem } from "../../../models";
import { formatDateTime } from "../../../utils";

export type SettingInformationEditFormProps = {
    settingInformation: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export interface SettingInformationEditModel {
    mainid: string,
    deviceNo: string,
    channelno: string,
    Io: string,
    phase: string,
    angle: string,

}

const ioOption = [
    {
        value: '01',
        label: '01：(未使用)Ior自動設定',
        attribute: '',
    },
    {
        value: '02',
        label: '02：Ior手動設定',
        attribute: '',
    },
    {
        value: '03',
        label: '03：Io設定',
        attribute: '',
    }
]

const phaseOption = [
    {
        value: '01',
        label: '01：単相変圧器',
        attribute: '',
    },
    {
        value: '03',
        label: '03：三相変圧器',
        attribute: '',
    },
]

const angles = ['000', '030', '060', '090', '120', '150'];

export const SettingInformationEdit = (props: SettingInformationEditFormProps) => {
    const { settingInformation, onOK, onCancel } = props;

    const convertValue = (input: string, options: OptionItem[]) => {
        const index = options.findIndex(item => item.value === input);
        return index !== -1 ? input : '';
    }

    // 初期値設定
    const initialValue: SettingInformationEditModel = {
        mainid: settingInformation.mainid,
        deviceNo: settingInformation.device_no,
        channelno: "01",
        Io: convertValue(settingInformation.Io1, ioOption),
        phase: convertValue(settingInformation.phase1, phaseOption),
        angle: settingInformation.angle1,
    }

    const { register, handleSubmit, formState: { errors }, setValue, control, clearErrors } = useForm<SettingInformationEditModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: SettingInformationEditModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: SettingInformationEditModel) => {
        let request = {
            mainid: formValue.mainid,
            device_no: formValue.deviceNo,
            channelno: channelno,
            Io: formValue.Io,
            phase: formValue.phase,
            angle: formValue.angle,
            last_get_datetime: formatDateTime(new Date()),
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const [channelno, setChannelno] = useState("01");

    useEffect(() => {
        clearErrors();
        switch (channelno) {
            case '01': {
                setValue('Io', convertValue(settingInformation.Io1, ioOption));
                setValue('phase', convertValue(settingInformation.phase1, phaseOption));
                setValue('angle', settingInformation.angle1);
                break;
            }
            case '02': {
                setValue('Io', convertValue(settingInformation.Io2, ioOption));
                setValue('phase', convertValue(settingInformation.phase2, phaseOption));
                setValue('angle', settingInformation.angle2);
                break;
            }
            case '03': {
                setValue('Io', convertValue(settingInformation.Io3, ioOption));
                setValue('phase', convertValue(settingInformation.phase3, phaseOption));
                setValue('angle', settingInformation.angle3);
                break;
            }
            case '04': {
                setValue('Io', convertValue(settingInformation.Io4, ioOption));
                setValue('phase', convertValue(settingInformation.phase4, phaseOption));
                setValue('angle', settingInformation.angle4);
                break;
            }
            case '05': {
                setValue('Io', convertValue(settingInformation.Io5, ioOption));
                setValue('phase', convertValue(settingInformation.phase5, phaseOption));
                setValue('angle', settingInformation.angle5);
                break;
            }
            case '06': {
                setValue('Io', convertValue(settingInformation.Io6, ioOption));
                setValue('phase', convertValue(settingInformation.phase6, phaseOption));
                setValue('angle', settingInformation.angle6);
                break;
            }
        }
        // eslint-disable-next-line
    }, [channelno])

    const handleToggle = (evt: React.MouseEvent<HTMLElement>, value: string) => {
        if (value) {
            setChannelno(value);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleEditSubmit)} style={{ height: '100%' }}>
                <div style={{ height: '90%', width: '100%' }}>
                    <Stack spacing={3} style={{ width: '90%', margin: 'auto' }}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <ToggleButtonGroup
                                    color="primary"
                                    value={channelno}
                                    exclusive
                                    onChange={handleToggle}
                                    aria-label="Platform"
                                    size="small"
                                    fullWidth
                                >
                                    <ToggleButton value="01">ch1</ToggleButton>
                                    <ToggleButton value="02">ch2</ToggleButton>
                                    <ToggleButton value="03">ch3</ToggleButton>
                                    <ToggleButton value="04">ch4</ToggleButton>
                                    <ToggleButton value="05">ch5</ToggleButton>
                                    <ToggleButton value="06">ch6</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <FormControl fullWidth
                                    size="small" error={!!errors?.Io}>
                                    <InputLabel >Io/Ior設定</InputLabel>
                                    <Controller
                                        name="Io"
                                        control={control}
                                        rules={{ required: '必須項目です。入力してください。' }}
                                        render={({ field } : any) => (
                                            <>
                                                <Select
                                                    {...field}
                                                    label="Io/Ior設定"
                                                    error={!!errors?.Io}
                                                >
                                                    {ioOption.map(item => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    !!errors?.Io &&
                                                    <FormHelperText style={{ color: 'red' }}>{errors?.Io?.message}
                                                    </FormHelperText>
                                                }
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <FormControl fullWidth
                                    size="small" error={!!errors?.phase}>
                                    <InputLabel>変圧器種類</InputLabel>
                                    <Controller
                                        name="phase"
                                        control={control}
                                        rules={{ required: '必須項目です。入力してください。' }}
                                        render={({ field } : any) => (
                                            <>
                                                <Select
                                                    {...field}
                                                    label="変圧器種類"
                                                    error={!!errors?.phase}
                                                >
                                                    {phaseOption.map(item => (
                                                        <MenuItem key={item.value} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {
                                                    !!errors?.phase &&
                                                    <FormHelperText style={{ color: 'red' }}>{errors?.phase?.message}
                                                    </FormHelperText>
                                                }
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="位相補正角設定"
                                    size="small"
                                    {...register("angle",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => angles.includes(value) || '000,030,060,090,120,150 の値を入力してください。',
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.angle}
                                    helperText={errors?.angle?.message}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                </div>
                <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }} style={{ position: 'relative', right: 'calc(5% + 15px)', top: '-5px' }}>
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    <Button variant="contained" type="submit">保存</Button>
                </Stack>
            </form >
        </>
    );
};
export default SettingInformationEdit;


