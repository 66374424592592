export const getDefaultStartAndEnd = () => {
  const now = new Date();
  return {
    startDate: formatDate(now),
    endDate: formatDate(now),
  };
};

export const getDate = (dateTime: string) => {
  var date = new Date(Date.parse(dateTime));
  var month = date.getMonth() + 1;
  return date.getFullYear() + "/" + month + "/" + date.getDate();
};

export const formatDate = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
};

export const formatDateTime = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
    }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
    }:${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`;
};

export const parseDate = (dateStr: string): Date | undefined => {
  if (dateStr.match(/^\d{4}-(12|11|10|0[1-9])-(0[1-9]|[12][0-9]|30|31)$/)) {
    const param = dateStr.split("-").map((e) => Number(e));
    const date = new Date(param[0], param[1] - 1, param[2], 0, 0, 0, 0);
    return date;
  }
  return undefined;
};
export const parseDatePrefix = (dateStr: string): Date | undefined => {
  if (!dateStr) return undefined;
  if (dateStr.match(/^\d{4}-(12|11|10|0[1-9])-(0[1-9]|[12][0-9]|30|31)/)) {
    const param = dateStr.slice(0, 10).split("-").map((e) => Number(e));
    const date = new Date(param[0], param[1] - 1, param[2], 0, 0, 0, 0);
    return date;
  }
  return undefined;
};
export const parseDatePrefix2 = (dateStr: string): Date | null => {
  return parseDatePrefix(dateStr) ?? null;
};

export const addDays = (date: Date, count: number) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + count);
};

export const compareDate = (date1: Date, date2: Date) => {
  if (!date1 && !date2) return 0;
  if (!date1) return 1;
  if (!date2) return -1;

  var y1 = date1.getFullYear();
  var m1 = date1.getMonth() + 1;
  var d1 = date1.getDate();
  var y2 = date2.getFullYear();
  var m2 = date2.getMonth() + 1;
  var d2 = date2.getDate();
  if (y1 < y2) return 1;
  if (y1 > y2) return -1;

  if (m1 < m2) return 1;
  if (m1 > m2) return -1;

  if (d1 < d2) return 1;
  if (d1 > d2) return -1;

  // 一致
  return 0;
}

// string | null 型を string 型 に変換する
export const parseNullStringToString = (value: string | null) => {
  return value ? value.toString() : '';
}

// number | null 型を string 型 に変換する
export const parseNullNumberToString = (value: number | null) => {
  return value ? value.toString() : '';
}

// boolean | null 型を string 型 に変換する
export const parseUndefinedBooleanToString = (value: boolean | undefined) => {
  return value ? value.toString() : 'false';
}

// date | null 型を string 型 に変換する
export const parseNullDateToString = (date: Date | null) => {
  if (!date) return '';
  const toString = Object.prototype.toString;
  if (toString.call(new Date()) !== toString.call(date)) return date;
  return formatDate(date);
}
export const parseNullDateTimeToString = (date: Date | null) => {
  if (!date) return '';
  const toString = Object.prototype.toString;
  if (toString.call(new Date()) !== toString.call(date)) return date;
  return formatDateTime(date);
}

// 日付有効チェック
export const isEnableDate = (date: Date) => {
  let d = new Date(date);
  if (isNaN(d.getDate())) return false;
  // 日付が「Wed Nov 29 2017 15:56:43 GMT+0900 (JST)」形式の場合はOK
  if (date.toString().search("-") < 0 && date.toString().search("/") < 0) {
    return true;
  }
  // 日付が「YYYY-MM-DD」形式や「YYYY/MM/DD」形式の場合
  // 2022-04-31 が new Date() で 2022年05月01日 と解釈されOKとなるので、
  // new Date()での変換結果と、元々の文字列の月が異なった場合は、
  // 無効な日付と判定する
  var sep = date.toString().search("-") >= 0 ? "-" : "/";
  var s: string[] = date.toString().split(sep);
  return d.getMonth() + 1 === Number(s[1]);
}

export const isEnableTime = (time: string) => {
  const hour = time.slice(0, 1);
  const minute = time.slice(1, 2);
  if (hour >= '24') {
    return false;
  }
  if (minute >= '60') {
    return false;
  }
  return true;
}

export const formatTime = (time: string) => {
  if (time === null) {
    return "";
  }
  if (time.length < 4) {
    time = time.padStart(4, '0');
  }
  const hour = time.slice(0, 2);
  const minute = time.slice(2, 4);
  return hour + ':' + minute;
}

export const formatNumber = (value: string) => {
  if (value == null || value.length < 0) {
    return 0;
  }
  return value.toLocaleString();
}

export const formatNumberNullToHyphen = (value: string, unit: string) => {
  if (value == null || value.length < 0) {
    return "-";
  }
  return value.toLocaleString() + " " + unit;
}

export const showUnit = (value: string, unit: string) => {
  return value ? unit : "";
}

export const changeDisabledPollingBtn = (newValue: boolean, deviceNo: string, deviceNos: string[], disabledBtn: boolean[]) => {
  const deviceIndex = deviceNos.findIndex((element) => element === deviceNo);
  const arr = [...disabledBtn].map((value, index) => {
    if (index === deviceIndex) {
      return value = newValue;
    }
    return value;
  })
  return arr;
}

export const padZeroToStart = (value: string, length: number) => {
  return value.length === length ? value : value.padStart(length, '0');
}

export const formatDecimal = (value: string,digit:number) => {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    return value;
  }
  
  const options = {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit
  };
  
  return parsedValue.toLocaleString(undefined, options);
}

export const convertTimeToSecond = (time:string) => {
  if(time){
      const array = time.split(":");
      if(array.length === 3){
          const hours = array[0];
          const minutes = array[1];
          const seconds = array[2];
          return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds)
      }
  }
  return 0;
}

export const formatRoundDownDecimal = (number:any,digit:any,isNumber?:any) => {
  if(number == "-" || number == null || number == undefined)
      return "-"
  const shiftedNumber = number * Math.pow(10,digit);
  const roundedNumber = Math.floor(shiftedNumber);
  const result = roundedNumber / Math.pow(10,digit);
  if(!isNumber)
    return result.toLocaleString(); 
  return result
} 

export const groupBy = (array:any, key:any) => {
  return array.reduce((rv:any, x:any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};