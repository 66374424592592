import { Checkbox, CircularProgress, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import { formatNumber } from "../../../utils";
import ChartPanel, { ChartResult } from "../../chart/ChartPanel";
import FetchHelper from "../../FetchHelper";
import { Progress } from "./Progress";

const fetchId = "321_DEMANDLP_LIST";
const formId = "321_DEMANDLP_SEARCH_FORM";
const menusFetchId = 'MENUS_FETCH';
const COPY_LINK = "COPY_LINK";


// -------------
// Props
export type Gaex3Props = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

interface Gaex3Data {
    dataChart1: number[],
    dataChart2: number[],
    dataChart3: number[],
}

// -------------

// Component
export const Gaex3 = (props: Gaex3Props) => {
    const {
        onError,
        onSearch,
        onErrorSearch,
        onSuccessCopy,
        onErrorCopy,
        onGetAuthRole,
        onCopyLink,
        fetchStateMenu
    } = props;

    let navigate = useNavigate();
    const [gaex3Data, setGaex3Data] = React.useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [authRole, setAuthRole] = React.useState<any>();
    const [checked, setChecked] = React.useState(false);
    const [chartModel,setChartModel] = React.useState<any>([]);
    const labels = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5];

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const mainid = urlParams.get('mainid');

    React.useEffect(()=> {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    // 検索実行
    const handleSearchSubmit = () => {
        let isError = false;
        if (isEmpty(mainid)) isError = true;

        const params = new Map<string, any>();
        params.set("code", "GAEX3")
        params.set("mainid", mainid)

        if (!isError) {
            setLoadingFlag(true);
            onSearch(params);
        }
        else {
            onErrorSearch();
        }
    }

    const isEmpty = (value: any) => {
        return (value === null || value?.length <= 0)
    }

    const handleFetchSuccess = (data: any) => {
        const chartData = reCreateChartData(data);
        setChartModel(chartData);
        setGaex3Data(data);

        setLoadingFlag(false);
    };

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit();
        // eslint-disable-next-line
    }, []);

    // キャンセル
    const handleCancel = () => {
        let path = sessionStorage.getItem("references");
        if(!path){
            if(authRole.auth_role_map["LM-telemetry-view"])
            {

                if(authRole.code_restriction_array?.length == 0 || authRole.code_restriction_array?.includes("GASOLAR")){
                    let newPath = "/telemetry/ga2";
                    let newUrlPath = (urlParams.get('path'))
                    if(newUrlPath){
                        newUrlPath = (urlParams.get('path') ?? "root");
                        newPath +='?path=' + newUrlPath;
                        newPath += id ? '&id=' + id : "";
                        if (newUrlPath=="customer") {
                            newPath += '&customer_id=' + (urlParams.get('customer_id') ?? '');
                        }
                    }else{
                        newPath += "?path=root";
                    }
                    if(newPath){
                        navigate(newPath, { replace: true });
                        return;
                    }
                }
            }
            if(authRole.auth_role_map["LM-smonitor-view"]){
                if(authRole.code_restriction_array?.includes("GASOLAR")){
                    navigate(RoutePath.SmartSolarList);
                    return;
                }
            }
            navigate("/");
            return;
        }
        sessionStorage.removeItem('references');
        let urlPath = (urlParams.get('path'))
        if(urlPath){
            urlPath = (urlParams.get('path') ?? "root");
            path +='?path=' + urlPath;
            path += id ? '&id=' + id : "";
            if (urlPath=="customer") {
                path += '&customer_id=' + (urlParams.get('customer_id') ?? '');
            }
        }else{
            path += id ? '?id=' + id : "";
        }
        if(path)
            navigate(path, { replace: true });
    }

    const handleNavigateMonthlyReport = () => {
        navigate(`${RoutePath.Gaex2}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
    }

    const handleNavigateYearlyReport = () => {
        navigate(`${RoutePath.Gaex5}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
    }

    const handleChange = (e:any) => {
        setChecked(e.target.checked);
    }

    const reCreateChartData = (data : any) => {
        return {
            chartId: 5,
            labels: labels,
            dataChart: CreateGaex3Data(data),
        } as ChartResult;
    };

    const CreateGaex3Data = (data : any) => {
        return {
            dataChart1: data.days_data[1].halfhours_data.map((item: any) => item.used_kw),
            dataChart2: data.days_data[0].halfhours_data.map((item: any) => item.used_kw),
            dataChart3: labels.map(() => data.panel_capacity === 0 ? null : data.panel_capacity),
            list_source_type_today: data.days_data[1].halfhours_data.map((item: any) => item.source_type),
            list_temperature_today: data.days_data[1].halfhours_data.map((item: any) => item.temperature),
            list_condition_today: data.days_data[1].halfhours_data.map((item: any) => item.condition),
            list_solar_radiation_today: data.days_data[1].halfhours_data.map((item: any) => item.solar_radiation),
            list_precipitation_today: data.days_data[1].halfhours_data.map((item: any) => item.precipitation),
        } as Gaex3Data;
    };

    const handleCopyLinkSolarReport = () => {
        setLoadingFlag(true);
        const params = {
            code: "OUTURL",
            type: "GAEX3",
            customer_id: urlParams.get("customer_id"),
            mainid: mainid,
        }
        onCopyLink(params);
    }

    const handleSuccessCopyLink = async (data:any) => {
        setLoadingFlag(false);
        if(data && data.result_code == 0){
            const string = `${data.results.generate_url}`.toString();
            try {
                await navigator.clipboard.writeText(string);
                onSuccessCopy();
            } catch (err) {
                onErrorCopy("");
            }
        }else{
            onErrorCopy("");
        }
    }

    const handleErrorCopyLink = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                {
                    gaex3Data &&
                    <Progress onlyBackFlag={urlParams.get('customer_id') ? false : true} onRefreshChart={() => {handleSearchSubmit()}} stopFlag={checked} onBack={handleCancel} 
                    onMonthlyReport={handleNavigateMonthlyReport} onYearlyReport={handleNavigateYearlyReport} onCopyLink={handleCopyLinkSolarReport} progressData={gaex3Data} ></Progress>
                }
                <div style={{ maxWidth: '500px', margin: 'auto'}} >
                    <ChartPanel chartModel={chartModel} />
                    <div style={{ textAlign: 'center',marginLeft:"50px" }}>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={checked}
                                    onChange={handleChange}/>}
                                    label="自動更新" 
                                />
                    </div>
                </div>
                <div style={{margin: 'auto'}} >
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h3" style={{ color: "gray"}}>当月発電予想金額 {formatNumber(gaex3Data?.estimated_amount)} 円</Typography>
                        <Typography variant="h3">当月発電実績金額 {formatNumber(gaex3Data?.actual_amount)} 円</Typography>
                    </div>
                </div>
            </div>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleFetchSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />

            <FetchHelper fetchId={COPY_LINK} onComplete={(success, data, error) => {
                success ? handleSuccessCopyLink(data) : handleErrorCopyLink(success, data, error)}} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onCopyLink: (params:any) => actionCreators.fetch(COPY_LINK,`/telemetry/get?code=${params.code}&type=${params.type}&customer_id=${params.customer_id}&mainid=${params.mainid}`,"GET",null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "遠方監視管理一覧検索",
            body: "遠方監視管理一覧を検索できません。" + error,
        }),
    onErrorSearch: () =>
        actionCreators.showMessage({
            type: "error",
            title: "遠方監視管理一覧検索",
            body: "遠方監視管理一覧を検索できません。",
        }),
    onSuccessCopy: () => 
        actionCreators.showMessage({
            type: "info",
            title: "URLをコピー",
            body: "URLをコピーしました。",
        }),
    onErrorCopy: (message:any) => 
        actionCreators.showMessage({
            type: "error",
            title: "URLをコピー",
            body: "URLをコピーできません。" ,
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gaex3 as any);
