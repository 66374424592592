import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { actionCreators, selectors } from '../store/AppStore';
import FetchHelper from './FetchHelper';
// material
import { Box, SxProps } from '@mui/material';
import LogoIcon from '../logo/logo_1x.png';
// ----------------------------------------------------------------------

const menusFetchId = "MENUS_FETCH";

const params = {
    setMenusData: (data: any) => {}
}

export type LogoProps = 
    {
        sx?: SxProps<any>;
    } 
    & typeof params
    & ReturnType<typeof mapStateToProps> 
    & typeof mapDispatchToProps;

export const Logo = (props: LogoProps) => {
    const { sx, onGetMenu, setMenusData, isAuthorize, isNewPasswordRequired } = props;

    useEffect(() => {
        if (isAuthorize && !isNewPasswordRequired){
            onGetMenu();
        }
    }, []);

    const handleClickLogo = () => {
        onGetMenu();
    }

    const handleFetchMenusSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setMenusData(data)
    }

    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
        setMenusData([])
    };

    return (
        <>
            <RouterLink to="/" onClick={handleClickLogo}>
                <Box component="img" src={LogoIcon} sx={{ width: 40, height: 40, ...sx }} />
            </RouterLink>
            
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) =>
                success ? handleFetchMenusSuccess(data) : handleError(success, data, error)} />
        </>
    );
}

const mapStateToProps = (state: any) => ({
    isAuthorize: selectors.isAuthorize(state),
    isNewPasswordRequired: selectors.isNewPasswordRequired(state),
});

const mapDispatchToProps = {
    onGetMenu: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, "GET", null),
    
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logo);