import { Breakpoint, Button, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useResponsive from '../../../hooks/useResponsive';
import LinkIcon from '@mui/icons-material/Link';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { ReactComponent as UmbrellaIcon } from "../../../assets/umbrella.svg";
import { ReactComponent as CloudIcon } from "../../../assets/cloud.svg";

export type ProgressProps = {
    progressData: any;
    onRefreshChart: () => void;
    stopFlag: boolean;
    onBack: () => void;
    onMonthlyReport: () => void;
    onYearlyReport: () => void;
    onCopyLink: () => void;
};

const buttonStyle1= {
    backgroundColor:  "white",
    color: '#7D7C7C',
    height: '40px',
    margin: 'auto 0',
    border: '0.5px solid #B4B4B3',
    borderRadius: '9px 0 0 9px',
}
const buttonStyle2 = {
    backgroundColor:  "white",
    color: '#7D7C7C',
    height: '40px',
    margin: 'auto 0',
    border: '0.5px solid #B4B4B3',
    borderRadius:'0px 0px 0px 0px',
}

const buttonStyle3 = {
    backgroundColor:  "white",
    color: '#7D7C7C',
    height: '40px',
    margin: 'auto 0',
    border: '0.5px solid #B4B4B3',
    borderRadius: '0px 9px 9px 0px',
}

const buttonStyle4= {
    backgroundColor:  "white",
    color: '#7D7C7C',
    height: '40px',
    margin: 'auto 0',
    border: '0.5px solid #B4B4B3',
    borderRadius: '9px 9px 9px 9px',
}

export const Progress = (props: ProgressProps) => {
    const { progressData, onRefreshChart, stopFlag,onBack,onMonthlyReport,onYearlyReport, onCopyLink } = props;
    const [scaleInfoList, setScaleInfoList] = useState<any>([]);    
    const [scaleInfoListMobileSize,setScaleInfoListMobileSize] = useState<any>([]);
    const [data, setData] = useState<any>();
    const [warningFlag,setWarningFlag] = useState<boolean>(false);
    const [warningPercent,setWarningPercent] = useState<any>();    
    const myInterval = useRef<any>();
    const widthOneRectMobileSize = 60;
    const widthOneRectOtherSize = 100;
    const isMobile = useResponsive({ query: 'between', key: "sm" as Breakpoint,start:320,end:766 });

    useEffect(() => {
        let value = {
            customer_name: "",
            target_month: "",
            target_day: "",
            target_time: "",
            current_kw: 0,
            th2nd_limit_kw: 0,
            percent: "0",
        }
        if(progressData?.customer_name){
            value = {
                customer_name: progressData.customer_name,
                target_month: progressData.target_month,
                target_day: progressData.target_day,
                target_time: progressData.target_time,
                current_kw: progressData.current_kw ? progressData.current_kw : 0,
                th2nd_limit_kw: progressData.th2nd_limit_kw ? progressData.th2nd_limit_kw : 0,
                percent: progressData.th2nd_limit_kw ? Math.round((progressData.current_kw / progressData.th2nd_limit_kw) * 100).toString() : "0",
            }
            if(progressData.th2nd_limit_kw && progressData.th2nd_limit_kw != 0 && progressData.th1st_caution_kw && progressData.th1st_caution_kw != 0 && progressData.th1st_caution_visible == "1"){
                setWarningFlag(true);
                const warningPercent = Math.round((progressData.th1st_caution_kw / progressData.th2nd_limit_kw) * 100).toString()
                setWarningPercent(Math.min(Number(warningPercent),100));
            }else{
                setWarningFlag(false);
            }

            if(progressData?.th2nd_limit_kw){
                let scaleInfoListTemp = [] as any;
                let scaleInfoListTempMobileSize = [] as any;
                let percentValue = Number(value.percent);
                let totalPercentValue = 0;
                for (let index = 0; index < 6; index++) {
                    if(index !== 0){
                        let color = "#D0F288";
                        if(index === 4 )
                        {
                            color = "#FEFF89"
                        }
                        else if(index === 5){
                            color = "orange"
                        }
                        if(percentValue > 20)
                        {
                            scaleInfoListTemp.push({color: color ,width: `${widthOneRectOtherSize}px`})
                            scaleInfoListTempMobileSize.push({color: color ,width: `${widthOneRectMobileSize}px`})
                            percentValue = percentValue - ((20 * index) -  totalPercentValue)
                            totalPercentValue = (20 * index);
                        }
                        else{
                            let valueMobileSize = Math.min((percentValue * widthOneRectMobileSize) / 20, widthOneRectMobileSize);
                            let valueLargerMobileSize = Math.min((percentValue * widthOneRectOtherSize) / 20, widthOneRectOtherSize);
                        
                            scaleInfoListTemp.push({color: color ,width: valueLargerMobileSize + 'px'})
                            scaleInfoListTempMobileSize.push({color: color ,width: valueMobileSize + 'px'})
                            percentValue = 0
                        }
                    }
                }
                setScaleInfoList(()=>[...scaleInfoListTemp]);
                setScaleInfoListMobileSize(() => [...scaleInfoListTempMobileSize])
            }
        }
        
        setData(value);
        refreshChart();
        // eslint-disable-next-line
    }, [progressData]);
  

    useEffect(()=>{
        refreshChart();
        return () => {
            clearInterval(myInterval.current);
          };
    }, [stopFlag]);

    const refreshChart = () => {
        clearInterval(myInterval.current);
        if(stopFlag){
            let str = progressData.target_time.replace('分','').split('時');
            const dayServer = progressData.target_day;
            const monthServer = progressData.target_month;
            if(str.length === 2){
                const currentTime = new Date();
                const dayLocal = currentTime.getDate();
                const monthLocal = currentTime.getMonth() + 1;
                const yearLocal = currentTime.getUTCFullYear();
                const dateServer:any = new Date(`${monthLocal}/${dayLocal}/${yearLocal}`);
                const dateLocal:any = new Date(`${monthServer}/${dayServer}/${yearLocal}`);
                const diffTime = Math.abs(dateLocal - dateServer);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                let serverTime = Number(str[0]) * 60 + Number(str[1]);
                serverTime += 60;
 
                let clientTime = diffDays * 24 * 60 + currentTime.getHours() * 60 + currentTime.getMinutes();  
                let temp = 0;
                if(clientTime >= serverTime){
                    temp = 10;
                }else{
                    temp = ((serverTime - clientTime) * 60) - currentTime.getSeconds();
                }
                myInterval.current = setInterval(() => {
                    onRefreshChart();
                    clearInterval(myInterval.current);
                }, temp * 1000);
            }
        }
    };

    return (
        <Stack width="100%" textAlign="center">
            <div style={{ display: 'flex', backgroundColor: '#FFD966', justifyContent: 'center', marginBottom: '10px' }}>
                <Typography style={{ fontSize: '18px', fontWeight: 'bold', padding: '6px', textDecoration: 'underline'}}>スマート · デマンド３</Typography>
            </div>
            {
                (!isMobile) &&
                <>
                <div style={{display: "inline-flex",justifyContent:"center"}}>
                    {
                        progressData.condition && progressData.temperature &&
                        <>
                            {(progressData.condition == "晴れ" || progressData.condition == "快晴") && <>
                                <WbSunnyIcon style={{marginRight:"5px"}}  color="warning"  />
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </>}
                            {( progressData.condition == "雲り") && <>
                                <CloudIcon style={{marginRight:"5px"}} /> 
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </> }
                            {( progressData.condition == "雨") && <>
                                <UmbrellaIcon style={{marginRight:"5px"}} />
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </> }
                            {( progressData.condition == "雪") && <>
                                <AcUnitIcon style={{marginRight:"5px"}} /> 
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </> }
                        </>
                    }
                    <text>{data?.customer_name}</text><span style={{ marginLeft: '25px' }}>様</span>  
                    {
                        progressData.condition && progressData.temperature &&
                        <>
                        <div style={{ textTransform: 'none',opacity:"0"}}>
                            {(progressData.condition == "晴れ" || progressData.condition == "快晴") && <>
                                <WbSunnyIcon style={{marginRight:"5px"}}  color="warning"  />
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </>}
                            {( progressData.condition == "雲り") && <>
                                <CloudIcon style={{marginRight:"5px"}} /> 
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </> }
                            {( progressData.condition == "雨") && <>
                                <UmbrellaIcon style={{marginRight:"5px"}} />
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </> }
                            {( progressData.condition == "雪") && <>
                                <AcUnitIcon style={{marginRight:"5px"}} /> 
                                <text style={{marginRight:"50px"}}>{progressData.temperature}°C</text>
                            </> }
                        </div>
                        </>
                    }                  
                </div>
                <div style={{display: "inline-flex",justifyContent:"right"}}>
                    <Button style={{ textTransform: 'none',opacity:"0",marginLeft:"10px"}} disabled={true} variant="text">kWh</Button>
                    <Button style={{ textTransform: 'none',opacity:"0" }} disabled={true} variant="text">kW</Button>
                    <Button style={{textTransform:'none',opacity:"0" }} disabled={true} variant="text">金額</Button>
                    <Button style={{textTransform:'none',opacity:"0" }} disabled={true}> <LinkIcon sx={{color:"black", fontSize:"35px"}} /></Button>
                    <div style={{fontWeight: 'bold',display:"block", margin: '12px auto',justifyContent:"center" }}>
                        <span>{data?.target_month}月{data?.target_day}日</span>
                        &emsp;
                        <span>{data?.target_time}</span>
                        &emsp;
                        <span>（３０分每更新）</span>
                    </div>
                    <Button style={{ textTransform: 'none' }} sx={buttonStyle1} onClick={onBack} variant="text">戻る</Button>
                    <Button style={{ textTransform: 'none' }}  sx={buttonStyle2} onClick={onMonthlyReport} variant="text">月表</Button>
                    <Button style={{textTransform:'none'}} onClick={onYearlyReport} sx={buttonStyle2} variant="text">年表</Button>
                    <Button sx={buttonStyle3} onClick={onCopyLink}> <LinkIcon sx={{color:"#7D7C7C", fontSize:"30px"}} /></Button>                    
                </div>
                <div style={{ margin: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '450px', marginBottom: '20px' }}>
                        <div style={{ minWidth: '250px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ backgroundColor: '#FFD966', width: '100px', height: '25px', borderRadius: '5px', marginTop: '5px', marginRight: '40px' }}>使用電力</div>
                                <div style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '30px' }}>{data?.current_kw}<span style={{ fontWeight: 'normal', fontSize: '16px' }}>kW</span></div>
                            </div>
                            <hr style={{ width: '250px' }} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ backgroundColor: '#FFD966', width: '120px', height: '25px', borderRadius: '5px', marginTop: '5px', marginRight: '40px' }}>限界デマンド</span>
                                <div style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '30px' }}>{data?.th2nd_limit_kw}<span style={{ fontWeight: 'normal', fontSize: '16px' }}>kW</span></div>
                            </div>
                        </div>
                        <div>
                            <div style={{ backgroundColor: '#FFD966', width: '100px', height: '25px', borderRadius: '5px' }}>使用率</div>
                            <div style={{ fontWeight: 'bold', fontSize: '50px', lineHeight: '50px' }}>{data?.percent}<span style={{ fontWeight: 'normal', fontSize: '20px' }}>%</span></div>
                        </div>
                    </div>
                </div>
                <div style={{ height: '100%', width: '100%' }}>
                <div style={{position:"relative"}}>
                    <table style={{ margin: 'auto' }}>
                        <tbody>
                            <tr>
                                {scaleInfoList.length > 0 ? (
                                    <>
                                        {
                                            warningFlag && 
                                            <div style={{position:"relative"}}>
                                                <div style={{position: "absolute",
                                                top: "-30px",
                                                left: `${warningPercent * 5 - 24 + Math.round((warningPercent)/20)*2.35}px`,
                                                width: "50px",
                                                height: "100px",
                                                color:"#000000",
                                                margin:"auto"}}>
                                                    注意
                                                </div>
                                                <div style={{
                                                    position: "absolute",
                                                    top: "-5px",
                                                    left: `${warningPercent  * 5 + Math.round(warningPercent/20)*2.35 - 3.55}px`,
                                                    width: "3px",
                                                    height: "30px",
                                                    backgroundColor: "#808080",
                                                    zIndex:"999",
                                                }}></div>
                                            </div>
                                        }
                                        
                                        {[...scaleInfoList].map((x, i) =>
                                            <>
                                            <td style={{width: `${widthOneRectOtherSize}px`, height: '25px', border: 'none', backgroundColor: '#D8D9DA', position: 'relative'}}>
                                                <div style={{position: 'absolute',top: '0', left: '0', width: x.width, height: '25px', backgroundColor: x.color, zIndex: '1'}}>
                                                </div>
                                            </td>
                                            </>
                                        )}
                                    </>
                                ):(
                                    <>
                                        {[...Array(5)].map((x, i) =>
                                            <>
                                            <td style={{width: `${widthOneRectOtherSize}px`, height: '25px', border: 'none', backgroundColor: '#D8D9DA'}}>
                                                
                                            </td>
                                            </>
                                        )}
                                    </>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
                    <table style={{ margin: 'auto' }}>
                        <tbody>
                            <tr>
                            {[...Array(6)].map((x, i) =>
                                <>
                                    {i === 5 ? ( 
                                        <td style={{ width: `${widthOneRectOtherSize}px`}}>
                                            {i * 20}
                                            <br />
                                            <span>(%)</span>
                                        </td>
                                    ) : (
                                        <td style={{width: `${widthOneRectOtherSize}px`, verticalAlign: 'top'}}>{i * 20}</td>
                                    )}
                                </>
                            )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
                </>
            }
            {isMobile &&
                <>
                <div style={{display: "inline-flex",justifyContent:"right",position:"relative"}}>
                    {
                        progressData.condition && progressData.temperature ?
                        <>
                            <Stack direction={"row"} margin={"auto 0px"}>
                                {(progressData.condition == "晴れ" || progressData.condition == "快晴") && <>
                                    <WbSunnyIcon style={{marginRight:"5px"}} color="warning"/>
                                    <text>{progressData.temperature}°C</text>
                                </>}
                                {( progressData.condition == "雲り") && <>
                                    <CloudIcon style={{marginRight:"5px"}} /> 
                                    <text>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雨") && <>
                                    <UmbrellaIcon style={{marginRight:"5px"}} />
                                    <text>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雪") && <>
                                    <AcUnitIcon style={{marginRight:"5px"}} /> 
                                    <text>{progressData.temperature}°C</text>
                                </> }
                            </Stack>
                        </>
                        :
                        <Button style={{ textTransform: 'none',opacity:0,cursor:"normal"}} disabled={true} sx={buttonStyle4} variant="text">戻る</Button>
                    }
                    <div style={{display:"block",width:"90%",justifyContent:"center" }}>
                        {data?.customer_name}
                        <br/>
                        <span style={{fontWeight:"bold",marginTop:"20px"}}>{data?.target_month}月{data?.target_day}日</span>
                        <span style={{fontWeight:"bold",}}>{data?.target_time}</span>
                    </div>
                    <Button style={{ textTransform: 'none', marginLeft:"10px" }} sx={buttonStyle4} onClick={onBack} variant="text">戻る</Button>
                </div>
                <div style={{marginLeft:"10px",marginRight:"10px",marginTop:"30px"}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <div style={{width:"65%"}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ backgroundColor: '#FFD966', width: '90px', height: '25px', borderRadius: '5px', marginTop: '5px'}}>使用電力</div>
                                <div style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '30px' }}>{data?.current_kw}<span style={{ fontWeight: 'normal', fontSize: '16px' }}>kW</span></div>
                            </div>
                            <hr style={{ width: '100%' }} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ backgroundColor: '#FFD966', width: '110px', height: '25px', borderRadius: '5px', marginTop: '5px'}}>限界デマンド</span>
                                <div style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '30px' }}>{data?.th2nd_limit_kw}<span style={{ fontWeight: 'normal', fontSize: '16px' }}>kW</span></div>
                            </div>
                        </div>
                        <div>
                            <div style={{ backgroundColor: '#FFD966', width: '100px', height: '25px', borderRadius: '5px' }}>使用率</div>
                            <div style={{ fontWeight: 'bold', fontSize: '50px', lineHeight: '50px' }}>{data?.percent}<span style={{ fontWeight: 'normal', fontSize: '20px' }}>%</span></div>
                        </div>
                    </div>
                </div>
                <div style={{position:"relative",marginTop:"20px"}}>
                    <table style={{ margin: 'auto' }}>
                        <tbody>
                            <tr>
                                {scaleInfoListMobileSize.length > 0 ? (
                                    <>
                                        {
                                            warningFlag && 
                                            <div style={{position:"relative"}}>
                                                <div style={{position: "absolute",
                                                top: "-30px",
                                                left: `${warningPercent * 5 * widthOneRectMobileSize / 100 - 24 + Math.round((warningPercent * widthOneRectMobileSize/100)/20)*2.35}px`,
                                                width: "50px",
                                                height: "100px",
                                                color:"#000000",
                                                margin:"auto"}}>
                                                    注意
                                                </div>
                                                <div style={{
                                                    position: "absolute",
                                                    top: "-5px",
                                                    left: `${warningPercent  * 5 * widthOneRectMobileSize / 100 + Math.round(warningPercent * widthOneRectMobileSize/100/20)*2.35}px`,
                                                    width: "3px",
                                                    height: "30px",
                                                    backgroundColor: "#808080",
                                                    zIndex:"999",
                                                }}></div>
                                            </div>
                                        }
                                        
                                        {[...scaleInfoListMobileSize].map((x, i) =>
                                            <>
                                            <td style={{width:`${widthOneRectMobileSize}px`, height: '25px', border: 'none', backgroundColor: '#D8D9DA', position: 'relative'}}>
                                                <div style={{position: 'absolute',top: '0', left: '0', width: x.width, height: '25px', backgroundColor: x.color, zIndex: '1'}}>
                                                </div>
                                            </td>
                                            </>
                                        )}
                                    </>
                                ):(
                                    <>
                                        {[...Array(5)].map((x, i) =>
                                            <>
                                            <td style={{width:`${widthOneRectMobileSize}px`,height: '25px', border: 'none', backgroundColor: '#D8D9DA'}}>
                                                
                                            </td>
                                            </>
                                        )}
                                    </>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
                    <table style={{ margin: 'auto' }}>
                        <tbody>
                            <tr>
                            {[...Array(6)].map((x, i) =>
                                <>
                                    {i === 5 ? ( 
                                        <td style={{ width: '42.85px'}}>
                                            {i * 20}
                                            <span>(%)</span>
                                        </td>
                                    ) : (
                                        <td style={{width: `${widthOneRectMobileSize}px`, verticalAlign: 'top',marginRight:"20px"}}>{i * 20}</td>
                                    )}
                                </>
                            )}
                            </tr>
                        </tbody>
                    </table>
                </div>
                </>
            }
        </Stack >
    );
};

export default Progress;
