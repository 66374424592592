import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { connect } from "react-redux";
import { SettingInformationModel, UpdateResult } from '../../../models';
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import { changeDisabledPollingBtn } from '../../../utils';
import FetchHelper from '../../FetchHelper';
import SelectSettingInformationDialog from '../../dialogs/SelectSettingInformationDialog';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 8;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
    isDisbleUpdateBtn: true,
}

type SettingInformationProps = {
    defaultData: SettingInformationModel[] | undefined;
};

export type PureSettingInformationProps = SettingInformationProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

const colStyleTitle = {
    width: '40%',
    border: 'none',
}

const colStyle = {
    border: 'none',
}

const fetchId = "SETTING_INFORMATION_FETCH";

export const SettingInformation = (props: PureSettingInformationProps) => {

    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isEdit, isRefreshGroup, isDisbleRefeshBtn, isDisbleUpdateBtn } = props;
    const { onError, onUpdate, onRefresh, onUpdateError, onUpdateSuccess, isRefreshAllCompleted, setIsEdit, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [updateData, setUpdateData] = React.useState<any>();
    const [settingInformation0, setSettingInformation0] = React.useState<SettingInformationModel>(fetchState.data);
    const [settingInformation1, setSettingInformation1] = React.useState<SettingInformationModel>(fetchState.data);
    const [settingInformation2, setSettingInformation2] = React.useState<SettingInformationModel>(fetchState.data);
    const [settingInformation3, setSettingInformation3] = React.useState<SettingInformationModel>(fetchState.data);
    const [settingInformation4, setSettingInformation4] = React.useState<SettingInformationModel>(fetchState.data);
    const [editData, setEditData] = React.useState<SettingInformationModel>();
    const [fetchLoadingDevice0, setFetchLoadingDevice0] = React.useState(false);
    const [fetchLoadingDevice1, setFetchLoadingDevice1] = React.useState(false);
    const [fetchLoadingDevice2, setFetchLoadingDevice2] = React.useState(false);
    const [fetchLoadingDevice3, setFetchLoadingDevice3] = React.useState(false);
    const [fetchLoadingDevice4, setFetchLoadingDevice4] = React.useState(false);
    const [editFlg, setEditFlg] = React.useState(new Array(5).fill('').map(() => false));
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const deviceNos = ['00', '01', '02', '03', '04'];
    const deviceNames = ['本体', '子機1', '子機2', '子機3', '子機4'];
    const [isRefreshSuccess0, setIsRefreshSuccess0] = React.useState(false);
    const [isRefreshSuccess1, setIsRefreshSuccess1] = React.useState(false);
    const [isRefreshSuccess2, setIsRefreshSuccess2] = React.useState(false);
    const [isRefreshSuccess3, setIsRefreshSuccess3] = React.useState(false);
    const [isRefreshSuccess4, setIsRefreshSuccess4] = React.useState(false);

    // パラメータ設定
    const handleFetchParams = (deviceNo: string) => {
        const params = new Map<string, any>();
        const code = "PU";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", deviceNo);
        return params;
    }

    const convertIo = (input: string) => {
        let result = input;
        if (!input) return '';
        switch (input) {
            case '01':
                result = '01：(未使用)Ior自動設定';
                break;
            case '02':
                result = '02：Ior手動設定';
                break;
            case '03':
                result = '03：Io設定';
                break;
        }
        return result;
    }

    const convertPhase = (input: string) => {
        let result = input;
        if (!input) return '';
        switch (input) {
            case '01':
                result = '01：単相変圧器';
                break;
            case '03':
                result = '03：三相変圧器';
                break;
        }
        return result;
    }

    const convertAngle = (input: string) => {
        let result = input;
        if (!input) return '';
        switch (input) {
            case '000':
                result = '000：0°';
                break;
            case '030':
                result = '030：30°';
                break;
            case '060':
                result = '060：60°';
                break;
            case '090':
                result = '090：90°';
                break;
            case '120':
                result = '120：120°';
                break;
            case '150':
                result = '150：150°';
                break;
        }
        return result;
    }


    const handleFetchSuccess = (data: any, action: string, isClickedRefreshBtn: boolean) => {
        setEditFlg(changeDisabledPollingBtn(false, data.device_no, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            Io1: convertIo(data.Io1),
            Io2: convertIo(data.Io2),
            Io3: convertIo(data.Io3),
            Io4: convertIo(data.Io4),
            Io5: convertIo(data.Io5),
            Io6: convertIo(data.Io6),
            phase1: convertPhase(data.phase1),
            phase2: convertPhase(data.phase2),
            phase3: convertPhase(data.phase3),
            phase4: convertPhase(data.phase4),
            phase5: convertPhase(data.phase5),
            phase6: convertPhase(data.phase6),
            angle1: convertAngle(data.angle1),
            angle2: convertAngle(data.angle2),
            angle3: convertAngle(data.angle3),
            angle4: convertAngle(data.angle4),
            angle5: convertAngle(data.angle5),
            angle6: convertAngle(data.angle6),
            lastGetDatetime: data.last_get_datetime,
        } as SettingInformationModel;

        switch (data.device_no) {
            case deviceNos[0]:
                setSettingInformation0(resData);
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                isClickedRefreshBtn && setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                setSettingInformation1(resData);
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                isClickedRefreshBtn && setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                setSettingInformation2(resData);
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                isClickedRefreshBtn && setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                setSettingInformation3(resData);
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                isClickedRefreshBtn && setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                setSettingInformation4(resData);
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                isClickedRefreshBtn && setIsRefreshSuccess4(true);
                break;
        }

        if (action === 'edit') {
            setEditData(data);
            setOpenSelectDialog(true);
        }
    };

    const handleError = (success: boolean, data: any, error: any, deviceNo: string) => {
        onError(success, data, error, deviceNames[Number(deviceNo)]);
        setEditFlg(changeDisabledPollingBtn(false, deviceNo, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                setIsRefreshSuccess4(false);
                break;
        }
    }

    const handleRefresh = (deviceNo: string) => {
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleRefreshSuccess = (data: any, deviceNo: string) => {
        const deviceIndex = deviceNos.findIndex((element) => element === data.device_no);
        editFlg[deviceIndex] ? handleFetchSuccess(data, 'edit', true) : handleFetchSuccess(data, 'view', true);
    }

    const [updateDialogTitle, setUpdateDialogTitle] = React.useState('');
    const handleEdit = (deviceNo: string) => {
        setEditFlg(changeDisabledPollingBtn(true, deviceNo, deviceNos, editFlg));
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
        switch (deviceNo) {
            case deviceNos[0]:
                setUpdateDialogTitle('Io/Ior設定情報/本体');
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setUpdateDialogTitle('Io/Ior設定情報/子機1');
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setUpdateDialogTitle('Io/Ior設定情報/子機2');
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setUpdateDialogTitle('Io/Ior設定情報/子機3');
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setUpdateDialogTitle('Io/Ior設定情報/子機4');
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateEquipmentOk = (request: any) => {
        setOpenSelectDialog(false);
        const code = "PV";
        const requestData = {
            mainid: request.mainid,
            device_no: request.device_no,
            channelno: request.channelno,
            Io: request.Io,
            phase: request.phase,
            angle: request.angle,
        }
        onUpdate(code, mainid, requestData);
        setUpdateData(request);
        switch (request.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateEquipmentCancel = () => {
        setOpenSelectDialog(false);
    };

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        const result = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            channelno: data.channelno,
            resultCode: data.result_code,
        } as UpdateResult;
        if (result.resultCode === '0') {
            setUpdateDataToView(updateData, result.deviceNo, result.channelno);
            onUpdateSuccess(deviceNames[Number(result.deviceNo)]);
        } else {
            handleUpdateError(success, data, error);
        }
    }

    const setUpdateDataToView = (updateData: any, deviceNo: string, channelno: string | undefined) => {
        setEditFlg(changeDisabledPollingBtn(false, updateData.deviceNo, deviceNos, editFlg));
        let newData: any;
        switch (deviceNo) {
            case deviceNos[0]:
                newData = { ...settingInformation0 };
                newData[`Io${Number(channelno)}`] = convertIo(updateData.Io);
                newData[`phase${Number(channelno)}`] = convertPhase(updateData.phase);
                newData[`angle${Number(channelno)}`] = convertAngle(updateData.angle);
                newData[`lastGetDatetime`] = updateData.last_get_datetime;
                setSettingInformation0(newData);
                setFetchLoadingDevice0(false);
                setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                newData = { ...settingInformation1 };
                newData[`Io${Number(channelno)}`] = convertIo(updateData.Io);
                newData[`phase${Number(channelno)}`] = convertPhase(updateData.phase);
                newData[`angle${Number(channelno)}`] = convertAngle(updateData.angle);
                newData[`lastGetDatetime`] = updateData.last_get_datetime;
                setSettingInformation1(newData);
                setFetchLoadingDevice1(false);
                setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                newData = { ...settingInformation2 };
                newData[`Io${Number(channelno)}`] = convertIo(updateData.Io);
                newData[`phase${Number(channelno)}`] = convertPhase(updateData.phase);
                newData[`angle${Number(channelno)}`] = convertAngle(updateData.angle);
                newData[`lastGetDatetime`] = updateData.last_get_datetime;
                setSettingInformation2(newData);
                setFetchLoadingDevice2(false);
                setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                newData = { ...settingInformation3 };
                newData[`Io${Number(channelno)}`] = convertIo(updateData.Io);
                newData[`phase${Number(channelno)}`] = convertPhase(updateData.phase);
                newData[`angle${Number(channelno)}`] = convertAngle(updateData.angle);
                newData[`lastGetDatetime`] = updateData.last_get_datetime;
                setSettingInformation3(newData);
                setFetchLoadingDevice3(false);
                setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                newData = { ...settingInformation4 };
                newData[`Io${Number(channelno)}`] = convertIo(updateData.Io);
                newData[`phase${Number(channelno)}`] = convertPhase(updateData.phase);
                newData[`angle${Number(channelno)}`] = convertAngle(updateData.angle);
                newData[`lastGetDatetime`] = updateData.last_get_datetime;
                setSettingInformation4(newData);
                setFetchLoadingDevice4(false);
                setIsRefreshSuccess4(true);
                break;
        }
    }

    const handleUpdateError = (success: boolean, data: any, error: any) => {
        switch (updateData.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                setIsRefreshSuccess4(true);
                break;
        }
        onUpdateError(success, data, error, deviceNames[Number(updateData.device_no)]);
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh(deviceNos[0]);
        }
        if (isRefreshGroup === true) {
            handleRefresh(deviceNos[0]);
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, loadingRefreshAll, isRefreshGroup]);

    const handleDisabledRefreshAll = () => {
        if (!fetchLoadingDevice0 && !fetchLoadingDevice1 && !fetchLoadingDevice2 && !fetchLoadingDevice3 && !fetchLoadingDevice4) {
            setDisabledRefreshGroupBtn(false, fetchId);
        } else {
            setDisabledRefreshGroupBtn(true, fetchId);
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevice0, fetchLoadingDevice1, fetchLoadingDevice2, fetchLoadingDevice3, fetchLoadingDevice4]);

    React.useEffect(() => {
        [...editFlg].find(item => item === true) === true ? setIsEdit(true) : setIsEdit(false);
        // eslint-disable-next-line
    }, [editFlg])

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, 'view', false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);
    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess0 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('00')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice0 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('00')} disabled={isDisbleRefeshBtn || editFlg[0] || fetchLoadingDevice0 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[0] === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Io/Ior設定情報 / 本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice0 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定1</td>
                                    <td style={colStyle}>{settingInformation0?.Io1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定2</td>
                                    <td style={colStyle}>{settingInformation0?.Io2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定3</td>
                                    <td style={colStyle}>{settingInformation0?.Io3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定4</td>
                                    <td style={colStyle}>{settingInformation0?.Io4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定5</td>
                                    <td style={colStyle}>{settingInformation0?.Io5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定6</td>
                                    <td style={colStyle}>{settingInformation0?.Io6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類1</td>
                                    <td style={colStyle}>{settingInformation0?.phase1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類2</td>
                                    <td style={colStyle}>{settingInformation0?.phase2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類3</td>
                                    <td style={colStyle}>{settingInformation0?.phase3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類4</td>
                                    <td style={colStyle}>{settingInformation0?.phase4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類5</td>
                                    <td style={colStyle}>{settingInformation0?.phase5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類6</td>
                                    <td style={colStyle}>{settingInformation0?.phase6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定1</td>
                                    <td style={colStyle}>{settingInformation0?.angle1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定2</td>
                                    <td style={colStyle}>{settingInformation0?.angle2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定3</td>
                                    <td style={colStyle}>{settingInformation0?.angle3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定4</td>
                                    <td style={colStyle}>{settingInformation0?.angle4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定5</td>
                                    <td style={colStyle}>{settingInformation0?.angle5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定6</td>
                                    <td style={colStyle}>{settingInformation0?.angle6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{settingInformation0?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess1 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('01')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice1 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('01')} disabled={isDisbleRefeshBtn || editFlg[1] || fetchLoadingDevice1 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[1] === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Io/Ior設定情報 / 子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice1 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定1</td>
                                    <td style={colStyle}>{settingInformation1?.Io1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定2</td>
                                    <td style={colStyle}>{settingInformation1?.Io2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定3</td>
                                    <td style={colStyle}>{settingInformation1?.Io3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定4</td>
                                    <td style={colStyle}>{settingInformation1?.Io4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定5</td>
                                    <td style={colStyle}>{settingInformation1?.Io5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定6</td>
                                    <td style={colStyle}>{settingInformation1?.Io6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類1</td>
                                    <td style={colStyle}>{settingInformation1?.phase1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類2</td>
                                    <td style={colStyle}>{settingInformation1?.phase2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類3</td>
                                    <td style={colStyle}>{settingInformation1?.phase3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類4</td>
                                    <td style={colStyle}>{settingInformation1?.phase4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類5</td>
                                    <td style={colStyle}>{settingInformation1?.phase5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類6</td>
                                    <td style={colStyle}>{settingInformation1?.phase6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定1</td>
                                    <td style={colStyle}>{settingInformation1?.angle1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定2</td>
                                    <td style={colStyle}>{settingInformation1?.angle2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定3</td>
                                    <td style={colStyle}>{settingInformation1?.angle3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定4</td>
                                    <td style={colStyle}>{settingInformation1?.angle4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定5</td>
                                    <td style={colStyle}>{settingInformation1?.angle5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定6</td>
                                    <td style={colStyle}>{settingInformation1?.angle6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{settingInformation1?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess2 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('02')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice2 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('02')} disabled={isDisbleRefeshBtn || editFlg[2] || fetchLoadingDevice2 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[2] === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Io/Ior設定情報 / 子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice2 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定1</td>
                                    <td style={colStyle}>{settingInformation2?.Io1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定2</td>
                                    <td style={colStyle}>{settingInformation2?.Io2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定3</td>
                                    <td style={colStyle}>{settingInformation2?.Io3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定4</td>
                                    <td style={colStyle}>{settingInformation2?.Io4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定5</td>
                                    <td style={colStyle}>{settingInformation2?.Io5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定6</td>
                                    <td style={colStyle}>{settingInformation2?.Io6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類1</td>
                                    <td style={colStyle}>{settingInformation2?.phase1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類2</td>
                                    <td style={colStyle}>{settingInformation2?.phase2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類3</td>
                                    <td style={colStyle}>{settingInformation2?.phase3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類4</td>
                                    <td style={colStyle}>{settingInformation2?.phase4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類5</td>
                                    <td style={colStyle}>{settingInformation2?.phase5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類6</td>
                                    <td style={colStyle}>{settingInformation2?.phase6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定1</td>
                                    <td style={colStyle}>{settingInformation2?.angle1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定2</td>
                                    <td style={colStyle}>{settingInformation2?.angle2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定3</td>
                                    <td style={colStyle}>{settingInformation2?.angle3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定4</td>
                                    <td style={colStyle}>{settingInformation2?.angle4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定5</td>
                                    <td style={colStyle}>{settingInformation2?.angle5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定6</td>
                                    <td style={colStyle}>{settingInformation2?.angle6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{settingInformation2?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess3 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('03')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice3 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('03')} disabled={isDisbleRefeshBtn || editFlg[3] || fetchLoadingDevice3 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[3] === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Io/Ior設定情報 / 子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice3 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定1</td>
                                    <td style={colStyle}>{settingInformation3?.Io1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定2</td>
                                    <td style={colStyle}>{settingInformation3?.Io2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定3</td>
                                    <td style={colStyle}>{settingInformation3?.Io3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定4</td>
                                    <td style={colStyle}>{settingInformation3?.Io4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定5</td>
                                    <td style={colStyle}>{settingInformation3?.Io5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定6</td>
                                    <td style={colStyle}>{settingInformation3?.Io6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類1</td>
                                    <td style={colStyle}>{settingInformation3?.phase1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類2</td>
                                    <td style={colStyle}>{settingInformation3?.phase2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類3</td>
                                    <td style={colStyle}>{settingInformation3?.phase3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類4</td>
                                    <td style={colStyle}>{settingInformation3?.phase4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類5</td>
                                    <td style={colStyle}>{settingInformation3?.phase5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類6</td>
                                    <td style={colStyle}>{settingInformation3?.phase6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定1</td>
                                    <td style={colStyle}>{settingInformation3?.angle1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定2</td>
                                    <td style={colStyle}>{settingInformation3?.angle2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定3</td>
                                    <td style={colStyle}>{settingInformation3?.angle3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定4</td>
                                    <td style={colStyle}>{settingInformation3?.angle4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定5</td>
                                    <td style={colStyle}>{settingInformation3?.angle5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定6</td>
                                    <td style={colStyle}>{settingInformation3?.angle6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{settingInformation3?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess4 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('04')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice4 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('04')} disabled={isDisbleRefeshBtn || editFlg[4] || fetchLoadingDevice4 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[4] === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='Io/Ior設定情報 / 子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice4 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>Io/Ior設定6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>変圧器種類6</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定1</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定2</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定3</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定4</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定5</td>
                                    </tr>
                                    <tr>
                                        <td style={colStyleTitle}>位相補正角設定6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定1</td>
                                    <td style={colStyle}>{settingInformation4?.Io1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定2</td>
                                    <td style={colStyle}>{settingInformation4?.Io2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定3</td>
                                    <td style={colStyle}>{settingInformation4?.Io3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定4</td>
                                    <td style={colStyle}>{settingInformation4?.Io4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定5</td>
                                    <td style={colStyle}>{settingInformation4?.Io5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>Io/Ior設定6</td>
                                    <td style={colStyle}>{settingInformation4?.Io6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類1</td>
                                    <td style={colStyle}>{settingInformation4?.phase1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類2</td>
                                    <td style={colStyle}>{settingInformation4?.phase2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類3</td>
                                    <td style={colStyle}>{settingInformation4?.phase3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類4</td>
                                    <td style={colStyle}>{settingInformation4?.phase4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類5</td>
                                    <td style={colStyle}>{settingInformation4?.phase5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>変圧器種類6</td>
                                    <td style={colStyle}>{settingInformation4?.phase6}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定1</td>
                                    <td style={colStyle}>{settingInformation4?.angle1}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定2</td>
                                    <td style={colStyle}>{settingInformation4?.angle2}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定3</td>
                                    <td style={colStyle}>{settingInformation4?.angle3}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定4</td>
                                    <td style={colStyle}>{settingInformation4?.angle4}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定5</td>
                                    <td style={colStyle}>{settingInformation4?.angle5}</td>
                                </tr>
                                <tr>
                                    <td style={colStyleTitle}>位相補正角設定6</td>
                                    <td style={colStyle}>{settingInformation4?.angle6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{settingInformation4?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {
                openSelectDialog ? (
                    <SelectSettingInformationDialog
                        isOpen={true}
                        onOK={(data) => handleUpdateEquipmentOk(data)}
                        onCancel={handleUpdateEquipmentCancel}
                        data={editData}
                        dialogTitle={updateDialogTitle}
                    />
                ) : (
                    <div />
                )
            }
            <FetchHelper fetchId={fetchId + deviceNos[0]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[0]) : handleError(success, data, error, deviceNos[0])} />
            <FetchHelper fetchId={fetchId + deviceNos[1]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[1]) : handleError(success, data, error, deviceNos[1])} />
            <FetchHelper fetchId={fetchId + deviceNos[2]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[2]) : handleError(success, data, error, deviceNos[2])} />
            <FetchHelper fetchId={fetchId + deviceNos[3]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[3]) : handleError(success, data, error, deviceNos[3])} />
            <FetchHelper fetchId={fetchId + deviceNos[4]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[4]) : handleError(success, data, error, deviceNos[4])} />
            <FetchHelper fetchId="UPDATE_SETTING_INFORMATION" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(success, data, error) : handleUpdateError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onUpdate: (code: string, mainid: string, data: any) => actionCreators.fetch("UPDATE_SETTING_INFORMATION", `/polling/set?code=${code}`, 'POST', data, false, true),
    onRefresh: (params: any, fetchId: string) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `Io/Ior設定情報/${deviceName}取得`,
            body: "Io/Ior設定情報取得できませんでした。",
        }),
    onUpdateSuccess: (deviceName: string) =>
        actionCreators.showMessage({
            type: "info",
            title: `Io/Ior設定情報/${deviceName}変更`,
            body: "Io/Ior設定情報を変更しました。",
        }),
    onUpdateError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `Io/Ior設定情報/${deviceName}変更`,
            body: "Io/Ior設定情報を変更できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingInformation);

