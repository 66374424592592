import { Button, Grid, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { LightLeakageSettingModel } from "../../../models";
import { formatDateTime, padZeroToStart } from "../../../utils";

export type LightLeakageSettingEditFormProps = {
    lightLeakageSetting: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const LightLeakageSettingEdit = (props: LightLeakageSettingEditFormProps) => {
    const { lightLeakageSetting, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: LightLeakageSettingModel = {
        mainid: lightLeakageSetting.mainid,
        deviceNo: lightLeakageSetting.deviceNo,
        leakMa1: lightLeakageSetting.leakMa1,
        leakMa2: lightLeakageSetting.leakMa2,
        leakMa3: lightLeakageSetting.leakMa3,
        leakMa4: lightLeakageSetting.leakMa4,
        leakMa5: lightLeakageSetting.leakMa5,
        leakMa6: lightLeakageSetting.leakMa6,
        lastGetDatetime: lightLeakageSetting.lastGetDatetime,
    }

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<LightLeakageSettingModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: LightLeakageSettingModel) => {
        // setChanged(false);
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: LightLeakageSettingModel) => {
        let request = {
            mainid: formValue.mainid,
            device_no: formValue.deviceNo,
            leak_ma1: formValue.leakMa1,
            leak_ma2: formValue.leakMa2,
            leak_ma3: formValue.leakMa3,
            leak_ma4: formValue.leakMa4,
            leak_ma5: formValue.leakMa5,
            leak_ma6: formValue.leakMa6,
            last_get_datetime: formatDateTime(new Date()),
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleInputOnBlur = (e: any, name: any) => {
        setValue(name, padZeroToStart(e.target.value, 4));
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '90%', margin: 'auto' }}>
                    <Stack spacing={3}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} paddingTop={"10px"}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("leakMa1",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 2000,
                                                message: '0000~2000 の値を入力してください。',
                                            }
                                        })
                                    }
                                    onBlur={(e) => handleInputOnBlur(e, 'leakMa1')}
                                    fullWidth
                                    type="text"
                                    label="漏電電流1"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.leakMa1}
                                    helperText={errors?.leakMa1?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("leakMa2",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 2000,
                                                message: '0000~2000 の値を入力してください。',
                                            }
                                        })
                                    }
                                    onBlur={(e) => handleInputOnBlur(e, 'leakMa2')}
                                    fullWidth
                                    type="text"
                                    label="漏電電流2"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.leakMa2}
                                    helperText={errors?.leakMa2?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("leakMa3",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 2000,
                                                message: '0000~2000 の値を入力してください。',
                                            }
                                        })
                                    }
                                    onBlur={(e) => handleInputOnBlur(e, 'leakMa3')}
                                    fullWidth
                                    type="text"
                                    label="漏電電流3"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.leakMa3}
                                    helperText={errors?.leakMa3?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("leakMa4",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 2000,
                                                message: '0000~2000 の値を入力してください。',
                                            }
                                        })
                                    }
                                    onBlur={(e) => handleInputOnBlur(e, 'leakMa4')}
                                    fullWidth
                                    type="text"
                                    label="漏電電流4"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.leakMa4}
                                    helperText={errors?.leakMa4?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("leakMa5",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 2000,
                                                message: '0000~2000 の値を入力してください。',
                                            }
                                        })
                                    }
                                    onBlur={(e) => handleInputOnBlur(e, 'leakMa5')}
                                    fullWidth
                                    type="text"
                                    label="漏電電流5"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.leakMa5}
                                    helperText={errors?.leakMa5?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("leakMa6",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: 2000,
                                                message: '0000~2000 の値を入力してください。',
                                            }
                                        })
                                    }
                                    onBlur={(e) => handleInputOnBlur(e, 'leakMa6')}
                                    fullWidth
                                    type="text"
                                    label="漏電電流6"
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    error={!!errors?.leakMa6}
                                    helperText={errors?.leakMa6?.message}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        <Button variant="contained" type="submit">保存</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default LightLeakageSettingEdit;


