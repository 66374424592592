import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import React from 'react';
import { OptionItem, PageData } from '../../models';
import TextSelector from './TextSelector';

// -------------

// ページサイズ
export const PageSizeList = [
    { value: '10', label: '10', attribute: '', },
    { value: '50', label: '50', attribute: '', },
    { value: '100', label: '100', attribute: '', },
] as OptionItem[];

// Props
export type PaginationMobileCpnProps = {
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    limit: number;
};

// -------------
// Component
export const PaginationMobileCpn = (props: PaginationMobileCpnProps) => {

    const { pageData, onChangeSearchCondition, limit } = props;

    const onBtnPre = () => { onChangeSearchCondition('page', Number.parseInt(pageData.currPage.replace(',', '')) - 1); };
    const onBtnNext = () => { onChangeSearchCondition('page', Number.parseInt(pageData.currPage.replace(',', '')) + 1); };
    const [pageSize, setPageSize] = React.useState(limit);

    const pStyle1 = {
        padding: '0 4px',
        fontWeight: 'bold',
    }

    const handlePageSizeChange: React.ChangeEventHandler<any> = (e) => {
        setPageSize(e.target.value)
        onChangeSearchCondition('limit', e.target.value)
    };

    const disablePrevious = pageData.currPage === "1" || pageData.currPage === "0";
    const disableNext = pageData.currPage === pageData.totalPage || pageData.totalPage === "0";

    return (
            <div>
                <TableRow style={{display:"flex",margin:"auto",border:"1px solid grey",borderTop:"0px"}}>
                    <TableCell style={{width:"42%",padding:"4px 0px 4px 0px"}}>
                        <div style={{ display: 'flex'}}>
                            <Stack style={{ minWidth: '80px', margin: 'auto 10px auto 3px' }}>
                                <TextSelector
                                    name="limit"
                                    value={pageSize.toString()}
                                    label=""
                                    options={PageSizeList}
                                    onChange={handlePageSizeChange}
                                />
                            </Stack>
                            <p style={{ marginRight: '5px' }}>件表示</p>
                        </div>
                    </TableCell>
                    <TableCell style={{padding:"4px 0px 4px 0px",width:"38%"}}>
                        <div style={{ display: 'flex'}}>
                            <p id='totalRow' style={pStyle1}>{pageData.totalRow.toLocaleString()}</p>
                            <p style={{ marginRight: '10px' }}>件中</p>
                            <p id='rowFrom' style={pStyle1}>{pageData.totalRow !== "0" ? pageData.rowFrom.toLocaleString() : "0"}</p>
                            <p>-</p>
                            <p id='rowTo' style={pStyle1}>{pageData.rowTo.toLocaleString()}</p>
                            <p>件</p> 
                        </div>
                    </TableCell>
                    <TableCell style={{padding:"4px 0px 4px 0px",width:"20%"}}>
                    <div style={{ display: 'flex',marginTop:"10px" }}>
                        <IconButton style={{color:"rgb(24, 144, 255)",opacity:disablePrevious ? "0.3" : "1"}}  id='btnPrePage' onClick={onBtnPre} disabled={disablePrevious}><NavigateBeforeIcon fontSize='small' /></IconButton>
                        <IconButton style={{color:"rgb(24, 144, 255)",opacity:disableNext ? "0.3" : "1"}} id='btnNextPage' onClick={onBtnNext} disabled={disableNext}><NavigateNextIcon fontSize='small' /></IconButton>
                    </div>
                    </TableCell>
                </TableRow>
            </div>
    );
};

export default PaginationMobileCpn;
