import CachedIcon from '@mui/icons-material/Cached';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { PhaseAngleInformationModel } from '../../../models';

import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 9;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
}

type PhaseAngleInformationProps = {
    defaultData: PhaseAngleInformationModel[] | undefined;
};

export type PurePhaseAngleInformationProps = PhaseAngleInformationProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

const fetchId = "PHASE_ANGLE_INFORMATION_FETCH";
export const PhaseAngleInformation = (props: PurePhaseAngleInformationProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isRefreshGroup, isDisbleRefeshBtn } = props;
    const { onError, onRefresh, isRefreshAllCompleted, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [phaseAngleInformation, setPhaseAngleInformation] = React.useState<PhaseAngleInformationModel>(fetchState.data);
    const [refreshFlg, setRefreshFlg] = React.useState(false);
    const [isRefreshSuccess, setIsRefreshSuccess] = React.useState(false);

    // パラメータ設定
    const handleFetchParams = () => {
        const params = new Map<string, any>();
        const code = "PW";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", '00');
        return params;
    }

    // デマンド現在値取得成功時
    const handleFetchSuccess = (data: any, isClickedRefreshBtn: boolean) => {
        setRefreshFlg(false);

        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            Io1: data.Io1,
            pa1: data.pa1,
            Io2: data.Io2,
            pa2: data.pa2,
            Io3: data.Io3,
            pa3: data.pa3,
            Io4: data.Io4,
            pa4: data.pa4,
            Io5: data.Io5,
            pa5: data.pa5,
            Io6: data.Io6,
            pa6: data.pa6,
            lastGetDatetime: data.last_get_datetime,
        } as PhaseAngleInformationModel;

        setPhaseAngleInformation(resData);
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        isClickedRefreshBtn && setIsRefreshSuccess(true);
    };

    const handleError = (success: boolean, data: any, error: any) => {
        setRefreshFlg(false);
        onError(success, data, error);
        if (isRefreshAllBegin > 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        setIsRefreshSuccess(false);
    }

    const handleRefresh = () => {
        setRefreshFlg(true);
        setIsRefreshSuccess(false);
        const params = handleFetchParams();
        onRefresh(params);
    };

    const handleRefreshSuccess = (data: any) => {
        handleFetchSuccess(data, true);
        if (isRefreshAllBegin !== 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh();
        }
        if (isRefreshGroup) {
            handleRefresh();
            setIsRefreshSuccess(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setIsRefreshSuccess(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setRefreshFlg(true) : setRefreshFlg(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, isRefreshGroup]);

    React.useEffect(() => {
        setDisabledRefreshGroupBtn(refreshFlg, fetchId);
        // eslint-disable-next-line
    }, [refreshFlg]);

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);
    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={handleRefresh} disabled={isDisbleRefeshBtn || refreshFlg}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || refreshFlg ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='PhaseAngle情報 / 親機'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {(fetchState.loading || loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch1 Io測定値</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch1 位相角</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch2 Io測定値</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch2 位相角</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch3 Io測定値</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch3 位相角</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch4 Io測定値</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch4 位相角</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch5 Io測定値</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch5 位相角</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch6 Io測定値</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>Ch6 位相角</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch1 Io測定値</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.Io1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch1 位相角</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.pa1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch2 Io測定値</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.Io2}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch2 位相角</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.pa2}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch3 Io測定値</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.Io3}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch3 位相角</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.pa3}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch4 Io測定値</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.Io4}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch4 位相角</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.pa4}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch5 Io測定値</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.Io5}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch5 位相角</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.pa5}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch6 Io測定値</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.Io6}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>Ch6 位相角</td>
                                    <td style={pollingColStyle}>{phaseAngleInformation?.pa6}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{phaseAngleInformation?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleRefreshSuccess(data) : handleError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onRefresh: (params: any) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: `PhaseAngle情報取得`,
            body: "PhaseAngle情報取得できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PhaseAngleInformation);

