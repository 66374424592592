import { Button, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import BusinessPartnerGrid from "./BusinessPartnerGrid";

export type BusinessPartnerDialogProps = {
    onOK: (data: any) => void;
    onCancel: () => void;
    data: any;
};

export const BusinessPartnerDialog = (props:BusinessPartnerDialogProps) => {
    const {onOK,onCancel,data} = props;
    const gridRef = useRef() as any;
    const [listData,setListData] = useState<any>([]);
    const [colState, setColState] = useState<any>();
    const [disableEdit,setDisableEdit] = useState<boolean>(true);
    const [keyword, setKeyword] = useState("");
    const [selectData,setSelectData] = useState<any>(null);
    
    const handleGetData = (data: any) => {
        data.forEach((element: any) => {
            element.isSelect = false;
        });
        return data;
    };

    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        const lastSelect = listData.find((item:any) => item.isSelect == true);
        if(lastSelect){
            if(lastSelect.id != data.id)
            {
                lastSelect.isSelect = false;
            }else{
                return
            }
        }
        const currentSelect = listData.find((item:any) => item.id == data.id);
        if(currentSelect){
            currentSelect.isSelect = true;
        } 
        setSelectData(currentSelect);
        setDisableEdit(false);                
    }

    useEffect(() => {
        if (gridRef && gridRef.current) {
            gridRef.current.setRowData(handleGetData(data));
        }
        setListData([...handleGetData(data)]);
    },[data])

    const handleChangeKeyword = (value:string) => {
        setKeyword(value);
    }

    const handleData = () => {
        onOK(selectData);
    }

    return (
        <>
            <div>
                <Dialog open={true} maxWidth={"xl"} fullWidth>
                    <DialogContent sx={{ pb: 1.5,position:"relative" }}>
                        <Typography variant="h6" mb={3}>取引先選択</Typography>
                        <div style={{position:"absolute",right:24,top:15,}}>
                            <Grid container
                                justifyContent="flex-start"
                                alignItems={"end"}
                                spacing={1}
                                direction="row"
                                style={{ alignItems: 'center' }}
                            >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => handleChangeKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                        <BusinessPartnerGrid 
                            ref={gridRef}
                            colState={colState}
                            onSelect={handleSelect}
                            keyword={keyword}
                        />
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ width:"100%" ,mt: 2}}>
                            <Button variant="contained" disabled={disableEdit} onClick={handleData}>選択</Button>
                            <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

