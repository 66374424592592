import CachedIcon from '@mui/icons-material/Cached';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { MaximumEarthLeakageModel } from '../../../models';

import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import { showUnit } from '../../../utils';
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 12;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
}

type MaximumEarthLeakageProps = {
    defaultData: MaximumEarthLeakageModel[] | undefined;
};

export type PureMaximumEarthLeakageProps = MaximumEarthLeakageProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

const fetchId = "MAXIMUM_EARTH_LEAKAGE_FETCH";
export const MaximumEarthLeakage = (props: PureMaximumEarthLeakageProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isRefreshGroup, isDisbleRefeshBtn } = props;
    const { onError, onRefresh, isRefreshAllCompleted, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [maximumEarthLeakage, setMaximumEarthLeakage] = React.useState<MaximumEarthLeakageModel>(fetchState.data);
    const [refreshFlg, setRefreshFlg] = React.useState(false);
    const [isRefreshSuccess, setIsRefreshSuccess] = React.useState(false);

    // パラメータ設定
    const handleFetchParams = () => {
        const params = new Map<string, any>();
        const code = "QE";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", '00');
        return params;
    }

    const convertFormatTimeToMMddhhmm = (value: string) => {
        if (value === undefined || value === null) return '';
        if (value.length === 8) {
            const MM = value.substring(0, 2) + '月';
            const dd = value.substring(2, 4) + '日';
            const hh = " " + value.substring(4, 6) + ':';
            const mm = value.substring(6, 8);
            value = MM + dd + hh + mm
        }
        return value;
    }

    // デマンド現在値取得成功時
    const handleFetchSuccess = (data: any, isClickedRefreshBtn: boolean) => {
        setRefreshFlg(false);
        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            maxOccurrenceTime1: convertFormatTimeToMMddhhmm(data.max_occurrence_time1),
            maxLeakMa1: data.max_leak_ma1,
            maxOccurrenceTime2: convertFormatTimeToMMddhhmm(data.max_occurrence_time2),
            maxLeakMa2: data.max_leak_ma2,
            maxOccurrenceTime3: convertFormatTimeToMMddhhmm(data.max_occurrence_time3),
            maxLeakMa3: data.max_leak_ma3,
            maxOccurrenceTime4: convertFormatTimeToMMddhhmm(data.max_occurrence_time4),
            maxLeakMa4: data.max_leak_ma4,
            maxOccurrenceTime5: convertFormatTimeToMMddhhmm(data.max_occurrence_time5),
            maxLeakMa5: data.max_leak_ma5,
            maxOccurrenceTime6: convertFormatTimeToMMddhhmm(data.max_occurrence_time6),
            maxLeakMa6: data.max_leak_ma6,
            lastGetDatetime: data.last_get_datetime,
        } as MaximumEarthLeakageModel;

        setMaximumEarthLeakage(resData);
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        isClickedRefreshBtn && setIsRefreshSuccess(true);
    };

    const handleError = (success: boolean, data: any, error: any) => {
        setRefreshFlg(false);
        onError(success, data, error);
        if (isRefreshAllBegin > 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        setIsRefreshSuccess(false);
    }

    const handleRefresh = () => {
        setRefreshFlg(true);
        setIsRefreshSuccess(false);
        const params = handleFetchParams();
        onRefresh(params);
    };

    const handleRefreshSuccess = (data: any) => {
        handleFetchSuccess(data, true);
        if (isRefreshAllBegin !== 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh();
        }
        if (isRefreshGroup) {
            handleRefresh();
            setIsRefreshSuccess(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setIsRefreshSuccess(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setRefreshFlg(true) : setRefreshFlg(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, isRefreshGroup]);

    React.useEffect(() => {
        setDisabledRefreshGroupBtn(refreshFlg, fetchId);
        // eslint-disable-next-line
    }, [refreshFlg]);

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={handleRefresh} disabled={isDisbleRefeshBtn || refreshFlg}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || refreshFlg ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='最大漏電 / 親機'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {(fetchState.loading || loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大発生時刻1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大発生時刻2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大発生時刻3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大発生時刻4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大発生時刻5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大発生時刻6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>最大漏電電流6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大発生時刻1</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxOccurrenceTime1}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大漏電電流1</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxLeakMa1}{showUnit(maximumEarthLeakage?.maxLeakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大発生時刻2</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxOccurrenceTime2}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大漏電電流2</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxLeakMa2}{showUnit(maximumEarthLeakage?.maxLeakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大発生時刻3</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxOccurrenceTime3}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大漏電電流3</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxLeakMa3}{showUnit(maximumEarthLeakage?.maxLeakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大発生時刻4</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxOccurrenceTime4}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大漏電電流4</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxLeakMa4}{showUnit(maximumEarthLeakage?.maxLeakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大発生時刻5</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxOccurrenceTime5}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大漏電電流5</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxLeakMa5}{showUnit(maximumEarthLeakage?.maxLeakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大発生時刻6</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxOccurrenceTime6}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>最大漏電電流6</td>
                                    <td style={pollingColStyle}>{maximumEarthLeakage?.maxLeakMa6}{showUnit(maximumEarthLeakage?.maxLeakMa6, "mA")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{maximumEarthLeakage?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleRefreshSuccess(data) : handleError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onRefresh: (params: any) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: `最大漏電取得`,
            body: "最大漏電取得できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaximumEarthLeakage);


