import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FormControlLabel } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { customerLabelName } from '../../../statics';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -------------
// Props
export type ObservationPointAndDevicesGridProps = {
    colState: any[];
    keyword: string;
    checkAll: boolean;
    onSelect: (data: any) => void;
    onSelectManyRows: (list_mainid:any,value:boolean) => void;
    onSendLeftPosition: (left_position:any) => void;
};

// -------------
// Component
export const ObservationPointAndDevicesGrid = forwardRef((props: ObservationPointAndDevicesGridProps, ref) => {
    const { checkAll, colState, keyword, onSelect, onSelectManyRows, onSendLeftPosition} = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any>();
    const [isFirst,setIsFirst] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        setSelectRowData(list_mainid: any) {
            if (rowData) {
                let list = rowData.filter((item: any) => item.mainid === list_mainid)
                list[0].isSelect = !list[0].isSelect;
            }
        },

        setSelectAllRowData(list_mainid:any,value:boolean){
            if(rowData){
                const filteredList = rowData.filter((itemA:any) => 
                    list_mainid.some((itemB:any) => 
                        itemA.mainid === itemB
                    )
                );                
                for(const item of filteredList){
                    item.isSelect = value;
                }
            }
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        },

        getRowsInCurrent(){
            return gridRef.current?.api?.getModel().rowsToDisplay.filter((item:any) => item.data.isDisableSelect == false);
        }
    }));

    useEffect(() => {
        const all_rows = gridRef.current?.api?.getModel().rowsToDisplay.filter((item:any) => item.data.isDisableSelect == false);
        if(checkAll == true){
            setIsFirst(true);
            const list_mainid = all_rows.map((item:any) => {return item.data.mainid});
            onSelectManyRows(list_mainid,true)
            gridRef.current.api.redrawRows();
        }else{
            if(isFirst == true){
                const list_mainid = all_rows.map((item:any) => {return item.data.mainid});
                onSelectManyRows(list_mainid,false)
                gridRef.current.api.redrawRows();
            }
        }
    },[checkAll])

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const handleSelectRow = (props: any) => {
        onSelect(props.data);
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
    };

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword);
        const { isSelect, isDisableSelect } = props.data;
        return (
            <FormControlLabel
                control={<Checkbox disabled={isDisableSelect} icon={icon} checkedIcon={checkedIcon} checked={isSelect} onChange={() => handleSelectRow(props)} />}
                label={""}
                labelPlacement="end"
            />
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const setAddress = (props: any) => {
        return props.data.address1 + props.data.address2;
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: ``,
            field: "isSelect",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            cellRenderer: CellCheckRenderer,
            width: 80,
            resizable:false,
            headerComponentParams: {
                template:
                `<div class="ag-cell-label-container" role="presentation">
                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
                    <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button" aria-hidden="true"></span>
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <text style="margin-left: 25px;"></text>
                        <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
                        <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
                        <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
                    </div>
                </div>`,
            }
        },
        {
            headerName: `グループ名`,
            field: "group_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 250,
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 200,
        },
        {
            headerName: `${customerLabelName}-住所`,
            field: "address",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            valueGetter:setAddress,
            width: 200,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 150,
        },
        {
            headerName: "管理組織名",
            field: "manage_customer_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 150,
        },
        {
            headerName: "装置ID",
            field: "mainid",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 150,
        },
        {
            headerName: "備考",
            field: "ob_relation_note",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 150,
        },
    ];

    

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
              };
        }
        return {
            backgroundColor: 'white',
          };
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 250px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        getRowStyle={getRowStyle}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        onBodyScroll={(event:any) => {
                            onSendLeftPosition(event.left);
                        }}
                    />
                </div>
            </div>
        </>
    );
});

export default ObservationPointAndDevicesGrid;