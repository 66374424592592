import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

// 検索フォーム
export interface ItemForm {
    "id": string;
    "name": string;
    "managerName": string;
    "note": string;
}

export type CustomersGroupEditFormProps = {
    customersGroup: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const CustomersGroupEdit = (props: CustomersGroupEditFormProps) => {
    const { customersGroup, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: ItemForm = {
        id: customersGroup.group_id,
        name: customersGroup.name,
        managerName: customersGroup.manager_name,
        note: customersGroup.note,
    }
    const { register, handleSubmit,formState: { errors }} = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: ItemForm) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ItemForm) => {
        let request = {
            id: formValue.id,
            name: formValue.name,
            manager_name: formValue.managerName,
            note: formValue.note
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div style={{ height: '35vh', width: '100%', margin: 'auto', overflow: 'auto' }}>
                    <Stack spacing={3} mt={5}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    {...register("name",{
                                        required: '必須項目です。入力してください。',
                                        }
                                    )}
                                    fullWidth
                                    type="text"
                                    label="組織グループ名"
                                    size="small"
                                    error={!!errors?.name}
                                    helperText={errors?.name?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    {...register("managerName")}
                                    fullWidth
                                    type="text"
                                    label="責任者"
                                    size="small"
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    {...register("note")}
                                    fullWidth
                                    type="text"
                                    label="備考"
                                    size="small"
                                    error={!!errors?.note}
                                    helperText={errors?.note?.message}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                </div>
                <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                    <Button variant="contained" type="submit">保存</Button>
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                </Stack>
            </form >
        </>
    );
};
export default CustomersGroupEdit;


