import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EditNoteIcon from '@mui/icons-material/EditNote';
import GridOnIcon from '@mui/icons-material/GridOn';
import { Checkbox, FormControlLabel, Grid, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -------------
// Props
export type WeathersGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    onSelect: (data: any) => void;
    openSearch: boolean;
    keyword: string;
    onShowDetails: (value: string, redirectId: string) => void;
    isDisableView:boolean;
    isDisableSelect:boolean;
};

// -------------
// Component
export const WeathersGrid = forwardRef((props: WeathersGridProps, ref) => {
    const { colState, pageSize, pageData, isDisableView, isDisableSelect, openSearch, keyword, onChangeSearchCondition, onSelect, onShowDetails} = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {
    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const handleCheckedDelete = (props: any) => {
        onSelect(props.data);
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
    };

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword);
        const { ob_point_name, selected } = props.data;
        let isSelected = selected
        if (props.data.isSelect) {
            isSelected = true;
        }
        if(isDisableSelect)
            return ob_point_name;
        return (
            <FormControlLabel
                control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} onChange={() => handleCheckedDelete(props)} />}
                label={ob_point_name}
                labelPlacement="end"
            />
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword)
        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data, props.colDef.field);
        };

        return (
            <>
                {props.colDef.field === "data" &&
                    <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"} >
                        <IconButton aria-label="edit" style={{ color: 'white',opacity:isDisableView?"0.2":"1", backgroundColor: '#1890ff', borderRadius: '3px', margin: '4px' }} disabled={isDisableView} onClick={handleEdit} >
                            <GridOnIcon fontSize="small" />
                        </IconButton>
                    </Grid>}
                    
            </>
        );
    };

    const cellEditFieldRenderer = (props:any) => {
        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data, props.colDef.field);
        };
        return (
            <>
                {props.colDef.field === "edit_field" &&
                    <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"} >
                        <IconButton aria-label="edit" style={{ color: 'white',opacity:isDisableView?"0.2":"1", backgroundColor: '#1890ff', borderRadius: '3px', margin: '4px' }} disabled={isDisableView} onClick={handleEdit} >
                            <EditNoteIcon fontSize="small" />
                        </IconButton>
                    </Grid>}
                    
            </>
        );
    }
    
    const cellRenderCorrect = (props:any) => {
        let correct_flag = props.data.collect_flag;
        let result;
        if(correct_flag == 0){
            result = "なし"
        }
        if(correct_flag == 1){
            result = "あり"
        }
        return result;
    }

    // 列の個別定義
    const columnDefs = [
        {
            headerName: `観測地点名`,
            field: "ob_point_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            cellRenderer: CellCheckRenderer,
            width: 250,
        },
        {
            headerName: "住所",
            field: "address_in",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 200,
        },
        {
            headerName: "緯度",
            field: "latitude",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 150,
        },
        {
            headerName: "経度",
            field: "longitude",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 150,
        },
        {
            headerName: "収集有無",
            field: "collect_flag",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 90,
            cellRenderer: cellRenderCorrect,
        },
        {
            headerName: "装置数",
            field: "gateway_count",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: 150,
        },
        {
            headerName: "装置設定",
            field: "edit_field",
            rowDrag: false,
            cellStyle: { textAlign: 'center' },
            width:110,
            cellRenderer: cellEditFieldRenderer,
            sortable:false,
        },
        {
            headerName: "気象データ",
            field: "data",
            rowDrag: false,
            cellStyle: { textAlign: 'center' },
            width:110,
            cellRenderer: cellMessageRenderer,
            sortable:false,
        },
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
              };
        }
        return {
            backgroundColor: 'white',
          };
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 275px)' : 'calc(100vh - 180px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        getRowStyle={getRowStyle}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize}/>
            </div>
        </>
    );
});

export default WeathersGrid;