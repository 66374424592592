import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { connect } from "react-redux";
import { UpdateResult } from '../../../models';
import { ApplicationState, actionCreators } from "../../../store";
import { formatDateTime } from '../../../utils';
import FetchHelper from '../../FetchHelper';
import SelectDemandChangeStartEndDialog from '../../dialogs/SelectDemandChangeStartEndDialog';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles } from '../../styles';

const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setDemandChangeStartEnd: (isEdit: boolean) => { },
    isDisbleUpdateBtn: true,
    demandStartEndInfo: [] as any
}
export type DemandChangeStartEndProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

export const DemandChangeStartEnd = (props: DemandChangeStartEndProps) => {
    const { isDisbleUpdateBtn, demandStartEndInfo, mainid, isRefreshAllBegin, isEdit, onUpdate, onUpdateSuccess, onUpdateError, setIsEdit, setDemandChangeStartEnd, } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [editFlg, setEditFlg] = React.useState(false);
    const [updateLoading, setUpdateLoading] = React.useState(false);
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const [showDoneIcon,setShowDoneIcon] = React.useState(false);
    const [showTimeExecute,setShowTimeExecute] =  React.useState<any>();

    React.useEffect(() => {
        if(demandStartEndInfo){
            setShowTimeExecute(demandStartEndInfo.last_get_datetime);
        }
    },[demandStartEndInfo]);

    const handleEdit = () => {
        setOpenSelectDialog(true);
    };

    const handleUpdateOk = (request: any) => {
        setOpenSelectDialog(false);
        setUpdateLoading(true);
        const code = "PF";
        onUpdate(code, mainid, request);
    };

    const handleUpdateCancel = () => {
        setOpenSelectDialog(false);
        setEditFlg(false);
    };

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        const result = {
            mainid: data.mainid,
            resultCode: data.result_code,
        } as UpdateResult;
        if (result.resultCode === '0') {
            onUpdateSuccess();
            setUpdateLoading(false);
            setEditFlg(false);
            setShowDoneIcon(true);
            setShowTimeExecute(formatDateTime(new Date()))
        } else {
            handleUpdateError(success, data, error);
        }
    }

    const handleUpdateError = (success: boolean, data: any, error: any) => {
        onUpdateError(success, data, error);
        setUpdateLoading(false);
        setEditFlg(false);
        setShowDoneIcon(false);
    }

    const refreshAll = () => {
        isRefreshAllBegin > 0 ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin]);

    React.useEffect(() => {
        setIsEdit(editFlg);
        // eslint-disable-next-line
    }, [editFlg]);

    React.useEffect(() => {
        setDemandChangeStartEnd(updateLoading);
    })

    return (
        <>
            <Card style={{ marginBottom:"12px", backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={showDoneIcon ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit()} disabled={isDisbleUpdateBtn || isEdit || updateLoading || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || updateLoading || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            
                        </div>
                    }
                    title='デマンド開始終了変更'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0',height:'50px' }}>
                    {updateLoading ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '10%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終実行日時：{showTimeExecute}</Typography>
                    )}
                </CardContent>
            </Card >
            {
                openSelectDialog ? (
                    <SelectDemandChangeStartEndDialog
                        isOpen={true}
                        onOK={(data) => handleUpdateOk(data)}
                        onCancel={handleUpdateCancel}
                        mainId={mainid}
                    />
                ) : (
                    <div />
                )
            }
            <FetchHelper fetchId="UPDATE_DEMAND_CHANGE_START_END" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(success, data, error) : handleUpdateError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
});
const mapDispatchToProps = {
    onUpdate: (code: string, mainid: string, data: any) => actionCreators.fetch("UPDATE_DEMAND_CHANGE_START_END", `/polling/set?code=${code}`, 'POST', data, false, true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "デマンド開始終了変更",
            body: "デマンド開始終了を更新しました。",
        }),
    onUpdateError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド開始終了変更",
            body: "デマンド開始終了を変更できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemandChangeStartEnd);



