import { Button, Grid, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Cpn4GLteSettingModel } from "../../../models";
import { formatDateTime } from "../../../utils";

export type Cpn4GLteSettingEditFormProps = {
    data4GLteSetting: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const Cpn4GLteSettingEdit = (props: Cpn4GLteSettingEditFormProps) => {
    const { data4GLteSetting, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: Cpn4GLteSettingModel = {
        mainid: data4GLteSetting.mainid,
        deviceNo: data4GLteSetting.deviceNo,
        serverIpAddr: data4GLteSetting.serverIpAddr,
        serverPort: data4GLteSetting.serverPort,
        modemIpAddr: data4GLteSetting.modemIpAddr,
        modemPort: data4GLteSetting.modemPort,
        serverTime: data4GLteSetting.serverTime,
        ackTime: data4GLteSetting.ackTime,
        lastGetDatetime: data4GLteSetting.lastGetDatetime,
    }

    const { register, handleSubmit, formState: { errors } } = useForm<Cpn4GLteSettingModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: Cpn4GLteSettingModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: Cpn4GLteSettingModel) => {

        let request = {
            mainid: formValue.mainid,
            device_no: formValue.deviceNo,
            server_ip_addr: formValue.serverIpAddr,
            server_port: formValue.serverPort,
            modem_ip_addr: formValue.modemIpAddr,
            modem_port: formValue.modemPort,
            server_time: formValue.serverTime,
            ack_time: formValue.ackTime,
            last_get_datetime: formatDateTime(new Date()),
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '90%', margin: 'auto' }}>
                    <Stack spacing={3}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Server IPアドレス"
                                    size="small"
                                    {...register("serverIpAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    inputProps={{ maxLength: 15 }}
                                    error={!!errors?.serverIpAddr}
                                    helperText={errors?.serverIpAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Server ポート"
                                    size="small"
                                    {...register("serverPort",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: '999999',
                                                message: '000001~999999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 6 }}
                                    error={!!errors?.serverPort}
                                    helperText={errors?.serverPort?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Modem IPアドレス"
                                    size="small"
                                    {...register("modemIpAddr",
                                        {
                                            required: '必須項目です。入力してください。',
                                        })
                                    }
                                    inputProps={{ maxLength: 15 }}
                                    error={!!errors?.modemIpAddr}
                                    helperText={errors?.modemIpAddr?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Modem ポート"
                                    size="small"
                                    {...register("modemPort",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: '999999',
                                                message: '000001~999999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 6 }}
                                    error={!!errors?.modemPort}
                                    helperText={errors?.modemPort?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="死活監視間隔"
                                    size="small"
                                    {...register("serverTime",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: '999',
                                                message: '000001~999999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.serverTime}
                                    helperText={errors?.serverTime?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="再送回数"
                                    size="small"
                                    {...register("ackTime",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[0-9\b]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            max: {
                                                value: '999',
                                                message: '000001~999999 の値を入力してください。',
                                            }
                                        })
                                    }
                                    inputProps={{ maxLength: 3 }}
                                    error={!!errors?.ackTime}
                                    helperText={errors?.ackTime?.message}
                                />
                            </Grid>
                        </Stack>
                    </Stack>

                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        <Button variant="contained" type="submit">保存</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default Cpn4GLteSettingEdit;


