import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { LocationNameModel, UpdateResult } from '../../../models';

import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import SelectLocationNameDialog from '../../dialogs/SelectLocationNameDialog';
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 14;

const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
    isDisbleUpdateBtn: true,
}
const fetchId = "LOCATION_NAME_FETCH";

type LocationNameProps = {
    defaultData: LocationNameModel[] | undefined;
};

export type PureLocationNameProps = LocationNameProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

export const LocationName = (props: PureLocationNameProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isEdit, isRefreshGroup, isDisbleRefeshBtn, isDisbleUpdateBtn } = props;
    const { onError, onUpdate, onRefresh, onUpdateSuccess, onUpdateError, isRefreshAllCompleted, setIsEdit, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [updateData, setUpdateData] = React.useState<any>();
    const [locationNameData, setLocationNameData] = React.useState<LocationNameModel>(props.fetchState.data);
    const [editFlg, setEditFlg] = React.useState(false);
    const [updateLoading, setUpdateLoading] = React.useState(false);
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const [isRefreshSuccess, setIsRefreshSuccess] = React.useState(false);

    // パラメータ設定
    const handleFetchParams = () => {
        const params = new Map<string, any>();
        const code = "QH";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", '00');
        return params;
    }

    const handleFetchSuccess = (data: any, action: string, isClickedRefreshBtn: boolean) => {
        setUpdateLoading(false);
        setEditFlg(false);
        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            locationName: data.location_name,
            lastGetDatetime: data.last_get_datetime,
        } as LocationNameModel;
        setLocationNameData(resData);
        action === "edit" && setOpenSelectDialog(true);
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        isClickedRefreshBtn && setIsRefreshSuccess(true);
    };

    const handleError = (success: boolean, data: any, error: any) => {
        setEditFlg(false);
        onError(success, data, error);
        if (isRefreshAllBegin !== 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        setIsRefreshSuccess(false);
    }

    const handleRefresh = () => {
        const params = handleFetchParams();
        setIsRefreshSuccess(false);
        onRefresh(params);
    };

    const handleRefreshSuccess = (data: any) => {
        editFlg ? handleFetchSuccess(data, 'edit', true) : handleFetchSuccess(data, 'view', true);
        if (isRefreshAllBegin !== 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
    }

    const handleEdit = () => {
        setEditFlg(true);
        setIsRefreshSuccess(false);
        const params = handleFetchParams();
        onRefresh(params);
    };

    const handleUpdateOk = (request: any) => {
        setOpenSelectDialog(false);
        setUpdateLoading(true);
        const code = "QI";
        const requestData = {
            mainid: request.mainid,
            device_no: request.device_no,
            location_name: request.location_name,
        }
        onUpdate(code, mainid, requestData);
        setUpdateData(request);
        setIsRefreshSuccess(false);
    };

    const handleUpdateCancel = () => {
        setOpenSelectDialog(false);
    };

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        const result = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            resultCode: data.result_code,
        } as UpdateResult;
        if (result.resultCode === '0') {
            onUpdateSuccess();
            handleFetchSuccess(updateData, 'view', true);
        } else {
            handleUpdateError(success, data, error);
        }
    }

    const handleUpdateError = (success: boolean, data: any, error: any) => {
        onUpdateError(success, data, error);
        setUpdateLoading(false);
        setEditFlg(false);
        setIsRefreshSuccess(true);
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh();
        }
        if (isRefreshGroup) {
            handleRefresh();
            setIsRefreshSuccess(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setIsRefreshSuccess(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, isRefreshGroup]);

    React.useEffect(() => {
        setIsEdit(editFlg);
        // eslint-disable-next-line
    }, [editFlg]);

    React.useEffect(() => {
        setDisabledRefreshGroupBtn(fetchState.loading || updateLoading, fetchId);
        // eslint-disable-next-line
    }, [updateLoading, fetchState.loading]);

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, 'view', false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit()} disabled={isDisbleUpdateBtn || isEdit || fetchState.loading || updateLoading || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || fetchState.loading || updateLoading || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={handleRefresh} disabled={isDisbleRefeshBtn || editFlg || fetchState.loading || updateLoading || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg || fetchState.loading || updateLoading || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='LocationName / 親機'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {(fetchState.loading || loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] || updateLoading) ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>LocationName</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '10%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>LocationName</td>
                                    <td style={pollingColStyle}>{locationNameData?.locationName}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{locationNameData?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >
            {
                openSelectDialog ? (
                    <SelectLocationNameDialog
                        isOpen={true}
                        onOK={(data) => handleUpdateOk(data)}
                        onCancel={handleUpdateCancel}
                        data={{ ...locationNameData }}
                    />
                ) : (
                    <div />
                )
            }
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleRefreshSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId="UPDATE_LOCATION_NAME" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(success, data, error) : handleUpdateError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onUpdate: (code: string, mainid: string, data: any) => actionCreators.fetch("UPDATE_LOCATION_NAME", `/polling/set?code=${code}`, 'POST', data, false, true),
    onRefresh: (params: any) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: `LocationName / 親機取得`,
            body: "LocationName取得できませんでした。",
        }),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: `LocationName / 親機変更`,
            body: "LocationNameを変更しました。",
        }),
    onUpdateError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: `LocationName / 親機変更`,
            body: "LocationNameを変更できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationName);