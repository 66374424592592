import { Chart } from "chart.js";

export const CreateDailyChartLine = (data) => {
    if (data.labels.length === 0 || data.dataChart.length === 0) {
        return;
    };
    new Chart(
        document.getElementById("calendarChart"),
        {
            type: 'line',
            data: {
                labels: data.labels,
                datasets: [
                    {
                        label: '使用電力',
                        data: data.dataChart.dataChart1,
                        borderColor: '#1890ff',
                        backgroundColor: '#1890ff',
                        pointHoverRadius: 10,
                        pointHoverBorderWidth: 5,
                        pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
                    },
                    {
                        type: 'line',
                        label: '契約',
                        data: data.dataChart.dataChart2,
                        // fill: false,
                        borderColor: '#000000',
                        backgroundColor: '#000000',
                        tension: 0.1,
                        pointRadius: 0,
                        pointStyle: 'rectRot',
                        pointHoverRadius: 10,
                        pointHoverBorderWidth: 5,
                        pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
                    },
                    {
                        type: 'line',
                        label: '警報',
                        data: data.dataChart.dataChart3,
                        borderColor: '#ff0000',
                        backgroundColor: '#ff0000',
                        pointHoverRadius: 10,
                        pointHoverBorderWidth: 5,
                        pointRadius: 0,
                        pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
                        pointStyle: 'rect',
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        displayColors: true,
                        backgroundColor: 'rgba(242, 242, 242, 0.5)',
                        bodyColor: '#1a1a1a',
                        borderWidth: 1,
                        borderColor: 'rgba(143, 190, 1, 0.7)',
                        padding: 15,
                        titleFont: {
                            size: 16,
                            weight: 'bold',
                        },
                        bodyFont: {
                            size: 14,
                        },
                        titleColor: '#1a1a1a',
                        titleFontStyle: 'bold',
                        usePointStyle: 'true',
                        callbacks: {
                            label: function (context) {
                                //Return value for label
                                if (context.datasetIndex === 0) {
                                    if (data.dataChart.pulse_type === "1") {
                                        return context.dataset.label + "            " + context.parsed.y + " kW";
                                    }
                                    else return "発電量　            " + context.parsed.y + " kW";
                                }
                                return context.dataset.label + "                   " + context.parsed.y + " kW";
                            },
                        },
                    },
                },
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    x: {
                        display: true,
                    },
                    y: {
                        display: true,
                        ticks: {
                            stepSize: '10',
                        },
                        title: {
                            display: true,
                            text: '(kW)',
                            font: {
                                size: 16,
                            }
                        },
                        grace: '10%',
                        beginAtZero: true,
                    }
                }
            },
        });
}