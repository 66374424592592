import { Autocomplete, Button, CircularProgress, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { OptionItem } from "../../../models";
import { customerLabelName } from "../../../statics";
import palette from "../../../theme/palette";
import FetchHelper from "../../FetchHelper";

const GET_DEVICE_JSP_BY_DEVICE_NAME = "GET_DEVICE_JSP_BY_DEVICE_NAME"

export type DeviceJSPEditProps = {
    onOK: (data: any) => void;
    onCancel: () => void;
    onError: (success:any,data:any,error:any) => void;
    deviceName:string;
    customerList:any;
}

// フォーム定義
interface DeviceJSPModel{
    device_name:string,
    customer_id:string,
    jsp_url:string,
}
interface DeviceJSPEditForm {
    customer: OptionItem | null,
    device_name:string,
    jsp_url:string,
    group_name:string,
}

// 初期値設定
const initialValue: DeviceJSPEditForm = {
    customer: null,
    device_name:"",
    jsp_url:"",
    group_name:"",
}

export const DeviceJSPEdit = (props: DeviceJSPEditProps) => {
    const { deviceName,customerList,onOK,onCancel,onError } = props;
    const { handleSubmit, setValue, formState: { errors }, control } = useForm<DeviceJSPEditForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [loadingFlag, setLoadingFlag] = useState(false);

    const regexUrl = new RegExp( '^([a-zA-Z]+:\\/\\/)?' + //プロトコルパターン 
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + //ドメインパターン 
            '((\\d{1,3}\\.){3}\\d{1,3}))' + //IPアドレスパターン 
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // ポート番号またはパス 
            '(\\?[;&a-z\\d%_.~+=-]*)?' + //クエリストリング 
            '(\\#[-a-z\\d_]*)?', //フラグ情報 
            'i' ); 

    useEffect(()=>{
        setLoadingFlag(true);
    },[])
    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        onOK(request);
    }


    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: DeviceJSPEditForm) => {
        let request = {
            device_name: formValue.device_name,
            customer_id: formValue.customer?.value,
            jsp_url: formValue.jsp_url
        } as DeviceJSPModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    // 顧客名一覧取得成功時
    const handleSuccess = (data:any) => {
        setLoadingFlag(false);
        if(data){
            setValue("jsp_url",data.jsp_url);
            setValue("device_name",data.device_name);
            const value = customerList.find((item:any)=>item.value == data.customer_id);
            if(value){
                setValue("customer",value)
                setValue("group_name",value.group_name)
            }
        }
    };

    // 顧客名一覧取得成功時
    const handleError = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    };

    return (
        <>
            {
                loadingFlag &&
                <FetchHelper fetchId={GET_DEVICE_JSP_BY_DEVICE_NAME}
                url={`/master/gateways-jsp/get?device_name=${deviceName}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error)
                }}
                />
            }
            
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <Stack direction='row' justifyContent="flex-start" spacing={1.5} alignItems='center'>
                    <Typography variant="h5">装置編集</Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)} >
                    <Stack spacing={3} mt={0.5}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1} style={{ marginLeft: '10px' }}>
                                <Grid container spacing={1} direction="row">
                                    <FormControl fullWidth>
                                        <Controller
                                        control={control}
                                        name="device_name"
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                size="small"
                                                label="装置名"
                                                type="text"
                                                fullWidth
                                                InputProps={{
                                                    readOnly:true
                                                }}
                                                style={{
                                                    backgroundColor:"floralwhite"
                                                }}
                                            />
                                        )}
                                        />
                                    </FormControl>
                                </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="group_name"
                                    render={({ field }) => (
                                        <Grid container direction="row">
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="グループ名"
                                                size="small"
                                                error={!!errors?.group_name}
                                                helperText={errors?.group_name?.message}
                                                inputProps={{
                                                    maxLength:255,
                                                    readOnly:true,
                                                }}
                                                sx={{backgroundColor:"floralwhite"}}
                                            />
                                        </Grid>
                                    )}
                                />
                            </Grid>
                        </Stack>

                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <FormControl fullWidth>
                                    <Controller
                                    control={control}
                                    name="customer"
                                    rules={{
                                        required:"必須項目です。選択してください。"
                                    }}
                                    render={({ field}) => (
                                        <Autocomplete
                                            {...field}
                                            options={customerList}
                                            getOptionLabel={(option) => option.label}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" style={{ background: palette.background.paper }} error={!!errors?.customer}
                                            helperText={errors?.customer?.message}  />}
                                            onChange={(event, data:any) => {
                                                field.onChange(data);
                                                if(data != null){
                                                    setValue("group_name",data.group_name);
                                                }else{
                                                    setValue("group_name","");
                                                }
                                            }}                                            
                                        />
                                    )}
                                    />
                                </FormControl>
                            </Grid>
                        </Stack>
                        
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name="jsp_url"
                                        rules={{
                                            required:"必須項目です。入力してください。",
                                            pattern:{
                                                value:regexUrl,
                                                message:"URLアドレス形式で入力してください。"
                                            }
                                        }}
                                        render={({field}) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                label="URL"
                                                error={!!errors?.jsp_url}
                                                helperText={errors?.jsp_url?.message}
                                                inputProps={{ maxLength: 255 }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
};

export default DeviceJSPEdit ;


