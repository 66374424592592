import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Auth } from 'aws-amplify';
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Path as RoutePath } from "../../path";
import { actionCreators, ApplicationState, selectors } from "../../store";
import FetchHelper from "../FetchHelper";
import { CustomTextField } from "../styles";
import useForm from "../useForm";

// FORM ID
const formId = "PASSWORD_EDIT_FORM";
const menusFetchId = 'MENUS_FETCH';

// フォーム定義
interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

// 初期値設定
const initialValue: ChangePasswordForm = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: ""
};

// 初期パスワード更新 Request
interface ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
};

export type ChangePasswordProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const ChangePassword = (props: ChangePasswordProps) => {
  const { formState, isAuthorize, isNewPasswordRequired, onUpdate, onCancel, onError, onGetAuthRole,onSuccess } = props;
  const [isChangeNewPass, setIsChangeNewPass] = React.useState(false);
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  
  let navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthorize && !isNewPasswordRequired) {
      navigate('/login');
    }
  },[])

  React.useEffect(() => {

    if (isAuthorize && !isNewPasswordRequired && isChangeNewPass) {
        localStorage.setItem('currentUrl', "");
        setLoadingFlag(true);
        onGetAuthRole();
    }
  },[isNewPasswordRequired,isChangeNewPass ])

  // Submitイベント
  const handleEditSubmit = async (value: ChangePasswordForm) => {
    // 更新後と確認用パスワードで一致しているか検証
    if (formState.value.newPassword !== formState.value.confirmPassword) {
      formState.errors.confirmPassword = "パスワードが一致しません。"
      form.setSubmitting(false);
      return;
    }

    const request = createRequestData(formState.value);

    if (isNewPasswordRequired) {
      setIsChangeNewPass(true);
      // new pass
      onUpdate(request);
    } else {
      setIsChangeNewPass(false);
      // change pass
      const response = await changePassword(formState.value.currentPassword, formState.value.newPassword)
      
      if (response === 'SUCCESS'){
        // logout
        onSuccess();
        onCancel();
        navigate('/login');
      } else {
        let messageError;
        const type = response.code;
        if(type == "LimitExceededException")
          messageError = "入力規定回数を超えました。時間をおいて再試行してください。";
        else if(type == "InvalidPasswordException")
          messageError = "パスワードは半角英字の大文字・小文字と半角数字を含む8文字以上で設定してください。";
        else if(type == "NotAuthorizedException"){
          messageError = "現在のパスワードに誤りがあります。正しいパスワードを設定してください。"
        }
        else{
          messageError = response.message;
        }
        onError({message:messageError});
      }
    }
    form.setSubmitting(false);
  }

  // Form値変更時イベント
  const handleFormChanged = (name: string, value: string) => {
    console.log('>>> handleFormChanged');
  }

  // FORM 入力チェック
  const form = useForm({
    formId,
    initialValue,
    validator: (value) => {
      const errors: { [key in keyof ChangePasswordForm]?: string } = {};
      if (value.currentPassword === '') {
        errors.currentPassword = "必須項目です。入力してください。";
      }
      if (value.newPassword === '') {
        errors.newPassword = "必須項目です。入力してください。";
      }
      if (value.confirmPassword === '') {
        errors.confirmPassword = "必須項目です。入力してください。";
      }

      return errors;
    },
    onSubmit: handleEditSubmit,
    onChanged: handleFormChanged,
  });

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(user, oldPassword, newPassword);
      return data;
    } catch(error:any) {
      return error;
    }
  };

  // FormデータからRequestデータを作成する
  const createRequestData = (formValue: ChangePasswordForm) => {
    let request = {
      currentPassword: formValue.currentPassword,
      newPassword: formValue.newPassword
    } as ChangePasswordRequest;

    return request;
  }

  //Enterキーを防ぐ
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const target = event.target as any;
    if (event.key === 'Enter' && event.shiftKey === false && target.localName != "textarea") {
      event.preventDefault();
    }
  };

  const handleCancelClick = () => {
    if (isNewPasswordRequired)
    {
      onCancel();
      navigate('/login');
    }
    else
    {
      // Go back to the previous page
      navigate(-1)
    }
  };

  // check role
  const handleFetchAuthRoleSuccess = (data: any) => {
    if (!isChangeNewPass){
        return;
    }
    setIsChangeNewPass(false);
    setLoadingFlag(false);
    if (data){
      navigate(RoutePath.Dashboard);
      /*
      if (data['role'] == CustomerRoleValue){
            //localStorage.setItem('currentUrl', RoutePath.SolarLiveStatus);
            navigate(RoutePath.SolarLiveStatus)
            return
          } else if(data["role"].toString() === SmartCustomerRoleValue){
            if(data.code_restriction_array){
              if(data.code_restriction_array.includes("GADEMAND")){
                navigate(RoutePath.SmartDemandList);
                return
              }else if(data.code_restriction_array.includes("GASOLAR")){
                navigate(RoutePath.SmartSolarList);
                return
              }
            }
          }
          else if (data.auth_role_map['LM-telemetry-view'] && (data['code_restriction_array']?.length == 0 || data['code_restriction_array'].includes('ALARM'))){
            //localStorage.setItem('currentUrl', RoutePath.AlarmList + '?path=root');
            navigate(RoutePath.AlarmList + '?path=root')
            return
          } else {
            //localStorage.setItem('currentUrl', RoutePath.SolarLiveStatus);
            navigate(RoutePath.SolarLiveStatus)
            return
          }
      */
    }
  }
  
  const handleError = (success: boolean, data: any, error: any) => {
    console.log('>>> handleError');
    setLoadingFlag(false);
  };

  return (
    <>
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress />
        </div>
      )}
      <form onSubmit={form.handleSubmit} onKeyDown={e => { handleKeyDown(e) }}>
        <Stack justifyContent="center" alignItems="center" style={{ height: '66vh'}}>
          <Typography width="60%" variant="h4" mb={1}>パスワード変更</Typography>
          <div style={{ height: '60%', width: '60%' }}>
            <Stack mt="5px" mb="3px" width="100%" spacing={3} >
              <CustomTextField
                fullWidth
                type="password"
                label={isNewPasswordRequired || loadingFlag ?"初期パスワード":"現在のパスワード"}
                name="currentPassword"
                size="small"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={form.values.currentPassword === null || form.values.currentPassword === undefined ? "" : form.values.currentPassword}
                error={!!form.errors.currentPassword}
                helperText={form.errors.currentPassword}
              />
              <CustomTextField
                fullWidth
                type="password"
                label="新しいパスワード"
                name="newPassword"
                size="small"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={form.values.newPassword === null || form.values.newPassword === undefined ? "" : form.values.newPassword}
                error={!!form.errors.newPassword}
                helperText={form.errors.newPassword}
              />
              <CustomTextField
                fullWidth
                type="password"
                label="新しいパスワード（確認用）"
                name="confirmPassword"
                size="small"
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                value={form.values.confirmPassword === null || form.values.confirmPassword === undefined ? "" : form.values.confirmPassword}
                error={!!form.errors.confirmPassword}
                helperText={form.errors.confirmPassword}
              />
            </Stack>
            
            <Typography mt={3}>※パスワードは8文字以上で設定してください。</Typography>
            <Typography>※パスワードには、半角英字の大文字・小文字と半角数字を含めてください。</Typography>
            { !isNewPasswordRequired && !loadingFlag &&
              <>
                <Typography mt={2}>パスワードを変更すると自動的にログアウトします。</Typography>
                <Typography>新しいパスワードで再度ログインしてください。</Typography>
              </>
            }
            <Box height="54px" />
            <Stack direction='row' justifyContent="flex-end" spacing={1} >
              <LoadingButton variant="contained" type="submit" loading={formState.submitting} sx={{ padding: '8px 54px' }}>更新</LoadingButton>
              <Button color="inherit" variant="contained" onClick={handleCancelClick}>キャンセル</Button>
            </Stack>
          </div>
        </Stack>
      </form >
      <FetchHelper 
        fetchId={menusFetchId} 
        onComplete={(success, data, error) => 
          success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)
        } 
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  formState: selectors.getFormState(
    state,
    formId
  ),
  isAuthorize: selectors.isAuthorize(state),
  isNewPasswordRequired: selectors.isNewPasswordRequired(state),
});

const mapDispatchToProps = {
  onUpdate: (data: ChangePasswordRequest) => actionCreators.changePassword(data.currentPassword, data.newPassword),
  onCancel: () => actionCreators.clearSession(),
  onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
  onError: (error: any) =>
    actionCreators.showMessage({
      type: "error",
      title: "パスワード変更",
      body: error.message,
    }
  ),
  onSuccess: () =>
  actionCreators.showMessage({
    type: "info",
    title: "パスワード変更",
    body: "パスワードを変更しました。",
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword as any);
