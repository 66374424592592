import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

interface DemandChangeStartEndModel{
    mainid: string,
    statusCode: string,
    device_no: string,
}

const changeMeasurentStartEndEdit = [
    {
        value: '0',
        label: '定期通報 停止',
        attribute: '',
    },
    {
        value: '1',
        label: '定期通報 開始',
        attribute: '',
    }
]

export type SelectChangeMeasurentStartEndDialogProps = {
    device_no:string;
    isOpen: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
    mainId: string;
};

export const SelectChangeMeasurentStartEndDialog = (props: SelectChangeMeasurentStartEndDialogProps) => {
    const { device_no, isOpen, mainId, onOK, onCancel } = props;
    const [open, setOpen] = React.useState(isOpen);

    // Cancelボタンイベント
    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    // 初期値設定
    const initialValue: any = {
        mainid: mainId,
        statusCode: changeMeasurentStartEndEdit[0].value,
        device_no: device_no,
    }

    const { handleSubmit, formState: { errors }, setValue, control } = useForm<DemandChangeStartEndModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: DemandChangeStartEndModel) => {
        setOpen(false);
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: DemandChangeStartEndModel) => {
        let request = {
            mainid: formValue.mainid,
            status_code: formValue.statusCode,
            device_no: formValue.device_no,
        } as any;
        return request;
    }

    return (
        <div>
            <Dialog open={open} maxWidth={"md"} fullWidth>
                <DialogTitle>測定値開始終了変更</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                    <div style={{ width: '100%' }}>
                        <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '90%', margin: 'auto' }}>
                            <Stack spacing={3}>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} paddingTop={"10px"}>
                                    <Grid container spacing={1} direction="row" >
                                        <FormControl fullWidth
                                            size="small" error={!!errors?.statusCode}>
                                            <InputLabel>測定値開始終了変更</InputLabel>
                                            <Controller
                                                name="statusCode"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' }}
                                                render={({ field } : any) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            label="測定値開始終了変更"
                                                            error={!!errors?.statusCode}
                                                            size="small"
                                                        >
                                                            {changeMeasurentStartEndEdit.map(item => (
                                                                <MenuItem key={item.value} value={item.value}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {
                                                            !!errors?.statusCode &&
                                                            <FormHelperText style={{ color: 'red' }}>{errors?.statusCode?.message}</FormHelperText>
                                                        }</>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Stack>
                            </Stack>
                            <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                                <Button variant="contained" type="submit" >保存</Button>
                            </Stack>
                        </form >
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SelectChangeMeasurentStartEndDialog;