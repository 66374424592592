import { connect } from "react-redux";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import React from "react";
import { Path as RoutePath } from "../../../path";
import { CircularProgress, Grid, Typography, Stack, Button, TextField, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Card, CardContent, CardHeader, FormControlLabel, Switch } from "@mui/material";
import DeviceInformation from "../device/DeviceInformation";
import FetchHelper from "../../FetchHelper";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import palette from "../../../theme/palette";
import { useNavigate } from "react-router";
import { Customer } from "../../../models";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const menusFetchId = 'MENUS_FETCH';
const GET_DEVICE = "GET_DEVICE";
const GET_LIST_ADDRESS = "GET_LIST_ADDRESS";
const UPDATE_LIST_ADDRESS = "UPDATE_LIST_ADDRESS";
const SET_REPORT = "SET_REPORT";
interface Item{
    address:string,
}
interface ReportDemandModel{
    address_info_array: {
        addressList: Item[],
        addressListAutoDay: Item[],
        addressListAutoWeek: Item[],
        addressListAutoMonth: Item[],
    },
    autoSendDay:boolean,
    autoSendWeek:boolean,
    autoSendMonth:boolean,
}

const initialValue :ReportDemandModel = {
    address_info_array:{
        addressList:[{address:""}],
        addressListAutoDay:[{address:""}],
        addressListAutoWeek:[{address:""}],
        addressListAutoMonth:[{address:""}],
    },
    autoSendDay:false,
    autoSendWeek:false,
    autoSendMonth:false,
}
// -------------
// Props
export type ReportDemandProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const ReportDemand = (props: ReportDemandProps) => {
    const {
        fetchStateMenu,
        onGetAuthRole, onGetDevice, onGetListAddress, onUpdateListAddress, onUpdateSuccess, onUpdateError, onSetReport, onSetReportSuccess, onSetReportError
    } = props;

    const { control, formState: { errors }, handleSubmit, setValue, getValues, trigger } = useForm<ReportDemandModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    const { fields: addressFields, append: appendAddress, remove: removeAddress } = useFieldArray({
        control,
        name: "address_info_array.addressList",
    });

    const { fields: addressFieldsAutoDay, append: appendAddressAutoDay, remove: removeAddressAutoDay } = useFieldArray({
        control,
        name: "address_info_array.addressListAutoDay",
    });

    const { fields: addressFieldsAutoWeek, append: appendAddressAutoWeek, remove: removeAddressAutoWeek } = useFieldArray({
        control,
        name: "address_info_array.addressListAutoWeek",
    });

    const { fields: addressFieldsAutoMonth, append: appendAddressAutoMonth, remove: removeAddressAutoMonth } = useFieldArray({
        control,
        name: "address_info_array.addressListAutoMonth",
    });
    
    localStorage.setItem('lastActiveFormPath', RoutePath.DeviceList);
    localStorage.setItem('title', '監視装置');
    const urlParams = new URLSearchParams(window.location.search);
    const mainid = urlParams.get('mainid') || "";
    const [addressLoadingFlag, setAddressLoadingFlag] = React.useState(false);
    const [device, setDevice] = React.useState<any>();
    const [deviceLoadingFlag,setDeviceLoadingFlag] = React.useState(false);
    const [openConfirm,setOpenConfirm] = React.useState(false);
    const [title,setTitle] = React.useState<string>("");
    const [message,setMessage] = React.useState<string>("");
    const [hiddenCancel,setHiddenCancel] = React.useState<boolean>(false);
    const [paramsRegister,setParamsRegister] = React.useState<any>();
    const [authRole, setAuthRole] = React.useState<any>();
    const [disableSaveBtn, setDisableSaveBtn] = React.useState(true);
    const [menuLoadingFlag,setMenuLoadingFlag] = React.useState(false);
    const [isError,setError] = React.useState<boolean>(false);
    const [dateSelect, setDateSelect] = React.useState<any>(new Date());

    //タイトルの高さを戻
    React.useEffect(() => {
        setAddressLoadingFlag(true);
        setDeviceLoadingFlag(true);
        onGetDevice(mainid);
        onGetListAddress(mainid);
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            setMenuLoadingFlag(true);
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        if(dateSelect != null){
            setError(false)
        }
    },[dateSelect])

    React.useEffect(() => {
        if (authRole !== undefined && device !== undefined){
            if (authRole.auth_role_map['GW-demandreportsave-all']){
                setDisableSaveBtn(false);
            } else {
                if (authRole.auth_role_map['GW-demandreportsave-belong']){
                    if (authRole.company_id == device.customer_id)
                    setDisableSaveBtn(false);
                }
                if (authRole.auth_role_map['GW-demandreportsave-group']){
                    if (authRole.company_group_id == device.group_id)
                    {
                        setDisableSaveBtn(false);
                    }
                }
                if (authRole.auth_role_map['GW-demandreportsave-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == device.customer_id).length > 0)
                        setDisableSaveBtn(false);
                }
            }
        }
    },[authRole, device])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        setMenuLoadingFlag(false);
        if (data)
            setAuthRole(data);
    }

    const handleError = (success: boolean, data: any, error: any) => {
        setMenuLoadingFlag(false);
    }

    const handleDeviceSuccess = (data:any) => {
        setDevice(data);
        setDeviceLoadingFlag(false);
    }

    const handleDeviceError = () => {
        setDeviceLoadingFlag(false);
    }

    // Submitイベント
    const handleEditSubmit = (data:any) => {
        setDeviceLoadingFlag(true);
        const request = createRequestData(data);
        onUpdateListAddress(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ReportDemandModel) => {
        const addressList = formValue.address_info_array.addressList.filter((item:any) => item.address != "").map((item) => item.address);
        const addressListAutoDay = formValue.address_info_array.addressListAutoDay.filter((item:any) => item.address != "").map((item) => item.address);
        const addressListAutoWeek = formValue.address_info_array.addressListAutoWeek.filter((item:any) => item.address != "").map((item) => item.address);
        const addressListAutoMonth = formValue.address_info_array.addressListAutoMonth.filter((item:any) => item.address != "").map((item) => item.address);
        let request = {
            mainid: mainid,
            delivery_info: {
                DemandManual:{
                    auto_send:null,
                    addressList:addressList
                },
                DemandAutoDay:{
                    auto_send:formValue.autoSendDay === true ? 1 : 0,
                    addressList:addressListAutoDay
                },
                DemandAutoWeek:{
                    auto_send:formValue.autoSendWeek === true ? 1 : 0,
                    addressList:addressListAutoWeek
                },
                DemandAutoMonth: {
                    auto_send:formValue.autoSendMonth === true ? 1 : 0,
                    addressList:addressListAutoMonth
                }
            }
        } as any;
        
        return request;
    }

    // 取得正常終了時の初期表示
    const handleAddressSuccess = (data:any) => {                    
        setAddressLoadingFlag(false);
        if(data){
            setValue("address_info_array.addressList",data["delivery_info"]["DemandManual"]["addressList"].length > 0 ? data["delivery_info"]["DemandManual"]["addressList"].map((item: any) => ({ address: item })) : initialValue.address_info_array.addressList);
            setValue("address_info_array.addressListAutoDay",data["delivery_info"]["DemandAutoDay"]["addressList"].length > 0 ? data["delivery_info"]["DemandAutoDay"]["addressList"].map((item: any) => ({ address: item })) : initialValue.address_info_array.addressListAutoDay);
            setValue("address_info_array.addressListAutoWeek",data["delivery_info"]["DemandAutoWeek"]["addressList"].length > 0 ? data["delivery_info"]["DemandAutoWeek"]["addressList"].map((item: any) => ({ address: item })) : initialValue.address_info_array.addressListAutoWeek);
            setValue("address_info_array.addressListAutoMonth",data["delivery_info"]["DemandAutoMonth"]["addressList"].length > 0 ? data["delivery_info"]["DemandAutoMonth"]["addressList"].map((item: any) => ({ address: item })) : initialValue.address_info_array.addressListAutoMonth);
            setValue("autoSendDay",data["delivery_info"]["DemandAutoDay"]["auto_send"] === 0 ? false : true );
            setValue("autoSendWeek",data["delivery_info"]["DemandAutoWeek"]["auto_send"] === 0 ? false : true );
            setValue("autoSendMonth",data["delivery_info"]["DemandAutoMonth"]["auto_send"] === 0 ? false : true );
        }
    }

    const handleAddressError = () => {
        setAddressLoadingFlag(false);
    }
    
    let navigate = useNavigate();
    // キャンセル
    const handleCancel = () => {
        navigate(RoutePath.DeviceList);
    }

    const handleDeleteRow = (index:any,key:string) =>{
        let arrInput;
        switch(key){
            case "manual":
                arrInput = getValues("address_info_array.addressList");
                removeAddress(index);
                arrInput.length === 1 && appendAddress({ address: "" } as Item);
                break;
            case "day":
                arrInput = getValues("address_info_array.addressListAutoDay");
                removeAddressAutoDay(index);
                arrInput.length === 1 && appendAddressAutoDay({ address: "" } as Item);
                break;
            case "week":
                arrInput = getValues("address_info_array.addressListAutoWeek");
                removeAddressAutoWeek(index);
                arrInput.length === 1 && appendAddressAutoWeek({ address: "" } as Item);
                break;
            case "month":
                arrInput = getValues("address_info_array.addressListAutoMonth");
                removeAddressAutoMonth(index);
                arrInput.length === 1 && appendAddressAutoMonth({ address: "" } as Item);
                break;
        }
    }

    const handleAddRow = (key:string) => {
        switch(key){
            case "manual":
                appendAddress({address:""} as Item);
                break;
            case "day":
                appendAddressAutoDay({address:""} as Item);
                break;
            case "week":
                appendAddressAutoWeek({address:""} as Item);
                break;
            case "month":
                appendAddressAutoMonth({address:""} as Item);
                break
        }
    }

    const handleUpdateSuccess = (data:any) => {
        setDeviceLoadingFlag(false);
        if(data.result_code === 0){
            onUpdateSuccess();
        }else{
			onUpdateError();
		}
    }

    const handleUpdateError = () => {
        setDeviceLoadingFlag(false);
        onUpdateError();
    }

    const handleRegistReport = (key:string) => {
        const addressList = getValues("address_info_array.addressList").filter((item:any) => item.address != "").map((item) => item.address);
        setOpenConfirm(true);
        let errorData = false;
        const formData = getValues("address_info_array.addressList");
        const data = formData.filter((item) => item.address != "");
        data.length > 0 ? errorData = false : errorData = true;
        const params:any = {
            type: "DEMAND",
            mainid:mainid,
            pulse_type: device.pulse_type,
            addressList:addressList,
        }
        switch(key){
            case "daily":
                setTitle("日報送信")
                setMessage("日報を送信しますか。");
                params.report_type = "日報";
                break;
            case "weekly":
                setTitle("週報送信")
                setMessage("週報を送信しますか。");
                params.report_type = "週報";
                break;
            case "monthly":
                const date = new Date(dateSelect);
                setMessage(`${date.getFullYear()}年${date.getMonth() + 1}月分の\n月報を送信しますか。`);
                setTitle("月報送信");
                params.report_type = "月報";
                const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1
                const target_date = `${date.getFullYear()}-${month}-${getLastDayOfMonth(date.getFullYear(),date.getMonth())}`
                params.target_date = target_date
                break;
        }
        if(errorData){
            setMessage("宛先が設定されていません。")
            setHiddenCancel(true);
        }else{
            setHiddenCancel(false);
            setParamsRegister(params);
        }
    }

    const  getLastDayOfMonth = (year: number, month: number) => {
        const nextMonth = new Date(year, month + 1, 1);
        const lastDay = new Date(nextMonth.getTime() - 1);
        return lastDay.getDate();
    }

    const handleOK = () => {
        setOpenConfirm(false);
        if(!hiddenCancel){
            setDeviceLoadingFlag(true);
            onSetReport(paramsRegister);
        }
    }

    const handleRegistSuccess = (data:any) => {
        setDeviceLoadingFlag(false);
        if(data.result_code != undefined){
            if(data.result_code == 0){
                onSetReportSuccess(title);
            }else{
                onSetReportError(title,data.error_message);
            }
        }else{
            onSetReportError(title,data);
        }
    }

    const handleRegistError = (error:string) => {
        setDeviceLoadingFlag(false);
        onSetReportError(title,error);
    }

    return (
        <>
            {(menuLoadingFlag || addressLoadingFlag || deviceLoadingFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}

            {
                openConfirm && 
                <>
                    <Dialog
                        open={true}
                    >
                        <DialogTitle>
                            {title}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {message.split("\n").map((line, index) => (
                                    <React.Fragment key={index}>
                                    {line}
                                    <br />
                                    </React.Fragment>
                                ))}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleOK} variant="contained">OK</Button>
                        {
                            !hiddenCancel &&
                            <Button onClick={() => setOpenConfirm(false)} color="inherit" variant="contained">キャンセル</Button>
                        }
                        </DialogActions>
                    </Dialog>
                </>
            }
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <DeviceInformation deviceInformation={device} isDisplayDeviceInfo={true} mainid={mainid ?? ''} title="帳票配信" isShowMenuButton ={true}></DeviceInformation>
                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                        <Grid container spacing={1} direction="row">
                            <Grid item xs={7} sm={6} md={4} lg={4} spacing={4}>
                                <Stack spacing={1}>
                                    <Stack>
                                        <Grid container direction="row">
                                            <Grid item xs={3}></Grid>
                                            <Grid item xs={6}>
                                                <Stack>
                                                    <Button disabled={disableSaveBtn} variant="contained" onClick={ async () => {
                                                        const result = await trigger();
                                                        if(result)
                                                            handleRegistReport("daily");
                                                    }}>日報送信</Button>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={3}></Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                    <Grid container direction="row">
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={6}>
                                            <Stack>
                                                <Button disabled={disableSaveBtn} variant="contained" onClick={ async () => {
                                                        const result = await trigger();
                                                        if(result)
                                                            handleRegistReport("weekly");
                                                    }}>週報送信</Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                    </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid container direction="row">
                                            <Grid item xs={3}></Grid>
                                            <Grid item xs={6}>
                                                <Stack>
                                                    <Button disabled={disableSaveBtn} variant="contained" onClick={ async () => {
                                                        const result = await trigger();
                                                        if(result)
                                                            if (!isError && !isNaN(dateSelect.getTime())) 
                                                                handleRegistReport("monthly");
                                                    }}>月報送信</Button>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={3}></Grid>
                                        </Grid>
                                    </Stack>
                                    <Stack>
                                        <Grid container direction="row" sx={{alignItems:"center",justifyContent:"center"}}  >
                                            <Box sx={{ display: 'inline' }}>
                                                <Box sx={{ display: 'inline-flex',mr:"5px", marginTop:"10px"}}>
                                                    <Typography sx={{ color: 'text.secondary' }}>月報：対象年月</Typography>
                                                </Box>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            inputFormat="YYYY.MM"
                                                            views={["year", "month"]}
                                                            openTo="month"
                                                            renderInput={(props: any) => (
                                                                <TextField
                                                                    size="small"
                                                                    style={{ width: '125px', marginLeft: '-1px' }}
                                                                    error={!!isError}
                                                                    sx={{
                                                                        marginTop:"0px",
                                                                        fieldset: {
                                                                            border: "1px solid rgba(24, 144, 255, 0.5)",
                                                                            borderWidth: "1px 1px 1px 1px",
                                                                            zIndex: "1",
                                                                            borderRadius: "7px"
                                                                        }
                                                                    }}
                                                                    {...props} />
                                                            )}
                                                            value={dateSelect}
                                                            onChange={(newValue: any) => {
                                                                if(newValue !== null){
                                                                    setDateSelect(newValue.$d);
                                                                }else{
                                                                    setError(true);
                                                                }
                                                            }}
                                                            
                                                            disableMaskedInput={false}
                                                        />
                                                </LocalizationProvider>
                                            </Box>
                                        </Grid>
                                    </Stack>
                                    
                                </Stack>
                            </Grid>
                            <Grid item xs={5} sm={6} md={8} lg={8}>
                                <div style={{maxHeight:"67vh",overflowY:"auto"}}>
                                    <Card style={{ margin: '0px auto 12px', width: '100%' }}>
                                        <CardHeader
                                            style={{minWidth:"12%",padding:"16px 0px 8px 16px"}}
                                            title='手動　帳票配信先編集'
                                        />
                                        <CardContent style={{ paddingTop: '0' }}>
                                        <Stack spacing={2} ml={3} mr={3}>
                                            <Stack spacing={1} >
                                            <span>宛先一覧</span>
                                            {
                                                addressFields.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.addressList.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="配信アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.addressList?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.addressList?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button"
                                                                                onClick={() => handleDeleteRow(index,"manual")}
                                                                            >
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            </Stack>
                                            <Stack>
                                                <Grid item xs={1}>
                                                    <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow("manual")} >
                                                        <AddIcon fontSize="small" />
                                                    </Button>
                                                </Grid>
                                            </Stack>
                                        </Stack>    
                                        </CardContent>
                                    </Card>

                                    <Card style={{ margin: '0px auto 12px', width: '100%' }}>
                                        <CardHeader
                                            style={{minWidth:"12%",padding:"16px 0px 4px 16px"}}
                                            title='自動：日報　帳票配信先編集'
                                        />
                                        <CardContent style={{ paddingTop: '0' }}>
                                        <Stack spacing={2} ml={3} mr={3}>
                                            <Stack spacing={1} >
                                            <Controller
                                                name="autoSendDay"
                                                control={control}
                                                render={({field:{onChange,value}}) => (
                                                    <>
                                                        <FormControlLabel 
                                                            sx={{
                                                                display:"block",
                                                                minWidth:"100px"
                                                            }}
                                                            control={<Switch 
                                                            checked={value}
                                                            onChange={onChange}
                                                            />}
                                                            label="自動送信"
                                                            labelPlacement="end"
                                                        />
                                                    </>
                                                )}
                                            />
                                            <span>宛先一覧</span>
                                            {
                                                addressFieldsAutoDay.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.addressListAutoDay.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="配信アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.addressListAutoDay?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.addressListAutoDay?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button"
                                                                                onClick={() => handleDeleteRow(index,"day")}
                                                                            >
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            </Stack>
                                            <Stack>
                                                <Grid item xs={1}>
                                                    <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow("day")} >
                                                        <AddIcon fontSize="small" />
                                                    </Button>
                                                </Grid>
                                            </Stack>
                                        </Stack>    
                                        </CardContent>
                                    </Card>

                                    <Card style={{ margin: '0px auto 12px', width: '100%' }}>
                                        <CardHeader
                                            style={{minWidth:"12%",padding:"16px 0px 4px 16px"}}
                                            title='自動：週報　帳票配信先編集'
                                        />
                                        <CardContent style={{ paddingTop: '0' }}>
                                        <Stack spacing={2} ml={3} mr={3}>
                                            <Stack spacing={1} >
                                            <Controller
                                                name="autoSendWeek"
                                                control={control}
                                                render={({field:{onChange,value}}) => (
                                                    <>
                                                        <FormControlLabel 
                                                            sx={{
                                                                display:"block",
                                                                minWidth:"100px"
                                                            }}
                                                            control={<Switch 
                                                            checked={value}
                                                            onChange={onChange}
                                                            />}
                                                            label="自動送信"
                                                            labelPlacement="end"
                                                        />
                                                    </>
                                                )}
                                            />
                                            <span>宛先一覧</span>
                                            {
                                                addressFieldsAutoWeek.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.addressListAutoWeek.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="配信アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.addressListAutoWeek?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.addressListAutoWeek?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button"
                                                                                onClick={() => handleDeleteRow(index,"week")}
                                                                            >
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            </Stack>
                                            <Stack>
                                                <Grid item xs={1}>
                                                    <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow("week")} >
                                                        <AddIcon fontSize="small" />
                                                    </Button>
                                                </Grid>
                                            </Stack>
                                        </Stack>    
                                        </CardContent>
                                    </Card>

                                    <Card style={{ margin: '0px auto 12px', width: '100%' }}>
                                        <CardHeader
                                            style={{minWidth:"12%",padding:"16px 0px 4px 16px"}}
                                            title='自動：月報　帳票配信先編集'
                                        />
                                        <CardContent style={{ paddingTop: '0' }}>
                                        <Stack spacing={2} ml={3} mr={3}>
                                            <Stack spacing={1} >
                                            <Controller
                                                name="autoSendMonth"
                                                control={control}
                                                render={({field:{onChange,value}}) => (
                                                    <>
                                                        <FormControlLabel 
                                                            sx={{
                                                                display:"block",
                                                                minWidth:"100px"
                                                            }}
                                                            control={<Switch 
                                                            checked={value}
                                                            onChange={onChange}
                                                            />}
                                                            label="自動送信"
                                                            labelPlacement="end"
                                                        />
                                                    </>
                                                )}
                                            />
                                            <span>宛先一覧</span>
                                            {
                                                addressFieldsAutoMonth.map((item, index) => (
                                                    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
                                                        <Controller
                                                            key={item.id}
                                                            name={`address_info_array.addressListAutoMonth.${index}.address`}
                                                            control={control}
                                                            rules={
                                                                {
                                                                    pattern: {
                                                                        value: /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/,
                                                                        message: 'メールアドレス形式で入力してください。'
                                                                    }
                                                                }
                                                            }
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    fullWidth
                                                                    type="text"
                                                                    label="配信アドレス"
                                                                    size="small"
                                                                    error={!!errors?.address_info_array?.addressListAutoMonth?.[index]?.address}
                                                                    helperText={errors?.address_info_array?.addressListAutoMonth?.[index]?.address?.message}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <IconButton type="button"
                                                                                onClick={() => handleDeleteRow(index,"month")}
                                                                            >
                                                                                <DeleteIcon style={{ color: palette.primary.main }} />
                                                                            </IconButton>
                                                                        )
                                                                    }}
                                                                    inputProps={{ maxLength: 255 }}
                                                                />
                                                            )}
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                            </Stack>
                                            <Stack>
                                                <Grid item xs={1}>
                                                    <Button variant="outlined" aria-label="edit" onClick={() => handleAddRow("month")} >
                                                        <AddIcon fontSize="small" />
                                                    </Button>
                                                </Grid>
                                            </Stack>
                                        </Stack>    
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                            <Stack direction='row' justifyContent="flex-end" spacing={3} width="100%" mt={1}>
                                <Button variant="contained" disabled={disableSaveBtn} type="submit">保存</Button>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                            </Stack>
                        </Grid>
                    </Stack>
                </form>
            </div>
            <FetchHelper fetchId={GET_DEVICE} method="POST" onComplete={(success, data, error) => success ? handleDeviceSuccess(data) : handleDeviceError()} />
            <FetchHelper fetchId={GET_LIST_ADDRESS} method="POST" onComplete={(success, data, error) => success ? handleAddressSuccess(data) : handleAddressError()} />
            <FetchHelper fetchId={UPDATE_LIST_ADDRESS} method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(data) : handleUpdateError()} />
            <FetchHelper fetchId={SET_REPORT} method="POST" onComplete={(success, data, error) => success ? handleRegistSuccess(data) : handleRegistError(error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </>
    )
}



const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onGetDevice: (id: string) => actionCreators.fetch(GET_DEVICE, `/master/gateways/get?id=${id}`, "GET", null),
    onGetListAddress: (id:string) => actionCreators.fetch(GET_LIST_ADDRESS,`/master/reports-demand2/get?mainid=${id}`,"GET",null),
    onUpdateListAddress: (params:any) => actionCreators.fetch(UPDATE_LIST_ADDRESS,`/master/reports-demand2/edit`,"POST",params,false,true),
    onSetReport: (params:any) => actionCreators.fetch(SET_REPORT,`/telemetry/set?code=REPORT`,"POST",params,false,true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "帳票配信先編集",
            body: "帳票配信先を更新しました。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "帳票配信先編集",
            body: "帳票配信先を更新出来ませんでした。",
        }),
    onSetReportSuccess: (title:string) => 
        actionCreators.showMessage({
            type: "info",
            title: `${title}`,
            body: `${title}しました。`,
        }),
    onSetReportError: (title:string,error:string) =>
        actionCreators.showMessage({
            type: "error",
            title: `${title}`,
            body: `${title}出来ませんでした。${error}`,
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportDemand as any);