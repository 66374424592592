import { Button, Autocomplete, CircularProgress, Grid, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { connect } from "react-redux";
import FetchHelper from "../../FetchHelper";

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { CustomerResponse, DeviceModel, OptionItem } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/AppStore";
import DeviceInformation from "./DeviceInformation";
import { limitMasterCustomers, customerLabelName } from "../../../statics";

const customerFetchId = "CUSTOMER_FETCH";

export type EditDeviceFormProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// フォーム定義
interface EditDeviceForm {
    id: string,
    ipAddress: string,
    customer: OptionItem | null,
    place: string,
    pulseType: string,
    emgAlarmLeak:string,
    develop:string,
    group_name:string,
    manage_customer:OptionItem|null,    
}

// 初期値設定
const initialValue: EditDeviceForm = {
    id: "",
    ipAddress: "",
    customer: null,
    place: "",
    pulseType: "0",
    emgAlarmLeak: "1",
    develop: "0",
    group_name:"",
    manage_customer:null,
}

export const EditDevice = (props: EditDeviceFormProps) => {
    const { onUpdate, onUpdateSuccess, onUpdateError, onGetDevice } = props;
    const { handleSubmit, formState: { errors }, control, setValue } = useForm<DeviceModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [optionCustomer, setOptionCustomer] = useState<any>([]);
    const [optionManageCustomer, setOptionManageCustomer] = useState<OptionItem[]>([]);
    const [device, setDevice] = useState<any>();
    const { id } = useParams();
    const [loadingFlag, setLoadingFlag] = useState(true);
    let navigate = useNavigate();
    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        onUpdate(request);
        setLoadingFlag(true);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: DeviceModel) => {

        let request = {
            id: formValue.id,
            ip_address: formValue.ipAddress,
            customer_id: formValue.customer?.value,
            place: formValue.place,
            pulse_type: formValue.pulseType,
            emg_alarm_leak:formValue.emgAlarmLeak,
            develop:formValue.develop,
            manage_customer_id: formValue.manage_customer ? formValue.manage_customer.value : "",
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => navigate(RoutePath.DeviceList);

    // 顧客名一覧取得成功時
    const handleFetchCustomersSuccess = (data: CustomerResponse) => {
        if (data?.results) {
            const customer_option = data?.results.map((x) => { return { value: x.id.toString(), label: x.name,attributes:"", group_name:x.group_name, group_id:x.group_id } as any });
            setOptionCustomer(customer_option);
            setOptionManageCustomer(customer_option);
            id && onGetDevice(id);
        }
    };

    // 顧客名一覧取得成功時
    const handleFetchCustomersError = () => {
    };

    const handleUpdateSuccess = (data: any) => {
        const result = {
            resultCode: data.result_code,
            updateData: data.data,
        };
        if (result.resultCode === 1) {
            handleUpdateError();
        } else {
            onUpdateSuccess();
            setLoadingFlag(false);
        }
    }

    const handleUpdateError = () => {
        setLoadingFlag(false);
        onUpdateError();
    }

    const handleSuccess = (data: any) => {
        setDevice(data);
        const customer = {
            value: data.customer_id,
            label: data.customer_name,
            attribute: '',
        }
        const manage_customer = {
            value: data.manage_customer_id,
            label: data.manage_customer_name,
            attribute: '',
        }
        setValue('id', data.id);
        setValue('ipAddress', data.ip_address);
        setValue('customer', customer);
        if(!data.manage_customer_id){
            setValue('manage_customer', null);
        }else{
            setValue('manage_customer', manage_customer);
        }
        setValue('place', data.place);
        setValue('pulseType', data.pulse_type);
        setValue("emgAlarmLeak",data.emg_alarm_leak);
        setValue("develop",data.develop);
        setValue("group_name",optionCustomer.find((item:any) => item.value == data.customer_id).group_name)
        setLoadingFlag(false);
    }

    useEffect(() => {       
        if((device && optionManageCustomer && optionManageCustomer.length > 0 && !(optionManageCustomer.find((item:any) => item.value == device.manage_customer_id)))){                        
            if(device.manage_customer_id){
                const manage_customer = {
                    value: device.manage_customer_id,
                    label: device.manage_customer_name,
                    attribute: '',
                }
                const new_data:any = [...optionManageCustomer,manage_customer];
                setOptionManageCustomer(new_data);
            }
        }
    },[optionManageCustomer,device])


    const handleError = () => {
        setLoadingFlag(false);
    }

    return (
        <>
            <FetchHelper fetchId={customerFetchId}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomersSuccess(data) : handleFetchCustomersError()
                }}
            />
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <DeviceInformation deviceInformation={device} mainid={id ?? ''} title="装置編集" isShowMenuButton ={true}></DeviceInformation>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack spacing={3} mt={1.5}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    name="id"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="装置ID"
                                            size="small"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            sx={{ backgroundColor: 'floralwhite' }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    name="ipAddress"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="IP アドレス"
                                            size="small"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            sx={{ backgroundColor: 'floralwhite' }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="group_name"
                                render={({ field }) => (
                                    <Grid container direction="row">
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="グループ名"
                                            size="small"
                                            error={!!errors?.group_name}
                                            helperText={errors?.group_name?.message}
                                            inputProps={{
                                                maxLength:255,
                                                readOnly:true,
                                            }}
                                            sx={{backgroundColor:"floralwhite"}}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="customer"
                                rules={{ required: "必須項目です。選択してください。", }}
                                render={({ field }) => (
                                    <Grid container spacing={1} direction="row">
                                        <Autocomplete
                                            {...field}
                                            autoHighlight
                                            options={optionCustomer}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values:any) => {
                                                field.onChange(values)
                                                if(values != null){
                                                    setValue("group_name",values.group_name);
                                                }else{
                                                    setValue("group_name","");
                                                }
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} error={!!errors?.customer} helperText={errors?.customer?.message} />}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    name="place"
                                    control={control}
                                    rules={{
                                        required: '必須項目です。入力してください。',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="場所"
                                            size="small"
                                            error={!!errors?.place}
                                            helperText={errors?.place?.message}
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="manage_customer"
                                render={({ field }) => (
                                    <Grid container spacing={1} direction="row">
                                        <Autocomplete
                                            {...field}
                                            autoHighlight
                                            options={optionManageCustomer}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values:any) => {
                                                field.onChange(values)
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label={`管理組織名`} error={!!errors?.manage_customer} helperText={errors?.manage_customer?.message} />}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="pulseType"
                                render={({ field }: any) => (
                                    <Grid container spacing={1} direction="row" >
                                        <ToggleButtonGroup
                                            color="primary"
                                            exclusive
                                            {...field}
                                            size="small"
                                        >
                                            <ToggleButton value="0">なし</ToggleButton>
                                            <ToggleButton value="1">デマンド</ToggleButton>
                                            <ToggleButton value="2">太陽光</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Stack mt={"20px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                        <Typography>テス卜用装置判定</Typography>
                    </Stack>
                    <Stack mt={"5px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                        <Controller
                            control={control}
                            name="develop"
                            render={({ field }: any) => (
                                <Grid container spacing={0.5} direction="row" >
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        {...field}
                                        size="small"
                                    >
                                        <ToggleButton value="0">本番用</ToggleButton>
                                        <ToggleButton value="1">テス卜用</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            )}
                        />
                    </Stack>
                    <Stack mt={"20px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                            <Typography>漏電緊急警報設定</Typography>
                        </Stack>
                    <Stack mt={"5px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                        <Controller
                            control={control}
                            name="emgAlarmLeak"
                            render={({ field }: any) => (
                                <Grid container spacing={0.5} direction="row" >
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        {...field}
                                        size="small"
                                    >
                                        <ToggleButton value="0">なし</ToggleButton>
                                        <ToggleButton value="1">あり</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            )}
                        />
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>

            <FetchHelper fetchId="GET_DEVICE" method="POST" onComplete={(success, data, error) => success ? handleSuccess(data) : handleError()} />
            <FetchHelper fetchId="UPDATE_DEVICE" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(data) : handleUpdateError()} />
        </>
    );
};


const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = {
    onGetDevice: (id: string) => actionCreators.fetch('GET_DEVICE', `/master/gateways/get?id=${id}`, "GET", null),
    onUpdate: (data: any) => actionCreators.fetch("UPDATE_DEVICE", `/master/gateways/edit`, 'POST', data, false, true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "装置変更",
            body: "装置を更新しました。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "装置変更",
            body: "装置を更新出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDevice as any);


