import { Chart } from "chart.js";

export const CreateDailyWeatherChart = (data) => {
    if (data.dataChart.length === 0) {
        return;
    };
    const maxValue = Math.max(...data.dataChart);
    const minValue = Math.min(...data.dataChart)
    const max = maxValue  - Math.abs(maxValue) % 10 + 10;
    const min = minValue > 0 ?  minValue - minValue % 10 : minValue - minValue % 10 - 10  ;
    var ctx = document.getElementById('weatherChart').getContext('2d');
    const data1 = {
        labels:data.labels,
        datasets:
        [
            {
                datalabels:data.dataChart,
                labels:data.labels,
                label:"気温",
                data:data.dataChart,
                pointHoverRadius: 15,
                pointHoverBorderWidth: 10,
                pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
                borderColor: '#1890ff',
                backgroundColor: '#1890ff',
            }
        ]
    }
    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: true,
                backgroundColor: 'rgba(242, 242, 242, 0.5)',
                bodyColor: '#1a1a1a',
                borderWidth: 1,
                borderColor: 'rgba(143, 190, 1, 0.7)',
                padding: 15,
                titleFont: {
                    size: 16,
                    weight: 'bold',
                },
                bodyFont: {
                    size: 14,
                },
                titleColor: '#1a1a1a',
                titleFontStyle: 'bold',
                usePointStyle: 'true',
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + "                   " + context.parsed.y + "°C";
                    },
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: Math.round,
                font: {
                    weight: 'bold'
                }
            }
            
        },
        interaction: {
            mode: 'index',
            intersect: false
        },
        scales: {
            x: {
                display: false,
                title:false,
            },
            y: {
                display: true,
                ticks: {
                    stepSize: 10,
                },
                title: {
                    display: false,
                },
                min:min,
                max:max,
            }
        }
    }
    Chart.register({
        id: 'customLabel',
        afterDatasetsDraw: function(chart) {
            var ctx = chart.ctx;
            ctx.save();
            if(chart.canvas.id == "weatherChart"){
                chart.data.datasets.forEach(function(dataset) {
                    if (!dataset.hidden) {
                        dataset.data.forEach(function(dataPoint, index) {
                            var x = chart.getDatasetMeta(0).data[index].x;
                            var y = chart.getDatasetMeta(0).data[index].y;
                            ctx.fillStyle = 'black';
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            ctx.fillText(dataPoint, x, y - 10);
                        });
                    }
                });
                ctx.restore();
            }
    
            
        }
    });
    
    new Chart(
        ctx,
        {
            type:"line",
            data:data1,
            options:options,
        })

}