import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Credential } from "../../models";
export type LoginFormProps = {
  handleDataLogin: (data: any) => void;
};

export const LoginForm = (props: LoginFormProps) => {
  const { handleDataLogin } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const showPasswordIcon = (visible: boolean) => {
    if (visible) {
      return <Visibility />
    }
    return <VisibilityOff />;
  };

  const initialValue: Credential = { username: "", password: "", rememberMe: "false" };
  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<Credential>({
    mode: 'all',
    criteriaMode: "all",
    defaultValues: initialValue,
  });
  return (
    <>
      <form onSubmit={handleSubmit(handleDataLogin)} style={{ width: '90%', margin: 'auto' }}>
        <Stack spacing={3}>

          <TextField
            {...register("username",
              {
                required: 'ユーザー名は必須です。',
              })
            }
            fullWidth
            autoComplete="username"
            type="email"
            label="ユーザー名"
            error={!!errors?.username}
            helperText={errors?.username?.message}
          />

          <TextField
            {...register("password",
              {
                required: 'パスワードは必須です。',
              })
            }
            fullWidth
            autoComplete="current-password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="パスワード"
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPasswordIcon(showPassword)}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}></Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
        // loading={isSubmitting}
        >
          ログイン
        </LoadingButton>
      </form>
    </>
  );
};

export default LoginForm;
