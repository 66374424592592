// material
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//
import palette from './palette';
import shadows, { customShadows } from './shadows';
import typography from './typography';

import '@fontsource/noto-sans';

// ----------------------------------------------------------------------
export type ThemeConfigProps = { children?: React.ReactNode }

export default function ThemeConfig(props: ThemeConfigProps) {
  // const themeOptions = useMemo(
  //   () => ({
  //     palette,
  //     shape: { borderRadius: 8 },
  //     typography,
  //     shadows,
  //     customShadows
  //   }),
  //   []
  // );

  const theme = createTheme(
    {
      palette,
      shape: { borderRadius: 8 },
      typography,
      shadows,
    },
    customShadows,
  );
  theme.components = {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Noto Sans';
          font-style:  normal;
          font-weight: 400;
          font-display: swap;
        }
      `,
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
