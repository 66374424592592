import { useEffect, useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
//
//import Iconify from './Iconify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { NavMenuItem } from '../../models';
import { customerLabelName } from '../../statics';
// ----------------------------------------------------------------------

export type NavItemProps = {
  item: NavMenuItem;
  active: Function;
  isCustomer:boolean;
};

function NavItem(props: NavItemProps) {
  const { item, active,isCustomer } = props;
  const theme = useTheme();
  const isActiveRoot = active(localStorage.getItem('lastActiveFormPath') ?? '', item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isCustomer ? false : true);

  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  const handleClickPath = (path: string) => {
    localStorage.setItem('lastActiveFormPath', path);
  }

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium'
  };

  const pageLoad = () => {
    localStorage.setItem('currentUrl', (window.location.pathname + window.location.search).slice(1));
  };
  useEffect(pageLoad, []);

  if (children !== undefined) {
    return (
      <>
        <ListItemButton
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot)
          }}
        >
          <ListItemText disableTypography primary={title} />
          {info && info}
          {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { key, title, path } = item;
              let isActiveSub = active(localStorage.getItem('lastActiveFormPath') ?? '', path);
              if(localStorage.getItem('lastActiveFormPath') == path){
                isActiveSub = true
              }
              return (
                <ListItemButton
                  key={key}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                  onClick={() => handleClickPath(path)}
                >
                  <ListItemText disableTypography primary={title} style={{ marginLeft: '30px' }} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemButton
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemButton>
  );
}

type NavSectionProps = {
  navConfig: NavMenuItem[];
};

export default function NavSection(props: NavSectionProps, { ...other }) {
  const { navConfig } = props;
  const match = (lastActivePath: string, path: string) => (path ? !!matchPath(lastActivePath, path) : false);

  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  return (
    <Box {...other}>
      <List disablePadding>
        {
          navConfig.map(function (item: NavMenuItem) {
            if (item.title !== `${customerLabelName}別`) {
              return <NavItem isCustomer={false} key={item.key} item={item} active={match} />
            }
            else {
              return <>
                <ListItemButton onClick={handleOpen}>
                  <ListItemText disableTypography primary={item.title} />
                  { item.info && item.info }
                  { open ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
                </ListItemButton>
                { open &&
                  <Stack style={{ marginLeft: '30px' }}>
                    {
                      item.children?.map((item: NavMenuItem) => 
                        <NavItem isCustomer={true} key={item.key} item={item} active={match} />
                      )
                    }
                  </Stack>
                }
              </>
            }
          })
        }
      </List>
    </Box>
  );
}
