import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, FormControlLabel, IconButton, Link, Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Customer, PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import PaginationCpn from '../../common/PaginationCpn';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { customerLabelName } from "../../../statics";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// -------------
// Props
export type DeviceJSPGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    keyword:string;
    onChangeSearchCondition: (name: string, value: any) => void;
    onSelect: (data: any) => void;
    openSearch: boolean;
    hiddenSelectFlag?:boolean;
};

// -------------
// Component
export const DeviceJSPGrid = forwardRef((props: DeviceJSPGridProps, ref) => {
    const { colState, pageSize, pageData, keyword, hiddenSelectFlag, onChangeSearchCondition, onSelect, openSearch } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword);
        const { customer_name, selected } = props.data;
        let isSelected = selected
        if (props.data.isSelect) {
            isSelected = true;
        }
        if(hiddenSelectFlag){
            return customer_name;
        }
        return (
            <FormControlLabel
                control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} onChange={() =>  handleChecked(props)} />}
                label={customer_name}
                labelPlacement="end"
            />
        );
    };

    const CellUrlRenderer = (props:any) => {
        const url = props.data.jsp_url;
        gridRef.current?.api?.setQuickFilter(keyword);
        return (
            <Link target="_blank" title={"旧版システムの画面表示用のリンク。"} href={url}>{url}</Link>
        )
    }

    const handleChecked = (props: any) => {
        onSelect(props.data)
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: colState ? colState[0].width : 500,
            cellRenderer: CellCheckRenderer,
        },
        {
            headerName: "装置名",
            field: "device_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[1].width : 500,
        },
        {
            headerName: "URL",
            field: "jsp_url",
            rowDrag: false,
            cellRenderer:CellUrlRenderer,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[2].width : 600,
        },
    ];

    const getRowStyle = (params:any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
              };
        }
        return {
            backgroundColor: 'white',
          };
    }

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 275px)' : 'calc(100vh - 230px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        getRowStyle={getRowStyle}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default DeviceJSPGrid;
