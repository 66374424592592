import {
    Chart as ChartJS,
    registerables
} from 'chart.js';
import { useEffect } from 'react';
import { createChart } from './CreateChart';
import { createDailyChart } from './CreateDailyChart';
import { CreateDailyChartLine } from './CreateDailyChartLine';
import { CreateDailyWeatherChart } from './CreateDailyWeatherChart';
import { createSolarPowerChart } from './CreateSolarPowerChart';
import { createEarthFaultChart } from './CreateEarthFaultChart';
export interface ChartResult {
    "chartId": number;
    "labels": any[] | null;
    "dataChart": any | null;
    "unit":any|null;
}

// export interface ChartModel {
//     "result": ChartResult | null;
// }

export type ChartPanelProps = {
    chartModel: ChartResult | undefined;
};

export const ChartPanel = (props: ChartPanelProps) => {
    const { chartModel } = props;
    ChartJS.register(
        ...registerables
    )

    useEffect(() => {
        if (chartModel?.chartId === 1) {
            createChart(chartModel);
        }
        if (chartModel?.chartId === 2) {
            createDailyChart(chartModel,chartModel?.unit);
        }
        if (chartModel?.chartId === 3) {
            CreateDailyChartLine(chartModel);
        }
        if(chartModel?.chartId === 4){
            CreateDailyWeatherChart(chartModel);
        }
        if(chartModel?.chartId === 5){
            createSolarPowerChart(chartModel);
        }
        if(chartModel?.chartId === 6){
            createEarthFaultChart(chartModel);
        }
    }, [chartModel]);

    return (
        <>
        {   (chartModel?.chartId === 4) &&
                <div style={{ marginTop: '0px', minHeight: '80%', minWidth: '100%' }} id='chartContainer'>
                <canvas id="weatherChart" height='40%'></canvas>
                </div>
        }
        { (chartModel?.chartId !== 4) &&
        <div style={{ marginTop: '40px', minHeight: '80%', minWidth: '60%' }} id='chartContainer'>
            
            {
                chartModel?.chartId === 1 && <canvas id="demandChart" height='250px'></canvas>
            }
            {
                (chartModel?.chartId === 2 || chartModel?.chartId === 3) && <canvas id="calendarChart" height='100%'></canvas>
            }
            {
                chartModel?.chartId === 5 && <canvas id="solarChart" height="250px"></canvas>
            }
            
        </div>
        }

        {
            chartModel?.chartId === 6 &&
            <div style={{ marginTop: '0px', maxHeight: '50%', minWidth: '60%' }} id='chartContainer'>
                <canvas id="earthFaultChart" height='100px'></canvas>
            </div>
        }
        </>
    );
};
export default ChartPanel;


