import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useForm } from "react-hook-form";
import { useCSVReader } from 'react-papaparse';
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationState, actionCreators } from "../../store";
import FetchHelper from "../FetchHelper";
import DeviceInformation from "../polling/device/DeviceInformation";
import { useDispatch } from "react-redux";


const getDevice = "getDevice";
const POWER_UPDATE = "POWER_UPDATE";
// Props
export type EbecListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
export const MonthlyPowerUpdate = (props: EbecListProps) => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const {onPowerUpdate, onErrorDevice,onGetDevice} = props;
    const { CSVReader } = useCSVReader();
    const { register, handleSubmit, formState: { errors }, control } = useForm<any>({
        mode: "all",
        criteriaMode: "all",
        // defaultValues: initialValue,
    });
    const [loadingFlag, setLoadingFlag] = React.useState<boolean>(false);
    const [runFlag, setRunFlag] = React.useState<boolean>(false);
    const [dataCSV, setDataCSV] = React.useState<any>([]);
    const [filePath, setFilePath] = React.useState('');
    const [selectCSV, setSelectCSV] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState('');
    const [location] = React.useState(useLocation());
    const [mainid] = React.useState(location.state ? location.state.mainid : null);
    const [dateSelect, setDateSelect] = React.useState(location.state.date);
    const [isError,setError] = React.useState<boolean>(false);
    const [clickCancelFlag, setClickCancelFlag] = React.useState(false);
    const [device, setDevice] = React.useState<any>();
    const [saveDataCSV, setSaveDataCSV] = React.useState<any[]>([]);

    React.useEffect(() => {
        setLoadingFlag(true);
        onGetDevice(mainid)
    },[])

    React.useEffect(() => {
        if(dateSelect !== null){
            setError(false);
        }
    },[dateSelect])

    const handleGetDevice = (data: any) => {
        setDevice(data);
        setLoadingFlag(false);
    };

    const handleErrorGetDevice = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
        onErrorDevice(success,data,error);
    }
    // Submitイベント
    const handleEditSubmit = (value: any) => {
        if (!isNaN(dateSelect.getTime())) {
            setSaveDataCSV([])
            setDataCSV([])
            setSelectCSV(false)
            setFilePath('')
            setSaveDataCSV([])
            setClickCancelFlag(false)
            setRunFlag(true)
            let csvdata = "";
            dataCSV.forEach((item:any,index:number) => {
                if(index == dataCSV.length - 1) {
                    csvdata += item.toString()
                }else{
                    csvdata += item.toString() + "\n"
                }
            })
            const date = new Date(dateSelect);
            const params = {
                mainid: mainid,
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                csvdata: csvdata,
            }
            onPowerUpdate(params)
            setMessage(`${message}装置ID[${mainid}] ${date.getFullYear()}年${date.getMonth() + 1}月の電力情報更新を開始します。\n`)
        }

    }

    const handleCancel = () => {
        if (!isNaN(dateSelect.getTime())) {
            dispatch({type:"SET_BACK_DATA",payload:{data:dateSelect}})
        }
        navigate(-1)
    }

    const handleRemoveFile =() => {
        setSaveDataCSV([])
        setDataCSV([])
        setMessage('')
        setSelectCSV(false)
        setFilePath('')
    }

    const handleSuccessPowerUpdate = (data:any) => {
        setRunFlag(false);
        const date = new Date(dateSelect);
        let new_message = "";
        if(data && data.result_code === 0)
            new_message = message + `装置ID[${mainid}] ${date.getFullYear()}年${date.getMonth() + 1}月の電力更新が完了しました。`
        else
            new_message = message + `装置ID[${mainid}] ${date.getFullYear()}年${date.getMonth() + 1}月の電力更新に失敗しました。\n${data.error_message}`
        setMessage(new_message)
    }

    const handleErrorPowerUpdate = (success:any, data:any, error:any) => {
        setRunFlag(false);
        const date = new Date(dateSelect);
        const new_message = message + `装置ID[${mainid}] ${date.getFullYear()}年${date.getMonth() + 1}月の電力更新に失敗しました。\n${error}`
        setMessage(new_message)
    }
    return (
        <>
            {(loadingFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <Typography variant="h6" >電力情報の更新</Typography>
                <DeviceInformation titleFlag={true} deviceInformation={device} mainid={mainid ?? ''} title="定期通報" isDisplayDeviceInfo={true} isShowMenuButton ={false} ></DeviceInformation>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={1}>
                    <Box sx={{ display: 'inline' }}>
                        <Box sx={{ display: 'inline-flex',mr:"10px", marginTop:"25px"}}>
                            <Typography sx={{ color: 'text.secondary' }}>更新対象年月:</Typography>
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    inputFormat="YYYY.MM"
                                    views={["year", "month"]}
                                    openTo="month"
                                    renderInput={(props: any) => (
                                        <TextField
                                            size="small"
                                            style={{ width: '125px', marginLeft: '-1px' }}
                                            error={!!isError}
                                            sx={{
                                                marginTop:"15px",
                                                fieldset: {
                                                    border: "1px solid rgba(24, 144, 255, 0.5)",
                                                    borderWidth: "1px 1px 1px 1px",
                                                    zIndex: "1",
                                                    borderRadius: "7px"
                                                }
                                            }}
                                            {...props} />
                                    )}
                                    value={dateSelect}
                                    onChange={(newValue: any) => {
                                        if(newValue !== null){
                                            setDateSelect(newValue.$d);
                                        }else{
                                            setError(true);
                                        }
                                    }}
                                    
                                    disableMaskedInput={false}
                                />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <CSVReader
                        config ={{
                            skipEmptyLines: true,
                        }}
                        
                        onUploadAccepted={(results: any, file: any) => {
                            let data = [] as any[]
                            for (let index = 0; index < results.data.length; index++) {
                                let isEmptyRow = results.data[index].find((item: any) => item.trim().length > 0);
                                if(isEmptyRow){
                                    data.push(results.data[index])
                                }
                            }
                            setDataCSV(data)
                            setFilePath(file.name)
                            setMessage("ファイル[" + file.name.replace(".csv", "") + "]を取り込みました。\n")
                            setSelectCSV(true)
                        }}
                    >
                        {({
                            getRootProps,
                            acceptedFile,
                        }: any) => (
                            <>
                                <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={8} md={9} style={{display: 'flex', alignItems: 'center'}}>
                                            <FormControl sx={{width:"100%" }} size="small" variant="outlined">
                                                <InputLabel htmlFor="filePath">ファイル</InputLabel>
                                                <OutlinedInput
                                                    {...getRootProps()}
                                                    id="filePath"
                                                    type={'text'}
                                                    readOnly={true}
                                                    sx={{ backgroundColor: 'floralwhite' }}
                                                    value={filePath}
                                                    endAdornment={
                                                    <>{selectCSV && <InputAdornment position="end">
                                                        <IconButton onClick={()=>{handleRemoveFile();}} disabled = {runFlag}>
                                                            <Close sx={{ fontSize: "25px", color: 'red', cursor: 'pointer' }} />
                                                        </IconButton>
                                                    </InputAdornment>}
                                                    </>
                                                    }
                                                    label="ファイル"
                                                    onClick={() => {}}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button variant="contained" {...getRootProps()} disabled = {runFlag}>ファイルを追加</Button>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </>
                        )}
                    </CSVReader>
                    <Stack direction={{ xs: 'column', sm: 'row' }} mt={3}>
                        <Grid container spacing={1} >
                            <Grid item width={'100%'}>
                                <TextField
                                    fullWidth
                                    name="result"
                                    label="更新状況表示"
                                    multiline
                                    rows={13}
                                    value={message}
                                    inputProps={
                                        { readOnly: true, }
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <LoadingButton variant="contained" type="submit" disabled = { !selectCSV } loading={runFlag}>実行</LoadingButton>
                        <Button color="inherit" variant="contained" onClick={handleCancel} disabled = {runFlag}>戻る</Button>
                        
                    </Stack>
                </form>
            </div>


            
            <FetchHelper fetchId={getDevice} onComplete={(success, data, error) => success ? handleGetDevice(data) : handleErrorGetDevice(success, data, error)} />
            <FetchHelper fetchId={POWER_UPDATE} onComplete={(success, data, error) => success ? handleSuccessPowerUpdate(data) : handleErrorPowerUpdate(success, data, error)} />
        </>
    )
}


const mapStateToProps = (state: ApplicationState) => ({

})
const mapDispatchToProps = {
    onGetDevice: (id: string | null) => actionCreators.fetch(getDevice, `/master/gateways/get?id=${id}`, "GET", null),
    onPowerUpdate: (params:any) => actionCreators.fetch(POWER_UPDATE,`/telemetry/set?code=GAUP1`,"POST",params,false,true),
    onErrorDevice: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "装置検索",
            body: "装置を検索できません。" + error,
        }),
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlyPowerUpdate as any);