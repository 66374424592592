import { TableRows } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Collapse, Grid, IconButton, TableCell, TableRow } from "@mui/material";
import React from "react";
export type CollapseItemProps = {
    id:any;
    data:any;
    onShowDetails: (value: string, redirectId: string) => void;
    index:number;
};
export const CollapseItem = (props: CollapseItemProps) => {
    const {id, data, index, onShowDetails} = props;
    const [openDetailField,setOpenDetailField] = React.useState(false);
    const handleOpenProps = () => {
        setOpenDetailField((prev: boolean) => !prev);
    }

    return(
        <>
            {
                data && data[0] && data[0]["customer_name"] &&
                <TableRow style={{borderTop:index != 0 ? "0.5px solid grey" :"0px"}}>
                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px",width:"110%",border:"0px"}} >{data[0]["customer_name"]}
                    </TableCell>
                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px",border:"0px"}}>
                        {openDetailField ? 
                            <IconButton style={{fontSize:"30px",marginLeft:"17px",width:"20%"}}  onClick={handleOpenProps}><ArrowDropDownIcon /></IconButton> : 
                            <IconButton style={{fontSize:"30px",marginLeft:"17px",width:"20%"}} onClick={handleOpenProps}><ArrowRightIcon /></IconButton>
                    }</TableCell>
                </TableRow>
            }
            
            <Collapse  style={{width: "100%"}} in={openDetailField} timeout="auto" unmountOnExit >
                {
                    data &&
                    <>
                        {data.map((item:any) => {
                            return<>
                                <TableRow style={{borderTop:"0.5px solid grey"}}>
                                        <TableCell style={{width:"100%",paddingTop:"8px",paddingBottom:"8px",border:"0px"}}><p style={{marginLeft:"30px"}}>{item.id}</p></TableCell>
                                        <TableCell style={{width:"20%",paddingTop:"8px",paddingBottom:"8px",border:"0px"}}>
                                                <Grid container direction="row" justifyContent="flex-start" paddingLeft={"-60px"}>
                                                    <IconButton aria-label="edit" style={{ color: 'white', backgroundColor: '#1890ff', borderRadius: '3px',marginRight:"15px" }} onClick={() =>{
                                                        onShowDetails(item, "smartDemand");
                                                    }} >
                                                        <TimelineIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                        </TableCell>
                                </TableRow>
                            </>
                        })}
                    </>
                }
            </Collapse>
        </>
    )
}

export default CollapseItem;
