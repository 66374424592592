import CachedIcon from '@mui/icons-material/Cached';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Breakpoint, alpha, styled } from "@mui/material/styles";
import { MouseEventHandler, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { store } from "..";
import useResponsive from '../hooks/useResponsive';
import { OptionItem } from "../models";
import { AppIcon, Menu } from "./../components/app/AppIcon";
// ----------------------------------------------------------------------
// 検索フォーム
export interface ItemForm {
    settingView:any;
}
export const viewSettingOption:OptionItem[] = [
    {
        value:"速報",
        label:"速報",
        attribute:"1",
    },
    {
        value:"死活監視",
        label:"死活監視",
        attribute:"2",
    },
    {
        value:"装置別当日発電量",
        label:"装置別当日発電量",
        attribute:"3",
    },
    {
        value:"現在の電力使用率",
        label:"現在の電力使用率",
        attribute:"4",
    },
    {
        value:"当月発電実績",
        label:"当月発電実績",
        attribute:"5",
    },
    {
        value:"デマンド監視",
        label:"デマンド監視",
        attribute:"6",
    },
    {
        value:"今月のデマンド超過",
        label:"今月のデマンド超過",
        attribute:"7",
    },
    {
        value:"太陽光発電状況",
        label:"太陽光発電状況",
        attribute:"8",
    },
    {
        value:"天気情報",
        label:"天気情報",
        attribute:"9",
    }
]
const RootStyle = styled("div")(({ theme }) => ({
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    // [theme.breakpoints.up("lg")]: {
    //     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    // },
    // [theme.breakpoints.up("xl")]: {
    //     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    // },
}));
// ----------------------------------------------------------------------

type DashboardNavbarProps = {
    onOpenSidebar: MouseEventHandler | undefined;
    openMenu: boolean;
};

export const DashboardNavbar = (props: DashboardNavbarProps) => {
    const { onOpenSidebar, openMenu } = props;
    const dispatch = useDispatch();
    const isRefresh = store.getState().app.isRefresh;
    const isDesktop = useResponsive({ query: 'up', key: 'lg' as Breakpoint });
    const isDashboard = localStorage.getItem('title')?.endsWith("@Dashboard");
    const dayJapan = ["日", "月", "火", "水", "木", "金", "土"];
    const [currentDateData, setCurrentDateDate] = useState<Date>(new Date());
    const [disableButtonRefresh,setDisableButtonRefresh] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);
    const [user_id] = useState(store.getState()?.app?.user?.cognito_username);
    const isMobile = useResponsive({ query: 'down', key: "sm" as Breakpoint});
    const isMobileL = useResponsive({ query: 'between',start:410,end:435,key:"sm" as Breakpoint});
    const isMobileS = useResponsive({ query: 'between',start:300,end:375, key: "sm" as Breakpoint});
    const isTablet = useResponsive({ query: 'between',start:435,end:768,key:"sm" as Breakpoint});

    const formatDateTime = (date:Date) => {
        const fullYearString = date.getFullYear() + "年";
        const monthString = (date.getMonth() + 1 < 10 ? "0" + (Number(date.getMonth() + 1)) : date.getMonth() + 1) + "月";
        const dateString = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
        const day = "(" + dayJapan[date.getDay()] + ") ";
        const hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        return fullYearString + monthString + dateString + day + hour + ":" + minutes;
    }

    useEffect(() => {
        if(localStorage.getItem("title") =="ダッシュボード@Dashboard"){
            setCurrentDateDate(new Date());
        }
    },[localStorage.getItem("title")])

    const { formState: { errors }, control, setValue, getValues } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
    });

    const handleUpdateSetting = () => {
        const settingView = JSON.parse(localStorage.getItem("settingView")!);
        const settingViewByUserId = settingView.find((item:any) => item.user_id == user_id);
        settingViewByUserId["settingView"] = getValues().settingView;
        localStorage.setItem("settingView",JSON.stringify(settingView));
        dispatch({type:"SET_VIEW_SETTING_DASHBOARD",payload:{data:settingViewByUserId["settingView"]}});
        setOpenSetting(false);
    }

    const handleOpenSettingView = () => {
        setOpenSetting(true);
        const data = localStorage.getItem("settingView");
        if(data){
            const list_data = JSON.parse(data);
            const object_data_by_user_id = list_data.find((item:any) => item?.user_id == user_id)
            if(object_data_by_user_id){
                setValue("settingView",object_data_by_user_id.settingView);
            }else{
                setValue("settingView",[])
            }
        }else{
            setValue("settingView",[])
        }
    }

    const desktopStyles = {
        color: 'black'
    }

    const menuStyles = {
        margin: '18px',
        color: 'black'
    }

    return (
        <RootStyle>
            <div style={{ display: "flex", height: "56px",width:"100%" }}>
                <div style={{ display: "flex", alignItems: "center",width:"100%" }}>
                    <IconButton
                        onClick={onOpenSidebar}
                        sx={{ mr: 1, color: "text.primary", display: (!isDesktop || !openMenu) ? '' : 'none' }}
                    >
                        <AppIcon muiName={Menu} />
                    </IconButton>
                    <Stack direction="row" alignItems="center" style={(!isDesktop || !openMenu) ? desktopStyles : menuStyles}>
                        
                        {isDashboard ? 
                            <>
                                {isMobile ?
                                <>
                                    <p style={{fontWeight:"bold",fontSize:isTablet ? "17.5px" : isMobileL ?  "13px" : isMobileS ? "10px" : "11px"}}>{localStorage.getItem("title")?.replace("@Dashboard",``)}</p>
                                    <p style={{fontWeight:"bold",fontSize:isTablet ? "17.5px" : isMobileL ?  "13px" : isMobileS ? "10px" : "11px" ,marginLeft:isMobileL ? "20px" : isMobileS ? "10px" : "10px"}}>{formatDateTime(new Date())}</p>
                                </>
                                :
                                <>
                                    <Typography variant="h4">
                                        {localStorage.getItem("title")?.replace("@Dashboard",``)}
                                    </Typography>
                                    <Typography variant="h4" ml={10}>
                                        {formatDateTime(currentDateData)}
                                    </Typography>
                                </>
                                }
                                
                            </>
                             : 
                             <Typography variant="h4">
                                {localStorage.getItem('title')}
                             </Typography>
                            }
                        
                    </Stack>
                    
                </div>
                {isDashboard &&
                    <>
                        <div style={{margin:"auto",marginRight: isMobileL ? "20px" : isMobileS ? "0px" : "10px"}}>
                            <IconButton onClick={handleOpenSettingView} size="small" style={{color:"#1890ff"}} children={<DashboardCustomizeIcon />} />
                        </div>
                        <div style={{margin:"auto",marginRight: isMobileL ? "20px" : isMobileS ? "0px" : "10px"}}>
                            <IconButton onClick={() => {
                                setDisableButtonRefresh(true);
                                setTimeout(() => {
                                    setCurrentDateDate(new Date())
                                    dispatch({type:"SET_IS_REFRESH_DASHBOARD",payload:{isRefresh:true}})
                                    setDisableButtonRefresh(false);
                                },1000)
                            }} size="small" disabled={disableButtonRefresh || isRefresh} children={<CachedIcon style={{ color: (isRefresh || disableButtonRefresh) ? "gray" : '#1890ff'}}/>}></IconButton>
                        </div>
                    </>
                }
            </div>
            {openSetting &&
                <div>
                    <Dialog
                        open={true}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth={"md"} fullWidth
                    >
                    <DialogTitle id="alert-dialog-title">
                        {"ダッシュボード表示項目編集"}
                    </DialogTitle>
                    <DialogContent style={{paddingBottom:"0px", paddingTop:"10px", overflow:"hidden"}}>
                        <Controller
                            control={control}
                            name="settingView"
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    disableCloseOnSelect
                                    fullWidth
                                    multiple
                                    defaultValue={undefined}
                                    size="small"
                                    options={viewSettingOption}
                                    isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, values) => {
                                        field.onChange(values)
                                    }}
                                    renderOption={(field, option, { selected }) => (
                                        <li {...field}>
                                            <Checkbox
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} label="識別子の一覧"/>
                                    )}
                                />
                            )}
                        />
                    </DialogContent>
                    
                    <DialogActions>
                        <Button onClick={handleUpdateSetting} variant="contained">反映</Button>
                        <Button onClick={() => setOpenSetting(false)} color="inherit" variant="contained">キャンセル</Button>
                    </DialogActions>
                    </Dialog>
                </div>
            }
        </RootStyle>
    );
};

export default DashboardNavbar;
