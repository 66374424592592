import { Button, Grid, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { LocationNameModel } from "../../../models";
import { formatDateTime } from "../../../utils";

export type LocationNameEditFormProps = {
    locationName: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const LocationNameEdit = (props: LocationNameEditFormProps) => {
    const { locationName, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: LocationNameModel = {
        mainid: locationName.mainid,
        deviceNo: locationName.deviceNo,
        locationName: locationName.locationName,
        lastGetDatetime: locationName.lastGetDatetime,
    }

    const { register, handleSubmit, formState: { errors } } = useForm<LocationNameModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: LocationNameModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: LocationNameModel) => {

        let request = {
            mainid: formValue.mainid,
            device_no: formValue.deviceNo,
            location_name: formValue.locationName,
            last_get_datetime: formatDateTime(new Date()),
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '90%', margin: 'auto' }}>
                    <Stack spacing={3}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} paddingTop={"10px"}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("locationName",
                                        { required: '必須項目です。入力してください。' })
                                    }
                                    fullWidth
                                    type="text"
                                    label="LocationName"
                                    size="small"
                                    error={!!errors?.locationName}
                                    helperText={errors?.locationName?.message}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>
                        </Stack>
                    </Stack>

                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        <Button variant="contained" type="submit">保存</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default LocationNameEdit;


