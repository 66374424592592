import { Chart } from "chart.js";
import { formatNumber } from "../../utils"; 

export const createDailyChart = (data,label) => {
    if (data.labels.length === 0 || data.dataChart.length === 0) {
        return;
    };
    const unit = label ? label : "kWh";
    new Chart(
        document.getElementById("calendarChart"),
        {
            type: 'bar',
            data: {
                labels: data.labels,
                datasets: [
                    {
                        label: '使用電力',
                        data: data.dataChart,
                        borderColor: '#1890ff',
                        backgroundColor: '#1890ff',
                        pointHoverRadius: 10,
                        pointHoverBorderWidth: 5,
                        pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
                    }
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        displayColors: true,
                        backgroundColor: 'rgba(242, 242, 242, 0.5)',
                        bodyColor: '#1a1a1a',
                        borderWidth: 1,
                        borderColor: 'rgba(143, 190, 1, 0.7)',
                        padding: 15,
                        titleFont: {
                            size: 16,
                            weight: 'bold',
                        },
                        bodyFont: {
                            size: 14,
                        },
                        titleColor: '#1a1a1a',
                        titleFontStyle: 'bold',
                        usePointStyle: 'true',
                        callbacks: {
                            label: function (context) {
                                //Return value for label
                                return "合計値: " + formatNumber(context.parsed.y) + " " + unit;
                            },
                        },
                    },
                },
                // interaction: {
                //     mode: 'index',
                //     intersect: false,
                // },
                scales: {
                    x: {
                        display: true,
                    },
                    y: {
                        display: true,
                        ticks: {
                            stepSize: '5',
                            callback: function (value, index, ticks) {
                                if (ticks[index].value % 250 === 0) {
                                    return formatNumber(ticks[index].value);
                                } else
                                    return '';
                            },
                        },
                        title: {
                            display: true,
                            text: `(${unit})`,
                            font: {
                                size: 16,
                            }
                        },
                        grace: '10%',
                    }
                }
            },
        });
}