
export type WeathersDayHeaderProps = {
    date: Date,
    data:any,
};

export const WeathersDayHeader = (props: WeathersDayHeaderProps) => {
    const { date,data } = props;
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const dayJapan = ["日", "月", "火", "水", "木", "金", "土"];
    const bgColor = "rgb(189,215,238)";
    return (
        <>
                    <tr>
                        <td className={'sticky-col first-col'} colSpan={1} rowSpan={1} style={{ minWidth: '55px', backgroundColor: bgColor, verticalAlign: 'middle',textAlign:"center"}}>日付</td>  
                        <td colSpan={24}  style={{backgroundColor: bgColor,textAlign:"center"}}>{year}年{month+1}月{day}日({dayJapan[date.getDay()]})</td>
                    </tr>
                    <tr>
                        <th colSpan={1} className={'sticky-col first-col'} style={{ minWidth: '120px',textAlign:"center",backgroundColor:"white"}}>時刻</th>
                        {(!data ||data.hours_data.length == 0) &&
                        [...Array(24)].map((x, i) =>
                        <td rowSpan={1} style={{ backgroundColor:"white",textAlign:"center"}}>{Number(i)}</td>)
                        }
                        {
                            data && data.hours_data.length > 0 &&
                            [...Array(24)].map((x, i) =>
                            <>
                            <td rowSpan={1} style={{ backgroundColor:data.hours_data[i].source_type == 0?"#FFFFEF":"white",textAlign:"center"}}>{Number(i)}</td>
                            </>)
                        }
                    </tr>            
        </>
    );
};

export default WeathersDayHeader;
