import * as React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import HandymanIcon from '@mui/icons-material/Handyman';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import MenuIcon from '@mui/icons-material/Menu';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const Dashboard:string = 'Dashboard';
export const EquipmentList:string = 'EquipmentList';
export const SystemSettings:string = 'SystemSettings';
export const Maintenance:string = 'Maintenance';
export const Develop:string = 'BuildCircleIcon';
export const Menu:string = 'Menu';
export const AccountBox:string = 'AccountBox'
export const AccountCircle:string = 'AccountCircle';


export type AppIconProps = { muiName: string }

export const AppIcon = (props: AppIconProps) => {
  const { muiName } = props;

  const getIcon = (name: string) => {
    switch(name){
      case Dashboard:
        return <DashboardIcon/>;
      case EquipmentList:
        return <FactCheckIcon/>;
      case SystemSettings:
        return <SettingsApplicationsIcon/>;
      case Maintenance:
        return <HandymanIcon/>;
      case Develop:
        return <BuildCircleIcon/>
      case Menu:
        return <MenuIcon/>
      case AccountBox:
        return <AccountBox />
      case AccountCircle:
        return <AccountCircle/>

    }
    return undefined;
  };

  return (
    <React.Fragment>
      {getIcon(muiName)}
    </React.Fragment>
  );
};
