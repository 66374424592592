import React from 'react';

export const DefaultLoadingMessage: string = "読み込み中...";

const LoadingOverlay = (props:any) => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: 'lightsteelblue', height: '9%' }}
    >
      <i className="fas fa-hourglass-half"> {props.loadingMessage} </i>
    </div>
  );
};

export default LoadingOverlay;