import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Button, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { OptionItem } from "../../../models";

export type WeathersEditFormProps = {
    weatherModel?:any;
    onOK: (data: any) => void;
    onCancel: () => void;
    customerList: OptionItem[];
};

// 検索フォーム
export interface ItemForm {
    "id":number,
    "address_in": string;
    "address_out": string;
    "latitude": string;
    "longitude":string;
    "collect_flag": number;
    "ob_point_name":string;
}



export const WeathersEdit = (props: WeathersEditFormProps) => {
    const { onOK, onCancel, customerList,weatherModel } = props;

    // 初期値設定
    const initialValue: ItemForm = {
        id:weatherModel.id,
        address_in:weatherModel.address_in,
        address_out:weatherModel.address_out,
        latitude:weatherModel.latitude,
        longitude:weatherModel.longitude,
        collect_flag:Number(weatherModel.collect_flag),
        ob_point_name:weatherModel.ob_point_name,
    }

    const { register, handleSubmit, formState: { errors }, control, setValue, getValues,trigger  } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: any) => {
        let request = {
            ob_point_name:formValue.ob_point_name,
            id:formValue.id,
            collect_flag: formValue.collect_flag
            } as any
        return request;
    };

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeRestricted = (e: any, value: any) => {
        if (value != null) {
            if (value !== '1'){
                setValue('collect_flag', 0);
            }
            setValue("collect_flag", value);
        }
    };

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                <Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name="ob_point_name"
                                    rules={{ required: "必須項目です。入力してください。", }}
                                    render={({ field }) => (
                                        <Grid container spacing={1} direction="row">
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="観測地点名"
                                                size="small"
                                                inputProps={{
                                                    maxLength:255
                                                }}
                                                error={!!errors?.ob_point_name}
                                                helperText={errors?.ob_point_name?.message}
                                            />
                                        </Grid>
                                    )}
                                />
                            </FormControl>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name="address_in"
                                    rules={{ required: "必須項目です。入力してください。", }}
                                    render={({ field }) => (
                                        <Grid container spacing={1} direction="row">
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="住所"
                                                size="small"
                                                style={{
                                                    backgroundColor:"floralwhite"
                                                }}
                                                inputProps={{
                                                    readOnly:true,
                                                    maxLength:255
                                                }}
                                                error={!!errors?.address_in}
                                                helperText={errors?.address_in?.message}
                                                onChange={(e:any)=>{
                                                    setValue("address_in",e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    )}
                                />
                            </FormControl>
                        </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name="address_out"
                                        render={({ field }) => (
                                            <Grid container spacing={1} direction="row">
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="住所(確認用)"
                                                    size="small"
                                                    inputProps={{
                                                        readOnly:true,
                                                        maxLength:255
                                                    }}
                                                    style={{
                                                        backgroundColor:"floralwhite"
                                                    }}
                                                    error={!!errors?.address_out}
                                                    helperText={errors?.address_out?.message}
                                                />
                                            </Grid>
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name="latitude"
                                        render={({ field }) => (
                                            <Grid container spacing={1} direction="row">
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="緯度"
                                                    size="small"
                                                    inputProps={{
                                                        readOnly:true
                                                    }}
                                                    style={{
                                                        backgroundColor:"floralwhite"
                                                    }}
                                                    error={!!errors?.latitude}
                                                    helperText={errors?.latitude?.message}
                                                />
                                            </Grid>
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name="longitude"
                                        render={({ field }) => (
                                            <Grid container spacing={1} direction="row">
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="経度"
                                                    size="small"
                                                    inputProps={{
                                                        readOnly:true
                                                    }}
                                                    style={{
                                                        backgroundColor:"floralwhite"
                                                    }}
                                                    error={!!errors?.latitude}
                                                    helperText={errors?.latitude?.message}
                                                />
                                            </Grid>
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                        <Typography style={{ marginLeft: '5px' } } mt={1.5}>収集有無</Typography>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px', marginTop: '10px' }}>
                            <Controller
                                control={control}
                                name="collect_flag"
                                render={({ field: { value } }) => (
                                    <Grid container spacing={1} direction="row" >
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={value}
                                            exclusive
                                            onChange={(e, value) => handleChangeRestricted(e, value)}
                                            aria-label="Platform"
                                            size="small"
                                            aria-labelledby="権限"
                                        >
                                            <ToggleButton value={0}>なし</ToggleButton>
                                            <ToggleButton value={1}>あり</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
};

export default WeathersEdit;


