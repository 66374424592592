import { useEffect, useState } from "react";
import { formatNumber, formatRoundDownDecimal } from "../../utils";

export interface calendarItem {
    day: string;
    date: string;
    day_kwh: any;
    day_amount:any;
    data: any[];
}

export type Calendar2Props = {
    calendarItem: calendarItem,
    mode: string
    // kw
    max_kw_by_month?:any;
    final_day_max_kw?:any;
    final_time_max_kw?:any;
    // kwh 
    max_total_kwh_by_month?:any;
    max_kwh_by_month?:any;
    final_day_max_kwh?:any;
    final_hour_max_kwh?:any;


    // money
    max_total_money_by_month?:any;
    max_money_by_month?:any;
    jpy_per_kwh?:any;


};

export const Calendar2 = (props: Calendar2Props) => {
    const {  
        final_day_max_kw, final_time_max_kw, max_kw_by_month,
        max_total_kwh_by_month, max_kwh_by_month,final_day_max_kwh,final_hour_max_kwh,
        max_total_money_by_month,max_money_by_month,jpy_per_kwh
        , calendarItem, mode } = props;
    const [maxKw,setMaxKw] = useState(0);
    const [maxKwHour,setMaxKwHour] = useState(0);
    const [kw0Flag,setKw0Flag] = useState<boolean>();
    const [maxKwhDay,setMaxKwhDay] = useState<any>();
    const [maxAmountDay,setMaxAmountDay] = useState<any>();
    
    const getStyleColor = (max_value: any,value:any) => {
        if(value === '-' ||  value === '0'){
            return 'white';
        }
        return `rgba(255,0,0,${value/max_value})`;
    }

    const getFontWeight = (max_value:any,value:any) => {
        if(value && max_value){
            if(value == max_value)
                return "bold";
            else{
                return "normal";
            }
        }else{
            return "normal";
        }
    }

    

    const getkwhValue = (data: any) => {
        let result = [] as any[];
        if (data.length > 0) {
            for (let index = 0; index < 24; index++) {
                let value = data.filter((x : any) => x.hour === index)
                if(value.length > 0){
                    result.push({kwh: value[0].kwh !== null ? value[0].kwh : "-", kw0: value[0].kw0 !== null ? value[0].kw0 : "-", kw30: value[0].kw30 !== null ? value[0].kw30 : "-",hour: value[0].hour !== null ? value[0].hour : "-"})
                }else{
                    result.push({kwh: "-", kw0: "-", kw30: "-",hour:"-"})
                }
                
            }
        }
        return result;
    }

    const getamountValue = (data: any) => {
        let result = [] as any[];
        if (data.length > 0) {
            for (let index = 0; index < 24; index++) {
                let value = data.filter((x : any) => x.hour === index)
                if(value.length > 0){
                    result.push({amount: value[0].amount !== null ? value[0].amount : "-", kw0: value[0].kw0 !== null ? value[0].kw0 : "-", kw30: value[0].kw30 !== null ? value[0].kw30 : "-"})
                }else{
                    result.push({amount: "-", kw0: "-", kw30: "-"})
                }
            }
        }
        return result;
    }

    const kwhValue:any = getkwhValue(calendarItem.data);
    const colorKw = getStyleColor(max_kw_by_month,calendarItem.day_kwh);
    const colorKWh = getStyleColor(max_total_kwh_by_month,calendarItem.day_kwh)
    const colorMoney = getStyleColor(max_total_money_by_month,calendarItem.day_amount)
    const amount = getamountValue(calendarItem.data);

    useEffect(()=>{
        if(final_time_max_kw)
        {
            const time = final_time_max_kw.split("h");
            setMaxKwHour(time[0]);
            if(time[1] === "30"){
                setKw0Flag(false);
            }else if(time[1] === "00"){
                setKw0Flag(true)
            }
        }
        if(calendarItem.data.length > 0){
            const max_kw0 = calendarItem.data.reduce((a:any,b:any)=>a.kw0>b.kw0?a:b).kw0; 
            const max_kw30 = calendarItem.data.reduce((a:any,b:any)=>a.kw30>b.kw30?a:b).kw30; 
            const max_kw = Math.max(max_kw0,max_kw30);
            setMaxKw(max_kw);
            const max_kwh = calendarItem.data.reduce((a:any,b:any)=>a.kwh>b.kwh?a:b).kwh; 
            setMaxKwhDay(max_kwh);
            const maxAmount = formatNumber((jpy_per_kwh*max_kwh).toString());
            setMaxAmountDay(Math.floor(Number(maxAmount)));
            // const max_amount_in_month = kwhValue.reduce((a:any,b:any)=>a.day_amount>b.day_amount?a:b).day_amount; 
            // setMaxKwhMonth(max_kwh_in_month);
            // setMaxKwMonth(max_kw_in_month);
            // setMaxAmountMonth(max_amount_in_month)
        }
        
    },[]);

    const styleDayKw =  {
        textAlign: calendarItem.day_kwh === "-" ? 'center' as const : 'right' as const,
        paddingRight: calendarItem.day_kwh === "-" ? '0px' : '2px',
        background: colorKw,
        fontWeight: calendarItem.day_kwh == max_kw_by_month ? "bold" : "normal",
    }
    const styleDayKwh =  {
        textAlign: calendarItem.day_kwh === "-" ? 'center' as const : 'right' as const,
        paddingRight: calendarItem.day_kwh === "-" ? '0px' : '2px',
        background: colorKWh,
        fontWeight: calendarItem.day_kwh == max_total_kwh_by_month ? "bold" : "normal",
    }
    const styleDayMoney = {
        textAlign: calendarItem.day_amount === "-" ? 'center' as const : 'right' as const,
        paddingRight: calendarItem.day_amount === "-" ? '0px' : '2px',
        background: colorMoney,
        fontWeight: calendarItem.day_amount == max_total_money_by_month ? "bold" : "normal",
    }
    return (
        <tr>
            {mode === 'calendar1' &&
                <>
                    <td className={'sticky-col first-col'} style={{backgroundColor:"white",padding:"0px",margin:"0px"}}>
                        <div style={styleDayKwh}>
                            {formatRoundDownDecimal(calendarItem.day_kwh,0)}
                        </div>
                    </td>
                    <td className={'sticky-col second-col'} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccccff'}}>{calendarItem.date}</td>
                    <td className={'sticky-col third-col'} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccccff', width: '15px'}}>{calendarItem.day}</td>
                    {[...Array(24)].map((x, i) =>
                        <>
                            {calendarItem.data.length > 0 ? (
                                <td style={{ textAlign: 'center', backgroundColor: getStyleColor(max_kwh_by_month,kwhValue[i].kwh),
                                fontWeight:getFontWeight(maxKwhDay,kwhValue[i].kwh)
                                ,color: (kwhValue[i].hour == final_hour_max_kwh && calendarItem.date == final_day_max_kwh)?"white":"black"}}>{formatRoundDownDecimal(kwhValue[i].kwh,0)}</td>
                            ):(
                                <td style={{ textAlign: 'center', backgroundColor: "white"}}>-</td>
                            )}
                        </>
                        
                    )}
                </>
            }

            {mode === 'calendar2' &&
                <>
                    <td className={'sticky-col first-col'} style={{backgroundColor:"white",padding:"0px",margin:"0px"}}>
                        <div style={styleDayKw}>
                            {formatRoundDownDecimal(calendarItem.day_kwh,0)}
                        </div>
                    </td>
                    <td className={'sticky-col second-col'} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccccff'}}>{calendarItem.date}</td>
                    <td className={'sticky-col third-col'} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccccff', width: '15px'}}>{calendarItem.day}</td>
                    {[...Array(48)].map((x, i) =>
                        <>
                            {calendarItem.data.length > 0 ? (
                                <>
                                    {i % 2 === 0 ? (
                                        <td style={{ textAlign: 'center', backgroundColor: getStyleColor(max_kw_by_month,kwhValue[parseInt((i/2).toString())].kw0)
                                        ,fontWeight:getFontWeight(maxKw,kwhValue[parseInt((i/2).toString())].kw0)
                                        ,color:calendarItem.date == final_day_max_kw && kwhValue[parseInt((i/2).toString())].hour == maxKwHour && kw0Flag == true ? "white" :"black"}}>{formatRoundDownDecimal(kwhValue[parseInt((i/2).toString())].kw0,0)}</td>
                                    ):(
                                        <td style={{ textAlign: 'center', backgroundColor: getStyleColor(max_kw_by_month,kwhValue[parseInt((i/2).toString())].kw30)
                                        ,fontWeight: getFontWeight(maxKw,kwhValue[parseInt((i/2).toString())].kw30)
                                        ,color:calendarItem.date == final_day_max_kw && kwhValue[parseInt((i/2).toString())].hour == maxKwHour && !kw0Flag ? "white" :"black"
                                    }}>{formatRoundDownDecimal(kwhValue[parseInt((i/2).toString())].kw30,0)}</td>
                                    )}
                                </>
                            ):(
                                <td style={{ textAlign: 'center', backgroundColor: 'white'}}>-</td>
                            )}
                        </>
                    )}
                </>
            }
            {mode === 'calendar3' &&
                <>
                    <td className={'sticky-col first-col'} style={{backgroundColor:"white",padding:"0px",margin:"0px"}}>
                        <div style={styleDayMoney}>
                        {formatNumber(calendarItem.day_amount)}
                        </div>
                    </td>
                    <td className={'sticky-col second-col'} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccccff'}}>{calendarItem.date}</td>
                    <td className={'sticky-col third-col'} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccccff', width: '15px'}}>{calendarItem.day}</td>
                    {[...Array(24)].map((x, i) =>
                        <>
                            {calendarItem.data.length > 0 ? (
                                <td style={{ textAlign: 'center', backgroundColor: getStyleColor(max_money_by_month,amount[i].amount),
                                fontWeight:getFontWeight(maxAmountDay,amount[i].amount)
                                ,color: (kwhValue[i].hour == final_hour_max_kwh && calendarItem.date == final_day_max_kwh)?"white":"black" }}>{formatNumber(amount[i].amount)}</td>
                            ):(
                                <td style={{ textAlign: 'center', backgroundColor: 'white'}}>-</td>
                            )}
                        </>
                        
                    )}
                </>
            }
        </tr>
    );
};

export default Calendar2;
