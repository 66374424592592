import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Autocomplete, Button, Checkbox, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { OptionItem } from "../../../models";
import { BussinessOperationMonitoringRoleValue, CodeRestrictionArrayOptions, GwAuthOptions, MobileSmartCustomerRoleValue, MonitoringOperatorCustomerRoleValue, SmartCustomerRoleValue, SmartObserveArrayOptions, customerLabelName } from "../../../statics";

export type UsersEditFormProps = {
    onOK: (data: any) => void;
    onCancel: () => void;
    user: any;
    customerList: any;
    roleList: OptionItem[];
    authRoleMap: any;
    currentLoginUser:any;
};

let companyData: OptionItem[] = [
    {
        value: "1",
        label: "〇〇株式会社１",
        attribute: ""
    },
    {
        value: "2",
        label: "〇〇株式会社２",
        attribute: ""
    },
    {
        value: "3",
        label: "〇〇株式会社３",
        attribute: ""
    }
];

let deptData: OptionItem[] = [
    {
        value: "1",
        label: "□□部署１",
        attribute: ""
    },
    {
        value: "2",
        label: "□□部署２",
        attribute: ""
    }
];

// 検索フォーム
export interface ItemForm {
    "custom_company": OptionItem | null,
    "email": string;
    "cognito_username": string;
    // "custom_employee_number": string;
    "family_name": string;
    "given_name": string;
    "custom_role": any,
    "users_customer_array": OptionItem[],
    "code_restriction_array": OptionItem[],
    "custom_department": OptionItem | null;
    "custom_system_manage_flag": string;
    "code_restriction_flag": string;
    "smart_observer_options": OptionItem[];
    restriction_auth_device_flag:string;
    gw_edit_auth_array:OptionItem[];
    company_group_name:string;
    company_group_id:string|null;
}

export const UsersEdit = (props: UsersEditFormProps) => {
    const { onOK, onCancel, user, customerList, roleList, authRoleMap, currentLoginUser } = props;
    const [companyList] = React.useState<OptionItem[]>(companyData);
    const [deptList] = React.useState<OptionItem[]>(deptData);
    const [optionCodeRestriction] = React.useState<OptionItem[]>(CodeRestrictionArrayOptions);
    const [isRestricted, setIsRestricted] = React.useState(user.code_restriction_array?.length > 0);
    const [isRestrictedAuthDevice, setIsRestrictedAuthDevice] = React.useState(user.gw_edit_auth_array?.length > 0);
    const [isSmartCustomer,setSmartCustomerRole] = React.useState<boolean>(user?.custom_role == SmartCustomerRoleValue);
    const [isMobileSmartCustomer,setMobileSmartCustomer] = React.useState<boolean>(user?.custom_role == MobileSmartCustomerRoleValue);
    const [isMonitorOperatorCustomer,setIsMonitorOperatorCustomer] = React.useState<boolean>(user?.custom_role == MonitoringOperatorCustomerRoleValue)
    const [isCanEditRole] = React.useState<boolean>(authRoleMap["MS-user-editrole"]);
    const [isMonitoringOperatorCustomerRoleLoginUser] = React.useState(Number(currentLoginUser?.attributes["custom:role"]) == Number(MonitoringOperatorCustomerRoleValue));
    const [readOnlyEditDevicePermissions,setReadOnlyEditDevicePermissions]= React.useState(isMonitoringOperatorCustomerRoleLoginUser && user.custom_role == MonitoringOperatorCustomerRoleValue);
    const [readOnlyCodeRetrict,setReadOnlyCodeRetrict] = React.useState(isMonitoringOperatorCustomerRoleLoginUser && (user.custom_role == MonitoringOperatorCustomerRoleValue || user.custom_role == BussinessOperationMonitoringRoleValue));
    
    const getDefaultSmart = (array:any) => {
        const result:OptionItem[] = []
        array.forEach((item:any)=> {
            if(item === "GADEMAND"){
                result.push(SmartObserveArrayOptions[0]);
            }else if(item === "GASOLAR")
            {
                result.push(SmartObserveArrayOptions[1]);
            }
        })
        return result;
    }

    // 初期値設定
    const initialValue: ItemForm = {
        cognito_username: user.username,
        custom_company: user.custom_company ? customerList.filter((item:any) => item.value === user.custom_company)[0] : null,
        email: user.email,
        // custom_employee_number: user.custom_employee_number,
        family_name: user.family_name,
        given_name: user.given_name,
        custom_role: roleList.filter((item) => item.value == user.custom_role)[0],
        users_customer_array: user.users_customer_array?.length > 0 ?
                                    customerList.filter((item:any) => user.users_customer_array.includes(item.value))
                                    : [],
        code_restriction_array: user.code_restriction_array?.length > 0 ? 
                                    CodeRestrictionArrayOptions.filter(
                                        (item) => user.code_restriction_array.includes(item.value)) 
                                    : [],
        custom_department: deptList.filter((item) => item.value === user.custom_department)[0],
        custom_system_manage_flag: user.custom_system_manage_flag,
        code_restriction_flag: user.code_restriction_array?.length > 0 ? "1" : "0",
        smart_observer_options: user.custom_role == SmartCustomerRoleValue || user.custom_role == MobileSmartCustomerRoleValue ? getDefaultSmart(user.code_restriction_array) : [],
        restriction_auth_device_flag: user.gw_edit_auth_array?.length > 0 ? "1" : "0",
        gw_edit_auth_array: user.gw_edit_auth_array?.length > 0 ? GwAuthOptions.filter((item) => user.gw_edit_auth_array.includes(item.value)) : [],
        company_group_name: user.company_group_name,
        company_group_id: user.custom_reserve_num_02,
    }

    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ItemForm) => {
        let request = [{
            cognito_username: formValue.cognito_username,
            update_info: {
                family_name: formValue.family_name,
                given_name: formValue.given_name,
                custom_reserve_num_02: formValue.company_group_id,
                custom_company: formValue.custom_company?.value,
                custom_role: formValue.custom_role?.value,
                // custom_employee_number: formValue.custom_employee_number,
                // custom_company: formValue.custom_company?.value,
                // custom_department: formValue.custom_department?.value ? formValue.custom_department?.value : "",
            },
            users_customer_array: formValue.users_customer_array.map(e => e.value),
            code_restriction_array: formValue.code_restriction_array.map(e => e.value),
            gw_edit_auth_array: formValue.gw_edit_auth_array.map(e => e.value),
        }] as any;

        if(isSmartCustomer || isMobileSmartCustomer){
            request[0].code_restriction_array = formValue.smart_observer_options.map(e => e.value)
        }

        if(formValue.custom_role?.value != MonitoringOperatorCustomerRoleValue){
            request[0].gw_edit_auth_array = [];
        }
        
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeRestricted = (e: any, value: any) => {
        if (value != null) {
            setIsRestricted(value === '1');
            if (value !== '1'){
                setValue('code_restriction_array', []);
            }
            setValue('code_restriction_flag', value);
        }
    };

    const handleChangeRestrictedAuthDevice = (e: any, value: any) => {
        if(value != null){
            setIsRestrictedAuthDevice(value === '1');
            if (value !== '1'){
                setValue("gw_edit_auth_array", []);
            }
            setValue("restriction_auth_device_flag",value);
        }
    }

    const handleChangeRole = (values:any) => {
        const smartCustomer = roleList.find((item:any) => item.value == SmartCustomerRoleValue)
        const smartMobileCustomer = roleList.find((item:any) => item.value == MobileSmartCustomerRoleValue)
        const monitoringOperatorCustomer = roleList.find((item:any) => item.value == MonitoringOperatorCustomerRoleValue);
        const operationMonitoringBussiness = roleList.find((item:any) => item.value == BussinessOperationMonitoringRoleValue);

        if(values == smartCustomer){
            setSmartCustomerRole(true);
            setMobileSmartCustomer(false);
            setValue("smart_observer_options",SmartObserveArrayOptions);
        }
        else if(values == smartMobileCustomer){
            setMobileSmartCustomer(true);
            setSmartCustomerRole(false);
            setValue("users_customer_array",[]);
            setValue("smart_observer_options",SmartObserveArrayOptions);
        }
        else{
            setSmartCustomerRole(false);
            setMobileSmartCustomer(false);
            setValue("smart_observer_options",[])
        }

        if(values == monitoringOperatorCustomer){
            setIsMonitorOperatorCustomer(true);
        }else{
            setIsMonitorOperatorCustomer(false);
        }

        if(isMonitoringOperatorCustomerRoleLoginUser){
            if(values == monitoringOperatorCustomer){
                setReadOnlyEditDevicePermissions(true);
                setReadOnlyCodeRetrict(true);
            }else if(values == operationMonitoringBussiness){
                setReadOnlyEditDevicePermissions(false);
                setReadOnlyCodeRetrict(true);
            }else{
                setReadOnlyCodeRetrict(false);
                setReadOnlyEditDevicePermissions(false);
            }
        }else{
            setReadOnlyCodeRetrict(false);
            setReadOnlyEditDevicePermissions(false);
        }
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="company_group_name"
                                    render={({ field }) => (
                                        <Grid container direction="row">
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="組織グループ名"
                                                size="small"
                                                error={!!errors?.company_group_name}
                                                helperText={errors?.company_group_name?.message}
                                                inputProps={{
                                                    maxLength:255,
                                                    readOnly:true,
                                                }}
                                                sx={{backgroundColor:"floralwhite"}}
                                            />
                                        </Grid>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="custom_company"
                                rules={{ required: "必須項目です。選択してください。", }}
                                render={({ field }) => (
                                    <Grid container spacing={1} direction="row">
                                        <Autocomplete
                                            {...field}
                                            autoHighlight
                                            options={customerList}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values:any) => {
                                                field.onChange(values)
                                                if(values != null){
                                                    setValue("company_group_name",values.group_name);
                                                    setValue("company_group_id",values.group_id);
                                                }else{
                                                    setValue("company_group_name","");
                                                    setValue("company_group_id",null);
                                                }
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} error={!!errors?.custom_company} helperText={errors?.custom_company?.message} />}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    {...register("email")}
                                    fullWidth
                                    type="text"
                                    label="Eメール"
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{ backgroundColor: 'floralwhite' }}
                                    inputProps={{
                                        maxLength:255
                                    }}
                                />
                            </Grid>
                        </Stack>
                        {/* <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="社員番号"
                                    size="small"
                                    {...register("custom_employee_number")}
                                />
                            </Grid>
                        </Stack> */}
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="氏名-苗字"
                                    size="small"
                                    {...register("family_name",
                                        {
                                            required: '必須項目です。入力してください。'
                                        })
                                    }
                                    error={!!errors?.family_name}
                                    helperText={errors?.family_name?.message}
                                    inputProps={{
                                        maxLength:255
                                    }}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="氏名-名前"
                                    size="small"
                                    {...register("given_name",
                                        {
                                            required: '必須項目です。入力してください。'
                                        })
                                    }
                                    error={!!errors?.given_name}
                                    helperText={errors?.given_name?.message}
                                    inputProps={{
                                        maxLength:255
                                    }}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="custom_role"
                                    rules={{ required: "必須項目です。選択してください。", }}
                                    render={({ field }) => (
                                        <Grid container direction="row">
                                            <Autocomplete
                                                {...field}
                                                autoHighlight
                                                options={isMonitoringOperatorCustomerRoleLoginUser ? roleList.filter((item:any) => Number(item.value) > Number(currentLoginUser?.attributes["custom:role"]) || Number(item.value) == Number(initialValue.custom_role.value)) : roleList}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(event, values) => {
                                                    field.onChange(values)
                                                    handleChangeRole(values);
                                                }}
                                                readOnly={!isCanEditRole}
                                                fullWidth
                                                size="small"
                                                renderInput={(params) => <TextField {...params} style={{backgroundColor:isCanEditRole ? "white" : "floralwhite"}} label="ロール" error={!!errors?.custom_role} helperText={errors?.custom_role?.message} />}
                                            />
                                        </Grid>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="users_customer_array"
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            disableCloseOnSelect
                                            fullWidth
                                            multiple
                                            size="small"
                                            options={customerList}
                                            isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values) => {
                                                field.onChange(values)
                                            }}
                                            renderOption={(field, option, { selected }) => (
                                                <li {...field}>
                                                    <Checkbox
                                                        checked={selected}
                                                    />
                                                    {option.label}
                                                </li>
                                            )}
                                            readOnly={(isMobileSmartCustomer)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="担当事業所の一覧" style={{backgroundColor: (isMobileSmartCustomer) ? "floralwhite" :"white"}}/>
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        
                        { (!isSmartCustomer && !isMobileSmartCustomer) &&
                            <>
                                <Typography style={{ marginLeft: '5px' } } mt={1.5}>監視対象の制限</Typography>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                    <Controller
                                        control={control}
                                        name="code_restriction_flag"
                                        render={({ field: { value } }) => (
                                            <Grid container spacing={1} direction="row">
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={value}
                                                    exclusive
                                                    onChange={(e, value) => handleChangeRestricted(e, value)}
                                                    aria-label="Platform"
                                                    size="small"
                                                    aria-labelledby="権限"
                                                    disabled={readOnlyCodeRetrict}
                                                >
                                                    <ToggleButton value="0">制限なし</ToggleButton>
                                                    <ToggleButton value="1">制限あり</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        )}
                                    />
                                </Stack>
                                { isRestricted &&
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                        <Grid container spacing={1} direction="row">
                                            <Controller
                                                control={control}
                                                name="code_restriction_array"
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        disableCloseOnSelect
                                                        fullWidth
                                                        multiple
                                                        size="small"
                                                        options={optionCodeRestriction}
                                                        readOnly={readOnlyCodeRetrict}
                                                        isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, values) => {
                                                            field.onChange(values)
                                                        }}
                                                        renderOption={(field, option, { selected }) => (
                                                            <li {...field}>
                                                                <Checkbox
                                                                    checked={selected}
                                                                />
                                                                {option.label}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="監視対象の一覧"/>
                                                        )}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Stack>
                                }
                                {
                                    isMonitorOperatorCustomer &&
                                    <>
                                        <Typography style={{ marginLeft: '5px' } } mt={1.5}>装置編集権限</Typography>
                                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                            <Controller
                                                control={control}
                                                name="restriction_auth_device_flag"
                                                render={({ field: { value } }) => (
                                                    <Grid container spacing={1} direction="row" >
                                                        <ToggleButtonGroup
                                                            color="primary"
                                                            value={value}
                                                            exclusive
                                                            onChange={(e, value) => handleChangeRestrictedAuthDevice(e, value)}
                                                            aria-label="Platform"
                                                            size="small"
                                                            aria-labelledby="権限"
                                                            disabled={readOnlyEditDevicePermissions}
                                                        >
                                                            <ToggleButton value="0">制限なし</ToggleButton>
                                                            <ToggleButton value="1">制限あり</ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Grid>
                                                )}
                                            />
                                        </Stack>
                                        { isRestrictedAuthDevice &&
                                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                                <Grid container spacing={1} direction="row">
                                                    <Controller
                                                        control={control}
                                                        name="gw_edit_auth_array"
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                disableCloseOnSelect
                                                                fullWidth
                                                                multiple
                                                                size="small"
                                                                options={GwAuthOptions}
                                                                readOnly={readOnlyEditDevicePermissions}
                                                                isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                                                getOptionLabel={(option) => option.label}
                                                                onChange={(event, values) => {
                                                                    field.onChange(values)
                                                                }}
                                                                renderOption={(field, option, { selected }) => (
                                                                    <li {...field}>
                                                                        <Checkbox
                                                                            checked={selected}
                                                                        />
                                                                        {option.label}
                                                                    </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="権限の一覧"/>
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Stack>
                                        }
                                    </>
                                }
                            </>
                        }
                        {
                            (isSmartCustomer || isMobileSmartCustomer) &&
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={2} style={{ marginLeft: '10px' }}>
                                <Grid container spacing={1} direction="row">
                                    <FormControl fullWidth>
                                        <Typography mb={2}>監視対象の設定</Typography>
                                        <Controller
                                            control={control}
                                            name="smart_observer_options"
                                            rules={{ required: "必須項目です。選択してください。" }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    multiple
                                                    disableCloseOnSelect
                                                    fullWidth
                                                    size="small"
                                                    options={SmartObserveArrayOptions}
                                                    isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, values) => {
                                                        field.onChange(values)
                                                    }}
                                                    renderOption={(field, option, { selected }) => (
                                                        <li {...field}>
                                                            <Checkbox
                                                                checked={selected}
                                                            />
                                                            {option.label}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="監視対象" error={!!errors?.smart_observer_options} helperText={errors?.smart_observer_options?.message}/>
                                                    )}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Stack>
                        }
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
};
export default UsersEdit;


