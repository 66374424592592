import { Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageData } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import { formatDateTime } from "../../../utils";
import Page from "../../common/Page";
import FetchHelper from "../../FetchHelper";
import DemandDataGrid from "./DemandDataGrid";
import DeviceInformation from "../../polling/device/DeviceInformation";

// -------------
// FETCH ID
const fetchId = "DEMAND_DATA_LIST";
const getDevice = "DEMAND_GET_DEVICE";

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// -------------
// Props
export type DemandDataListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const DemandDataList = (props: DemandDataListProps) => {
    const {
        onSearch,
        onError,
        onErrorSearch,
        onGetDevice,
    } = props;

    const { setValue, getValues } = useForm<SearchItemForm>({
        defaultValues: initialValue
    });

    const [pulseType, setPulseType] = React.useState("");
    const [pageData, setPageData] = useState(initialPageData);
    const [listData, setListData] = useState<any>([]);
    const [colState, setColState] = useState<any>();
    const [latestUpdates, setLatestUpdates] = useState("");
    const gridRef = useRef() as any;

    const [loadingFlag, setLoadingFlag] = React.useState(true);

    let navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const mainid = urlParams.get('mainid');
    const customer_id = urlParams.get('customer_id');
    const customer_name = urlParams.get('customer_name');
    const place = urlParams.get('place');
    const [device, setDevice] = React.useState<any>();

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        let isError = false;

        if(isEmpty(mainid) || isEmpty(customer_id) || isEmpty(place)) isError = true;

        onGetDevice(mainid)

        if (gridRef && gridRef.current){
            setColState(gridRef.current.saveState());
        }

        const params = new Map<string, any>();
        params.set("code", "GA")
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        params.set('path', urlParams.get('path') ?? 'root');
        params.set("mainid", mainid);
        params.set("customer_id", customer_id);
        params.set("place", place);
        
        if (!isError) {
            setLoadingFlag(true);
            onSearch(params);
        }
        else{
            onErrorSearch();
            setLoadingFlag(false);
        }
    }

    const handleGetDevice = (data: any) => {
        setPulseType(data.pulse_type);
        setDevice(data)
    };

    const isEmpty = (value: any) => {
        return (value === null || value?.length <=0)
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
        // eslint-disable-next-line
    }, []);

    // 検索正常終了時
    const handleSuccess = (data: any) => {
        if(data.results){
            if (gridRef && gridRef.current){
                gridRef.current.setRowData(data.results);
            }
            
            setListData(() => [...data.results]);

            // if (myParam) {
            //     listData.forEach((item: any) => {
            //         item.serial_no = myParam
            //     });
            //     setListData(()=>[...listData])
            // } 
            // else {
            //     listData.forEach((item: any) => {
            //         item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            //     });
            //     setListData(()=>[...listData])
            // }

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        }
        else{
            setListData(() => [...[] as any]);
        }
        setLatestUpdates(formatDateTime(new Date()));
        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if(name === 'limit'){
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    const onRefreshGrid = () => {
        handleSearchSubmit(getValues());
    }

    // キャンセル
    const handleCancel = () => {
        sessionStorage.removeItem('references');
        let path = pulseType === "1" ? RoutePath.GaList : RoutePath.Ga2List;
        let urlPath = (urlParams.get('path') ?? 'root')
        path +='?path=' + urlPath;
        path += id ? '&id=' + id : "";
        if (urlPath=="customer") {
            path += '&customer_id=' + (urlParams.get('customer_id') ?? '');
        }
        navigate(path, { replace: true });
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%'}}>
            <DeviceInformation deviceInformation={device} mainid={mainid ?? ''} title="定期通報" isDisplayDeviceInfo={true} isShowMenuButton ={false} ></DeviceInformation>
                    <div style={{ marginBottom: "10px", marginTop: '20px' }}>
                        <Stack direction="row">
                            <Typography variant="body2" style={{textAlign: "center", lineHeight: "40px"}}>
                                最終表示日時&emsp;{latestUpdates}
                            </Typography>
                            <div style={{marginInlineStart: 'auto'}}>
                                <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                    <Grid item>
                                        <Page onRefreshGrid={onRefreshGrid}></Page>
                                    </Grid>
                                </Grid>
                            </div>
                        </Stack>
                    </div>
                    <div>
                        <DemandDataGrid
                            ref={gridRef}
                            colState={colState}
                            pageSize={getValues('limit')}
                            pageData={pageData}                                
                            onChangeSearchCondition={handleChangePageData}
                            pulseType={pulseType}
                            mainid={mainid}
                            />
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} mt="5px">
                        <Button color="inherit" variant="contained" onClick={handleCancel}>戻る</Button>
                    </Stack>
            </div>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={getDevice} onComplete={(success, data, error) => success ? handleGetDevice(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onGetDevice: (id: string | null) => actionCreators.fetch(getDevice, `/master/gateways/get?id=${id}`, "GET", null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－データ検索",
            body: "デマンド－データを検索できません。" + error,
        }),
    onErrorSearch: () =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－データ検索",
            body: "デマンド－データを検索できません。" ,
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemandDataList as any);
