import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Stack, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { useNavigate } from 'react-router';
import { OptionItem, PageData } from '../../../models';
import { Path as RoutePath } from "../../../path";
import { limitMasterCustomers, limitMasterGateways } from '../../../statics';
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import AlertDialog from '../../common/AlertDialog';
import WeathersAdd from './WeathersAdd';
import WeathersEdit from './WeathersEdit';
import WeathersGrid from './WeathersGrid';

// -------------
// FETCH ID
const menusFetchId = 'MENUS_FETCH';
const formId = "WEATHER_SEARCH_FORM";
const getWeathers = "GET_WEATHERS";
const getAllGateways = "GET_ALL_GATEWAYS";
const getAllCustomers = "GET_ALL_CUSTOMERS";
const insertWeathers = "INSERT_WEATHERS";
const updateWeathers = "UPDATE_USERSS";
const deleteWeathers = "DELETE_USERS";
export const strInsert = "insert";
export const strUpdate = "update";
export const strDelete = "delete";

interface WeatherModel {
    customer_id: OptionItem|null,
    mainid: OptionItem|null;
    location: string;
    ip_address: string;
    address_in: string;
    address_out: string;
    latitude: string;
    longitude:string
    collect_flag: number;
    isSelect: boolean;
}

// 検索フォーム
export interface SearchItemForm {
    "target": string;
    "limit": number;
    "page": number;
    "keyword": string;
}

// 初期値設定
const initialValue: SearchItemForm = {
    target: '',
    limit: 50,
    page: 0,
    keyword: '',
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

const initialWeather: WeatherModel = {
    customer_id: null,
    mainid: null,
    location: "",
    ip_address: "",
    address_in: "",
    address_out: "",
    latitude: "",
    longitude: "",
    collect_flag: 1,
    isSelect: false,
}

// -------------
// Props
export type WeathersProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const Weathers = (props: WeathersProps) => {
    // #region props
    const {
        fetchStateMenu,
        onGetAuthRole,
        onSearch,
        onInsertWeathers,
        onUpdateWeathers,
        onDeleteWeathers,
        onCreateSuccess,
        onUpdateSuccess,
        onDeleteSuccess,
        onError,
        onCreateError,
        onUpdateError,
        onDeleteError,
        onGetCoordinateError,
    } = props;
    localStorage.setItem('lastActiveFormPath', RoutePath.Weather);
    localStorage.setItem('title', '気象情報 観測地点一覧');
    
    const { setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const gridRef = useRef() as any;
    
    const [customerList, setCustomerList] = React.useState<OptionItem[]>([]);
    const [gatewayList,setGateWayList] = React.useState<OptionItem[]>([]);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [listData, setListData] = React.useState<any[]>([]);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [openEditDialog, setOpenEditDialog] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectData, setSelectData] = React.useState<WeatherModel>(initialWeather);
    const [disableEdit, setDisableEdit] = React.useState(true);
    const [disableDelete, setDisableDelete] = React.useState(true);
    const [keyword, setKeyword] = React.useState("");
    const [titleAdd,setTitleAdd] = React.useState("気象情報　観測地点追加");
    const [messageFlag,setMessageFlag] = React.useState(false);
    const [disable_add_flag,set_disable_add_flag] = useState<boolean>(true);
    const [disable_edit_flag,set_disable_edit_flag] = useState<boolean>(true);
    const [disable_delete_flag,set_disable_delete_flag] = useState<boolean>(true);
    const [disable_view_flag,set_disable_view_flag] = useState<boolean>(true);
    const [openErrorDialog,setOpenErrorDialog] = useState<boolean>(false);
    const [messageError,setMessageError] = React.useState<string>("");
    const [loadedCustomerFlag, setLoadedCustomerFlag] = React.useState(true);
    const [loadedGatewaysFlag,setLoadedGatewaysFlag] = React.useState(true);
    const [loadedWeathersInfoFlag,setLoadedWeatherInfoFlag] = React.useState(true);
    const [pageData,setPageData] = React.useState<PageData>(initialPageData);
    const [colState, setColState] = useState<any>();

    // #endregion props

    // #region method

    useEffect(()=> {
        if(fetchStateMenu && fetchStateMenu.data){
            handleFetchAuthRoleSuccess(fetchStateMenu.data)
        }else{
            onGetAuthRole();
        }
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            set_disable_view_flag(!data.auth_role_map['MS-weather-view'] ?? true);
            set_disable_add_flag(!data.auth_role_map['MS-weather-add'] ?? true);
            set_disable_edit_flag(!data.auth_role_map['MS-weather-edit'] ?? true);
            set_disable_delete_flag(!data.auth_role_map['MS-weather-delete'] ?? true);
        }
    }

    // 検索正常終了時
    const handleSuccess = (data: any, type: string) => {
        console.log('>>> handleSuccess');
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        if(data && data.result_code == 0){
            switch(type){
                case strInsert:
                    setOpenAddDialog(false);
                    setMessageFlag(false);
                    onCreateSuccess();
                    break;
                case strUpdate:
                    setOpenEditDialog(false);
                    onUpdateSuccess();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteSuccess();
                    break;
                default:
                    break;
            }
            setLoadedWeatherInfoFlag(true);
            const params = new Map<string, any>();
            params.set("limit",50);
            params.set("page",0);
            onSearch(params);
            setDisableEdit(true);
            setDisableDelete(true);
        }else{
            switch(type){
                case strInsert:
                    onCreateError();
                    break;
                case strDelete:
                    setOpenDeleteDialog(false);
                    onDeleteError();
                    break;
                case strUpdate:
                    onUpdateError();
                    break;
                default:
                    break;
            }
            setLoadedWeatherInfoFlag(false);
        }
    };
    
    // 顧客名一覧取得成功時
    const handleFetchCustomersSuccess = (data: any) => {
        if (data?.results) 
            setCustomerList(data?.results.map((x:any) => { return { value: x.id.toString(), label: x.name, attribute: "",address1:x.address1.toString() } as any }));
        setLoadedCustomerFlag(false);
    };

    // 検索正常終了時
    const handleSuccessGetAll = (data: any) => {
        console.log('>>> handleSuccessGetAll');
        if (data && data.results) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(handleGetData(data.results));
            }
            setListData(() => [...handleGetData(data.results)]);

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
                setListData(() => []);
            }
        }
        setLoadedWeatherInfoFlag(false);
        };

    const handleGetData = (data:any) => {
        const result:any = [];
        for(const item of data){
            result.push(item);
        }
        return result;
    }

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadedCustomerFlag(false);
        setLoadedGatewaysFlag(false);
        setLoadedWeatherInfoFlag(false);
        gridRef.current.hideOverlay();
    };

    // 追加の処理
    const handleAdd = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        handleOpenAddDialog();
    }

    // 編集の処理
    const handleEdit = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        handleOpenEditDialog();
    }

    //削除の処理
    const handleDelete = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        let selectedData = listData.filter((item) => item.isSelect === true);
        if (selectedData.length === 0) {
            return;
        }
        const msg = "選択された気象情報観測地点を削除しますか。";
        setTitle("気象情報観測地点削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadedWeatherInfoFlag(true);
        listData.filter((item) => item.isSelect === true).forEach((item) => {
            onDeleteWeathers(item.id);
        })
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    }

    // 検索正常終了時
    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        let list = listData.filter((item) => item.id === data.id)
        list[0].isSelect = !list[0].isSelect;

        let selectCount = listData.filter((item) => item.isSelect === true).length;
        if (selectCount === 1) {
            setDisableEdit(false);
            setDisableDelete(false);
        } else if (selectCount > 0) {
            setDisableEdit(true);
            setDisableDelete(false);
        } else {
            setDisableEdit(true);
            setDisableDelete(true);
        }
    };

    // 帳票ダイアログを表示する
    const handleOpenAddDialog = () => {
        setSelectData(initialWeather);
        setOpenAddDialog(true);
    };

    // 帳票ダイアログを無効にする
    const handleOpenAddDialogCancel = () => {
        setOpenAddDialog(false);
        setMessageFlag(false);
    };

    // 帳票ダイアログを表示する
    const handleOpenEditDialog = () => {
        console.log('>>> handleOpenEditDialog');
        var weatherInfoSelect = listData.filter((item) => item.isSelect === true)[0] as WeatherModel
        setSelectData(weatherInfoSelect);
        setOpenEditDialog(true);
    };

    // 帳票ダイアログを無効にする
    const handleOpenEditDialogCancel = () => {
        setOpenEditDialog(false);
    };

    const handleInsert = (data: any) => {
        setLoadedWeatherInfoFlag(true);
        onInsertWeathers(data);
    };

    const handleUpdate = (data: any) => {
        setLoadedWeatherInfoFlag(true);
        onUpdateWeathers(data);
    };

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
    }

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);

        setLoadedWeatherInfoFlag(true);
        onSearch(params);
    }

    const handleFetchGatewaysSuccess = (data:any) => {
        if (data?.results) 
            setGateWayList(data?.results.map((x:any) => { return { id: x.id.toString(), customer_id: x.customer_id, location: x.place, ip_address: x.ip_address}}));
        setLoadedGatewaysFlag(false);
    }

    let navigate = useNavigate();
    const onShowDetailPage = (data:any,field:any) =>{
        const observationPoint = listData.find((item:any) => item.id == data.id);
        if (field === "data") {
            navigate(`${RoutePath.WeatherData}?code=DATA&type=WEATHER&weather_id=${data.id}`,{
                state:{
                    observationPoint:observationPoint,
                }
            });
        }
        else if(field === "edit_field"){
            navigate(`${RoutePath.ObservationPointsAndDeviceScreen}/${data.id}`,
                {
                    state:{
                        observationPoint:observationPoint,
                    }
                }
            );
        }
    }

    const showError = (success:any,data:any,error:any) => {
        onGetCoordinateError(error);
    }

    const handleOK = () => {
        setOpenErrorDialog(false);
    }

    // #endregion Method

    return (
        <>
            <FetchHelper fetchId={getAllCustomers}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomersSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={getWeathers}
                url="/master/weathers/get"
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetAll(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={getAllGateways}
                url={`/master/gateways/get?limit=${limitMasterGateways}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchGatewaysSuccess(data) : handleError(success, data, error)
                }}
            />

            {(loadedCustomerFlag || loadedGatewaysFlag || loadedWeathersInfoFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: "100%", width: '100%' }}>
                {openDeleteDialog &&
                    <AlertDialog 
                        title={title} 
                        message={message} 
                        isOpen={true} 
                        onOK={handleDeleteOK} 
                        onCancel={handleDeleteCancel} 
                    />
                }
                {
                    openErrorDialog &&
                    <>
                        <Dialog open={openAddDialog} fullWidth style={{ zIndex: 1300 }}>
                            
                                <DialogTitle>{"観測地点追加"}</DialogTitle>
                                <DialogContent sx={{ pb: 1.5 }}>
                                    <DialogContentText>
                                        {messageError}
                                    </DialogContentText>
                                </DialogContent>
                                <Grid container>
                                    <Grid item xs={5.5}>
                                    </Grid>
                                    <Grid item xs={1} mb={1}>
                                        <Button onClick={handleOK} style={{width:"60px"}} variant="contained">OK</Button>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                    </Grid>
                                </Grid>
                        </Dialog>
                    </>
                }
                {openAddDialog &&
                    <Dialog open={openAddDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <Grid container justifyContent="flex-start" direction="row">
                            <Grid item xs={4}>
                                <DialogTitle>{titleAdd}</DialogTitle>
                            </Grid>
                            <Grid item xs={7} mr={1}>
                                {
                                    messageFlag && 
                                    <p style={{fontSize:"12.5px",whiteSpace:"pre-line"}} >※登録された住所、緯度、経度は変更することができません。<br></br>
                                    内容を確認し、保存ボタンを押してください。</p>
                                }
                            </Grid>
                        </Grid>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <WeathersAdd
                                onOK={handleInsert}
                                onCancel={handleOpenAddDialogCancel}
                                customerList={customerList}
                                gatewayList={gatewayList}
                                handleBack={()=>{
                                    setTitleAdd("気象情報　観測地点追加")
                                    setMessageFlag(false);
                                }}
                                handleNext={()=>{
                                    setTitleAdd("気象情報　観測地点確認")
                                    setMessageFlag(true);
                                }}
                                showDialogError={(message) => {
                                    setMessageError(message);
                                    setOpenErrorDialog(true);
                                }}
                                onError={showError}
                            />
                        </DialogContent>
                    </Dialog>
                }
                {openEditDialog &&
                    <Dialog open={openEditDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>{"気象情報　観測地点編集"}</DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <WeathersEdit
                                weatherModel={selectData}
                                onOK={handleUpdate}
                                onCancel={handleOpenEditDialogCancel}
                                customerList={customerList}
                            />
                        </DialogContent>
                    </Dialog>
                }
                <div style={{ marginBottom: "10px",paddingTop:"5px"}}>
                    <Stack direction="row">
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => handleChangeKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                        <Stack direction={{ xs: 'column', sm: 'row' }} ml={5} spacing={1}>
                            <Button variant="contained" size="small" onClick={handleAdd} disabled={disable_add_flag}>{<AddIcon />}</Button>
                            <Button variant="contained" size="small" onClick={handleEdit} disabled={disableEdit || disable_edit_flag }>{<EditIcon />}</Button>
                            <Button variant="contained" size="small" onClick={handleDelete} disabled={disableDelete || disable_delete_flag }>{<ClearIcon />} </Button>
                        </Stack>
                    </Stack>
                </div>
                <div>
                    <WeathersGrid
                        ref={gridRef}
                        colState={colState}
                        onShowDetails={onShowDetailPage}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        keyword={keyword}
                        onChangeSearchCondition={handleChangePageData} 
                        onSelect={handleSelect}
                        openSearch={false}
                        isDisableView={disable_view_flag}
                        isDisableSelect={disable_edit_flag && disable_delete_flag}
                        />
                </div>
            </div>
            <FetchHelper fetchId={insertWeathers} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strInsert) : handleError(success, data, error)} />
            <FetchHelper fetchId={updateWeathers} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strUpdate) : handleError(success, data, error)} />
            <FetchHelper fetchId={deleteWeathers} onComplete={(success, data, error) =>
                success ? handleSuccess(data, strDelete) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params:any) => actionCreators.fetch(getWeathers,`/master/weathers/get`,"GET",params),
    onInsertWeathers: (data: any) => actionCreators.fetch(insertWeathers, `/master/weathers/add`, "POST", data, false, true),
    onUpdateWeathers: (data: any) => actionCreators.fetch(updateWeathers, `/master/weathers/edit`, "POST", data, false, true),
    onDeleteWeathers: (id: string) => actionCreators.fetch(deleteWeathers, `/master/weathers/delete?id=${id}`, "POST", null),
    onCreateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "気象観測地点追加",
            body: "気象観測地点を登録しました。",
        }),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "気象観測地点編集",
            body: "気象観測地点を更新しました。",
        }),
    onDeleteSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "気象観測地点削除",
            body: "気象観測地点を削除しました。",
        }),
    onCreateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "気象観測地点追加",
            body: "気象観測地点が登録出来ませんでした。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "気象観測地点編集",
            body: "気象観測地点が更新出来ませんでした。",
        }),
    onDeleteError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "気象観測地点削除",
            body: "気象観測地点が削除出来ませんでした。",
        }),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "気象観測地点",
            body: "気象観測地点を取得できません。" + error,
        }),
    onGetCoordinateError: (error:any) =>
        actionCreators.showMessage({
            type: "error",
            title: "気象観測地点",
            body: "気象観測地点を取得できません。" + error ,
        }),
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Weathers as any);
