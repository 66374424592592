import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import ExcessHeavyLeakageCurrentEdit from '../polling/pollingTopicEdit/ExcessHeavyLeakageCurrentEdit';

export type SelectExcessHeavyLeakageCurrentDialogProps = {
    isOpen: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
    data: any;
    dialogTitle: string;
};

export const SelectExcessHeavyLeakageCurrentDialog = (props: SelectExcessHeavyLeakageCurrentDialogProps) => {
    const { isOpen, onOK, onCancel, data, dialogTitle } = props;
    const [open, setOpen] = React.useState(isOpen);

    // Cancelボタンイベント
    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const handleUpdate = (data: any) => {
        setOpen(false);
        onOK(data);
    };

    return (
        <div>
            <Dialog open={open} maxWidth={"md"} fullWidth>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                    <ExcessHeavyLeakageCurrentEdit onCancel={handleCancel} excessHeavyLeakageCurrent={data} onOK={(data) => handleUpdate(data)}></ExcessHeavyLeakageCurrentEdit>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default SelectExcessHeavyLeakageCurrentDialog;