import { connect } from "react-redux";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import { Path as RoutePath } from "../../../path";
import { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { Autocomplete, Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Controller, useForm } from "react-hook-form";
import palette from "../../../theme/palette";
import { OptionItem, PageData } from "../../../models";
import FetchHelper from "../../FetchHelper";
import DeviceJSPGrid from "./DeviceJSPGrid";
import DeviceJSPAdd from "./DeviceJSPAdd";
import AlertDialog from "../../common/AlertDialog";
import DeviceJSPEdit from "./DeviceJSPEdit";
import { limitMasterCustomers, customerLabelName } from "../../../statics";

const menusFetchId = 'MENUS_FETCH';
const formId = "DEVICE_JSP_SEARCH_FORM";
const GET_ALL_DEVICE_JSP_BY_CONDITION = "GET_ALL_DEVICE_JSP_BY_CONDITION";
const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER"
const CREATE_DEVICE_JSP = "CREATE_DEVICE_JSP";
const UPDATE_DEVICE_JSP = "UPDATE_DEVICE_JSP";
const DELETE_DEVICE_JSP = "DELETE_DEVICE_JSP";
const KEY_INSERT = "INSERT";
const KEY_UPDATE = "UPDATE";
const KEY_DELELTE = "DELETE";

interface DeviceJSPModel{
    customer: OptionItem | null,
    device_name:string,
    jsp_url:string,
    isSelect:boolean,
}

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
    customer_id: OptionItem | null;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
    customer_id: null,
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

const initialDeviceJSPValue: DeviceJSPModel = {
    customer:null,
    device_name:"",
    jsp_url:"",
    isSelect:false,
}


// Props
export type DeviceJSPListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
export const DeviceJSPList = (props: DeviceJSPListProps) => {
    // #region props
    const { onSearchByCondition,onError,onCreateDeviceJSP,onDeleteDeviceJSP,onCreateSuccess,
        onUpdateSuccess,onDeleteSuccess,onCreateError,onUpdateError,onDeleteError,onGetAllCustomer,onUpdateDeviceJSP,
        onGetAuthRole,fetchStateMenu, } = props;
    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [authRole, setAuthRole] = useState();
    //状態設定値
    //装置管理
    const [hiddenSelectFlag,setHiddenSelectFlag] = useState(true);
    const [disableGWcrudAddBtn, setDisableGWcrudAddBtn] = useState(true);
    const [disableGWcrudEditBtn, setDisableGWcrudEditBtn] = useState(true);
    const [disableGWcrudDeleteBtn, setDisableGWcrudDeleteBtn] = useState(true);
    const [openSearch,setOpenSearch] = useState<boolean>(false);
    const [isOpenAddDialog,setOpenAddDialog] = useState<boolean>(false);
    const [isOpenEditDialog,setOpenEditDialog] = useState<boolean>(false);
    const [isOpenDeleteDialog,setOpenDeleteDialog] = useState<boolean>(false);
    const [keyword,setKeyword] = useState<string>("");
    const [colState, setColState] = useState<any>();
    const [pageData, setPageData] = useState(initialPageData);
    const [listData,setListData] = useState<any>([]);
    const [selectData, setSelectData] = useState<DeviceJSPModel>(initialDeviceJSPValue);
    const [isDisableEdit,setDisableEdit] = useState<boolean>(true);
    const [isDisableDelete,setDisableDelete] = useState<boolean>(true);
    const [messageDelete,setMessageDelete] = useState<string>("");
    const [customerOptionsAddOrEdit,setCustomerOptionsAddOrEdit] = useState<any>([]);
    const [loadingFlag,setLoadingFlag] = useState<boolean>(false);
    const [customerLoadingFlag,setCustomerLoadingFlag] = useState<boolean>(false);
    const gridRef = useRef() as any;
    localStorage.setItem('lastActiveFormPath', RoutePath.DeviceJSPList);
    localStorage.setItem('title', '旧版監視装置');
    // #endregion props

    // #region method

    useEffect(()=> {
        setCustomerLoadingFlag(true);
        setLoadingFlag(true);
        handleSearchSubmit(getValues())
        onGetAllCustomer();
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
            handleFetchAuthRoleSuccess(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            setAuthRole(data);
            setHiddenSelectFlag((!data.auth_role_map['GW-gwcrud-edit'] && !data.auth_role_map['GW-gwcrud-delete']) ?? true)
            setDisableGWcrudAddBtn(!data.auth_role_map['GW-gwcrud-add'] ?? true);
            setDisableGWcrudEditBtn(!data.auth_role_map['GW-gwcrud-edit'] ?? true);
            setDisableGWcrudDeleteBtn(!data.auth_role_map['GW-gwcrud-delete'] ?? true);
        }
    }

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        setLoadingFlag(true);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.customer_id && value.customer_id.value) params.set("customer_id", value.customer_id.value);
        onSearchByCondition(params);
    }

    // 検索正常終了時
    const handleSuccess = (data:any) => {
        setLoadingFlag(false);
        const totalCount = data.total_count;
        const pageSize = getValues('limit');
        const page = Number(data.page) + 1;
        const totalPage = Math.ceil(totalCount / pageSize);
        const rowFrom = (pageSize * page - pageSize) + 1;
        const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);
        const updateData: PageData = {
            rowFrom: rowFrom.toLocaleString(),
            rowTo: rowTo.toLocaleString(),
            totalRow: totalCount.toLocaleString(),
            currPage: page.toLocaleString(),
            totalPage: totalPage.toLocaleString(),
        };
        setPageData(updateData);
        
        if(data && data.results){
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.results);
            }
            setListData(() => [...data.results]);
        }else {
            setListData([]);
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
    }

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    const handleSearch = () => {
        setValue("page", 0)
        handleSearchSubmit(getValues());
    }

    const handleClearSeachCondition = () => {
        setValue("customer_id",{label:"",value:"",attribute:""} as OptionItem);
    }

    // 検索エラー時
    const handleError = (success:any,data:any,error:any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    }

    const handleChangeKeyword = (value:string) => {
        setKeyword(value);
    }

    // 追加の処理
    const handleAdd = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        handleOpenAddDialog();
    }

    // 削除の処理
    const handleDelete = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        let selectedData = listData.filter((item:any) => item.isSelect === true);
        if (selectedData.length === 0) {
            return;
        }
        let msg = "";
        if(selectedData.length === 1) {
            msg = `装置名：${selectedData[0].device_name}を削除しますか？`;
        }else if(selectedData.length > 1){
            msg = "装置名：";
            for(let i =0;i<selectedData.length - 1;i++){
                msg += selectedData[i].device_name + ","
            }
            msg+= selectedData[selectedData.length - 1].device_name + "を削除しますか？";
        }
        setMessageDelete(msg);
        handleOpenDeleteDialog();
    }
    // 編集の処理
    const handleEdit = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        var deviceJSP = listData.filter((item:any) => item.isSelect === true)[0] 
        setSelectData(deviceJSP);
        handleOpenEditDialog();
    }

    const handleChangePageData = (name:any,value:any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    }

    // 検索正常終了時
    const handleSelect = (data:any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        let list = listData.filter((item:any) => item.customer_id === data.customer_id && item.device_name === data.device_name)
        list[0].isSelect = !list[0].isSelect;

        let selectCount = listData.filter((item:any) => item.isSelect === true).length;
        if (selectCount === 1) {
            setDisableEdit(false);
            setDisableDelete(false);
        } else if (selectCount > 0) {
            setDisableEdit(true);
            setDisableDelete(false);
        } else {
            setDisableEdit(true);
            setDisableDelete(true);
        }
    }

    const handleInsert = (data:any) => {
        setLoadingFlag(true);
        onCreateDeviceJSP(data);
    }

    const handleKeySuccess = (data:any,key:any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        if(data && data.result_code == 0){
            switch(key){
                case KEY_INSERT:
                    setOpenAddDialog(false);
                    onCreateSuccess();
                    break;
                case KEY_UPDATE:
                    setOpenEditDialog(false);
                    onUpdateSuccess();
                    break;
                case KEY_DELELTE:
                    setOpenDeleteDialog(false);
                    onDeleteSuccess();
                    break;
                default:
                    break;
            }
            setLoadingFlag(true);
            const params = new Map<string, any>();
            if(getValues("customer_id.value")){
                params.set("customer_id",getValues("customer_id.value"));
            }
            params.set("limit",50);
            params.set("page",0);
            onSearchByCondition(params);
            setDisableEdit(true);
            setDisableDelete(true);
        }else{
            switch(key){
                case KEY_INSERT:
                    onCreateError();
                    break;
                case KEY_UPDATE:
                    onUpdateError();
                    break;
                case KEY_DELELTE:
                    setOpenDeleteDialog(false);
                    onDeleteError();
                    break;
                default:
                    break;
            }
            setLoadingFlag(false);
        }
    }

    const showError = (success:any,data:any,error:any) => {
        onError(success,data,error);
    }

    const handleEditSubmit = (data:any) => {
        setLoadingFlag(true);
        onUpdateDeviceJSP(data);
    }
    
    // 顧客名一覧取得成功時
    const handleSuccessGetAllCustomer = (data:any) => {
        setCustomerLoadingFlag(false);
        if (data?.results) setCustomerOptionsAddOrEdit(data?.results.map((x:any) => { return { value: x.id.toString(), label: x.name,attributes:"", group_name:x.group_name, group_id:x.group_id } as any }));
    }

    const handleErrorGetAllCustomer = () => {
        setCustomerLoadingFlag(false);
        // onError();
    }
    
    // 帳票ダイアログを表示する
    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    }

    // 帳票ダイアログを無効にする
    const handleCancelAddDialog = () => {
        setOpenAddDialog(false);
    }

    const handleOpenEditDialog = () => {
        setOpenEditDialog(true);
    }

    // 帳票ダイアログを無効にする
    const handleCancelEditDialog = () => {
        setOpenEditDialog(false);
    }

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        listData.filter((item:any) => item.isSelect === true).forEach((item:any) => {
            onDeleteDeviceJSP(item.device_name);
        })
    }

    // 削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    }

    // #endregion method
    return(<>
            {(loadingFlag|| customerLoadingFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                {isOpenDeleteDialog &&
                    <AlertDialog 
                        title={"旧版監視装置削除"} 
                        message={messageDelete} 
                        isOpen={true} 
                        onOK={handleDeleteOK} 
                        onCancel={handleDeleteCancel} 
                    />
                }
                {isOpenAddDialog &&
                    <Dialog open={isOpenAddDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>旧版監視装置</DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <DeviceJSPAdd
                                onOK={handleInsert}
                                onCancel={handleCancelAddDialog}
                                onError={showError}
                                customerList={customerOptionsAddOrEdit}
                            />
                        </DialogContent>
                    </Dialog>
                }
                {
                    isOpenEditDialog &&
                    <Dialog open={isOpenEditDialog} maxWidth={"md"} fullWidth style={{ zIndex: 1200 }}>
                        <DialogTitle>旧版監視装置</DialogTitle>
                        <DialogContent sx={{ pb: 1.5 }}>
                            <DeviceJSPEdit
                                onOK={handleEditSubmit}
                                onCancel={handleCancelEditDialog}
                                onError={showError}
                                deviceName={selectData.device_name}
                                customerList={customerOptionsAddOrEdit}
                            />
                        </DialogContent>
                    </Dialog>
                }
                <form onSubmit={handleSubmit(handleSearch)}>
                    <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                        <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="h6" >検索条件</Typography>
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {
                                    openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                            <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                >
                                    <Grid item minWidth="20rem">
                                        <Controller
                                            control={control}
                                            name="customer_id"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={customerOptionsAddOrEdit}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" style={{ background: palette.background.paper }} />}
                                                    onChange={(_, data) => setValue("customer_id", data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                    </Grid>
                                    <Grid item >
                                        <Button variant="contained" onClick={handleSearch}>
                                            検索
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </div>
                    </Stack>
                </form >
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} mb={1} spacing={1}>
                        <Grid item>
                            <TextField
                                fullWidth
                                autoComplete="searchStr"
                                name="keyword"
                                type="text"
                                label="フィルター"
                                size="small"
                                value={keyword}
                                onChange={e => handleChangeKeyword(e.target.value)}
                                />
                            </Grid>
                        <Button variant="outlined" size="small" onClick={() => handleChangeKeyword("")}>クリア</Button>
                        <Button variant="contained" size="small" onClick={handleAdd} disabled={disableGWcrudAddBtn} >{<AddIcon />}</Button>
                        <Button variant="contained" size="small" onClick={handleEdit} disabled={isDisableEdit || disableGWcrudEditBtn} >{<EditIcon />}</Button>
                        <Button variant="contained" size="small" onClick={handleDelete} disabled={isDisableDelete || disableGWcrudDeleteBtn} >{<ClearIcon />} </Button>
                    </Stack>
                </div>
                <div>
                    <DeviceJSPGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        openSearch={openSearch}
                        keyword = {keyword}
                        onSelect={handleSelect}
                        hiddenSelectFlag={hiddenSelectFlag}
                    />
                </div>
            </div>
            <FetchHelper fetchId={GET_ALL_DEVICE_JSP_BY_CONDITION} onComplete={
                (success:any,data:any,error:any) => success ? handleSuccess(data) : handleError(success,data,error) } />
            <FetchHelper fetchId={GET_ALL_CUSTOMER} onComplete={
                (success:any,data:any,error:any) => success ? handleSuccessGetAllCustomer(data) : handleErrorGetAllCustomer() } />
            <FetchHelper fetchId={CREATE_DEVICE_JSP} onComplete={
                (success:any,data:any,error:any) => success ? handleKeySuccess(data,KEY_INSERT) : handleError(success,data,error) } />
            <FetchHelper fetchId={UPDATE_DEVICE_JSP} onComplete={
                (success:any,data:any,error:any) => success ? handleKeySuccess(data,KEY_UPDATE) : handleError(success,data,error) } />
            <FetchHelper fetchId={DELETE_DEVICE_JSP} onComplete={
                (success:any,data:any,error:any) => success ? handleKeySuccess(data,KEY_DELELTE) : handleError(success,data,error) } />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />

    </>)
}

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});
const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearchByCondition:(params: { [key: string]: any }) =>  actionCreators.fetch(GET_ALL_DEVICE_JSP_BY_CONDITION,`/master/gateways-jsp/get`,"GET",params),
    onCreateDeviceJSP: (data:any) => actionCreators.fetch(CREATE_DEVICE_JSP,`/master/gateways-jsp/add`,"POST",data,false,true),
    onUpdateDeviceJSP: (data:any) => actionCreators.fetch(UPDATE_DEVICE_JSP,`/master/gateways-jsp/edit`,"POST",data,false,true),
    onDeleteDeviceJSP: (device_name:string) => actionCreators.fetch(DELETE_DEVICE_JSP,`/master/gateways-jsp/delete?device_name=${device_name}`,"POST",null),
    onGetAllCustomer: () => actionCreators.fetch(GET_ALL_CUSTOMER,`/master/customers/get?limit=${limitMasterCustomers}`,"GET",null),

    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "旧版監視装置",
            body: "旧版監視装置を取得できません。" + error,
        }),
    onCreateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "旧版監視装置追加",
            body: "旧版監視装置を登録しました。",
        }),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "旧版監視装置編集",
            body: "旧版監視装置を更新しました。",
        }),
    onDeleteSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "旧版監視装置削除",
            body: "旧版監視装置を削除しました。",
        }),
    onCreateError:() =>
        actionCreators.showMessage({
            type: "error",
            title: "旧版監視装置追加",
            body: "旧版監視装置が登録出来ませんでした。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "旧版監視装置編集",
            body: "旧版監視装置が更新出来ませんでした。",
        }),
    onDeleteError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "旧版監視装置削除",
            body: "旧版監視装置が削除出来ませんでした。",
        }),
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceJSPList as any);