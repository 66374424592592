import WarningIcon from "@mui/icons-material/Warning";
import { Typography } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useNavigate } from 'react-router';
import { PageData } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { AG_GRID_LOCALE_JP } from "../../../theme/localeJP";
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from "../../common/PaginationCpn";
import { useSearchParams } from "react-router-dom";
import { customerLabelName } from "../../../statics";

// -------------
// Props
export type AlarmGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    openSearch: boolean;
    authRole: any;
};

// -------------
// Component
export const AlarmGrid = forwardRef((props: AlarmGridProps, ref) => {
    const {
        colState,
        pageSize,
        pageData,
        onChangeSearchCondition,
        keyword,
        openSearch,
        authRole,
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    const [roleMap, setRoleMap] = useState<string[]>([]);
    
    const [searchParams] = useSearchParams();
    
    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    useEffect(() => {
        if (authRole) {
            if (authRole['code_restriction_array']?.length > 0) {
                let rMap:string[] = [];
                authRole['code_restriction_array']?.forEach((e: string) => {
                    if (e === 'ABAC'){
                        rMap.push('AB');
                        rMap.push('AC');
                    } else if (e === 'EBEC'){
                        rMap.push('EB');
                        rMap.push('EC');
                    } else if (['AD', 'GB', 'GC'].includes(e)) {
                        rMap.push(e);
                    } else if (e === "BX"){
                        rMap.push("BA");
                        rMap.push("BB");
                        rMap.push("BC");
                        rMap.push("BD");
                    }
                });
                setRoleMap(rMap);
            }
        }
    }, [authRole])

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => { };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    let navigate = useNavigate();

    const cellGetNameByCode = (props: any) => {
        let path = "";
        switch (props.data.code) {
            case "AB":
                path = RoutePath.AbacList
                break;
            case "AC":
                path = RoutePath.AbacList
                break;
            case "AD":
                path = RoutePath.AdList
                break;
            case "GB":
                path = RoutePath.GbList
                break;
            case "GC":
                path = RoutePath.GcList
                break;
            case "EB":
            case "EC":
            case "EE":
            case "ED":
                path = RoutePath.EbecList
                break;
            case "BA":
            case "BB":
            case "BC":
            case "BD":
                path= RoutePath.BxList
                break;
            case "BE":
                path = RoutePath.BeList
                break;
        }

        if (path){
            let params = "?path=root";
            if (searchParams.get("path") === "customer" && searchParams.get("customer_id"))
                params = "?path=customer&customer_id=" + searchParams.get("customer_id");
            
            path = path + params;
        }
        
        let isLink = false;
        if (authRole) {
            if (authRole['code_restriction_array']?.length === 0 || roleMap.includes(props.data.code))
                isLink = true;
        }
        
        if (isLink)
            return <Typography style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }} onClick={() => navigate(path)}>{props.data.category}</Typography>
        
        return <Typography>{props.data.category}</Typography>
    };

    const cellStatusRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword)
        if (props.data.restore_datetime === null && (
            ((props.data.code === "AB" || props.data.code === "AC") && props.data.status_name === "停電") || 
            (props.data.code === "AD" && props.data.status_name === "警報") || 
            (props.data.code === "GB" && props.data.status_name === "発生") || 
            (props.data.code === "GC" && props.data.status_name === "発生") ||
            ((props.data.code === "EB" || props.data.code === "EC") && (props.data.status_name === "軽漏電" || props.data.status_name === "重漏電")) ||
            ((["BA","BB","BC","BD"].includes(props.data.code)) && (props.data.status_name === "下限検出" || props.data.status_name === "警戒検出" || props.data.status_name === "微地絡警報" || props.data.status_name === "地絡警報"))
        )) {
                return (
                    <>
                        <WarningIcon fontSize="small" style={{ verticalAlign: "text-top", color: "red" }} />{" "}
                        {props.data.status_name}
                    </>
                );
        }
        return <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.data.status_name}</>;
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true, // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "No.",
            field: "id",
            rowDrag: false,
            width: colState ? colState[0].width : 80,
        },
        {
            headerName: "種別",
            field: "category",
            rowDrag: false,
            width: colState ? colState[1].width : 160,
            cellRenderer: cellGetNameByCode,
        },
        {
            headerName: "状況",
            field: "status_name",
            rowDrag: false,
            width: colState ? colState[2].width : 140,
            cellRenderer: cellStatusRenderer,
        },
        {
            headerName: "受信日時",
            field: "occurrence_time",
            rowDrag: false,
            width: colState ? colState[3].width : 200,
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: colState ? colState[4].width : 200,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            width: colState ? colState[5].width : 200,
        },
        {
            headerName: "装置ID",
            field: "mainid",
            rowDrag: false,
            width: colState ? colState[6].width : 110,
        },
        {
            headerName: "漏電端末番号",
            field: "terminal_no",
            rowDrag: false,
            width: colState ? colState[7].width : 120,
        },
        {
            headerName: "CH番号",
            field: "channel_no",
            rowDrag: false,
            width: colState ? colState[8].width : 90,
        },
        {
            headerName: "接点名称",
            field: "point_name",
            rowDrag: false,
            width: colState ? colState[9].width : 100,
        },
        {
            headerName: "警報発生時間",
            field: "warning_time",
            rowDrag: false,
            width: colState ? colState[10].width : 120,
        },
        {
            headerName: "目標電力",
            field: "target_kw",
            rowDrag: false,
            width: colState ? colState[11].width : 100,
        },
        {
            headerName: "現在電力",
            field: "current_kw",
            rowDrag: false,
            width: colState ? colState[12].width : 100,
        },
        {
            headerName: "予測電力",
            field: "predict_kw",
            rowDrag: false,
            width: colState ? colState[13].width : 100,
        },
        {
            headerName: "電流値",
            field: "leak",
            rowDrag: false,
            width: colState ? colState[14].width : 100,
        },
        {
            headerName: "解除区分",
            field: "restore_type",
            width: colState ? colState[15].width : 100,
        },
        {
            headerName: "解除日時",
            field: "restore_datetime",
            width: colState ? colState[16].width : 200,
        },
        {
            headerName: "確認コメント",
            field: "comment",
            width: colState ? colState[17].width : 550,
        },
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)

    return (
        <>
            <div>
                <div
                    className="ag-theme-alpine"
                    style={{ height: openSearch ? 'calc(100vh - 335px)' : 'calc(100vh - 240px)', width: "100%" }}
                >
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        cacheQuickFilter={true}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn
                    pageData={pageData}
                    onChangeSearchCondition={onChangeSearchCondition}
                    limit={pageSize}
                    exportCsv={{ gridApi: gridRef?.current?.api, name: "全般" }}
                    enabledExportCsv={true}
                />
            </div>
        </>
    );
});

export default AlarmGrid;
