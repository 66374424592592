import { Button, Checkbox, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState } from "../../../store";
import FetchHelper from "../../FetchHelper";
import { deviceBackgroundStyles, deviceContentStyles } from "../../styles";
import ObservationPointAndDevicesGrid from "./ObservationPointAndDevicesGrid";


const GET_OBSERVATION_POINTS_AND_DEVICES = "GET_OBSERVATION_POINTS_AND_DEVICES";
const CHANGE_OBSERVATION_POINT_ASSOCIATION = "CHANGE_OBSERVATION_POINT_ASSOCIATION";
// -------------
// Props
export type ObservationPointAndDevicesScreenProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const ObservationPointAndDevicesScreen = (props: ObservationPointAndDevicesScreenProps) => {
    const { onSearch, OnChangeObservationPointAssociation, onChangeSuccess, onChangeError } = props;
    localStorage.setItem('lastActiveFormPath', RoutePath.Weather);
    localStorage.setItem('title', '観測地点と装置の関連付け');
    const location = useLocation();
    const params = useParams();
    const gridRef = useRef<any>();
    const navigate = useNavigate();
    const [loadingFlag,setLoadingFlag] = useState<boolean>(false);
    const [listData,setListData] = useState<any>([]);
    const [colState, setColState] = useState<any>();
    const [keyword, setKeyword] = useState("");
    const [checkAll,setCheckAll] = useState<boolean>(false);
    const [observationPoint] = useState<any>(location.state.observationPoint);
    const [indeterminate,setIndeterminate] = useState<boolean>(false);
    const [leftPosition,setLeftPosition] = useState<number>(0);

    useEffect(() => {
        if(params && params.id){
            setLoadingFlag(true);
            onSearch(params.id);
        }
    },[]);

    useEffect(() => {
        const length_item_select = gridRef?.current?.getRowsInCurrent()?.map((item:any) => {return item.data}).filter((item:any) => item.isSelect == true).length;
        const length_total_item = gridRef?.current?.getRowsInCurrent()?.map((item:any) => {return item.data}).length;
        if(length_item_select == length_total_item || length_item_select == 0){
            setIndeterminate(false);
        }else{
            setIndeterminate(true);
        }
    },[gridRef?.current?.getRowsInCurrent()])

    const handleGetData = (data:any) => {
        const result:any = [];
        for(const item of data){
            const temp_data = {...item,isDisableSelect:item.ob_relation_state == "2",isSelect:item.ob_relation_state == "1"};            
            result.push(temp_data);
        }
        return result;
    }

    const handleSuccess = (data:any) => {
        setLoadingFlag(false);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        if(data && data.result_code == 0){
            setListData(() => [...handleGetData(data.data)]);
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([...handleGetData(data.data)]);
            }
        }
    }

    const handleError = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    // 検索正常終了時
    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        let list = listData.filter((item:any) => item.mainid === data.mainid)
        list[0].isSelect = !list[0].isSelect;

        if (gridRef && gridRef.current) {
            gridRef.current.setSelectRowData(data.mainid);
        }
    };

    const handleSelectManyRows = (list_mainid:any,value:boolean) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const filteredList = listData.filter((itemA:any) => 
            list_mainid.some((itemB:any) => 
                itemA.mainid === itemB
            )
        );
        
        for(const item of filteredList){
            item.isSelect = value;
        }

        if (gridRef && gridRef.current) {
            gridRef.current.setSelectAllRowData(list_mainid,value);
        }
    }

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
    }

    const handleSuccessChangeObservation = (data:any) => {
        setLoadingFlag(false);
        if(data && data.result_code == 0){
            onChangeSuccess();
        }else{
            onChangeError();
        }
    }

    const handleErrorChangeObservation = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
        onChangeError();
    }

    const handleSaveLeftPosition = (left_position:any) => {
        setLeftPosition(left_position);
    }

    return (
        <>
            {(loadingFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}

            <Grid container direction="row" mt={2} mb={2}>
                <Grid minWidth="22rem" width="50%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                    <Grid xs={4} style={deviceBackgroundStyles}>
                        <Typography style={deviceContentStyles}>観測地点名</Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Typography style={deviceContentStyles}>{observationPoint.ob_point_name}</Typography>
                    </Grid>
                </Grid>
                <Grid minWidth="22rem" width="50%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                    <Grid xs={4} style={deviceBackgroundStyles}>
                        <Typography style={deviceContentStyles}>住所</Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Typography style={deviceContentStyles}>{observationPoint.address_in}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" alignItems={"center"} direction="row" spacing={1} mb={2} >
                <Grid item>
                    <TextField
                        fullWidth
                        autoComplete="searchStr"
                        name="keyword"
                        type="text"
                        label="フィルター"
                        size="small"
                        value={keyword}
                        onChange={e => handleChangeKeyword(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                </Grid>
            </Grid>

            <div style={{position:"relative"}}>
                <div style={{position:"absolute",backgroundColor:"#F9FAFB",width:"16px",height:"48px",top:0, left:-16,zIndex:110 }}></div>
                <Checkbox indeterminate={indeterminate} checked={checkAll} style={{position:"absolute", zIndex:100, top:5, left:8 - leftPosition, height:"38px", width:"38px"}} onClick={() => {setCheckAll((prev) => (!prev))}} />
                <ObservationPointAndDevicesGrid
                    ref={gridRef}
                    colState={colState}
                    keyword={keyword}
                    checkAll={checkAll}
                    onSelect={handleSelect}
                    onSelectManyRows={handleSelectManyRows}
                    onSendLeftPosition={handleSaveLeftPosition}
                />
            </div>

            <Stack spacing={2} mt={2}  direction="row" display={"flex"} justifyContent={"flex-end"}>
                <Button variant={"contained"} style={{marginBlock: 'auto'}} onClick={() => {
                    const list_mainid = listData.filter((item:any) => item.isSelect === true).map((item:any) => item.mainid);
                    const param_send = {
                        id:params.id,
                        relation_gw_array:list_mainid
                    }
                    setLoadingFlag(true);
                    OnChangeObservationPointAssociation(param_send);
                }}>保存</Button>
                <Button variant={"outlined"} style={{marginBlock: 'auto'}} onClick={() => {navigate(RoutePath.Weather);}}>戻る</Button>
            </Stack>

            <FetchHelper fetchId={GET_OBSERVATION_POINTS_AND_DEVICES} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />

            <FetchHelper fetchId={CHANGE_OBSERVATION_POINT_ASSOCIATION} onComplete={(success, data, error) =>
                success ? handleSuccessChangeObservation(data) : handleErrorChangeObservation(success, data, error)} />

        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({

});

const mapDispatchToProps = {
    onSearch: (id:any) => actionCreators.fetch(GET_OBSERVATION_POINTS_AND_DEVICES,`/master/weathers-gw/get?id=${id}`,"GET",null),
    OnChangeObservationPointAssociation: (params:any) => actionCreators.fetch(CHANGE_OBSERVATION_POINT_ASSOCIATION,`/master/weathers-gw/edit`,"POST",params,false,true),
    onChangeSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "観測地点の関連付け変更",
            body: "観測地点の関連付けを更新しました。",
        }),
    onChangeError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "観測地点の関連付け変更",
            body: "観測地点の関連付けを更新できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ObservationPointAndDevicesScreen as any);
