import * as React from "react";
import { connect } from "react-redux";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";

import { Button, Card, CardContent, CardHeader, Grid, Stack, TextField, ToggleButton, ToggleButtonGroup, CircularProgress } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import DeviceInformation from "../device/DeviceInformation";
import { Customer } from "../../../models";

// FETCH ID
const fetchIdCh1 = "POINTS_ITEM_CH1";
const fetchIdCh2 = "POINTS_ITEM_CH2";
const menusFetchId = 'MENUS_FETCH';

// フォーム定義
interface EditPointsForm {
    name1: string,
    alarmOn1: string,
    alarmMessage1: string,
    restoreMessage1: string,
    name2: string,
    alarmOn2: string,
    alarmMessage2: string,
    restoreMessage2: string,
}

// 初期値設定
const initialValue: EditPointsForm = {
    name1: "",
    alarmOn1: "0",
    alarmMessage1: "",
    restoreMessage1: "",
    name2: "",
    alarmOn2: "0",
    alarmMessage2: "",
    restoreMessage2: "",
}

// -------------
// Props
export type PointsDetailsProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const PointsDetails = (props: PointsDetailsProps) => {
    const { onGetData, onError, onUpdate, onUpdateSuccess, onUpdateError, fetchStateMenu, onGetAuthRole } = props;

    const urlParams = new URLSearchParams(window.location.search);
    const mainid = urlParams.get('mainid');
    const [device, setDevice] = React.useState<any>();
    const [fetchCh1, setFetchCh1] = React.useState<boolean>(true);
    const [fetchCh2, setFetchCh2] = React.useState<boolean>(true);



    //ref変数
    let navigate = useNavigate();

    const [authRole, setAuthRole] = React.useState<any>();
    const [disableSaveBtn, setDisableSaveBtn] = React.useState(true);

    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        if (authRole !== undefined && device !== undefined){
            if (authRole.auth_role_map['GW-adsave-all']){
                setDisableSaveBtn(false);
            } else {
                if (authRole.auth_role_map['GW-adsave-belong']){
                    if (authRole.company_id == device.customer_id)
                        setDisableSaveBtn(false);
                }
                if (authRole.auth_role_map['GW-adsave-group']){
                    if (authRole.company_group_id == device.group_id)
                        {
                            setDisableSaveBtn(false);
                        }
                }
                if (authRole.auth_role_map['GW-adsave-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == device.customer_id).length > 0)
                        setDisableSaveBtn(false);
                }
            }
        }
    },[authRole, device])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    const getOrBlank = (value: string) => {
        if (value) {
            return value;
        } else return '';
    }

    const getOrDefaultAlarm = (value: string) => {
        if (value) {
            return value;
        } else return '1';
    }

    const { handleSubmit, control, setValue } = useForm<EditPointsForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (data: EditPointsForm) => {
        setFetchCh1(true);
        setFetchCh2(true);
        const request = createRequestData(data);
        onUpdate(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: EditPointsForm) => {
        let request = {
            mainid: mainid,
            point_info_array: [
                {
                    ch: '1',
                    name: getOrBlank(formValue.name1),
                    alarm_on: getOrDefaultAlarm(formValue.alarmOn1),
                    alarm_message: getOrBlank(formValue.alarmMessage1),
                    restore_message: getOrBlank(formValue.restoreMessage1),
                },
                {
                    ch: '2',
                    name: getOrBlank(formValue.name2),
                    alarm_on: getOrDefaultAlarm(formValue.alarmOn2),
                    alarm_message: getOrBlank(formValue.alarmMessage2),
                    restore_message: getOrBlank(formValue.restoreMessage2),
                }
            ]
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => navigate(RoutePath.DeviceList);

    const handleError = (success: boolean, data: any, error: any) => {
        setFetchCh1(false);
        setFetchCh2(false);
        onError(success, data, error);
    }

    //タイトルの高さを戻
    React.useEffect(() => {
        onGetData(fetchIdCh1, handleFetchParam('mainid', mainid, '1'));
        setFetchCh1(true);
        onGetData(fetchIdCh2, handleFetchParam('mainid', mainid, '2'));
        setFetchCh2(true);
        return () => {
            let div: any = document.getElementsByClassName('css-ua3wu6')[0];
            if (typeof (div) !== 'undefined' && div != null) {
                div.removeAttribute("style");
            }
        };
    }, []);

    const handleFetchParam = (id: string, value: any, ch?: string) => {
        const params = new Map<string, any>();
        params.set(id, value);
        if (ch) {
            params.set('ch', ch);
        }
        return params;
    }

    const handleFetchPointsSuccess = (data: any, fetchId: string) => {
        if (fetchId === fetchIdCh1) {
            setValue('name1', data.name);
            setValue('alarmOn1', data.alarm_on);
            setValue('alarmMessage1', data.alarm_message);
            setValue('restoreMessage1', data.restore_message);
            setFetchCh1(false);
        } else if (fetchId === fetchIdCh2) {
            setValue('name2', data.name);
            setValue('alarmOn2', data.alarm_on);
            setValue('alarmMessage2', data.alarm_message);
            setValue('restoreMessage2', data.restore_message);
            setFetchCh2(false);
        }
    }

    const handleUpdateSuccess = (data: any) => {
        const result = {
            resultCode: data.result_code,
            updateData: data.data,
        };
        if (result.resultCode === 1) {
            handleUpdateError();
        } else {
            setFetchCh1(false);
            setFetchCh2(false);
            onUpdateSuccess();
        }
    }

    const handleUpdateError = () => {
        setFetchCh1(false);
        setFetchCh2(false);
        onUpdateError();
    }

    const handleFetchDeviceSuccess = (data: any) => {
        setDevice(data)
    }

    const handleSelectOption = (e: any, option: any, name: any) => {
        // 値変更
        if(option !=null){
            setValue(name, option);
        }
    };

    return (
        <div style={{ margin: 'auto 10px' }} >
            <FetchHelper fetchId={'DEVICE_FETCH'}
                url={"/master/gateways/get"}
                method="GET"
                request={true}
                params={handleFetchParam('id', mainid)}
                onComplete={(success, data, error) => {
                    success ? handleFetchDeviceSuccess(data) : handleError(success, data, error)
                }}
            />
            <DeviceInformation deviceInformation={device} mainid={mainid ?? ''} title="接点編集" isDisplayDeviceInfo={true} isShowMenuButton={true} ></DeviceInformation>
            {(fetchCh1 || fetchCh2) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <form onSubmit={handleSubmit(handleEditSubmit)} >
                <Grid>
                    <Card style={{ margin: '12px auto 12px', width: '100%' }}>
                        <CardHeader
                            title='ch1'
                        />
                        <CardContent style={{ paddingTop: '0' }}>
                            <Stack spacing={3}>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            name="name1"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="接点名称"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '8px' }}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            control={control}
                                            name="alarmOn1"
                                            render={({ field: { value } }) => (
                                                <Grid container spacing={1} direction="row" >
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        value={value}
                                                        exclusive
                                                        onChange={(e, value) => handleSelectOption(e, value, 'alarmOn1')}
                                                        aria-label="Platform"
                                                        size="small"
                                                        aria-labelledby="デマンド/太陽"
                                                    >
                                                        <ToggleButton value="1">接点 OFF→ON 警報, ON→OFF 復帰</ToggleButton>
                                                        <ToggleButton value="0">接点 ON→OFF 警報, OFF→ON 復帰</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            name="alarmMessage1"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="警報メッセージ"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            name="restoreMessage1"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="復帰メッセージ"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card >
                    <Card style={{ margin: '0 auto 12px', width: '100%' }}>
                        <CardHeader
                            title='ch2'
                        />
                        <CardContent style={{ paddingTop: '0' }}>
                            <Stack spacing={3}>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            name="name2"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="接点名称"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '8px' }}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            control={control}
                                            name="alarmOn2"
                                            render={({ field: { value } }) => (
                                                <Grid container spacing={1} direction="row" >
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        value={value}
                                                        exclusive
                                                        onChange={(e, value) => handleSelectOption(e, value, 'alarmOn2')}
                                                        aria-label="Platform"
                                                        size="small"
                                                        aria-labelledby="デマンド/太陽"
                                                    >
                                                        <ToggleButton value="1">接点 OFF→ON 警報, ON→OFF 復帰</ToggleButton>
                                                        <ToggleButton value="0">接点 ON→OFF 警報, OFF→ON 復帰</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            name="alarmMessage2"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="警報メッセージ"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                    <Grid container spacing={1} direction="row" >
                                        <Controller
                                            name="restoreMessage2"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="復帰メッセージ"
                                                    size="small"
                                                    inputProps={{ maxLength: 255 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card >
                </Grid>
                <Stack direction='row' justifyContent="flex-end" spacing={2} width="100%" margin="10px auto 0">
                    <Grid item xs={1.5}>
                        <Button fullWidth variant="contained" type="submit" disabled={disableSaveBtn}>保存</Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button fullWidth color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Grid>
                </Stack>
            </form >
            <FetchHelper fetchId={fetchIdCh1} onComplete={(success, data, error) => success ? handleFetchPointsSuccess(data, fetchIdCh1) : handleError(success, data, error)} />
            <FetchHelper fetchId={fetchIdCh2} onComplete={(success, data, error) => success ? handleFetchPointsSuccess(data, fetchIdCh2) : handleError(success, data, error)} />
            <FetchHelper fetchId="UPDATE_POINT" onComplete={(success, data, error) => success ? handleUpdateSuccess(data) : handleUpdateError()} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </div >
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onGetData: (fetchId: string, params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/master/points/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "接点情報",
            body: "接点情報が取得出来ません。",
        }),
    onUpdate: (data: any) => actionCreators.fetch("UPDATE_POINT", `/master/points/edit`, 'POST', data, false, true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "接点変更",
            body: "接点を更新しました。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "接点更新",
            body: "接点を更新出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PointsDetails as any);