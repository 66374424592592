import React from 'react'
import { connect } from 'react-redux';
import { Snackbar, AlertTitle } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { actionCreators, ApplicationState, AppState } from '../../store';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ToastContainerActions {
  onCloseMessage: (messageId: string) => void
}

export type ToastContainerProps =
  ToastContainerActions &
  AppState;

export const ToastContainer = (props: ToastContainerProps) => {
  const { messages, onCloseMessage } = props

  return (
    <div className="position-absolute">
      {/* <div aria-live="polite" aria-atomic="true" style={{ position: 'relative', minHeight: '200px' }}>
        <div style={{ position: 'fixed', bottom: '40%', left: '50%', zIndex: 100, }}> */}
          {messages && messages.map(m => {
            return (
              <Snackbar
                open={true}
                onClose={() => m.id && onCloseMessage(m.id)}
                title={m.title}
              >
                <Alert onClose={() => m.id && onCloseMessage(m.id)} severity={m.type}>
                  <AlertTitle>{m.title}</AlertTitle>
                  {m.body}
                </Alert>
              </Snackbar>
            )
          })}
        {/* </div>
      </div> */}
    </div>
  )
}

export default connect(
  (state: ApplicationState) => state.app,
  dispatch => ({
    onCloseMessage: messageId => dispatch(actionCreators.dismissMessage(messageId))
  } as ToastContainerActions)
)(ToastContainer as any)