import { Chart } from "chart.js";
export const createEarthFaultChart = (data) => {
    if (!data) {
        return;
    }

    document.getElementById("earthFaultChart").remove();     
    let canvas = document.createElement('canvas');     
    canvas.setAttribute('id','earthFaultChart');     
    canvas.setAttribute('height','220px');    
    document.querySelector('#chartContainer').appendChild(canvas);

    const dataChart1 = {
        labels: data.labels,
        datasets: [{
            data: data.dataChart.supply,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 5,
            pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
        }]
    };

    const maxValue = Math.max( ...dataChart1.datasets[0].data );

    new Chart(canvas, {
        type: 'line',
        data: dataChart1,
        options:{
            plugins: {
                responsive: true,
                title:{
                    display:false
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    displayColors: true,
                    backgroundColor: 'rgba(242, 242, 242, 0.5)',
                    bodyColor: '#1a1a1a',
                    borderWidth: 1,
                    borderColor: 'rgba(143, 190, 1, 0.7)',
                    padding: 15,
                    titleFont: {
                        size: 16,
                        weight: 'bold',
                    },
                    bodyFont: {
                        size: 14,
                    },
                    titleColor: '#1a1a1a',
                    titleFontStyle: 'bold',
                    usePointStyle: 'true',
                    callbacks: {
                        title:function (context) {
                            return  "微地絡電流";
                        },
                        beforeBody:function (context) {
                            const notify_time = data.dataChart.notify_time;
                            const row1 = "発生日　　　　　　：" + data.dataChart.notify_date;
                            let row2 = "";
                            const row3 = "電流値(mA)　　　   ：" + context[0].parsed.y + " mA";

                            if(context[0].dataIndex != 10){
                                row2 = `発生時刻　　　　　：${notify_time}`;
                            }else{
                                let hours = Number(notify_time.substring(0,2));
                                let minutes = Number(notify_time.substring(3,5));
                                let seconds = Number(notify_time.substring(6,8)) + 1;
                                if( seconds == 60){
                                    seconds = 0;
                                    minutes = minutes + 1;
                                    if(minutes == 60){
                                        minutes = 0;
                                        hours += 1;
                                        if(hours == 24){
                                            hours = 0;
                                        }
                                    }
                                }

                                if(hours < 10){
                                    hours = '0' + hours.toString();
                                }
            
                                if(minutes < 10){
                                    minutes = '0' + minutes.toString();
                                }
            
                                if(seconds < 10){
                                    seconds = '0' + seconds.toString();
                                }
                                
                                const temp_notify_time = hours + ":" + minutes + ":" + seconds;
                                row2 = `発生時刻　　　　　：${temp_notify_time}`;
                            }
                            return  row1 + "\n" + row2 + "\n" + row3;
                        },
                        label:function(context) {
                            return null;
                        }
                    },
                },            
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: '計測時間 →',
                        align:"end"
                    },

                },
                y: {
                    title: {
                        display: true,
                        text: '電流(mA) →',
                        align:"end",
                    },
                    min:0,
                    max: maxValue < 200 ? 200 : maxValue + 100,
                }
            },
        }
    });

}