import WarningIcon from "@mui/icons-material/Warning";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import { convertTimeToSecond, formatTime } from "../../../utils";
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import PaginationCpn from '../../common/PaginationCpn';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { customerLabelName } from "../../../statics";

// -------------
// Props
export type AeGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    openSearch: boolean;
};

// -------------
// Component
export const AeGrid = forwardRef((props: AeGridProps, ref) => {
    const { colState, pageSize, pageData, onChangeSearchCondition, keyword, openSearch } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const setFormatTime = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword)
        if (props.data.send_time && props.data.send_time.length === 6) {
            const house = props.data.send_time.slice(0, 2);
            const minute = props.data.send_time.slice(2, 4);
            const second = props.data.send_time.slice(4, 6);
            return house + ":" + minute + ":" + second;
        }
        return "";
    };

    const cellMainIdRenderer = (props: any) => {
        if (props.data.id === null) {
            return (
                <>
                    <WarningIcon fontSize="small" style={{ verticalAlign: "text-top", color: "red" }} />{" "}
                    {props.data.mainid}
                </>
            );
        }
        return <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.data.mainid}</>;
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "No.",
            field: "id",
            rowDrag: false,
            width: colState ? colState[0].width : 120,
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: colState ? colState[1].width : 405,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            width: colState ? colState[2].width : 300,
        },
        {
            headerName: "装置ID",
            field: "mainid",
            rowDrag: false,
            cellRenderer: cellMainIdRenderer,
            width: colState ? colState[3].width : 300,
        },
        {
            headerName: "送信時刻",
            field: "send_time",
            rowDrag: false,
            valueGetter: setFormatTime,
            width: colState ? colState[4].width : 280,
            comparator:(valueA:any,valueB:any) => {
                const a = convertTimeToSecond(valueA);
                const b = convertTimeToSecond(valueB);
                if (a == b) return 0;
                return (a > b) ? 1 : -1;
            }
        },
        {
            headerName: "受信日時",
            field: "registered",
            rowDrag: false,
            width: colState ? colState[5].width : 300,
        },
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 280px)' : 'calc(100vh - 240px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        cacheQuickFilter={true}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn
                    pageData={pageData}
                    onChangeSearchCondition={onChangeSearchCondition}
                    limit={pageSize}
                    exportCsv={{ gridApi: gridRef?.current?.api, name: "死活監視" }}
                    enabledExportCsv={true}
                />
            </div>
        </>
    );
});

export default AeGrid;
