import { Button, Grid, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { DeviceNameModel } from "../../../models";
import { formatDateTime } from "../../../utils";

export type DeviceNameEditFormProps = {
    deviceName: any;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const DeviceNameEdit = (props: DeviceNameEditFormProps) => {
    const { deviceName, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: DeviceNameModel = {
        mainid: deviceName.mainid,
        deviceNo: deviceName.deviceNo,
        deviceName: deviceName.deviceName,
        lastGetDatetime: deviceName.lastGetDatetime,
    }

    const { register, handleSubmit, formState: { errors } } = useForm<DeviceNameModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: DeviceNameModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: DeviceNameModel) => {

        let request = {
            mainid: formValue.mainid,
            device_no: formValue.deviceNo,
            device_name: formValue.deviceName,
            last_get_datetime: formatDateTime(new Date()),
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '90%', margin: 'auto' }}>
                    <Stack spacing={3}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} paddingTop={"10px"}>
                            <Grid container spacing={1} direction="row" >
                                <TextField
                                    {...register("deviceName",
                                        { required: '必須項目です。入力してください。' })
                                    }
                                    fullWidth
                                    type="text"
                                    label="DeviceName"
                                    size="small"
                                    error={!!errors?.deviceName}
                                    helperText={errors?.deviceName?.message}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        <Button variant="contained" type="submit">保存</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default DeviceNameEdit;


