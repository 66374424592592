import * as React from "react";
import { Box, Stack, Button, Typography, Grid, ToggleButtonGroup, ToggleButton, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";

// FORM ID

export type ChangePasswordByAdminProps = {
  onOK: (data: any) => void;
  onCancel: () => void;
  user: any;
}


// 初期値設定
const initialValueForm: any = {
  userFullName:"",
  email:"",
  send_mail_flag:"1",
  newPassword: "",
  confirmPassword: ""
};

// 初期パスワード更新 Request
interface ChangePasswordByAdminRequest {
  cognito_username:any;
  new_password: string;
  send_mail_flag: string;
  AccessToken:any;
};

export const ChangePasswordByAdmin = (props: ChangePasswordByAdminProps) => {
  const { user, onOK, onCancel } = props;
  // FORM 入力チェック
  const { handleSubmit, formState: { errors }, control, setValue, getValues ,setError,trigger } = useForm<any>({
    mode: 'all',
    criteriaMode: "all",
    defaultValues: initialValueForm,
});

  const formRef = React.useRef(null);

  React.useEffect(() => {
    setValue("userFullName",user.user_full_name);
    setValue("email",user.email);
  },[])

  // Submitイベント
  const handleEditSubmit = (value: any) => {
    // 更新後と確認用パスワードで一致しているか検証
    
    const params = createRequestData(value);
    onOK(params);
  }

  // FormデータからRequestデータを作成する
  const createRequestData = (formValue: any) => {
    let request = [{
      new_password: formValue.newPassword,
      cognito_username:user.username,
      send_mail_flag: formValue.send_mail_flag,
    }]as any
    return request;
  }

  //Enterキーを防ぐ
  const handleKeyDown = (event: React.KeyboardEvent) => {
    const target = event.target as any;
    if (event.key === 'Enter' && event.shiftKey === false && target.localName != "textarea") {
      event.preventDefault();
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };

  const handleChangeRestricted = (e: any, value: any) => {
    if (value != null) {
        setValue('send_mail_flag', value);
    }
};

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <form ref={formRef} onSubmit={handleSubmit(handleEditSubmit)} onKeyDown={e => { handleKeyDown(e) }}>
          <Stack>
              <Stack mt="5px" mb="3px" paddingTop={"5px"} width="100%" spacing={3}>
                  <Controller
                      control={control}
                      name="userFullName"
                      render={({field}) =>(
                          <TextField
                              style={{backgroundColor:"floralwhite"}}
                              {...field}
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              type="text"
                              label="氏名"
                              size="small"
                          />
                          )}
                  />
                  <Controller
                      control={control}
                      name="email"
                      render={({field}) => (
                          <TextField 
                              style={{backgroundColor:"floralwhite"}}
                              {...field}
                              fullWidth
                              type="text"
                              label="Eメール"
                              size="small"
                              InputProps={{
                                readOnly: true,
                              }}
                          />
                      )}
                  />
                  <Typography>通知メールの送信</Typography>
                  <Stack direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px', marginTop: '10px' }}>
                      <Controller
                          control={control}
                          name="send_mail_flag"
                          render={({ field: { value } }) => (
                              <Grid container spacing={1} direction="row" >
                                  <ToggleButtonGroup
                                      color="primary"
                                      value={value}
                                      exclusive
                                      onChange={(e, value) => handleChangeRestricted(e, value)}
                                      size="small"
                                  >
                                      <ToggleButton value="0">なし</ToggleButton>
                                      <ToggleButton value="1">あり</ToggleButton>
                                  </ToggleButtonGroup>
                              </Grid>
                          )}
                      />
                  </Stack>
                  <Controller
                      control={control}
                      name="newPassword"
                      rules={{
                          required:"必須項目です。入力してください。"
                      }}
                      render={({field}) => (
                          <TextField 
                              {...field}
                              fullWidth
                              type="password"
                              label="新しいパスワード"
                              size="small"
                              inputProps={{
                                maxLength:255
                              }}
                              error={!!errors?.newPassword}
                              helperText={errors?.newPassword?.message}
                          />
                      )}
                  />
                  <Controller
                      control={control}
                      name="confirmPassword"
                      rules={{
                          required:"必須項目です。入力してください。",
                          validate: (item) => getValues("newPassword") == item || "パスワードが一致しません。"
                      }}
                      render={({field}) => (
                          <TextField 
                              {...field}
                              fullWidth
                              type="password"
                              label="新しいパスワード（確認用）"
                              size="small"
                              inputProps={{
                                maxLength:255
                              }}
                              error={!!errors?.confirmPassword}
                              helperText={errors?.confirmPassword?.message}
                          />
                      )}
                  />
              </Stack>
              <Typography mt={"20px"}>※パスワードは8文字以上で設定してください。</Typography>
              <Typography>※パスワードには、半角英字の大文字・小文字と半角数字を含めてください。</Typography>
              <Box height="26px" />
              <Stack direction='row' justifyContent="flex-end" spacing={1} >
                <LoadingButton variant="contained" type="submit" sx={{ padding: '8px 20px' }}>更新</LoadingButton>
                <Button color="inherit" variant="contained" onClick={handleCancelClick}>キャンセル</Button>
              </Stack>
          </Stack>
        </form >
      </div>
    </>
  );
};

export default ChangePasswordByAdmin;
