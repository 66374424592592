import { Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import * as React from 'react';
import { useNavigate } from 'react-router';
import { Path as RoutePath } from "../../path";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { connect } from "react-redux";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../store";

const menusFetchId = 'MENUS_FETCH';

type params = {
    mainid: string,
    title: string,
    pulseType:string,
    isYearlyReport:boolean,
    isShowMenuButton?:boolean,
    titleFlag?:boolean|false,
}

export type BackButtonProps = params & ReturnType<typeof mapStateToProps> 
& typeof mapDispatchToProps;

export const BackButton = (props: BackButtonProps) => {
    const { isYearlyReport, pulseType, mainid, title,isShowMenuButton, fetchStateMenu,titleFlag} = props;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenuOptions = Boolean(anchorEl);

    let navigate = useNavigate();

    const [pollingMenuDisplay, setPollingMenuDisplay] = React.useState<any[]>([]);

    React.useEffect(() => {
        handleShowLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleShowLink = () => {
        setPollingMenuDisplay([])
        if(pulseType == "1"){
            if(!isYearlyReport){
                setPollingMenuDisplay(
                    [
                        {
                            value:"smartDemand",
                            label:"スマートデマンド"
                        },
                        {
                            value:"yearlyReport",
                            label:"年表"
                        }
                    ]
                )
            }else{
                setPollingMenuDisplay(
                    [
                        {
                            value:"smartDemand",
                            label:"スマートデマンド"
                        },
                        {
                            value:"monthlyReport",
                            label:"月表"
                        }
                    ]
                )
            }
        }
        if(pulseType == "2"){
            if(!isYearlyReport){
                setPollingMenuDisplay(
                    [
                        {
                            value:"smartSolar",
                            label:"発電量"
                        },
                        {
                            value:"yearlyReport",
                            label:"年表"
                        },
                    ]
                )
            }else{
                setPollingMenuDisplay(
                    [
                        {
                            value:"smartSolar",
                            label:"発電量"
                        },
                        {
                            value:"monthlyReport",
                            label:"月表"
                        }
                    ]
                )
            }
            
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRedirectMenu = (val: string) => {
        switch (val) {
            case "smartDemand":
                navigate(`${RoutePath.Gaex1}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
                break;
            case "smartSolar":
                navigate(`${RoutePath.Gaex3}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
                break;
            case "monthlyReport":
                navigate(`${RoutePath.Gaex2}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
                break;
            case "yearlyReport":
                navigate(`${RoutePath.Gaex5}${urlParams.get('path') ? '?path=' + urlParams.get('path') + '&' : '?'}mainid=${mainid}&customer_id=${urlParams.get("customer_id")}${id ? '&id=' + id : ""}`);
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <div>
            <Stack direction='row' justifyContent="flex-start" spacing={0} alignItems='center'>
                    {!titleFlag && <Typography variant="h5">{title}</Typography>}
                    { isShowMenuButton && <IconButton style={{ borderRadius: '0', border: '2px solid gray', color: 'gray', width: '28px', height: '24px' }} onClick={handleClick}>
                        <MoreHorizIcon/>
                    </IconButton> 
                    }
                </Stack>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenuOptions}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {pollingMenuDisplay.map(option => (
                        <MenuItem key={option.value} onClick={() => handleRedirectMenu(option.value)}>{option.label}</MenuItem>
                    ))}
                </Menu>
            
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BackButton);