import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';
import { customerLabelName } from '../../../statics';

// -------------
// Props
export type SettingSolarGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    keyword:string;
    onShowDetails: (value: string, redirectId: string) => void;
    onChangeSearchCondition: (name: string, value: any) => void;
    openSearch: boolean;
};

// -------------
// Component
export const SettingSolarGrid = forwardRef((props: SettingSolarGridProps, ref) => {
    const { colState, pageSize, pageData, keyword, openSearch, onChangeSearchCondition,onShowDetails } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();
    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword)
        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data, props.colDef.field);
        };

        return (
            <>
                <Grid container direction="row" justifyContent="flex-start">
                    <IconButton aria-label="edit" onClick={handleEdit} >
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };


    // 列の個別定義
    const columnDefs = [
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[0].width : 500,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[1].width : 415,
        },
        {
            headerName: "装置ID",
            field: "id",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[2].width : 200,
        },
        {
            headerName: "IPアドレス",
            field: "ip_address",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[2].width : 150,
        },
        {
            headerName: "パルス種別",
            field: "pulse_type_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[2].width : 150,
        },
        {
            headerName: "太陽光編集",
            field: "demand_edit",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            sortable:false,
            width: colState ? colState[2].width : 150,
            cellRenderer:cellMessageRenderer
        },
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 277px)' : 'calc(100vh - 232px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />
            </div>
        </>
    );
});

export default SettingSolarGrid;
