import AcUnitIcon from '@mui/icons-material/AcUnit';
import LinkIcon from '@mui/icons-material/Link';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Breakpoint, Button, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as CloudIcon } from "../../../assets/cloud.svg";
import { ReactComponent as UmbrellaIcon } from "../../../assets/umbrella.svg";
import useResponsive from '../../../hooks/useResponsive';
import { formatRoundDownDecimal } from '../../../utils';

const buttonStyle2 = {
    backgroundColor:  "white",
    color: '#7D7C7C',
    height: '40px',
    margin: 'auto 0',
    border: '0.5px solid #B4B4B3',
    borderRadius:'0px 0px 0px 0px',
}

const buttonStyle3 = {
    backgroundColor:  "white",
    color: '#7D7C7C',
    height: '40px',
    margin: 'auto 0',
    border: '0.5px solid #B4B4B3',
    borderRadius: '0px 9px 9px 0px',
}

const buttonStyle4 = {
    backgroundColor:  "white",
    color: '#7D7C7C',
    height: '40px',
    margin: 'auto 0',
    border: '0.5px solid #B4B4B3',
    borderRadius: '9px 9px 9px 9px',
}

export type ProgressProps = {
    progressData: any;
    onlyBackFlag: boolean;
    stopFlag: boolean;
    onRefreshChart: () => void;
    onBack: () => void;
    onMonthlyReport: () => void;
    onYearlyReport: () => void;
    onCopyLink: () => void;
};

export const Progress = (props: ProgressProps) => {
    const { stopFlag, onlyBackFlag, progressData, onRefreshChart, onBack, onMonthlyReport, onYearlyReport, onCopyLink } = props;
    const buttonStyle1= {
        backgroundColor:  "white",
        color: '#7D7C7C',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #B4B4B3',
        borderRadius: onlyBackFlag ? '9px': "9px 0 0 9px",
    }
    const [data, setData] = useState<any>();
    const [scaleInfoList, setScaleInfoList] = useState<any>([]);
    const [scaleInfoListMobileSize, setScaleInfoListMobileSize] = useState<any>([]);
    const myInterval = useRef<any>();
    const widthOneRectMobileSize = 65;
    const widthOneRectOtherSize = 130;
    const isMobile = useResponsive({ query: 'down', key: "md" as Breakpoint});

    const getColor = (value:any) => {
        if(Number(value) >=0 && Number(value) <= 1200)
            return "#D0F288";
        else if( Number(value) <= 1600)
            return "#FEFF89";
        else if(Number(value) <=2000){
            return "#FF8787";
        }
    }

    useEffect(() => {
        let value = {
            plant_name: progressData?.plant_name,
            date: "",
            time: "",
            current_kw: 0,
            today_kwh: 0,
            month_kwh: 0,
            estimated_amount: 0,
            actual_amount: 0,
            scale_info: [],
        }
        if(progressData?.year){
            value = {
                plant_name: progressData.plant_name,
                date: progressData.month + "月" + progressData.day + "日",
                time: progressData.time.split(':')[0] + "時" + progressData.time.split(':')[1] + "分台",
                current_kw: progressData.current_kw,
                today_kwh: progressData.today_kwh,
                month_kwh: progressData.month_kwh,
                estimated_amount: progressData.estimated_amount,
                actual_amount: progressData.actual_amount,
                scale_info: progressData.scale_info,
            }
        }
        if(progressData?.scale_info){
            let currentkwValue = progressData.current_kw;
            let totalkwValue = 0;
            let scaleInfoListTemp = [] as any;
            let scaleInfoListTempMobileSize = [] as any;


            progressData.scale_info.forEach((item: any, index: any) => {
                if(index !== 0 ) {
                    if (currentkwValue !== 0){
                        if(currentkwValue >= (item.scale - totalkwValue)){
                            scaleInfoListTemp.push({color:getColor(item.scale) ,width: `${widthOneRectOtherSize}px`, scale: item.scale})
                            scaleInfoListTempMobileSize.push({color:getColor(item.scale) ,width: `${widthOneRectMobileSize}px`, scale: item.scale})
                            currentkwValue = currentkwValue - (item.scale - totalkwValue);
                            totalkwValue = item.scale;
                        }
                        else{
                            scaleInfoListTemp.push({color:getColor(item.scale),width: ((currentkwValue * widthOneRectOtherSize/*1.3: 100px->130px*/) / (item.scale - progressData.scale_info[index - 1].scale)) + 'px', scale: item.scale})
                            scaleInfoListTempMobileSize.push({color:getColor(item.scale),width: ((currentkwValue * widthOneRectMobileSize/* 65px */) / (item.scale - progressData.scale_info[index - 1].scale)) + 'px', scale: item.scale})

                            currentkwValue = 0;
                        }
                    }
                    else if(currentkwValue === 0){
                        scaleInfoListTemp.push({color: '' ,width: '0px', scale: item.scale })
                        scaleInfoListTempMobileSize.push({color: '' ,width: '0px', scale: item.scale })

                    }
                }
            });
            setScaleInfoList(()=>[...scaleInfoListTemp]);
            setScaleInfoListMobileSize(() => [...scaleInfoListTempMobileSize])
        }
        setData(value);
        refreshChart();
        // eslint-disable-next-line
    }, [progressData]);

    useEffect(()=>{
        refreshChart();
        return () => {
            clearInterval(myInterval.current);
          };
    }, [stopFlag]);

    const refreshChart = () => {
        clearInterval(myInterval.current);
        if(stopFlag){
            let str = progressData.time.split(':');
            const dayServer = progressData.day;
            const monthServer = progressData.month;
            if(str.length === 2){
                const currentTime = new Date();
                const dayLocal = currentTime.getDate();
                const monthLocal = currentTime.getMonth() + 1;
                const yearLocal = currentTime.getUTCFullYear();
                const dateServer:any = new Date(`${monthLocal}/${dayLocal}/${yearLocal}`);
                const dateLocal:any = new Date(`${monthServer}/${dayServer}/${yearLocal}`);
                const diffTime = Math.abs(dateLocal - dateServer);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                let serverTime = Number(str[0]) * 60 + Number(str[1]);
                serverTime += 60;

                let clientTime = diffDays * 24 * 60 + currentTime.getHours() * 60 + currentTime.getMinutes();  
                let temp = 0;
                if(clientTime >= serverTime){
                    temp = 10;  
                }else{
                    temp = ((serverTime - clientTime) * 60) - currentTime.getSeconds();
                }
                console.log(temp);
                myInterval.current = setInterval(() => {
                    onRefreshChart();
                    clearInterval(myInterval.current);
                }, temp * 1000);
            }
        }
    };

    return (
        <Stack width="100%" textAlign="center">
            <div style={{ display: 'flex', backgroundColor: '#D0F288', justifyContent: 'center', marginBottom: '13px' }}>
                <Typography style={{ fontSize: '18px', fontWeight: 'bold', padding: '6px', textDecoration: 'underline'}}>太陽光発電設備 発電状況</Typography>
            </div>
            {
                (!isMobile) &&
                <>
                <div style={{display: "inline-flex",justifyContent:"center",position:"relative"}}>
                    {
                        progressData.condition && progressData.temperature &&
                        <>
                            <Stack direction={"row"} margin={"auto 0px"}>
                                {(progressData.condition == "晴れ" || progressData.condition == "快晴") && <>
                                    <WbSunnyIcon sx={{marginRight:"5px"}} color="warning"  />
                                    <text>{progressData.temperature}°C</text>
                                </>}
                                {( progressData.condition == "雲り") && <>
                                    <CloudIcon style={{marginRight:"5px"}} /> 
                                    <text>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雨") && <>
                                    <UmbrellaIcon style={{marginRight:"5px"}} />
                                    <text>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雪") && <>
                                    <AcUnitIcon style={{marginRight:"5px"}} /> 
                                    <text>{progressData.temperature}°C</text>
                                </> }
                            </Stack>
                        </>
                    }
                    <text>{data?.customer_name}</text><span style={{ marginLeft: '80px',marginRight:"80px",fontSize:"32px" }}>{data?.plant_name}</span>  
                    {
                        progressData.condition && progressData.temperature &&
                        <>
                            <div style={{opacity:0}}>
                                {(progressData.condition == "晴れ" || progressData.condition == "快晴") && <>
                                    <WbSunnyIcon sx={{marginTop:"10px",marginRight:"5px"}} color="warning"  />
                                    <text style={{marginTop:"10px"}}>{progressData.temperature}°C</text>
                                </>}
                                {( progressData.condition == "雲り") && <>
                                    <CloudIcon style={{marginTop:"10px",marginRight:"5px"}} /> 
                                    <text style={{marginTop:"10px"}}>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雨") && <>
                                    <UmbrellaIcon style={{marginTop:"10px",marginRight:"5px"}} />
                                    <text style={{marginTop:"10px"}}>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雪") && <>
                                    <AcUnitIcon style={{marginTop:"10px",marginRight:"5px"}} /> 
                                    <text style={{marginTop:"10px"}}>{progressData.temperature}°C</text>
                                </> }
                            </div>
                        </>
                    }
                    {
                        onlyBackFlag ? 
                        <>
                            <Button style={{ textTransform: 'none',marginRight:"20px" }} sx={buttonStyle1} onClick={onBack} variant="text">戻る</Button>
                            <Button sx={buttonStyle3} onClick={onCopyLink}> <LinkIcon sx={{color:"#7D7C7C", fontSize:"30px"}} /></Button>
                        </>
                        :
                        <>
                            <Stack direction={"row"} justifyContent={"flex-end"} display={"flex"} style={{position:"absolute",right:0}}>
                                <Button style={{ textTransform: 'none'}} sx={buttonStyle1} onClick={onBack} variant="text">戻る</Button>
                                <Button style={{ textTransform: 'none' }}  sx={buttonStyle2} onClick={onMonthlyReport} variant="text">月表</Button>
                                <Button style={{textTransform:'none'}} onClick={onYearlyReport} sx={buttonStyle2} variant="text" >年表</Button>
                                <Button sx={buttonStyle3} onClick={onCopyLink}> <LinkIcon sx={{color:"#7D7C7C", fontSize:"30px"}} /></Button>
                            </Stack>
                        </>
                    }
                </div>
                
                {data?.date && (
                    <div style={{ fontWeight: 'bold', margin: '15px auto',fontSize:"32px" }}>
                        <span>{data?.date}</span>
                        &emsp;
                        <span>{data?.time}</span>
                    </div>
                )}
                <div style={{ margin: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '637px', marginBottom: '26px' }}>
                        <div style={{ minWidth: '325px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ backgroundColor: '#D0F288', width: '130px', height: '38px', borderRadius: '5px', marginTop: '3.25px', marginRight: '13px',fontSize:"26px" }}>現在 出力</div>
                                <div style={{ fontWeight: 'bold', fontSize: '39px', lineHeight: '39px' }}>{data?.current_kw}<span style={{ fontWeight: 'normal', fontSize: '20.8px' }}>kW</span></div>
                            </div>
                            <hr style={{ width: '325px' }} />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span style={{ backgroundColor: '#D0F288', width: '156px', height: '38px', borderRadius: '5px', marginTop: '3.25px', marginRight: '13px',fontSize:"26px" }}>本日 発電量</span>
                                <div style={{ fontWeight: 'bold', fontSize: '39px', lineHeight: '39px' }}>{data?.today_kwh}<span style={{ fontWeight: 'normal', fontSize: '20.8px', marginTop: '3.25px' }}>kWh</span></div>
                            </div>
                        </div>
                        <div style={{marginLeft:"65px"}}>
                            <div style={{ backgroundColor: '#D0F288', height: '38px', borderRadius: '5px', marginTop: '3.25px',fontSize:"26px",minWidth:"221px"}}>当月 累積発電量</div>
                            <div style={{ fontWeight: 'bold', fontSize: '65px', lineHeight: '65px',marginTop:"6.5px" }}>{data?.month_kwh}<span style={{ fontWeight: 'normal', fontSize: '26px' }}>kWh</span></div>
                        </div>
                    </div>
                </div>
                <div style={{ height: '100%', width: '100%' }}>
                    <div>
                        <table style={{ margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    {scaleInfoList.length > 0 ? (
                                        <>
                                            {[...scaleInfoList].map((x, i) =>
                                            {
                                                return(<>  
                                                    <td style={{width: `${widthOneRectOtherSize}px`, height: '33px', border: 'none', backgroundColor: '#D8D9DA', position: 'relative'}}>
                                                        <div style={{position: 'absolute',top: '0', left: '0', width: x.width, height: '33px', backgroundColor: x.color, zIndex: '1'}}>
                                                        </div>
                                                    </td>
                                                </>)
                                            }
                                                
                                            )}
                                        </>
                                    ):(
                                        <>
                                            {[...Array(5)].map((x, i) =>
                                                <>
                                                <td style={{width: `${widthOneRectOtherSize}px`, height: '33px', border: 'none', backgroundColor: '#D8D9DA'}}>
                                                    
                                                </td>
                                                </>
                                            )}
                                        </>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
                    <table style={{ margin: 'auto', fontSize:'1.3rem' }}>
                            <tbody>
                                <tr>
                                {[...scaleInfoList].map((x, i) =>
                                    <>
                                        {i === 0 && <td style={{width: `${widthOneRectOtherSize}px`, verticalAlign: 'top'}}>{0}</td>}
                                        {i + 1 !== scaleInfoList.length ? (
                                            <td style={{width: `${widthOneRectOtherSize}px`, verticalAlign: 'top'}}>{x.scale}</td>
                                        ):(
                                            <td style={{ width: `${widthOneRectOtherSize}px`}}>
                                            {x.scale}
                                                <br />
                                                <span>(kW)</span>
                                            </td>
                                        )} 
                                    </>
                                )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </>
            }

            {
                isMobile &&
                <>
                    <div style={{display: "inline-flex",justifyContent:"right"}}>

                        {
                            progressData.condition && progressData.temperature ?
                            <>
                                <Stack direction={"row"} margin={"auto 0px"}>
                                {(progressData.condition == "晴れ" || progressData.condition == "快晴") && <>
                                    <WbSunnyIcon style={{marginRight:"5px"}} color="warning"/>
                                    <text>{progressData.temperature}°C</text>
                                </>}
                                {( progressData.condition == "雲り") && <>
                                    <CloudIcon style={{marginRight:"5px"}} /> 
                                    <text>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雨") && <>
                                    <UmbrellaIcon style={{marginRight:"5px"}} />
                                    <text>{progressData.temperature}°C</text>
                                </> }
                                {( progressData.condition == "雪") && <>
                                    <AcUnitIcon style={{marginRight:"5px"}} /> 
                                    <text>{progressData.temperature}°C</text>
                                </> }
                                </Stack>
                            </>
                            :
                            <Button style={{ textTransform: 'none',opacity:0,cursor:"normal"}} disabled={true} sx={buttonStyle4} variant="text">戻る</Button>
                        }
                        <div style={{fontSize:"24px", margin: 'auto', fontWeight:"bold"}}>{data?.plant_name}</div>
                        <Button style={{ textTransform: 'none'}} sx={buttonStyle4} onClick={onBack} variant="text">戻る</Button>
                    </div>
                    {data?.date && (
                    <div style={{ fontWeight:"bold",margin: 'auto',fontSize:"24px" }}>
                        <span>{data?.date}</span>
                        &emsp;
                        <span>{data?.time}</span>
                    </div>
                    )}
                    <div style={{ margin: 'auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '26px',marginTop:"13px" }}>
                            <div style={{ width:"100%" }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ backgroundColor: '#D0F288', width: '156px', height: '38px', borderRadius: '5px', marginTop: '3.25px', marginRight: '13px',fontSize:"26px" }}>現在 出力</div>
                                    <div style={{ fontWeight: 'bold', fontSize: '39px', lineHeight: '39px' }}>{data?.current_kw}<span style={{ fontWeight: 'normal', fontSize: '20.8px' }}>kW</span></div>
                                </div>
                                <hr style={{ width: '100%' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span style={{ backgroundColor: '#D0F288', width: '156px', height: '38px', borderRadius: '5px', marginTop: '3.25px', marginRight: '13px',fontSize:"26px" }}>本日 発電量</span>
                                    <div style={{ fontWeight: 'bold', fontSize: '39px', lineHeight: '39px' }}>{data?.today_kwh}<span style={{ fontWeight: 'normal', fontSize: '20.8px', marginTop: '3.25px' }}>kWh</span></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ backgroundColor: '#D0F288', height: '38px', borderRadius: '5px', marginTop: '3.25px',fontSize:"26px",minWidth:"221px"}}>当月 累積発電量</div>
                            <div style={{ fontWeight: 'bold', fontSize: '65px', lineHeight: '65px',marginTop:"6.5px" }}>{formatRoundDownDecimal(data?.month_kwh,0)}<span style={{ fontWeight: 'normal', fontSize: '26px',marginLeft:"20px" }}>kWh</span></div>
                        </div>
                    </div>
                    <div style={{ height: '100%', width: '100%',margin: 'auto' }}>
                        <div >
                            <table style={{ margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        {scaleInfoListMobileSize.length > 0 ? (
                                            <>
                                                {[...scaleInfoListMobileSize].map((x, i) =>
                                                {
                                                    return(<>  
                                                        <td style={{width: `${widthOneRectMobileSize}px`, height: '33px', border: 'none', backgroundColor: '#D8D9DA', position: 'relative'}}>
                                                            <div style={{position: 'absolute',width: `${x.width}`,top: '0', left: '0', height: '33px', backgroundColor: x.color, zIndex: '1'}}>
                                                            </div>
                                                        </td>
                                                    </>)
                                                }
                                                    
                                                )}
                                            </>
                                        ):(
                                            <>
                                                {[...Array(5)].map((x, i) =>
                                                    <>
                                                    <td style={{width: `${widthOneRectMobileSize}px`, height: '33px', border: 'none', backgroundColor: '#D8D9DA'}}>
                                                        
                                                    </td>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
                        <table style={{ margin: 'auto', fontSize:'1.3rem' }}>
                            <tbody>
                                <tr>
                                {[...scaleInfoListMobileSize].map((x, i) =>
                                    <>
                                        {i === 0 && <td style={{width: `${widthOneRectMobileSize}px`, verticalAlign: 'top',left:0,paddingRight:"20px"}}>{0}</td>}
                                        {i + 1 !== scaleInfoListMobileSize.length ? (
                                            <td style={{width: `${widthOneRectMobileSize}px`, verticalAlign: 'top'}}>{x.scale}</td>
                                        ):(
                                            <td style={{ width: `${widthOneRectMobileSize}px`}}>
                                            {x.scale}
                                                <br />
                                                <span>(kW)</span>
                                            </td>
                                        )} 
                                    </>
                                )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
            
        </Stack >
    );
};

export default Progress;
