import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import * as React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import SolarLiveStatusImg from "../../logo/solar_live_status.png";
import { Path as RoutePath } from "../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../store";
import FetchHelper from "../FetchHelper";
import ja from 'dayjs/locale/ja';
import { formatNumber, formatNumberNullToHyphen } from "../../utils";

// FETCH ID
const fetchId = "SOLAR_LIVE_STATUS";
const menusFetchId = 'MENUS_FETCH';

// フォーム定義
interface SolarLiveStatusModel {
    kwh_day_total: string,
    amount_day_total: string,
    kwh_month_total: string,
    amount_month_total: string,
    live_situation_info_array: [
        {
            mainid: string,
            plant_name: string,
            panel_capacity: string,
            current_kw: string,
            kwh_sum_by_day: string,
            amount_sum_by_day: string,
            kwh_sum_by_month: string,
            amount_sum_by_month: string,
        }
    ] | [],
}

// 初期値設定
const initialValue: SolarLiveStatusModel = {
    kwh_day_total: "",
    amount_day_total: "",
    kwh_month_total: "",
    amount_month_total: "",
    live_situation_info_array: []
}

// -------------
// Props
export type SolarLiveStatusProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const SolarLiveStatus = (props: SolarLiveStatusProps) => {
    const { onGetData, onError, onGetAuthRole, fetchStateMenu } = props;

    localStorage.setItem('lastActiveFormPath', RoutePath.SolarLiveStatus);
    localStorage.setItem('title', '太陽光ライブ');
    const [solarLiveStatus, setSolarLiveStatus] = React.useState<SolarLiveStatusModel>(initialValue);
    const [fetchLoading, setFetchLoading] = React.useState(true);

    const [disableLink, setDisableLink] = React.useState(true);
    
    React.useEffect(() => {
        if(fetchStateMenu.data){
            let codeRestrictionArray = fetchStateMenu.data.code_restriction_array ?? []
            let isDisable = codeRestrictionArray.length > 0 && !codeRestrictionArray.includes('GASOLAR')
            setDisableLink(isDisable)
        } else {
            onGetAuthRole()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            let codeRestrictionArray = data.code_restriction_array ?? []
            let isDisable = codeRestrictionArray.length > 0 && !codeRestrictionArray.includes('GASOLAR')
            setDisableLink(isDisable)
        }
    }

    // 取得正常終了時の初期表示
    const handleSuccess = (data: any) => {
        setSolarLiveStatus(data);
        setFetchLoading(false);
    }

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setFetchLoading(false);
    }

    //タイトルの高さを戻
    React.useEffect(() => {
        const params = new Map<string, any>();
        params.set('code', 'GAEX4');
        params.set('path', 'root');
        onGetData(params);
        return () => {
            let div: any = document.getElementsByClassName('css-ua3wu6')[0];
            if (typeof (div) !== 'undefined' && div != null) {
                div.removeAttribute("style");
            }
        };
        // eslint-disable-next-line
    }, []);

    const colHeaderStyle = {
        fontWeight: 'bold',
        border: '1px solid gray',
        textAlign: 'center' as 'center',
        backgroundColor: '#d9d9d9',
        height: '48px',
    }

    const colStyle = {
        border: '1px solid gray',
        textAlign: 'right' as 'right',
        paddingRight: '10px',
        height: '44px',
    }

    let navigate = useNavigate();
    const handleOnclick = (mainid: string) => {
        sessionStorage.setItem('references', window.location.pathname);
        navigate(RoutePath.Gaex3 + "?mainid=" + mainid);
    }

    const getNowTime = () => {
        dayjs.locale(ja);
        let now = dayjs();
        return (
            <span style={{ letterSpacing: '0.3em' }}>{now.locale('ja').format('YYYY年MM月DD日 (ddd) HH時mm分')}</span>
        );
    }

    return (
        <div style={{ margin: 'auto 10px' }}>
            {fetchLoading ? (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <div>
                        <Stack direction='row' spacing={2} width="100%" margin="10px auto 0">
                            <Grid item xs={6}>
                                <Box component="img" src={SolarLiveStatusImg} sx={{ width: 140, height: 140 }} />
                            </Grid>
                            <Grid item xs={6} style={{ marginLeft: '50px', fontSize: 'large' }}>
                                <table style={{ width: '100%' }} cellPadding={10}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>本日の発電状況</td>
                                        </tr>
                                        <tr>
                                            <td>{getNowTime()}</td>
                                        </tr>
                                        <tr>
                                            <td>日合計発電量 {formatNumber(solarLiveStatus.kwh_day_total)}kWh ({formatNumber(solarLiveStatus.amount_day_total)}円)</td>
                                        </tr>
                                        <tr>
                                            <td>月合計発電量 {formatNumber(solarLiveStatus.kwh_month_total)}kWh ({formatNumber(solarLiveStatus.amount_month_total)}円)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Stack>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '4px' }}>
                            <tbody>
                                <tr>
                                    <td style={colHeaderStyle}>発電所</td>
                                    <td style={colHeaderStyle}>発電容量</td>
                                    <td style={colHeaderStyle}>現在電力</td>
                                    <td style={colHeaderStyle}>当日発電量(日)</td>
                                    <td style={colHeaderStyle}>当日発電金額(日)</td>
                                    <td style={colHeaderStyle}>当月発電量(月)</td>
                                    <td style={colHeaderStyle}>当月発電金額(月)</td>
                                </tr>
                                {
                                    solarLiveStatus.live_situation_info_array && solarLiveStatus.live_situation_info_array.map((val, index) =>
                                        <tr key={index}>
                                            <td style={{
                                                border: '1px solid gray',
                                                paddingLeft: '10px',
                                                height: '44px',
                                                textAlign: 'left'
                                            }}>
                                                { disableLink ?
                                                    <Typography>{val.plant_name}</Typography>
                                                    : <Typography style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }} onClick={() => handleOnclick(val.mainid)}>{val.plant_name}</Typography>
                                                }
                                            </td>
                                            <td style={colStyle}>{formatNumberNullToHyphen(val.panel_capacity, "kW")}</td>
                                            <td style={colStyle}>{formatNumberNullToHyphen(val.current_kw, "kW")}</td>
                                            <td style={colStyle}>{formatNumberNullToHyphen(val.kwh_sum_by_day, "kWh")}</td>
                                            <td style={colStyle}>{formatNumberNullToHyphen(val.amount_sum_by_day, "円")}</td>
                                            <td style={colStyle}>{formatNumberNullToHyphen(val.kwh_sum_by_month, "kWh")}</td>
                                            <td style={colStyle}>{formatNumberNullToHyphen(val.amount_sum_by_month, "円")}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack> */}
                </div>
            )}
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ),
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onGetData: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "太陽光ライブ状況",
            body: "太陽光ライブ状況一覧取得出来ません。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SolarLiveStatus as any);