import { Chart } from "chart.js";

export const createSolarPowerChart = (data) => {
    if (!data) {
        return;
    }
    
    const colorBar = [];
    for (let i = 0; i < data.dataChart.dataChart1.length; i++) {
        let value = Number(data.dataChart.dataChart1[i])
        if (value < 1200) {
            colorBar.push('#D0F288');
        } else if (value < 1600) {
            colorBar.push('#FEFF89');
        } else {
            colorBar.push('#FF8787');
        }
    }

    document.getElementById("solarChart").remove();     
    let canvas = document.createElement('canvas');     
    canvas.setAttribute('id','solarChart');     
    canvas.setAttribute('height','250px');    
    document.querySelector('#chartContainer').appendChild(canvas);

    const data1 = {
        labels:data.labels,
        datasets:
        [
            {
                type: 'bar',
                label: '現在出力',
                data: data.dataChart.dataChart1,
                borderColor: colorBar,
                backgroundColor: colorBar,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 5,
                pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
                order: 2,
            },
            {
                label: '前日',
                data: data.dataChart.dataChart2,
                // fill: false,
                borderColor: 'rgb(26, 117, 255)',
                backgroundColor: 'rgb(26, 117, 255)',
                tension: 0.3,
                pointHoverRadius: 10,
                pointRadius: 0,
                pointHoverBorderWidth: 5,
                pointHoverBorderColor: 'rgba(26, 117, 255, 0.3)',
                order: 1,
            },
        ]
    }
    
    let checkNull = false;
    data.dataChart3?.forEach((item) => {
        if(!(item && item != 0)){
            checkNull = true;
        }
    })
    if(!checkNull){
        data1.datasets.push({
                type: 'line',
                label: 'パネル容量',
                data: data.dataChart.dataChart3,
                // fill: false,
                borderColor: 'yellow',
                backgroundColor: 'yellow',
                tension: 0.1,
                pointRadius: 0,
                pointStyle: 'rectRot',
                pointHoverRadius: 10,
                pointHoverBorderWidth: 5,
                pointHoverBorderColor: 'rgba(75, 192, 192, 0.3)',
                order: 3,
            },)
    }

    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');
      
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';
            if (document.body.clientWidth <= 768) { 
                tooltipEl.style.width = "260px";
            }

            const table = document.createElement('table');
            table.style.margin = '0px';
        
            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }
      
        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        const {chart, tooltip} = context;
        const tooltipEl = getOrCreateTooltip(chart);
      
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }
      
        if (tooltip.body) {
            const dataIndex = context.tooltip.dataPoints[0].dataIndex;
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(b => b.lines);
        
            const tableHead = document.createElement('thead');
        
            titleLines.forEach(title => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;
        
                const th = document.createElement('th');
                th.style.borderWidth = 0;
                th.style.alignItems = "left";
                let convert_title = ""; 
                if (context.tooltip.dataPoints[0].label % 1 !== 0) {
                    convert_title =  context.tooltip.dataPoints[0].label - 0.5 + '時30分台';
                } else {
                    convert_title = context.tooltip.dataPoints[0].label + '時00分台';
                }
                const text = document.createTextNode(convert_title);
        
                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });
        
            const tableBody = document.createElement('tbody');

            let string = "";
            const index_row2 = bodyLines.findIndex((item) => item[0].includes("現在出力:"));
            if(index_row2 != -1){
                const colors_index_row2 = tooltip.labelColors[index_row2];
                string = `現在出力:　　　`;

                const span = document.createElement('span');
                span.style.background = colors_index_row2.backgroundColor;
                span.style.borderColor = colors_index_row2.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '18px';
                span.style.width = '18px';
                span.style.display = 'inline-block';
                span.style.borderRadius = "50%";

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;
        
                const text = document.createTextNode(string);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);

                let td1 = document.createElement('td');
                td1.style.borderWidth = 0;
                let text1 = document.createTextNode(`${context.tooltip.dataPoints[index_row2].formattedValue}kW`);
            
                td.appendChild(span);
                td.appendChild(text);
                td1.appendChild(text1);
                
                tr.appendChild(td);
                tr.appendChild(td1);
                tableBody.appendChild(tr);
            }

            const index_row3 = bodyLines.findIndex((item) => item[0].includes("前日:"));
            if(index_row3 != -1){
                const colors_index_row3 = tooltip.labelColors[index_row3];
                string = `前日:`;
                const span = document.createElement('span');
                span.style.background = colors_index_row3.backgroundColor;
                span.style.borderColor = colors_index_row3.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '18px';
                span.style.width = '18px';
                span.style.display = 'inline-block';
                span.style.borderRadius = "50%";

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;
        
                const text = document.createTextNode(string);

                let td1 = document.createElement('td');
                td1.style.borderWidth = 0;
                let text1 = document.createTextNode(`${context.tooltip.dataPoints[index_row3].formattedValue}kW`);
            
                td.appendChild(span);
                td.appendChild(text);
                td1.appendChild(text1); 
                
                tr.appendChild(td);
                tr.appendChild(td1);
                tableBody.appendChild(tr);
            }

            const index_row4 = bodyLines.findIndex((item) => item[0].includes("パネル容量:"));
            if(index_row4 != -1){
                const colors_index_row4 = tooltip.labelColors[index_row4];
                string = `パネル容量:`;
                const span = document.createElement('span');
                span.style.background = colors_index_row4.backgroundColor;
                span.style.borderColor = colors_index_row4.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '15px';
                span.style.width = '15px';
                span.style.display = 'inline-block';
                span.style.transform = 'rotate(45deg)';
                span.style.transformOrigin = '50% 50%';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;
        
                const text = document.createTextNode(string);

                let td1 = document.createElement('td');
                td1.style.borderWidth = 0;
                let text1 = document.createTextNode(`${context.tooltip.dataPoints[index_row4].formattedValue}kW`);
            
                td.appendChild(span);
                td.appendChild(text);
                td1.appendChild(text1); 
                
                tr.appendChild(td);
                tr.appendChild(td1);
                tableBody.appendChild(tr);
            }

            const row7 = "気象種別：" ;
            let tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;
            let td = document.createElement('td');
            td.style.borderWidth = 0;
            let text = document.createTextNode(row7);

            let td1 = document.createElement('td');
            td1.style.borderWidth = 0;
            let text1 = document.createTextNode((data.dataChart.list_source_type_today[dataIndex] == 0 ? "予報" : "実績"));
        
            td.appendChild(text);
            td1.appendChild(text1); 
            
            tr.appendChild(td);
            tr.appendChild(td1);
            tableBody.appendChild(tr);

            if(data.dataChart.list_condition_today[dataIndex] != null){
                let svg = "";
                const row8 = "天気：";
                switch(data.dataChart.list_condition_today[dataIndex]){
                    case "晴れ":
                    case "快晴":
                        // Sunny svg
                        svg = `<svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="#FCC233"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <circle cx="12" cy="12" r="5" stroke="#FCC233" />
                            <line x1="12" y1="1" x2="12" y2="3" stroke="#FCC233" />
                            <line x1="12" y1="21" x2="12" y2="23" stroke="#FCC233" />
                            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" stroke="#FCC233" />
                            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" stroke="#FCC233" />
                            <line x1="1" y1="12" x2="3" y2="12" stroke="#FCC233" />
                            <line x1="21" y1="12" x2="23" y2="12" stroke="#FCC233" />
                            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" stroke="#FCC233" />
                            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" stroke="#FCC233" />
                        </svg>`
                        break;
                    case "雲り":
                        // Cloudy
                        svg = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#A0A0A0"><path d="M0 0h24v24H0V0z" fill="none"/>
                                    <path fill="#FFFFFF" d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"/>
                                    <path d="M12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6m0-2C9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96C18.67 6.59 15.64 4 12 4z"/>
                                </svg>`;
                        break;
                    case "雨":
                        // Rain
                        svg = `<svg xmlns="http://www.w3.org/2000/svg" height="1.4em" viewBox="0 0 576 512">
                                <path fill="#0a62f0"  d="M288 0c17.7 0 32 14.3 32 32V49.7C451.8 63.4 557.7 161 573.9 285.9c2 15.6-17.3 24.4-27.8 12.7C532.1 283 504.8 272 480 272c-38.7 0-71 27.5-78.4 64.1c-1.7 8.7-8.7 15.9-17.6 15.9s-15.8-7.2-17.6-15.9C359 299.5 326.7 272 288 272s-71 27.5-78.4 64.1c-1.7 8.7-8.7 15.9-17.6 15.9s-15.8-7.2-17.6-15.9C167 299.5 134.7 272 96 272c-24.8 0-52.1 11-66.1 26.7C19.4 310.4 .1 301.5 2.1 285.9C18.3 161 124.2 63.4 256 49.7V32c0-17.7 14.3-32 32-32zm0 304c12.3 0 23.5 4.6 32 12.2V430.6c0 45-36.5 81.4-81.4 81.4c-30.8 0-59-17.4-72.8-45l-2.3-4.7c-7.9-15.8-1.5-35 14.3-42.9s35-1.5 42.9 14.3l2.3 4.7c3 5.9 9 9.6 15.6 9.6c9.6 0 17.4-7.8 17.4-17.4V316.2c8.5-7.6 19.7-12.2 32-12.2z" />
                            </svg>`;
                        break;
                    case "雪":
                        // Snow
                        svg = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M22 11h-4.17l3.24-3.24-1.41-1.42L15 11h-2V9l4.66-4.66-1.42-1.41L13 6.17V2h-2v4.17L7.76 2.93 6.34 4.34 11 9v2H9L4.34 6.34 2.93 7.76 6.17 11H2v2h4.17l-3.24 3.24 1.41 1.42L9 13h2v2l-4.66 4.66 1.42 1.41L11 17.83V22h2v-4.17l3.24 3.24 1.42-1.41L13 15v-2h2l4.66 4.66 1.41-1.42L17.83 13H22z"/></svg>`
                        break;
                }
                let tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                let td = document.createElement('td');
                td.style.borderWidth = 0;
                td.style.display = 'flex'; 
                td.style.justifyContent = 'space-between';
                td.style.alignItems = 'center'; 

                let text = document.createTextNode(row8);
                td.appendChild(text);

                let tempContainer = document.createElement('div');
                tempContainer.innerHTML = svg;

                let td1 = document.createElement('td');
                td1.style.borderWidth = 0;
            
                td.appendChild(text);
                td1.appendChild(tempContainer);
                
                tr.appendChild(td);
                tr.appendChild(td1);
                tableBody.appendChild(tr);
            };

            if(data.dataChart.list_temperature_today[dataIndex] != null){
                const row9 = "気温：";
                let tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;
                let td = document.createElement('td');
                td.style.borderWidth = 0;
                let text = document.createTextNode(row9);

                let td1 = document.createElement('td');
                td1.style.borderWidth = 0;
                let text1 = document.createTextNode((data.dataChart.list_temperature_today[dataIndex]) +  "℃");
            
                td.appendChild(text);
                td1.appendChild(text1);
                
                tr.appendChild(td);
                tr.appendChild(td1);
                tableBody.appendChild(tr);
            }

            if(data.dataChart.list_solar_radiation_today[dataIndex] != null){
                const row10 = "日射量(W/m²):" ;
                let tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;
                let td = document.createElement('td');
                td.style.borderWidth = 0;
                td.style.display = 'flex';
                td.style.alignItems = 'center';
                let text = document.createTextNode(row10);

                let td1 = document.createElement('td');
                td1.style.borderWidth = 0;
                let text1 = document.createTextNode(data.dataChart.list_solar_radiation_today[dataIndex]);
            
                td.appendChild(text);
                td1.appendChild(text1);
                
                tr.appendChild(td);
                tr.appendChild(td1);
                tableBody.appendChild(tr);
            }

            if (data.dataChart.list_precipitation_today[dataIndex] != null) {
                const row11 = "降水量(mm/h): ";
                
                let tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;
                
                let td = document.createElement('td');
                td.style.borderWidth = 0;
                td.style.display = 'flex';
                td.style.alignItems = 'center';
            
                let text = document.createTextNode(row11);

                let td1 = document.createElement('td');
                td1.style.borderWidth = 0;
                let text1 = document.createTextNode(data.dataChart.list_precipitation_today[dataIndex]);
            
                td.appendChild(text);
                td1.appendChild(text1); 
                
                tr.appendChild(td);
                tr.appendChild(td1);
                tableBody.appendChild(tr);
            }

            const tableRoot = tooltipEl.querySelector('table');        
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }
      
        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      
        tooltipEl.style.opacity = 1;

        const tooltipRect = tooltipEl.getBoundingClientRect();
        const tooltipWidth = tooltipRect.width;
        const tooltipHeight = tooltipRect.height;

        let leftPosition = positionX + tooltip.caretX;
        let topPosition = positionY + tooltip.caretY;

        const heightBrowser = document.body.clientHeight;
        const widthBrowser = document.body.clientWidth;

        if (widthBrowser <= 768) { 
            tooltipEl.style.left = (widthBrowser / 2 - tooltipWidth / 2) + 150 + 'px';
        } else {
            if (widthBrowser - (leftPosition + tooltipWidth) > 20) {
                tooltipEl.style.left = leftPosition + 'px';
            } else {
                tooltipEl.style.left = (widthBrowser - tooltipWidth - 20) + 'px';
            }
        }
        if (heightBrowser - (topPosition + tooltipHeight) > 20) {
            tooltipEl.style.top = (topPosition - 200) + 'px';
        } else {
            tooltipEl.style.top = (heightBrowser - tooltipHeight - 200) + 'px';
        }

        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        tooltipEl.style.background = "#f2f2f280";
        tooltipEl.style.border = "1px solid #8fbe01b3";
        tooltipEl.style.borderRadius = "8px";
        tooltipEl.style.padding = "15px";
        tooltipEl.style.color = "black";
        tooltipEl.style.zIndex = 10;
    };

    const options = {
        responsive: true,
        // maintainAspectRatio:false,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                external: externalTooltipHandler
            },
        },
        interaction: {
            mode: 'index',
            intersect: false
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: '(時)',
                    font: {
                        size: 16,
                    }
                },
                ticks: {
                    callback: function (value, index, ticks) {
                        if (value % 2 === 0) {
                            return value / 2;
                        }
                    }
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: '(kW)',
                    font: {
                        size: 16,
                    }
                },
                grace: '5%',
            }
        }
    }

    Chart.register({
        id: 'customLabel',
        afterDatasetsDraw: function(chart) {
            const ctx = chart.ctx;
            ctx.save();
            if(chart.canvas.id == "solarChart"){
                const datalength = chart.data.datasets[1].data.length;
                if (datalength > 2){
                    const x = chart.getDatasetMeta(1).data[datalength-3].x;
                    const y = chart.getDatasetMeta(1).data[datalength-3].y;
                    ctx.fillStyle = 'rgb(26, 117, 255)';
                    ctx.textAlign = 'center';
                    ctx.font = '15px Arial';
                    ctx.textBaseline = 'bottom';
                    const value = chart.getDatasetMeta(1).data[datalength-3]["$context"].raw;
                    if(Number(value) >= 70){
                        ctx.fillText("前日", x, y  + 20);
                    }else{
                        ctx.fillText("前日", x, y  - 3);
                    }
                } 
                ctx.restore();
            }
        }
    });

    new Chart(
        canvas,
        {
            type:"line",
            data:data1,
            options:options,
        })
}