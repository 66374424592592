import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Autocomplete, Button, CircularProgress, Collapse, Grid, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { OptionItem, PageData } from "../../../models";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import palette from '../../../theme/palette';
import { formatDateTime } from '../../../utils';
import Page from "../../common/Page";
import FetchHelper from "../../FetchHelper";
import { customerLabelName, limitMasterCustomers, limitMasterGateways } from '../../../statics';
import FaGrid from './FaGrid';

// -------------
// FETCH ID
const fetchId = "FA_LIST";
const formId = "FA_SEARCH_FORM";
const customerFetch = "FA_CUSTOMER_FETCH";
const mainidFetch = "FA_MAINID_FETCH";

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
    customer_id: OptionItem | null;
    place: string;
    mainid: OptionItem | null;
    occurrence_time_from: Date | null;
    occurrence_time_to: Date | null;
    kw_min: string;
    kw_max: string;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
    customer_id: null,
    place: "",
    mainid: null,
    occurrence_time_from: null,
    occurrence_time_to: null,
    kw_min: '',
    kw_max: '',
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// -------------
// Props
export type FAListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const FaList = (props: FAListProps) => {
    const { onSearch,onError } = props;

    const { handleSubmit, control, setValue, getValues, formState: { errors } } = useForm<SearchItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue
    });

    localStorage.setItem('lastActiveFormPath', window.location.pathname + window.location.search);
    localStorage.setItem('title', '測定値定期通報');

    const [openSearch, setOpenSearch] = React.useState(false);
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<any>([]);
    const [colState, setColState] = useState<any>();
    const [searchParams] = useSearchParams();    
    const [customerList, setCustomerList] = React.useState<OptionItem[]>([]);
    const [mainidList, setMainidList] = React.useState<OptionItem[]>([]);
    const [latestUpdates, setLatestUpdates] = React.useState("");
    const [keyword, setKeyword] = useState("");
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [expReg] = useState(/^(([0-9]{1,4}(\.?)(\.[0-9]{1,3})?)$)/);

    const myParam = searchParams.get('id');
    const gridRef = useRef() as any;
    const isShowCustomerSearch = !(searchParams.get('path') === 'customer' && searchParams.get('customer_id'));
    const watchChangeUrl = searchParams.get('customer_id'); // reload data when change URL query strings    

    React.useEffect(() => {
        if (!loadingFlag){
            const params = new Map<string, any>();

            params.set('code', 'FA');
            params.set('limit', getValues('limit'));
            params.set('path', searchParams.get('path') ?? 'root');
            if (!isShowCustomerSearch)
                params.set('customer_id', searchParams.get('customer_id'));

            handleClearSeachCondition();
            setLoadingFlag(true);
            onSearch(params);
        }        
    }, [watchChangeUrl]);

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        let isError = false;

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const params = new Map<string, any>();
        params.set("code", "FA")
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.place) params.set("place", value.place);
        if (value.mainid && value.mainid.value) params.set("mainid", value.mainid.value);
        if (value.occurrence_time_from) params.set("occurrence_time_from", formatDateTime(value.occurrence_time_from));
        if (value.occurrence_time_to) params.set("occurrence_time_to", formatDateTime(value.occurrence_time_to));

        if (value.kw_min) {
            let sub_string = value.kw_min.replace(".","");
            if(value.kw_min.length != 8){
                for(let i = 0;i < 8 - value.kw_min.length;i++){
                    sub_string = '0' + sub_string;
                }
            }
            params.set("kw_min", sub_string);
        }
        if (value.kw_max){
            let sub_string = value.kw_max.replace(".","");
            if(value.kw_max.length != 8){
                for(let i = 0;i < 8 - value.kw_max.length;i++){
                    sub_string = '0' + sub_string;
                }
            }
            params.set("kw_max", sub_string);
        } 

        params.set('path', searchParams.get('path') ?? 'root');
        if (isShowCustomerSearch){
            if (value.customer_id && value.customer_id.value) 
                params.set("customer_id", value.customer_id.value);
        } else {
            params.set('customer_id', searchParams.get('customer_id'));
        }

        if (!isError) {
            setLoadingFlag(true);
            onSearch(params);
        }
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
        // eslint-disable-next-line
    }, []);

    // 検索正常終了時
    const handleSuccess = (data: any) => {
        if (data && data.results) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.results);
            }

            setListData(() => [...data.results]);

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            setListData(() => [...[] as any]);
        }
        setLatestUpdates(formatDateTime(new Date()));
        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    const onRefreshGrid = () => {
        handleSearchSubmit(getValues());
    }

    React.useEffect(() => {
        // グラフ表示用データの更新
        // --------------------------------
        if (myParam) {
            listData.forEach((item: any) => {
                item.serial_no = myParam
            });
            setListData(() => [...listData])
        }
        else {
            listData.forEach((item: any) => {
                item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            });
            setListData(() => [...listData])
        }
        // eslint-disable-next-line
    }, [myParam]);

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("mainid", null)
        setValue("customer_id", null)
        setValue("place", "")
        setValue("kw_min", '')
        setValue("kw_max", '')
        setValue("occurrence_time_from", null)
        setValue("occurrence_time_to", null)
    };

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
    }

    const handleFetchCustomerSuccess = (data: any) => {
        if (data?.results) setCustomerList(data?.results.map((x: any) => { return { value: x.id.toString(), label: x.name, attribute: '' } as OptionItem }));
    };

    const handleFetchMainidSuccess = (data: any) => {
        if (data?.results) setMainidList(data?.results.map((x: any) => { return { value: x.id.toString(), label: x.id.toString(), attribute: '' } as OptionItem }));
    };

    const handleSearch = () => {
        setValue("page", 0)
        handleSearchSubmit(getValues());
    };

    const convert_number = (e:any) => {
        const index = e.target.value.indexOf(".");
        const target = Number(e.target.value).toString();
        let sub_string = "";
        if(index == -1){
            if(target.length <= 4){
                sub_string += target.substring(0) + ".000";
            }else if(target.substring(4).length < 3) {
                sub_string += target.substring(0,4) + "." + target.substring(4);
                if(3 - target.substring(4).length == 1){
                    sub_string += "0";
                }else if(3 - target.substring(4).length == 2){
                    sub_string += "00";                                                                        
                }
            }else{
                sub_string += target.substring(0,4) + "." + target.substring(4,7);
            }   
        }else{
            const new_index = target.indexOf(".");
            if(new_index == -1){
                sub_string += target.substring(0);
                sub_string += ".000";
            }else{
                let x = target.substring(new_index + 1).length;
                if(x < 3) {
                    if(new_index > 4){
                        sub_string += target.substring(0,4);
                    }else{
                        sub_string += target.substring(0,new_index);
                    }
                    sub_string += target.substring(new_index);
                    if(3 - x == 1){
                        sub_string += "0";
                    }else if(3 - x == 2){
                        sub_string += "00";
                    }
                }else{
                    sub_string += target.substring(0,new_index + 4);
                }
            }
        }
        return sub_string;
    }

    return (
        <>
            <FetchHelper fetchId={customerFetch}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomerSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={mainidFetch}
                url={`/master/gateways/get?limit=${limitMasterGateways}` + (( searchParams.get('path') && searchParams.get('path') === 'customer' && searchParams.get('customer_id')) ? ('&customer_id='+searchParams.get('customer_id')) : "")}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchMainidSuccess(data) : handleError(success, data, error)
                }}
            />
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleSearchSubmit)}>
                    <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                        <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="h6" >検索条件</Typography>
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {
                                    openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                            <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                >
                                    <Grid item minWidth="20rem">
                                        <Controller
                                            control={control}
                                            name="mainid"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={mainidList}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="装置ID" size="small" style={{ background: palette.background.paper }} />}
                                                    onChange={(_, data) => setValue("mainid", data)}
                                                />
                                            )}
                                        />
                                    </Grid> 
                                    {isShowCustomerSearch &&
                                        <Grid item minWidth="20rem">
                                            <Controller
                                                control={control}
                                                name="customer_id"
                                                render={({ field: { value } }) => (
                                                    <Autocomplete
                                                        autoHighlight
                                                        value={value}
                                                        options={customerList}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" style={{ background: palette.background.paper }} />}
                                                        onChange={(_, data) => setValue("customer_id", data)}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    <Grid item minWidth="20rem">
                                        <Controller
                                            name="place"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="設置場所"
                                                    size="small"
                                                    style={{ background: palette.background.paper }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                    mt="3px"
                                >
                                    <Grid item xs={4} minWidth="29.5rem">
                                        <Stack spacing={1} direction="row">
                                            <Controller
                                                control={control}
                                                name="kw_min"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="計測値-最小値(kw)"
                                                        size="small"
                                                        style={{ background: palette.background.paper }}
                                                        onChange={(e) => {
                                                            if (!isNaN(Number(e.target.value)) && expReg.test(e.target.value) || e.target.value == "") {                                                                
                                                                field.onChange(e);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            if(e.target.value.length != 0){
                                                                const kw_min = convert_number(e);
                                                                setValue("kw_min",kw_min);
                                                            }
                                                        }}
                                                        inputProps={{ maxLength: 8 }}
                                                        error={!!errors?.kw_min}
                                                        helperText={errors?.kw_min?.message}
                                                    />
                                                )}
                                            />
                                            <Stack justifyContent="center" alignItems="center">
                                                <div>～</div>
                                            </Stack>
                                            <Controller
                                                control={control}
                                                name="kw_max"
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="計測値-最大値(kw)"
                                                        size="small"
                                                        style={{ background: palette.background.paper }}
                                                        onChange={(e) => {
                                                            if (!isNaN(Number(e.target.value)) && expReg.test(e.target.value) || e.target.value == "") {                                                                
                                                                field.onChange(e);
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            if(e.target.value.length != 0){
                                                                const kw_max = convert_number(e);
                                                                setValue("kw_max",kw_max);
                                                            }                                                            
                                                        }}
                                                        inputProps={{
                                                            maxLength: 8
                                                        }}
                                                        error={!!errors?.kw_max}
                                                        helperText={errors?.kw_max?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} minWidth="29.5rem">
                                        <Stack spacing={1} direction="row">
                                            <Controller
                                                control={control}
                                                name="occurrence_time_from"
                                                render={({ field: { value } }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            inputFormat="YYYY/MM/DD HH:mm:ss"
                                                            renderInput={(props: any) => (
                                                                <TextField
                                                                    size="small"
                                                                    {...props}
                                                                    style={{ background: palette.background.paper }}
                                                                />
                                                            )}
                                                            label="受信日時From"
                                                            value={value}
                                                            onChange={(newValue: any) => {
                                                                setValue("occurrence_time_from", newValue ? newValue.$d : null);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                            <Stack justifyContent="center" alignItems="center">
                                                <div>～</div>
                                            </Stack>
                                            <Controller
                                                control={control}
                                                name="occurrence_time_to"
                                                render={({ field: { value } }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            inputFormat="YYYY/MM/DD HH:mm:ss"
                                                            renderInput={(props: any) => (
                                                                <TextField
                                                                    size="small"
                                                                    {...props}
                                                                    style={{ background: palette.background.paper }}
                                                                />
                                                            )}
                                                            label="受信日時To"
                                                            value={value}
                                                            onChange={(newValue: any) => {
                                                                setValue("occurrence_time_to", newValue ? newValue.$d : null);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                    </Grid>
                                    <Grid item >
                                        <Button variant="contained" onClick={handleSearch}>
                                            検索
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </div>
                    </Stack>
                </form>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <Typography variant="body2" style={{ textAlign: "center", lineHeight: "40px" }}>
                            最終表示日時&emsp;{latestUpdates}
                        </Typography>
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <Page onRefreshGrid={onRefreshGrid}></Page>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => handleChangeKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <FaGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        keyword={keyword}
                        openSearch={openSearch}
                    />
                </div>
            </div>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "測定値定期通報検索",
            body: "測定値定期通報情報を取得できません。" + error,
        }),
};

export default connect(mapStateToProps,mapDispatchToProps)(FaList as any);
