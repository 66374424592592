import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Autocomplete, Button, Checkbox, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { OptionItem } from "../../../models";
import { CodeRestrictionArrayOptions, GwAuthOptions, MobileSmartCustomerRoleValue, MonitoringOperatorCustomerRoleValue, BussinessOperationMonitoringRoleValue, SmartCustomerRoleValue, SmartObserveArrayOptions, customerLabelName } from "../../../statics";

export type UsersAddFormProps = {
    onOK: (data: any) => void;
    onCancel: () => void;
    customerList: any;
    roleList: any;
    currentLoginUser:any;
    authRole:any;
};

let companyData: OptionItem[] = [
    {
        value: "1",
        label: "〇〇株式会社１",
        attribute: ""
    },
    {
        value: "2",
        label: "〇〇株式会社２",
        attribute: ""
    },
    {
        value: "3",
        label: "〇〇株式会社３",
        attribute: ""
    }
];

let deptData: OptionItem[] = [
    {
        value: "1",
        label: "□□部署１",
        attribute: ""
    },
    {
        value: "2",
        label: "□□部署２",
        attribute: ""
    }
];

// 検索フォーム
export interface ItemForm {
    "custom_company": OptionItem | null,
    "email": string;
    "custom_employee_number": string;
    "family_name": string;
    "given_name": string;
    "custom_role": OptionItem | null,
    "users_customer_array": OptionItem[],
    "code_restriction_array": OptionItem[],
    "custom_department": OptionItem | null;
    "custom_system_manage_flag": string;
    "send_invitation_mail_flag": boolean;
    "set_email_auto_verify_flag": boolean;
    "temporary_pass_manual_setting_flag": boolean;
    "temporary_pass_manual_setting_value": string;
    "DesiredDeliveryMediums": string;
    "code_restriction_flag": string;
    "smart_observer_options": OptionItem[];
    restriction_auth_device_flag:string;
    gw_edit_auth_array:OptionItem[];
    company_group_name:string | null; 
    company_group_id:string | null;
}

// 初期値設定
const initialValue: ItemForm = {
    custom_company: null,
    email: "",
    custom_employee_number: "",
    family_name: "",
    given_name: "",
    custom_role: null,
    users_customer_array: [],
    code_restriction_array: [],
    custom_department: null,
    custom_system_manage_flag: "0",
    send_invitation_mail_flag: true,
    set_email_auto_verify_flag: true,
    temporary_pass_manual_setting_flag: false,
    temporary_pass_manual_setting_value: "",
    DesiredDeliveryMediums: "EMAIL",
    code_restriction_flag: "0",
    smart_observer_options:SmartObserveArrayOptions,
    restriction_auth_device_flag:"0",
    gw_edit_auth_array:[],
    company_group_name:"",
    company_group_id:null
}

export const UsersAdd = (props: UsersAddFormProps) => {
    const { onOK, onCancel, customerList, roleList, currentLoginUser, authRole } = props;
    const [companyList] = React.useState<OptionItem[]>(companyData);
    const [deptList] = React.useState<OptionItem[]>(deptData);
    const [optionCodeRestriction] = React.useState<OptionItem[]>(CodeRestrictionArrayOptions);
    const [optionSmartObserve] = React.useState<OptionItem[]>(SmartObserveArrayOptions)
    const [isRestricted, setIsRestricted] = React.useState(false);
    const [isSmartCustomer,setSmartCustomerRole] = React.useState<boolean>(false);
    const [isMobileSmartCustomer,setMobileSmartCustomer] = React.useState<boolean>(false);
    const [isMonitorOperatorCustomer, setIsMonitorOperatorCustomer] = React.useState<boolean>(false);
    const [isRestrictedAuthDevice, setIsRestrictedAuthDevice] = React.useState(false);
    const [isMonitoringOperatorCustomerRoleLoginUser] = React.useState(Number(currentLoginUser?.attributes["custom:role"]) == Number(MonitoringOperatorCustomerRoleValue));
    const [readOnlyCodeRetrict,setReadOnlyCodeRetrict] = React.useState(false);

    const { register, handleSubmit, formState: { errors }, control, setValue, getValues } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ItemForm) => {

        let request = [{
            custom_company: formValue.custom_company?.value,
            email: formValue.email,
            // custom_employee_number: formValue.custom_employee_number,
            family_name: formValue.family_name,
            given_name: formValue.given_name,
            custom_reserve_num_02:formValue.company_group_id,
            custom_role: formValue.custom_role?.value.toString(),
            users_customer_array: formValue.users_customer_array.map(e => e.value),
            code_restriction_array: formValue.code_restriction_array.map(e => e.value),
            // custom_company: formValue.custom_company?.value,
            // custom_department: formValue.custom_department?.value,
            custom_system_manage_flag: formValue.custom_system_manage_flag,
            gw_edit_auth_array: formValue.gw_edit_auth_array.map(item => item.value),
            additional_info: {
                send_invitation_mail_flag: formValue.send_invitation_mail_flag,
                set_email_auto_verify_flag: formValue.set_email_auto_verify_flag,
                temporary_pass_manual_setting_flag: formValue.temporary_pass_manual_setting_flag,
                temporary_pass_manual_setting_value: formValue.temporary_pass_manual_setting_value,
                DesiredDeliveryMediums: formValue.DesiredDeliveryMediums
            }
        }] as any;
        if(Number(formValue.custom_role?.value) == Number(SmartCustomerRoleValue) ||  Number(formValue.custom_role?.value) == Number(MobileSmartCustomerRoleValue)){
            request[0].code_restriction_array = formValue.smart_observer_options.map(item => item.value);
        }
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeRestricted = (e: any, value: any) => {
        if (value != null) {
            setIsRestricted(value === '1');
            if (value !== '1'){
                setValue('code_restriction_array', []);
            }
            setValue('code_restriction_flag', value);
        }
    };

    const handleChangeRestrictedAuthDevice = (e: any, value: any) => {
        if(value != null){
            setIsRestrictedAuthDevice(value === '1');
            if (value !== '1'){
                setValue("gw_edit_auth_array", []);
            }
            setValue("restriction_auth_device_flag",value);
        }
    }

    const handleChangeRole = (values:any) => {
        const smartCustomer = roleList.find((item:any) => item.value == SmartCustomerRoleValue)
        const smartMobileCustomer = roleList.find((item:any) => item.value == MobileSmartCustomerRoleValue)
        const monitoringOperatorCustomer = roleList.find((item:any) => item.value == MonitoringOperatorCustomerRoleValue);
        const operationMonitoringBussiness = roleList.find((item:any) => item.value == BussinessOperationMonitoringRoleValue);

        if(values == smartCustomer){
            setSmartCustomerRole(true);
            setMobileSmartCustomer(false);
            setValue("smart_observer_options",SmartObserveArrayOptions);
        }
        else if(values == smartMobileCustomer){
            setMobileSmartCustomer(true);
            setSmartCustomerRole(false);
            setValue("smart_observer_options",SmartObserveArrayOptions);
            setValue("users_customer_array",[]);
        }
        else{
            setSmartCustomerRole(false);
            setMobileSmartCustomer(false);
            setValue("smart_observer_options",[])
        }

        if(values == monitoringOperatorCustomer){
            setIsMonitorOperatorCustomer(true);
        }else{
            setValue("gw_edit_auth_array",[]);
            setIsMonitorOperatorCustomer(false);
        }
        if(isMonitoringOperatorCustomerRoleLoginUser){
            if(values == operationMonitoringBussiness){
                setReadOnlyCodeRetrict(true);
                if(authRole["code_restriction_array"].length == 0){
                    setIsRestricted(false);
                    setValue("code_restriction_flag","0");
                    setValue("code_restriction_array",[]);
                }else{
                    setIsRestricted(true);
                    setValue("code_restriction_flag","1");
                    const cloneOptions = [...CodeRestrictionArrayOptions];
                    const options = [];
                    for(const item of authRole["code_restriction_array"]){
                        const data = cloneOptions.find((object:any) => object.value == item);
                        if(data){
                            options.push(data);
                        }
                    }                
                    setValue("code_restriction_array",options);
                }
                
                if(authRole["users_customer_info_array"].length > 0){
                    const cloneOptions = [...customerList];
                    const options = [];
                    for(const item of authRole["users_customer_info_array"]){
                        const data = cloneOptions.find((object:any) => object.value == item.id);
                        if(data){
                            options.push(data);
                        }
                    }
                    setValue("users_customer_array",options);
                }else{
                    setValue("users_customer_array",[]);
                }
            }else{
                setReadOnlyCodeRetrict(false);
            }
        }else{
            setReadOnlyCodeRetrict(false);
        }
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="company_group_name"
                                    render={({ field }) => (
                                        <Grid container direction="row">
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="組織グループ名"
                                                size="small"
                                                error={!!errors?.company_group_name}
                                                helperText={errors?.company_group_name?.message}
                                                inputProps={{
                                                    maxLength:255,
                                                    readOnly:true,
                                                }}
                                                sx={{backgroundColor:"floralwhite"}}
                                            />
                                        </Grid>
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="custom_company"
                                rules={{ required: "必須項目です。選択してください。", }}
                                render={({ field }) => (
                                    <Grid container spacing={1} direction="row">
                                        <Autocomplete
                                            {...field}
                                            autoHighlight
                                            options={customerList}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values:any) => {
                                                field.onChange(values)
                                                if(values != null){
                                                    setValue("company_group_name",values.group_name);
                                                    setValue("company_group_id",values.group_id);
                                                }else{
                                                    setValue("company_group_name","");
                                                    setValue("company_group_id",null);
                                                }
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} error={!!errors?.custom_company} helperText={errors?.custom_company?.message} />}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Eメール"
                                    size="small"
                                    {...register("email",
                                        {
                                            required: "必須項目です。入力してください。",
                                            pattern: {
                                                value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                message: 'メールアドレス形式で入力してください。'
                                            }
                                        },
                                    )}
                                    inputProps={{
                                        maxLength:255
                                    }}
                                    error={!!errors?.email}
                                    helperText={errors?.email?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="氏名-苗字"
                                    size="small"
                                    {...register("family_name",
                                        {
                                            required: '必須項目です。入力してください。'
                                        })
                                    }
                                    error={!!errors?.family_name}
                                    helperText={errors?.family_name?.message}
                                    inputProps={{
                                        maxLength:255
                                    }}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="氏名-名前"
                                    size="small"
                                    {...register("given_name",
                                        {
                                            required: '必須項目です。入力してください。'
                                        })
                                    }
                                    error={!!errors?.given_name}
                                    helperText={errors?.given_name?.message}
                                    inputProps={{
                                        maxLength:255
                                    }}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="custom_role"
                                rules={{ required: "必須項目です。選択してください。", }}
                                render={({ field }) => (
                                    <Grid container spacing={1} direction="row">
                                        <Autocomplete
                                            {...field}
                                            autoHighlight
                                            options={isMonitoringOperatorCustomerRoleLoginUser ? roleList.filter((item:any) => Number(item.value) > Number(currentLoginUser?.attributes["custom:role"])) : roleList}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values) => {
                                                field.onChange(values)
                                                handleChangeRole(values);
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label="ロール" error={!!errors?.custom_role} helperText={errors?.custom_role?.message} />}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <Controller
                                    control={control}
                                    name="users_customer_array"
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            disableCloseOnSelect
                                            fullWidth
                                            multiple
                                            size="small"
                                            options={customerList}
                                            isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values) => {
                                                field.onChange(values)
                                            }}
                                            renderOption={(field, option, { selected }) => (
                                                <li {...field}>
                                                    <Checkbox
                                                        checked={selected}
                                                    />
                                                    {option.label}
                                                </li>
                                            )}
                                            readOnly={(isMobileSmartCustomer)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="担当事業所の一覧"  style={{backgroundColor: (isMobileSmartCustomer) ? "floralwhite" :"white"}} />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        
                        {(!isSmartCustomer && !isMobileSmartCustomer) &&
                            <>
                                <Typography style={{ marginLeft: '5px' } } mt={1.5}>監視対象の制限</Typography>
                                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                    <Controller
                                        control={control}
                                        name="code_restriction_flag"
                                        render={({ field: { value } }) => (
                                            <Grid container spacing={1} direction="row" >
                                                <ToggleButtonGroup
                                                    color="primary"
                                                    value={value}
                                                    exclusive
                                                    onChange={(e, value) => handleChangeRestricted(e, value)}
                                                    aria-label="Platform"
                                                    size="small"
                                                    aria-labelledby="権限"
                                                    disabled={readOnlyCodeRetrict}
                                                >
                                                    <ToggleButton value="0">制限なし</ToggleButton>
                                                    <ToggleButton value="1">制限あり</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        )}
                                    />
                                </Stack>
                                { isRestricted &&
                                    <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                        <Grid container spacing={1} direction="row">
                                            <Controller
                                                control={control}
                                                name="code_restriction_array"
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        disableCloseOnSelect
                                                        fullWidth
                                                        multiple
                                                        readOnly={readOnlyCodeRetrict}
                                                        defaultValue={SmartObserveArrayOptions}
                                                        size="small"
                                                        options={optionCodeRestriction}
                                                        isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                                        getOptionLabel={(option) => option.label}
                                                        onChange={(event, values) => {
                                                            field.onChange(values)
                                                        }}
                                                        renderOption={(field, option, { selected }) => (
                                                            <li {...field}>
                                                                <Checkbox
                                                                    checked={selected}
                                                                />
                                                                {option.label}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="監視対象の一覧"/>
                                                        )}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Stack>
                                }
                                {
                                    isMonitorOperatorCustomer &&
                                    <>
                                        <Typography style={{ marginLeft: '5px' } } mt={1.5}>装置編集権限</Typography>
                                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px', marginTop: '10px' }}>
                                            <Controller
                                                control={control}
                                                name="restriction_auth_device_flag"
                                                render={({ field: { value } }) => (
                                                    <Grid container spacing={1} direction="row" >
                                                        <ToggleButtonGroup
                                                            color="primary"
                                                            value={value}
                                                            exclusive
                                                            onChange={(e, value) => handleChangeRestrictedAuthDevice(e, value)}
                                                            aria-label="Platform"
                                                            size="small"
                                                            aria-labelledby="権限"
                                                        >
                                                            <ToggleButton value="0">制限なし</ToggleButton>
                                                            <ToggleButton value="1">制限あり</ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Grid>
                                                )}
                                            />
                                        </Stack>
                                        { isRestrictedAuthDevice &&
                                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                                                <Grid container spacing={1} direction="row">
                                                    <Controller
                                                        control={control}
                                                        name="gw_edit_auth_array"
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                disableCloseOnSelect
                                                                fullWidth
                                                                multiple
                                                                defaultValue={undefined}
                                                                size="small"
                                                                options={GwAuthOptions}
                                                                isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                                                getOptionLabel={(option) => option.label}
                                                                onChange={(event, values) => {
                                                                    field.onChange(values)
                                                                }}
                                                                renderOption={(field, option, { selected }) => (
                                                                    <li {...field}>
                                                                        <Checkbox
                                                                            checked={selected}
                                                                        />
                                                                        {option.label}
                                                                    </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="権限の一覧"/>
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Stack>
                                        }
                                    </>
                                }
                            </>
                        }
                        {
                            (isSmartCustomer || isMobileSmartCustomer) &&
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={2} style={{ marginLeft: '10px' }}>
                                <Grid container spacing={1} direction="row">
                                <FormControl fullWidth>
                                    <Typography mb={2}>監視対象の設定</Typography>
                                    <Controller
                                        control={control}
                                        name="smart_observer_options"
                                        rules={{ required: "必須項目です。選択してください。" }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                multiple
                                                disableCloseOnSelect
                                                fullWidth
                                                size="small"
                                                options={optionSmartObserve}
                                                isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(event, values) => {
                                                    field.onChange(values)
                                                }}
                                                renderOption={(field, option, { selected }) => (
                                                    <li {...field}>
                                                        <Checkbox
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="監視対象" error={!!errors?.smart_observer_options} helperText={errors?.smart_observer_options?.message}/>
                                                )}
                                            />
                                        )}
                                    />
                                </FormControl>
                                </Grid>
                            </Stack>
                        }
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
};
export default UsersAdd;


