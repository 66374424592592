import { useEffect, useState } from "react";
import { formatNumber, formatRoundDownDecimal } from "../../utils";

export interface calendarItem {
    month: string;
    date: string;
    month_kwh: any;
    month_kw:any;
    month_amount:any;
    days_data: any;
}

export type YearContentProps = {
    calendarItem: calendarItem,
    mode: string
    // kwh
    monthMaxKwh?:number, //kWhの最大の月合計値　
    yearMaxKwh?:number,  //kWhの最大の明細値
    // kw
    yearMaxKw?:number, //kWの最大の明細値
    monthValue?:any, //kWの最大の明細値の最後月
    dayValue?:any,  //kWの最大の明細値の最後日
    // amount
    amountValueYears?:number, //金額の最大の明細値
    monthsMaxAmount?:number, //金額の最大の月合計値　
    // kwh && amount
    monthKwhValue?:any, //kWh・金額の最大の明細値の最後月
    dayKwhValue?:any, //kWh・金額の最大の明細値の最後日

};

export const YearContent = (props: YearContentProps) => {
    const { calendarItem, mode, monthMaxKwh, yearMaxKw, monthsMaxAmount, yearMaxKwh,monthValue ,dayValue ,amountValueYears,monthKwhValue ,dayKwhValue } = props;
    const [maxKwhMonth,setMaxKwhMonth] = useState<any>(0);
    const [maxKwMonth,setMaxKwMonth] = useState<any>(0);
    const [maxAmountMonth,setMaxAmountMonth] = useState<any>(0);

    const getStyleColor = (month_max_kwh: any,month_kwh:any) => {
        if(month_kwh === '-' ||  month_kwh === '0'){
            return 'white';
        }
        return `rgba(255,0,0,${month_kwh/month_max_kwh})`;
    }

    useEffect(()=>{
        
        const days_data = calendarItem.days_data;
        if(days_data.length > 0){
            const max_kwh_in_month = days_data.reduce((a:any,b:any)=>a.day_kwh>b.day_kwh?a:b).day_kwh; 
            const max_kw_in_month = days_data.reduce((a:any,b:any)=>a.day_kw>b.day_kw?a:b).day_kw; 
            const max_amount_in_month = days_data.reduce((a:any,b:any)=>a.day_amount>b.day_amount?a:b).day_amount; 
            setMaxKwhMonth(max_kwh_in_month);
            setMaxKwMonth(max_kw_in_month);
            setMaxAmountMonth(max_amount_in_month)
        }
    },[])

    const getkwhValue = (data: any) => {
        let result = [] as any[];
        if (data.length > 0) {
            for (let index = 1; index <= 31; index++) {
                let value = data.filter((x : any) => x.day === index)
                if(value.length > 0){
                    result.push({day_kwh: value[0].day_kwh !== null ? value[0].day_kwh : "-",day:value[0].day !== null ? value[0].day : "-"})
                }else{
                    result.push({day_kwh: "-",day:"-"})
                }
                
            }
        }
        return result;
    }

    const getamountValue = (data: any) => {
        let result = [] as any[];
        if (data.length > 0) {
            for (let index = 1; index <= 31; index++) {
                let value = data.filter((x : any) => x.day === index)
                if(value.length > 0){
                    result.push({day_amount: value[0].day_amount !== null ? value[0].day_amount : "-",day:value[0].day !== null ? value[0].day : "-"})
                }else{
                    result.push({day_amount: "-",day:"-"})
                }
                
            }
        }
        return result;
    }

    const getkwValue = (data: any) => {
        let result = [] as any[];
        if (data.length > 0) {
            for (let index = 1; index <= 31; index++) {
                let value = data.filter((x : any) => x.day === index)
                if(value.length > 0){
                    result.push({day_kw: value[0].day_kw !== null ? value[0].day_kw : "-",day:value[0].day !== null ? value[0].day : "-"})
                }else{
                    result.push({day_kw: "-",day:"-"})
                }
                
            }
        }
        return result;
    }
    const colorMonthKwh = getStyleColor(monthMaxKwh,calendarItem.month_kwh);
    const colorMonthKw = getStyleColor(yearMaxKw,calendarItem.month_kw);
    const colorMonthAmount = getStyleColor(monthsMaxAmount,calendarItem.month_amount)
    const kwhValue = getkwhValue(calendarItem.days_data);
    const amount = getamountValue(calendarItem.days_data);
    const kwValue = getkwValue(calendarItem.days_data);

    const styleMonthKwh = {
        textAlign: calendarItem.month_kwh === "-" ? 'center' as const : 'right' as const,
        paddingRight: calendarItem.month_kwh === "-" ? '0px' : '2px',
        background: colorMonthKwh,
        fontWeight: calendarItem.month_kwh === monthMaxKwh ? 'bold' : "normal",
        width:"100%",
    }

    const styleMonthKw = {
        textAlign: (calendarItem.month_kw) === "-" ? 'center' as const : 'right' as const,
        paddingRight: (calendarItem.month_kw) === "-" ? '0px' : '2px',
        background: colorMonthKw,
        fontWeight: calendarItem.month_kw === yearMaxKw ? 'bold' : "normal",
    }

    const styleMonthAmout = {
        textAlign: calendarItem.month_amount === "-" ? 'center' as const : 'right' as const,
        paddingRight: calendarItem.month_amount === "-" ? '0px' : '2px',
        background: colorMonthAmount,
        fontWeight: calendarItem.month_amount === monthsMaxAmount ? 'bold' : "normal",
    }
    return (
        <tr>
            {mode === 'kwh' &&
                <>
                    
                    <td className={'sticky-col first-col'} style={{backgroundColor:"white",padding:"0px",margin:"0px"}}>
                        <div style={styleMonthKwh}>
                            {formatRoundDownDecimal(calendarItem.month_kwh,0)}
                        </div>
                    </td>
                    <td className={'sticky-col second-col'} style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ccccff'}}>{calendarItem.month}月</td>
                    {[...Array(31)].map((x, i) =>
                        <>
                            {calendarItem.days_data.length > 0 ? (
                                
                                <td 
                                style={{ textAlign: 'center', backgroundColor: yearMaxKwh?`rgba(255,0,0,${kwhValue[i].day_kwh/yearMaxKwh})`:"white",
                                color:calendarItem.days_data.length > 0 && calendarItem.month == monthKwhValue && kwValue[i].day ==  dayKwhValue ? "white" : "black",
                                fontWeight: kwhValue[i].day_kwh == maxKwhMonth ? "bold" : "normal"}}>
                                    {formatRoundDownDecimal(kwhValue[i].day_kwh,0)}
                                </td>
                            ):(
                                <td style={{ textAlign: 'center', backgroundColor: 'white'}}>-</td>
                            )}
                        </>
                        
                    )}
                </>
            }

            {mode === 'kw' &&
                <>
                    <td className={'sticky-col first-col'} style={{backgroundColor:"white",padding:"0px",margin:"0px"}}>
                        <div style={styleMonthKw}>
                            {calendarItem.month_kw != "0"? formatRoundDownDecimal(calendarItem.month_kw,0) : "-"}
                        </div>
                    </td>
                    <td className={'sticky-col second-col'}  style={{ textAlign: 'center', backgroundColor: '#ccccff', width: '15px',fontWeight:"bold"}}>{calendarItem.month}月</td>
                    {[...Array(31)].map((x, i) =>
                        <>
                            {calendarItem.days_data.length > 0 ? (
                                <td 
                                style={{ 
                                    textAlign: 'center'
                                    , backgroundColor:yearMaxKw ? `rgba(255,0,0,${kwValue[i].day_kw/yearMaxKw})`:'white',
                                    fontWeight: kwValue[i].day_kw == maxKwMonth ? "bold" : "normal"
                                    ,color:  calendarItem.days_data.length > 0 && calendarItem.month == monthValue && kwValue[i].day ==  dayValue ? "white" : "black"
                                }}>
                                    {formatRoundDownDecimal(kwValue[i].day_kw,0)}
                                </td>
                            ):(
                                <td style={{ textAlign: 'center', backgroundColor: 'white'}}>-</td>
                            )}
                        </>
                    )}
                </>
            }
            {mode === 'money' &&
                <>
                    <td className={'sticky-col first-col'} style={{backgroundColor:"white",padding:"0px",margin:"0px"}}>
                        <div style={styleMonthAmout}>
                            {formatNumber(calendarItem.month_amount)}
                        </div>
                    </td>
                    <td className={'sticky-col second-col'} style={{ textAlign: 'center', backgroundColor: '#ccccff', width: '15px',fontWeight:"bold"}}>{calendarItem.month}月</td>
                    {[...Array(31)].map((x, i) =>
                        <>
                            {calendarItem.days_data.length > 0 ? (
                                <td style={{ textAlign: 'center', 
                                backgroundColor: amountValueYears ? `rgba(255,0,0,${amount[i].day_amount/amountValueYears})`:"white",
                                fontWeight: amount[i].day_amount == maxAmountMonth ? "bold" : "normal",
                                color: calendarItem.days_data.length > 0 && calendarItem.month == monthKwhValue && kwValue[i].day ==  dayKwhValue ? "white" : "black"}}>
                                    {formatNumber(amount[i].day_amount)}
                                </td>
                            ):(
                                <td style={{ textAlign: 'center', backgroundColor: 'white'}}>-</td>
                            )}
                        </>
                        
                    )}
                </>
            }
        </tr>
    );
};

export default YearContent;
