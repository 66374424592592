import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { connect } from "react-redux";
import { ExcessHeavyLeakageCurrentModel, UpdateResult } from '../../../models';
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import { changeDisabledPollingBtn, showUnit } from '../../../utils';
import FetchHelper from '../../FetchHelper';
import SelectExcessHeavyLeakageCurrentDialog from '../../dialogs/SelectExcessHeavyLeakageCurrentDialog';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 11;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
    isDisbleUpdateBtn: true,
}

const fetchId = "EXCESS_HEAVY_LEAKAGE_CURRENT_FETCH";

type ExcessHeavyLeakageCurrentProps = {
    defaultData: ExcessHeavyLeakageCurrentModel[] | undefined;
};

export type PureExcessHeavyLeakageCurrentProps = ExcessHeavyLeakageCurrentProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

export const ExcessHeavyLeakageCurrent = (props: PureExcessHeavyLeakageCurrentProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isEdit, isRefreshGroup, isDisbleRefeshBtn, isDisbleUpdateBtn } = props;
    const { onError, onUpdate, onRefresh, onUpdateSuccess, onUpdateError, isRefreshAllCompleted, setIsEdit, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [updateData, setUpdateData] = React.useState<any>();
    const [excessHeavyLeakageCurrent0, setExcessHeavyLeakageCurrent0] = React.useState<ExcessHeavyLeakageCurrentModel>(fetchState.data);
    const [excessHeavyLeakageCurrent1, setExcessHeavyLeakageCurrent1] = React.useState<ExcessHeavyLeakageCurrentModel>(fetchState.data);
    const [excessHeavyLeakageCurrent2, setExcessHeavyLeakageCurrent2] = React.useState<ExcessHeavyLeakageCurrentModel>(fetchState.data);
    const [excessHeavyLeakageCurrent3, setExcessHeavyLeakageCurrent3] = React.useState<ExcessHeavyLeakageCurrentModel>(fetchState.data);
    const [excessHeavyLeakageCurrent4, setExcessHeavyLeakageCurrent4] = React.useState<ExcessHeavyLeakageCurrentModel>(fetchState.data);
    const [editData, setEditData] = React.useState<ExcessHeavyLeakageCurrentModel>();
    const [fetchLoadingDevice0, setFetchLoadingDevice0] = React.useState(false);
    const [fetchLoadingDevice1, setFetchLoadingDevice1] = React.useState(false);
    const [fetchLoadingDevice2, setFetchLoadingDevice2] = React.useState(false);
    const [fetchLoadingDevice3, setFetchLoadingDevice3] = React.useState(false);
    const [fetchLoadingDevice4, setFetchLoadingDevice4] = React.useState(false);
    const [editFlg, setEditFlg] = React.useState(new Array(5).fill('').map(() => false));
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const [isRefreshSuccess0, setIsRefreshSuccess0] = React.useState(false);
    const [isRefreshSuccess1, setIsRefreshSuccess1] = React.useState(false);
    const [isRefreshSuccess2, setIsRefreshSuccess2] = React.useState(false);
    const [isRefreshSuccess3, setIsRefreshSuccess3] = React.useState(false);
    const [isRefreshSuccess4, setIsRefreshSuccess4] = React.useState(false);

    const deviceNos = ['00', '01', '02', '03', '04'];
    const deviceNames = ['親機', '子機1', '子機2', '子機3', '子機4'];

    // パラメータ設定
    const handleFetchParams = (deviceNo: string) => {
        const params = new Map<string, any>();
        const code = "QC";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", deviceNo);
        return params;
    }

    const handleFetchSuccess = (data: any, action: string, isClickedRefreshBtn: boolean) => {
        setEditFlg(changeDisabledPollingBtn(false, data.device_no, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            limitOverSec1: data.limit_over_sec1,
            recoverySec1: data.recovery_sec1,
            limitOverSec2: data.limit_over_sec2,
            recoverySec2: data.recovery_sec2,
            limitOverSec3: data.limit_over_sec3,
            recoverySec3: data.recovery_sec3,
            limitOverSec4: data.limit_over_sec4,
            recoverySec4: data.recovery_sec4,
            limitOverSec5: data.limit_over_sec5,
            recoverySec5: data.recovery_sec5,
            limitOverSec6: data.limit_over_sec6,
            recoverySec6: data.recovery_sec6,
            lastGetDatetime: data.last_get_datetime,
        } as ExcessHeavyLeakageCurrentModel;

        switch (data.device_no) {
            case deviceNos[0]:
                setExcessHeavyLeakageCurrent0(resData);
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                isClickedRefreshBtn && setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                setExcessHeavyLeakageCurrent1(resData);
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                isClickedRefreshBtn && setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                setExcessHeavyLeakageCurrent2(resData);
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                isClickedRefreshBtn && setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                setExcessHeavyLeakageCurrent3(resData);
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                isClickedRefreshBtn && setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                setExcessHeavyLeakageCurrent4(resData);
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                isClickedRefreshBtn && setIsRefreshSuccess4(true);
                break;
        }

        if (action === 'edit') {
            setEditData(resData);
            setOpenSelectDialog(true);
        }
    };

    const handleError = (success: boolean, data: any, error: any, deviceNo: string) => {
        onError(success, data, error, deviceNames[Number(deviceNo)]);
        setEditFlg(changeDisabledPollingBtn(false, deviceNo, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                setIsRefreshSuccess4(false);
                break;
        }
    }

    const handleRefresh = (deviceNo: string) => {
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
    };

    const handleRefreshSuccess = (data: any, deviceNo: string) => {
        const deviceIndex = deviceNos.findIndex((element) => element === data.device_no);
        editFlg[deviceIndex] ? handleFetchSuccess(data, 'edit', true) : handleFetchSuccess(data, 'view', true);
    }

    const [updateDialogTitle, setUpdateDialogTitle] = React.useState('');
    const handleEdit = (deviceNo: string) => {
        setEditFlg(changeDisabledPollingBtn(true, deviceNo, deviceNos, editFlg));
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
        switch (deviceNo) {
            case deviceNos[0]:
                setUpdateDialogTitle('重漏電流超過・復帰時間 / 親機');
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setUpdateDialogTitle('重漏電流超過・復帰時間 / 子機1');
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setUpdateDialogTitle('重漏電流超過・復帰時間 / 子機2');
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setUpdateDialogTitle('重漏電流超過・復帰時間 / 子機3');
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setUpdateDialogTitle('重漏電流超過・復帰時間 / 子機4');
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateEquipmentOk = (request: any) => {
        setOpenSelectDialog(false);
        const code = "QD";
        const requestData = {
            mainid: request.mainid,
            device_no: request.device_no,
            limit_over_sec1: request.limit_over_sec1,
            recovery_sec1: request.recovery_sec1,
            limit_over_sec2: request.limit_over_sec2,
            recovery_sec2: request.recovery_sec2,
            limit_over_sec3: request.limit_over_sec3,
            recovery_sec3: request.recovery_sec3,
            limit_over_sec4: request.limit_over_sec4,
            recovery_sec4: request.recovery_sec4,
            limit_over_sec5: request.limit_over_sec5,
            recovery_sec5: request.recovery_sec5,
            limit_over_sec6: request.limit_over_sec6,
            recovery_sec6: request.recovery_sec6,
        }
        onUpdate(code, mainid, requestData);
        setUpdateData(request);
        switch (request.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateEquipmentCancel = () => {
        setOpenSelectDialog(false);
    };

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        const result = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            resultCode: data.result_code,
        } as UpdateResult;
        if (result.resultCode === '0') {
            handleFetchSuccess(updateData, 'view', true);
            onUpdateSuccess(deviceNames[Number(result.deviceNo)]);
        } else {
            handleUpdateError(success, data, error);
        }
    }

    const handleUpdateError = (success: boolean, data: any, error: any) => {
        switch (updateData.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                setIsRefreshSuccess4(true);
                break;
        }
        onUpdateError(success, data, error, deviceNames[Number(updateData.device_no)]);
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh(deviceNos[0]);
        }
        if (isRefreshGroup === true) {
            handleRefresh(deviceNos[0]);
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, loadingRefreshAll, isRefreshGroup]);

    const handleDisabledRefreshAll = () => {
        if (!fetchLoadingDevice0 && !fetchLoadingDevice1 && !fetchLoadingDevice2 && !fetchLoadingDevice3 && !fetchLoadingDevice4) {
            setDisabledRefreshGroupBtn(false, fetchId);
        } else {
            setDisabledRefreshGroupBtn(true, fetchId);
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevice0, fetchLoadingDevice1, fetchLoadingDevice2, fetchLoadingDevice3, fetchLoadingDevice4]);

    React.useEffect(() => {
        [...editFlg].find(item => item === true) === true ? setIsEdit(true) : setIsEdit(false);
        // eslint-disable-next-line
    }, [editFlg])

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, 'view', false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess0 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('00')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice0 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('00')} disabled={isDisbleRefeshBtn || editFlg[0] || fetchLoadingDevice0 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[0] === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='重漏電流超過・復帰時間 / 親機'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice0 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.limitOverSec1}{showUnit(excessHeavyLeakageCurrent0?.limitOverSec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.recoverySec1}{showUnit(excessHeavyLeakageCurrent0?.recoverySec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.limitOverSec2}{showUnit(excessHeavyLeakageCurrent0?.limitOverSec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.recoverySec2}{showUnit(excessHeavyLeakageCurrent0?.recoverySec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.limitOverSec3}{showUnit(excessHeavyLeakageCurrent0?.limitOverSec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.recoverySec3}{showUnit(excessHeavyLeakageCurrent0?.recoverySec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.limitOverSec4}{showUnit(excessHeavyLeakageCurrent0?.limitOverSec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.recoverySec4}{showUnit(excessHeavyLeakageCurrent0?.recoverySec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.limitOverSec5}{showUnit(excessHeavyLeakageCurrent0?.limitOverSec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.recoverySec5}{showUnit(excessHeavyLeakageCurrent0?.recoverySec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.limitOverSec6}{showUnit(excessHeavyLeakageCurrent0?.limitOverSec6, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent0?.recoverySec6}{showUnit(excessHeavyLeakageCurrent0?.recoverySec6, "s")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{excessHeavyLeakageCurrent0?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess1 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('01')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice1 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('01')} disabled={isDisbleRefeshBtn || editFlg[1] || fetchLoadingDevice1 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[1] === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='重漏電流超過・復帰時間 / 子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice1 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.limitOverSec1}{showUnit(excessHeavyLeakageCurrent1?.limitOverSec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.recoverySec1}{showUnit(excessHeavyLeakageCurrent1?.recoverySec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.limitOverSec2}{showUnit(excessHeavyLeakageCurrent1?.limitOverSec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.recoverySec2}{showUnit(excessHeavyLeakageCurrent1?.recoverySec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.limitOverSec3}{showUnit(excessHeavyLeakageCurrent1?.limitOverSec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.recoverySec3}{showUnit(excessHeavyLeakageCurrent1?.recoverySec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.limitOverSec4}{showUnit(excessHeavyLeakageCurrent1?.limitOverSec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.recoverySec4}{showUnit(excessHeavyLeakageCurrent1?.recoverySec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.limitOverSec5}{showUnit(excessHeavyLeakageCurrent1?.limitOverSec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.recoverySec5}{showUnit(excessHeavyLeakageCurrent1?.recoverySec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.limitOverSec6}{showUnit(excessHeavyLeakageCurrent1?.limitOverSec6, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent1?.recoverySec6}{showUnit(excessHeavyLeakageCurrent1?.recoverySec6, "s")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{excessHeavyLeakageCurrent1?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess2 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('02')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice2 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('02')} disabled={isDisbleRefeshBtn || editFlg[2] || fetchLoadingDevice2 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[2] === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='重漏電流超過・復帰時間 / 子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice2 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.limitOverSec1}{showUnit(excessHeavyLeakageCurrent2?.limitOverSec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.recoverySec1}{showUnit(excessHeavyLeakageCurrent2?.recoverySec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.limitOverSec2}{showUnit(excessHeavyLeakageCurrent2?.limitOverSec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.recoverySec2}{showUnit(excessHeavyLeakageCurrent2?.recoverySec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.limitOverSec3}{showUnit(excessHeavyLeakageCurrent2?.limitOverSec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.recoverySec3}{showUnit(excessHeavyLeakageCurrent2?.recoverySec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.limitOverSec4}{showUnit(excessHeavyLeakageCurrent2?.limitOverSec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.recoverySec4}{showUnit(excessHeavyLeakageCurrent2?.recoverySec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.limitOverSec5}{showUnit(excessHeavyLeakageCurrent2?.limitOverSec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.recoverySec5}{showUnit(excessHeavyLeakageCurrent2?.recoverySec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.limitOverSec6}{showUnit(excessHeavyLeakageCurrent2?.limitOverSec6, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent2?.recoverySec6}{showUnit(excessHeavyLeakageCurrent2?.recoverySec6, "s")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{excessHeavyLeakageCurrent2?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess3 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('03')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice3 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('03')} disabled={isDisbleRefeshBtn || editFlg[3] || fetchLoadingDevice3 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[3] === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='重漏電流超過・復帰時間 / 子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice3 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.limitOverSec1}{showUnit(excessHeavyLeakageCurrent3?.limitOverSec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.recoverySec1}{showUnit(excessHeavyLeakageCurrent3?.recoverySec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.limitOverSec2}{showUnit(excessHeavyLeakageCurrent3?.limitOverSec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.recoverySec2}{showUnit(excessHeavyLeakageCurrent3?.recoverySec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.limitOverSec3}{showUnit(excessHeavyLeakageCurrent3?.limitOverSec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.recoverySec3}{showUnit(excessHeavyLeakageCurrent3?.recoverySec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.limitOverSec4}{showUnit(excessHeavyLeakageCurrent3?.limitOverSec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.recoverySec4}{showUnit(excessHeavyLeakageCurrent3?.recoverySec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.limitOverSec5}{showUnit(excessHeavyLeakageCurrent3?.limitOverSec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.recoverySec5}{showUnit(excessHeavyLeakageCurrent3?.recoverySec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.limitOverSec6}{showUnit(excessHeavyLeakageCurrent3?.limitOverSec6, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent3?.recoverySec6}{showUnit(excessHeavyLeakageCurrent3?.recoverySec6, "s")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{excessHeavyLeakageCurrent3?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess4 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('04')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice4 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('04')} disabled={isDisbleRefeshBtn || editFlg[4] || fetchLoadingDevice4 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[4] === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='重漏電流超過・復帰時間 / 子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0', minHeight: '200px' }}>
                    {fetchLoadingDevice4 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>超過時間6</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>復帰時間6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.limitOverSec1}{showUnit(excessHeavyLeakageCurrent4?.limitOverSec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間1</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.recoverySec1}{showUnit(excessHeavyLeakageCurrent4?.recoverySec1, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.limitOverSec2}{showUnit(excessHeavyLeakageCurrent4?.limitOverSec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間2</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.recoverySec2}{showUnit(excessHeavyLeakageCurrent4?.recoverySec2, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.limitOverSec3}{showUnit(excessHeavyLeakageCurrent4?.limitOverSec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間3</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.recoverySec3}{showUnit(excessHeavyLeakageCurrent4?.recoverySec3, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.limitOverSec4}{showUnit(excessHeavyLeakageCurrent4?.limitOverSec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間4</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.recoverySec4}{showUnit(excessHeavyLeakageCurrent4?.recoverySec4, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.limitOverSec5}{showUnit(excessHeavyLeakageCurrent4?.limitOverSec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間5</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.recoverySec5}{showUnit(excessHeavyLeakageCurrent4?.recoverySec5, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>超過時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.limitOverSec6}{showUnit(excessHeavyLeakageCurrent4?.limitOverSec6, "s")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>復帰時間6</td>
                                    <td style={pollingColStyle}>{excessHeavyLeakageCurrent4?.recoverySec6}{showUnit(excessHeavyLeakageCurrent4?.recoverySec6, "s")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{excessHeavyLeakageCurrent4?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {
                openSelectDialog ? (
                    <SelectExcessHeavyLeakageCurrentDialog
                        isOpen={true}
                        onOK={(data) => handleUpdateEquipmentOk(data)}
                        onCancel={handleUpdateEquipmentCancel}
                        data={editData}
                        dialogTitle={updateDialogTitle}
                    />
                ) : (
                    <div />
                )
            }
            <FetchHelper fetchId={fetchId + deviceNos[0]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[0]) : handleError(success, data, error, deviceNos[0])} />
            <FetchHelper fetchId={fetchId + deviceNos[1]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[1]) : handleError(success, data, error, deviceNos[1])} />
            <FetchHelper fetchId={fetchId + deviceNos[2]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[2]) : handleError(success, data, error, deviceNos[2])} />
            <FetchHelper fetchId={fetchId + deviceNos[3]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[3]) : handleError(success, data, error, deviceNos[3])} />
            <FetchHelper fetchId={fetchId + deviceNos[4]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[4]) : handleError(success, data, error, deviceNos[4])} />
            <FetchHelper fetchId="UPDATE_EXCESS_HEAVY_LEAKAGE_CURRENT" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(success, data, error) : handleUpdateError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onUpdate: (code: string, mainid: string, data: any) => actionCreators.fetch("UPDATE_EXCESS_HEAVY_LEAKAGE_CURRENT", `/polling/set?code=${code}`, 'POST', data, false, true),
    onRefresh: (params: any, fetchId: string) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `重漏電流超過・復帰時間/${deviceName}取得`,
            body: "重漏電流超過・復帰時間取得できませんでした。",
        }),
    onUpdateSuccess: (deviceName: string) =>
        actionCreators.showMessage({
            type: "info",
            title: `重漏電流超過・復帰時間/${deviceName}変更`,
            body: "重漏電流超過・復帰時間を変更しました。",
        }),
    onUpdateError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `重漏電流超過・復帰時間/${deviceName}変更`,
            body: "重漏電流超過・復帰時間を変更できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExcessHeavyLeakageCurrent);


