import { Button, Grid, Stack, TextField } from "@mui/material";
import * as React from "react";
import { connect } from "react-redux";
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";

import CircularProgress from '@mui/material/CircularProgress';
import { push } from "connected-react-router";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import DeviceInformation from "../device/DeviceInformation";
import { Customer } from "../../../models";

// FETCH ID
const fetchId = "SOLARS_FETCH_ID";
const menusFetchId = 'MENUS_FETCH';

// フォーム定義
interface EditSolarsForm {
    mainid: string,
    plant_name: string,
    jpy_per_kwh: string,
    predict_info: string,
    panel_capacity: string,
}

// 初期値設定
const initialValue: EditSolarsForm = {
    mainid: "",
    plant_name: "",
    jpy_per_kwh: "",
    predict_info: "",
    panel_capacity: "",
}

// -------------
// Props
export type SolarsDetailsProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
// -------------
// Component
export const SolarsDetails = (props: SolarsDetailsProps) => {
    const { onError, onUpdate, onUpdateSuccess, onUpdateError, fetchStateMenu, onGetAuthRole  } = props;

    const urlParams = new URLSearchParams(window.location.search);
    const mainid = urlParams.get('mainid');
    const { control, handleSubmit, setValue, formState: { errors } } = useForm<EditSolarsForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [device, setDevice] = React.useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [location] = React.useState(useLocation());
    const [hiddenOptions] = React.useState(location.state ? location.state.hiddenOptions : null);
    const [authRole, setAuthRole] = React.useState<any>();
    const [disableSaveBtn, setDisableSaveBtn] = React.useState(true);
    localStorage.setItem('title', '監視装置');
    let navigate = useNavigate();


    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
            
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(() => {
        if (authRole !== undefined && device !== undefined){
            if (authRole.auth_role_map['GW-solarsave-all']){
                setDisableSaveBtn(false);
            } else {
                if (authRole.auth_role_map['GW-solarsave-belong']){
                    if (authRole.company_id == device.customer_id)
                    setDisableSaveBtn(false);
                }
                if (authRole.auth_role_map['GW-solarsave-group']){
                    if (authRole.company_group_id == device.group_id)
                        {
                            setDisableSaveBtn(false);
                        }
                }
                if (authRole.auth_role_map['GW-solarsave-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == device.customer_id).length > 0)
                        setDisableSaveBtn(false);
                }
            }
            
        }
    },[authRole, device])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    // Submitイベント
    const handleEditSubmit = (data: EditSolarsForm) => {
        const request = createRequestData(data);
        setLoadingFlag(true);
        onUpdate(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: EditSolarsForm) => {
        let request = {
            mainid: mainid,
            plant_name: formValue.plant_name ? formValue.plant_name : '',
            jpy_per_kwh: formValue.jpy_per_kwh,
            predict_info: formValue.predict_info ? formValue.predict_info : '',
            panel_capacity: formValue.panel_capacity,
        } as any;
        console.log(request);
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        if(hiddenOptions){
            // Back to SettingSolar
            navigate(RoutePath.SettingSolarList);
            return
        }
        if(authRole){
            if(authRole.auth_role_map["LM-gateway-view"]){
                navigate(RoutePath.DeviceList)
                return
            }
            if(authRole.auth_role_map["LM-smonitor-view"]){
                if(authRole.code_restriction_array?.includes("GASOLAR")){
                    navigate(RoutePath.SettingSolarList);
                    return;
                }
            }
        }
        navigate("/");
        return
        
    };

    // 取得正常終了時の初期表示
    const handleSuccess = (data: any) => {
        setValue('mainid', data.mainid);
        setValue('plant_name', data.plant_name);
        setValue('jpy_per_kwh', data.jpy_per_kwh);
        setValue('predict_info', data.predict_info);
        setValue('panel_capacity', data.panel_capacity);
        setLoadingFlag(false);
    }

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid # ccc',
        boxShadow: '0 0 5px #fff, 0 0 5px #ccc, 0 0 1px #aaa',
        padding: '20px 40px',
        marginTop: '10px',
    }

    //タイトルの高さを戻
    React.useEffect(() => {
        return () => {
            let div: any = document.getElementsByClassName('css-ua3wu6')[0];
            if (typeof (div) !== 'undefined' && div != null) {
                div.removeAttribute("style");
            }
        };
    }, []);

    const handleFetchParam = (key: string) => {
        const params = new Map<string, any>();
        params.set(key, mainid);
        return params;
    }

    const handleUpdateSuccess = (data: any) => {
        const result = {
            resultCode: data.result_code,
            updateData: data.data,
        };
        if (result.resultCode === 1) {
            handleUpdateError();
        } else {
            onUpdateSuccess();
            setLoadingFlag(false);
        }
    }

    const handleUpdateError = () => {
        onUpdateError();
        setLoadingFlag(false);
    }

    const handleFetchDeviceSuccess = (data: any) => {
        setDevice(data)
    }

    return (
        <div style={{ margin: 'auto 10px' }}>
            <FetchHelper fetchId={'DEVICE_FETCH'}
                url={"/master/gateways/get"}
                method="GET"
                request={true}
                params={handleFetchParam('id')}
                onComplete={(success, data, error) => {
                    success ? handleFetchDeviceSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={fetchId}
                url={"/master/solars/get"}
                method="GET"
                request={true}
                params={handleFetchParam('mainid')}
                onComplete={(success, data, error) => {
                    success ? handleSuccess(data) : handleError(success, data, error)
                }}
            />
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <DeviceInformation deviceInformation={device} mainid={mainid ?? ''} title="太陽光編集" isDisplayDeviceInfo={true} isShowMenuButton={hiddenOptions ? false : true}></DeviceInformation>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={divStyles}>
                        <Stack spacing={3}>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={1} direction="row" >
                                    <Controller
                                        name="plant_name"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="発電所の名称"
                                                size="small"
                                                inputProps={{ maxLength: 255 }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={1} direction="row" >
                                    <Controller
                                        name="jpy_per_kwh"
                                        control={control}
                                        rules={{
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^(\d+\.)?\d+$/,
                                                message: '数字を入力してください。'
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="単価"
                                                size="small"
                                                error={!!errors?.jpy_per_kwh}
                                                helperText={errors?.jpy_per_kwh?.message}
                                                inputProps={{ maxLength: 255 }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={1} direction="row" >
                                    <Controller
                                        name="predict_info"
                                        control={control}
                                        rules={
                                            {
                                                required:"必須項目です。入力してください。",
                                                pattern:{
                                                    value:/^[\[][\ ]{0,}[\"][0-9]{0,}[\"][\ ]{0,}(?:[\,][\ ]{0,}[\"][0-9]{0,}[\"][\ ]{0,}){11}[\]]$/,
                                                    message:`「予想電力情報」のフォーマットが正しくありません。`
                                                }
                                            }
                                        }
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="予想電力情報"
                                                size="small"
                                                inputProps={{ maxLength: 255 }}
                                                error={!!errors?.predict_info}
                                                helperText={errors?.predict_info?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3}>
                                <Grid container spacing={1} direction="row" >
                                    <Controller
                                        name="panel_capacity"
                                        control={control}
                                        rules={{
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^(\d+\.)?\d+$/,
                                                message: '数字を入力してください。'
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="パネル容量"
                                                size="small"
                                                error={!!errors?.panel_capacity}
                                                helperText={errors?.panel_capacity?.message}
                                                inputProps={{ maxLength: 255 }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                        </Stack>
                    </div>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} width="100%" margin="10px auto 0">
                        <Grid item xs={1.5}>
                            <Button fullWidth variant="contained" type="submit" disabled={disableSaveBtn}>保存</Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button fullWidth color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Grid>
                    </Stack>
                </div>
            </form >
            <FetchHelper fetchId="UPDATE_SOLARS" onComplete={(success, data, error) => success ? handleUpdateSuccess(data) : handleUpdateError()} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "太陽光情報",
            body: "太陽光情報が取得出来ません。",
        }),
    onCancel: () => push(RoutePath.DeviceList),
    onUpdate: (data: any) => actionCreators.fetch("UPDATE_SOLARS", `/master/solars/edit`, 'POST', data, false, true),
    onUpdateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "太陽光変更",
            body: "太陽光を更新しました。",
        }),
    onUpdateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "太陽光変更",
            body: "太陽光を更新出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SolarsDetails as any);