import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { connect } from "react-redux";
import { LightLeakageSettingModel, UpdateResult } from '../../../models';
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import { changeDisabledPollingBtn, showUnit } from '../../../utils';
import FetchHelper from '../../FetchHelper';
import SelectLightLeakageSettingDialog from '../../dialogs/SelectLightLeakageSettingDialog';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 5;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setRefreshGroup: (refreshGroup: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
    isDisbleUpdateBtn: true,
}

type LightLeakageSettingProps = {
    defaultData: LightLeakageSettingModel[] | undefined;
};

export type PureLightLeakageSettingProps = LightLeakageSettingProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

const fetchId = "LIGHT_LEAKAGE_SETTING_FETCH";
export const LightLeakageSetting = (props: PureLightLeakageSettingProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isEdit, isRefreshGroup, isDisbleRefeshBtn, isDisbleUpdateBtn } = props;
    const { onError, onRefresh, onUpdate, onUpdateSuccess, onUpdateError, isRefreshAllCompleted, setIsEdit, setRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [updateData, setUpdateData] = React.useState<any>();
    const [lightLeakageSetting0, setLightLeakageSetting0] = React.useState<LightLeakageSettingModel>(fetchState.data);
    const [lightLeakageSetting1, setLightLeakageSetting1] = React.useState<LightLeakageSettingModel>(fetchState.data);
    const [lightLeakageSetting2, setLightLeakageSetting2] = React.useState<LightLeakageSettingModel>(fetchState.data);
    const [lightLeakageSetting3, setLightLeakageSetting3] = React.useState<LightLeakageSettingModel>(fetchState.data);
    const [lightLeakageSetting4, setLightLeakageSetting4] = React.useState<LightLeakageSettingModel>(fetchState.data);
    const [editData, setEditData] = React.useState<LightLeakageSettingModel>();
    const [fetchLoadingDevice0, setFetchLoadingDevice0] = React.useState(false);
    const [fetchLoadingDevice1, setFetchLoadingDevice1] = React.useState(false);
    const [fetchLoadingDevice2, setFetchLoadingDevice2] = React.useState(false);
    const [fetchLoadingDevice3, setFetchLoadingDevice3] = React.useState(false);
    const [fetchLoadingDevice4, setFetchLoadingDevice4] = React.useState(false);
    const [editFlg, setEditFlg] = React.useState(new Array(5).fill('').map(() => false));
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const [isRefreshSuccess0, setIsRefreshSuccess0] = React.useState(false);
    const [isRefreshSuccess1, setIsRefreshSuccess1] = React.useState(false);
    const [isRefreshSuccess2, setIsRefreshSuccess2] = React.useState(false);
    const [isRefreshSuccess3, setIsRefreshSuccess3] = React.useState(false);
    const [isRefreshSuccess4, setIsRefreshSuccess4] = React.useState(false);

    const deviceNos = ['00', '01', '02', '03', '04'];
    const deviceNames = ['本体', '子機1', '子機2', '子機3', '子機4'];

    // パラメータ設定
    const handleFetchParams = (deviceNo: string) => {
        const params = new Map<string, any>();
        const code = "PI";
        params.set("code", code);
        params.set("mainid", mainid);
        params.set("device_no", deviceNo);
        return params;
    }

    // 軽漏電設定取得成功時
    const handleFetchSuccess = (data: any, action: string, isClickedRefreshBtn: boolean) => {
        setEditFlg(changeDisabledPollingBtn(false, data.device_no, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        if (data) {
            const resData = {
                mainid: data.mainid,
                deviceNo: data.device_no,
                leakMa1: data.leak_ma1,
                leakMa2: data.leak_ma2,
                leakMa3: data.leak_ma3,
                leakMa4: data.leak_ma4,
                leakMa5: data.leak_ma5,
                leakMa6: data.leak_ma6,
                lastGetDatetime: data.last_get_datetime,
            } as LightLeakageSettingModel;

            switch (data.device_no) {
                case deviceNos[0]:
                    setLightLeakageSetting0(resData);
                    setFetchLoadingDevice0(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                    isClickedRefreshBtn && setIsRefreshSuccess0(true);
                    break;
                case deviceNos[1]:
                    setLightLeakageSetting1(resData);
                    setFetchLoadingDevice1(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                    isClickedRefreshBtn && setIsRefreshSuccess1(true);
                    break;
                case deviceNos[2]:
                    setLightLeakageSetting2(resData);
                    setFetchLoadingDevice2(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                    isClickedRefreshBtn && setIsRefreshSuccess2(true);
                    break;
                case deviceNos[3]:
                    setLightLeakageSetting3(resData);
                    setFetchLoadingDevice3(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                    isClickedRefreshBtn && setIsRefreshSuccess3(true);
                    break;
                case deviceNos[4]:
                    setLightLeakageSetting4(resData);
                    setFetchLoadingDevice4(false);
                    (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                    isClickedRefreshBtn && setIsRefreshSuccess4(true);
                    setRefreshGroup(false, fetchId);
            }

            if (action === 'edit') {
                setEditData(resData);
                setOpenSelectDialog(true);
            }
        }
    };

    const handleError = (success: boolean, data: any, error: any, deviceNo: string) => {
        onError(success, data, error, deviceNames[Number(deviceNo)]);
        setEditFlg(changeDisabledPollingBtn(false, deviceNo, deviceNos, editFlg));
        (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) && (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false);
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[1]);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[2]);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[3]);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC || isRefreshGroup === true) && handleRefresh(deviceNos[4]);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                (isRefreshAllBegin === NO_OF_POLLING_TOPIC) && isRefreshAllCompleted(isRefreshAllBegin + 1);
                setRefreshGroup(false, fetchId);
                setIsRefreshSuccess4(false);
                break;
        }
    }

    const handleRefresh = (deviceNo: string) => {
        switch (deviceNo) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
    };

    const handleRefreshSuccess = (data: any, deviceNo: string) => {
        const deviceIndex = deviceNos.findIndex((element) => element === data.device_no);
        editFlg[deviceIndex] ? handleFetchSuccess(data, 'edit', true) : handleFetchSuccess(data, 'view', true);
    }

    const [updateDialogTitle, setUpdateDialogTitle] = React.useState('');
    const handleEdit = (deviceNo: string) => {
        setEditFlg(changeDisabledPollingBtn(true, deviceNo, deviceNos, editFlg));
        const params = handleFetchParams(deviceNo);
        onRefresh(params, fetchId + deviceNo);
        switch (deviceNo) {
            case deviceNos[0]:
                setUpdateDialogTitle('軽漏電設定/本体');
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setUpdateDialogTitle('軽漏電設定/子機1');
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setUpdateDialogTitle('軽漏電設定/子機2');
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setUpdateDialogTitle('軽漏電設定/子機3');
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setUpdateDialogTitle('軽漏電設定/子機4');
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateEquipmentOk = (request: any) => {
        setOpenSelectDialog(false);
        const code = "PJ";
        const requestData = {
            mainid: request.mainid,
            device_no: request.device_no,
            leak_ma1: request.leak_ma1,
            leak_ma2: request.leak_ma2,
            leak_ma3: request.leak_ma3,
            leak_ma4: request.leak_ma4,
            leak_ma5: request.leak_ma5,
            leak_ma6: request.leak_ma6,
        }
        onUpdate(code, mainid, requestData);
        setUpdateData(request);
        switch (request.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(true);
                setIsRefreshSuccess0(false);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(true);
                setIsRefreshSuccess1(false);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(true);
                setIsRefreshSuccess2(false);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(true);
                setIsRefreshSuccess3(false);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(true);
                setIsRefreshSuccess4(false);
                break;
        }
    };

    const handleUpdateEquipmentCancel = () => {
        setOpenSelectDialog(false);
    };

    const handleUpdateSuccess = (success: boolean, data: any, error: any) => {
        const result = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            resultCode: data.result_code,
        } as UpdateResult;
        if (result.resultCode === '0') {
            handleFetchSuccess(updateData, 'view', true);
            onUpdateSuccess(deviceNames[Number(result.deviceNo)]);
        } else {
            handleUpdateError(success, data, error);
        }
    }

    const handleUpdateError = (success: boolean, data: any, error: any) => {
        switch (updateData.device_no) {
            case deviceNos[0]:
                setFetchLoadingDevice0(false);
                setIsRefreshSuccess0(true);
                break;
            case deviceNos[1]:
                setFetchLoadingDevice1(false);
                setIsRefreshSuccess1(true);
                break;
            case deviceNos[2]:
                setFetchLoadingDevice2(false);
                setIsRefreshSuccess2(true);
                break;
            case deviceNos[3]:
                setFetchLoadingDevice3(false);
                setIsRefreshSuccess3(true);
                break;
            case deviceNos[4]:
                setFetchLoadingDevice4(false);
                setIsRefreshSuccess4(true);
                break;
        }
        onUpdateError(success, data, error, deviceNames[Number(updateData.device_no)]);
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh(deviceNos[0]);
        }
        if (isRefreshGroup === true) {
            handleRefresh(deviceNos[0]);
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setFetchLoadingDevice0(true);
            setFetchLoadingDevice1(true);
            setFetchLoadingDevice2(true);
            setFetchLoadingDevice3(true);
            setFetchLoadingDevice4(true);
            setIsRefreshSuccess0(false);
            setIsRefreshSuccess1(false);
            setIsRefreshSuccess2(false);
            setIsRefreshSuccess3(false);
            setIsRefreshSuccess4(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setIsFetchAll(true) : setIsFetchAll(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, loadingRefreshAll, isRefreshGroup]);

    const handleDisabledRefreshAll = () => {
        if (!fetchLoadingDevice0 && !fetchLoadingDevice1 && !fetchLoadingDevice2 && !fetchLoadingDevice3 && !fetchLoadingDevice4) {
            setDisabledRefreshGroupBtn(false, fetchId);
        } else {
            setDisabledRefreshGroupBtn(true, fetchId);
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevice0, fetchLoadingDevice1, fetchLoadingDevice2, fetchLoadingDevice3, fetchLoadingDevice4]);

    React.useEffect(() => {
        [...editFlg].find(item => item === true) === true ? setIsEdit(true) : setIsEdit(false);
        // eslint-disable-next-line
    }, [editFlg])

    React.useEffect(() => {
        defaultData && (
            defaultData.forEach(data => {
                handleFetchSuccess(data, 'view', false);
            })
        )
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            {/* device00 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess0 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('00')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice0 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('00')} disabled={isDisbleRefeshBtn || editFlg[0] || fetchLoadingDevice0 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[0] === true || fetchLoadingDevice0 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='軽漏電設定/本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice0 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting0?.leakMa1}{showUnit(lightLeakageSetting0?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting0?.leakMa2}{showUnit(lightLeakageSetting0?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting0?.leakMa3}{showUnit(lightLeakageSetting0?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting0?.leakMa4}{showUnit(lightLeakageSetting0?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting0?.leakMa5}{showUnit(lightLeakageSetting0?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting0?.leakMa6}{showUnit(lightLeakageSetting0?.leakMa6, "mA")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{lightLeakageSetting0?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device01 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess1 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <div>
                                <IconButton aria-label="edit" onClick={() => handleEdit('01')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice1 || isFetchAll}>
                                    <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                </IconButton>
                                <IconButton aria-label="refresh" onClick={() => handleRefresh('01')} disabled={isDisbleRefeshBtn || editFlg[1] || fetchLoadingDevice1 || isFetchAll}>
                                    <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[1] === true || fetchLoadingDevice1 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                                </IconButton>
                            </div>
                        </div>
                    }
                    title='軽漏電設定/子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice1 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting1?.leakMa1}{showUnit(lightLeakageSetting1?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting1?.leakMa2}{showUnit(lightLeakageSetting1?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting1?.leakMa3}{showUnit(lightLeakageSetting1?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting1?.leakMa4}{showUnit(lightLeakageSetting1?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting1?.leakMa5}{showUnit(lightLeakageSetting1?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting1?.leakMa6}{showUnit(lightLeakageSetting1?.leakMa6, "mA")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{lightLeakageSetting1?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device02 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess2 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('02')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice2 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('02')} disabled={isDisbleRefeshBtn || editFlg[2] || fetchLoadingDevice2 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[2] === true || fetchLoadingDevice2 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='軽漏電設定/子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice2 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting2?.leakMa1}{showUnit(lightLeakageSetting2?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting2?.leakMa2}{showUnit(lightLeakageSetting2?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting2?.leakMa3}{showUnit(lightLeakageSetting2?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting2?.leakMa4}{showUnit(lightLeakageSetting2?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting2?.leakMa5}{showUnit(lightLeakageSetting2?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting2?.leakMa6}{showUnit(lightLeakageSetting2?.leakMa6, "mA")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{lightLeakageSetting2?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device03 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess3 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('03')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice3 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('03')} disabled={isDisbleRefeshBtn || editFlg[3] || fetchLoadingDevice3 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[3] === true || fetchLoadingDevice3 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='軽漏電設定/子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice3 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting3?.leakMa1}{showUnit(lightLeakageSetting3?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting3?.leakMa2}{showUnit(lightLeakageSetting3?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting3?.leakMa3}{showUnit(lightLeakageSetting3?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting3?.leakMa4}{showUnit(lightLeakageSetting3?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting3?.leakMa5}{showUnit(lightLeakageSetting3?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting3?.leakMa6}{showUnit(lightLeakageSetting3?.leakMa6, "mA")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{lightLeakageSetting3?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >

            {/* device04 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess4 ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit('04')} disabled={isDisbleUpdateBtn || isEdit || fetchLoadingDevice4 || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh('04')} disabled={isDisbleRefeshBtn || editFlg[4] || fetchLoadingDevice4 || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[4] === true || fetchLoadingDevice4 || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='軽漏電設定/子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevice4 ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流2</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流3</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流4</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流5</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>漏電電流6</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流1</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting4?.leakMa1}{showUnit(lightLeakageSetting4?.leakMa1, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流2</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting4?.leakMa2}{showUnit(lightLeakageSetting4?.leakMa2, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流3</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting4?.leakMa3}{showUnit(lightLeakageSetting4?.leakMa3, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流4</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting4?.leakMa4}{showUnit(lightLeakageSetting4?.leakMa4, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流5</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting4?.leakMa5}{showUnit(lightLeakageSetting4?.leakMa5, "mA")}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>漏電電流6</td>
                                    <td style={pollingColStyle}>{lightLeakageSetting4?.leakMa6}{showUnit(lightLeakageSetting4?.leakMa6, "mA")}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{lightLeakageSetting4?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >
            {
                openSelectDialog ? (
                    <SelectLightLeakageSettingDialog
                        isOpen={true}
                        onOK={(data) => handleUpdateEquipmentOk(data)}
                        onCancel={handleUpdateEquipmentCancel}
                        data={editData}
                        dialogTitle={updateDialogTitle}
                    />
                ) : (
                    <div />
                )
            }
            <FetchHelper fetchId={fetchId + deviceNos[0]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[0]) : handleError(success, data, error, deviceNos[0])} />
            <FetchHelper fetchId={fetchId + deviceNos[1]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[1]) : handleError(success, data, error, deviceNos[1])} />
            <FetchHelper fetchId={fetchId + deviceNos[2]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[2]) : handleError(success, data, error, deviceNos[2])} />
            <FetchHelper fetchId={fetchId + deviceNos[3]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[3]) : handleError(success, data, error, deviceNos[3])} />
            <FetchHelper fetchId={fetchId + deviceNos[4]} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, deviceNos[4]) : handleError(success, data, error, deviceNos[4])} />
            <FetchHelper fetchId="UPDATE_LIGHT_LEAKAGE_SETTING" method="POST" onComplete={(success, data, error) => success ? handleUpdateSuccess(success, data, error) : handleUpdateError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onUpdate: (code: string, mainid: string, data: any) => actionCreators.fetch("UPDATE_LIGHT_LEAKAGE_SETTING", `/polling/set?code=${code}`, 'POST', data, false, true),
    onRefresh: (params: any, fetchId: string) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `軽漏電設定/${deviceName}取得`,
            body: "軽漏電設定情報取得できませんでした。",
        }),
    onUpdateSuccess: (deviceName: string) =>
        actionCreators.showMessage({
            type: "info",
            title: `軽漏電設定/${deviceName}変更`,
            body: "軽漏電設定を変更しました。",
        }),
    onUpdateError: (success: boolean, data: any, error: any, deviceName: string) =>
        actionCreators.showMessage({
            type: "error",
            title: `軽漏電設定/${deviceName}変更`,
            body: "軽漏電設定を変更できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LightLeakageSetting);

