import WbCloudyOutlinedIcon from '@mui/icons-material/WbCloudyOutlined';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { ReactComponent as UmbrellaIcon } from "../../../assets/umbrella.svg";
import { ReactComponent as CloudIcon } from "../../../assets/cloud.svg";
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import ChartPanel, { ChartResult } from '../../chart/ChartPanel';

export type WeathersDayContentProps = {
    data:any,
    temperatureList:any,
};
export const WeathersDayContent = (props: WeathersDayContentProps) => {
    const {data,temperatureList} = props;
    const labels = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
    const [chartModel,setChartModel] = React.useState<ChartResult>({chartId:4,dataChart:temperatureList,labels: labels.map((item) => item + "時")}as ChartResult);
    const getBgColor = (logic:boolean) =>{
        if(logic === true){
            return "#FFFFEF";
        }
        return "white";
    }
    useEffect(()=>{
        const dataTemperature:any = []
        if(data && data.hours_data ){
            data.hours_data.forEach((item:any) => {
                if(item.temperature!= "-")
                    dataTemperature.push(item.temperature);
                else
                    dataTemperature.push(0);

            })
        }
    },[data])
    return (
        <>
            {/* 天気 */}
            <tr>
                <th rowSpan={2}  className={'sticky-col first-col'} style={{backgroundColor:"white",height:"70px"}}>天気</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",backgroundColor:getBgColor(item.source_type == 0)}}>{item.condition}</td>
                )}   
            </tr>
            <tr style={{height:"50px"}}>
                {data.hours_data &&data.hours_data.map((item:any,i:any) =>
                    <td colSpan={1} style={{ textAlign:"center",height:"30px",backgroundColor:getBgColor(item.source_type == 0),borderLeft:i==0?"none":""}}>
                        <>
                        {(item.condition == "晴れ" || item.condition == "快晴") && <WbSunnyIcon color="warning"  /> }
                        {( item.condition == "雲り") && <CloudIcon /> }
                        {( item.condition == "雨") && <UmbrellaIcon />}
                        {( item.condition == "雪") && <AcUnitIcon /> }
                        {(item.condition == "-") && <>-</>}
                        </>
                    </td>
                )}
            </tr>
            {/* 気温(°C)*/}
            <tr>
                <th rowSpan={2} className={'sticky-col first-col'} style={{backgroundColor:"white",height:"100px"}}>気温(°C)</th>
                <td colSpan={24} style={{height:"80px"}}>
                    <Grid container direction={"row"}>
                        <Grid item xs={12}>
                            <ChartPanel chartModel={chartModel} />
                        </Grid>
                        
                    </Grid>                    
                </td>
            </tr>
            <tr>
                {data.hours_data &&data.hours_data.map((item:any, i:any) =>
                     <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0),borderLeft:i==0?"none":""}}>{item.temperature}</td>
                )} 
            </tr>
            {/* 日射量(W/m²) */}
            <tr>
                <th rowSpan={1}  className={'sticky-col first-col'} style={{backgroundColor:"white"}}>日射量(W/m²)</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0)}}>{item.solar_radiation}</td>   
                )}   
            </tr>
            {/* 日照割合(%) */}
            <tr>
                <th rowSpan={1}  className={'sticky-col first-col'} style={{backgroundColor:"white"}}>日照割合(%)</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0)}}>{item.solar_ratio}</td>
                )}   
            </tr>
            {/* 降水量(mm/h) */}
            <tr>
                <th rowSpan={1}  className={'sticky-col first-col'} style={{backgroundColor:"white"}} >降水量(mm/h)</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0)}}>{item.precipitation}</td>
                )}   
            </tr>
            {/* 湿度(%) */}
            <tr>
                <th rowSpan={1}  className={'sticky-col first-col'} style={{backgroundColor:"white"}}>湿度(%)</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0)}}>{item.humidity}</td>
                )}   
            </tr>
            {/* 風速(m/s) */}
            <tr>
                <th rowSpan={1}  className={'sticky-col first-col'} style={{backgroundColor:"white"}}>風速(m/s)</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0)}}>{item.wind_speed}</td>
                )}   
            </tr>
            {/* 気圧(hPa) */}
            <tr>
                <th rowSpan={1}  className={'sticky-col first-col'} style={{backgroundColor:"white"}}>気圧(hPa)</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0)}}>{item.pressure_sealevel}</td>
                )}   
            </tr>
            {/* 降雪量(cm/h) */}
            <tr>
                <th rowSpan={1}  className={'sticky-col first-col'} style={{backgroundColor:"white"}}>降雪量(cm/h)</th>
                {data.hours_data &&data.hours_data.map((item:any) =>
                        <td colSpan={1} style={{ textAlign:"center",height:"42px",backgroundColor:getBgColor(item.source_type == 0)}}>{item.snowfall}</td>
                )}   
            </tr>
        </>
    )
}
export default WeathersDayContent;
