import CachedIcon from '@mui/icons-material/Cached';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Path as RoutePath } from "../../../path";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import { formatNumber, formatDecimal, formatRoundDownDecimal } from "../../../utils";
import ChartPanel, { ChartResult } from "../../chart/ChartPanel";
import { DailyChartLineData } from "../../chart/DailyChart";
import Calendar1 from '../../common/calendar1';
import Calendar2 from '../../common/calendar2';
import FetchHelper from "../../FetchHelper";
import './styles.css';
import DeviceInformation from '../../polling/device/DeviceInformation';
import { useCSVDownloader } from 'react-papaparse';
import BackButton from '../../common/BackButton';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

// -------------
// FETCH ID
const fetchId = "GAEX2_LIST";
const formId = "GAEX2_SEARCH_FORM";
const getDevice = "GAEX2_GET_DEVICE";
const menusFetchId = 'MENUS_FETCH';

// -------------
// Props
export type Gaex2ListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const Gaex2List = (props: Gaex2ListProps) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const mainid = urlParams.get('mainid');
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { CSVDownloader, Type } = useCSVDownloader();
    const [device, setDevice] = React.useState<any>();
    const [pulseType, setPulseType] = React.useState("");
    const { onGetAuthRole, onError, onSearch, onErrorSearch, onGetDevice,fetchStateMenu,fetchState, backData } = props;
    const [unitFlag, setUnitFlag] = React.useState<number>(1);
    const [modeFlag, setModeFlag] = React.useState(true);
    const [gaex2List, setGaex2List] = React.useState<any[]>([]);
    const [dateValue, setDateValue] = React.useState(backData ? dayjs(backData).toDate() : new Date());
    const [dateSelect, setDateSelect] = React.useState(backData ? dayjs(backData).toDate() : new Date());
    const [chartModel, setChartModel] = React.useState<ChartResult>();
    const [chartLineModel, setChartLineModel] = React.useState<ChartResult>();
    const [moneyChartModel, setMoneyChartModel] = React.useState<ChartResult>();
    const [month_kwh, setMonth_kwh] = React.useState("0");
    const [month_amount,setMonth_amount] = React.useState("0");
    const [jpy_per_kwh,setJpy_per_kwh] = React.useState("0");
    const [dateTimeMax, setDateTimeMax] = React.useState("");
    const [dataList, setDataList] = React.useState<any>();
    const [totalKwhMaxByMonth,setTotalKwhMaxByMonth] = React.useState<any>();
    const [totalMoneyMaxByMonth,setTotalMoneyMaxByMonth] = React.useState<any>();
    const [kwhMaxByMonth,setKwhMaxByMonth] = React.useState<any>();
    const [moneyMaxByMonth,setMoneyMaxByMonth] = React.useState<any>();
    const [dayFinalMaxKwh,setDayFinalMaxKwh] = React.useState<any>();
    const [hourFinalMaxKwh,setHourFinalMaxKwh] = React.useState<any>();
    const [dayFinalMaxKw,setDayFinalMaxKw] = React.useState<any>();
    const [hourFinalMaxKw,setHourFinalMaxKw] = React.useState<any>();
    const [fileNameCSV,setFileNameCSV] = React.useState<any>(pulseType === "1" ? "デマンド定期通報":"太陽光定期通報");
    const dayJapan = ["日", "月", "火", "水", "木", "金", "土"];
    const [loadingDeviceFlag,setLoadingDeviceFlag] = React.useState(false);
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [authRole, setAuthRole] = React.useState<any>();
    const [isError,setError] = React.useState<boolean>(false);

    const getValueData = (data:any) => {
        if(data != null && data != undefined)
            return data;
        return "-";
    }

    React.useEffect(()=> {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    },[])

    React.useEffect(() => {
        if(dateSelect !== null){
            setError(false)
        }
    },[dateSelect])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    const handleExportCSV = () => {
        let result:any = [];
        let headerName = ["月合計","月"];
        // var calendar = (new Array(31)).fill('').map((v, i) => new Date(dateValue.getFullYear(), dateValue.getMonth(), i + 1)).filter(v => v.getMonth() === dateValue.getMonth())
        switch(unitFlag){
            case 1:
                result = [];
                headerName = ["日合計","日","曜日"];
                [...Array(24)].forEach((item:any,index:any)=> {
                    if(index < 10){
                        headerName.push(`0${index}:00`);
                    }else{
                        headerName.push(`${index}:00`);
                    }
                })
                result.push(headerName);
                gaex2List.forEach((item:any)=> {
                    const dayData = [];
                    dayData.push(getValueData(item.day_kwh));
                    dayData.push(getValueData(item.date));
                    dayData.push(getValueData(item.day));
                    [...Array(24)].forEach((item:any,index:any)=> {
                        dayData.push("-");
                    })
                    if(item.data.length > 0){
                        item.data.forEach((item:any)=>{
                            dayData[Number(item.hour)+3] = getValueData(item.kwh);
                        })
                    }
                    result.push(dayData);
                });
                break;
            case 2:
                result = [];
                headerName = ["日最大","日","曜日"];
                [...Array(24)].forEach((item:any,index:any)=> {
                    if(index < 10){
                        headerName.push(`0${index}:00`);
                        headerName.push(`0${index}:30`);
                    }else{
                        headerName.push(`${index}:00`);
                        headerName.push(`${index}:30`);
                    }
                })
                result.push(headerName);
                gaex2List.forEach((item:any)=> {
                    const dayData = [];
                    dayData.push(getValueData(item.day_kwh));
                    dayData.push(getValueData(item.date));
                    dayData.push(getValueData(item.day));
                    [...Array(48)].forEach((item:any,index:any)=>{
                        dayData.push("-")
                    })
                    if(item.data.length > 0){
                        item.data.forEach((item:any)=>{
                            dayData[Number(item.hour)*2+3] = getValueData(item.kw0);
                            dayData[Number(item.hour)*2+4] = getValueData(item.kw30);
                        })
                    }
                    result.push(dayData);
                });
                break;
            case 3:
                result = [];
                headerName = ["日合計","日","曜日"];
                [...Array(24)].forEach((item:any,index:any)=> {
                    if(index < 10){
                        headerName.push(`0${index}:00`);
                    }else{
                        headerName.push(`${index}:00`);
                    }
                })
                result.push(headerName);
                gaex2List.forEach((item:any)=> {
                    const dayData = [];
                    dayData.push(getValueData(item.day_amount));
                    dayData.push(getValueData(item.date));
                    dayData.push(getValueData(item.day));
                    [...Array(24)].forEach((item:any,index:any)=> {
                        dayData.push("-");
                    })
                    if(item.data.length > 0){
                        item.data.forEach((item:any)=>{
                            dayData[Number(item.hour)+3] = getValueData(item.amount);
                        })
                    }
                    result.push(dayData);
                });
                break;
        }
        return result;
    }

    useEffect(() => {
        const date = new Date();
        let name = date.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
        if(pulseType === "1"){
            name += "_デマンド-月表"; 
        }else if(pulseType === "2"){
            name += "_太陽光-月表";
        }
        switch(unitFlag){
            case 1:
                name += "(kWh)";
                break;
            case 2:
                name += "(kW)";
                break;
            case 3:
                name += "(金額)";
                break;
        }
        if(device){
            name += `_${dateValue.getFullYear()}年${dateValue.getMonth()+1}月_${device.customer_name}_${device.id}`
        }
        setFileNameCSV(name);
    },[unitFlag,device])

    const handleFetchSuccess = (data: any) => {
        setDataList(data);
        handleData(data, unitFlag, modeFlag);
        setLoadingFlag(false);
    };

    const handleGetDevice = (data: any) => {
        setPulseType(data.pulse_type);
        setDevice(data);
        setLoadingDeviceFlag(false);
    };

    const handleData = (data: any, unitFlag: number, modeFlag: boolean) => {
        setDateTimeMax("");
        setMonth_kwh("0");
        setJpy_per_kwh("0");
        setMonth_amount("0");
        var calendar = (new Array(31)).fill('').map((v, i) => new Date(dateValue.getFullYear(), dateValue.getMonth(), i + 1)).filter(v => v.getMonth() === dateValue.getMonth())
        let newData: any[] = [];
        if(data.month_amount)
            setMonth_amount(formatNumber(data.month_amount).toString());
        if(data.jpy_per_kwh)
            setJpy_per_kwh(data.jpy_per_kwh.toString());
        if (data.days_data === undefined) {
            calendar.forEach(item => {
                var day = dayJapan[item.getDay()];
                setMonth_kwh("0");
                newData.push({
                    day: day,
                    date: item.getDate().toString(),
                    month_kwh: 0,
                    day_kwh: "-",
                    data: [],
                    alarm1_kw: 0,
                    alarm2_kw: 0,
                    day_amount:"-"
                })
            });
            setGaex2List(newData);
            onCreateChart(newData);
            onErrorSearch();
        }
        else {
            if (modeFlag) {
                if (data.days_data.length > 0) {
                    switch(unitFlag){
                        case 1:
                            let totalKwhMaxByMonth = 0;
                            let kwhMaxByMonth = 0;
                            calendar.forEach(item => {
                                var day = dayJapan[item.getDay()];
                                var list = data.days_data.filter((x: any) => x.day === item.getDate());
                                if(list.length > 0){
                                    if(totalKwhMaxByMonth < list[0].day_kwh){
                                        totalKwhMaxByMonth = list[0].day_kwh
                                    }
                                    const day_data_max = list[0].hours_data.reduce((a:any,b:any)=>a.kwh>b.kwh?a:b); 
                                    if(kwhMaxByMonth <= day_data_max.kwh ){
                                        kwhMaxByMonth = day_data_max.kwh;
                                        setDayFinalMaxKwh(item.getDate())
                                        setHourFinalMaxKwh(day_data_max.hour);
                                    }
                                }
                                setMonth_kwh(data.month_kwh.toString());
                                newData.push({
                                    day: day,
                                    date: item.getDate().toString(),
                                    month_kwh: data.month_kwh,
                                    day_kwh: list.length > 0 ? list[0].day_kwh : "-",
                                    data: list.length > 0 ? list[0].hours_data : [],
                                    alarm1_kw: data.alarm1_kw,
                                    alarm2_kw: data.alarm2_kw
                                })
                            });
                            setKwhMaxByMonth(kwhMaxByMonth);
                            setTotalKwhMaxByMonth(totalKwhMaxByMonth);
                            break;
                        case 2:
                            var day_kwh_temp = 0;
                            calendar.forEach(item => {
                            var day = dayJapan[item.getDay()];
                            var list = data.days_data.filter((x: any) => x.day === item.getDate());
                            var strTime = "";
                            var day_kwh = "-";
                            var day_kwhMax = 0;
                            if (list.length > 0) {
                                var itemMax = list[0].hours_data.reduce((prev: any, current: any) => (Math.max(prev.kw0, prev.kw30) > Math.max(current.kw0, current.kw30)) ? prev : current);
                                day_kwhMax = Number(itemMax.kw0);
                                strTime = itemMax.hour.toString().padStart(2, "0") + "h" + "00";
                                if (itemMax.kw30 >= itemMax.kw0) {
                                    day_kwhMax = Number(itemMax.kw30);
                                    strTime = itemMax.hour.toString().padStart(2, "0") + "h" + "30";
                                }

                                day_kwh = day_kwhMax.toString()
                            }
                            if (day_kwh_temp <= Number(day_kwhMax)) {
                                day_kwh_temp = Number(day_kwhMax);
                                setDayFinalMaxKw(item.getDate());
                                setHourFinalMaxKw(strTime);
                                setDateTimeMax(item.getDate() + "日" + strTime);
                                setMonth_kwh(day_kwh_temp.toString());
                            }
                            newData.push({
                                day: day,
                                date: item.getDate().toString(),
                                month_kwh: data.month_kwh,
                                day_kwh: day_kwh,
                                data: list.length > 0 ? list[0].hours_data : [],
                                alarm1_kw: data.alarm1_kw,
                                alarm2_kw: data.alarm2_kw
                            })
                            });
                            break;
                        case 3:   
                            let totalMoneyDayMaxByMonth = 0;
                            let moneyDayMaxByMonth = 0;
                            const jpy_per_kwh = data.jpy_per_kwh;
                            calendar.forEach(item => {
                                var day = dayJapan[item.getDay()];
                                var list = data.days_data.filter((x: any) => x.day === item.getDate());
                                
                                if(list.length > 0){
                                    if(totalMoneyDayMaxByMonth < list[0].day_amount){
                                        totalMoneyDayMaxByMonth = list[0].day_amount;
                                    }
                                    const day_data_max = list[0].hours_data.reduce((a:any,b:any)=> a.kwh > b.kwh ? a:b); 
                                    if(moneyDayMaxByMonth <= day_data_max.kwh*jpy_per_kwh){
                                        moneyDayMaxByMonth = day_data_max.kwh*jpy_per_kwh;
                                        setDayFinalMaxKwh(item.getDate())
                                        setHourFinalMaxKwh(day_data_max.hour);
                                    }
                                }
                                setMonth_kwh(data.month_kwh ? data.month_kwh.toString() : "0");
                                newData.push({
                                    day: day,
                                    date: item.getDate().toString(),
                                    day_kwh: list.length > 0 ? list[0].day_kwh : "-",
                                    month_kwh: data.month_kwh,
                                    day_amount: list.length > 0 ? list[0].day_amount : "-",
                                    data: list.length > 0 ? list[0].hours_data : [],
                                    alarm1_kw: data.alarm1_kw,
                                    alarm2_kw: data.alarm2_kw
                                })
                            });
                            setMoneyMaxByMonth(moneyDayMaxByMonth);
                            setTotalMoneyMaxByMonth(totalMoneyDayMaxByMonth);
                            break;
                    }
                } else {
                    calendar.forEach(item => {
                        var day = dayJapan[item.getDay()];
                        setMonth_kwh("0");
                        newData.push({
                            day: day,
                            date: item.getDate().toString(),
                            month_kwh: 0,
                            day_kwh: "-",
                            data: [],
                            alarm1_kw: 0,
                            alarm2_kw: 0,
                            day_amount:"-"
                        })
                    });
                }
                setGaex2List(newData);
            }
            else {
                var day_kwh_temp = 0;
                calendar.forEach(item => {
                    var day = dayJapan[item.getDay()];
                    var list = data.days_data.filter((x: any) => x.day === item.getDate());
                    var day_kwh = 0;
                    var day_amount = 0;
                    switch(unitFlag){
                        case 1:
                            setMonth_kwh(data.month_kwh ? data.month_kwh.toString() : "0");
                            if (list.length > 0) {
                                day_kwh = list[0].day_kwh
                            }
                            break;
                        case 2:
                            var strTime = "";
                            var day_kwhMax = 0;
                            if (list.length > 0) {
                                var itemMax = list[0].hours_data.reduce((prev: any, current: any) => (Math.max(prev.kw0, prev.kw30) > Math.max(current.kw0, current.kw30)) ? prev : current);
                                day_kwhMax = Number(itemMax.kw0);
                                strTime = itemMax.hour.toString().padStart(2, "0") + "h" + "00";
                                if (itemMax.kw30 >= itemMax.kw0) {
                                    day_kwhMax = Number(itemMax.kw30);
                                    strTime = itemMax.hour.toString().padStart(2, "0") + "h" + "30";
                                }

                                day_kwh = day_kwhMax;
                            }
                            if ((day_kwh_temp <= Number(day_kwhMax) && strTime !== "")) {
                                day_kwh_temp = Number(day_kwhMax);
                                setDayFinalMaxKw(item.getDate());
                                setHourFinalMaxKw(strTime);
                                setDateTimeMax(item.getDate() + "日" + strTime);
                                setMonth_kwh(formatNumber(day_kwh_temp.toString()).toString());
                            }
                            break;
                        case 3:
                            if (list.length > 0) {
                                day_amount =list[0].day_amount
                            }
                            break;
                    }
                    newData.push({
                        day: day,
                        date: item.getDate().toString(),
                        month_kwh: data.month_kwh,
                        day_kwh: day_kwh,
                        data: list.length > 0 ? list[0].hours_data : [],
                        alarm1_kw: data.alarm1_kw,
                        alarm2_kw: data.alarm2_kw,
                        day_amount:day_amount
                    })
                });
                setGaex2List(newData);
                onCreateChart(newData);
            }
        }
    }

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingDeviceFlag(false);
    }

    const CreateDataChartLine = (data: any) => {
        return {
            dataChart1: data.map((item: any) => formatRoundDownDecimal(item.day_kwh.toString(),0,true)),
            dataChart2: data.map((item: any) => item.alarm2_kw === 0 ? null : item.alarm2_kw),
            dataChart3: data.map((item: any) => item.alarm1_kw === 0 ? null : item.alarm1_kw),
            pulse_type: pulseType,
        } as DailyChartLineData;
    };

    const reCreateChartData = (data: any) => {
        return {
            chartId: 2,
            labels: data.map((item: any) => item.date + '日(' + item.day + ')'),
            dataChart: data.map((item: any) => formatRoundDownDecimal(item.day_kwh.toString(),0,true)),
            unit: "kWh"
        } as ChartResult;
    };
    const reCreateDataChartLine = (data: any) => {
        return {
            chartId: 3,
            labels: data.map((item: any) => item.date + '日(' + item.day + ')'),
            dataChart: CreateDataChartLine(data),
        } as ChartResult;
    };
    const reCreateMoneyChartData = (data:any)=>{
        return {
            chartId: 2,
            labels: data.map((item: any) => item.date + '日(' + item.day + ')'),
            dataChart: data.map((item: any) => item.day_amount),
            unit: "円"
        } as ChartResult;
    }
    const onCreateChart = (data: any) => {
        const chartData = reCreateChartData(data);
        const moneyChartData= reCreateMoneyChartData(data);
        const chartLineData = reCreateDataChartLine(data);
        setChartModel(chartData);
        setMoneyChartModel(moneyChartData);
        setChartLineModel(chartLineData);
    }

    const createData = (value: Date) => {
        const params = new Map<string, any>();
        params.set("code", "GAEX2");
        params.set("mainid", mainid);
        params.set("year", value.getFullYear());
        params.set("month", (value.getMonth() + 1).toString().padStart(2, "0"));
        return params;
    };

    const handleErrorSearch = () => {
        setLoadingFlag(false);
        handleData([], unitFlag, modeFlag);
    }

    // 検索実行
    const handleSearchSubmit = (params: any) => {
        let isError = false;

        if (isEmpty(mainid)) isError = true;

        onGetDevice(mainid)
        setLoadingDeviceFlag(true);

        if (!isError) {
            onSearch(params);
        } else {
            handleErrorSearch();
        }
    }

    const isEmpty = (value: any) => {
        return (value === null || value?.length <= 0)
    }

    React.useEffect(() => {
        var params = createData(dateValue);
        if(backData){
            dispatch({type:"SET_BACK_DATA",payload:{data:null}})
        }
        handleSearchSubmit(params);
        // eslint-disable-next-line
    }, []);

    //タブ表示ボタンスタイル
    const buttonStyle1 = {
        backgroundColor:  unitFlag === 1  ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '9px 0 0 9px',
    }

    const buttonStyle2 = {
        backgroundColor:  unitFlag === 2  ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: pulseType ==="2" ? '0px 0px 0px 0px': '0px 9px 9px 0px',
    }

    const buttonStyle3 = {
        backgroundColor: unitFlag === 3 ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '0px 9px 9px 0px',
    }

    const buttonStyle4 = {
        backgroundColor: modeFlag ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '9px 0 0 9px',
        
    }

    const buttonStyle5 = {
        backgroundColor: modeFlag ? '#e6b800' : '#997a00',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '0 9px 9px 0',
    }
    
    // キャンセル
    const handleCancel = () => {
        let path = sessionStorage.getItem("references");
        if(!path){
            if(authRole.auth_role_map["LM-telemetry-view"])
            {
                if(pulseType == "1" && (authRole.code_restriction_array?.length == 0 || authRole.code_restriction_array?.includes("GADEMAND"))){
                    let newPath = "/telemetry/ga";
                    let newUrlPath = (urlParams.get('path'))
                    if(newUrlPath){
                        newUrlPath = (urlParams.get('path') ?? "root");
                        newPath +='?path=' + newUrlPath;
                        newPath += id ? '&id=' + id : "";
                        if (newUrlPath=="customer") {
                            newPath += '&customer_id=' + (urlParams.get('customer_id') ?? '');
                        }
                    }else{
                        newPath += "?path=root";
                    }
                    if(newPath){
                        navigate(newPath, { replace: true });
                        return;
                    }
                }
                if(pulseType == "2" && (authRole.code_restriction_array?.length == 0 || authRole.code_restriction_array?.includes("GASOLAR"))){
                    let newPath = "/telemetry/ga2";
                    let newUrlPath = (urlParams.get('path'))
                    if(newUrlPath){
                        newUrlPath = (urlParams.get('path') ?? "root");
                        newPath +='?path=' + newUrlPath;
                        newPath += id ? '&id=' + id : "";
                        if (newUrlPath=="customer") {
                            newPath += '&customer_id=' + (urlParams.get('customer_id') ?? '');
                        }
                    }else{
                        newPath += "?path=root";
                    }
                    if(newPath){
                        navigate(newPath, { replace: true });
                        return;
                    }
                }
            }
            if(authRole.auth_role_map["LM-smonitor-view"]){
                if(pulseType == "1" && authRole.code_restriction_array?.includes("GADEMAND")){
                    navigate(RoutePath.SmartDemandList);
                    return;
                }
                if(pulseType == "2" && authRole.code_restriction_array?.includes("GASOLAR")){
                    navigate(RoutePath.SmartSolarList);
                    return;
                }
            }
            navigate("/");
            return;
        }
        sessionStorage.removeItem('references');
        let urlPath = (urlParams.get('path'))
        if(urlPath){
            urlPath = (urlParams.get('path') ?? "root");
            path +='?path=' + urlPath;
            path += id ? '&id=' + id : "";
            if (urlPath=="customer") {
                path += '&customer_id=' + (urlParams.get('customer_id') ?? '');
            }
        }else{
            path += id ? '?id=' + id : "";
        }
        if(path)
                navigate(path, { replace: true });
    }

    const handleChangeUnit = (flag: number) => {
        if (unitFlag !== flag) {
            setUnitFlag(flag);
            handleData(dataList, flag, modeFlag);
        }
    }

    const handleChangeMode = (flag: boolean) => {
        if (modeFlag !== flag) {
            setModeFlag(flag);
            handleData(dataList, unitFlag, flag);
        }
    }

    // キャンセル
    const onLeft = () => {
        var tempDate = new Date(dateValue.getFullYear(), dateValue.getMonth() + 1);
        setDateValue(tempDate);
        setDateSelect(tempDate);
        setLoadingFlag(true);
        handleSearchSubmit(createData(tempDate));
    }

    // キャンセル
    const onRight = () => {
        var tempDate = new Date(dateValue.getFullYear(), dateValue.getMonth());
        tempDate.setMonth(tempDate.getMonth() - 1)
        setDateValue(tempDate);
        setDateSelect(tempDate);
        setLoadingFlag(true);
        handleSearchSubmit(createData(tempDate));
    }

    const handleRefresh = () => {
        if (!isNaN(dateSelect.getTime())) {
            setLoadingFlag(true);
            setDateValue(dateSelect);
            handleSearchSubmit(createData(dateSelect));
        }
    }

    const handleGetLatestData = () => {
        setLoadingFlag(true);
        var date = new Date();
        setDateSelect(date);
        setDateValue(date);
        handleSearchSubmit(createData(date));
    }

    const handleNavigateImport = () => {
        if (!isNaN(dateSelect.getTime())) {
            const isRoot = urlParams.get("path") ? true : false
            navigate(RoutePath.MonthlyPowerUpdate,{state:{
                mainid:mainid,
                date:dateSelect,
                isRoot: isRoot,
            }})
        }
    }

    return (
        <>
            {(loadingFlag || loadingDeviceFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <DeviceInformation titleFlag={true} deviceInformation={device} mainid={mainid ?? ''} title="定期通報"   isDisplayDeviceInfo={true} isShowMenuButton ={false} ></DeviceInformation>
            <Stack direction="row">
                <div style={{ width: '100%', display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                    <Typography variant="body1" style={{ color: "gray" }} mr={2}>単位</Typography>
                    <Button style={{ textTransform: 'none' }} sx={buttonStyle1} variant="text" onClick={e => handleChangeUnit(1)}>kWh</Button>
                    <Button style={{ textTransform: 'none' }} sx={buttonStyle2} variant="text" onClick={e => handleChangeUnit(2)}>kW</Button>
                    {
                    pulseType === "2" && 
                    <Button style={{textTransform:'none' }}  sx={buttonStyle3}  variant="text" onClick= {(e)=> handleChangeUnit(3)} >金額</Button>
                    }
                    <Typography variant="body1" style={{ color: "gray" }} ml={2} mr={2}>種類</Typography>
                    <Button style={{ textTransform: 'none'}} sx={buttonStyle4} variant="text" onClick={e => handleChangeMode(true)}>表</Button>
                    <Button style={{ textTransform: 'none' }} sx={buttonStyle5} variant="text" onClick={e => handleChangeMode(false)}>グラフ（日毎）</Button>
                    <div style={{ marginInlineStart: 'auto', pointerEvents: fetchState.loading ? "none" : "auto" }}>
                        {
                            authRole && modeFlag && unitFlag === 2 && authRole.auth_role_map["TM-gaedit-all"] && 
                            <Button variant="outlined" size="small" style={{ margin: 'auto 10px auto 0',padding:"7px" }} onClick={handleNavigateImport}>電力更新</Button>
                        }
                        {modeFlag && 
                            <CSVDownloader
                            type={Type.Link}
                            filename={fileNameCSV}
                            bom={true}
                            data={handleExportCSV}
                            >
                                <Button variant="outlined" size="small" style={{ margin: 'auto 10px auto 0',padding:"7px" }}>CSV</Button>
                            </CSVDownloader>
                        }
                        <Button variant="outlined" size="small" style={{ height: '40px', borderRadius: '9px 0 0 9px' }} onClick={onRight} >{<ChevronLeftIcon />}前月</Button>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY.MM"
                                views={["year", "month"]}
                                openTo="month"
                                renderInput={(props: any) => (
                                    <TextField
                                        size="small"
                                        style={{ width: '125px', marginLeft: '-1px' }}
                                        error={!!isError}
                                        sx={{
                                            fieldset: {
                                                border: "1px solid rgba(24, 144, 255, 0.5)",
                                                borderWidth: "1px 1px 1px 1px",
                                                zIndex: "1",
                                                borderRadius: "unset"
                                            }
                                        }}
                                        {...props} />
                                )}
                                value={dateSelect}
                                onChange={(newValue: any) => {
                                    if(newValue !== null){
                                        setDateSelect(newValue.$d);
                                    }else{
                                        setError(true);
                                    }
                                }}                            
                                disableMaskedInput={false}
                            />
                        </LocalizationProvider>
                        <Button variant="outlined" size="small" style={{ height: '40px', borderRadius: '0px 0px', marginLeft: '-1px' }} onClick={onLeft}>翌月{<ChevronRightIcon />}</Button>
                        <Button variant="outlined" size="small" style={{ height: '40px', borderRadius: '0px 9px 9px 0px', borderWidth: "1px 1px 1px 1px", marginLeft: '-1px' }} onClick={handleGetLatestData}>最新</Button>
                        <IconButton aria-label="edit" >
                            <CachedIcon fontSize="medium" style={{ color: '#1890ff' }} onClick={handleRefresh} />
                        </IconButton>
                        <IconButton>
                            <div>
                            {
                                pulseType &&
                                <BackButton isYearlyReport={false} pulseType={pulseType} title='' isShowMenuButton={true} titleFlag={false} mainid={mainid ?? ""}/>
                            }
                            </div>
                        </IconButton>
                    </div>
                </div>

            </Stack>
            {!loadingFlag && <>
                {
                    unitFlag === 1 &&
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                        <Typography variant="h2" mr={4} style={{ fontWeight: "normal" }}>{dateValue.getFullYear()}年{dateValue.getMonth() + 1}月</Typography>
                        <Typography variant="h4" mr={1} style={{ color: "gray", fontWeight: "normal" }}>{pulseType === "1" ? "電力量 月合計" : "発電量 月合計"}</Typography>
                        <Typography variant="h3" style={{ color: "red" }}>{ formatRoundDownDecimal((month_kwh.toString()),0)} kWh</Typography>
                    </div>
                }
                {
                    unitFlag === 2 &&
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                        <Typography variant="h2" mr={4} style={{ fontWeight: "normal" }}>{dateValue.getFullYear()}年{dateValue.getMonth() + 1}月</Typography>
                        <Typography variant="h4" mr={1} style={{ color: "gray", fontWeight: "normal" }}>{pulseType === "1" ? "最大デマンド値" : "最大発電量"}</Typography>
                        <Typography variant="h3" style={{ color: "red" }}>{formatRoundDownDecimal(month_kwh.toString(),0)} kW</Typography>
                        <Typography variant="h4" style={{ color: "red" }}>{dateTimeMax !== "" ? "(" + dateTimeMax + ")" : ""}</Typography>
                    </div>
                }
                {   
                    pulseType === "2" && unitFlag === 3 &&
                    <div style={{ display: "flex", alignItems: "baseline"}}>
                        <Typography variant="h2" mr={4} style={{ fontWeight: "normal" }}>{dateValue.getFullYear()}年{dateValue.getMonth() + 1}月</Typography>
                        <Typography variant="h4" mr={1} style={{ color: "gray", fontWeight: "normal" }}>発電金額 月合計</Typography>
                        <Typography variant="h3" mr={3} style={{ color: "red" }}>{formatNumber(month_amount.toString())} 円 </Typography>
                        <Typography variant="h6" mr={1} style={{ color: "gray",fontWeight: "normal" }}> 単価 </Typography>
                        <Typography variant="h4" style={{ color: "red" }}> {formatDecimal(jpy_per_kwh.toString(),1)} 円/kWh</Typography>
                    </div>
                }
                <div className={'TableStyle'} style={{ height: 'calc(100vh - 315px)', width: '100%', overflowY: 'auto' }}>
                    {
                        modeFlag &&
                        <>
                            {unitFlag ===1 && 
                                <>
                                <table className={'tableFixHead'} style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <Calendar1 month={dateValue.getMonth() + 1} year={dateValue.getFullYear()} mode={'calendar1'}></Calendar1 >
                                    </thead>
                                    {
                                        gaex2List.map((row) => {
                                            return (
                                                <>
                                                    <Calendar2 final_day_max_kwh={dayFinalMaxKwh} final_hour_max_kwh={hourFinalMaxKwh} max_kwh_by_month={kwhMaxByMonth} max_total_kwh_by_month={totalKwhMaxByMonth} calendarItem={row} mode={'calendar1'}></Calendar2>
                                                </>
                                            );
                                        })
                                    }
                                </table>
                                </>
                            }
                            {unitFlag === 2 &&
                                <>
                                    <table className={'tableFixHead'} style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <Calendar1 month={dateValue.getMonth() + 1} year={dateValue.getFullYear()} mode={'calendar2'}></Calendar1 >
                                        </thead>
                                        {
                                            gaex2List.map((row) => {
                                                return (
                                                    <>
                                                        <Calendar2 final_day_max_kw={dayFinalMaxKw} final_time_max_kw={hourFinalMaxKw} max_kw_by_month={month_kwh} calendarItem={row} mode={'calendar2'}></Calendar2>
                                                    </>
                                                );
                                            })
                                        }
                                    </table>
                                </>
                            }
                            {unitFlag === 3 && pulseType === "2" &&
                            <>
                                <table className={'tableFixHead'} style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <Calendar1 month={dateValue.getMonth() + 1} year={dateValue.getFullYear()} mode={'calendar3'}></Calendar1 >
                                    </thead>
                                    {
                                        gaex2List.map((row) => {
                                            return (
                                                <>
                                                    <Calendar2 jpy_per_kwh={jpy_per_kwh} final_day_max_kwh={dayFinalMaxKwh} final_hour_max_kwh={hourFinalMaxKwh} max_money_by_month={moneyMaxByMonth} max_total_money_by_month={totalMoneyMaxByMonth} calendarItem={row} mode={'calendar3'}></Calendar2>
                                                </>
                                            );
                                        })
                                    }
                                </table>
                            </>
                            }
                        </>
                            
                    }
                    {unitFlag === 1 && !modeFlag &&
                        (<>
                            <ChartPanel chartModel={chartModel} />
                        </>)
                    }
                    {unitFlag === 2 && !modeFlag &&
                        (<>
                            <ChartPanel chartModel={chartLineModel} />
                        </>)
                    }
                    {unitFlag === 3 && !modeFlag && pulseType === "2" &&
                        (<>
                            <ChartPanel chartModel={moneyChartModel} />
                        </>)
                    }
                </div>
            </>
            }
            <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                <Button color="inherit" variant="contained" onClick={handleCancel}>戻る</Button>
            </Stack>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleFetchSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={getDevice} onComplete={(success, data, error) => success ? handleGetDevice(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
    backData:state.app.backData,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onGetDevice: (id: string | null) => actionCreators.fetch(getDevice, `/master/gateways/get?id=${id}`, "GET", null),
    onChangePageSize: (pageSize: number) => actionCreators.setPageSize(pageSize),
    onShowDetailPage: (id: string) => push(RoutePath.Edit212 + `/${id}`),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－月表検索",
            body: "遠方監視管理一覧を検索できません。" + error,
        }),
    onErrorSearch: () =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－月表検索",
            body: "デマンド－月表を検索できません。",
        }),
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gaex2List as any);
