import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import ChartPanel from "../../chart/ChartPanel";

// -------------
// FETCH ID
// -------------
// Props

export type EarthFaultWaveFormProps = {
    initialValue:any;
    onCancel: () => void;
};

// -------------
// Component
export const EarthFaultWaveForm = (props: EarthFaultWaveFormProps) => {
    // #region Fields
    const { initialValue, onCancel  } = props;
    const [maxValue,setMaxValue] = React.useState(0);
    const [notifyDate,setNotifyDate] = React.useState<any>();
    const [chartModel,setChartModel] = React.useState<any>(null);

    useEffect(() => {
        if(initialValue){
            const currentDate = new Date();
            let temp_data = 0;
            const temp_list_data = [];
            for(let i = 1;i <= 11; i++){
                temp_list_data.push(Number(initialValue[`kw_${i}`]));
                if(Number(temp_data) < Number(initialValue[`kw_${i}`])){
                    temp_data = Number(initialValue[`kw_${i}`]);
                }
            }
            const subString = currentDate.getFullYear() + "/" + initialValue.notify_datetime.substring(0,2) + "/" + initialValue.notify_datetime.substring(2,4) + " " 
            + initialValue.notify_datetime.substring(4,6) + ":" + initialValue.notify_datetime.substring(6,8) + ":" + initialValue.notify_datetime.substring(8,10);
            setNotifyDate(subString);
            setMaxValue(temp_data);
            const chartData = reCreateChartData(temp_list_data);
            setChartModel(chartData);
        }
    },[initialValue])
    // #endregion

    // #region handleFunction
    const reCreateChartData = (data : any) => {
        return {
            chartId: 6,
            labels: [...Array(11)].map((item,index) => {
                if(index == 10){
                    let hours:any = Number(initialValue.notify_datetime.substring(4,6));
                    let minutes:any = Number(initialValue.notify_datetime.substring(6,8));
                    let seconds:any = Number(initialValue.notify_datetime.substring(8,10)) + 1;
                    if( seconds == 60){
                        seconds = 0;
                        minutes = minutes + 1;
                        if(minutes == 60){
                            minutes = 0;
                            hours += 1;
                            if(hours == 24){
                                hours = 0;
                            }
                        }
                    }

                    if(hours < 10){
                        hours = '0' + hours.toString();
                    }

                    if(minutes < 10){
                        minutes = '0' + minutes.toString();
                    }

                    if(seconds < 10){
                        seconds = '0' + seconds.toString();
                    }

                    return hours + ":" + minutes + ":" + seconds;
                }
                else if (index == 0){
                    return initialValue.notify_datetime.substring(4,6) + ":" + initialValue.notify_datetime.substring(6,8) + ":" + initialValue.notify_datetime.substring(8,10)
                }
                return "";
            }),
            dataChart: CreateDataForChart(data),
        };
    };
    
    const CreateDataForChart = (supply_list:any) => {
        const currentDate = new Date();
        return {
            supply:supply_list,
            notify_date: currentDate.getFullYear() + "/" + initialValue.notify_datetime.substring(0,2) + "/" + initialValue.notify_datetime.substring(2,4),
            notify_time: initialValue.notify_datetime.substring(4,6) + ":" + initialValue.notify_datetime.substring(6,8) + ":" + initialValue.notify_datetime.substring(8,10),
        }
    }

    const handleCancel = () => {
        onCancel();
    }
    // #endregion

    return (
        <>
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                微地絡検出 波形詳細
            </DialogTitle>
            <DialogContent>
                    <Grid container direction={"row"}>
                        <Grid item md={3.5}></Grid>
                        <Grid item md={1.5} xs={4}>
                            <Stack direction={"column"} spacing={1}>
                                <Stack direction={"row"}>
                                    <text>検出日時</text>
                                </Stack>
                                <Stack direction={"row"}>
                                    <text>事業所名</text>
                                </Stack>
                                <Stack direction={"row"}>
                                    <text>装置ID</text>
                                </Stack>
                                <Stack direction={"row"}>
                                    <text>CH</text>
                                </Stack>
                                <Stack direction={"row"}>
                                    <text style={{marginRight:"30px"}}>発生時電流</text>
                                </Stack>                            
                            </Stack>
                        </Grid>
                        <Grid item md={6.5} xs={8}>
                            <Stack direction={"column"} spacing={1}>
                                <Stack>
                                    <text>{notifyDate}</text>
                                </Stack>
                                <Stack>
                                    <text>{initialValue.customer_name}</text>
                                </Stack>                        
                                <Stack>
                                    <text>{initialValue.mainid}</text>
                                </Stack>
                                <Stack>
                                    <text>Ch{initialValue.channel_no}{initialValue.point_name}</text>
                                </Stack>
                                <Stack direction={"row"}>
                                    <text>{Number(initialValue.kw_1)}mA</text>
                                    <text style={{marginRight:"30px",marginLeft:"20%"}}>最大電流</text>
                                    <text>{maxValue}mA</text>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid> 
                    { chartModel &&
                        <div style={{ maxWidth: '600px', margin: 'auto',position:"relative"}} >
                            <ChartPanel chartModel={chartModel} />
                            <text style={{position:"absolute",bottom:2,left:0,fontSize:"12px",color:"grey"}}>発生時刻</text>
                            
                        </div>
                    }                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="inherit" variant="contained">キャンセル</Button>
            </DialogActions>
        </Dialog>
            
        </>
    );
};

export default EarthFaultWaveForm;
