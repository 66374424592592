export const AG_GRID_LOCALE_JP = {
    contains: '含む',
    notContains: '含まない',
    equals: '等しい',
    notEqual: '等しくない',
    startsWith: 'で始まる',
    endsWith: 'で終わる',
    blank: '空欄',
    notBlank: '空白ではない',
    filterOoo: 'フィルター...',
    andCondition: 'と',
    orCondition: 'また', 
};