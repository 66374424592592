import { Autocomplete, Button, Grid, Stack, TextField, ToggleButton, ToggleButtonGroup, CircularProgress, Typography } from "@mui/material";
import { connect } from "react-redux";
import FetchHelper from "../../FetchHelper";
import { Controller, useForm } from "react-hook-form";
import { CustomerResponse, DeviceModel, OptionItem } from "../../../models";
import { ApplicationState } from "../../../store";
import { useState } from "react";
import { Path as RoutePath } from "../../../path";
import { useNavigate } from "react-router";
import { actionCreators } from "../../../store/AppStore";
import { limitMasterCustomers, customerLabelName } from "../../../statics";

const customerFetchId = "CUSTOMER_FETCH";

export type AddDeviceFormProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// フォーム定義
interface AddDeviceForm {
    id: string,
    ipAddress: string,
    customer: OptionItem | null,
    place: string,
    pulseType: string,
    emgAlarmLeak: string,
    develop:string,
    group_name:string,
    manage_customer:OptionItem|null,
}

// 初期値設定
const initialValue: AddDeviceForm = {
    id: "",
    ipAddress: "",
    customer: null,
    place: "",
    pulseType: "0",
    emgAlarmLeak:"1",
    develop:"0",
    group_name:"",
    manage_customer:null,
}

export const AddDevice = (props: AddDeviceFormProps) => {
    const { onCreate, onCreateSuccess, onCreateError } = props;
    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<DeviceModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [optionCustomer, setOptionCustomer] = useState<OptionItem[]>([]);
    const [loadingFlag, setLoadingFlag] = useState(true);
    let navigate = useNavigate();
    // Submitイベント
    const handleEditSubmit = (value: any) => {
        setLoadingFlag(true);
        const request = createRequestData(value);
        onCreate(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: DeviceModel) => {
        let request = {
            id: formValue.id,
            ip_address: formValue.ipAddress,
            customer_id: formValue.customer?.value,
            place: formValue.place,
            pulse_type: formValue.pulseType,
            emg_alarm_leak:formValue.emgAlarmLeak,
            develop:formValue.develop,
            manage_customer_id: formValue.manage_customer ? formValue.manage_customer.value : "",
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => navigate(RoutePath.DeviceList);

    // 顧客名一覧取得成功時
    const handleFetchCustomersSuccess = (data: CustomerResponse) => {
        setLoadingFlag(false);
        if (data?.results) setOptionCustomer(data?.results.map((x) => { return { value: x.id.toString(), label: x.name, group_name:x.group_name, group_id:x.group_id } as any }));
    };

    // 顧客名一覧取得成功時
    const handleFetchCustomersError = () => {
    };

    const handleAddSuccess = (data: any) => {
        if (data.result_code === 1) {
            handleAddError();
        } else {
            onCreateSuccess();
            navigate(RoutePath.EditDevice + '/' + data.data.id);
        }
    }

    const handleAddError = () => {
        setLoadingFlag(false);
        onCreateError();
    }

    return (
        <>
            <FetchHelper fetchId={customerFetchId}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomersSuccess(data) : handleFetchCustomersError()
                }}
            />
            
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <Stack direction='row' justifyContent="flex-start" spacing={1.5} alignItems='center'>
                    <Typography variant="h5">装置追加</Typography>
                </Stack>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                    <Stack spacing={3} mt={0.5}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    {...register("id",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^[a-zA-Z0-9]+$/,
                                                message: '半角英数字のみ可能'
                                            },
                                            maxLength: {
                                                value: 6,
                                                message: '最大6文字まで'
                                            },
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    label="装置ID"
                                    size="small"
                                    inputProps={{ maxLength: 6 }}
                                    error={!!errors?.id}
                                    helperText={errors?.id?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    {...register("ipAddress",
                                        {
                                            required: '必須項目です。入力してください。',
                                            pattern: {
                                                value: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                                                message: 'IPアドレスタイプを入力してください。'
                                            },
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    label="IP アドレス"
                                    size="small"
                                    inputProps={{ maxLength: 15 }}
                                    error={!!errors?.ipAddress}
                                    helperText={errors?.ipAddress?.message}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="group_name"
                                render={({ field }) => (
                                    <Grid container direction="row">
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="グループ名"
                                            size="small"
                                            error={!!errors?.group_name}
                                            helperText={errors?.group_name?.message}
                                            inputProps={{
                                                maxLength:255,
                                                readOnly:true,
                                            }}
                                            sx={{backgroundColor:"floralwhite"}}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="customer"
                                rules={{ required: "必須項目です。選択してください。", }}
                                render={({ field }) => (
                                    <Grid container spacing={1} direction="row">
                                        <Autocomplete
                                            {...field}
                                            autoHighlight
                                            options={optionCustomer}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values:any) => {
                                                field.onChange(values)
                                                if(values != null){
                                                    setValue("group_name",values.group_name);
                                                }else{
                                                    setValue("group_name","");
                                                }
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} error={!!errors?.customer} helperText={errors?.customer?.message} />}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Grid container spacing={1} direction="row">
                                <TextField
                                    {...register("place",
                                        {
                                            required: '必須項目です。入力してください。'
                                        })
                                    }
                                    fullWidth
                                    type="text"
                                    label="場所"
                                    size="small"
                                    error={!!errors?.place}
                                    helperText={errors?.place?.message}
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="manage_customer"
                                render={({ field }) => (
                                    <Grid container spacing={1} direction="row">
                                        <Autocomplete
                                            {...field}
                                            autoHighlight
                                            options={optionCustomer}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, values:any) => {
                                                field.onChange(values)
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label={`管理組織名`} error={!!errors?.manage_customer} helperText={errors?.manage_customer?.message} />}
                                        />
                                    </Grid>
                                )}
                            />
                        </Stack>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '10px' }}>
                            <Controller
                                control={control}
                                name="pulseType"
                                render={({ field }: any) => (
                                    <Grid container spacing={1} direction="row" >
                                        <ToggleButtonGroup
                                            color="primary"
                                            exclusive
                                            {...field}
                                            size="small"
                                        >
                                            <ToggleButton value="0">なし</ToggleButton>
                                            <ToggleButton value="1">デマンド</ToggleButton>
                                            <ToggleButton value="2">太陽光</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                )}
                            />
                        </Stack>
                    </Stack>
                    
                    <Stack mt={"20px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                        <Typography>テス卜用装置判定</Typography>
                    </Stack>
                    <Stack mt={"5px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                        <Controller
                            control={control}
                            name="develop"
                            render={({ field }: any) => (
                                <Grid container spacing={0.5} direction="row" >
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        {...field}
                                        size="small"
                                    >
                                        <ToggleButton value="0">本番用</ToggleButton>
                                        <ToggleButton value="1">テス卜用</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            )}
                        />
                    </Stack>
                    <Stack mt={"20px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                        <Typography>漏電緊急警報設定</Typography>
                    </Stack>
                    <Stack mt={"5px"} spacing={4} direction={{ xs: 'column', sm: 'row' }} style={{ marginLeft: '12px' }}>
                        <Controller
                            control={control}
                            name="emgAlarmLeak"
                            render={({ field }: any) => (
                                <Grid container spacing={0.5} direction="row" >
                                    <ToggleButtonGroup
                                        color="primary"
                                        exclusive
                                        {...field}
                                        size="small"
                                    >
                                        <ToggleButton value="0">なし</ToggleButton>
                                        <ToggleButton value="1">あり</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            )}
                        />
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button variant="contained" type="submit">保存</Button>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
            < FetchHelper fetchId="CREATE_DEVICE" method="POST" onComplete={(success, data, error) => success ? handleAddSuccess(data) : handleAddError()} />
        </>
    );
};


const mapStateToProps = (state: ApplicationState) => ({
});

const mapDispatchToProps = {
    onCreate: (data: any) => actionCreators.fetch("CREATE_DEVICE", `/master/gateways/add`, "POST", data, false, true),
    onCreateSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "装置追加",
            body: "装置を登録しました。",
        }),
    onCreateError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "装置追加",
            body: "装置の登録が出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDevice as any);


