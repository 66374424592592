import CachedIcon from '@mui/icons-material/Cached';
import { Card, CardContent, CardHeader, CircularProgress, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { ConfirmTimeModel } from "../../../models";

import { connect } from "react-redux";
import { actionCreators, ApplicationState, FetchState, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const NO_OF_POLLING_TOPIC = 7;
const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isRefreshAllCompleted: (status: number) => { },
    loadingRefreshAll: [false],
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setRefreshGroup: (refreshGroup: boolean, name: string) => { },
    isDisbleRefeshBtn: true,
}

type ConfirmTimeProps = {
    defaultData: ConfirmTimeModel[] | undefined;
};

export type PureConfirmTimeProps = ConfirmTimeProps & ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

const fetchId = "CONFIRM_TIME_FETCH";
export const ConfirmTime = (props: PureConfirmTimeProps) => {
    const { defaultData, fetchState, mainid, isRefreshAllBegin, loadingRefreshAll, isRefreshGroup, isDisbleRefeshBtn } = props;
    const { onError, onRefresh, isRefreshAllCompleted, setDisabledRefreshGroupBtn, setRefreshGroup } = props;
    const [confirmTime, setConfirmTime] = React.useState<ConfirmTimeModel>(props.fetchState.data);
    const [refreshFlg, setRefreshFlg] = React.useState(false);
    const [isRefreshSuccess, setIsRefreshSuccess] = React.useState(false);

    // パラメータ設定
    const handleFetchParams = () => {
        const params = new Map<string, any>();
        const code = "PO";
        params.set("code", code);
        params.set("mainid", mainid);
        return params;
    }

    // デマンド現在値取得成功時
    const handleFetchSuccess = (data: any, isClickedRefreshBtn: boolean) => {
        setRefreshFlg(false);
        const resData = {
            mainid: data.mainid,
            deviceNo: data.device_no,
            year: data.year,
            month: data.month,
            day: data.day,
            hour: data.hour,
            minute: data.minute,
            lastGetDatetime: data.last_get_datetime,
        } as ConfirmTimeModel;
        setConfirmTime(resData);
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        isClickedRefreshBtn && setIsRefreshSuccess(true);
    };

    const handleError = (success: boolean, data: any, error: any) => {
        setRefreshFlg(false);
        onError(success, data, error);
        if (isRefreshAllBegin > 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
        loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] = false;
        setRefreshGroup(false, fetchId);
        setIsRefreshSuccess(false);
    }

    const handleRefresh = () => {
        setRefreshFlg(true);
        setIsRefreshSuccess(false);
        const params = handleFetchParams();
        onRefresh(params);
    };

    const handleRefreshSuccess = (data: any, fetchId: string) => {
        handleFetchSuccess(data, true);
        if (isRefreshAllBegin !== 0) {
            isRefreshAllCompleted(isRefreshAllBegin + 1);
        }
    }

    const refreshAll = () => {
        if (isRefreshAllBegin === NO_OF_POLLING_TOPIC) {
            console.log("fetch No:______" + isRefreshAllBegin);
            handleRefresh();
        }
        if (isRefreshGroup) {
            handleRefresh();
            setIsRefreshSuccess(false);
        }
        if (loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) {
            setIsRefreshSuccess(false);
        }
        isRefreshAllBegin > 0 || isRefreshGroup ? setRefreshFlg(true) : setRefreshFlg(false);
    }
    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, isRefreshGroup]);

    React.useEffect(() => {
        setDisabledRefreshGroupBtn(refreshFlg, fetchId);
        // eslint-disable-next-line
    }, [refreshFlg]);

    React.useEffect(() => {
        defaultData && handleFetchSuccess(defaultData, false);
        // eslint-disable-next-line
    }, [defaultData]);

    return (
        <>
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSuccess ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="refresh" onClick={handleRefresh} disabled={isDisbleRefeshBtn || refreshFlg}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || refreshFlg ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='時間確認'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {(fetchState.loading || loadingRefreshAll[NO_OF_POLLING_TOPIC - 1] === true) ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>年</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>月</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>日</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>時</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>分</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '40%', left: '60%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>年</td>
                                    <td style={pollingColStyle}>{confirmTime?.year}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>月</td>
                                    <td style={pollingColStyle}>{confirmTime?.month}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>日</td>
                                    <td style={pollingColStyle}>{confirmTime?.day}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>時</td>
                                    <td style={pollingColStyle}>{confirmTime?.hour}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>分</td>
                                    <td style={pollingColStyle}>{confirmTime?.minute}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>最終取得日時：{confirmTime?.lastGetDatetime ?? '-'}</Typography>
                </CardContent>
            </Card >
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleRefreshSuccess(data, fetchId) : handleError(success, data, error)} />
        </>
    );
};
const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});
const mapDispatchToProps = {
    onRefresh: (params: any) => actionCreators.fetch(fetchId, `/polling/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "時間確認取得",
            body: "時間確認取得できませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmTime);


