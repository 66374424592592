import { Box, Card, CardContent, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Credential } from "../../models";
import { Path as RoutePath } from "../../path";
import { ApplicationState, actionCreators, selectors } from "../../store";
import FetchHelper from "../FetchHelper";
import LoginForm from "./LoginForm";

const menusFetchId = 'MENUS_FETCH';

// -------------
// Props
export type LoginProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

// -------------
// Component
export const Login = (props: LoginProps) => {
  const version = process.env.REACT_APP_VERSION;
  const { onSubmit, isAuthorize, isNewPasswordRequired, onGetAuthRole } = props;
  
  const [loadingFlag, setLoadingFlag] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (isNewPasswordRequired)
    {
        navigate('/');
        return;
    }
    if (isAuthorize) {
      navigate(RoutePath.Dashboard);
      // const currentUrl = localStorage.getItem("currentUrl");
      // if (currentUrl !== undefined && currentUrl !== null && currentUrl !== '') {
      //   navigate('/' + currentUrl);
      // } else {
      //   setLoadingFlag(true);
      //   onGetAuthRole();
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isAuthorize, isNewPasswordRequired])

  // check role
  const handleFetchAuthRoleSuccess = (data: any) => {
    if (data){
      navigate(RoutePath.Dashboard);
      // if (data['role'].toString() === CustomerRoleValue){
      //   //localStorage.setItem('currentUrl', RoutePath.SolarLiveStatus);
      //   navigate(RoutePath.SolarLiveStatus)
      //   return
      // }else if(data["role"].toString() === SmartCustomerRoleValue){
      //   if(data.code_restriction_array){
      //     if(data.code_restriction_array.includes("GADEMAND")){
      //       navigate(RoutePath.SmartDemandList);
      //       return
      //     }else if(data.code_restriction_array.includes("GASOLAR")){
      //       navigate(RoutePath.SmartSolarList);
      //       return
      //     }
      //   }
      // } else if (data.auth_role_map['LM-telemetry-view'] && (data['code_restriction_array']?.length == 0 || data['code_restriction_array'].includes('ALARM'))){
      //   //localStorage.setItem('currentUrl', RoutePath.AlarmList + '?path=root');
      //   navigate(RoutePath.AlarmList + '?path=root')
      //   return
      // } else {
      //   //localStorage.setItem('currentUrl', RoutePath.SolarLiveStatus);
      //   navigate(RoutePath.SolarLiveStatus)
      //   return
      // }
    }
  }
  
  const handleError = (success: boolean, data: any, error: any) => {
    console.log('>>> handleError');
    setLoadingFlag(false);
  };

  const onLogin = (loginData: Credential) => {
    localStorage.setItem('currentUrl', "");
    onSubmit(loginData);
  };

  return (
    <>
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress />
        </div>
      )}
      <Stack justifyContent="center" alignItems="center" style={{ height: '66vh'}}>
        <Typography variant="h3">遠方監視サービス</Typography>
        <div>
          <Card sx={{ width: 400, boxShadow: 5, marginTop: 5 }}>
            <CardContent>
              <LoginForm handleDataLogin={onLogin} />
            </CardContent>
          </Card>
        </div>
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: 'relative' }}
        >
          <caption>{version}</caption>
        </Stack>
      </Box>
      
      <FetchHelper 
        fetchId={menusFetchId} 
        onComplete={(success, data, error) => 
          success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)
        } 
      />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isAuthorize: selectors.isAuthorize(state),
  isNewPasswordRequired: selectors.isNewPasswordRequired(state),
})

const mapDispatchToProps = {
  onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
  onSubmit: (value: Credential) => actionCreators.login(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login as any);
