import CachedIcon from '@mui/icons-material/Cached';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, CircularProgress, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import { formatDecimal, formatNumber, formatRoundDownDecimal } from "../../../utils";
import FetchHelper from "../../FetchHelper";
import ChartPanel, { ChartResult } from "../../chart/ChartPanel";
import { DailyChartLineData } from "../../chart/DailyChart";
import YearContent from '../../common/YearContent';
import YearHeader from '../../common/YearHeader';
import './styles.css';
import DeviceInformation from '../../polling/device/DeviceInformation';
import { useCSVDownloader } from 'react-papaparse';
import BackButton from '../../common/BackButton';
const menusFetchId = 'MENUS_FETCH';


// -------------
// FETCH ID
const fetchId = "GAEX5_LIST";
const formId = "GAEX5_SEARCH_FORM";
const getDevice = "GAEX5_GET_DEVICE";

// -------------
// Props
export type Gaex5ListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const Gaex5List = (props: Gaex5ListProps) => {
    const { CSVDownloader, Type } = useCSVDownloader();
    const { fetchState, onError, onSearch, onErrorSearch, onGetAuthRole, onGetDevice,fetchStateMenu } = props;
    const [pulseType, setPulseType] = React.useState("");
    const [unitFlag, setUnitFlag] = React.useState<number>(1);
    const [modeFlag, setModeFlag] = React.useState(true);
    const [gaex5List, setGaex5List] = React.useState<any[]>([]);
    const [dateValue, setDateValue] = React.useState(new Date());
    const [dateSelect, setDateSelect] = React.useState(new Date());
    const [chartModel, setChartModel] = React.useState<ChartResult>();
    const [chartLineModel, setChartLineModel] = React.useState<ChartResult>();
    const [moneyChartModel, setMoneyChartModel] = React.useState<ChartResult>();
    const [year_total_kwh, set_year_total_kwh] = React.useState("0");
    const [year_amount,setYear_amount] = React.useState("0");
    const [jpy_per_kwh,setJpy_per_kwh] = React.useState("0");
    const [year_kw, setYear_kw] = React.useState<number>(0);
    const [dataList, setDataList] = React.useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [alarm1_kw,setAlarm1_kw] = React.useState("0");
    const [alarm2_kw,setAlarm2_kw] = React.useState("0");
    const [monthMaxKwh,setMonthMaxKwh] = React.useState<any>(0);
    const [monthsMaxAmount,setMonthsMaxAmount] = React.useState<any>(0);
    const [yearMaxKwh,setYearMaxKwh] = React.useState<any>(0);
    const [getMaxAmountByYear,setMaxAmountByYear] = React.useState<any>(0);
    const [device, setDevice] = React.useState<any>();
    const [dayMaxKw,setDayMaxKw] = React.useState("");
    const [monthMaxKw,setMonthMaxKw] = React.useState("");
    const [getDayFinalMaxKwh,setDayFinalMaxKwh] = React.useState<any>("");
    const [getMonthFinalMaxKwh,setMonthFinalMaxKwh] = React.useState<any>("");
    const [fileNameCSV,setFileNameCSV] = React.useState<any>(pulseType === "1" ? "デマンド定期通報":"太陽光定期通報");
    const [loadingDeviceFlag,setLoadingDeviceFlag] = React.useState(false);
    const [authRole, setAuthRole] = React.useState<any>();

    const getValueData = (data:any) => {
        if(data != null && data != undefined)
            return data;
        return "-";
    }

    useEffect(()=> {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    },[])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    useEffect(()=>{
        const date = new Date();
        let name = date.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')
        if(pulseType === "1"){
            name += "_デマンド-年表"; 
        }else if(pulseType === "2"){
            name += "_太陽光-年表"
        }
        
        switch(unitFlag){
            case 1:
                name += "(kWh)";
                break;
            case 2:
                name += "(kW)";
                break;
            case 3:
                name += "(金額)";
                break;
        }
        if(device){
            name += `_${dateValue.getFullYear()}年_${device.customer_name}_${device.id}`
        }
        setFileNameCSV(name);
    },[unitFlag,device])

    const handleExportCSV = () => {
        let result:any = [];
        let headerName = ["月合計","月"];
        switch(unitFlag){
            case 1:
                result = [];
                headerName = ["月合計","月"];
                [...Array(31)].forEach((item:any,index:any)=> {
                    headerName.push(`${index+1}日`);
                })
                result.push(headerName);
                gaex5List.forEach((item:any)=> {
                    const monthData = [];
                    monthData.push(getValueData(item.month_kwh));
                    monthData.push(item.month);
                    [...Array(31)].forEach((item:any,index:any)=>{
                        monthData.push("-")
                    })
                    if(item.days_data.length > 0){
                        item.days_data.forEach((item:any)=>{
                            monthData[Number(item.day)+1] = getValueData(item.day_kwh);
                        })
                    }
                    result.push(monthData);
                });
                break;
            case 2:
                result = [];
                headerName = ["月最大","月"];
                [...Array(31)].forEach((item:any,index:any)=> {
                    headerName.push(`${index+1}日`);
                })
                result.push(headerName);
                gaex5List.forEach((item:any)=> {
                    const monthData = [];
                    monthData.push(getValueData(item.month_kw));
                    monthData.push(item.month);
                    [...Array(31)].forEach((item:any,index:any)=>{
                        monthData.push("-")
                    })
                    if(item.days_data.length > 0){
                        item.days_data.forEach((item:any)=>{
                            monthData[Number(item.day)+1] = getValueData(item.day_kw);
                        })
                    }
                    result.push(monthData);
                });
                break;
            case 3:
                result = [];
                headerName = ["月合計","月"];
                [...Array(31)].forEach((item:any,index:any)=> {
                    headerName.push(`${index+1}日`);
                })
                result.push(headerName);
                gaex5List.forEach((item:any)=> {
                    const monthData = [];
                    monthData.push(getValueData(item.month_amount));
                    monthData.push(item.month);
                    [...Array(31)].forEach((item:any,index:any)=>{
                        monthData.push("-")
                    })
                    if(item.days_data.length > 0){
                        item.days_data.forEach((item:any)=>{
                            monthData[Number(item.day)+1] = getValueData(item.day_amount);
                        })
                    }
                    result.push(monthData);
                });
                break;
        }
        return result;
    }

    let navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const mainid = urlParams.get('mainid');

    const handleFetchSuccess = (data: any) => {
        setDataList(data);
        handleData(data, unitFlag, modeFlag);
        setLoadingFlag(false);
    };

    const handleGetDevice = (data: any) => {
        setPulseType(data.pulse_type);
        setDevice(data);
        setLoadingDeviceFlag(false);
    };

    const handleData = (data: any, unitFlag: number, modeFlag: boolean) => {
        set_year_total_kwh("0");
        setJpy_per_kwh("0");
        setYear_amount("0");
        setAlarm1_kw("0");
        setAlarm2_kw("0");
        setMonthMaxKwh(0);
        setMonthsMaxAmount(0);
        setYearMaxKwh(0);
        
        let monthsData: any[] = [];
        if(data.year_amount)
            setYear_amount(formatNumber(data.year_amount).toString());
        if(data.jpy_per_kwh)
            setJpy_per_kwh(data.jpy_per_kwh.toString());
        if(data.year_kwh){
            set_year_total_kwh(data.year_kwh.toString());
        }
        if(data.alarm1_kw)
            setAlarm1_kw(data.alarm1_kw);
        if(data.alarm2_kw)
            setAlarm2_kw(data.alarm2_kw);
        if (data.months_data === undefined || data.months_data.length == 0) {
            for(let i = 1;i<=12;i++){
                monthsData.push({
                    month:i,
                    month_kwh:"-",
                    month_kw:"-",
                    month_amount:"-",
                    days_data:[],
                })
            }
            setMonthMaxKw("");
            setDayMaxKw("");
            setDayFinalMaxKwh("");
            setMonthFinalMaxKwh("");
            setYear_kw(0);
        }
        else
        {
            if(data.months_data.length > 0){
                for(let i =1;i<=12;i++){
                    monthsData.push({
                        month:i,
                        month_kwh:"-",
                        month_kw:"-",
                        month_amount:"-",
                        days_data:[],
                    })
                }
                let arrayDay:[] = [];
                let month_max_kwh = data.months_data[0].month_kwh;
                let months_max_amount = data.months_data[0].month_amount;
                let years_max_kwh = data.months_data[0].days_data[0].day_kwh;
                let max_day_amount_year = data.months_data[0].days_data[0].day_amount;
                const months_data:any[] = data.months_data;
                let year_kw = 0;
                let month_max = 0;
                let day_max = 0;
                
                months_data.forEach((item:any) => {
                    const indexMonth = monthsData.map(e => e.month).indexOf(item.month);
                    if(item.month_kwh > month_max_kwh)
                    {
                        month_max_kwh = item.month_kwh
                    }
                    if(item.month_amount > months_max_amount){
                        months_max_amount = item.month_amount;
                    }
                    monthsData[indexMonth]["month_kwh"] = item.month_kwh;
                    monthsData[indexMonth]["month_amount"] = item.month_amount;
                    monthsData[indexMonth]["month_kw"] = item.month_kw;
                    const daysData = item.days_data;
                        const max_day_kwh_in_month = daysData.reduce((a:any,b:any)=>a.day_kwh>b.day_kwh?a:b).day_kwh;
                        const day_max = daysData.reduce((a:any,b:any)=>a.day_kwh>b.day_kwh?a:b).day; 
                        const max_day_amount_by_year = daysData.reduce((a:any,b:any)=>a.day_amount>b.day_amount?a:b).day_amount;
                        // kwh <=> amount 
                        if(max_day_kwh_in_month > years_max_kwh){
                            years_max_kwh = max_day_kwh_in_month;
                            max_day_amount_year = max_day_amount_by_year;
                            setDayFinalMaxKwh(day_max);
                            setMonthFinalMaxKwh(item.month);
                        }else if(max_day_kwh_in_month == years_max_kwh){
                            setDayFinalMaxKwh(day_max);
                            setMonthFinalMaxKwh(item.month);
                        }
                    if(year_kw <= Number(item.month_kw))
                    {
                        year_kw = item.month_kw;
                        month_max = item.month;
                        arrayDay = daysData.filter((value:any) => value.day_kw == item.month_kw);                    
                    }
                    monthsData[indexMonth]["days_data"] = daysData;
                    })  
                    if(arrayDay.length > 0){
                        day_max = arrayDay[arrayDay.length-1]["day"];
                    }
                setYear_kw(year_kw);
                setMonthMaxKw(month_max.toString());
                setDayMaxKw(day_max.toString());
                setMonthMaxKwh(month_max_kwh);
                setMonthsMaxAmount(months_max_amount);
                setYearMaxKwh(years_max_kwh);
                setMaxAmountByYear(max_day_amount_year);
            }
        }
        setGaex5List(monthsData);
        onCreateChart(monthsData);
    }

    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingDeviceFlag(false);
    }

    const CreateDataChartLine = (data: any) => {
        return {
            dataChart1: data.map((item: any) => formatRoundDownDecimal(item.month_kw.toString(),0,true)),
            dataChart2: data.map((item: any) => Number(alarm2_kw) === 0 ? null : Number(alarm2_kw)),
            dataChart3: data.map((item: any) => Number(alarm1_kw) === 0 ? null : Number(alarm1_kw)),
            pulse_type: pulseType,
        } as DailyChartLineData;
    };

    const reCreateChartData = (data: any) => {
        return {
            chartId: 2,
            labels: data.map((item: any,index:any) => item.month+ '月'),
            dataChart: data.map((item: any) => formatRoundDownDecimal(item.month_kwh.toString(),0,true)),
            unit: "kWh"
        } as ChartResult;
    };
    const reCreateDataChartLine = (data: any) => {
        const newData = [...data].map((item) => {
            if(item.month_kw == "-")
                item.month_kw = 0
            return item;
        })
        return {
            chartId: 3,
            labels: data.map((item: any) => item.month + '月'),
            dataChart: CreateDataChartLine(newData),
        } as ChartResult;
    };
    const reCreateMoneyChartData = (data:any)=>{
        return {
            chartId: 2,
            labels: data.map((item: any) => item.month + '月'),
            dataChart: data.map((item: any) => item.month_amount),
            unit: "円"
        } as ChartResult;
    }
    const onCreateChart = (data: any) => {
        const newData = JSON.parse(JSON.stringify(data))
        const chartData = reCreateChartData(newData);
        const moneyChartData= reCreateMoneyChartData(newData);
        const chartLineData = reCreateDataChartLine(newData);
        setChartModel(chartData);
        setMoneyChartModel(moneyChartData);
        setChartLineModel(chartLineData);
    }

    const createData = (value: Date) => {
        const params = new Map<string, any>();
        params.set("code", "GAEX5");
        params.set("mainid", mainid);
        params.set("year", value.getFullYear());
        return params;
    };

    const handleErrorSearch = () => {
        setLoadingFlag(false);
        handleData([], unitFlag, modeFlag);
    }

    // 検索実行
    const handleSearchSubmit = (params: any) => {
        let isError = false;

        if (isEmpty(mainid)) isError = true;

        onGetDevice(mainid);
        setLoadingDeviceFlag(true);

        if (!isError) {
            onSearch(params);
        } else {
            handleErrorSearch();
        }
    }

    const isEmpty = (value: any) => {
        return (value === null || value?.length <= 0)
    }

    React.useEffect(() => {
        var params = createData(dateValue);
        handleSearchSubmit(params);
        // eslint-disable-next-line
    }, []);

    //タブ表示ボタンスタイル
    const buttonStyle1 = {
        backgroundColor:  unitFlag === 1  ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '9px 0 0 9px',
    }

    const buttonStyle2 = {
        backgroundColor:  unitFlag === 2  ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: pulseType ==="2" ? '0px 0px 0px 0px': '0px 9px 9px 0px',
    }

    const buttonStyle3 = {
        backgroundColor: unitFlag === 3 ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '0px 9px 9px 0px',
    }

    const buttonStyle4 = {
        backgroundColor: modeFlag ? '#997a00' : '#e6b800',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '9px 0 0 9px',
        
    }

    const buttonStyle5 = {
        backgroundColor: modeFlag ? '#e6b800' : '#997a00',
        color: 'white',
        height: '40px',
        margin: 'auto 0',
        border: '0.5px solid #997a00',
        borderRadius: '0 9px 9px 0',
    }
    
    // キャンセル
    const handleCancel = () => {
        let path = sessionStorage.getItem("references");
        if(!path){
            if(authRole.auth_role_map["LM-telemetry-view"])
            {
                if(pulseType == "1" && (authRole.code_restriction_array?.length == 0 || authRole.code_restriction_array?.includes("GADEMAND"))){
                    let newPath = "/telemetry/ga";
                    let newUrlPath = (urlParams.get('path'))
                    if(newUrlPath){
                        newUrlPath = (urlParams.get('path') ?? "root");
                        newPath +='?path=' + newUrlPath;
                        newPath += id ? '&id=' + id : "";
                        if (newUrlPath=="customer") {
                            newPath += '&customer_id=' + (urlParams.get('customer_id') ?? '');
                        }
                    }else{
                        newPath += "?path=root";
                    }
                    if(newPath){
                        navigate(newPath, { replace: true });
                        return;
                    }
                }
                if(pulseType == "2" && (authRole.code_restriction_array?.length == 0 || authRole.code_restriction_array?.includes("GASOLAR"))){
                    let newPath = "/telemetry/ga2";
                    let newUrlPath = (urlParams.get('path'))
                    if(newUrlPath){
                        newUrlPath = (urlParams.get('path') ?? "root");
                        newPath +='?path=' + newUrlPath;
                        newPath += id ? '&id=' + id : "";
                        if (newUrlPath=="customer") {
                            newPath += '&customer_id=' + (urlParams.get('customer_id') ?? '');
                        }
                    }else{
                        newPath += "?path=root";
                    }
                    if(newPath){
                        navigate(newPath, { replace: true });
                        return;
                    }
                }
            }
            if(authRole.auth_role_map["LM-smonitor-view"]){
                if(pulseType == "1" && authRole.code_restriction_array?.includes("GADEMAND")){
                    navigate(RoutePath.SmartDemandList);
                    return;
                }
                if(pulseType == "2" && authRole.code_restriction_array?.includes("GASOLAR")){
                    navigate(RoutePath.SmartSolarList);
                    return;
                }
            }
            navigate("/");
            return;
        }
        sessionStorage.removeItem('references');
        let urlPath = (urlParams.get('path'))
        if(urlPath){
            urlPath = (urlParams.get('path') ?? "root");
            path +='?path=' + urlPath;
            path += id ? '&id=' + id : "";
            if (urlPath=="customer") {
                path += '&customer_id=' + (urlParams.get('customer_id') ?? '');
            }
        }else{
            path += id ? '?id=' + id : "";
        }
        if(path)
            navigate(path, { replace: true });
    }

    const handleChangeUnit = (flag: number) => {
        if (unitFlag !== flag) {
            setUnitFlag(flag);
            handleData(dataList, flag, modeFlag);
        }
    }

    const handleChangeMode = (flag: boolean) => {
        if (modeFlag !== flag) {
            setModeFlag(flag);
            handleData(dataList, unitFlag, flag);
        }
    }

    // キャンセル
    const onLeft = () => {
        var tempDate = new Date(dateValue.getFullYear(), dateValue.getMonth());
        tempDate.setFullYear(tempDate.getFullYear() + 1)
        setDateValue(tempDate);
        setDateSelect(tempDate);
        setLoadingFlag(true);
        handleSearchSubmit(createData(tempDate));
    }

    // キャンセル
    const onRight = () => {
        var tempDate = new Date(dateValue.getFullYear(), dateValue.getMonth());
        tempDate.setFullYear(tempDate.getFullYear() - 1)
        setDateValue(tempDate);
        setDateSelect(tempDate);
        setLoadingFlag(true);
        handleSearchSubmit(createData(tempDate));
    }

    const handleRefresh = () => {
        if (!isNaN(dateSelect.getTime())) {
            setLoadingFlag(true);
            setDateValue(dateSelect);
            handleSearchSubmit(createData(dateSelect));
        }
    }

    const handleGetLatestData = () => {
        setLoadingFlag(true);
        var date = new Date();
        setDateSelect(date);
        setDateValue(date);
        handleSearchSubmit(createData(date));
    }

    return (
        <>
            {(loadingFlag || loadingDeviceFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <DeviceInformation titleFlag={true} deviceInformation={device} mainid={mainid ?? ''} title="定期通報" isDisplayDeviceInfo={true} isShowMenuButton ={false} ></DeviceInformation>
            <Stack mt={"10px"} direction="row">
                <div style={{ width: '100%', display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                    <Typography variant="body1" style={{ color: "gray" }} mr={2}>単位</Typography>
                    <Button style={{ textTransform: 'none' }} sx={buttonStyle1} variant="text" onClick={e => handleChangeUnit(1)}>kWh</Button>
                    <Button style={{ textTransform: 'none' }} sx={buttonStyle2} variant="text" onClick={e => handleChangeUnit(2)}>kW</Button>
                    {
                    pulseType === "2" && 
                    <Button style={{textTransform:'none' }}  sx={buttonStyle3}  variant="text" onClick= {(e)=> handleChangeUnit(3)} >金額</Button>
                    }
                    <Typography variant="body1" style={{ color: "gray" }} ml={2} mr={2}>種類</Typography>
                    <Button style={{ textTransform: 'none'}} sx={buttonStyle4} variant="text" onClick={e => handleChangeMode(true)}>表</Button>
                    <Button style={{ textTransform: 'none' }} sx={buttonStyle5} variant="text" onClick={e => handleChangeMode(false)}>グラフ（月毎）</Button>
                    <div style={{ marginInlineStart: 'auto', pointerEvents: fetchState.loading ? "none" : "auto" }}>
                        {modeFlag && 
                            <CSVDownloader
                            type={Type.Link}
                            filename={fileNameCSV}
                            bom={true}
                            data={handleExportCSV}
                            >
                                <Button variant="outlined" size="small" style={{ margin: 'auto 10px auto 0',padding:"7px" }}>CSV</Button>
                            </CSVDownloader>
                        }
                        <Button variant="outlined" size="small" style={{ height: '40px', borderRadius: '9px 0 0 9px' }} onClick={onRight} >{<ChevronLeftIcon />}前年</Button>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY"
                                views={["year"]}
                                openTo="year"
                                renderInput={(props: any) => (
                                    <TextField
                                        size="small"
                                        inputProps={{readOnly:true}}
                                        disabled={true}
                                        style={{ width: '100px', marginLeft: '-1px' }}
                                        sx={{
                                            fieldset: {
                                                border: "1px solid rgba(24, 144, 255, 0.5)",
                                                borderWidth: "1px 1px 1px 1px",
                                                zIndex: "1",
                                                borderRadius: "unset"
                                            }
                                        }}
                                        {...props} />
                                )}
                                value={dateSelect}
                                onChange={(newValue: any) => {
                                    if(newValue){
                                        var tempDate = new Date(newValue.$y, dateValue.getMonth());
                                        tempDate.setFullYear(tempDate.getFullYear())
                                        setDateSelect(tempDate);
                                    }
                                }}
                                disableMaskedInput={false}
                            />
                        </LocalizationProvider>
                        <Button variant="outlined" size="small" style={{ height: '40px', borderRadius: '0px 0px', marginLeft: '-1px' }} onClick={onLeft}>翌年{<ChevronRightIcon />}</Button>
                        <Button variant="outlined" size="small" style={{ height: '40px', borderRadius: '0px 9px 9px 0px', borderWidth: "1px 1px 1px 1px", marginLeft: '-1px' }} onClick={handleGetLatestData}>最新</Button>
                        <IconButton aria-label="edit" >
                            <CachedIcon fontSize="medium" style={{ color: '#1890ff' }} onClick={handleRefresh} />
                        </IconButton>
                        <IconButton>
                            <div>
                            {
                                pulseType &&
                                <BackButton isYearlyReport={true} pulseType={pulseType} title='' isShowMenuButton={true} titleFlag={false} mainid={mainid ?? ""}/>
                            }
                            </div>
                        </IconButton>
                    </div>
                </div>

            </Stack>
            {!loadingFlag && <>
                {
                    unitFlag === 1 &&
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                        <Typography variant="h2" mr={4} style={{ fontWeight: "normal" }}>{dateValue.getFullYear()}年</Typography>
                        <Typography variant="h4" mr={1} style={{ color: "gray", fontWeight: "normal" }}>発電量 年合計</Typography>
                        <Typography variant="h3" style={{ color: "red" }}>{formatRoundDownDecimal(year_total_kwh.toString(),0)} kWh</Typography>
                    </div>
                }
                {
                    unitFlag === 2 &&
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                        <Typography variant="h2" mr={4} style={{ fontWeight: "normal" }}>{dateValue.getFullYear()}年</Typography>
                        <Typography variant="h4" mr={1} style={{ color: "gray", fontWeight: "normal" }}>最大発電量</Typography>
                        <Typography variant="h3" style={{ color: "red" }}>{formatRoundDownDecimal(year_kw.toString(),0)} kW</Typography>
                        <Typography variant="h4" style={{ color: "red" }}>{monthMaxKw !== "" ? `(${Number(monthMaxKw)}月 ${Number(dayMaxKw)}日)` : ""}</Typography>
                    </div>
                }
                {   
                    pulseType === "2" && unitFlag === 3 &&
                    <div style={{ display: "flex", alignItems: "baseline"}}>
                        <Typography variant="h2" mr={4} style={{ fontWeight: "normal" }}>{dateValue.getFullYear()}年</Typography>
                        <Typography variant="h4" mr={1} style={{ color: "gray", fontWeight: "normal" }}>発電金額 年合計</Typography>
                        <Typography variant="h3" mr={3} style={{ color: "red" }}>{formatNumber(year_amount.toString())} 円 </Typography>
                        <Typography variant="h6" mr={1} style={{ color: "gray",fontWeight: "normal" }}> 単価 </Typography>
                        <Typography variant="h4" style={{ color: "red" }}> {formatDecimal(jpy_per_kwh.toString(),1)} 円/kWh</Typography>
                    </div>
                }
                <div className={'TableStyle'} style={{ height: 'calc(100vh - 350px)', width: '100%', overflowY: 'auto' }}>
                    {
                        modeFlag &&
                        <>
                            {unitFlag ===1 && 
                                <>
                                <table className={'tableFixHead'} style={{ width: '100%',height:"fit-content"}} cellPadding={0} cellSpacing={0}>
                                    <thead>
                                        <YearHeader year={dateValue.getFullYear()} mode={'calendarYear'}></YearHeader >
                                    </thead>
                                    {
                                        gaex5List.map((row) => {
                                            return (
                                                <>
                                                    <YearContent yearMaxKwh={yearMaxKwh} monthMaxKwh={monthMaxKwh} monthKwhValue={getMonthFinalMaxKwh} dayKwhValue={getDayFinalMaxKwh}  calendarItem={row} mode={'kwh'}></YearContent>
                                                </>
                                            );
                                        })
                                    }
                                </table>
                                </>
                            }
                            {unitFlag === 2 &&
                                <>
                                    <table className={'tableFixHead'} style={{ width: '100%',height:"fit-content" }} cellPadding={0} cellSpacing={0}>
                                        <thead>
                                            <YearHeader  year={dateValue.getFullYear()} mode={'calendarYear'} isKwMode={true}></YearHeader>
                                        </thead>
                                        {
                                            gaex5List.map((row) => {
                                                return (
                                                    <>
                                                        <YearContent yearMaxKw={Number(year_kw)} calendarItem={row} mode={'kw'} monthValue={monthMaxKw} dayValue={dayMaxKw}></YearContent>
                                                    </>
                                                );
                                            })
                                        }
                                    </table>
                                </>
                            }
                            {unitFlag === 3 && pulseType === "2" &&
                            <>
                                <table className={'tableFixHead'} style={{ width: '100%',height:"fit-content",borderSpacing:"0px",backgroundColor:"white"}}  cellSpacing="0" cellPadding="0">
                                    <thead>
                                        <YearHeader year={dateValue.getFullYear()} mode={'calendarYear'}></YearHeader >
                                    </thead>
                                    {
                                        gaex5List.map((row) => {
                                            return (
                                                <>
                                                    <YearContent monthsMaxAmount={monthsMaxAmount} calendarItem={row} amountValueYears={getMaxAmountByYear} monthKwhValue={getMonthFinalMaxKwh} dayKwhValue={getDayFinalMaxKwh} mode={'money'}></YearContent>
                                                </>
                                            );
                                        })
                                    }
                                </table>
                            </>
                            }
                        </>
                            
                    }
                    {unitFlag === 1 && !modeFlag &&
                        (<>
                            <ChartPanel chartModel={chartModel} />
                        </>)
                    }
                    {unitFlag === 2 && !modeFlag &&
                        (<>
                            <ChartPanel chartModel={chartLineModel} />
                        </>)
                    }
                    {unitFlag === 3 && !modeFlag && pulseType === "2" &&
                        (<>
                            <ChartPanel chartModel={moneyChartModel} />
                        </>)
                    }
                </div>
            </>
            }
            <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                <Button color="inherit" variant="contained" onClick={handleCancel}>戻る</Button>
            </Stack>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleFetchSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={getDevice} onComplete={(success, data, error) => success ? handleGetDevice(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onGetDevice: (id: string | null) => actionCreators.fetch(getDevice, `/master/gateways/get?id=${id}`, "GET", null),
    onChangePageSize: (pageSize: number) => actionCreators.setPageSize(pageSize),
    onShowDetailPage: (id: string) => push(RoutePath.Edit212 + `/${id}`),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－月表検索",
            body: "遠方監視管理一覧を検索できません。" + error,
        }),
    onErrorSearch: () =>
        actionCreators.showMessage({
            type: "error",
            title: "デマンド－月表検索",
            body: "デマンド－月表を検索できません。",
        }),
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Gaex5List as any);
