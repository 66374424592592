import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Autocomplete, Breakpoint, Button, CircularProgress, Collapse, Grid, IconButton, Stack, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OptionItem, PageData } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import palette from '../../../theme/palette';
import FetchHelper from "../../FetchHelper";
import SmartSolarGrid from './SmartSolarGrid';
import { formatDateTime, groupBy } from '../../../utils';
import TextSelector from '../../common/TextSelector';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CollapseItem from './CollapseItem';
import useResponsive from '../../../hooks/useResponsive';
import PaginationMobileCpn from '../../common/PaginationMobileCpn';
import { limitMasterCustomers, customerLabelName } from '../../../statics';

const pStyle1 = {
    padding: '0 4px',
    fontWeight: 'bold',
}

// ページサイズ
const PageSizeList = [
    { value: '10', label: '10', attribute: '', },
    { value: '50', label: '50', attribute: '', },
    { value: '100', label: '100', attribute: '', },
] as OptionItem[];

// -------------
// FETCH ID
const fetchId = "GATEWAY_LIST";
const formId = "GATEWAY_SEARCH_FORM";
const customerFetch = "GATEWAY_CUSTOMER_FETCH";

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
    customer_id: OptionItem | null;
    place: string;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
    customer_id: null,
    place: "",
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// -------------
// Props
export type SmartSolarListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const SmartSolarList = (props: SmartSolarListProps) => {
    const { onSearch,onError, } = props;
    const { handleSubmit, control, setValue, getValues } = useForm<SearchItemForm>({
        defaultValues: initialValue
    });
    const [openSearch, setOpenSearch] = React.useState(false);
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<any>([]);
    const [colState, setColState] = useState<any>();
    const [searchParams] = useSearchParams();
    const [customerList, setCustomerList] = React.useState<OptionItem[]>([]);
    const [keyword, setKeyword] = useState("");
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [latestUpdates, setLatestUpdates] = React.useState("");
    const [smallGridData,setSmallGridData] = React.useState<any>([]);
    const [dataGrid,setDataGrid] = React.useState<any>([]);
    const [pageSize, setPageSize] = React.useState(getValues('limit'));
    const disablePrevious = pageData.currPage === "1" || pageData.currPage === "0";
    const disableNext = pageData.currPage === pageData.totalPage || pageData.totalPage === "0";
    const isShowCustomerSearch = !(searchParams.get('path') === 'customer' && searchParams.get('customer_id'));
    const myParam = searchParams.get('id');
    const gridRef = useRef() as any;
    localStorage.setItem('lastActiveFormPath', RoutePath.SmartSolarList);
    localStorage.setItem('title', 'スマート太陽光');


    // reload data when change URL query strings
    const watchChangeUrl = searchParams.get('customer_id');
    const isMobile = useResponsive({ query: 'between', key: 'sm' as Breakpoint,start:320,end: 630});

    React.useEffect(()=> {
        if(!isMobile){
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(dataGrid);
            }
        }
    },[isMobile])

    React.useEffect(() => {
        if (!loadingFlag){
            const params = new Map<string, any>();
            params.set('limit', getValues('limit'));
            params.set("pulse_type",2);
            if (!isShowCustomerSearch)
                params.set('customer_id', searchParams.get('customer_id'));
            handleClearSeachCondition();
            setLoadingFlag(true);
            onSearch(params);
        }        
    }, [watchChangeUrl]);


    React.useEffect(() => {
        const search_key = keyword.split(" ");
        let data = dataGrid;
        for(const key of search_key){
            data = data.filter((item:any) =>{
                return item?.customer_name?.toLowerCase().includes(key?.toLowerCase()) || item?.id?.toLowerCase().includes(key?.toLowerCase()) || item?.device_name?.toLowerCase().includes(key?.toLowerCase())
            })
        }
        
        const groupedData = groupBy(data,"customer_id");
        const sortedArray = Object.entries(groupedData)
            .sort((a:any,b:any) => {
                const nameA = a[1][0].customer_name.toUpperCase();
                const nameB = b[1][0].customer_name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
        setSmallGridData(sortedArray);
    },[keyword,dataGrid])

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        let isError = false;

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.place) params.set("place", value.place);
        params.set("pulse_type",2);

        if (isShowCustomerSearch){
            if (value.customer_id && value.customer_id.value) 
                params.set("customer_id", value.customer_id.value);
        } else {
            params.set('customer_id', searchParams.get('customer_id'));
        }

        if (!isError) {
            setLoadingFlag(true);
            onSearch(params);
        }
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());
        // eslint-disable-next-line
    }, []);

    // 検索正常終了時
    const handleSuccess = (data: any) => {
        if (data.results) {
            const newValues:any = [...data.results];
            setDataGrid(newValues);
            const groupedData = groupBy(newValues,"customer_id");
            const sortedArray = Object.entries(groupedData)
            .sort((a:any,b:any) => {
                const nameA = a[1][0].customer_name.toUpperCase();
                const nameB = b[1][0].customer_name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            })
            setSmallGridData(sortedArray);
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(newValues);
            }
            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            setListData(() => [...[] as any]);
        }
        setLoadingFlag(false);
        setLatestUpdates(formatDateTime(new Date()));
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    React.useEffect(() => {
        // グラフ表示用データの更新
        // --------------------------------
        if (myParam) {
            listData.forEach((item: any) => {
                item.serial_no = myParam
            });
            setListData(() => [...listData])
        }
        else {
            listData.forEach((item: any) => {
                item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            });
            setListData(() => [...listData])
        }
        // eslint-disable-next-line
    }, [myParam]);

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("customer_id", null)
        setValue("place", "")
    };
    
    let navigate = useNavigate();
    const onShowDetailPage = (data: any, field: string) => {
        if (field === "smartDemand") {
            sessionStorage.setItem('references', RoutePath.SmartSolarList);
            navigate(`${RoutePath.Gaex3}?mainid=${data.id}&customer_id=${data.customer_id}`);
        }
        else if(field === "monthlyTable") {
            sessionStorage.setItem('references', RoutePath.SmartSolarList);
            navigate(`${RoutePath.Gaex2}?mainid=${data.id}&customer_id=${data.customer_id}`);
        }else if(field === "yearlyTable") {
            sessionStorage.setItem('references', RoutePath.SmartSolarList);
            navigate(`${RoutePath.Gaex5}?mainid=${data.id}&customer_id=${data.customer_id}`);
        }
    }

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
    }

    const handleFetchCustomerSuccess = (data: any) => {
        if (data?.results) setCustomerList(data?.results.map((x: any) => { return { value: x.id.toString(), label: x.name, attribute: '' } as OptionItem }));
    };

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    return (
        <>
            <FetchHelper fetchId={customerFetch}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomerSuccess(data) : handleError(success, data, error)
                }}
            />
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                {
                    (!isMobile) &&
                    <>
                        <form onSubmit={handleSubmit(handleSearchSubmit)}>
                            <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                                <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                                    <div style={{ display: 'inline-block' }}>
                                        <Typography variant="h6" >検索条件</Typography>
                                    </div>
                                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                        {
                                            openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                        }
                                    </div>
                                </div>
                                <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                                    <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems={"end"}
                                            spacing={1}
                                            direction="row"
                                        >
                                            {isShowCustomerSearch &&
                                                <Grid item minWidth="20rem">
                                                    <Controller
                                                        control={control}
                                                        name="customer_id"
                                                        render={({ field: { value } }) => (
                                                            <Autocomplete
                                                                autoHighlight
                                                                value={value}
                                                                options={customerList}
                                                                fullWidth
                                                                renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" style={{ background: palette.background.paper }} />}
                                                                onChange={(_, data) => setValue("customer_id", data)}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item minWidth="20rem">
                                                <Controller
                                                    name="place"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            label="設置場所"
                                                            size="small"
                                                            style={{ background: palette.background.paper }}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                            </Grid>
                                            <Grid item >
                                                <Button variant="contained" onClick={handleSearch}>
                                                    検索
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </div>
                            </Stack>
                        </form>
                        <div style={{ marginBottom: "10px" }}>
                            <Stack direction="row">
                                <Typography variant="body2" style={{ textAlign: "center", lineHeight: "40px" }}>
                                最終更新日時&emsp;{latestUpdates}
                                </Typography>
                                <div style={{ marginInlineStart: 'auto' }}>
                                    <Stack mb={1} justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                autoComplete="searchStr"
                                                name="keyword"
                                                type="text"
                                                label="フィルター"
                                                size="small"
                                                value={keyword}
                                                onChange={e => handleChangeKeyword(e.target.value)}
                                                />
                                            </Grid>
                                        <Button variant="outlined" size="small" onClick={() => handleChangeKeyword("")}>クリア</Button>
                                    </Stack>
                                </div>
                            </Stack>
                        </div>
                        <div>
                            <SmartSolarGrid
                                ref={gridRef}
                                colState={colState}
                                pageSize={getValues('limit')}
                                pageData={pageData}
                                onChangeSearchCondition={handleChangePageData}
                                onShowDetails={onShowDetailPage}
                                keyword={keyword}
                                openSearch={openSearch}
                            />
                        </div>
                    </>
                }
                {
                    isMobile &&
                    <>
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Stack paddingTop={1} mb={1} justifyContent="flex-end" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"                                            
                                        value={keyword}
                                        onChange={e => handleChangeKeyword(e.target.value)}
                                        />
                                    </Grid>
                                <Button variant="outlined" size="small" sx={{paddingTop:"5px",paddingBottom:"5px"}} onClick={() => handleChangeKeyword("")}>クリア</Button>
                            </Stack>
                        </div>
                        <Table>
                            <div>
                                <TableRow style={{display:"flex",margin:"auto",border:"1px solid grey"}}>
                                    <TableCell style={{width:"100%",fontWeight:"bold"}}>
                                        {`${customerLabelName}名`}
                                    </TableCell>
                                </TableRow>
                            </div>
                            {
                                smallGridData.length > 0 &&
                                <>
                                    <Table style={{border:"1px solid grey",borderTop:"0px"}}>
                                        <div style={{overflow:"auto",maxHeight:"calc(100vh - 252px)",width:"100%"}}>
                                            {smallGridData.map((item:any,index:any) => {
                                                return <> 
                                                    <CollapseItem id={item[0]} data={item[1]} index={index} onShowDetails={onShowDetailPage} />
                                                </>
                                            })}
                                        </div>
                                    </Table>
                                </>
                            }
                            <PaginationMobileCpn pageData={pageData} onChangeSearchCondition={handleChangePageData} limit={pageSize} />
                        </Table>
                    </>
                }
                
            </div>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/master/gateways/get`, "GET", params),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "スマート太陽光",
            body: "スマート太陽光一覧を検索できません。" + error,
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SmartSolarList as any);
