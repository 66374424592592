import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { DemandChangeStartEndModel } from "../../../models";

const demandChangeStartEndEdit = [
    {
        value: '0',
        label: '定期通報 停止',
        attribute: '',
    },
    {
        value: '2',
        label: '定期通報 開始',
        attribute: '',
    }
]
export type DemandChangeStartEndEditFormProps = {
    mainId: string,
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const DemandChangeStartEndEdit = (props: DemandChangeStartEndEditFormProps) => {
    const { mainId, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: DemandChangeStartEndModel = {
        mainid: mainId,
        statusCode: demandChangeStartEndEdit[0].value,
    }

    const { handleSubmit, formState: { errors }, setValue, control } = useForm<DemandChangeStartEndModel>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    // Submitイベント
    const handleEditSubmit = (value: DemandChangeStartEndModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: DemandChangeStartEndModel) => {
        let request = {
            mainid: formValue.mainid,
            status_code: formValue.statusCode,
        } as any;

        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleSelectOption = (e: any, option: any, name: any) => {
        // 値変更
        if (option) {
            setValue(name, option);
        }
    };

    return (
        <>
            <div style={{ width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)} style={{ width: '90%', margin: 'auto' }}>
                    <Stack spacing={3}>
                        <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} paddingTop={"10px"}>
                            <Grid container spacing={1} direction="row" >
                                <FormControl fullWidth
                                    size="small" error={!!errors?.statusCode}>
                                    <InputLabel>デマンド開始終了変更</InputLabel>
                                    <Controller
                                        name="statusCode"
                                        control={control}
                                        rules={{ required: '必須項目です。入力してください。' }}
                                        render={({ field } : any) => (
                                            <><Select
                                                {...field}
                                                label="デマンド開始終了変更"
                                                error={!!errors?.statusCode}
                                                size="small"
                                            >
                                                {demandChangeStartEndEdit.map(item => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                                {
                                                    !!errors?.statusCode &&
                                                    <FormHelperText style={{ color: 'red' }}>{errors?.statusCode?.message}</FormHelperText>
                                                }</>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                        <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        <Button variant="contained" type="submit" >保存</Button>
                    </Stack>
                </form >
            </div>
        </>
    );
};
export default DemandChangeStartEndEdit;


