import { Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import * as React from 'react';
import { useNavigate } from 'react-router';
import { Path as RoutePath } from "../../../path";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { PollingMenuOptions, customerLabelName } from "../../../statics";
import { deviceBackgroundStyles, deviceContentStyles } from "../../styles";
import { connect } from "react-redux";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import FetchHelper from "../../FetchHelper";
import { Customer } from "../../../models";

const menusFetchId = 'MENUS_FETCH';

type params = {
    mainid: string,
    deviceInformation?: any,
    title: string,
    isDisplayDeviceInfo?: boolean,
    isShowMenuButton?:boolean,
    weatherFlag?:boolean|false,
    titleFlag?:boolean|false,
}

export type DeviceInformationProps = params & ReturnType<typeof mapStateToProps> 
& typeof mapDispatchToProps;

export const DeviceInformation = (props: DeviceInformationProps) => {
    const { mainid, deviceInformation, title, isDisplayDeviceInfo,isShowMenuButton, fetchStateMenu, onGetAuthRole,weatherFlag,titleFlag} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenuOptions = Boolean(anchorEl);

    let navigate = useNavigate();

    const [authRole, setAuthRole] = React.useState<any>();
    const [pollingMenuDisplay, setPollingMenuDisplay] = React.useState<any[]>([]);

    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    React.useEffect(() => {
        if(authRole !== undefined && deviceInformation !== undefined)
            handleShowLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[authRole, deviceInformation])

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data)
            setAuthRole(data);
    }

    const checkShowLink = (roleName: string, authRole: any, data: any) => {
        let isShow = false;
        if (authRole.auth_role_map){
            if (authRole.auth_role_map[roleName + '-all']){
                isShow = true;
            } else {
                if (authRole.auth_role_map[roleName + '-belong']){
                    if (authRole.company_id == data.customer_id)
                        isShow = true;
                }
                if(authRole.auth_role_map[roleName + '-group']){
                    if(authRole.company_group_id == data.group_id){
                        isShow = true;
                    }
                }
                if (authRole.auth_role_map[roleName + '-manage']){
                    if (authRole.users_customer_info_array?.filter((e: Customer) => e.id == data.customer_id).length > 0)
                        isShow = true;
                }
            }
        }
        return isShow
    }

    const handleShowLink = () => {
        setPollingMenuDisplay([]);
        // 状態設定値
        if (authRole.auth_role_map['GW-gwstatus-view'])
            setPollingMenuDisplay([PollingMenuOptions[0]]);
        // 装置編集
        if (authRole.auth_role_map['GW-gwcrud-edit'])
            setPollingMenuDisplay(
                prevArray => [
                    ...prevArray,
                    PollingMenuOptions[1]
                ]
            );
        // 接点編集
        if (checkShowLink('GW-adview', authRole, deviceInformation))
            setPollingMenuDisplay(
                prevArray => [
                    ...prevArray,
                    PollingMenuOptions[2]
                ]
            );
        // 漏電編集
        if (checkShowLink('GW-ebecview', authRole, deviceInformation))
            setPollingMenuDisplay(
                prevArray => [
                    ...prevArray,
                    PollingMenuOptions[3]
                ]
            );
        // デマンド編集
        if (checkShowLink('GW-demandview', authRole, deviceInformation))
            setPollingMenuDisplay(
                prevArray => [
                    ...prevArray,
                    PollingMenuOptions[4]
                ]
            );
        // 太陽光編集
        if (checkShowLink('GW-solarview', authRole, deviceInformation))
            setPollingMenuDisplay(
                prevArray => [
                    ...prevArray,
                    PollingMenuOptions[5]
                ]
            );
        // 通報編集
        if (checkShowLink('GW-notifyview', authRole, deviceInformation))
            setPollingMenuDisplay(
                prevArray => [
                    ...prevArray,
                    PollingMenuOptions[6]
                ]
            );
        // 帳票配信
        if(checkShowLink("GW-demandreportview",authRole, deviceInformation))
            {
                setPollingMenuDisplay(
                    prevArray => [
                        ...prevArray,
                        PollingMenuOptions[7]
                    ]
                );
            }
            
    }

    const handleError = (success: boolean, data: any, error: any) => {
        console.log('>>> handleError');
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRedirectMenu = (val: string) => {
        switch (val) {
            case "pollingTopicDetail":
                navigate(RoutePath.PollingTopicDetail + `/${mainid}`);
                break;
            case "editDevice":
                navigate(RoutePath.EditDevice + `/${mainid}`);
                break;
            case "contact":
                navigate(RoutePath.PointsEdit + `?mainid=${mainid}`);
                break;
            case "leaks":
                navigate(RoutePath.LeaksEdit + `?mainid=${mainid}`);
                break;
            case "demand":
                navigate(RoutePath.DemandsEdit + `?mainid=${mainid}`);
                break;
            case "solar":
                navigate(RoutePath.SolarsEdit + `?mainid=${mainid}`);
                break;
            case "notification":
                navigate(RoutePath.NotificationsEdit + `?mainid=${mainid}`);
                break;
            case "registReport":
                navigate(RoutePath.ReportDemand + `?mainid=${mainid}`);
                break;
        }
    }

    return (
        <div>
            <div>
            <Stack direction='row' justifyContent="flex-start" spacing={1.5} alignItems='center'>
                    {!titleFlag && <Typography variant="h5">{title}</Typography>}
                    { isShowMenuButton && <IconButton style={{ borderRadius: '0', border: '2px solid gray', color: 'gray', padding: '2px 2px', width: '28px', height: '26px' }} onClick={handleClick}>
                        <MoreHorizIcon viewBox="24px 23px 0 0" />
                    </IconButton> 
                    }
                </Stack>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenuOptions}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {pollingMenuDisplay.map(option => (
                        <MenuItem key={option.value} onClick={() => handleRedirectMenu(option.value)}>{option.label}</MenuItem>
                    ))}
                </Menu>
            
            </div>
            {
                isDisplayDeviceInfo && <Grid
                    container
                    direction="row"
                    mt={2}
                >   
                    <Grid item minWidth="22rem" width="25%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                        <Grid xs={3.5} style={deviceBackgroundStyles}>
                            <Typography style={deviceContentStyles}>{`${customerLabelName}名`}</Typography>
                        </Grid>
                        <Grid xs={8.5}>
                            <Typography style={deviceContentStyles}>{deviceInformation?.customer_name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item minWidth="22rem" width="25%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                        <Grid xs={3.5} style={deviceBackgroundStyles}>
                            <Typography style={deviceContentStyles}>設置場所</Typography>
                        </Grid>
                        <Grid xs={8.5}>
                            <Typography style={deviceContentStyles}>{deviceInformation?.place}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item minWidth="22rem" width="25%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                        <Grid xs={3.5} style={deviceBackgroundStyles}>
                            <Typography style={deviceContentStyles}>装置ID</Typography>
                        </Grid>
                        <Grid xs={8.5}>
                            <Typography style={deviceContentStyles}>{deviceInformation?.id}</Typography>
                        </Grid>
                    </Grid>
                    {weatherFlag &&
                        <Grid item minWidth="22rem" width="25%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                            <Grid xs={3.5} style={deviceBackgroundStyles}>
                                <Typography style={deviceContentStyles}>住所</Typography>
                            </Grid>
                            <Grid xs={8.5}>
                                <Typography style={deviceContentStyles}>{deviceInformation?.address}</Typography>
                            </Grid>
                        </Grid>
                    }
                    {
                    (weatherFlag === undefined || !weatherFlag)
                        &&
                    <Grid item minWidth="22rem" width="25%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                        <Grid xs={3.5} style={deviceBackgroundStyles}>
                            <Typography style={deviceContentStyles}>IPアドレス</Typography>
                        </Grid>
                        <Grid xs={8.5}>
                            <Typography style={deviceContentStyles}>{deviceInformation?.ip_address}</Typography>
                        </Grid>
                    </Grid>
                    }
                    
                </Grid>
            }
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceInformation);