// -------------
import CachedIcon from '@mui/icons-material/Cached';
import { Button, CircularProgress, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from 'react-router';
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import FetchHelper from '../../FetchHelper';
import { deviceBackgroundStyles, deviceContentStyles } from '../../styles';
import WeathersDayContent from './WeathersDayContent';
import WeathersDayHeader from './WeathersDayHeader';

const fetchId = "WEATHER_DATA";

// Props
export type WeathersDataProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;
export const Weathers = (props: WeathersDataProps) => {
    // #region props
    localStorage.setItem('lastActiveFormPath', RoutePath.Weather);
    localStorage.setItem('title', '気象データ');
    let navigate = useNavigate();
    let location = useLocation();
    const { fetchState,onSearch } = props;
    const urlParams = new URLSearchParams(window.location.search);
    const weather_id = urlParams.get('weather_id');
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [dateSelect,setDateSelect] = React.useState(new Date());
    const [dateValue,setDateValue] = React.useState(new Date());
    const [device,setDevice] = React.useState<any>();
    const [dataWeathers,setDataWeather] = React.useState<any>(null);
    const [temperatureList,setTemperatureList] = React.useState<any>([]);
    const [observationPoint] = React.useState<any>(location.state.observationPoint);
    // #endregion props

    // #regeion useEffect
    useEffect(()=> {
        const date = new Date();
        const dateParam = {day:date.getDate(), month:date.getMonth()+1, year:date.getFullYear()}
        onSearch(weather_id,dateParam);
    },[])

    // #endregion useEff

    // #region handleEvent
    const handleRefresh = () => {
        if (!isNaN(dateSelect.getTime())) {
            setLoadingFlag(true);
            setDateValue(dateSelect);
            const daydata ={day:dateSelect.getDate(), month:dateSelect.getMonth()+1, year:dateSelect.getFullYear()}
            onSearch(weather_id,daydata);
            setTemperatureList([]);
        }
    }

    const handleSuccess = (data:any) => {
        const arrayTemperture:any = [...Array(24)].fill(0)
        const newDataWeather:any =[{day:dateValue.getDate(),hours_data:[]}]
            for(let i =0;i<24;i++){
                newDataWeather[0].hours_data.push({
                    hour: i,
                    source_type: "-",
                    condition: "-",
                    solar_radiation: "-",
                    solar_energy: "-",
                    solar_ratio: "-",
                    precipitation: "-",
                    temperature: "-",
                    humidity: "-",
                    wind_speed: "-",
                    pressure_sealevel: "-",
                    snowfall: "-"
                })
            }
        if(data && data.results.length > 0){
            const item = data?.results[0];
            setDevice({customer_name:item.customer_name,place:item.place,id:item.mainid,address:item.address_in,ip_address:item.ip_address});
            if(item && item.days_data){
                const days_data = item.days_data;
                if(days_data[0].hours_data){
                    const hours_data = days_data[0].hours_data;
                    if(hours_data.length >0){
                        hours_data.forEach((item:any)=>{
                            newDataWeather[0].hours_data[item.hour] = item;
                            arrayTemperture[item.hour] = item.temperature;
                        })
                    }
                }
            }
            if(newDataWeather)
                setDataWeather(newDataWeather[0]);
            setTemperatureList(arrayTemperture);
        }else{
            if(newDataWeather)
                setDataWeather(newDataWeather[0]);
            setTemperatureList(arrayTemperture);
        }
        setLoadingFlag(false);
    }

    const handleError = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    // キャンセル
    const handleCancel = () => {
        sessionStorage.removeItem('references');
        let path = RoutePath.Weather;
        if(path)
            navigate(path, { replace: true });
    }

    // #endregion handleEvent

    return(
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}

            <Grid container direction="row" mt={2} mb={2}>
                <Grid minWidth="22rem" width="50%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                    <Grid xs={4} style={deviceBackgroundStyles}>
                        <Typography style={deviceContentStyles}>観測地点名</Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Typography style={deviceContentStyles}>{observationPoint.ob_point_name}</Typography>
                    </Grid>
                </Grid>
                <Grid minWidth="22rem" width="50%" style={{ display: 'flex', border: '0.5px solid #b9ddff' }}>
                    <Grid xs={4} style={deviceBackgroundStyles}>
                        <Typography style={deviceContentStyles}>住所</Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Typography style={deviceContentStyles}>{observationPoint.address_in}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Stack direction="row">
                <div style={{ width: '100%', display: 'flex', marginTop: '10px', alignItems: 'center' }}>
                    <div style={{ marginInlineStart: 'auto', pointerEvents: fetchState.loading ? "none" : "auto" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="YYYY.MM.DD"
                                views={["year", "month","day"]}
                                openTo="day"
                                renderInput={(props: any) => (
                                    <TextField
                                        size="small"
                                        style={{ width: '150px'}}
                                        sx={{
                                            fieldset: {
                                                border: "1px solid rgba(24, 144, 255, 0.5)",
                                                borderWidth: "1px 1px 1px 1px",
                                                zIndex: "1",
                                                borderRadius: "10px solid"
                                            }
                                        }}
                                        inputProps={{
                                            readOnly:true
                                        }}
                                        {...props} />
                                )}
                                value={dateSelect}
                                onChange={(newValue: any) => {
                                    if(newValue != null)
                                        setDateSelect(newValue.$d);
                                }}
                                disableMaskedInput={false}
                            />
                        </LocalizationProvider>
                        <IconButton aria-label="edit" >
                            <CachedIcon fontSize="medium" style={{ color: '#1890ff' }} onClick={handleRefresh} />
                        </IconButton>
                    </div>
                </div>
            </Stack>

            <div className={'TableStyle'} style={{ height: 'calc(100vh - 260px)', width: '100%', overflowY: 'auto',marginTop:"10px" }}>
                <table className={'tableFixHead'} style={{ width: '100%' }}>
                {dataWeathers && temperatureList.length > 0 &&
                    <>
                        <thead>
                            <WeathersDayHeader date={dateValue} data={dataWeathers} ></WeathersDayHeader>
                        </thead>
                        <tbody>
                            <WeathersDayContent data={dataWeathers} temperatureList={temperatureList}></WeathersDayContent>                           
                        </tbody>
                    </>
                }      
                </table>
            </div>

            <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2, mr: 2 }}>
                <Button color="inherit" variant="contained" onClick={handleCancel}>戻る</Button>
            </Stack>
            
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onSearch: (weather_id:any,date:any) => actionCreators.fetch(fetchId, `/telemetry/get?code=DATA&type=WEATHER&weather_id=${weather_id}&year=${date.year}&month=${date.month}&day=${date.day}`, "GET", null), 
    onSetSearchParams: (params: string) => actionCreators.setSearchParams(params),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Weathers as any);