import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FlareIcon from '@mui/icons-material/Flare';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SmsIcon from '@mui/icons-material/Sms';
import { Breakpoint, Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { Browser, registerLicense } from '@syncfusion/ej2-base';
import {
    AccumulationChartComponent,
    AccumulationDataLabel,
    AccumulationLegend,
    AccumulationSeriesCollectionDirective, AccumulationSeriesDirective,
    AccumulationTooltip,
    Category,
    ChartAnnotation,
    ChartComponent,
    ColumnSeries,
    DataLabel,
    DateTime,
    Highlight,
    Inject,
    Legend,
    LineSeries,
    ScatterSeries,
    SeriesCollectionDirective, SeriesDirective,
    Tooltip as ToolTipDashBoard
} from '@syncfusion/ej2-react-charts';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../node_modules/@syncfusion/ej2-react-layouts/styles/material.css";
import useResponsive from '../../hooks/useResponsive';
import { viewSettingOption } from '../../layouts/DashboardNavbar';
import { Path as RoutePath } from "../../path";
import { customerLabelName } from '../../statics';
import {
    ApplicationState,
    FetchState,
    actionCreators,
    selectors,
} from "../../store";
import FetchHelper from '../FetchHelper';
import "./dashboard.css";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { ReactComponent as UmbrellaIcon } from "../../assets/umbrella.svg";
import { ReactComponent as CloudIcon } from "../../assets/cloud.svg";
registerLicense(process.env.REACT_APP_LICENSE_SYNCFUSION!);

interface DashboardInfo {
  name: string;
}

interface DashboardResponse {
  Dashboard: DashboardInfo[];
}

export type DashboardProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const GET_DATA_DASHBOARD = "GET_DATA_DASHBOARD";

const tableCellStyleSmartPhone = {
    paddingLeft:"5px",paddingRight:"5px",paddingTop:"8px",paddingBottom:"8px",
}

const tableCellStyle = {
    paddingTop:"8px",paddingBottom:"8px"
}

export const Dashboard = (props: DashboardProps) => {
    const { user_id, data, isRefresh, settingViewStore, onDateChange, onGet,} = props;
    let dispatch = useDispatch();
    localStorage.setItem('lastActiveFormPath', RoutePath.Dashboard);
    localStorage.setItem('title', `ダッシュボード@Dashboard`);
    const settingView = localStorage.getItem("settingView");
    const isTablet = useResponsive({query:"between", start:300,end:730,key:"md" as Breakpoint})
    const [loadingFlag,setLoadingFlag] = useState<boolean>(false);
    const [openDialog,setOpenDialog] = useState<boolean>(false);
    const [titleDiaglog,setTitleDialog] = useState<string>();
    const [count,setCount] = useState<any>();
    const [dataShow,setDataShow] = useState<any>([]);
    const [openMonitorDialog,setOpenMonitorDialog] = useState<boolean>(false);
    const [dataPieChart,setDataPieChart] = useState<any>(null);
    const [dataSplineChart,setDataSplineChart] = useState<any>(null);
    const [dataScatterChart,setdataScatterChart] = useState<any>(null);
    const [dataColumnChart,setDataColumnChart] = useState<any>([]);
    const [dataGASolarMonthKwh,setDataGASolarMonthKwh] = useState<any>(null);
    const [dataTelemetryBreakingNew,setDataTelemetryBreakingNew] = useState<any>(null);
    const [dataDemandMonitoring,setDataMonitoring] = useState<any>(null);
    const [dataExceededMonth,setDataExceededMonth] = useState<any>(null);
    const [openTodayUsageDialog,setOpenTodayUsageDialog] = useState<boolean>(false);
    const [isFirst, setIsFirst] = useState<any>(true);
    const [openTooltip,setOpenTooltip] = useState<any>(false);
    const [isNullValue,setNullValue]  = useState<any>(true);
    const [reDrawDashboard, setReDrawDashboard] = useState<number>(0); 
    const [isFirstLoginBrowser,setIsFirstLoginBrowser] = useState(!settingViewStore);
    const [currentDay] = useState(new Date());
    const [dataWeather,setDataWeather] = useState<any>([]);
    let restoreModel = [];
    let dashboardObj = useRef<any>();
    let tooltipInstance1 = useRef<any>();
    let tooltipInstance2 = useRef<any>();
    let tooltipInstance3 = useRef<any>();
    let tooltipInstance4 = useRef<any>();
    let tooltipInstance5 = useRef<any>();
    let tooltipInstance6 = useRef<any>();
    let tooltipInstance7 = useRef<any>();
    let tooltipInstance8 = useRef<any>();
    let tooltipInstance9 = useRef<any>();

    // #region useEffect
    useEffect(() => {
        setLoadingFlag(true);
        refreshDashBoard();
        onGet();
        document.addEventListener("click", (args) => {
            const isDashBoard = localStorage.getItem("title");
            if(isDashBoard != "ダッシュボード@Dashboard"){
                document.removeEventListener("click",() => {});
            }else{
                const parent1 = document.querySelector('#toolTipComponent1');
                const parent2 = document.querySelector('#toolTipComponent2');
                const parent3 = document.querySelector('#toolTipComponent3');
                const parent4 = document.querySelector('#toolTipComponent4');
                const parent5 = document.querySelector('#toolTipComponent5');
                const parent6 = document.querySelector('#toolTipComponent6');
                const parent7 = document.querySelector('#toolTipComponent7');
                const parent8 = document.querySelector('#toolTipComponent8');
                const parent9 = document.querySelector('#toolTipComponent9');
                if (args && args.target) {
                    if (!parent1?.contains(args.target as any)) {
                        if (tooltipInstance1) {
                            tooltipInstance1.current?.close();
                        }
                    }
                    if (!parent2?.contains(args.target as any)) {
                        if (tooltipInstance2) {
                            tooltipInstance2.current?.close();
                        }
                    }
                    if (!parent3?.contains(args.target as any)) {
                        if (tooltipInstance3) {
                            tooltipInstance3.current?.close();
                        }
                    }
                    if (!parent4?.contains(args.target as any)) {
                        if (tooltipInstance4) {
                            tooltipInstance4.current?.close();
                        }
                    }
                    if (!parent5?.contains(args.target as any)) {
                        if (tooltipInstance5) {
                            tooltipInstance5.current?.close();
                        }
                    }
                    if (!parent6?.contains(args.target as any)) {
                        if (tooltipInstance6) {
                            tooltipInstance6.current?.close();
                        }
                    }
                    if (!parent7?.contains(args.target as any)) {
                        if (tooltipInstance7) {
                            tooltipInstance7.current?.close();
                        }
                    }
                    if (!parent8?.contains(args.target as any)) {
                        if (tooltipInstance8) {
                            tooltipInstance8.current?.close();
                        }
                    }
                    if (!parent9?.contains(args.target as any)) {
                        if (tooltipInstance9) {
                            tooltipInstance9.current?.close();
                        }
                    }   
                }
            }
        });
    },[]);

    useEffect(() => {
        if(isRefresh){
            setLoadingFlag(true);
            onGet();
        }
    },[isRefresh]) 

    useEffect(() => {
        const positionDashboardData = localStorage.getItem("positionDashboardData");
        const list_data = dashboardObj.current.serialize();
        list_data.sort((a:any, b:any) => a.row - b.row)
        const settingViewList = [...settingViewStore];
        if(isFirstLoginBrowser == false && !isFirst){
            if(settingViewList?.length > 0){
                dashboardObj.current.removeAll();
                const list_data_position = positionDashboardData && !isTablet ? JSON.parse(positionDashboardData) : [];
                const list_data_position_by_user_id = list_data_position.find((item:any) => item?.user_id == user_id);
                list_data_position_by_user_id?.settingView?.sort((a:any,b:any) => a.row - b.row)
                if(list_data_position_by_user_id && list_data_position_by_user_id.settingView.length > 0 && list_data_position_by_user_id.settingView.length >= settingViewStore.length){
                    for(const position_data of list_data_position_by_user_id.settingView){
                        settingViewList.forEach((dashboardItem:any) => {
                            if(position_data.id == "one" && dashboardItem.value == "速報" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"one", content: () => {return dashboard1({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }
                            else if(position_data.id == "two" && dashboardItem.value == "死活監視" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"two", content: () => {return dashboard2({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }else if(position_data.id == "three" && dashboardItem.value == "装置別当日発電量" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"three", content: () => {return dashboard3({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }else if(position_data.id == "four" && dashboardItem.value == "現在の電力使用率" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"four", content: () => {return dashboard4({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }else if(position_data.id == "five" && dashboardItem.value == "当月発電実績" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"five", content: () => {return dashboard5({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }else if(position_data.id == "six" && dashboardItem.value == "デマンド監視" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"six", content: () => {return dashboard6({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }else if(position_data.id == "seven" && dashboardItem.value == "今月のデマンド超過" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"seven", content: () => {return dashboard7({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }else if(position_data.id == "eight" && dashboardItem.value == "太陽光発電状況" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"eight", content: () => {return dashboard8({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }
                            else if(position_data.id == "nine" && dashboardItem.value == "天気情報" && dashboardItem["hiddenFlag"] == false){
                                dashboardObj.current.addPanel({...position_data, id:"nine", content: () => {return dashboard9({sizeX:position_data.sizeX,sizeY:position_data.sizeY,row:position_data.row,col:position_data.col})}},)
                            }                            
                        })
                    }
                }else{
                    let row = 0;
                    settingViewList.sort((a:any,b:any) => a.attribute - b.attribute).forEach((dashboardItem:any) => {
                        if(dashboardItem.value == "速報" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"one",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 0, content: () => {return dashboard1({sizeX:3,sizeY:2,row:0,col:0})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"one",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard1({sizeX:1,sizeY:1,row:row,col:0})} },)
                                row++;
                            }
                        }else if(dashboardItem.value == "死活監視" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"two",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 3, content: () => {return dashboard2({sizeX:3,sizeY:2,row:0,col:0})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"two",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard2({sizeX:1,sizeY:1,row:row,col:0})}},)
                                row++;
                            }
                        }else if(dashboardItem.value == "装置別当日発電量" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"three",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 6, content: () => {return dashboard3({sizeX:3,sizeY:2,row:0,col:6})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"three",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard3({sizeX:1,sizeY:2,row:row,col:0})}},)
                                row += 2;
                            }
                        }else if(dashboardItem.value == "現在の電力使用率" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"four",'sizeX': 6, 'sizeY': 2, 'row': 2, 'col': 0, content: () => {return dashboard4({sizeX:6,sizeY:2,row:2,col:0})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"four",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard4({sizeX:1,sizeY:2,row:row,col:0})}},)
                                row += 2;
                            }
                        }else if(dashboardItem.value == "当月発電実績" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"five",'sizeX': 3, 'sizeY': 2, 'row': 2, 'col': 6, content: () => {return dashboard5({sizeX:3,sizeY:2,row:2,col:6})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"five",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard5({sizeX:1,sizeY:2,row:row,col:0})}},)
                            }
                        }else if(dashboardItem.value == "デマンド監視" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"six",'sizeX': 3, 'sizeY': 2, 'row': 4, 'col': 0, content: () => {return dashboard6({sizeX:3,sizeY:2,row:4,col:0})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"six",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard6({sizeX:3,sizeY:2,row:4,col:0})}},)
                                row += 1;
                            }
                        }else if(dashboardItem.value == "今月のデマンド超過" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"seven",'sizeX': 6, 'sizeY': 2, 'row': 4, 'col': 3, content: () => {return dashboard7({sizeX:6,sizeY:2,row:4,col:3})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"seven",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard7({sizeX:4,sizeY:2,row:4,col:4})}},)
                                row += 2;
                            }
                        }
                        else if(dashboardItem.value == "太陽光発電状況" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"eight",'sizeX': 6, 'sizeY': 2, 'row': 6, 'col': 0, content: () => {return dashboard8({sizeX:6,sizeY:2,row:6,col:0})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"eight",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard8({sizeX:1,sizeY:2,row:row,col:0})}},)
                                row += 2;
                            }
                        }                        
                        else if(dashboardItem.value == "天気情報" && dashboardItem["hiddenFlag"] == false){
                            if(!isTablet){
                                dashboardObj.current.addPanel({id:"nine",'sizeX': 3, 'sizeY': 2, 'row': 6, 'col': 6, content: () => {return dashboard9({sizeX:3,sizeY:2,row:6,col:6})}},)
                            }else{
                                dashboardObj.current.addPanel({id:"nine",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard9({sizeX:1,sizeY:1,row:row,col:0})}},)
                                row += 2;
                            }
                        }
                    })
                    if(isTablet){
                        dashboardObj.current.addPanel({id:"element_delete",'sizeX': 1, 'sizeY': 2, 'row': 6, 'col': 0, content: '<div class="content">0</div>'},)
                        dashboardObj.current.removePanel("element_delete");
                    }
                }
            }
        }

        // Update positionDashboardData when add new panel
        restoreModel = dashboardObj.current.serialize();
        const list_data_position_object = positionDashboardData && !isTablet ? JSON.parse(positionDashboardData) : [];
        const index_user_id = list_data_position_object.findIndex((obj:any) => obj.user_id == user_id);
        let list_data_position = list_data_position_object?.find((item:any) => item?.user_id == user_id);
        if(list_data_position && !isTablet && restoreModel.length > list_data_position.settingView?.length){
            list_data_position_object[index_user_id].settingView = restoreModel;
            localStorage.setItem("positionDashboardData",JSON.stringify(list_data_position_object));
        }
    },[settingViewStore]);

    useEffect(() => {
        if(reDrawDashboard > 0){
            if(isFirst){
                dashboardObj.current.removeAll();
                if(settingView)
                {
                    const data = JSON.parse(settingView);
                    const show_setting_object = data.find((item:any) => item.user_id == user_id);
                    if(show_setting_object){
                        const show_setting = show_setting_object.settingView;
                        if(show_setting_object && show_setting.length > 0){
                            const positionDashboardData = localStorage.getItem("positionDashboardData");
                            const list_data_position_object = positionDashboardData && !isTablet ? JSON.parse(positionDashboardData) : [];
                            const list_data_position = list_data_position_object.find((item:any) => item?.user_id == user_id)?.settingView?.sort((a:any, b:any) => a.row - b.row);
                            if(list_data_position && list_data_position.length > 0 && list_data_position.length >= data.length){
                                for(const item_position of list_data_position){
                                    show_setting.forEach((item:any) =>{
                                        if(item.value == "速報" && item_position.id == "one"){
                                            dashboardObj.current.addPanel({...item_position, id:"one", content: () => {return dashboard1({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "死活監視" && item_position.id == "two"){
                                            dashboardObj.current.addPanel({...item_position, id:"two", content: () => {return dashboard2({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "装置別当日発電量" && item_position.id == "three"){
                                            dashboardObj.current.addPanel({...item_position, id:"three", content: () => {return dashboard3({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "現在の電力使用率" && item_position.id == "four"){
                                            dashboardObj.current.addPanel({...item_position, id:"four", content: () => {return dashboard4({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                        else if(item.value == "当月発電実績" && item_position.id == "five"){
                                            dashboardObj.current.addPanel({...item_position, id:"five", content: () => {return dashboard5({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "デマンド監視" && item_position.id == "six"){
                                            dashboardObj.current.addPanel({...item_position, id:"six", content: () => {return dashboard6({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                        else if(item.value == "今月のデマンド超過" && item_position.id == "seven"){
                                            dashboardObj.current.addPanel({...item_position, id:"seven", content: () => {return dashboard7({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                        else if(item.value == "太陽光発電状況" && item_position.id == "eight"){
                                            dashboardObj.current.addPanel({...item_position, id:"eight", content: () => {return dashboard8({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                        else if(item.value == "天気情報" && item_position.id == "nine"){
                                            dashboardObj.current.addPanel({...item_position, id:"nine", content: () => {return dashboard9({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                    })
                                }
                            }else{
                                let row = 0;
                                show_setting.sort((a:any,b:any) => a.attribute - b.attribute).forEach((item:any,index:any) =>{
                                switch(item.value){
                                    case "速報":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"one",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 0, content: () => {return dashboard1({sizeX:3,sizeY:2,row:0,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"one",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard1({sizeX:1,sizeY:1,row:row,col:0})} },)
                                            row++;
                                        }
                                        break;
                                    case "死活監視":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"two",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 3, content: () => {return dashboard2({sizeX:3,sizeY:2,row:0,col:3})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"two",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard2({sizeX:1,sizeY:1,row:row,col:0})}},)
                                            row++;
                                        }
                                        break;
                                    case "装置別当日発電量":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"three",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 6, content: () => {return dashboard3({sizeX:3,sizeY:2,row:0,col:6})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"three",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard3({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "現在の電力使用率":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"four",'sizeX': 6, 'sizeY': 2, 'row': 2, 'col': 0, content: () => {return dashboard4({sizeX:6,sizeY:2,row:2,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"four",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard4({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "当月発電実績":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"five",'sizeX': 3, 'sizeY': 2, 'row': 2, 'col': 6, content: () => {return dashboard5({sizeX:3,sizeY:2,row:2,col:6})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"five",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard5({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "デマンド監視":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"six",'sizeX': 3, 'sizeY': 2, 'row': 4, 'col': 0, content: () => {return dashboard6({sizeX:3,sizeY:2,row:4,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"six",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard6({sizeX:3,sizeY:2,row:4,col:0})}},)
                                            row += 1;
                                        }
                                        break;
                                    case "今月のデマンド超過":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"seven",'sizeX': 6, 'sizeY': 2, 'row': 4, 'col': 3, content: () => {return dashboard7({sizeX:6,sizeY:2,row:4,col:3})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"seven",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard7({sizeX:4,sizeY:2,row:4,col:4})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "太陽光発電状況":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"eight",'sizeX': 6, 'sizeY': 2, 'row': 6, 'col': 0, content: () => {return dashboard8({sizeX:6,sizeY:2,row:6,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"eight",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard8({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "天気情報":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"nine",'sizeX': 3, 'sizeY': 2, 'row': 6, 'col': 6, content: () => {return dashboard9({sizeX:3,sizeY:2,row:6,col:6})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"nine",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard9({sizeX:1,sizeY:1,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                }   
                                })
                                if(isTablet){
                                    dashboardObj.current.addPanel({id:"element_delete",'sizeX': 1, 'sizeY': 2, 'row': 6, 'col': 0, content: '<div class="content">0</div>'},)
                                    dashboardObj.current.removePanel("element_delete");
                                }
                            }
                        }else{
                        }
                    }else{
                        const data = JSON.parse(localStorage.getItem("settingView")!);
                        const object_data = {
                            user_id:user_id,
                            settingView:viewSettingOption.map((item:any) => {return {...item}}),
                        };
                        data.push(object_data)
                        localStorage.setItem("settingView",JSON.stringify(data));
                        dispatch({type:"SET_VIEW_SETTING_DASHBOARD",payload: {data:object_data.settingView}});
                    }
                }else{
                    const object_data = {
                        user_id:user_id,
                        settingView:viewSettingOption.map((item:any) => {return {...item}}),
                    }
                    const data = [object_data]
                    localStorage.setItem("settingView",JSON.stringify(data));
                    dispatch({type:"SET_VIEW_SETTING_DASHBOARD",payload: {data:object_data.settingView}});
                }
            }
            else{
                dashboardObj.current.removeAll();
                if(settingView)
                { 
                    const data = JSON.parse(settingView);
                    const show_setting_object = data.find((item:any) => item.user_id == user_id);
                    if(show_setting_object){
                        const show_setting = show_setting_object.settingView;
                        if(show_setting_object && show_setting.length > 0){
                            const positionDashboardData = localStorage.getItem("positionDashboardData");
                            const list_data_position_object = positionDashboardData && !isTablet ? JSON.parse(positionDashboardData) : [];
                            const list_data_position = list_data_position_object.find((item:any) => item?.user_id == user_id)?.settingView?.sort((a:any, b:any) => a.row - b.row);
                            if(list_data_position && list_data_position.length > 0 && list_data_position.length >= data.length){
                                for(const item_position of list_data_position){
                                    show_setting.forEach((item:any) =>{
                                        if(item.value == "速報" && item_position.id == "one"){
                                            dashboardObj.current.addPanel({...item_position, id:"one", content: () => {return dashboard1({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "死活監視" && item_position.id == "two"){
                                            dashboardObj.current.addPanel({...item_position, id:"two", content: () => {return dashboard2({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "装置別当日発電量" && item_position.id == "three"){
                                            dashboardObj.current.addPanel({...item_position, id:"three", content: () => {return dashboard3({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "現在の電力使用率" && item_position.id == "four"){
                                            dashboardObj.current.addPanel({...item_position, id:"four", content: () => {return dashboard4({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                        else if(item.value == "当月発電実績" && item_position.id == "five"){
                                            dashboardObj.current.addPanel({...item_position, id:"five", content: () => {return dashboard5({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "デマンド監視" && item_position.id == "six"){
                                            dashboardObj.current.addPanel({...item_position, id:"six", content: () => {return dashboard6({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                        else if(item.value == "今月のデマンド超過" && item_position.id == "seven"){
                                            dashboardObj.current.addPanel({...item_position, id:"seven", content: () => {return dashboard7({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                        else if(item.value == "太陽光発電状況" && item_position.id == "eight"){
                                            dashboardObj.current.addPanel({...item_position, id:"eight", content: () => {return dashboard8({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }else if(item.value == "天気情報" && item_position.id == "nine"){
                                            dashboardObj.current.addPanel({...item_position, id:"nine", content: () => {return dashboard9({sizeX:item_position.sizeX,sizeY:item_position.sizeY,row:item_position.row,col:item_position.col})}},)
                                        }
                                    })
                                }
                            }else{
                                let row = 0;
                                show_setting.sort((a:any,b:any) => a.attribute - b.attribute).forEach((item:any,index:any) =>{
                                switch(item.value){
                                    case "速報":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"one",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 0, content: () => {return dashboard1({sizeX:3,sizeY:2,row:0,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"one",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard1({sizeX:1,sizeY:1,row:row,col:0})} },)
                                            row++;
                                        }
                                        break;
                                    case "死活監視":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"two",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 3, content: () => {return dashboard2({sizeX:3,sizeY:2,row:0,col:3})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"two",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard2({sizeX:1,sizeY:1,row:row,col:0})}},)
                                            row++;
                                        }
                                        break;
                                    case "装置別当日発電量":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"three",'sizeX': 3, 'sizeY': 2, 'row': 0, 'col': 6, content: () => {return dashboard3({sizeX:3,sizeY:2,row:0,col:6})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"three",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard3({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "現在の電力使用率":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"four",'sizeX': 6, 'sizeY': 2, 'row': 2, 'col': 0, content: () => {return dashboard4({sizeX:6,sizeY:2,row:2,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"four",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard4({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "当月発電実績":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"five",'sizeX': 3, 'sizeY': 2, 'row': 2, 'col': 6, content: () => {return dashboard5({sizeX:3,sizeY:2,row:2,col:6})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"five",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard5({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "デマンド監視":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"six",'sizeX': 3, 'sizeY': 2, 'row': 4, 'col': 0, content: () => {return dashboard6({sizeX:3,sizeY:2,row:4,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"six",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard6({sizeX:3,sizeY:2,row:4,col:0})}},)
                                            row += 1;
                                        }
                                        break;
                                    case "今月のデマンド超過":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"seven",'sizeX': 6, 'sizeY': 2, 'row': 4, 'col': 3, content: () => {return dashboard7({sizeX:6,sizeY:2,row:4,col:3})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"seven",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard7({sizeX:4,sizeY:2,row:4,col:4})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "太陽光発電状況":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"eight",'sizeX': 6, 'sizeY': 2, 'row': 6, 'col': 0, content: () => {return dashboard8({sizeX:6,sizeY:2,row:6,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"eight",'sizeX': 1, 'sizeY': 2, 'row': row, 'col': 0, content: () => {return dashboard8({sizeX:1,sizeY:2,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                    case "天気情報":
                                        if(!isTablet){
                                            dashboardObj.current.addPanel({id:"nine",'sizeX': 3, 'sizeY': 2, 'row': 6, 'col': 0, content: () => {return dashboard9({sizeX:3,sizeY:2,row:6,col:0})}},)
                                        }else{
                                            dashboardObj.current.addPanel({id:"nine",'sizeX': 1, 'sizeY': 1, 'row': row, 'col': 0, content: () => {return dashboard9({sizeX:1,sizeY:1,row:row,col:0})}},)
                                            row += 2;
                                        }
                                        break;
                                }   
                                })
                                if(isTablet){
                                    dashboardObj.current.addPanel({id:"element_delete",'sizeX': 1, 'sizeY': 2, 'row': 6, 'col': 0, content: '<div class="content">0</div>'},)
                                    dashboardObj.current.removePanel("element_delete");
                                }
                            }
                        }else{
                        }
                    }
                }
            }

            const positionDashboardData = localStorage.getItem("positionDashboardData");
            restoreModel = dashboardObj.current.serialize();
            const list_data_position_object = positionDashboardData && !isTablet ? JSON.parse(positionDashboardData) : [];
            const index_user_id = list_data_position_object.findIndex((obj:any) => obj.user_id == user_id);
            let list_data_position = list_data_position_object?.find((item:any) => item?.user_id == user_id);
            if(list_data_position && !isTablet && restoreModel.length > list_data_position.settingView?.length){
                list_data_position_object[index_user_id].settingView = restoreModel;
                localStorage.setItem("positionDashboardData",JSON.stringify(list_data_position_object));
            }
            setIsFirst(false);
        }
    },[reDrawDashboard])

    // #endregion useEffect

    // #region handle event
    const refreshDashBoard = (dataPie?:any,dataSline?:any,dataDemand?:any,dataGASolarKwh?:any,dataScatterChartRaw?:any) => {
        let chartObj0: any = ((document.querySelector('#pieChart')) as any)?.ej2_instances;
        let chartObj1: any = ((document.querySelector('#lineChart')) as any)?.ej2_instances;
        let chartObj2: any = ((document.querySelector('#chartsColumn')) as any)?.ej2_instances;
        let chartObj3: any = ((document.querySelector('#chartsColumn1')) as any)?.ej2_instances;
        let chartObj4: any = ((document.querySelector('#scatterChart')) as any)?.ej2_instances;

        if(chartObj0){
            if(dataPie){
                chartObj0[0].series[0].dataSource = dataPie.chart;
            }
            chartObj0[0]?.refresh();
        }
        if(chartObj1){
            chartObj1[0]?.refresh();
        }
        if(chartObj2){
            if(dataDemand){
                chartObj2[0].series[0].dataSource = dataDemand;
            }
            chartObj2[0]?.refresh();
        }
        if(chartObj3){
            if(dataGASolarKwh){
                chartObj3[0].series[0].dataSource = dataGASolarKwh;
            }
            chartObj3[0]?.refresh();
        }
        if(chartObj4){
            if(dataScatterChartRaw){
                chartObj4[0].series[0].dataSource = dataScatterChartRaw.yesterdayData;
                chartObj4[0].series[1].dataSource = dataScatterChartRaw.todayData;
            }
            chartObj4[0]?.refresh();
        }
    }

    const handleSuccessGet = (data:any) => {
        setLoadingFlag(false);
        if(data && data.result_code == 0){
            const get_data = data.results;
            let dataPie;
            if(!get_data.telemetry_alive){
                setNullValue(true);
            }
            else if(get_data.telemetry_alive.receive == 0 && get_data.telemetry_alive.unreceive == 0 
                && get_data.telemetry_alive.unreceive_gw_count == 0 && get_data.telemetry_alive.unreceive_gw_list?.length == 0){
                setNullValue(false);
                setDataPieChart(null);
            }else{
                dataPie = {
                    unreceive_gw_count:get_data.telemetry_alive.unreceive_gw_count,
                    unreceive_gw_list:get_data.telemetry_alive.unreceive_gw_list,
                    chart:[
                        {
                            'x': "正常",
                            y: get_data.telemetry_alive.receive,
                            labelConnect:Math.round(get_data.telemetry_alive.receive / (get_data.telemetry_alive.receive + get_data.telemetry_alive.unreceive) * 100)+ "%"
                        },
                        {
                            'x': "異常",
                            y: get_data.telemetry_alive.unreceive,
                            labelConnect: Math.round(get_data.telemetry_alive.unreceive / (get_data.telemetry_alive.receive + get_data.telemetry_alive.unreceive) * 100) +"%"
                        },
                    ]
                } 
                setNullValue(false);              
                setDataPieChart(dataPie);
            }

            let dataSline:any = [];
            if(get_data.ga_solar_day_kwh){
                for(const item of get_data.ga_solar_day_kwh){
                    const dataDay = [];
                    for(const hourData of item.hour_data){
                        const data = {
                            x:hourData.hour,
                            y:Number(hourData.kwh),
                            customer_name:item.customer_name
                        }
                        dataDay.push(data);
                    }
                    dataSline.push({mainId:item.mainid,dataDay:dataDay});
                }
            }else{
                dataSline = null;
            }
            setDataSplineChart(dataSline);

            let dataDemand:any = [];
            if(get_data.ga_demand_rate){
                for(const item of get_data.ga_demand_rate){
                    const converted_data = {
                        "x":item.mainid,
                        y:Math.round(Number(item.usage)),
                        customer_id:item.customer_id,
                        customer_name:item.customer_name,
                    }
                    dataDemand.push(converted_data);
                }
            }else{
                dataDemand = null;
            }
            setDataColumnChart(dataDemand);

            let dataGASolarKwh:any = [];
            if(get_data.ga_solar_month_kwh){
                for(const item of get_data.ga_solar_month_kwh){
                    const converted_data = {
                        "x":item.mainid,
                        y:item.month_sum_kwh,
                        customer_id:item.customer_id,
                        customer_name:item.customer_name
                    }
                    dataGASolarKwh.push(converted_data);
                }
            }else{
                dataGASolarKwh = null;
            }
            setDataGASolarMonthKwh(dataGASolarKwh);

            let converted_data;
            if(get_data.telemetry_breaking_news){
                converted_data =  {
                    alert_abac:{
                        count:get_data.telemetry_breaking_news.alert_abac.count,
                        gw_list:get_data.telemetry_breaking_news.alert_abac.gw_list.map((item:any) => { return item}) 
                    },
                    alert_gb:{
                        count:get_data.telemetry_breaking_news.alert_gb.count,
                        gw_list:get_data.telemetry_breaking_news.alert_gb.gw_list.map((item:any) => { return item}) 
                    },
                    alert_gc:{
                        count:get_data.telemetry_breaking_news.alert_gc.count,
                        gw_list:get_data.telemetry_breaking_news.alert_gc.gw_list.map((item:any) => { return item}) 
                    },
                    alert_ad:{
                        count:get_data.telemetry_breaking_news.alert_ad.count,
                        gw_list:get_data.telemetry_breaking_news.alert_ad.gw_list.map((item:any) => { return item}) 
                    },
                    alert_eb:{
                        count:get_data.telemetry_breaking_news.alert_eb.count,
                        gw_list:get_data.telemetry_breaking_news.alert_eb.gw_list.map((item:any) => { return item}) 
                    },
                    alert_ec:{
                        count:get_data.telemetry_breaking_news.alert_ec.count,
                        gw_list:get_data.telemetry_breaking_news.alert_ec.gw_list.map((item:any) => { return item}) 
                    },
                    solar_work:{
                        count: get_data.telemetry_breaking_news.solar_work.count,
                        gw_list: get_data.telemetry_breaking_news.solar_work.gw_list.map((item:any) => { return item}) 
                    },
                    alert_ba:{
                        count: get_data.telemetry_breaking_news.alert_ba.count,
                        gw_list: get_data.telemetry_breaking_news.alert_ba.gw_list.map((item:any) => { return item}) 
                    },
                    alert_bb:{
                        count: get_data.telemetry_breaking_news.alert_bb.count,
                        gw_list: get_data.telemetry_breaking_news.alert_bb.gw_list.map((item:any) => { return item}) 
                    },
                    alert_bc:{
                        count: get_data.telemetry_breaking_news.alert_bc.count,
                        gw_list: get_data.telemetry_breaking_news.alert_bc.gw_list.map((item:any) => { return item}) 
                    },
                    alert_bd:{
                        count: get_data.telemetry_breaking_news.alert_bd.count,
                        gw_list: get_data.telemetry_breaking_news.alert_bd.gw_list.map((item:any) => { return item}) 
                    },
                    solar_gw_count:get_data.solar_gw_count,
                    gw_count:get_data.gw_count,
                    demand_gw_count:get_data.demand_gw_count,
                }
            }else{
                converted_data = null;
            }
            setDataTelemetryBreakingNew(converted_data);

            let demandMonitoringData:any;
            if(get_data.demand_monitoring)
                demandMonitoringData = {...get_data.demand_monitoring}
            else
                demandMonitoringData = null;
            setDataMonitoring(demandMonitoringData);

            let exceededMonthData:any;
            if(get_data.demand_exceed_month){
                exceededMonthData = {
                    ...get_data.demand_exceed_month,
                }
            }else{
                exceededMonthData = null;
            }
            setDataExceededMonth(exceededMonthData);

            let dataScatterChartRaw;
            const todayMwData = [];
            const yesterdayMwData = [];
            if(get_data.solar_generation){
                for(const item of get_data.solar_generation.todays_mw_data){
                    const data:any = {
                        label:"本日",
                        mw_sum: item.mw_sum,
                        time:0,
                    }
                    const currentDay = new Date();
                    data.time = convertTime(item.time,currentDay);
                    todayMwData.push(data);
                }
                for(const item of get_data.solar_generation.yesterdays_mw_data){
                    const data:any = {
                        label:"前日",
                        mw_sum: item.mw_sum,
                        time:0,
                    }
                    const currentDay = new Date();
                    data.time = convertTime(item.time,currentDay);
                    yesterdayMwData.push(data);
                }
                dataScatterChartRaw = {todayData:todayMwData,yesterdayData:yesterdayMwData,solar_gw_count:get_data.solar_generation.solar_gw_count,
                    compare_yesterday:get_data.solar_generation.compare_yesterday,current_mw:get_data.solar_generation.current_mw
                    ,mw_sum_day:get_data.solar_generation.mw_sum_day,mw_sum_month:get_data.solar_generation.mw_sum_month};
                setdataScatterChart(dataScatterChartRaw)
            }else{
                setdataScatterChart(null);
            }

            if(get_data.weather_info){
                setDataWeather(get_data.weather_info);
            }
            // reDrawDashBoard
            
            if(isFirstLoginBrowser){
                setReDrawDashboard(reDrawDashboard + 2);
                setIsFirstLoginBrowser(false);
            }else{
                setReDrawDashboard(reDrawDashboard + 1);
            }

            if(isRefresh){
                refreshDashBoard(dataPie,dataSline,dataDemand,dataGASolarKwh,dataScatterChartRaw);
                dispatch({type:"SET_IS_REFRESH_DASHBOARD",payload:{isRefresh:false}})
            }
        }
    }

    const convertTime = (time:any,currentDay:Date) => {
        let result;
        switch(time){
            case "00:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),0,0);
                break;
            case "00:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),0,30);
                break;
            case "01:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),1,0);
                break;
            case "01:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),1,30);
                break;
            case "02:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),2,0);
                break;
            case "02:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),2,30);
                break;
            case "03:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),3,0);
                break;
            case "03:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),3,30);
                break;
            case "04:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),4,0);
                break;
            case "04:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),4,30);
                break;
            case "05:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),5,0);
                break;
            case "05:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),5,30);
                break;
            case "06:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),6,0);
                break;
            case "06:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),6,30);
                break;
            case "07:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),7,0);
                break;
            case "07:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),7,30);
                break;
            case "08:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),8,0);
                break;
            case "08:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),8,30);
                break;
            case "09:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),9,0);
                break;
            case "09:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),9,30);
                break;
            case "10:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),10,0);
                break;
            case "10:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),10,30);
                break;
            case "11:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),11,0);
                break;
            case "11:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),11,30);
                break;
            case "12:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),12,0);
                break;
            case "12:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),12,30);
                break;
            case "13:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),13,0);
                break;
            case "13:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),13,30);
                break;
            case "14:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),14,0);
                break;
            case "14:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),14,30);
                break;
            case "15:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),15,0);
                break;
            case "15:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),15,30);
                break;
            case "16:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),16,0);
                break;
            case "16:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),16,30);
                break;
            case "17:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),17,0);
                break;
            case "17:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),17,30);
                break;
            case "18:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),18,0);
                break;
            case "18:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),18,30);
                break;
            case "19:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),19,0);
                break;
            case "19:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),19,30);
                break;
            case "20:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),20,0);
                break;
            case "20:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),20,30);
                break;
            case "21:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),21,0);
                break;
            case "21:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),21,30);
                break;
            case "22:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),22,0);
                break;
            case "22:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),22,30);
                break;
            case "23:00":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),23,0);
                break;
            case "23:30":
                result = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),23,30);
                break;
        }
        return result;
    }

    const handleErrorGet = (success:any, data:any, error:any) => {
        setLoadingFlag(false);
    }

    const handleOpenDialogByKey = (key:string) => {
        setOpenDialog(true);
        switch(key){
            case "alert_abac":
                setTitleDialog("停電詳細");
                setCount(dataTelemetryBreakingNew.alert_abac.count);
                setDataShow(dataTelemetryBreakingNew.alert_abac.gw_list);
                break;
            case "alert_gb":
                setTitleDialog("デマンド注意警報詳細");
                setCount(dataTelemetryBreakingNew.alert_gb.count);
                setDataShow(dataTelemetryBreakingNew.alert_gb.gw_list);
                break;
            case "alert_gc":
                setTitleDialog("デマンド限界警報詳細");
                setCount(dataTelemetryBreakingNew.alert_gc.count);
                setDataShow(dataTelemetryBreakingNew.alert_gc.gw_list);
                break;
            case "alert_ad":
                setTitleDialog("接点警報詳細");
                setCount(dataTelemetryBreakingNew.alert_ad.count);
                setDataShow(dataTelemetryBreakingNew.alert_ad.gw_list);
                break;
            case "alert_eb":
                setTitleDialog("軽漏電詳細");
                setCount(dataTelemetryBreakingNew.alert_eb.count);
                setDataShow(dataTelemetryBreakingNew.alert_eb.gw_list);
                break;
            case "alert_ec":
                setTitleDialog("重漏電詳細");
                setCount(dataTelemetryBreakingNew.alert_ec.count);
                setDataShow(dataTelemetryBreakingNew.alert_ec.gw_list);
                break;
            case "alert_ba":
                setTitleDialog("微地絡下限詳細");
                setCount(dataTelemetryBreakingNew.alert_ba.count);
                setDataShow(dataTelemetryBreakingNew.alert_ba.gw_list);
                break;
            case "alert_bb":
                setTitleDialog("微地絡警戒詳細");
                setCount(dataTelemetryBreakingNew.alert_bb.count);
                setDataShow(dataTelemetryBreakingNew.alert_bb.gw_list);
                break;
            case "alert_bc":
                setTitleDialog("微地絡詳細");
                setCount(dataTelemetryBreakingNew.alert_bc.count);
                setDataShow(dataTelemetryBreakingNew.alert_bc.gw_list);
                break;
            case "alert_bd":
                setTitleDialog("地絡詳細");
                setCount(dataTelemetryBreakingNew.alert_bd.count);
                setDataShow(dataTelemetryBreakingNew.alert_bd.gw_list);
                break;
            case "solar_work":
                setTitleDialog("太陽光稼働詳細");
                setCount(dataTelemetryBreakingNew.solar_work.count);
                setDataShow(dataTelemetryBreakingNew.solar_work.gw_list);
                break;
            case "demand_stopped_data":
                setTitleDialog("デマンド停止");
                setCount(dataDemandMonitoring.demand_stopped_data.count);
                setDataShow(dataDemandMonitoring.demand_stopped_data.gw_list);
                break;
            case "demand_exceed_data":
                setTitleDialog("本日の契約電力超過");
                setCount(dataDemandMonitoring.demand_exceed_data.count);
                setDataShow(dataDemandMonitoring.demand_exceed_data.gw_list);
                break;
            case "todays_gc_data":
                setTitleDialog("本日のデマンド限界警報");
                setCount(dataDemandMonitoring.todays_gc_data.count);
                setDataShow(dataDemandMonitoring.todays_gc_data.gw_list);
                break;
            case "todays_gb_data":
                setTitleDialog("本日のデマンド注意警報");
                setCount(dataDemandMonitoring.todays_gb_data.count);
                setDataShow(dataDemandMonitoring.todays_gb_data.gw_list);
                break;  
        }
    }

    const getColor = (data:any) => {
        let color;
        if(data >=0 && data < 40){
            color = "#CBFFA9";
        }else if(data >= 40 && data < 60){
            color = "#FFF67E";
        }else if(data >= 60 && data < 80){
            color = "#FFC436";
        }else if(data >= 80){
            color = "#FF9B9B";
        }
        return color;
    }

    const getColorLineChart = (index:any) => {
        let color;
        if(index === 0)
            color = "#F7418F"
        else if(index === 1)
            color = "#59D5E0"
        else if(index === 2)
            color = "#FCE22A"
        else if(index === 3)
            color = "#998CEB"
        else if(index === 4)
            color = "#38E54D"
        return color
    }

    // const handleStopResize = (event:any) => {
    //     restoreModel = dashboardObj.current.serialize();
    //     localStorage.setItem("positionDashboardData",JSON.stringify(restoreModel));
    //     const div_id = event.element.attributes[0].nodeValue;
    //     switch(div_id)
    //     {
    //         case "two":
    //             let chartObj0: any = ((document.querySelector('#pieChart')) as any)?.ej2_instances;
    //             if(chartObj0)
    //                 chartObj0[0]?.refresh();
    //             break;
    //         case "three":
    //             let chartObj1: any = ((document.querySelector('#lineChart')) as any)?.ej2_instances;
    //             if(chartObj1)
    //                 chartObj1[0]?.refresh();
    //             break;
    //         case "four":
    //             let chartObj2: any = ((document.querySelector('#chartsColumn')) as any)?.ej2_instances;
    //             if(chartObj2)
    //                 chartObj2[0]?.refresh();
    //             break;
    //         case "five":
    //             let chartObj3: any = ((document.querySelector('#chartsColumn1')) as any)?.ej2_instances;
    //             if(chartObj3)
    //                 chartObj3[0]?.refresh();
    //             break;
    //         case "eight":
    //             let chartObj4: any = ((document.querySelector('#scatterChart')) as any)?.ej2_instances;
    //             if(chartObj4)
    //                 chartObj4[0]?.refresh();
    //             break;
    //     }
    // }

    // Update positionDashboardData when move new panel
    const handleDragStop = (event:any) => {
        restoreModel = dashboardObj.current.serialize();
        const positionData = localStorage.getItem("positionDashboardData");
        if(positionData){
            const data = JSON.parse(positionData);
            const item = data.find((item:any) => item.user_id == user_id);
            if(item){
                item.settingView = restoreModel;
            }else{
                data.push(
                    {
                        user_id:user_id,
                        settingView:restoreModel,
                    }
                )
            }
            localStorage.setItem("positionDashboardData",JSON.stringify(data));
        }else{
            const data = [{
                user_id:user_id,
                settingView:restoreModel,
            }]
            localStorage.setItem("positionDashboardData",JSON.stringify(data));
        }
    }
    // #endregion handle event

    // #region component
    const chartTemplateString = (args:any) => {
        return `
          <div id="chartTemplateString">
                <div style="width:auto; height:65px; margin: 5px; background-color:black; opacity:0.8; border-radius:3px">
                    <p style="color:white; text-align:center; padding-top:3px; font-weight:bold">${args.tooltip}</p>
                    <p style="color:white; text-align:center; margin-top:-15px; font-weight:bold">${args.series.name}</p>
                    <hr style="background:#6C7A89; height:2px; border:none; margin-top:-10px; width:80%" />
                    <svg style="margin-left:10px; max-width:140px; max-height:30px" xmlns="http://www.w3.org/2000/svg">
                        ${
                          args.marker.shape === "Circle"
                          ? `<circle r="5" cx="7" cy="6" fill="${args.marker.border.color}" stroke="#6C7A89" stroke-width="1" />`
                          : args.marker.shape === "Triangle"
                          ? `<svg xmlns="http://www.w3.org/2000/svg" width="130" height="130"><polygon points="4,9.5 11,9.5 7.5,2.5" fill="${args.marker.border.color}" stroke="#6C7A89" stroke-width="1" transform="scale(1.5) translate(-3, -2)" /></svg>`
                          : args.marker.shape === "Diamond"
                          ? `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120"><polygon points="7.5,2.5 11,6 7.5,9.5 4,6" fill="${args.marker.border.color}" stroke="#6C7A89" stroke-width="1" transform="scale(1.5) translate(-3, -2)" /></svg>`
                          : args.marker.shape === "Rectangle"
                          ? `<rect x="3" y="1.5" width="9" height="9" fill="${args.marker.border.color}" stroke="#6C7A89" stroke-width="1" />`
                          : args.marker.shape === "Pentagon"
                          ? `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><polygon points="7.5,2.5 11,5 9.5,9.5 4.5,9.5 3,5" fill="${args.marker.border.color}" stroke="#6C7A89" stroke-width="1" transform="scale(1.4) translate(1, -4) rotate(25)" /></svg>`
                          : ""
                        }
                        <text x="17" y="10" fill="white">${args.x + " : " } <tspan style="font-weight:bold">${args.y}kWh</tspan></text>
                    </svg>
                </div>
          </div>
        `;
    };

    const columnChartTemplateString = (args:any) => {
        return `
          <div id="columnChartTemplateString">
                <div style="width: auto; height: 65px; background-color: #000000; opacity: 0.8; border-radius: 3px;">
                    <p style="color: #FFFFFF; text-align: center; padding-top: 3px; font-weight: bold;">当月発電実績</p>
                    <hr style="background: #6C7A89; height: 2px; border: none; margin-top: -10px; width: 80%;" />
                    <svg style="margin-left: 10px; max-width: 160px; max-height: 35px;" xmlns="http://www.w3.org/2000/svg">
                        <text x="15" y="10" fill="#FFFFFF" style="font-weight: bold;">
                            ${args.tooltip}
                        </text>
                        <circle r="5" cx="7" cy="22" fill="#AAD7D9" stroke="#6C7A89" stroke-width="1" />
                        <text x="15" y="27" fill="#FFFFFF">
                            ${args.x + " : "}
                            <tspan style="font-weight: bold;">
                                ${Math.floor(args.yValue) + "kWh"}
                            </tspan>
                        </text>
                    </svg>
                </div>
          </div>
        `;
    };

    const columnChart1TemplateString = (args:any) => {
        return `
          <div id="columnChart1TemplateString" style="position:'relative'">
              <div>
                  <div style="width: auto; height: 65px; background-color: #000000; opacity: 0.8; border-radius: 3px;">
                      <p style="color: #FFFFFF; text-align: center; padding-top: 3px; font-weight: bold;">使用率</p>
                      <hr style="background: #6C7A89; height: 2px; border: none; margin-top: -10px; width: 80%;" />
                      <svg style="margin-left: 10px; max-width: 160px; max-height: 35px;" xmlns="http://www.w3.org/2000/svg">
                          <text x="15" y="10" fill="#FFFFFF" style="font-weight: bold;">
                              ${args.tooltip}
                          </text>
                          <circle r="5" cx="7" cy="22" fill="${args.color}" stroke="#6C7A89" stroke-width="1" />
                          <text x="15" y="27" fill="#FFFFFF">
                              ${args.x + " : "}
                              <tspan style="font-weight: bold;">
                                  ${Math.floor(args.yValue) + "%"}
                              </tspan>
                          </text>
                      </svg>
                  </div>
              </div>
          </div>
        `;
    };

    const dashboard1 = (object:any) => {
        return <>
            <div id="one" style={{position:"absolute"}} className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                <span id="close" className="e-template-icon e-clear-icon"/>
                <div className="e-panel-container">
                    <div className="e-panel-header">速報</div>
                    {         
                        dataTelemetryBreakingNew ?  
                        <div className="e-panel-content" style={{ width:`100%`,height:"90%",overflow:"auto",padding:0}}>
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableRow >
                                            <TableCell style={tableCellStyle}>停電件数</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_abac?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_abac?.count > 0 ? 
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_abac")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>
                                            }
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>デマンド：注意警報</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_gb?.count}/{dataTelemetryBreakingNew?.demand_gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_gb?.count > 0 ? 
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_gb")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>デマンド：限界警報</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_gc?.count}/{dataTelemetryBreakingNew?.demand_gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_gc?.count > 0 ? 
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_gc")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>接点警報</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_ad?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_ad?.count > 0 ? 
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_ad")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell> 
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>軽漏電警報</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_eb?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_eb?.count > 0 ?
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_eb")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>重漏電警報</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_ec?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_ec?.count > 0 ?
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_ec")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>微地絡下限検出</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_ba?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_ba?.count > 0 ?
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_ba")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>微地絡警戒検出</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_bb?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_bb?.count > 0 ?
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_bb")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>微地絡警報</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_bc?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_bc?.count > 0 ?
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_bc")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>地絡警報</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.alert_bd?.count}/{dataTelemetryBreakingNew?.gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.alert_bd?.count > 0 ?
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("alert_bd")} style={{padding:"0px"}} children={<ReportProblemIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={tableCellStyle}>太陽光：稼働中装置</TableCell>
                                            <TableCell style={tableCellStyle}>{dataTelemetryBreakingNew?.solar_work?.count}/{dataTelemetryBreakingNew?.solar_gw_count}</TableCell>
                                            {dataTelemetryBreakingNew?.solar_work?.count > 0 ?
                                            <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("solar_work")} style={{padding:"0px"}} children={<FlareIcon sx={{color:"#FDBF60"}}/>} /></TableCell>
                                            : <TableCell style={tableCellStyle}></TableCell>}
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </>
                        </div>
                        :
                        <svg height="100%" width="100%" viewBox='0 0 100 100'>
                            <g transform="translate(0, 37)">
                                <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                            </g>
                        </svg>
                    }
                    <TooltipComponent id="toolTipComponent1" ref={tooltipInstance1} cssClass='customtooltip' content='現在の警報発生件数、および装置稼働状況の一覧です。' opensOn='Click' target='#clickButton1'>
                        <IconButton id='clickButton1' style={{position:"absolute",top:-8,left:35,zIndex:999,color:"#2874CE"}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                    </TooltipComponent>
                </div>
            </div>
        </>
    }

    const dashboard2 = (object:any) => {
        return <> 
                <div id="two" className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                    <div className="e-panel-container">
                        <div className='e-panel-header'>死活監視</div>
                        {
                            isNullValue &&
                            <svg height="100%" width="100%" viewBox='0 0 100 100'>
                                <g transform="translate(0, 37)">
                                    <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                    <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                                </g>
                            </svg>                            
                        }
                        {
                            !dataPieChart && !isNullValue && 
                            <svg height="100%" width="100%" viewBox='0 0 100 100'>
                                <g transform="translate(0, 35)">
                                <rect rx="2" ry="2" x="27" y="4" width="50" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                <text fontSize={"5px"} x="29.5" y="10">デ一夕がありません</text>
                                </g>
                            </svg>
                        }
                        {
                            dataPieChart && !isNullValue &&
                            <div className="e-panel-content" id="pieNotNull" style={{width:"100%",height:"100%",position:"relative"}}>
                                <AccumulationChartComponent
                                    id="pieChart"
                                    style={{width:`90%`,height:"90%",position:"absolute"}}
                                    legendSettings={{ visible: true }}
                                    tooltip={{ enable: true }}
                                    centerLabel={{
                                        text: '生存割合',
                                        textStyle: { fontWeight: '600', size: '15px' }
                                    }}
                                    enableSmartLabels={true}
                                    load={(args:any)=>{
                                        args.cancel = true;
                                    }}
                                    enablePersistence={true}
                                    enableBorderOnMouseMove={false}
                                    >
                                    <Inject
                                        services={[
                                        AccumulationTooltip,
                                        AccumulationDataLabel,
                                        AccumulationLegend,
                                        AccumulationTooltip
                                        ]}
                                    />
                                    <AccumulationSeriesCollectionDirective>
                                        <AccumulationSeriesDirective
                                        dataSource={dataPieChart.chart}
                                        dataLabel={{
                                            visible: true,
                                            position: 'Outside',
                                            name: 'labelConnect'
                                        }}
                                        xName='x'
                                        yName='y'
                                        innerRadius={isTablet ? '75%' : "70%"}
                                        radius={isTablet ? "85%" : "80%"}
                                        palettes={['#AAD7D9','#FF9B9B',]}
                                        />
                                    </AccumulationSeriesCollectionDirective>
                                </AccumulationChartComponent>                                
                                {dataPieChart && dataPieChart.unreceive_gw_list &&  dataPieChart.unreceive_gw_list.length > 0 &&
                                    <div style={{position:"absolute",top:-25,right:0}}>
                                        <IconButton onClick={() => setOpenMonitorDialog(true)}  style={{color:"#FF6868"}} children={<SmsIcon sx={{fontSize:"40px"}} />} />
                                    </div>
                                }
                            </div>
                        }
                        <TooltipComponent id="toolTipComponent2" ref={tooltipInstance2} width={"250px"} cssClass='customtooltip' content='25時間以内に死活監視情報を受信している装置を正常と判断しています。' opensOn='Click' target='#clickButton2'>
                            <IconButton id='clickButton2' style={{position:"absolute",top:-8,left:65,color:"#2874CE"}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                        </TooltipComponent>
                    </div>
                </div>
            
        </>
    }

    const dashboard3 = (object:any) => {
        return <>
            <div id="three" className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                <div className="e-panel-container">
                <div className='e-panel-header'>装置別当日発電量</div>
                <TooltipComponent id="toolTipComponent3" ref={tooltipInstance3} cssClass='customtooltip' content='本日の総発電量が多い装置を最大5台表示しています。' opensOn='Click' target='#clickButton3'>
                    <IconButton id="clickButton3" style={{position:"absolute",top:-8,left:120,color:"#2874CE"}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                </TooltipComponent>
                {
                    dataSplineChart ? 
                    <div className="e-panel-content" style={{width:`100%`,height:"100%"}}>
                        <ChartComponent id='lineChart' style={{ width:`100%`,height:"95%",position:"absolute"}} 
                            legendSettings={{ visible: true,enableHighlight: true }}
                            tooltipRender= {(args: any) => {
                                args.cancel = false;
                                args.template = chartTemplateString(args.point)
                                setTimeout(() => {
                                    const tooltipElement = document.getElementById("lineChart_tooltip") as any;
                                    tooltipElement.style.setProperty("margin-top",`${0}px`)
                                    tooltipElement.style.setProperty("margin-left",`${0}px`)
                                    const div = document.getElementById("chartTemplateString")
                                    const symbolY = args.point.symbolLocations[0].y;
                                    const symbolX = args.point.symbolLocations[0].x;
                                    const clipRectX = args.series.clipRect.x;
                                    const clipRectY = args.series.clipRect.y;
                                    const toolTipY = tooltipElement?.offsetTop;
                                    const toolTipX = tooltipElement?.offsetLeft;
                                    const isBellow = (symbolY - toolTipY!) > 0;
                                    const isRight = toolTipX! + tooltipElement?.offsetWidth! < symbolX + clipRectX;
                                    if(isRight){
                                        if(div){
                                            tooltipElement.style.setProperty("margin-left",`${17}px`)
                                            const triangle_right = document.createElement('div');
                                            triangle_right.classList.add('triangle_right');
                                            triangle_right.style.setProperty("top",`${symbolY - toolTipY! + clipRectY - 3}px`);
                                            triangle_right.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX - 20}px`);
                                            div.appendChild(triangle_right);
                                        }
                                    }
                                    else
                                    if(isBellow){
                                        if(div){
                                            tooltipElement.style.setProperty("margin-top",`${7}px`)
                                            const triangle_down = document.createElement('div');
                                            triangle_down.classList.add('triangle_down');
                                            triangle_down.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX}px`);
                                            triangle_down.style.setProperty("top",`${symbolY - toolTipY! + clipRectY - 16}px`);
                                            div.appendChild(triangle_down);
                                        }
                                    }else{
                                        if(div){
                                            tooltipElement.style.setProperty("margin-top",`-${10}px`)
                                            const triangle_up = document.createElement('div');
                                            triangle_up.classList.add('triangle_up');
                                            triangle_up.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX}px`);
                                            triangle_up.style.setProperty("top",`${symbolY - toolTipY! + clipRectY + 12 }px`);
                                            div.appendChild(triangle_up);
                                        }
                                    }                        
                                },10)
                            }}
                            tooltip={{ enable: true, template:<></>}}
                            primaryXAxis={{valueType: 'Category', title:"時聞",minimum:0,interval:1}} width={'100%'} 
                            chartArea={{ border: { width: 0 } }} primaryYAxis={{labelFormat: '{value}kWh', title: "発電量"}}
                            legendRender={(arg:any) =>{
                                arg.text += "発電量";
                            }}
                            >
                            <Inject services={[LineSeries, Legend, Category, ToolTipDashBoard, ChartAnnotation, Highlight]} />
                            <SeriesCollectionDirective>
                                {
                                    dataSplineChart.length > 0
                                    && 
                                    dataSplineChart.map((item:any,index:any) => {
                                        return (
                                            <SeriesDirective fill={getColorLineChart(index)} dataSource={item.dataDay} xName='x' yName='y' width={2} tooltipMappingName='customer_name' name={item.mainId} type='Line' marker={{ visible: true, width: 5, height: 5 }} />
                                        )
                                    })
                                }
                            </SeriesCollectionDirective>
                        </ChartComponent>                        
                    </div>
                    :
                    <svg height="100%" width="100%" viewBox='0 0 100 100'>
                        <g transform="translate(0, 37)">
                            <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                            <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                        </g>
                    </svg>
                }                
                </div>
            </div>
        </>
    }

    const dashboard4 = (object:any) => {
        return <>
            <div id="four" className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                <div className="e-panel-container" style={{position:"relative"}}>
                    <div className='e-panel-header'>現在の電力使用率</div>   
                    <TooltipComponent id="toolTipComponent4" ref={tooltipInstance4} width={"250px"} cssClass='customtooltip' content='現在の電力使用率が高い装置を最大10台表示しています。[緑色: 40%未満, 黄色: 40%～60%, 橙色: 60%～80%, 赤色: 80%以上]' opensOn='Click' target='#clickButton4'>
                        <IconButton id='clickButton4'  style={{color:"#2874CE", position:"absolute",top:-8,left:120}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                    </TooltipComponent>
                    <div className="e-panel-content" style={{width:"100%",height:"95%",position:"absolute"}}>
                        {
                            dataColumnChart &&
                            <ChartComponent id='chartsColumn' style={{width:`100%`,height:"100%", textAlign: "center" }} 
                                pointRender={(arg:any) => {
                                    arg.fill = getColor(arg.point.y)
                                }}
                                legendSettings={{ visible: true,enableHighlight: true }}
                                tooltipRender={(args:any) => {
                                    args.cancel = false;
                                    args.template = columnChart1TemplateString(args.point);
                                    setTimeout(() => {
                                        const tooltipElement = document.getElementById("chartsColumn_tooltip") as any;
                                        const div = document.getElementById("columnChart1TemplateString")
                                        const symbolY = args.point.symbolLocations[0].y;
                                        const symbolX = args.point.symbolLocations[0].x;
                                        const clipRectX = args.series.clipRect.x;
                                        const clipRectY = args.series.clipRect.y;
                                        const toolTipY = tooltipElement?.offsetTop;
                                        const toolTipX = tooltipElement?.offsetLeft;
                                        const isBellow = (symbolY - toolTipY!) > 0;
                                        const isRight = toolTipX! + tooltipElement?.offsetWidth! < symbolX + clipRectX;
                                        if(isRight){
                                            if(div){
                                                const triangle_right = document.createElement('div');
                                                triangle_right.classList.add('triangle_right');
                                                triangle_right.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX - 7}px`);
                                                triangle_right.style.setProperty("top",`${symbolY - toolTipY! + clipRectY - 3}px`);
                                                div.appendChild(triangle_right);
                                            }
                                        }
                                        else
                                        if(isBellow){
                                            if(div){
                                                const triangle_down = document.createElement('div');
                                                triangle_down.classList.add('triangle_down');
                                                triangle_down.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX}px`);
                                                triangle_down.style.setProperty("top",`${symbolY - toolTipY! + clipRectY - 4}px`);
                                                div.appendChild(triangle_down);
                                            }
                                        }else{
                                            if(div){
                                                const triangle_up = document.createElement('div');
                                                triangle_up.classList.add('triangle_up');
                                                triangle_up.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX }px`);
                                                triangle_up.style.setProperty("top",`${symbolY - toolTipY! + clipRectY + 5 }px`);
                                                div.appendChild(triangle_up);
                                            }
                                        }                        
                                    },10)
                                }}
                                tooltip={{enable:true,template:<></>}}
                                // tooltip={{ enable: true, header: "<b>使用率</b>", enableMarker:true }}
                                primaryXAxis={{ labelIntersectAction: Browser.isDevice ? 'None' : 'Trim', valueType: 'Category',title:"使用率" }} primaryYAxis={{minimum:0,labelFormat: '{value}%'}} chartArea={{ border: { width: 0 } }} width={"100%"}>
                                <Inject services={[ColumnSeries, Legend, ToolTipDashBoard, Category, DataLabel, Highlight]} />
                                <SeriesCollectionDirective>
                                    <SeriesDirective dataSource={dataColumnChart} xName='x' yName='y' tooltipMappingName='customer_name' columnSpacing={0.1} type='Column' />
                                </SeriesCollectionDirective>
                            </ChartComponent>
                        }
                        {
                            !dataColumnChart &&
                            <svg height="100%" width="100%" viewBox='0 0 100 100'>
                                <g transform="translate(0, 37)">
                                    <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                    <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                                </g>
                            </svg>
                        }
                    </div>
                </div>
            </div>
        </>
    }

    const dashboard5 = (object:any) => {
        return <>
            <div id="five" className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                <span id="close" className="e-template-icon e-clear-icon"/>
                <div className="e-panel-container" style={{position:"relative"}}>
                    <div className='e-panel-header'>当月発電実績</div>
                    <TooltipComponent id="toolTipComponent5" ref={tooltipInstance5} cssClass='customtooltip' content='今月の総発電量が多い装置を、最大10台表示しています。' opensOn='Click' target='#clickButton5'>
                        <IconButton id='clickButton5' style={{color:"#2874CE",position:"absolute",top:-8,left:95}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                    </TooltipComponent>
                    {
                        dataGASolarMonthKwh &&
                        <div className="e-panel-content" style={{width:"100%",height:"95%",position:"absolute"}}>
                            <ChartComponent id='chartsColumn1' style={{width:`100%`,height:"100%", textAlign: "center" }} 
                                legendSettings={{ visible: true,enableHighlight: true }}
                                tooltipRender= {(args: any) => {
                                    args.cancel = false;
                                    args.template = columnChartTemplateString(args.point)
                                    setTimeout(() => {
                                        const tooltipElement = document.getElementById("chartsColumn1_tooltip") as any;
                                        tooltipElement.style.setProperty("margin-left",`${0}px`)
                                        const div = document.getElementById("columnChartTemplateString")
                                        const symbolY = args.point.symbolLocations[0].y;
                                        const symbolX = args.point.symbolLocations[0].x;
                                        const clipRectX = args.series.clipRect.x;
                                        const clipRectY = args.series.clipRect.y;
                                        const toolTipY = tooltipElement?.offsetTop;
                                        const toolTipX = tooltipElement?.offsetLeft;
                                        const isBellow = (symbolY - toolTipY!) > 0;
                                        const isRight = toolTipX! + tooltipElement?.offsetWidth! < symbolX + clipRectX;
                                        if(isRight){
                                            if(div){
                                                tooltipElement.style.setProperty("margin-left",`${3}px`);
                                                const triangle_right = document.createElement('div');
                                                triangle_right.classList.add('triangle_right');
                                                triangle_right.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX - 6.8}px`);
                                                triangle_right.style.setProperty("top",`${symbolY - toolTipY! + clipRectY - 3}px`);
                                                div.appendChild(triangle_right);
                                            }
                                        }
                                        else
                                        if(isBellow){
                                            if(div){
                                                const triangle_down = document.createElement('div');
                                                triangle_down.classList.add('triangle_down');
                                                triangle_down.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX}px`);
                                                triangle_down.style.setProperty("top",`${symbolY - toolTipY! + clipRectY - 3.5}px`);
                                                div.appendChild(triangle_down);
                                            }
                                        }else{
                                            if(div){
                                                const triangle_up = document.createElement('div');
                                                triangle_up.classList.add('triangle_up');
                                                triangle_up.style.setProperty("left",`${symbolX - toolTipX!  + clipRectX}px`);
                                                triangle_up.style.setProperty("top",`${symbolY - toolTipY! + clipRectY + 4.5 }px`);
                                                div.appendChild(triangle_up);
                                            }
                                        }                        
                                    },10)
                                }}
                                tooltip={{enable:true,template:<></>}}
                                primaryXAxis={{ labelIntersectAction: Browser.isDevice ? 'None' : 'Trim', valueType: 'Category',title:"発電量"}} primaryYAxis={{labelFormat: '{value}kWh',title:"装置別発電実績量(kWh)"}} chartArea={{ border: { width: 0 } }} width={"100%"}>
                                <Inject services={[ColumnSeries, Legend, ToolTipDashBoard, Category, DataLabel, Highlight]} />
                                <SeriesCollectionDirective>
                                    <SeriesDirective dataSource={dataGASolarMonthKwh} xName='x' yName='y' fill='#AAD7D9' tooltipMappingName='customer_name' columnSpacing={0.1} type='Column' />
                                </SeriesCollectionDirective>
                            </ChartComponent>                        
                        </div>
                    }
                    {
                        !dataGASolarMonthKwh &&
                        <svg height="100%" width="100%" viewBox='0 0 100 100'>
                            <g transform="translate(0, 37)">
                                <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                            </g>
                        </svg>
                    }
                </div>
            </div>
        </>
    }

    const dashboard6 = (object:any) => {
        return <>
        <div id="six" style={{position:"absolute"}} className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
            <span id="close" className="e-template-icon e-clear-icon"/>
            <div className="e-panel-container">
            <div className="e-panel-header">デマンド監視</div>
                    <>
                        {
                            dataDemandMonitoring ? 
                                <div className="e-panel-content" style={{width:`100%`,height:"90%",overflow:"auto",padding:0}}>
                                    <TableContainer>
                                        <Table>
                                            <TableRow>
                                                <TableCell style={tableCellStyle}>デマンド監視件数</TableCell>
                                                <TableCell style={tableCellStyle}>{dataDemandMonitoring.demand_gw_count}件</TableCell>
                                                <TableCell style={tableCellStyle}></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={tableCellStyle}>デマンド停止</TableCell>
                                                <TableCell style={tableCellStyle}>{dataDemandMonitoring.demand_stopped_data.count}件</TableCell>
                                                {dataDemandMonitoring.demand_stopped_data.count > 0 ? 
                                                <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("demand_stopped_data")} style={{padding:"0px"}} children={<InfoIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                                : <TableCell style={tableCellStyle}></TableCell>}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={tableCellStyle}>本日の契約電力超過</TableCell>
                                                <TableCell style={tableCellStyle}>{dataDemandMonitoring.demand_exceed_data.count}件</TableCell>
                                                {dataDemandMonitoring.demand_exceed_data.count > 0 ? 
                                                <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("demand_exceed_data")} style={{padding:"0px"}} children={<InfoIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                                : <TableCell style={tableCellStyle}></TableCell>}
                                            </TableRow>
                                            <TableRow>
                                            <TableCell style={tableCellStyle}>本日のデマンド限界警報</TableCell>
                                                <TableCell style={tableCellStyle}>{dataDemandMonitoring.todays_gc_data.count}件</TableCell>
                                                {dataDemandMonitoring.todays_gc_data.count > 0 ? 
                                                <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("todays_gc_data")} style={{padding:"0px"}} children={<InfoIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                                : <TableCell style={tableCellStyle}></TableCell>}
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={tableCellStyle}>本日のデマンド注意警報</TableCell>
                                                <TableCell style={tableCellStyle}>{dataDemandMonitoring.todays_gb_data.count}件</TableCell>
                                                {dataDemandMonitoring.todays_gb_data.count > 0 ? 
                                                <TableCell style={tableCellStyle}><IconButton onClick={() => handleOpenDialogByKey("todays_gb_data")} style={{padding:"0px"}} children={<InfoIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                                : <TableCell style={tableCellStyle}></TableCell>}
                                            </TableRow>
                                            <TableRow>
                                            <TableCell style={tableCellStyle}>本日の最大使用率</TableCell>
                                                <TableCell style={tableCellStyle}>{dataDemandMonitoring.todays_max_data.max_usage}%</TableCell>
                                                {dataDemandMonitoring.todays_max_data.max_usage > 0 ? 
                                                <TableCell style={tableCellStyle}><IconButton onClick={() => setOpenTodayUsageDialog(true)} style={{padding:"0px"}} children={<InfoIcon sx={{color:"#FF6868"}}/>} /></TableCell>
                                                : <TableCell style={tableCellStyle}></TableCell>}
                                            </TableRow>
                                        </Table>
                                    </TableContainer>
                                </div>
                            :
                            <svg height="100%" width="100%" viewBox='0 0 100 100'>
                                <g transform="translate(0, 37)">
                                    <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                    <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                                </g>
                            </svg>
                        }
                        <TooltipComponent id="toolTipComponent6" ref={tooltipInstance6} cssClass='customtooltip' content='本日のデマンドに関する情報を表示しています。' opensOn='Click' target='#clickButton6'>
                            <IconButton id='clickButton6' style={{position:"absolute",top:-7,left:95,zIndex:999,color:"#2874CE"}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                        </TooltipComponent>
                    </>                 
                </div>
            </div>
        </>
    }

    const dashboard7 = (object:any) => {
        return <>
            <div id="seven" style={{position:"absolute"}} className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                <span id="close" className="e-template-icon e-clear-icon"/>
                <div className="e-panel-container">
                    <div className="e-panel-header">今月のデマンド超過</div>
                    <TooltipComponent width={"270px"} id="toolTipComponent7" ref={tooltipInstance7} cssClass='customtooltip' content='今月のデマンド限界値(閾値)を超過した総数と装置の一覧です。装置一覧では、今月最大のデマンド値を装置ごとに1件表示しています。' opensOn='Click' target='#clickButton7'>
                        <IconButton id='clickButton7' style={{position:"absolute",top:-7,left:135,zIndex:999,color:"#2874CE"}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                    </TooltipComponent>
                    {
                        dataExceededMonth ?
                        <>
                            <Typography margin={"10px"} marginTop={"0px"}>件数: {dataExceededMonth?.count}件</Typography>
                            <div className="e-panel-content" style={{height:`${!isTablet ? "78%" : "90%"}`,width:`95%`,margin:"auto",padding:0,border:"1px solid #C7C8CC"}}>
                                <>
                                    <TableContainer sx={{ maxHeight: '100%', overflowY: 'auto' }}>
                                        <Table stickyHeader={true}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ backgroundColor:"#DCEAF7",borderRight: '1px solid #fff', padding: '6px' }}>{`${customerLabelName}名`}</TableCell>
                                                    <TableCell sx={{ backgroundColor:"#DCEAF7",borderRight: '1px solid #fff', padding: '6px' }}>装置ID</TableCell>
                                                    <TableCell sx={{ backgroundColor:"#DCEAF7",borderRight: '1px solid #fff', padding: '6px' }}>発生日時</TableCell>
                                                    <TableCell sx={{ backgroundColor:"#DCEAF7",borderRight: '1px solid #fff', paddingTop: '0px', paddingBottom: '0px', padding: '6px' }}>{`最大`}<br />{`デマンド`}</TableCell>
                                                    <TableCell sx={{ backgroundColor:"#DCEAF7",borderRight: '1px solid #fff', paddingTop: '0px', paddingBottom: '0px', padding: '6px' }}>{`デマンド`}<br />{`限界`}</TableCell>
                                                    <TableCell sx={{ backgroundColor:"#DCEAF7",padding: '6px', paddingTop: '0px', paddingBottom: '0px' }}>{`使用率`}<br />{`(%)`}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataExceededMonth.gw_list.map((item:any) => (
                                                    <TableRow key={item.mainid}>
                                                        <TableCell sx={{ paddingTop: '12px', paddingBottom: '12px' }}>{item.customer_name}</TableCell>
                                                        <TableCell sx={{ paddingTop: '12px', paddingBottom: '12px' }}>{item.mainid}</TableCell>
                                                        <TableCell sx={{ paddingTop: '12px', paddingBottom: '12px' }}>{item.registered}</TableCell>
                                                        <TableCell sx={{ paddingTop: '12px', paddingBottom: '12px' }}>{item.exceed_kw}</TableCell>
                                                        <TableCell sx={{ paddingTop: '12px', paddingBottom: '12px' }}>{item.th2nd_kw}</TableCell>
                                                        <TableCell sx={{ paddingTop: '12px', paddingBottom: '12px' }}>{item.usage}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            </div>
                        </>
                        :
                        <>
                            <svg height="100%" width="100%" viewBox='0 0 100 100'>
                                <g transform="translate(0, 37)">
                                    <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                    <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                                </g>
                            </svg>
                        </>
                    }
                </div>
            </div>
        </>
    }

    const dashboard8 = (object:any) => {
        return <>
            <div id="eight" className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                <div className="e-panel-container">
                    <div className='e-panel-header'>太陽光発電状況</div>
                    <div className="e-panel-content" style={{width:"100%",height:"100%",display:"inline-block",position:"relative"}}>
                        <TooltipComponent id="toolTipComponent8" ref={tooltipInstance8} cssClass='customtooltip' content='太陽光発電の発電状況や前日比を表示しています。' opensOn='Click' target='#clickButton8'>
                            <IconButton id="clickButton8" style={{position:"absolute",top:-35,left:105,color:"#2874CE"}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                        </TooltipComponent>
                        {
                            dataScatterChart && 
                            <>
                                {!isTablet &&
                                    <>
                                        <div style={{width:`65%`,height:"95%",position:"relative"}}>
                                            <ChartComponent id='scatterChart' tooltip={{enable:"true",shared:"true",format:'${point.tooltip}: ${point.y}MWh'}} primaryXAxis={{valueType: 'DateTime',labelFormat:"H:mm",title : "時間",rangePadding:'Additional',interval:2
                                                ,minimum: new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),0,0),maximum:new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),23,30)}} primaryYAxis={{title:"発電量(MWh)"}} title='1日の総発電量'>
                                                <Inject services={[ScatterSeries, DateTime, Legend,ToolTipDashBoard, DataLabel, Category]}/>
                                                <SeriesCollectionDirective>
                                                    <SeriesDirective tooltipMappingName='label' fill="#404041" dataSource={dataScatterChart.yesterdayData} xName='time' yName='mw_sum' type='Scatter' marker={{ width: 15, height: 15, shape:"Triangle" }}>
                                                    </SeriesDirective>
                                                    <SeriesDirective tooltipMappingName='label' fill="#28BDAE" dataSource={dataScatterChart.todayData} xName='time' yName='mw_sum' type='Scatter' marker={{ width: 15, height: 15, shape:"Circle" }}>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>                                            
                                            <div style={{position:"absolute",bottom:"-3%",left:"47.5%"}}>
                                                <svg width="100" height="40">
                                                    <circle r="5" transform="translate(53,10)" cx="7" cy="6" fill="#28BDAE" stroke="#6C7A89" stroke-width="1" />
                                                    <text x="70" y="20" fill="black">本日</text>
                                                    <polygon points="4,9.5 11,9.5 7.5,2.5" fill="${args.marker.border.color}" stroke="#6C7A89" stroke-width="1" transform="scale(1.3) translate(-3, 6)" />
                                                    <text x="17" y="20" fill="black">前日</text>
                                                </svg>
                                            </div>
                                        </div>
                                        <div style={{width:`35%`,height:"95%",overflow:"auto",display:"inline-block",position:"absolute",margin:"auto ",top:0,right:0}}>
                                            <Table style={{width:"100%"}}>
                                                <TableRow >
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>太陽光管理件数</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.solar_gw_count}件</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>今月の総発電量</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.mw_sum_month} MW</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>本日の発電量</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.mw_sum_day} MWh</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>現在の発電</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.current_mw} MW</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>現在時刻前日比</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>
                                                        {dataScatterChart.compare_yesterday > 0  && 
                                                            <>
                                                                <div style={{position:"relative"}}>
                                                                    {`+${dataScatterChart.compare_yesterday} MW`}
                                                                    <ArrowUpwardIcon style={{color:"green",position:"relative",top:5}} />
                                                                </div>
                                                            </>
                                                        }
                                                        {dataScatterChart.compare_yesterday == 0  && 
                                                            <>
                                                                <div style={{position:"relative"}}>
                                                                    {`${dataScatterChart.compare_yesterday} MW`}
                                                                    <ArrowForwardIcon style={{color:"#FFC000",position:"relative",top:5}} />
                                                                </div>
                                                            </>
                                                        }
                                                        {dataScatterChart.compare_yesterday < 0  && 
                                                            <>
                                                                <div style={{position:"relative"}}>
                                                                    {`${dataScatterChart.compare_yesterday} MW`}
                                                                    <ArrowDownwardIcon style={{color:"red",position:"relative",top:5}} />
                                                                </div>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </div>
                                    </>
                                }
                                {isTablet &&
                                    <>
                                        <div style={{width:`100%`,height:"75%",position:"relative"}}>
                                            <ChartComponent id='scatterChart' tooltip={{enable:"true",shared:"true",format:'${point.tooltip}: ${point.y}MWh'}} primaryXAxis={{valueType: 'DateTime',labelFormat:"H:mm",title : "時間",rangePadding:'Additional',interval:2,
                                                minimum: new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),0,0),maximum:new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),23,30)}} primaryYAxis={{title:"発電量(MWh)"}} title='1日の総発電量'>
                                                <Inject services={[ScatterSeries, DateTime, Legend,ToolTipDashBoard, DataLabel, Category]}/>
                                                <SeriesCollectionDirective>
                                                    <SeriesDirective fill="#404041" dataSource={dataScatterChart.yesterdayData} tooltipMappingName='label'  xName='time' yName='mw_sum' type='Scatter' marker={{ width: 15, height: 15,shape:"Triangle" }}>
                                                    </SeriesDirective>
                                                    <SeriesDirective fill="#28BDAE" dataSource={dataScatterChart.todayData} tooltipMappingName='label' xName='time' yName='mw_sum' type='Scatter' marker={{ width: 15, height: 15,shape:"Circle"}}>
                                                    </SeriesDirective>
                                                </SeriesCollectionDirective>
                                            </ChartComponent>
                                            <div style={{position:"absolute",bottom:"18%",left:"45%"}}>
                                                <svg width="100" height="40">
                                                    <circle r="5" transform="translate(53,10)" cx="7" cy="6" fill="#28BDAE" stroke="#6C7A89" stroke-width="1" />
                                                    <text x="70" y="20" fill="black">本日</text>
                                                    <polygon points="4,9.5 11,9.5 7.5,2.5" fill="${args.marker.border.color}" stroke="#6C7A89" stroke-width="1" transform="scale(1.3) translate(-3, 6)" />
                                                    <text x="17" y="20" fill="black">前日</text>
                                                </svg>
                                            </div>
                                        </div>
                                        <div style={{width:`100%`,height:"35%",overflow:"auto",display:"inline-block",position:"absolute",margin:"auto ",top:"60%"}}>
                                            <Table style={{width:"100%"}}>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>太陽光管理件数</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.solar_gw_count}件</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>今月の総発電量</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.mw_sum_month} MW</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>本日の発電量</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.mw_sum_day} MWh</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>現在の発電</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>{dataScatterChart.current_mw} MW</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}><Typography>現在時刻前日比</Typography></TableCell>
                                                    <TableCell style={{paddingTop:"8px",paddingBottom:"8px"}}>
                                                        {dataScatterChart.compare_yesterday > 0  && 
                                                            <>
                                                                <div style={{position:"relative"}}>
                                                                    {`+${dataScatterChart.compare_yesterday} MW`}
                                                                    <ArrowUpwardIcon style={{color:"green",position:"relative",top:5}} />
                                                                </div>
                                                            </>
                                                        }
                                                        {dataScatterChart.compare_yesterday == 0  && 
                                                            <>
                                                                <div style={{position:"relative"}}>
                                                                    {`${dataScatterChart.compare_yesterday} MW`}
                                                                    <ArrowForwardIcon style={{color:"#FFC000",position:"relative",top:5}} />
                                                                </div>
                                                            </>
                                                        }
                                                        {dataScatterChart.compare_yesterday < 0  && 
                                                            <>
                                                                <div style={{position:"relative"}}>
                                                                    {`${dataScatterChart.compare_yesterday} MW`}
                                                                    <ArrowDownwardIcon style={{color:"red",position:"relative",top:5}} />
                                                                </div>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </div>
                                    </>
                                }
                            </>
                        }
                        {
                            !dataScatterChart && 
                            <svg height="100%" width="100%" viewBox='0 0 100 100'>
                                <g transform="translate(0, 37)">
                                    <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                    <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                                </g>
                            </svg>
                        }                        
                    </div>
                </div>
            </div>
        </>
    }

    const dashboard9 = (object:any) => {
        return <>
            <div id="nine" style={{position:"absolute"}} className="e-panel" data-row={`${object.row}`} data-col={`${object.col}`} data-sizex={`${object.sizeX}`} data-sizey={`${object.sizeY}`}>
                <span id="close" className="e-template-icon e-clear-icon"/>
                <div className="e-panel-container">
                    <div className="e-panel-header">天気情報</div>
                    <TooltipComponent id="toolTipComponent9" ref={tooltipInstance9} cssClass='customtooltip' content='現在の天気と気温を気象観測地点ごとに最大10件表示しています。' opensOn='Click' target='#clickButton9'>
                        <IconButton id='clickButton9' style={{position:"absolute",top:-7,left:65,zIndex:999,color:"#2874CE"}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                    </TooltipComponent>
                    {
                        dataWeather && dataWeather.length > 0 ?
                        <>
                            <div className="e-panel-content" style={{ width:`100%`,height:"90%",overflow:"auto",padding:0}}>
                                <>
                                    <TableContainer>
                                        <Table>
                                            {
                                                dataWeather.map((item:any) => {
                                                    return <>
                                                        <TableRow>
                                                            <TableCell style={tableCellStyle}>{item.ob_point_name}</TableCell>
                                                            <TableCell style={tableCellStyle}>
                                                                {(item.condition == "晴れ" || item.condition == "快晴") && <WbSunnyIcon color="warning"  /> }
                                                                {( item.condition == "雲り") && <CloudIcon /> }
                                                                {( item.condition == "雨") && <UmbrellaIcon />}
                                                                {( item.condition == "雪") && <AcUnitIcon /> }
                                                                {(item.condition == "-") && <>-</>}
                                                            </TableCell>
                                                            <TableCell style={tableCellStyle}>{item.temperature}°C</TableCell>
                                                        </TableRow>
                                                    </>
                                                })
                                            }
                                            
                                        </Table>
                                    </TableContainer>
                                </>
                            </div>
                        </>
                        :
                        <>
                            <svg height="100%" width="100%" viewBox='0 0 100 100'>
                                <g transform="translate(0, 37)">
                                    <rect rx="2" ry="2" x="16" y="4" width="70" height="8" fill="white" stroke="#e7e6e6" stroke-width="1"/>
                                    <text fontSize={"5px"} x="18.5" y="10">データの取得に失敗しました</text>
                                </g>
                            </svg>
                        </>
                    }
                </div>
            </div>
        </>
    }
    // #endregion component

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div>
                <div className="control-section">
                    <DashboardLayoutComponent id='defaultLayout' allowDragging={true} cellSpacing={[10, 10]} ref={dashboardObj} cellAspectRatio={Browser.isDevice ? 1.05 : 0.85} allowResizing={false} columns={9} mediaQuery='max-width:730px' dragStop={handleDragStop}>
                    </DashboardLayoutComponent>
                </div>
                
            </div>
            {openDialog &&
                <div>
                    <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"sm"} fullWidth
                    >
                    <DialogTitle id="alert-dialog-title">
                        {titleDiaglog}
                    </DialogTitle>
                    {
                        titleDiaglog == "デマンド停止"
                        &&
                        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                            <div>
                                <Tooltip
                                    PopperProps={{
                                    disablePortal: true,
                                    }}
                                    onClose={() => setOpenTooltip(false)}
                                    open={openTooltip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="過去1時間以内にデマンド定期通報を受信していない装置を停止中と判断しています。また、日時は最終受信日時です。"
                                    slotProps={{
                                        popper: {
                                        modifiers: [
                                            {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -15],
                                            },
                                            },
                                        ],
                                        sx: {
                                            '& .MuiTooltip-tooltip': {
                                                backgroundColor: '#757575',
                                                color: '#fff',
                                                fontSize: 14,
                                                padding: '8px 12px',
                                            },
                                            '& .MuiTooltip-arrow': {
                                                color: '#757575',
                                            },
                                        },
                                        },
                                    }}
                                >
                                <IconButton onClick={() => setOpenTooltip((prev: boolean) => !prev)} style={{position:"absolute",top:10,color:"#2874CE", left: 125}} children={<InfoOutlinedIcon sx={{fontSize:"22px"}} />} />
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                    }
                    <DialogContent style={{paddingBottom:"0px",paddingTop:"0px",overflow:"hidden"}}>
                        <Typography>件数: {count}</Typography>
                        <Typography mt={2}>装置詳細: </Typography>
                        {
                            !isTablet
                            &&
                            <div style={{overflow:"auto",maxHeight:"35vh",marginLeft:`53px`}}>
                                <TableContainer>
                                    <Table>
                                        {dataShow.length > 0 &&
                                            dataShow.map((item:any) => {
                                                return(
                                                    <>
                                                        <TableRow>
                                                            <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{item.mainid}</TableCell>
                                                            <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{item.customer_name}</TableCell>
                                                            {item.registered &&
                                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{item.registered.length < 19 ? item.registered : item.registered.substring(0,item.registered.lastIndexOf(":"))}</TableCell>
                                                            }
                                                        </TableRow>
                                                    </>
                                                )
                                            })
                                        }
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                    </DialogContent>
                    {isTablet &&
                        <div style={{overflow:"auto",maxHeight:"35vh",marginLeft:`20px`}}>
                            <TableContainer>
                                <Table>
                                    {dataShow.length > 0 &&
                                        dataShow.map((item:any) => {
                                            return(
                                                <>
                                                    <TableRow>
                                                        <TableCell colSpan={0} style={{paddingLeft:"5px",paddingRight:"5px",paddingTop:"8px",paddingBottom:"8px",maxWidth:"20px !important"}}>{item.mainid}</TableCell>
                                                        <TableCell colSpan={3} style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{item.customer_name}</TableCell>
                                                        {item.registered &&
                                                            <TableCell colSpan={3} style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{item.registered.length < 19 ? item.registered : item.registered.substring(0,item.registered.lastIndexOf(":"))}</TableCell>
                                                        }
                                                    </TableRow>
                                                </>
                                            )
                                        })
                                    }
                                </Table>
                            </TableContainer>
                        </div>
                    }
                    <DialogActions>
                        <Button style={{marginRight:"10px",marginBottom:"5px"}} onClick={() => {setOpenTooltip(false);setOpenDialog(false)}} color="inherit" variant="contained">キャンセル</Button>
                    </DialogActions>
                    </Dialog>
                </div>
            }
            {openMonitorDialog &&
                <div>
                    <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"sm"} fullWidth
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"死活監視未受信装置一覧"}
                        </DialogTitle>
                        <DialogContent style={{paddingBottom:"0px",overflow:"hidden"}}>
                            <Typography>未受信装置数: {dataPieChart.unreceive_gw_count}件</Typography>
                            <Typography mt={2}>装置詳細:</Typography>
                            {!isTablet
                                &&
                                <div style={{overflow:"auto",maxHeight:"35vh",marginLeft:`53px`}}>
                                    <TableContainer>
                                        <Table>
                                            {dataPieChart.unreceive_gw_list.length > 0 &&
                                                dataPieChart.unreceive_gw_list.map((item:any) => {
                                                    return(
                                                        <>
                                                            <TableRow>
                                                                <TableCell style={tableCellStyle}>{item.mainid}</TableCell>
                                                                <TableCell style={tableCellStyle}>{item.customer_name}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Table>
                                    </TableContainer>
                                </div>
                            }
                        </DialogContent>
                        {isTablet &&
                            <div style={{overflow:"auto",maxHeight:"35vh",marginLeft:"20px"}}>
                                <TableContainer>
                                    <Table>
                                        {dataPieChart.unreceive_gw_list.length > 0 &&
                                            dataPieChart.unreceive_gw_list.map((item:any) => {
                                                return(
                                                    <>
                                                        <TableRow>
                                                            <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{item.mainid}</TableCell>
                                                            <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{item.customer_name}</TableCell>
                                                        </TableRow>
                                                    </>
                                                )
                                            })
                                        }
                                    </Table>
                                </TableContainer>
                            </div>
                        }
                        <DialogActions>
                            <Button style={{marginRight:"10px",marginBottom:"5px"}} onClick={() => setOpenMonitorDialog(false)} color="inherit" variant="contained">キャンセル</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            }
            {
                openTodayUsageDialog &&
                <div>
                    <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"sm"} fullWidth
                    >
                        <DialogTitle id="alert-dialog-title">本日の最大使用率</DialogTitle>
                        <DialogContent style={{paddingBottom:"0px",overflow:"hidden"}}>
                            <div style={{overflow:"auto",maxHeight:"35vh"}}>
                                {dataDemandMonitoring.todays_max_data &&
                                    <TableContainer>
                                        <Table>
                                            <TableRow>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>装置ID</TableCell>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{dataDemandMonitoring.todays_max_data.mainid}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{`${customerLabelName}名`}</TableCell>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{dataDemandMonitoring.todays_max_data.customer_name}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>発生日時</TableCell>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{dataDemandMonitoring.todays_max_data.registered.length < 19 ? dataDemandMonitoring.todays_max_data.registered.replaceAll("-","/") + ':00' : dataDemandMonitoring.todays_max_data.registered.replaceAll("-","/")} </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>最大使用率</TableCell>
                                                <TableCell style={!isTablet ? tableCellStyle : tableCellStyleSmartPhone}>{dataDemandMonitoring.todays_max_data.max_usage}%</TableCell>
                                            </TableRow>
                                        </Table>
                                    </TableContainer>
                                }
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{marginRight:"10px",marginBottom:"5px"}} onClick={() => setOpenTodayUsageDialog(false)} color="inherit" variant="contained">キャンセル</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            }
            <FetchHelper
                fetchId={GET_DATA_DASHBOARD}
                onComplete={(success:boolean,data:any,error:any) => {
                    success ? handleSuccessGet(data) : handleErrorGet(success,data,error)
                }}
            />
        </>
    )
};

const mapStateToProps = (state: ApplicationState) => ({
    data: selectors.getFetchState(state,"EQUIPMENT_LIST") as FetchState<DashboardResponse>,
    isRefresh: state.app.isRefresh,
    settingViewStore: state.app.settingView,
    user_id:state.app.user?.cognito_username,
});

const mapDispatchToProps = {
    onDateChange: (date: string | null) => actionCreators.fetch("EQUIPMENT_LIST", "/api/master/equipments", "GET", {date,}),
    onGet: () => actionCreators.fetch(GET_DATA_DASHBOARD,`/telemetry/get?code=DSBD&path=root`,"GET",null),
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard as any);