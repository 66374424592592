import { Grid, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import * as React from 'react';
import { customerLabelName } from '../../statics';

export type CommentDialogProps = {
  isOpen: boolean;
  operation: string;
  onOK: (value: string) => void;
  onCancel: () => void;
  data: any;
};

export const CommentDialog = (props: CommentDialogProps) => {
  const { isOpen, operation, onOK, onCancel, data } = props;
  const [open, setOpen] = React.useState(isOpen);
  const [comment, setComment] = React.useState(data.comment);


  const handleOK = () => {
    console.log(">>> modal handleOK")
    setOpen(false);
    onOK(comment);
  };

  const handleCancel = () => {
    console.log(">>> modal handleCancel")
    setOpen(false);
    onCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="comment-dialog-title"
        aria-describedby="comment-dialog-description"
      >
        <DialogActions>
          <div style={{ width: '100%' }}>
          <Stack>
            {operation  === "1" ? (
              <>
                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} ml={10} mr={10}>
                  <Grid container spacing={1} direction="row" >
                    <Typography variant="body1">受信日時: {data.occurrence_time}</Typography>
                  </Grid>
                </Stack>
                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1} ml={10} mr={10}>
                  <Grid container spacing={1} direction="row" >
                    <Typography variant="body1">{`${customerLabelName}名`}: {data.customer_name}</Typography>
                  </Grid>
                </Stack>
                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1} ml={10} mr={10}>
                  <Grid container spacing={1} direction="row" >
                    <Typography variant="body1">設置場所: {data.place}</Typography>
                  </Grid>
                </Stack>
                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1} ml={10} mr={10}>
                  <Grid container spacing={1} direction="row" >
                    <Typography variant="body1">装置ID: {data.mainid}</Typography>
                  </Grid>
                </Stack>
                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} ml={10} mr={10}>
                  <Grid container spacing={1} direction="row" >
                    <Typography variant="body1">以下の日時で手動解除を行います。</Typography>
                  </Grid>
                </Stack>
                <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={1} ml={12} mr={10}>
                  <Grid container spacing={1} direction="row" >
                    <Typography variant="body1">解除日時: {data.current_time}</Typography>
                  </Grid>
                </Stack>
              </>):(
                <div />)}
            <Stack spacing={4} direction={{ xs: 'column', sm: 'row' }} mt={3} ml={10} mr={10}>
              <Grid container spacing={1} direction="row" >
                <TextField
                  fullWidth
                  style={{width: "500px"}}
                  type="text"
                  label="確認コメント"
                  size="small"
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  inputProps={{ maxLength: 32 }}
                />
              </Grid>
            </Stack>
            <Stack direction='row' justifyContent="center" spacing={2} sx={{ mt: 2, mr: 2,mb: 1 }}>
              <Button onClick={handleOK} variant="contained">登録</Button>
              <Button onClick={handleCancel} color="inherit" variant="contained">キャンセル</Button>
            </Stack>
          </Stack>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CommentDialog;