import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ClearIcon from '@mui/icons-material/Clear';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Autocomplete, CircularProgress, Collapse, Grid, Stack, TextField, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { useNavigate } from 'react-router';
import { DeviceModel, OptionItem, PageData } from "../../../models";
import { Path as RoutePath } from "../../../path";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import palette from '../../../theme/palette';
import FetchHelper from "../../FetchHelper";
import AlertDialog from '../../common/AlertDialog';
import { DeviceGrid } from "./DeviceGrid";
import { customerLabelName, limitMasterCustomers } from '../../../statics';

// -------------
// FETCH ID
const getAllCustomers = "GET_ALL_CUSTOMERS";
const fetchId = "DEVICE_LIST";
const formId = "DEVICE_SEARCH_FORM";
const menusFetchId = 'MENUS_FETCH';
const CUSTOMER_GROUP_LIST = "CUSTOMER_GROUP_LIST";

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
    group_id: OptionItem | null;
    customer_id: OptionItem | null;
    place: string;
    manage_customer_name: string;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 1000,
    page: 0,
    group_id: null,
    customer_id: null,
    place: "",
    manage_customer_name: "",
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// -------------
// Props
export type DeviceListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------

// Component
export const DeviceList = (props: DeviceListProps) => {
    const {
        onSearch,
        onError,
        onDelete,
        onDeleteSuccess,
        onDeleteError,
        onGetAuthRole,
        onSearchCustomerGroups,
        fetchStateMenu,
    } = props;
    // const [hideMessage, setHideMessage] = React.useState(false);
    const { control, handleSubmit, setValue, getValues } = useForm<SearchItemForm>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    localStorage.setItem('lastActiveFormPath', RoutePath.DeviceList);
    localStorage.setItem('title', '監視装置');

    const [keyword, setKeyword] = useState("");
    const [openSearch, setOpenSearch] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<DeviceModel[]>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [disableDelete, setDisableDelete] = React.useState(true);
    const [customersOption, setCustomersOption] = React.useState<OptionItem[]>([]);
    const [customerGroupsOption,setCustomerGroupsOption] = React.useState<OptionItem[]>([]);
    // const [isFirstFetch, setIsFirstFetch] = React.useState<boolean>(true);
    const [colState, setColState] = useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(true);
    const [loadingCustomerGroupFlag, setLoadingCustomerGroupFlag] = React.useState(true);
    const [authRole, setAuthRole] = React.useState<any>();
    const [customerGroupsListShow, setCustomerGroupsListShow] = React.useState<OptionItem[]>([]);
    const gridRef = useRef() as any;

    
    //状態設定値
    const [disableGWStatusEditBtn, setDisableGWStatusEditBtn] = React.useState(true);
    //装置管理
    const [disableGWcrudAddBtn, setDisableGWcrudAddBtn] = React.useState(true);
    const [disableGWcrudEditBtn, setDisableGWcrudEditBtn] = React.useState(true);
    const [disableGWcrudDeleteBtn, setDisableGWcrudDeleteBtn] = React.useState(true);
    
    React.useEffect(() => {
        if(fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);

            setDisableGWStatusEditBtn(!fetchStateMenu.data.auth_role_map['GW-gwstatus-view'] ?? true);

            setDisableGWcrudAddBtn(!fetchStateMenu.data.auth_role_map['GW-gwcrud-add'] ?? true);
            setDisableGWcrudEditBtn(!fetchStateMenu.data.auth_role_map['GW-gwcrud-edit'] ?? true);
            setDisableGWcrudDeleteBtn(!fetchStateMenu.data.auth_role_map['GW-gwcrud-delete'] ?? true);
        } else {
            onGetAuthRole();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            setAuthRole(data);
            setDisableGWStatusEditBtn(!data.auth_role_map['GW-gwstatus-view'] ?? true);
            setDisableGWcrudAddBtn(!data.auth_role_map['GW-gwcrud-add'] ?? true);
            setDisableGWcrudEditBtn(!data.auth_role_map['GW-gwcrud-edit'] ?? true);
            setDisableGWcrudDeleteBtn(!data.auth_role_map['GW-gwcrud-delete'] ?? true);
        }
    }

    const isVisibleAddDeviceList = process.env.REACT_APP_ADD_DEVICE_LIST_BUTTON_VISIBLE;

    let navigate = useNavigate();
    const handleShowDetail = (id: string, redirect: string) => {
        if (redirect === "pollingTopicDetail") {
            return navigate(RoutePath.PollingTopicDetail + `/${id}`);
        }
        if (redirect === "editDevice") {
            return navigate(RoutePath.EditDevice + `/${id}`);
        }
        if (redirect === "contact") {
            return navigate(RoutePath.PointsEdit + `?mainid=${id}`);
        }
        if (redirect === "leaks") {
            return navigate(RoutePath.LeaksEdit + `?mainid=${id}`);
        }
        if (redirect === "demand") {
            return navigate(RoutePath.DemandsEdit + `?mainid=${id}`);
        }
        if (redirect === "solar") {
            return navigate(RoutePath.SolarsEdit + `?mainid=${id}`);
        }
        if (redirect === "notification") {
            return navigate(RoutePath.NotificationsEdit + `?mainid=${id}`);
        }
        if (redirect === "registReport") {
            return navigate(RoutePath.ReportDemand + `?mainid=${id}`);
        }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        setLoadingFlag(true);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const params = new Map<string, any>();
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if (value.customer_id && value.customer_id.value) params.set("customer_id", value.customer_id.value);
        if (value.place) params.set("place", value.place);
        if (value.group_id && value.group_id.value) params.set("group_id", value.group_id.value);
        if (value.manage_customer_name) params.set("manage_customer_name", value.manage_customer_name);        
        onSearch(params);
    }

    // 初回検索実行
    React.useEffect(() => {
        handleSearchSubmit(getValues());

        setLoadingCustomerGroupFlag(true);
        const params = new Map<string, any>();
        params.set("limit", 1000);
        onSearchCustomerGroups(params);

        // eslint-disable-next-line
    }, []);

    // 検索正常終了時
    const handleSuccess = (data: any) => {
        if (data && data.results) {
            setDisableDelete(true);
            data.results.forEach((element: { isSelect: boolean; }) => {
                element.isSelect = false;
            });
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.results);
            }
            setListData(() => [...data.results]);

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);

            // if (isFirstFetch === true) {
            //     // const customers = [...data.results].map(element => {
            //     //     return {
            //     //         id: element.customer_id,
            //     //         name: element.customer_name,
            //     //     }
            //     // }).sort((a, b) => a.name.localeCompare(b.name, "ja-JP"));
            //     // handleCustomers(customers.filter((element, index) => customers.findIndex((item) => item.id === element.id) === index));
            //     setIsFirstFetch(false);
            // }
        } else {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData([]);
            }
        }
        setLoadingFlag(false);
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        // setHideMessage(true);
        setLoadingFlag(false);
        onError(success, data, error);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    React.useEffect(() => {
        // グラフ表示用データの更新
        // --------------------------------
        if (myParam) {
            listData.forEach((item: any) => {
                item.serial_no = myParam
            });
            setListData(() => [...listData])
        }
        else {
            listData.forEach((item: any) => {
                item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            });
            setListData(() => [...listData])
        }
        // eslint-disable-next-line
    }, [myParam]);

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    const handleAdd = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        navigate(RoutePath.AddDevice);
    }

    const handleAddDeviceList = () => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        navigate(RoutePath.AddDeviceList);
    }

    //削除の処理
    const handleDelete = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        if (listData.filter((item) => item.isSelect === true).length === 0) {
            return;
        }
        const deleteIds = listData.filter((item) => item.isSelect === true).map((item) => item.id);
        const msg = "装置ID: " + deleteIds.join(', ') + " を削除しますか？";
        setTitle("監視装置削除");
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setLoadingFlag(true);
        setOpenDeleteDialog(false);
        const deleteIds = listData.filter((item) => item.isSelect === true);
        deleteIds.forEach((item) => {
            onDelete(item.id.toString());
        })
    }

    const handleDeleteSuccess = (data: any) => {
        if (data.result_code === 1) {
            handleDeleteError();
        } else {
            onDeleteSuccess();
            handleSearchSubmit(getValues());
            setDisableDelete(true);
        }
    }

    const handleDeleteError = () => {
        setLoadingFlag(false);
        onDeleteError();
    }

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    };

    // 検索正常終了時
    const handleSelect = (data: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        let list = listData.filter((item) => item.id === data.id)
        list[0].isSelect = !list[0].isSelect;

        let selectCount = listData.filter((item) => item.isSelect === true).length;

        if (selectCount === 1) {
            setDisableDelete(false);
        } else if (selectCount > 0) {
            setDisableDelete(false);
        } else {
            setDisableDelete(true);
        }
    };

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("customer_id", null);
        setValue("group_id", null);
        setValue("place", "");
        setValue("manage_customer_name", "");
    };

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
    }

    const handleCustomers = (data: { id: string, name: string }[]) => {
        data && setCustomersOption(data.map((x: any) => { return { value: x.id, label: x.name, attribute: '' } as OptionItem }));
    };

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    const handleFetchCustomerGroupListSucess = (data:any) => {
        setLoadingCustomerGroupFlag(false);
        if(data && data.result_code == 0){            
            const list_option = data.results.map((item:any) => {
                return {value:item.group_id,label:item.name} as OptionItem;
            });
            setCustomerGroupsOption(list_option);
        }
    }

    useEffect(() => {
        if(authRole){
            if(authRole.auth_role_map["MS-customergroup-get"]){
                setCustomerGroupsListShow(customerGroupsOption);
            }else if(authRole.auth_role_map["MS-customergroupbelong-get"]){
                const customer_options = customerGroupsOption.filter((item:any) => item.value == authRole.company_group_id);
                setCustomerGroupsListShow(customer_options);
            }else{
                setCustomerGroupsListShow([]);
            }
        }
    },[authRole,customerGroupsOption])

    const handleFetchCustomersSuccess = (data:any) => {
        if(data && data.result_code == 0){
            const customers = data.results.map((element:any) => {
                return {
                    id: element.id,
                    name: element.name,
                }
            }).sort((a:any, b:any) => a.name.localeCompare(b.name, "ja-JP"));
            handleCustomers(customers);
        }
    }

    const handleFetchCustomerGroupListError = (success:any, data:any, error:any) => {
        setLoadingCustomerGroupFlag(false);
    }

    return (
        <>
            <FetchHelper fetchId={getAllCustomers}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomersSuccess(data) : handleError(success, data, error)
                }}
            />

            {(loadingFlag || loadingCustomerGroupFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}

            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleSearch)}>
                    <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                        <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="h6" >検索条件</Typography>
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {
                                    openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                            <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                >
                                    <Grid item xs={2.5}>
                                        <Controller
                                            control={control}
                                            name="group_id"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={customerGroupsListShow}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label={`グループ名`} size="small" style={{ background: palette.background.paper }} />}
                                                    onChange={(_, data) => setValue("group_id", data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Controller
                                            control={control}
                                            name="customer_id"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={customersOption}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" style={{ background: palette.background.paper }} />}
                                                    onChange={(_, data) => setValue("customer_id", data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Controller
                                            name="place"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="設置場所"
                                                    size="small"
                                                    style={{ background: palette.background.paper }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Controller
                                            name="manage_customer_name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="管理組織名"
                                                    size="small"
                                                    style={{ background: palette.background.paper }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={handleSearch}>
                                            検索
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </div>
                    </Stack>
                </form >
                <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} mb={1} spacing={1}>
                        <Grid item>
                            <TextField
                                fullWidth
                                autoComplete="searchStr"
                                name="keyword"
                                type="text"
                                label="フィルター"
                                size="small"
                                value={keyword}
                                onChange={e => handleChangeKeyword(e.target.value)}
                                />
                            </Grid>
                        <Button variant="outlined" size="small" onClick={() => handleChangeKeyword("")}>クリア</Button>
                        {isVisibleAddDeviceList === "true" && <Button variant="contained" size="small" onClick={handleAddDeviceList} disabled={disableGWcrudAddBtn}>{<PlaylistAddIcon />}</Button>}
                        <Button variant="contained" size="small" onClick={handleAdd} disabled={disableGWcrudAddBtn}>{<AddIcon />}</Button>
                        <Button variant="contained" size="small" onClick={handleDelete} disabled={disableGWcrudDeleteBtn || disableDelete}>{<ClearIcon />} </Button>
                    </Stack>
                </div>
                <div>
                    <DeviceGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        onShowDetails={handleShowDetail}
                        onSelect={handleSelect}
                        openSearch={openSearch}
                        btnController={{
                            isDisableGWStatusEditBtn: disableGWStatusEditBtn,
                            isDisableGWcrudEditBtn: disableGWcrudEditBtn,
                            isDisableGWcrudDeleteBtn: disableGWcrudDeleteBtn
                        }}
                        authRole = {authRole}
                        keyword = {keyword}
                    />
                </div>
                {openDeleteDialog ?
                    <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                    <div />
                }
            </div>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) => success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId="DELETE_DEVICE" method="GET" onComplete={(success, data, error) => success ? handleDeleteSuccess(data) : handleDeleteError()} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={CUSTOMER_GROUP_LIST} onComplete={(success, data, error) => success ? handleFetchCustomerGroupListSucess(data) : handleFetchCustomerGroupListError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/master/gateways/get`, "GET", params),
    onSearchCustomerGroups: (params: { [key: string]: any }) => actionCreators.fetch(CUSTOMER_GROUP_LIST, `/master/customer-groups/get`,"GET",params),
    onChangePageSize: (pageSize: number) => actionCreators.setPageSize(pageSize),
    // onSuccess: () =>
    //     actionCreators.showMessage({
    //         type: "info",
    //         title: "装置検索",
    //         body: "装置を検索しました。",
    //     }),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "装置検索",
            body: "装置を検索できません。" + error,
        }),
    onDelete: (id: string) => actionCreators.fetch('DELETE_DEVICE', `/master/gateways/delete?id=${id}`, "GET", null),
    onDeleteSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "装置削除",
            body: "装置を削除しました。",
        }),
    onDeleteError: () =>
        actionCreators.showMessage({
            type: "error",
            title: "装置削除",
            body: "装置を削除出来ませんでした。",
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceList as any);
